/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import { Dropdown, DropdownButton, Card } from "react-bootstrap";

import PropTypes from 'prop-types';

/** Importing custom Components */
import { setCallFlowJsonStepSettings, setResponseAction, showResponseModal, showNodeResponseModal, setShowEntityModal, setShowExistingRulesModal } from "../../../../store/actions/rules/rulesActions";
import { messages } from '../../../../data/strings';
/** Importing custom Styles */
import classes from './Settings.module.css';
import { Icon } from '@iconify/react';

/** Main Components */
class Settings extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        this.state = {

        }
    }
    /** Lifecycle methods end */

    onClickSettings = () => {
        // this.setState({visible: !this.state.visible});
    }

    onConfigureInterruption = (stepKey) => {
        global.recordAudit("Configure Interruption clicked");
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [
                {"step": stepKey}
            ]
        });
        this.props.showResponseModal(true); 
    }

    onChangePassable = () => {
        /** Call Flow version 1.1 and old support */
        var newCallFlowJSON = { ...((this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson) };
        if (newCallFlowJSON[this.props.propsOwned.stepKey]) {
            if (!newCallFlowJSON[this.props.propsOwned.stepKey].settings) { 
                newCallFlowJSON[this.props.propsOwned.stepKey].settings = {};
            }
            if (this.props.propsOwned.settings && this.props.propsOwned.settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY]) {
                newCallFlowJSON[this.props.propsOwned.stepKey].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY] = false;
            } else {
                newCallFlowJSON[this.props.propsOwned.stepKey].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY] = true;
            }
            if (newCallFlowJSON[this.props.propsOwned.stepKey].settings.interruption) {
                delete newCallFlowJSON[this.props.propsOwned.stepKey].settings["interruption"];
            }

            this.props.setCallFlowJsonStepSettings(this.props.propsOwned.stepKey, newCallFlowJSON[this.props.propsOwned.stepKey].settings);
        }
    }

    onConfigureEntity = (stepKey) =>{
        if(this.props.propsOwned.type !=='multipath'){
            var newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
            var intentsInStep = newCallFlowJson[stepKey].list.map(block=>block.node);
            var uniqueIntents = [...new Set(intentsInStep)]
            
        }else{
            var seletedSteps = Object.keys(this.props.propsOwned.selectedSteps);
            this.props.propsOwned.deSelectSteps();
        }
        this.props.setShowEntityModal(true, uniqueIntents, stepKey, null, true,null, this.props.propsOwned.type, seletedSteps);
    }

   
    onClickExistingRules = (stepKey) =>{
        var newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
        const intentsInStep = newCallFlowJson[stepKey].list.map(block=>block.node);
        const uniqueIntents = [
            {
                name:"Rule 1",
                content:"should have:"
            },
            {
                name:"Rule 2",
                content:"should not have:"
            },
            {
                name:"Rule 3",
                content:"should not have:"
            }
        ]
        this.props.setShowExistingRulesModal(true, uniqueIntents);
    }

    passableDisable = () =>{
        const relations = this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'];
        const callFlowJSONPicked = this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]
        const accordionStates = Object.keys(this.props.propsRedux.accordionState);
        if(relations){
            for(let accordionId of accordionStates){
                let [step,relationIdx] = accordionId.split('-');
                relationIdx = parseInt(relationIdx);
                
                const relation = relations[step] && relations[step][relationIdx];
                if(relation){
                    
                    const mainAccordionLast = relation.path1[relation.path1.length-1];
                    const relationFirstStep = relation.path2[0];
                    const relationLastStep = relation.path2[relation.path2.length-1];
                    let mainSequenceRestrict = mainAccordionLast;
                    let relationRestrict = relationLastStep
                    if(callFlowJSONPicked[mainAccordionLast] && callFlowJSONPicked[mainAccordionLast].list.length === 0){
                        for(let i=relation.path1.length-1; i>=0;i--){
                            if(callFlowJSONPicked[relation.path1[i]] && callFlowJSONPicked[relation.path1[i]].list.length > 0){
                                mainSequenceRestrict = relation.path1[i]
                                break;
                            }
                        }
                        
                    }
                    if(callFlowJSONPicked[relationLastStep] && callFlowJSONPicked[relationLastStep].list.length === 0){
                        for(let i=relation.path2.length-1; i>=0;i--){
                            if(callFlowJSONPicked[relation.path2[i]] && callFlowJSONPicked[relation.path2[i]].list.length > 0){
                                relationRestrict = relation.path2[i]
                                break;
                            }
                        }
                    }
                    if([mainAccordionLast,relationFirstStep,relationLastStep,step, mainSequenceRestrict, relationRestrict].includes(this.props.propsOwned.stepKey)){
                        return true
                    }
                }
            }
        }
    }

    render() {
        const isMultipath = this.props.propsOwned.type ==='multipath';
        const isPassableDisabled = this.passableDisable();
                
        return (
            <div className={ [  "call-flow-settings", isMultipath ? classes.multiPathSettingsBox : classes.SettingsBox ].join(' ') }>
                <Dropdown className={ [  ].join(' ') } >
                    <DropdownButton drop="left" title={ (<span className="text-center pt-1" aria-label="Settings" data-tip = {messages.interruptionSettings} data-class="tooltip_cust"  data-place="top"><Icon style={{color: this.props.propsOwned.type ==='multipath' && 'white'}} icon="carbon:settings" /></span>) } variant={ "default" } className={ [ this.props.propsOwned.type !=='multipath' && classes.SettingsDropDownToggle, "noCaret" ].join(' ') } >
                        {
                            // (!this.props.isDisabled) &&
                            // (
                            //     (!this.props.propsOwned.settings) ||
                            //     (this.props.propsOwned.settings && !this.props.propsOwned.settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY])
                            // ) &&
                            
                            !isMultipath && <Dropdown.Item className={[classes.DropDownSettingsSize].join(' ')}
                                key={ "settings_configure_interruption" } 
                                onClick={ () => this.onConfigureInterruption(this.props.propsOwned.stepKey) } 
                                disabled={ 
                                    (
                                        (this.props.isDisabled) ||
                                        (this.props.propsOwned.settings && this.props.propsOwned.settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY])
                                    )
                                 } 
                            > 
                                Configure Interruption
                            </Dropdown.Item>
                        }
                        {
                            !isMultipath && <Dropdown.Item className={[classes.DropDownSettingsSize].join(' ')}
                                key={ "toggle_passable" } 
                                onClick={ () => this.onChangePassable() } 
                                disabled={ this.props.isDisabled || isPassableDisabled }
                            > 
                                Make step { (this.props.propsOwned.settings && this.props.propsOwned.settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY]) ? "strict": "passable" }
                            </Dropdown.Item>
                        }
                        {!isMultipath && <Dropdown.Item className={[classes.DropDownSettingsSize].join(' ')} key={ "add_response_block" } onClick={ this.props.propsOwned.addBlock } > 
                            Add Response In Step
                        </Dropdown.Item>
                        }
                        {!isMultipath && <Dropdown.Item className={[classes.DropDownSettingsSize].join(' ')} key={ "add_next_step" } onClick={ this.props.propsOwned.addStep } > 
                            Add next step
                        </Dropdown.Item>
                        }
                         <Dropdown.Item className={[classes.DropDownSettingsSize].join(' ')} key={ "configure_entity" } disabled={ this.props.isDisabled } onClick={ ()=>this.onConfigureEntity(this.props.propsOwned.stepKey) } > 
                           Configure Rules
                        </Dropdown.Item>
                        
                        
                    </DropdownButton>
                </Dropdown>
                            
            </div>
            
        );
    }
}

/** Defining prop types requirements */
Settings.propTypes = {
    propsRedux: PropTypes.shape({
        callFlowJson : PropTypes.object.isRequired
    })
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
           callFlowJson : state.rules.callFlow.callFlowJSON,
           accordionState: state.rules.accordionState
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setResponseAction, showResponseModal, setCallFlowJsonStepSettings, showNodeResponseModal, setShowEntityModal, setShowExistingRulesModal })(Settings);