/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import SearchableSelect from "react-select";

import PropTypes from 'prop-types';

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";
import { getPathString } from "../../../utils/callFlowUtils";
import { showNodeResponseModal, setResponseAction, setCallFlow } from '../../../store/actions/rules/rulesActions';

/** Importing custom Styles */

/** Main Components */
class RuleResponseNodeModal extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        // var nodeList = RuleResponseNodeModal.getNodesResponsesList(this.props.propsRedux.simpleViewRows, this.props.propsRedux.callFlowResponseMappings);
        this.state = {
            nodeSelected: null,
            responseSelected: null,
            nodeList: [],
            responseList: [],
            responseListDisabled: true,
            ruleAndCallFlowUpdated: this.props.propsRedux.ruleAndCallFlowUpdated,
            _smSVRowsType: (typeof this.props.propsRedux.simpleViewRows)
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if ( 
                (
                    (nextProps.propsRedux.ruleAndCallFlowUpdated !== prevState.ruleAndCallFlowUpdated) ||
                    (
                        (typeof nextProps.propsRedux.simpleViewRows == "object") 
                        // && 
                        // (prevState._smSVRowsType == "string")
                    )
                ) &&
                ( Object.keys(nextProps.propsRedux.callFlowResponseMappings).length > 0)
            ) {
            var nodeList = RuleResponseNodeModal.getNodesResponsesList(nextProps.propsRedux.simpleViewRows, nextProps.propsRedux.callFlowResponseMappings);
            return { 
                nodeList: nodeList,
                ruleAndCallFlowUpdated: nextProps.propsRedux.ruleAndCallFlowUpdated,
                _smSVRowsType: (typeof nextProps.propsRedux.simpleViewRows)
            };
        }
        return null;
    }
    /** Lifecycle methods end */


    static getNodesResponsesList = (simpleViewRows, callFlowResponseMappings, stepSequence, callFlowJSON) => {
        let nodesList = [];
        let nodeListIndexesObj = {};
        
        [ ...simpleViewRows ].forEach((row, index) => {
            let rowObj = (row.original) ? row.original : row;
            if (
                rowObj &&
                rowObj.node &&
                rowObj.pathsData && 
                rowObj.pathsData && 
                rowObj.pathsData[0] &&
                (rowObj.pathsData[0].evalKey !== global.CUST_CONSTANTS.PREDEFINED_KEYS.EVAL_KEYS.EVAL_FALSE) &&
                (!rowObj.isMetadata) &&
                (rowObj.node !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                (rowObj.node !== global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE) &&
                (rowObj.responseText !== global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT) &&
                ( 
                    (!rowObj.responseTagsObj) ||
                    (rowObj.responseTagsObj && !rowObj.responseTagsObj["Interruptions"] )
                )
            ) {
                let nodeTemp = rowObj.node;
                    let pathString = getPathString(rowObj.pathsData[0], true);
                    if (!callFlowResponseMappings[pathString]) {
                        if (nodeTemp in nodeListIndexesObj) {
                            nodesList[nodeListIndexesObj[nodeTemp]].responses.push({
                                label: rowObj.responseText,
                                value: rowObj.responseId,
                                referencePaths: [...rowObj.pathsData] 
                            });
                        } else {
                            nodesList.push({
                                label: nodeTemp,
                                value: nodeTemp,
                                isDisabled: false,
                                responses: [
                                    {
                                        label: rowObj.responseText,
                                        value: rowObj.responseId ,
                                        referencePaths: [...rowObj.pathsData]
                                    }
                                ]
                            });
                            nodeListIndexesObj[nodeTemp] = (nodesList.length-1);
                        }
    
                        // if (
                        //     simpleViewRows[index-1] &&
                        //     nodesList[nodesList.length-2] && 
                        //     (
                        //         (
                        //             simpleViewRows[index-1].original && 
                        //             simpleViewRows[index-1].original.node && 
                        //             (simpleViewRows[index-1].original.node == nodeTemp)
                        //         ) ||
                        //         (
                        //             simpleViewRows[index-1] && 
                        //             simpleViewRows[index-1].node && 
                        //             (simpleViewRows[index-1].node == nodeTemp)
                        //         )
                        //     )
                        // ) {
                        //     nodesList[nodesList.length-2].responses.push({
                        //         label: rowObj.responseText,
                        //         value: rowObj.responseId 
                        //     });
                        // } else {
                        //     nodesList.push({
                        //         label: nodeTemp,
                        //         value: nodeTemp,
                        //         isDisabled: false,
                        //         responses: [
                        //             {
                        //                 label: rowObj.responseText,
                        //                 value: rowObj.responseId ,
                        //                 referencePaths: [...rowObj.pathsData]
                        //             }
                        //         ]
                        //     });
                        // }
                    }
            }
        });
        console.log("DEBUGGER:: getNodeList nodesList", nodesList);
        return nodesList;
    }

    onHide = () => {
        global.recordAudit("Response modal closed");
        this.props.showNodeResponseModal(false);
        // this.props.setResponseAction(null);
        
        this.setState({ 
            nodeSelected: null,
            responseSelected: null,
        });
        this.props.propsOwned.onHide();
    }

    onNodeChange = (nodeSelected) => {
        this.setState({
            // isSaveDisabled: isSaveDisabled,
            nodeSelected: nodeSelected,
            responseList: nodeSelected.responses,
            responseListDisabled: false
            // selectionError : selectionError,

        });
    }

    onResponseChange = (responseSelected) => {
        this.setState({
            // isSaveDisabled: isSaveDisabled,
            responseSelected: responseSelected,
            // responseList: responseSelected.responses,
            // responseListDisabled: false
            // selectionError : selectionError,

        });
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: responseSelected.value,
            step: this.props.propsRedux.responseAction.step,
            referencePaths: [ ...responseSelected.referencePaths ]
        });
    }

    onSave = () => {
        
        this.props.setCallFlow(global.CUST_CONSTANTS.CRUD_MODE.ADD);
        // this.props.setResponseAction(null, parseInt(e.target.value));
        this.onHide();
    }

    render() {

        var modalButtons = [
            {
                type: 'add',
                text: 'Save Response',
                variant: 'primary',
                onClick: this.onSave,
                className: 'gl-btnPrimaryCust',
                isDisabled: ((this.state.nodeList.length === 0) || (!this.state.nodeSelected) || (!this.state.responseSelected))
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: this.onHide
            }
        ];
        return (
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.props.propsOwned.onShow}
                onHide={ this.onHide }
                title={ "Add Block" }
                footerComponents={modalButtons}
            >
            
                {
                    (this.state.nodeList.length === 0) &&
                    <div className={['alert', 'alert-danger'].join(' ')}>
                        { "No responses left to be added" }
                    </div>
                }
                {
                    (this.state.nodeList.length > 0) &&
                    <div >
                        <SearchableSelect 
                            name="node"
                            className={ [  ].join(' ') }
                            options={ this.state.nodeList } 
                            onChange={ (intentSelected) => this.onNodeChange(intentSelected) } 
                            placeholder={ "Select Node..." }
                            formatOptionLabel = { this.formatOptionLabel }
                        />
                        <br />
                        <SearchableSelect 
                            name="responses" 
                            isDisabled={ this.state.responseListDisabled }
                            value={ this.state.responseSelected }
                            hideSelectedOptions={ true }
                            options={ this.state.responseList } 
                            onChange={ (response) => this.onResponseChange(response, false) } 
                            formatOptionLabel={ this.formatOptionLabelResponses }
                        />
                    </div>
                }

            </Modal>
        );
    }
}

/** Defining prop types requirements */
RuleResponseNodeModal  .propTypes = {
    propsRedux: PropTypes.shape({
        simpleViewRows : PropTypes.isRequired,
        callFlowResponseMappings : PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            simpleViewRows: state.rules.simpleViewRows,
            callFlowResponseMappings: state.rules.callFlowResponseMappings,
            showRulesNodeResModal: state.rules.showRulesNodeResModal,
            responseAction: state.rules.responseAction,
            ruleAndCallFlowUpdated: state.rules.ruleAndCallFlowUpdated,
            stepSequence: state.rules.stepSequence,
            callFlowJSON: state.rules.callFlow.callFlowJSON,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { showNodeResponseModal, setResponseAction, setCallFlow })(RuleResponseNodeModal);