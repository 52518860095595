import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { precheckBeforeUpgrade, upgradeStoryToV2Editor } from '../../../../../store/actions/workbench/workbenchActions'
import Icon from 'react-fa';
import classes from './Upgrade.module.css'

const Upgrade = () => {

  const dispatch = useDispatch()
  const {story:{isUpgrading}} = useSelector(state => state)
  
  const handleUpgradeStoryToV2 = () => {
    dispatch(precheckBeforeUpgrade())
  }

  return (
        <span data-class="tooltip_cust">
            <button className={"btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue"} onClick={handleUpgradeStoryToV2} disabled={isUpgrading}>
                    Upgrade
            </button>
        </span>
  )
}

const CustomOverlay = (props) => {
    const {open,header,footer,children}  = props;
  return (
    open ? <div className={[classes.OverlayContainer].join(' ')}>
        {header  && <div className={classes.OverlayHeader}>{header}</div>}
        <div className={classes.OverlayContent}>
            {children}
        </div>
        <div className={classes.OverlayFooter}>
            {footer}
        </div>
    </div>: ''
  )
}

const LoaderOverlay = () => {
    const {story: {loaderOverlay}} = useSelector(state => state);
    const {isOpen, customMessage} = loaderOverlay

    return (
        // Overlay
        <CustomOverlay open = {isOpen}>
            <h5 className={classes.LoaderText}>Please do not refresh the page or hit the back button !!</h5>
            {customMessage && customMessage.length > 0 && <h6 className={classes.CustomMessageText}>{customMessage}</h6>}
        </CustomOverlay>
    );
}

export {Upgrade, CustomOverlay, LoaderOverlay}