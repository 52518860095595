
export const messages = {
    isDraftIcon: "Rules and Call flow are saved either as draft or not saved. Thus, latest changes will not reflect in practice sessions or anywhere else.",
    unsavedChanges: "Rules or Call flow are not saved at all. Do not leave the page without saving it or till it is saved automatically.",
    autoSaveLabel: "Rules and Call flow are automatically getting saved in draft mode. The changes will not be reflected in practice session until you hit save button.",
    intentAlreadyPresent: "This intent is already present in the json. Please edit the existing node or remove that node first before retrying this action.",
    sampleNodeMessage: "This is a sample node and it will not be saved and will not reflect anywhere. Please Add node to avoid this",
    errorInSavingRules: "Some error occurred while saving the rules. Please reload the page and try again.",
    errorInFetchingRules: "Unable to retrieve the requested story rule json.",
    invalidNode: "This node is not valid as no rules are configured against it. Please remove this node and add it again properly or it will not work as expected.",
    switchInvalid: "There is one and only response Configured among these rules. Thus You can't delete it!",
    passableResponseIfo: "Passable response.",
    syncCFRules: "Sync, Reset or Upgrade call flow using this option.",
    autoSavingAlertMessage: "Saving in progress.. Your changes done during this can be lost.",
    deleteBlockFromCallFlow: "Deletes the block only from the call flow. Doesn't effect the rules Json nodes.",
    deletionFromCFNotAllowed: "Call Flow can not be blank! Please add blocks before deleting the last block.",
    responseAlreadyOnNode: "This response already exists on the selected node. You can not have same response repeated on this node.",
    warnOverwritingMessage: "Rules have been changed on this story expliciltly. Thus it is warned not to overwrite the rules.",
    resetCallFlow: "This will reset your call flow to default call flow made at bootstrapping level and you will loose your current changes in the call flow. This is not going to affect rules directly.",
    syncCallFlowWithResponses: "This will sync your responses if changed in Rules and will resync dependencies.You can sync the call flow if any issues in current version or rules responses are changed explicitly. ",
    callflowDownload: "Download Call Flow",
    trainAndPublish: "Train and Publish",
    startTraining: "Start Training",
    noInterruptionConfigured: "Non happy test cases will not be generated if you do not configure this",
    testCaseFailedLuis: "Something went wrong while running this Test case",
    testCaseFailed: "Test Case Failed",
    testCaseSuccess: "Test Case ran successfully",
    callFlowInfo: "See call flow information",
    callFlowSettings: "Configure call flow settings",
    interruptionSettings: "Configure interruption settings",
    successTestCases: "Total Number of success Test Cases",
    failedTestCases: "Total Number of failed Test Cases",
    underProcessTestCases: "Total Number of Test Cases under process",
    generateTestPlan: "Generate Test plan",
    generationUnderProcess : "Test Plan generation is under process",
    happyPath: "Happy path",
    nonHappytPath: "Non happy path",
    syncLuisApp: "Sync Attached AI App",
    luisAppNotInSync: "Your App is out of sync. Please click on sync button in Call Flow Tab to make it up to date.",
    pleaseSyncLuisApp: "Your App is out of sync. Please Sync the app to make it up to date.",
    oneClickUtterances: "Attach highest scoring utterances in all the blocks in all the steps",
    outOfSync:"OUT OF SYNC",
    inSync:"IN SYNC",
    syncSuccess:"App Sync Completed",

    deleteUntrainedUtterance: "Delete Untrained Utterance",
    deleteSampleUtterance: "Delete Sample Utterance",
    deleteDefaultUtterance:'Delete Default Utterance',
    addUntrainedUtterance: 'Add Untrained Utterance',
    editSampleUtterance:'Edit Sample Utterance',
    saveUntrainedUtterance: 'Save Untrained Utterance',
    saveSampleUtterance:'Save Sample Utterance',
    noUtteranceTextIsConfigured: 'No Utterance Is Configured',

    luisStoryIdMismatchMessage : 'Actions are disabled.This AI App Is already being trained for another resource, ',
    luisStoryIdMatchMessage : 'This AI App Is being trained for this Story already.',


    deleteRelation: "Delete relation",
    editRelation: "Edit relation",
    overLappingRelations: "Overlapping AND-AND relations and AND-OR relations are restricted",
    accordionAbovePassable: "This step of relation cannot be blank. Please add blocks before deleting the last block.",
    deleteRestricted: "This cannot be deleted due to callflow restrictions.",

    luisActionsEnabledText: 'Operations are enabled',
    luisActionsDisabledText: 'Operations are disabled',
    luisdataAbsent: 'No AI Data',
    luisDataPresent: 'AI Data is Present',

    nodeValidationMessage:'Invalid.You can only use letters (a-z, A-Z), numbers (0-9) and symbols @ # _ . , ^ \\ [ ]',
    needAtleastOneUtterance:'Need atleast one utterance.',
    deleteUntrainedLuisObjFromDb:"AI Training Data deleted successfully",
    warningBeforeDeletingTrainingData:"if you delete currently untrained data, it will imbalance the whole training done till now. Make sure you have trained all the data by yourself before putting it in production.",
    restrictingSaveRulesButton:"Saving the data manually is restricted due to presence of untrained data. Please either train the data or wait for the data to be saved automatically",
    audioClipNotPresentStr: "Audio Clip is missing in call flow. So callflow will save in draft only.",

    deleteEntityFromUtterance : "Do you want to remove the Entity attached to this Utterance?",
    addEntityWarning: 'Please Select (or) Create Entity that you want to attach to the Utterances',
    entityColorCodeInfo:"The Trained Entities are in blue color and Untrained Entities are colored in red",

    actionInfoForEntityAddition: "Don't forget to save if you have added or removed an entity from an utterance",

    utterancesRetrived:"Utterances Retrived",
    intentsRetrived:"Intents Retrived",
    entitiesRetrived:"Entities Retrived",
    alreadyStartedTraining: "The story training has already been started",
    trainingStarted: "The story training has started"
};


export const luisDataOptions = {
    luisActionEnabled : {color: '#454B1B', shortText: messages.luisActionsEnabledText, title:'Operations Enabled'},
    luisActionDisabled : {color: '#D4070F', shortText: messages.luisActionsDisabledText, title:'Operations Disabled'},
    luisDataExist : {color: '#CC7722', shortText: messages.luisDataPresent,title:' Data Present' },
    luisDataAbsent:{color: '#FFDA13', shortText: messages.luisDataPresent,title:'No Data' },
    default:{color: '#FFDA13', shortText: "No Info",title:'No Information' }
}
