/*
 * File Description : Having all glbal functions
 * Author: Meghana Aggarwal
 */

import store from "../store/store";
import axiosCall from "../utils/axiosCall";
import { axiosErrorFunction } from "../store/actions/common/commonActions";

/**
 * Function to record UI audits
 * @param {string} data data to be entered while recording audit
 */
global.recordAudit = (data, eventId=0, eventType="rules-ui", userId=0) => {
    var state = store.getState();
    if ( eventId == 0 ) {
        if (state.rules && (state.rules.refEventId > 0)) {
            eventId = state.rules.refEventId;
        } else {
            if (state.story && (state.story.storyId > 0)) {
                eventId = state.story.storyId;
            }
        }
    }
    if ( userId == 0 ) {
        if (state.user && (state.user.id > 0)) {
            userId = state.user.id;
        }
    }
    console.log("Audit Log:", data);

    var url = global.CUST_CONSTANTS.RECORD_AUDIT_URL;
    var dataJson = {
        data: data,
        eventId: eventId,
        eventType: eventType,
        userId: userId,
    };
    const axiosSuccessFunction = (response) => {
        console.log("Audit recorded", response);
    };
    /** Axios call with custom method refresh */
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, { ...dataJson }, "POST");
}