/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import SearchableSelect from "react-select";
import { confirmAlert } from 'react-confirm-alert';
import Icon from 'react-fa';

/** Importing pre defined styles */
import 'handsontable/dist/handsontable.full.css';

/** Importing custom Components and functions */
import { setSelectedRule, setViewType, setIsChanged, setResponseAction, showResponseModal, removeResponse, setRules, setFreezeColumnFilter, setDetailedVsSimpleViewIndexesMapper, setLoaders, removeIntent, setSimpleRows, setBulkEdit, setRemoveIntentAndItsUtterancesFromUntrainedLuisObj } from "../../../store/actions/rules/rulesActions";
import { setAlert } from "../../../store/actions/common/commonActions";
import { createTableDataForNodeTable, getMetadata, getChangedRuleJson, createTableDataForSwitch, getResponseKeyFromObj } from "../../../utils/rulesUtils";

import SelectColumnFilter from "./TableComponents/SelectColumnFilter";
import SelectTagsColumnFilter from "./TableComponents/SelectTagsColumnFilter";

/** Importing custom Styles */
import classes from './SimpleView.module.css';
import SimpleViewTable from './SimpleViewTable/SimpleViewTable';
import { messages } from '../../../data/strings';


/** Main Components */
class SimpleView extends React.Component {
    /** Lifecycle methods start */
    constructor(props) {
        super(props);

        var tagsForSwitch = [];
        Object.keys(this.props.propsOwned.tags).forEach(tagCollection => {
            var tags = [];
            Object.keys(this.props.propsOwned.tags[tagCollection]).forEach(item => {
                tags.push({
                    label: item,
                    valueObj: {
                        tagCollection: tagCollection,
                        tag: item,
                        bgColor: this.props.propsOwned.tags[tagCollection][item].bg_color,
                    },
                    value: "tagselect_" + tagCollection + "_" + item,
                });
            });
            tagsForSwitch.push({
                label: tagCollection,
                options: [ ...tags ]
            });
        });

        this.columns = [
            {
                Header: 'Node',
                accessor: 'node',
                Filter: SelectColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Response Text',
                accessor: 'responseText',
                disableSortBy: true,
                Filter: SelectColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Response Clip',
                accessor: 'responseClipTags',
                disableSortBy: true,
                Filter: SelectTagsColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Alternate Response',
                accessor: 'alternateResponseClipTags',
                disableFilters: true,
                disableSortBy: true,
            },
        ];

        var stateData = createTableDataForNodeTable(this.props.propsRedux.rulesJson, this.props.propsRedux.botResponses);
        if (stateData.detailedVsSimpleViewIndexesMapper) {
            props.setDetailedVsSimpleViewIndexesMapper(stateData.detailedVsSimpleViewIndexesMapper);
        }

        this.confirmDeleteResponseAlert = {
            title: 'Confirm',
            message: 'Are you sure you want to delete the response?',
            buttons: [
              {
                label: 'Confirm',
                onClick: () => { props.removeResponse() }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };

        this.confirmDeleteNodeAlert = {
            title: 'Confirm',
            message: 'Are you sure you want to delete the node?',
            buttons: [
              {
                label: 'Confirm',
                onClick: this.removeNode
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };

        this.switchStatesObj = {
            0: null,
            1: "Show Switch Clips",
            2: "Switch Response",
            3: "Switch Response",
        }

        this.state = {
            /** it will have intentResponseData and tableData to be used in state */
            ...stateData,
            tagsForSwitch: tagsForSwitch,
            selectedTags : [],
            /** 0 - initial, 1- tags selected, 2 - Alternate show, 3 - disabled chosse controls (final confirmation) */
            switchState: 0,
            switchObj: {},
            tableDataBackup: [],
            nodeForDeletion: null,
            nodeIndex: -1,
            isJsonEmpty: (stateData.tableData.length == 0),
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if ( nextProps.propsRedux.isChanged ) {
            var stateData = createTableDataForNodeTable(nextProps.propsRedux.rulesJson, nextProps.propsRedux.botResponses);
            if (stateData.tableData) {
                nextProps.setSimpleRows(stateData.tableData);
            }
            if (stateData.detailedVsSimpleViewIndexesMapper) {
                nextProps.setDetailedVsSimpleViewIndexesMapper(stateData.detailedVsSimpleViewIndexesMapper);
                nextProps.setSelectedRule(null, null, false, null, null, [], null, null, true);
            }

            stateData['isJsonEmpty'] = (stateData.tableData.length == 0);

            return stateData;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.switchState !== prevState.switchState) {
            if ( ( this.state.switchState <= 1 ) && ( this.props.propsRedux.freezeColumnFilter ) ) {
                this.props.setFreezeColumnFilter(false);
            }
            if ( ( this.state.switchState > 1 ) && ( !this.props.propsRedux.freezeColumnFilter ) ) {
                this.props.setFreezeColumnFilter(true);
            }
        }

        if ( ( prevProps.propsRedux.isChanged === 0 ) && ( this.props.propsRedux.isChanged === 1 )) {
            this.props.setIsChanged();
        }
    }
    /** Lifecycle methods end */

    onNodeClick = (nodeKey, isMetadata=false, mode=global.CUST_CONSTANTS.CRUD_MODE.ADD, index=-1) => {
        global.recordAudit("Node clicked from simple view : " + nodeKey + ", isMetadata : " + isMetadata + ", Mode :" + mode);
        var selectedRule = {};
        if (isMetadata) {
            selectedRule = (this.props.propsRedux.rulesJson.metadata && this.props.propsRedux.rulesJson.metadata[nodeKey]) ? this.props.propsRedux.rulesJson.metadata[nodeKey] : {};
        } else {
            selectedRule = this.props.propsRedux.rulesJson.ruleResponses[nodeKey];
        }
        var pathsData = [];
        let indexToStart = index;
        /** for the same node rows before & the clicked node */
        while (
            (indexToStart > -1) && 
            this.state.tableData[indexToStart] && 
            this.state.tableData[indexToStart].node &&
            (this.state.tableData[indexToStart].node == nodeKey)
            ) {
            pathsData = [...pathsData, ...this.state.tableData[indexToStart].pathsData];
            indexToStart--;
        }
        /** for the same node rows after the clicked node */
        let tempIndex = index+1;
        while (
            (tempIndex > -1) && 
            this.state.tableData[tempIndex] && 
            this.state.tableData[tempIndex].node &&
            (this.state.tableData[tempIndex].node == nodeKey) &&
            this.state.tableData[tempIndex].pathsData
        ) {
            pathsData = [...pathsData, ...this.state.tableData[tempIndex].pathsData];
            tempIndex++;
        }
        this.props.setSelectedRule(nodeKey, selectedRule, isMetadata, null, null, pathsData, mode);
        if(mode === global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK){
            this.props.setViewType(1);
        }  
        if(this.props.propsRedux.bulkEdit.state){
            this.props.setBulkEdit({state: false,
                action: global.CUST_CONSTANTS.CRUD_MODE.EDIT,
                type: global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE,
                from: null,
                to: null,
                responseJson: {},
                keyMapper: {},
                selectedNodeBulk: {},
                ruleResponse:{} });
        }
    }

    removeNode = () => {
        this.onNodeClick(this.state.nodeForDeletion, null, global.CUST_CONSTANTS.CRUD_MODE.DELETE, this.state.nodeIndex);
        this.props.removeIntent(this.state.nodeForDeletion);
        this.props.setRemoveIntentAndItsUtterancesFromUntrainedLuisObj(this.state.nodeForDeletion)
    }

    _onResponseActionClick = (mode, pathsData, isDisabled=false) => {
        global.recordAudit("Mode : " + mode + " for response clicked on Simple View for Pathsdata : " + JSON.stringify(pathsData));
        if (mode === global.CUST_CONSTANTS.CRUD_MODE.DELETE && isDisabled) {
            this.props.setAlert(messages.switchInvalid);
            return true;
        }
        this.props.setResponseAction({
            mode: mode,
            isSimpleView: true,
            selectedId: -1,
            referencePaths: [ ...pathsData ]
        });
        if (mode === global.CUST_CONSTANTS.CRUD_MODE.EDIT) {
            this.props.showResponseModal(true);
        } else if (mode === global.CUST_CONSTANTS.CRUD_MODE.DELETE) {
            confirmAlert(this.confirmDeleteResponseAlert);
        }
    }

    _onTagsChange = (tags) => {
        global.recordAudit("Tags chosen for switch on Simple View. Tags : " + JSON.stringify(tags));
        if (tags === null || ( (typeof tags === "object") && tags.length <=0 )) {
            var newObj = {
                selectedTags: [],
                switchState: 0,
                switchObj: {},
            };
            if (this.state.tableDataBackup.length > 0) {
                newObj['tableData'] = this.state.tableDataBackup;
                newObj['tableDataBackup'] = [];
            }
            this.setState({ ...newObj});
        } else {
            this.setState({
                selectedTags: [ ...tags ],
                switchState: 1,
            });
        }
    }

    _onSwitchSelect = () => {
        global.recordAudit("Switch State change try from " + this.state.switchState);
        if ((this.state.switchState === 1) || (this.state.switchState === 3)) {
            var selectedTagsObj = {};
            this.state.selectedTags.forEach( tagTemp => {
                if (selectedTagsObj[tagTemp.valueObj.tagCollection]) {
                    selectedTagsObj[tagTemp.valueObj.tagCollection][tagTemp.valueObj.tag] = true;
                } else {
                    selectedTagsObj[tagTemp.valueObj.tagCollection] = {
                        [tagTemp.valueObj.tag]: true,
                    };
                }
            });
            var stateData = createTableDataForSwitch(this.props.propsRedux.botResponses, selectedTagsObj, this.props.propsRedux.simpleViewRows);
            if ( (stateData.switchObj) && (Object.keys(stateData.switchObj).length > 0)) {
                this.setState({ ...stateData , switchState: 2 });
                global.recordAudit("Switch objects shown " + JSON.stringify(stateData.switchObj));
            } else {
                global.recordAudit("No alternate response present for switch.");
                this.props.setAlert("No alternate responses present");
            }
        } else if (this.state.switchState === 2) {
            /** switch responses here confirmation and hiding rows */
            this.setState({switchState: 3});
            var newTableData = [];
            Object.keys(this.state.switchObj).forEach (tableDataIndex => {
                if (this.state.switchObj[tableDataIndex].isAlternateChosen) {
                    newTableData.push(this.state.tableData[tableDataIndex]);
                }
            });
            this.setState({
                switchState: 3,
                tableDataBackup: [ ...this.state.tableData ],
                tableData: newTableData,
            })
            global.recordAudit("Switch Ste turned to 3");
        }
    }

    _onCancelClick = () => {
        // this.setState({switchState: 1});
        this._onSwitchSelect();
    }

    _onChangeAlternateClipSelection = (index, isAlternateChosen=true) => {
        var newSwitchObj = { ...this.state.switchObj };
        newSwitchObj[index].isAlternateChosen = isAlternateChosen;
        this.setState({switchObj: {...newSwitchObj}})
        global.recordAudit("Alternate Clip selection changed to index: " + index + ", isAlternateChosen" + isAlternateChosen);
    }

    _switchAlternateClip = () => {
        global.recordAudit("Final switch button clicked.");
        this.state.tableData.forEach( tableRow => {
            if (tableRow.alternateResponseId && tableRow.alternateResponseClip && tableRow.pathsData && tableRow.pathsData.length > 0) {
                var data = getChangedRuleJson(this.props.propsRedux.rulesJson, tableRow.pathsData, global.CUST_CONSTANTS.OTHER_ACTIONS.SWITCH, tableRow.alternateResponseId, this.props.propsRedux.botResponses);

                if (data.newRulesJson) {
                    this.props.setRules(data.newRulesJson);
                }
                if (data.loadersPayloads) {
                    this.props.setLoaders(data.loadersPayloads);
                }
            }
        });
        this.setState({ 
            switchState: 0,
            tableData: this.state.tableDataBackup,
            tableDataBackup: [],
            switchObj: {},
            selectedTags: [],
        });
    }

    formatOptionLabel = ({ valueObj, label }) => (
        <div>
            <Icon name="tint"
                className={ [ classes.SwitchTagOptionColor ].join(' ') } 
                style={ (valueObj.bgColor) ? { color: valueObj.bgColor } : { color: "transparent" } }
            />
            { label }
        </div>
    );

    _onNodeDeleteClick = (e, intent, index) =>{
        this.setState({
            nodeForDeletion: intent,
            nodeIndex: index
        })
        confirmAlert(this.confirmDeleteNodeAlert);
        e.stopPropagation();
    }

    render() {
        
        var hiddenColumns = (this.state.switchState >= 2) ? [] : ["alternateResponseClipTags"];
        return (
            <>
                {
                this.state.isJsonEmpty 
                    ? 
                    <div className="text-danger" style={{marginTop:"25vh",textAlign:"center",paddingLeft:"60px"}}>
                        Rules json has not been configured properly
                    </div>
                    :
                    <Container fluid className={[''].join(' ')}>
                        {/* Code redo */}
                        <div className="d-flex align-items-center mt-3">
                            <label className={ [ classes.SwitchLabel ].join(' ') }>Change Response Clip Via Tag : </label>
                            <SearchableSelect 
                                name="switchTags" 
                                isMulti={true}
                                value={ this.state.selectedTags }
                                options={ this.state.tagsForSwitch }
                                onChange={ (tags) => this._onTagsChange(tags) }
                                className={ [ 'ml-2','basic-multi-select', classes.selectFilter ].join(' ') } 
                                formatOptionLabel={ this.formatOptionLabel }
                            />
                            {
                                this.state.selectedTags &&
                                (this.state.selectedTags.length > 0) &&
                                (this.state.switchState > 0) &&
                                <button 
                                    className={ [ classes.SaveBtn, "ml-2", "btn", "btn-primary", "btn-sm", "assign-button" ].join(' ') } 
                                    onClick={ () => this._onSwitchSelect() }
                                    disabled={ (this.state.switchState === 3) }
                                >
                                    { this.switchStatesObj[this.state.switchState] }
                                </button>
                            }
                        </div>
                        {/* Code redo */}
                        {
                            (this.state.switchState === 3) &&
                            <div className={ [ classes.MessageDiv, 'alert', 'alert-danger' ].join(' ') }>
                                All of the following responses will be changed to the alternate response. Are you sure you want to proceed ?
                                <button className={ [ classes.SaveBtn, "btn", "btn-danger", "btn-sm", "pull-right" ].join(' ') } onClick={ this._onCancelClick }>
                                    Cancel
                                </button>
                                <button className={ [ classes.SaveBtn, "btn", "btn-primary", "btn-sm", "assign-button", "pull-right" ].join(' ') } onClick={ () => this._switchAlternateClip() }>
                                    Yes, Switch response
                                </button>
                            </div>
                        }
                        <br />
                        <br />

                        
                            <SimpleViewTable
                                columns={ this.columns } 
                                tableData={ this.state.tableData }
                                onNodeClick={ this.onNodeClick }
                                hiddenColumns={ hiddenColumns }
                                getMetadata={ getMetadata }
                                _onResponseActionClick={ this._onResponseActionClick }
                                _onNodeDeleteClick={ this._onNodeDeleteClick }
                                switchState={ this.state.switchState }
                                _onChangeAlternateClipSelection={ this._onChangeAlternateClipSelection }
                                switchObj={ this.state.switchObj }
                                loaders={ this.props.propsRedux.loaders }
                            />
                        
                        
                    </Container>
                }
            </>
            
        );
    }
}


/** Defining prop types requirements */
SimpleView.propTypes = {
    propsRedux: PropTypes.shape({
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            // switchOptions: state.rules.switchOptions,
            rulesJson: state.rules.rulesJson,
            botResponses: state.rules.botResponses,
            isChanged: state.rules.isChanged,
            freezeColumnFilter: state.rules.freezeColumnFilter,
            loaders: state.rules.loaders,
            simpleViewRows: state.rules.simpleViewRows,
            bulkEdit: state.rules.bulkEdit,
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setSelectedRule, setViewType, setIsChanged, setResponseAction, showResponseModal, setAlert, removeResponse, getChangedRuleJson, setRules, setFreezeColumnFilter, setDetailedVsSimpleViewIndexesMapper, setLoaders, removeIntent, setSimpleRows, setBulkEdit,setRemoveIntentAndItsUtterancesFromUntrainedLuisObj })(SimpleView);