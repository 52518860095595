/*
 * Component Description : Modal Used to configure entity of block or step
 * Author: Payal Gupta
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";
import Tags from "../../UI/Tags/Tags";


/** Importing custom Styles */
import classes from './ExistingRulesModal.module.css';

import { setShowExistingRulesModal,setRulesAndCallFlowUpdatedState, setCallFlowJson, getResponsesFiltered } from "../../../store/actions/rules/rulesActions";
import { setAlert } from "../../../store/actions/common/commonActions";
import { params } from '../../../data/params';



/** Main Component */
class ExistingRulesModal extends React.Component {
    constructor(props) {
        super(props);
      

        this.CALLFLOW_STEPS = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW;

        this.state = {
            selectedConfig:{

                "value": 1,
                "label": "Entity",
                "isDisabled": false
            },

            entityOptions: null,
            selectedCriteria:null,
            selectedFrom: null,
            selectedTo: null,
            fromOption:null,
            toOption:null,
            selectedEntity:null,
            utterances: null,
            isSettingsEntity:false,
            selectedResponseTextKeyJson: null,
            nodeLevelExistingResponses: [],
            responseClips : [],
            responseSelected:null,
            inputSearchResValue: "",
            keyTimer: null,
            keyDownVal: null,
            selectedParallelStepCriterias:null,
            listItems:null
        }
    }


    formatOptionLabelResponses = ({ value, label, tags }) => (
        <div className={ [ classes.Option ].join(' ') }>
            { label }
            <Tags keyBase={ "response_modal_tag_" + value } tags={ tags } />
        </div>
    );

    onHide = () => {
        global.recordAudit("Entity modal closed");
        this.setState({ 
            selectedCriteria: null,
            selectedEntity:null,
            utterances:null,
            isSettingsEntity:false,
            selectedResponseTextKeyJson: null,
            nodeLevelExistingResponses: [],
            responseClips : [],
            responseSelected:null,
            
        });
        this.props.setShowExistingRulesModal(false, null);
    }

    
    onShow = () => {
        const { entities = []} = this.props.propsRedux; 
        const callFlowJson = this.props.propsRedux.callFlowJson; 
        const newCallFlowJson = (callFlowJson[this.CALLFLOW_STEPS]) ? callFlowJson[this.CALLFLOW_STEPS] : callFlowJson;
        let ruleBlocks = []

        Object.keys(newCallFlowJson).forEach(stepKey=>{
            newCallFlowJson[stepKey].list.forEach(blockObj=>{
                if( newCallFlowJson[stepKey].settings &&
                    newCallFlowJson[stepKey].settings["interruption"]){
                ruleBlocks.push({...blockObj,stepKey:stepKey,interruption:": \" "+ newCallFlowJson[stepKey].settings["interruption"].failureResponse.sequence[0].BRText+" \""})
                if(newCallFlowJson[stepKey].settings["entities"]&&newCallFlowJson[stepKey].settings["entities"].length>0)
                {
                    ruleBlocks.push({stepKey:stepKey,interruption:": \" "+ newCallFlowJson[stepKey].settings["interruption"].failureResponse.sequence[0].BRText+" \"",entities:newCallFlowJson[stepKey].settings["entities"]})

                }
   
                    }

                    else{
                        ruleBlocks.push({...blockObj,stepKey:stepKey,interruption:""})
                        if(newCallFlowJson[stepKey].settings && newCallFlowJson[stepKey].settings["entities"]&&newCallFlowJson[stepKey].settings["entities"].length>0)
                        {
                            ruleBlocks.push({stepKey:stepKey,interruption:"",entities:newCallFlowJson[stepKey].settings["entities"]})

                            
                        }
                    }

            })
            

        })
            let index=0;
        let listItems = (<>{
            ruleBlocks.map(blockObj=>{
              return( <>{blockObj.entities && blockObj.entities.length>0 && blockObj.entities.map(entity=>{
                    index+=1;
            return (

                <ListGroup variant="flush">
             <ListGroup.Item 
             as="li"
            
         >
             <div className="ms-4 me-auto">
    
             <div className={classes.BoldHeading}>Rule: {index}</div>
             </div>
             <div className={classes.TextFont}>
             When user hits {blockObj.node ? "the intent : \" " + blockObj.node +" \" ":"any intent" } on  : " {blockObj.stepKey} " it " {
             (entity.criteria===0 ? "must not hit " : "must hit ")+ "entity : \""+ entity.value
                 } " otherwise it should throw interruption {blockObj.interruption} 
             </div>
        
             <Badge bg="primary" pill>
             
             </Badge>
         </ListGroup.Item>
         </ListGroup>

            );})
    }</>);})}</>)

        this.setState({listItems:listItems})
       
        global.recordAudit("Existing AddOn Rules Opened");
       
    }
  
    /** Adding auto filter functionality */
    // var timer = null;
    clearTimer = () => {
        if (this.state.keyTimer) {
            clearInterval(this.state.keyTimer);
        }
    }

    initTimer = () => {
        this.clearTimer();
        var timer = setTimeout(() => {
            this.props.getResponsesFiltered(this.props.propsRedux.storyId, this.state.keyDownVal, false);
        }, ( params.keyGapTimeForFilter));
        this.setState({keyTimer:timer});
    }

    render() {
        

        var modalButtons = [
            {
                type: 'cancel',
                text: 'Discard',
                variant: 'light',
                onClick: this.onHide,
                
                
            }
            
        ];

        console.log('this.state.utterances',this.state.utterances,'this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]',this.props.propsRedux[this.props.propsRedux.responsesKeyToPick] )

        return (
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.onShow}
                onHide={this.onHide}
                title={this.props.propsOwned.title}
                footerComponents={modalButtons}
                size={"mb"}   
            >
            
            <div className={classes.ExistingRuleFlow }>
            <ListGroup as="ol" numbered>
               {
                 this.state.listItems
               }
            </ListGroup> 
            </div>
            </Modal>
            
        );

    }
}


/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            configureEntity: state.rules.configureEntity,
            statements: state.rules.statements,
            callFlowJson: state.rules.callFlow.callFlowJSON,
            entitiesUtterances: state.rules.entitiesUtterances,
            entities: state.rules.entities,
            botResponses: state.rules.botResponses,
            filteredBotResponses: state.rules.filteredBotResponses,
            responsesKeyToPick: state.rules.botResponsesLoadingVars.responsesKeyToPick,
            botResponsesSyncedAll: state.rules.botResponsesLoadingVars.botResponsesSyncedAll,
            filteringInProgress: state.rules.botResponsesLoadingVars.filteringInProgress,
            storyId: state.story.storyId,
            rulesJson: state.rules.rulesJson,
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setCallFlowJson,setRulesAndCallFlowUpdatedState, setShowExistingRulesModal, setAlert, getResponsesFiltered })(ExistingRulesModal);