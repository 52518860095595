import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import { connect } from "react-redux";

import { setBulkEdit } from '../../../../../store/actions/rules/rulesActions';

import classes from "./IntentResponseGlobal.module.css"


class IntentResponseGlobal extends Component {
    constructor (props) {
        super(props);
        this.state = {checked:false, eventName:null}
    }
    
    handleCheck = (e) => {
        this.setState({ checked: !this.state.checked, eventName: e.target.name });
    }
    
    componentDidUpdate = (prevProps, prevState) => {
            if(prevState.checked !== this.state.checked){
                let selectNode = this.props.propsRedux.bulkEdit.selectedNodeGlobal?this.props.propsRedux.bulkEdit.selectedNodeGlobal:[];
                if(this.state.checked){
                    selectNode.push(this.state.eventName)
                    this.props.setBulkEdit({...this.props.propsRedux.bulkEdit,selectedNodeGlobal:selectNode})
                }else{
                    this.props.setBulkEdit({...this.props.propsRedux.bulkEdit,
                    selectedNodeGlobal: selectNode.filter(x => x !== this.state.eventName)});
                }
                
            }
    }
    
    
    render() {
        return (
          <Table  hover className={ classes.tableBorder }>
            <thead>
              <tr>
                <th colSpan={5}> Intent Rule Response: { this.props.node } </th>
              </tr>
              </thead>
              <tbody>
                <tr>
                    { !this.props.propsRedux.bulkEdit.applyChanges &&
                  <td className={ classes.tableBorder }><input type="checkbox" className={ classes.CheckBox } name={this.props.node} onChange={this.handleCheck} defaultChecked={this.state.checked} disabled={ this.props.enable }/> </td> }
                  <td>{ this.props.value["lhs"]}</td>
                  <td>{ this.props.value["op"]}</td>
                  {Array.isArray(this.props.value["rhs"])? <td>{JSON.stringify(this.props.value["rhs"])}</td>: <td>{ this.props.value["rhs"]}</td>}
                </tr>
              </tbody>
          </Table>
        );
    }
}


const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            bulkEdit: state.rules.bulkEdit,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setBulkEdit })(IntentResponseGlobal);