/*
 * Component Description : Select column filter for simple view
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import SearchableSelect from "react-select";

/** Importing custom Components */
import { setSimpleRows } from "../../../../store/actions/rules/rulesActions";

/** Importing custom Styles */

/** Main Components */
class SelectColumnFilter extends React.Component {
    /**
     * props : {
         column: { filterValue, setFilter, preFilteredRows, id },
     }
     */
    /** Lifecycle methods start */
    constructor (props) {
        super(props);

        var options = SelectColumnFilter.getOptions(props);
        if ( 
            ( props.propsOwned.column.id == "node" ) &&
            ( props.propsRedux.simpleViewRows == global.CUST_CONSTANTS.READY_STATE ) 
        ) {
            props.setSimpleRows(props.propsOwned.rows);
        }

        this.state = {
            options: options,
            filterValue: undefined,
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if ( ( nextProps.propsRedux.isChanged == 1 ) ) {
            var options = SelectColumnFilter.getOptions(nextProps);
            return { 
                options:  options,
                filterValue: null,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if ( 
            ( this.props.propsOwned.column.id == "node" ) &&
            ( this.props.propsRedux.simpleViewRows == global.CUST_CONSTANTS.READY_STATE ) 
        ) {
            this.props.setSimpleRows(this.props.propsOwned.rows);
        }
    }
    /** Lifecycle methods end */

    static getOptions = (propsPassed) => {
        const options = new Set();
        var uniqueKeysObj = {};
        propsPassed.propsOwned.column.preFilteredRows.forEach(row => {
            var labelVal = row.original[propsPassed.propsOwned.column.id];
            if (uniqueKeysObj[labelVal]) {
                return false;
            }
            options.add({ label: labelVal, value: labelVal });
            uniqueKeysObj[labelVal] = true;
        })
        return [...options.values()]
    }

    setFilterValue = (filterChosen = null) => {
        if ( filterChosen && (filterChosen.length > 0)) {
            var filterValues = filterChosen.map(filterTemp => {
                return filterTemp.value;
            });
            this.props.propsOwned.column.setFilter((filterValues.length > 0) ? filterValues : undefined); 
            this.setState({ filterValue: filterChosen });
        } else {
            this.props.propsOwned.column.setFilter(undefined); 
            this.setState({ filterValue: null });
        }
        this.props.setSimpleRows(global.CUST_CONSTANTS.READY_STATE);
    }

    render() {
        return (
            <SearchableSelect 
                value={ this.state.filterValue }
                isMulti={ true }
                isDisabled={ this.props.propsRedux.freezeColumnFilter }
                name="table_fiter" 
                hideSelectedOptions={true} 
                options={ this.state.options } 
                onChange={ e => this.setFilterValue(e) } 
            />
        );
    }
}

/** Defining prop types requirements */
SelectColumnFilter.propTypes = {
    propsRedux: PropTypes.shape({
        freezeColumnFilter: PropTypes.bool.isRequired,
        isChanged: PropTypes.number.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            freezeColumnFilter: state.rules.freezeColumnFilter,
            isChanged: state.rules.isChanged,
            simpleViewRows: state.rules.simpleViewRows,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setSimpleRows })(SelectColumnFilter);