
/** code for local testing start */

/** code for local testing end */

var baseUrl = "https://" + window.location.host;
if (baseUrl === "https://localhost:3000") {
    baseUrl = "https://meghana.zenarate.com";
}

function getApiUrl(type='story'){
    var apiUrl = baseUrl;
    console.log(apiUrl);
    
    if ((baseUrl === "https://localhost:3000") || (baseUrl === "https://meghana.zenarate.com")) {
        apiUrl = "http://127.0.0.1:5000";
    if (type === 'ai') {
            apiUrl = "http://127.0.0.1:5003";
    }}
    
    return apiUrl
}

const apiUrlSubString = '/apis/api/v1/';

export const params = {
    test: "done",
    callFlowSeparator: "::",
    baseUrl: baseUrl,
    phpUrlBase: baseUrl + "/index.php?r=",
    /** interval in minutes */
    tokenRefreshInterval: 60,
    pythonApiUrl: getApiUrl('story') + apiUrlSubString,
    pythonAiApiUrl: getApiUrl('ai'),
    pythonNewUiApiUrl: getApiUrl('new-ui-rules'),
    platformApiUrl : getApiUrl('platform'),
    currentRulesJsonVersion: "2.0",
    currentCallFlowJsonVersion: "1.1",
    /** time to autosave the rules in seconds */
    autosaveTime: 5,
    /** Automatically sync the call flow according to rules
     * 1 : On Page Load
     * 2 : On Every save
     */
    autoSync: [1,2],
    /** Gap allowed without key press to apply filter ( in ms )*/
    keyGapTimeForFilter: 1000,

    luisVersions: {
        defaultVersion: "0.1",
        newTemp: "temp.1"
    },
    luisTrainStatusCheckInterval: 5,
};