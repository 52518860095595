/*
 * Component Description :
 * Author: Piyush
 */
/** Importing pre defined Libraries/Components */
import { Icon } from "@iconify/react";
import React, { useState ,useRef} from "react";
import { Link } from "react-router-dom";
import {
    setAddUtteranceInUntrainedLuisObj,
    setRemoveUtteranceInUntrainedLuisObj,
    setCallFlowJson,
    setRulesAndCallFlowUpdatedState,
    setSelectedBlockCallInfo, setShowUtteranceSideBar, setDeleteSampleUtterance, setAddIntentInUntrainedLuisObj, setDeleteUtteranceEntities,
} from "../../../../store/actions/rules/rulesActions";
import { confirmAlert } from 'react-confirm-alert';
import { getUntrainedUtterancesOfAnIntent, getUtterancesForModal as getSampleUtterances} from "../../../../utils/rulesUtils";
import PlayIcon from "../../../UI/PlayIcon/PlayIcon";

/** Importing custom Components */
import SearchableSelect from "../../../UI/SearchableSelect/SearchableSelect";

/** Importing custom Styles */
import { Icon as FaIcon } from "react-fa";
import { useDispatch, useSelector } from "react-redux";
import { addSpacesToSpecialCharacters, getRandomID, isStrEmptyOrSpaces } from "../../../../utils/utils";
import classes from "./InfoSideBar.module.css";
import { useEffect } from "react";
import { messages } from "../../../../data/strings";
import { setAlert } from "../../../../store/actions/common/commonActions";
import EntityPopover from "./EntityPopover/EntityPopover";

const iconStylesActive = ["action-btn", "d-flex", "align-items-center", "justify-content-center", 'ml-2','cursor-pointer'].join(" ")
const iconStylesInActive = ["action-btn disabled", "d-flex", "align-items-center", "justify-content-center", 'ml-2'].join(" ")

let initialUtteranceState = {
    name: null,
    id: null,
    relatedIntent: null,
    intentUtterances: null,
    selectionError: null,
}

/** Main Components */
    const InfoSideBar = (props) => {
    const { show } = props;
    const dispatch = useDispatch();
    const {selectedBlockCallInfo,untrainedLuisObj,showUtteranceSideBar,sampleUtterances,intents:allIntents} = useSelector((state) => state.rules);
    const {botResponse,node: intent,utteranceText,block,currentSampleUtterances,currentUntrainedUtterances,entities} = selectedBlockCallInfo;
    const {ADD:{utterances:untrainedUtterancesInAddState,intents:untrainedIntentsInAddState},DELETE:{utterances:untrainedUtterancesinDeleteState}} = untrainedLuisObj.data
    const {isActionEnabled} = untrainedLuisObj
    const callFlowJson = useSelector(  (state) => state.rules.callFlow.callFlowJSON );
    const statements = useSelector(  (state) => state.rules.statements );

    const [isEditUtterance, setIsEditUtterance] = useState(false);
    const [untrainedUtteranceText,setUntrainedUtteranceText] = useState('')
    const [utterance ,setUtterance] = useState(initialUtteranceState)

    const [selectedUtteranceTextForEntity,setSelectedUtteranceTextForEntity] = useState('')
    const [selectedUtterance,setSelectedUtterance] = useState(null)
    const [entityPopoverShow,setEntityPopoverShow] = useState(false)

    const handleCloseInfoSidebar = () => {
        dispatch(setShowUtteranceSideBar(false, {}));
        setIsEditUtterance(false)
        setUntrainedUtteranceText('')      
    };

    const onDefaultUtteranceChanges = (utterance) => {
        var newCallFlowJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;
            global.recordAudit("Utterance selected : " + utterance.name);
            /** Call Flow version 1.1 and old support */
            const statementBlockStepKey = block.step;
            const statementBlockIndexKey = block.index;   

            console.log({statementBlockStepKey},{statementBlockIndexKey})    
            /* If found sample utterances in callflow step then change to new else configure sample utterances in step */
            if (newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances) {
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0].utteranceText = utterance.name;
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]['utteranceId'] = utterance.id;
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]['relatedIntent'] = utterance.relatedIntent;
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]['blockId'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].id;

                if(newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']){
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance']?(newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'].utteranceText!=""?newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance']:newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]):newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0];
                    if (newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'].blockId==newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].id&&newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'].utteranceId!=newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0].utteranceId){
                        newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0];
                    }
                }else{
                    newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings'] = {}
                    newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance']?newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance']:{blockId:newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].id,...newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]};
                }
            } else {
                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey]['sampleUtterances'] = [{ blockId:newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].id,utteranceText: this.state.newUtteranceName, utteranceId: this.state.newUtteranceId, relatedIntent: this.state.relatedIntent }];

                newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']?{ ...newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings'] }:{ defaultUtterance: {blockId:newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].id,...newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0]} };
                if (newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'].utteranceText==""){
                    newCallFlowJson[(statements ? statementBlockStepKey : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(statements ? statementBlockStepKey : 'step0')].list[statementBlockIndexKey].sampleUtterances[0];
                }
            }
            setRulesAndCallFlowUpdatedState(2);
            dispatch( setCallFlowJson(newCallFlowJson,true,true));
            dispatch(setSelectedBlockCallInfo({utteranceText:utterance.name ? utterance.name : messages.noUtteranceTextIsConfigured }))  
    }  

    const onAddUntrainedUttrance = () => {
        
        if (isStrEmptyOrSpaces(untrainedUtteranceText)) {
            dispatch(setAlert('empty spaces are not allowed'))
            return
        }
        
    if(untrainedUtteranceText) {
        let removedExtraSpacesFromUtterance = untrainedUtteranceText.trim().split(' ').filter(item => item.length > 0).join(' ');
        let formattedTextData = addSpacesToSpecialCharacters(removedExtraSpacesFromUtterance)
        if (formattedTextData.length > 498) {
            dispatch(setAlert('max limit 498 characters'))
            return
        }
        const uniqueId = getRandomID(10);
        const untrainedUtteranceObj =  {
            tempId: uniqueId,
            text: formattedTextData,
            intent: intent,
        }
        dispatch(setAddUtteranceInUntrainedLuisObj(untrainedUtteranceObj))
        setUntrainedUtteranceText('')
        global.recordAudit("User added an untrained utterance");
        
    }
    }

    const handleUtteranceSelect = (e) => {
       if(e && e.value){
        const {utteranceText,utteranceId,relatedIntent} = e.value
        setUtterance({
            ...utterance,name:utteranceText,id:utteranceId,relatedIntent:relatedIntent
        })
       }
    };

    const handleUtteranceInput = (e) => {
        setUntrainedUtteranceText(e.target.value)
    };

    const handleSampleUtteranceEdit = () => {
        setIsEditUtterance(true)
    };

    const handleSampleUtteranceSave = () => {
        if(utterance.name){
            onDefaultUtteranceChanges(utterance)
            setUtterance({
                ...utterance,name:"",id:"",relatedIntent:null
            })
        }
        setIsEditUtterance(false)
       

    };

    const handleUntrainedUtteranceSave = () => {
        
       
        //check if the currentintent exists in untrained data and in allIntents, if it doesn't exist in both ,added the intent to untrained data
        if(intent){
          const allIntentNames = Object.keys(allIntents)
          const isPresentInAllIntentsList =  allIntentNames.includes(intent)
          const isPresentInUntrainedData = untrainedIntentsInAddState.map(intent=> intent.name).includes(intent)
          if(!isPresentInAllIntentsList && !isPresentInUntrainedData){
            dispatch(setAddIntentInUntrainedLuisObj(intent))
          }          
        }
        
        if(untrainedUtteranceText){
            onAddUntrainedUttrance()
        }
        
    };

    const handleConfirmDialog = ({title='Remove',message='Do you want to proceed with this action?',onSuccess=()=>{},onDisable=()=>{}}) => {
         confirmAlert({ title: title, message: message,buttons: [
            { label: 'Remove',onClick: ()=> onSuccess()},
            { label: 'Cancel', onClick: () => onDisable() }
        ] });
    }

    const isLastUtteranceBeingDeleted = ()=> {     
        //if  one trained AND one untrained utterance is present
        if (currentSampleUtterances.length === 1 && currentUntrainedUtterances.length === 1) {
            return true
        } //if 1 sample OR 1 untrained ,allow deletion
        else if ((currentSampleUtterances.length === 1 && currentUntrainedUtterances.length === 0) || (currentSampleUtterances.length === 0 && currentUntrainedUtterances.length === 1)) {
            return false
        } //>1 sample or untrained utterances
        else {
            return true
        }
    }

    const deleteUntrainedUtterance = (untrainedUtterance) => {
        global.recordAudit("User clicked on sample untrained utterance delete");
        if(!isLastUtteranceBeingDeleted()){
            dispatch(setAlert(messages.needAtleastOneUtterance + " " + "Please add another utterance in order to delete the current one"))
            return
        }
        const handleRemoveUntrainedUtterance = ()=> {
            dispatch(setRemoveUtteranceInUntrainedLuisObj(untrainedUtterance.value.utteranceId))
            dispatch(setDeleteUtteranceEntities({currentUtterance:untrainedUtterance,isCurrentUtteranceTrained:false}))

        }

        handleConfirmDialog({message:'Are you sure about removing this untrained utterance?',onSuccess:handleRemoveUntrainedUtterance})
    }

    const deleteSampleUtterance = (sampleUtterance) => {
        global.recordAudit("User clicked on sample trained utterance delete");
        if(!isLastUtteranceBeingDeleted()){
            dispatch(setAlert(messages.needAtleastOneUtterance + " " + "Please add another utterance in order to delete the current one"))
            return
        }
        const filteredUtterances = currentSampleUtterances.filter(item => item.value.utteranceId != sampleUtterance.value.utteranceId)
        const handleDeleteSampleUtterance = () => {
            dispatch(setDeleteSampleUtterance(sampleUtterance))
            dispatch(setSelectedBlockCallInfo({currentSampleUtterances:filteredUtterances}))
            dispatch(setDeleteUtteranceEntities({currentUtterance:sampleUtterance,isCurrentUtteranceTrained:true}))
        } 
        handleConfirmDialog({message:'Are you sure about removing this sample utterance?',onSuccess:handleDeleteSampleUtterance})
       
    }

    const handleUtternaceInputOnEnter = e => {
        if (e.key === 'Enter') {
            e.preventDefault()
          handleUntrainedUtteranceSave()
        }
    }

    const handleConfirmDialogForLuis = ({title='',message='Do you want to proceed with this action?',buttonLabel='',onSuccess=()=>{}}) => { 
        confirmAlert({
           title: title,message: message,buttons: [{label: buttonLabel, onClick: () => onSuccess()}],
           closeOnEscape: false,
           closeOnClickOutside: false,
         });
   }

   const handleUtteranceSelectionAndPopoverOpen = (utterance) => {
    setSelectedUtterance(utterance)
    // handleUtteranceTextSelection()
    setEntityPopoverShow(true);
  };
 
 useEffect(()=> {
    const formattedSampleUtterances= getSampleUtterances(sampleUtterances);
    let currentSampleUtterances = []
    let currentUntrainedUtterances = []
    let formattedSampleUtteranceOptions = [];

    //collect the sample(trained) utterances of the current Intent
    formattedSampleUtterances.forEach((utteranceItem, _) => {
       
      if (utteranceItem.intent === (intent && intent.trim())) {
        formattedSampleUtteranceOptions.push(utteranceItem.utterance);
      }
    });

    //remove the default utterance from formattedsampleutterance
    const filteredSampleUtterances = formattedSampleUtteranceOptions.filter(item => item.value.utteranceText !== utteranceText)

    // by default all current utterances are sample utterances  
    currentSampleUtterances = filteredSampleUtterances

    // if the utterance exists in delete array of the untrainedLuisObject state, remove those utterances from the currentUtterance list
    if(untrainedUtterancesinDeleteState.length > 0){
        currentSampleUtterances = formattedSampleUtteranceOptions.filter(item => !untrainedUtterancesinDeleteState.some(({utteranceId}) => item.value.utteranceId === utteranceId));
    }

     //map the untrainedUtterances of current Intent
     if (untrainedUtterancesInAddState && untrainedUtterancesInAddState.length) {
         currentUntrainedUtterances = getUntrainedUtterancesOfAnIntent(untrainedUtterancesInAddState, intent)
     }

    dispatch(setSelectedBlockCallInfo({currentSampleUtterances,currentUntrainedUtterances}))
    
 },[showUtteranceSideBar,untrainedLuisObj,utteranceText])

    return (
        show && (
            <div
                id="CallFlowInfoToggle"
                className={["rightToggle", classes.CallFlowInfoToggle].join(" ")}
            >
                <div className="bg-light font-18 p-2 blue font-weight-bold">
                    Call Flow Information
                    <Link className="float-right" onClick={handleCloseInfoSidebar}>
                        <Icon icon="carbon:close" />
                    </Link>
                </div>
                <div className={`p-2 ${classes.sidebarBody}`}>
                    <div className="row align-items-center mb-2 border-0 p-2 rounded m-0 pos-rel">
                        <div className="col-md-12 p-0 w-100 bg-light p-1 mb-2 font-16 font-weight-bold">
                            Intent
                        </div>
                        <div className="font-14">{intent ? intent : ""}</div>
                    </div>
                   
                    <div className="sampleUtteranceContainer">
                        <div className="row align-items-center gap-2 mb-2 border-0 p-2 rounded m-0 pos-rel">
                        <div className="col-md-12 p-0 w-100 bg-light p-1 mb-2 font-16 font-weight-bold">
                            Sample Utterance
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <div className="font-14">
                                {utteranceText ? utteranceText : messages.noUtteranceTextIsConfigured}
                            </div>
                            <div className='d-flex mt-2'>
                            {
                                !isEditUtterance ? <FaIcon
                                    data-tip={messages.editSampleUtterance}
                                    data-class="tooltip_cust"
                                    data-place="top"
                                    name={'edit'}
                                    className={iconStylesActive}
                                    onClick={handleSampleUtteranceEdit}
                                /> : <FaIcon
                                    data-tip={messages.saveSampleUtterance}
                                    data-class="tooltip_cust"
                                    data-place="top"
                                    name={'save'}
                                    className={iconStylesActive}
                                    onClick={handleSampleUtteranceSave}
                                    
                                />
                            }
                            </div>
                        </div>
                            
                    </div>
                        {
                            isEditUtterance && <div>
                                <SearchableSelect isClearable name="Utterances" placeholder='Select Sample Utterance' options={currentSampleUtterances} onChange={handleUtteranceSelect}/>
                            </div>
                        }
                        
                            <div className="mt-10">
                                <textarea disabled={!isActionEnabled} rows={1} value={untrainedUtteranceText} className={(classes.addUtteranceTextInput)+" "+ (!isActionEnabled && classes.cursorDisabled  )} buttonType="text" id="utterance-text" aria-describedby="utterance" placeholder="Enter Utterance To Train" onChange={handleUtteranceInput} onKeyPress={handleUtternaceInputOnEnter} />
                            </div>
                        
                        {
                        (currentUntrainedUtterances.length > 0 || currentSampleUtterances.length > 0) && <div className={classes.tableContainer}>
                                <table className={classes.customTable}>
                                    <thead>
                                        <tr>
                                            <th>Utterance</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentUntrainedUtterances.length > 0 && currentUntrainedUtterances.map(item => <TableUtteranceRow  key={item.value.utteranceId} onDeleteUtterance={deleteUntrainedUtterance} utterance={item} handleUtteranceSelectionAndPopoverOpen={handleUtteranceSelectionAndPopoverOpen}/>)
                                        }
                                        {
                                            currentSampleUtterances.length > 0 && currentSampleUtterances.map(item => <TableUtteranceRow  key={item.value.utteranceId} onDeleteUtterance={deleteSampleUtterance} utterance={item} handleUtteranceSelectionAndPopoverOpen={handleUtteranceSelectionAndPopoverOpen} />)
                                        }
                                    </tbody>
                                </table>
                        </div>
                        }
                    
                    </div>

                    <div className="row align-items-center mb-2 border-0 p-2 rounded m-0 pos-rel">
                        <div className="col-md-12 p-0 w-100 bg-light p-1 mb-2 font-16 font-weight-bold">
                            Bot Response
                        </div>
                        <div className="d-flex flex-column">
                        {botResponse && botResponse.BRText  && (
                            <div className="font-14">
                                {botResponse.BRText ? botResponse.BRText :  ""}
                            </div>
                        )}
                        {botResponse && botResponse.BRClip && (
                            <div className="font-14">
                                <div
                                    className={["d-flex align-items-center", "mt-10"].join(" ")}
                                    style={{ marginTop: "10px" }}
                                >
                                    <span>
                                        <PlayIcon
                                            src={botResponse.BRClip}
                                            uid={"side_bar_" + intent}
                                        />
                                    </span>
                                    {/* To be changed to modular css */}
                                    <span className={["ml-2", classes.SideBar1].join(" ")}>
                                        {botResponse.BRClip
                                            ? botResponse.BRClip
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>

                    <div className="row align-items-center mb-2 border-0 p-2 rounded m-0 pos-rel">
                        <div className="col-md-12 p-0 w-100 bg-light p-1 mb-2 font-16 font-weight-bold">
                            Configured Rules
                        </div>
 
                        {entities && entities.length
                            ? entities.map((entity, index) => (
                                <div
                                    key={entity.value}
                                    className={`d-flex flex-column w-100 font-14 ${classes.rulesContainer}`}
                                >
                                    <p className="mb-1">
                                        Rule:
                                        {entity.criteria === 0? "Should NOT have": "Should have"}
                                    </p>
                                    <p className="mb-1">Entity: {entity.value}</p>
                                    
                                    {entity.interruption && entity.interruption.BRText && (
                                        <p className="mb-1">
                                            Interruption: {entity.interruption.BRText}
                                        </p>
                                    )}

                                    {entity.interruption && entity.interruption.BRClip && (
                                        <div
                                            className={["d-flex align-items-center", "mb-1"].join(
                                                " "
                                            )}
                                        >
                                            <span>
                                                <PlayIcon
                                                    src={entity.interruption.BRClip}
                                                    uid={"side_bar_" + entity.interruption.id}
                                                />
                                            </span>
                                            <span className={["ml-2", classes.SideBar1].join(" ")}>
                                                {entity.interruption.BRClip}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )): ''}
                    </div>
                </div>

              {
                selectedUtterance && selectedUtterance.label &&   <EntityPopover
                show={entityPopoverShow}
                setShow={setEntityPopoverShow}
                selectedUtterance={selectedUtterance} 
                setSelectedUtteranceTextForEntity={setSelectedUtteranceTextForEntity} 
                selectedUtteranceTextForEntity={selectedUtteranceTextForEntity}
             />
              }
            </div>
        )
    );
};


/** Exporting main component */
export default InfoSideBar;


function TableUtteranceRow({ onDeleteUtterance, utterance,handleUtteranceSelectionAndPopoverOpen }) {
    const isUntrained = utterance.isUntrained;
    const isActionEnabled = useSelector(state => state.rules.untrainedLuisObj.isActionEnabled)

    return (<tr>
        <td className={classes.utteranceContent}>
           <div
                onClick={() => {isActionEnabled && handleUtteranceSelectionAndPopoverOpen(utterance)}} 
                className={classes.utteranceText + `${isUntrained && ' text-secondary'}`} 
                data-tip={utterance.label} 
                data-class="tooltip_cust" 
                data-place="top"
            >
                <span id={utterance.label}> {utterance.label}</span>
            </div>
        </td>

        <td className={classes.utteranceAction}>
            <FaIcon 
                data-tip={isUntrained ? messages.deleteUntrainedUtterance : messages.deleteSampleUtterance} data-class="tooltip_cust" data-place="top" name={'trash'} 
                onClick={() => {isActionEnabled && onDeleteUtterance(utterance)}} 
                className={isActionEnabled  ? iconStylesActive : iconStylesInActive} 
             
            /></td>
    </tr>);
    
}
    



