/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Icon } from "react-fa";
/** Importing custom Components */

/** Importing custom Styles */
import classes from './LoadingIcon.module.css';

/** Main Components */
const LoadingIcon = props => {
    return (
        <Icon name="spinner" className={ [ "fa-spin", props.className ].join(' ') } />
    );
}

/** Exporting main component */
export default LoadingIcon;