/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

/** Importing custom Components */
import AddOnTagRulesRow from "../AddOnTagRulesRow/AddOnTagRulesRow";
import { getIterationKeyFromObj, getResponseKeyFromObj } from '../../../../../utils/rulesUtils';

/** Importing custom Styles */

/** Main Components */
const AddOnRulesTable = props => {


    const ruleResponses = (props.ruleResponses) ? props.ruleResponses : null;

    return (
        <Table striped bordered hover className={ props.className }>
            <thead>
                <tr>
                    <th colSpan={5} > Add On Rules{props.bulkEdit.state && ": " + props.node}</th>
                </tr>
                <tr>
                    {
                        props.bulkEdit.state && 
                        !props.bulkEdit.applyChanges && 
                        <th></th>
                    }
                    <th>Rule Key</th>
                    <th>Priority</th>
                    <th>Response Type</th>
                    <th>Iteration Type</th>
                    <th>Response (s)</th>
                </tr>
            </thead>
            <tbody>
                {
                    Object.keys(ruleResponses).map( (ruleKey, index) => {
                        /** Change the value of priority to show from key (which is actually not being used in rule-engine) to the sequence number of rule (which actually is used as priority in rule-enging). */
                        var responsePriority = (index + 1);
                        if (ruleResponses[ruleKey]) {
                            // var responsePriority = ruleResponses[ruleKey]["priority"] || 1;
                            var responseTypeKey = getResponseKeyFromObj(ruleResponses[ruleKey]);
                            var iterationType = getIterationKeyFromObj(ruleResponses[ruleKey][responseTypeKey]);
                            var responses = (ruleResponses[ruleKey][responseTypeKey] && ruleResponses[ruleKey][responseTypeKey][iterationType]) ? ruleResponses[ruleKey][responseTypeKey][iterationType] : null;
                        } else {
                            // var responsePriority = 1;
                            var responseTypeKey = global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE;
                            var iterationType = global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE;
                            var responses = null;
                        }
                        return (
                            <AddOnTagRulesRow
                                key = { "add_on_" + ruleKey }
                                ruleKey = { ruleKey }
                                responsePriority = { responsePriority }
                                onInputValueChange = { props.onInputValueChange }
                                iterationType = { iterationType }
                                responseKey = { responseTypeKey }
                                responses = { responses }
                                showResponseModal = { props.showResponseModal }
                                selectedNode={ props.selectedNode }
                                storyId={ props.storyId }
                                bulkEdit= { props.bulkEdit }
                                node= { props.node }
                                enable= { props.enable }
                                isGlobal={ props.isGlobal }
                                />
                        )
                    }) 
                }
            </tbody>
        </Table>
    );
}

/** Defining prop types requirements */
AddOnRulesTable.propTypes = {
    ruleResponses: PropTypes.object.isRequired,
    onInputValueChange: PropTypes.func.isRequired,
    showResponseModal: PropTypes.func.isRequired,
};

/** Exporting main component */
export default AddOnRulesTable;