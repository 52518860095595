export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_ALERT = 'SET_ALERT';

/** Action types for user change */
export const USER_CHANGE = 'USER_CHANGE';

/** Action types for initialising THUMBNAILS */
export const WORKBENCH_CHANGE = 'WORKBENCH_CHANGE';

/** Action types for todolist on top right */
export const TODOLIST_GET = 'TODOLIST_GET';
export const TODOLIST_CHANGEVIEW = 'TODOLIST_CHANGEVIEW';

/** Action types for workbench conversations on top right */
export const CONVERSATION_INIT = 'CONVERSATION_INIT';
export const CONVERSATION_PUT_STITCHEDCLIP = 'CONVERSATION_PUT_STITCHEDCLIP';
export const CONVERSATION_PLAY = 'CONVERSATION_PLAY';
export const CONVERSATION_PAUSE = 'CONVERSATION_PAUSE';
export const CONVERSATION_CHANGE_SRC = 'CONVERSATION_CHANGE_SRC';
export const CONVERSATION_SET_ACTION_ITEMS = 'CONVERSATION_SET_ACTION_ITEMS';

/** Action types for initialising workbench */
export const THUMBNAILS_INIT = 'THUMBNAILS_INIT';
export const THUMBNAILS_CHANGE_TAB = 'THUMBNAILS_CHANGE_TAB';

/** Action types for guide notes and my notes on workbench */
export const NOTES_SET = 'NOTES_SET';
export const NOTES_SAVING = 'NOTES_SAVING';

/** Action types for guide notes and my notes on workbench */
export const RESOURCES_INIT = 'RESOURCES_INIT';
export const RESOURCES_ADD = 'RESOURCES_ADD';
export const RESOURCES_MODAL_OPEN = 'RESOURCES_MODAL_OPEN';
export const RESOURCES_MODAL_CLOSE = 'RESOURCES_MODAL_CLOSE';
export const RESOURCES_SET_URL = 'RESOURCES_SET_URL';
export const RESOURCES_EDIT = 'RESOURCES_EDIT';
export const RESOURCES_DELETE = 'RESOURCES_DELETE';

/** Action types for guide notes and my notes on workbench */
export const FEEDBACK_CHANGE = 'FEEDBACK_CHANGE';
export const FEEDBACK_ADD_COMMENT = 'FEEDBACK_ADD_COMMENT';
export const FEEDBACK_EDIT_COMMENT = 'FEEDBACK_EDIT_COMMENT';
export const FEEDBACK_REPLY = 'FEEDBACK_REPLY';

/** Action types for share modal on workbench */
export const SHARE_INIT = 'SHARE_INIT';
export const SHARE_SET_SHAREWITH = 'SHARE_SET_SHAREWITH';
export const SHARE_DELETE_SHAREWITH = 'SHARE_DELETE_SHAREWITH';

/** Coaching action types */
export const COACHING_SET = 'COACHING_SET';
export const COACHING_INIT = 'COACHING_INIT';
export const COACHING_SET_CURRENT_COACH = 'COACHING_SET_CURRENT_COACH';

/** Story action types */
export const SET_STORY_VARS = 'SET_STORY_VARS';

/** Rules action types */
// Todo : unuse these and use particular methods start
export const SET_RULES_VARS = 'SET_RULES_VARS';
export const SET_RULES_VARS_MULTIPLE = 'SET_RULES_VARS_MULTIPLE';
export const SET_RULES_EDITOR_VARS = 'SET_RULES_EDITOR_VARS';
export const SET_BULK_EDIT = 'SET_BULK_EDIT'; 

// Todo : unuse these and use particular methods end
export const SET_ONGOING_AXIOS_REQUEST = 'SET_ONGOING_AXIOS_REQUEST';

export const SET_SELECTION_CHANGE = 'SET_SELECTION_CHANGE';
export const SET_RESPONSE_MODAL_SHOW = 'SET_RESPONSE_MODAL_SHOW';
export const SET_INTENT_MODAL_SHOW = 'SET_INTENT_MODAL_SHOW';
export const SET_RULES_CHANGED = 'SET_RULES_CHANGED'; 
export const SET_RESPONSE_ACTION = 'SET_RESPONSE_ACTION'; 
export const SET_SELECTED_JSON = 'SET_SELECTED_JSON'; 
export const FREEZE_COLUMN_FILTER = 'FREEZE_COLUMN_FILTER'; 
export const SET_LOADERS = 'SET_LOADERS'; 
export const SET_SV_ROWS = 'SET_SV_ROWS'; 
export const SET_JSON_NAME = 'SET_JSON_NAME';
export const SET_SAVE_AS_SELECTED = 'SET_SAVE_AS_SELECTED';

export const SET_BOT_RESPONSES = 'SET_BOT_RESPONSES'; 
export const SET_FILTERED_RESPONSES = 'SET_FILTERED_RESPONSES'; 
export const ADD_FILTERED_RESPONSES = 'ADD_FILTERED_RESPONSES'; 
export const SET_FILTERING_RESPONSES_IN_PROGRESS = 'SET_FILTERING_RESPONSES_IN_PROGRESS'; 
export const EMPTY_FILTERED_RESPONSES = 'EMPTY_FILTERED_RESPONSES';

/** Story Call flow */
export const SET_CALL_FLOW_JSON = 'SET_CALL_FLOW_JSON';
export const CALL_FLOW_UPDATED = 'CALL_FLOW_UPDATED'; 
/** Story Call flow and Rules */
export const RULES_CALL_FLOW_UPDATED = 'RULES_CALL_FLOW_UPDATED'; 
export const SET_CALL_FLOW_MAPPINGS = 'SET_CALL_FLOW_MAPPINGS'; 
export const SET_CALL_FLOW_ADDING_BLOCK = 'SET_CALL_FLOW_ADDING_BLOCK'; 
export const SET_CALL_FLOW_STEP_SETTINGS = 'SET_CALL_FLOW_STEP_SETTINGS'; 
export const NODE_RESPONSE_BLOCK_SHOW = 'NODE_RESPONSE_BLOCK_SHOW'; 
export const SET_WARN_OVERWRITING = 'SET_WARN_OVERWRITING'; 
export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES'; 
export const SET_CALLFLOW_DRAFT = 'SET_CALLFLOW_DRAFT'; 
export const SET_STEP_COUNTER = 'SET_STEP_COUNTER';
export const HIDE_START_TRAINING_BUTTON = 'HIDE_START_TRAINING_BUTTON';

/* Utterance Modal */

export const SET_UTTERANCE_MODAL_SHOW = 'SET_UTTERANCE_MODAL_SHOW';
export const SET_UTTERANCE_SIDE_BAR = 'SET_UTTERANCE_SIDE_BAR';
export const SET_SAMPLE_UTTERANCES = 'SET_SAMPLE_UTTERANCES';
export const SET_NEW_NODE_INFO_SIDE_BAR_NEW = 'SET_NEW_NODE_INFO_SIDE_BAR_NEW';
export const SET_IS_NEW_NODE_CREATED = 'SET_IS_NEW_NODE_CREATED'

/* Entity Modal */
export const SET_ENTITY_MODAL_SHOW = 'SET_ENTITY_MODAL_SHOW';
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_ENTITIES_UTTERANCES = 'SET_ENTITIES_UTTERANCES';
/* Test Plans */

export const SET_TESTPLAN_LOADER = 'SET_TESTPLAN_LOADER';
export const SET_SHOW_TESTPLANS = 'SET_SHOW_TESTPLANS';
export const SET_TESTPLANS = 'SET_TESTPLANS';
export const SET_SELECT_TESTPLAN = 'SET_SELECT_TESTPLAN';

export const SET_TESTREPORTS = 'SET_TESTREPORTS';
export const SET_SELECT_TESTREPORT = 'SET_SELECT_TESTREPORT';
export const SET_ALL_TESTPLANS = 'SET_ALL_TESTPLANS';
export const SET_SAVED_TESTPLAN_DATA= 'SET_SAVED_TESTPLAN_DATA';
export const SET_TESTPLANSDATA_FROM_SAVED = 'SET_TESTPLANSDATA_FROM_SAVED';


export const TESTPLAN_RESET = 'TESTPLAN_RESET';

/* Luis App */
export const SET_APP_ID = 'SET_APP_ID';
export const SET_APP_DB_ID = 'SET_APP_DB_ID';
export const SET_APP_NAME = 'SET_APP_NAME';
export const SET_MODULE_SESSION_ID = 'SET_MODULE_SESSION_ID';
export const APP_SYNCING = 'APP_SYNCING';
export const SET_TOTAL_COUNTS = 'SET_TOTAL_COUNTS';
export const INCREMENT_SYNCING_STEP = 'INCREMENT_SYNCING_STEP';
export const INCREMENT_ONGOING_STEP = 'INCREMENT_ONGOING_STEP';
export const RESET_LUIS_APP_COUNTS = 'RESET_LUIS_APP_COUNTS';
export const SYNCING_ERROR_OCCURED = 'SYNCING_ERROR_OCCURED';
export const IS_IN_SYNC = 'IS_IN_SYNC';
export const FETCH_PERCENTAGE = 'FETCH_PERCENTAGE';
export const IS_APP_IN_SYNC = 'IS_APP_IN_SYNC'


/* Accordian State */
export const SET_ACCORDION_STATE = 'SET_ACCORDION_STATE';

/* Steps Sequence */
export const SET_STEP_SEQUENCE = 'SET_STEP_SEQUENCE'



/* Existing Rules Modal */
export const SET_EXISTING_RULES_MODAL_SHOW = 'SET_EXISTING_RULES_MODAL_SHOW';

/* Info side bar */
export const SET_SELECTED_BLOCK_CALL_INFO = 'SET_SELECTED_BLOCK_CALL_INFO'
export const SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE = 'SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE'
export const SET_UNTRAINED_LUIS_OBJ_EDIT_UTTERANCE = 'SET_UNTRAINED_LUIS_OBJ_EDIT_UTTERANCE'
export const SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE = 'SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE'
export const SET_DELETE_SAMPLE_UTTERANCE = 'SET_DELETE_SAMPLE_UTTERANCE'
export const SET_UNTRAINED_LUIS_OBJ_ADD_INTENT = 'SET_UNTRAINED_LUIS_OBJ_ADD_INTENT'
export const SET_UNTRAINED_LUIS_OBJ_STATE = 'SET_UNTRAINED_LUIS_OBJ_STATE'
export const CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE = 'CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE'
export const RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE = 'RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE'
export const IS_INCOMING_LUIS_DATA_EXIST = 'IS_INCOMING_LUIS_DATA_EXIST'
export const IS_STORY_ID_MATCH_IN_LUIS_DATA = 'IS_STORY_ID_MATCH_IN_LUIS_DATA'
export const RESET_UNTRAINED_LUIS_OBJ_STATE_DATA = 'RESET_UNTRAINED_LUIS_OBJ_STATE_DATA'
export const SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD = 'SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD'
export const REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE = 'REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE'
export const SET_UNTRAINED_LUIS_OBJ_STORY_TITLE = 'SET_UNTRAINED_LUIS_OBJ_STORY_TITLE'
export const SET_UNTRAINED_LUIS_OBJ_USERNAME = 'SET_UNTRAINED_LUIS_OBJ_USERNAME'
export const IS_INCOMING_LUIS_DATA_EMPTY_OBJ = 'IS_INCOMING_LUIS_DATA_EMPTY_OBJ'

/**
 * Train & publish procedure
 */
 export const INCREMENT_TRAINING_STEP='INCREMENT_TRAINING_STEP';
 export const SET_TEMP_VERSION_OLD='SET_TEMP_VERSION_OLD';
 export const SET_TEMP_VERSION_NEW='SET_TEMP_VERSION_NEW';
 export const SET_END_SESSION='SET_END_SESSION';
 export const SET_VERSION_LIST='SET_VERSION_LIST';


export const SET_TRAINED_LUIS_ENTITIES = 'SET_TRAINED_LUIS_ENTITIES'
export const SET_ADD_UNTRAINED_ENTITY = 'SET_ADD_UNTRAINED_ENTITY'
export const SET_ADD_UTTERANCE_ENTITIES = 'SET_ADD_UTTERANCE_ENTITIES'
export const SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA = 'SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA'
export const SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA = 'SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA'

export const SET_AI_VERSION = 'SET_AI_VERSION'
export const SET_TOTAL_DATA_COUNT = 'SET_TOTAL_DATA_COUNT'

/**
 * Upgrade editor
 */
export const SET_IS_ERROR_MODAL_OPEN = 'SET_IS_ERROR_MODAL_OPEN'
export const SET_IS_UPGRADING = 'SET_IS_UPGRADING'
export const SET_LOADER_OVERLAY = 'SET_LOADER_OVERLAY'
export const CLOSE_LOADER_OVERLAY = 'CLOSE_LOADER_OVERLAY'
