/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Icon } from "react-fa";
import SearchableSelect from "react-select";
import CreatableSelect from "react-select/creatable";

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";
import ResponseClips from "./ResponseClips/ResponseClips";
import Tags from "../../UI/Tags/Tags";


/** Importing custom Styles */
import classes from './BotResponsesModal.module.css';
import { showResponseModal, setResponseAction, setRules, setLoaders, setSelectedRule, setCallFlowJson, setCallFlowJsonStepSettings, setBulkEdit, setViewType, getResponsesFiltered, addResponsesFiltered ,onNewIntentSetForInfoSideBar, setAddIntentInUntrainedLuisObj, setShowUtteranceSideBar,setSelectedBlockCallInfo, emptyFilteredResponses } from '../../../store/actions/rules/rulesActions';
import { params } from '../../../../src/data/params';
import { getChangedRuleJson, getIntentsForModal, findAllOccurrence, listResponseOccurrence } from '../../../utils/rulesUtils';
import { setAlert } from "../../../store/actions/common/commonActions";
import { messages } from "../../../data/strings";
import { getPathsDataFromResponseNodeObj } from '../../../utils/callFlowUtils';
import { validateIntentText } from '../../../utils/utils';
/** Main Components */

class BotResponsesModal extends React.Component {
    constructor (props) {
        super(props);

        var responsesForModal = BotResponsesModal.getResponsesForModal(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]);

        var intentsForModal = getIntentsForModal(this.props.propsRedux.intents, this.props.propsRedux.rulesJson);

        this.state = {
            selectedResponseTextKeyJson: null,
            isSaveDisabled: true,
            modalResponseClips: [],
            haveClips : false,
            responsesForModal: responsesForModal,
            botResponsesLength: 0,
            intentsForModal: intentsForModal,
            intentSelected: null,
            responseSelected: null,
            selectionError: null,
            nodeLevelExistingResponses: [],
            filteredResponses: {},
            /** Variable mirrored just to compare in getDerivedStateFromProps */
            _mirrorShow: this.props.propsOwned.show,
            keyTimer: null,
            keyDownVal: null,
            inputSearchResValue: "",
            onNewIntentSet: false, 
            isGuidedPrompt: false,

            canNewNodeBeCreated:false

        };
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]) !== JSON.stringify(prevProps.propsRedux[prevProps.propsRedux.responsesKeyToPick])){
         var responsesForModal = BotResponsesModal.getResponsesForModal(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]);
             this.setState({
                 responsesForModal: responsesForModal,
                 filteredResponses: this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]
             })
        } 
     }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        var objChanged = null;
        // if ( Object.keys(nextProps.propsRedux[nextProps.propsRedux.responsesKeyToPick]).length !== prevState.botResponsesLength ) {
        //     console.log('inside check first getDerivedStateFromProps', objChanged)
        //     var responsesForModal = BotResponsesModal.getResponsesForModal(nextProps.propsRedux[nextProps.propsRedux.responsesKeyToPick]);
        //     console.log("DEBUGGER:: getDerivedStateFromProps responsesForModal", responsesForModal);
        //     console.log('inside check responsesForModal', responsesForModal)
        //     objChanged = { 
        //         responsesForModal: responsesForModal,
        //         filteredResponses: nextProps.propsRedux[nextProps.propsRedux.responsesKeyToPick]
        //     };
        // }

        if ( nextProps.propsOwned.show !== prevState._mirrorShow ) {
            let nodeResponses = [];
            if (
                nextProps.propsOwned.show && 
                !prevState._mirrorShow &&
                nextProps.propsRedux.responseAction && 
                nextProps.propsRedux.responseAction.referencePaths && 
                nextProps.propsRedux.responseAction.referencePaths[0] && 
                !nextProps.propsRedux.responseAction.referencePaths[0].isMetadata
            ) {
                let node = ( 
                        nextProps.propsRedux.responseAction.referencePaths[0].node
                        ) 
                        ? nextProps.propsRedux.responseAction.referencePaths[0].node 
                        : (
                            (   
                                nextProps.propsRedux.responseAction.referencePaths[0].nodeKey
                            ) 
                            ? nextProps.propsRedux.responseAction.referencePaths[0].nodeKey
                            : null);
                if (node && 
                    (node !== global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE) && 
                    (node !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE)
                ) {
                    nodeResponses = getPathsDataFromResponseNodeObj({node: node, obj: nextProps.propsRedux.rulesJson.ruleResponses[node], responseTextArrOnly: false});
                }
            }

            console.log('objChanged', objChanged)
            if (!objChanged) {
                objChanged = {};
            }
            objChanged = {
                ...objChanged,
                _mirrorShow: nextProps.propsOwned.show,
                nodeLevelExistingResponses: nodeResponses,
            }
        }

        if ( nextProps.propsRedux.isChanged ) {
            console.log('nextProps.propsRedux.isChanged')
            var intentsForModal = getIntentsForModal(nextProps.propsRedux.intents, nextProps.propsRedux.rulesJson);
            if (!objChanged) {
                objChanged = {};
            }
            return { ...objChanged, intentsForModal: intentsForModal };
        } else if ( nextProps.propsRedux.intents && prevState.intentsForModal && Object.keys(nextProps.propsRedux.intents).length != Object.keys(prevState.intentsForModal).length ) {
            var intentsForModal = getIntentsForModal(nextProps.propsRedux.intents, nextProps.propsRedux.rulesJson);
            if (!objChanged) {
                objChanged = {};
            }
            console.log('objChanged in else if', objChanged)
            return { ...objChanged, intentsForModal: intentsForModal };
        }

        if (objChanged) {
            console.log('inside objChanged', objChanged)
            return objChanged;
        }

        return null;
        
    }

    static getResponsesForModal = (botResponses, responseId = []) => {
        var responsesForModal = [];
        var uniqueKeyObj = {};
        var tagsTemp = [];
        if(Array.isArray(responseId) && responseId && responseId.length>0){
                responseId = responseId.map(String);
                botResponses = Object.keys(botResponses)
                .filter(key => responseId.includes(key))
                .reduce((obj, key) => {
                obj[key] = botResponses[key];
                return obj;
                }, {});
        }
        Object.keys(botResponses).forEach((responseId) => {
            var label = botResponses[responseId]["utterance_text"];
            if (uniqueKeyObj[label]) {
                return null;
            }
            tagsTemp = {};
            if (botResponses[responseId]["relatedUtterances"].length > 0) {
                botResponses[responseId]["relatedUtterances"].forEach(idtemp => {
                    if ( botResponses[idtemp] && botResponses[idtemp].TagDetails && Object.keys(botResponses[idtemp].TagDetails).length > 0) {
                        Object.keys(botResponses[idtemp].TagDetails).forEach(tagCollection => {
                            if (!tagsTemp[tagCollection]) {
                                tagsTemp[tagCollection] = [];
                            }
                            tagsTemp[tagCollection] = [ ...tagsTemp[tagCollection], ...botResponses[idtemp].TagDetails[tagCollection] ];
                            tagsTemp[tagCollection] = [ ...new Set(tagsTemp[tagCollection]) ];
                        })
                    }
                });
            }
            uniqueKeyObj[label] = responseId;
            responsesForModal.push({ value: responseId, label: label, tags: tagsTemp });
        });
        console.log('responsesForModal in getResponsesForModal', responsesForModal)
        return responsesForModal;
    }
    

    getNewRuleWithKeys = (currentResponse, isTagRule=false) => {
        var newRule = { ...this.props.propsRedux.selectedRule };
        if (isTagRule) {
            return newRule;
        }
        if (newRule[currentResponse.ruleKey] === undefined) {
            newRule[currentResponse.ruleKey] = {};
        }
        var keyBeforeResponse = newRule[currentResponse.ruleKey];
        if (currentResponse.evalKey !== null) {
            keyBeforeResponse = newRule[currentResponse.ruleKey][currentResponse.evalKey];
        }

        if (keyBeforeResponse === undefined) {
            keyBeforeResponse = {}
        }

        if (keyBeforeResponse[currentResponse.responseKey] === undefined) {
            keyBeforeResponse[currentResponse.responseKey] = {};
        }

        if (keyBeforeResponse[currentResponse.responseKey][currentResponse.iterationKey] === undefined) {
            keyBeforeResponse[currentResponse.responseKey][currentResponse.iterationKey] = [];
        }
        return newRule;
    }

    setClips = (key, selectedId = null) => {
        var responsesListToTake = this.props.propsRedux[this.props.propsRedux.responsesKeyToPick];
        if (responsesListToTake[key] && responsesListToTake[key]["relatedUtterances"]) {
            let haveClips = false
            var clipsArr = responsesListToTake[key]["relatedUtterances"].map(keyInner => {
                if(responsesListToTake[keyInner].audio_clip){
                    haveClips = true
                }
                return responsesListToTake[keyInner];
            })
            this.setState({modalResponseClips: [...clipsArr], haveClips: haveClips});
            if (selectedId != null) {
                this.props.setResponseAction(null);
            }
        }
    }

    onResponseChange = (response) => {
        // if(response && response.value){

            console.log("DEBUGGER:: onResponseChange response", response);
            var key = response.value;
            const isGuidedPrompt = this.props.propsRedux.responseAction.mode === global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION || this.state.isGuidedPrompt
            this.props.getResponsesFiltered(this.props.propsRedux.storyId, {keyword:response.label}, true, isGuidedPrompt);
            global.recordAudit("Response selection change : " + key);
            this.setClips(key);

            let selectionError = this.state.selectionError;
            let obj=this.state.nodeLevelExistingResponses.filter(item=>item.BRText==response.label)[0];
            let resAction = this.props.propsRedux.responseAction.referencePaths[0];
            if (
                obj &&
                resAction &&
                ( obj.nodeKey === resAction.nodeKey ) && 
                (
                    (this.props.propsRedux.responseAction.mode != global.CUST_CONSTANTS.CRUD_MODE.EDIT) || 
                    ( obj.ruleKey != resAction.ruleKey ) ||
                    ( obj.responseKey != resAction.responseKey ) ||
                    ( obj.iterationKey != resAction.iterationKey ) ||
                    ( obj.index != resAction.index )
                )
            ) {
                if (
                    (this.state.nodeLevelExistingResponses.length > 0) &&
                    (obj) && 
                    (response.label == obj.BRText)
                ) {
                    selectionError = messages.responseAlreadyOnNode;
                } else if (selectionError === messages.responseAlreadyOnNode) {
                    selectionError = null;
                }
            }

            this.setState({ 
                isSaveDisabled: true,
                selectedResponseTextKeyJson: response,
                selectionError: selectionError,
            });
        // }    
    }

    onClipSelection = (e) => {
        if (e.target.value) {
            //console.log(e.target.value,"main if is positive")
            global.recordAudit("Response clip selection changed : " + e.target.value);
            /** Considering that all response editing ids are same for now like first */
            let selectionError = this.state.selectionError;
            //if ((this.props.propsRedux.responseAction.referencePaths.length > 0) && (e.target.value == this.props.propsRedux.responseAction.referencePaths[0].referenceId))
            let obj= null;
            if (this.state.selectedResponseTextKeyJson.label) {
                obj = this.state.nodeLevelExistingResponses.filter(item=>item.BRText==this.state.selectedResponseTextKeyJson.label)[0]
            }
            
            //console.log(obj,"valueee")
            //console.log(this.state.selectedResponseTextKeyJson)
            // if (this.props.propsRedux.responseAction["mode"] === global.CUST_CONSTANTS.CRUD_MODE.EDIT) {
            //     selectionError = null;
            // } else {
            //     selectionError = this.state.selectionError
            // }
            var isSaveDisabled = false;
            var responseSelected = null
            let resAction = this.props.propsRedux.responseAction.referencePaths[0];
            if(obj && (this.props.propsRedux.responseAction["mode"] === global.CUST_CONSTANTS.CRUD_MODE.EDIT))
                {
                    if (e.target.value == obj.referenceId) {
                        selectionError = messages.responseAlreadyOnNode;
                    } else if (selectionError) {
                        if (
                            selectionError &&
                            ( obj.nodeKey == resAction.nodeKey ) && 
                            (this.props.propsRedux.responseAction.mode == global.CUST_CONSTANTS.CRUD_MODE.EDIT) && 
                            ( obj.ruleKey == resAction.ruleKey ) &&
                            ( obj.responseKey == resAction.responseKey ) &&
                            ( obj.iterationKey == resAction.iterationKey ) &&
                            ( obj.index == resAction.index )
                        ) 
                        selectionError = null;
                    }
                    responseSelected = null;
            } else {
                responseSelected = e.target.value;
            }

            if(this.props.propsRedux.showIntentInModal){
                if (!this.state.selectionError && this.props.propsRedux.showIntentInModal && this.state.intentSelected) {
                    isSaveDisabled = false;
                } else {
                    isSaveDisabled = true;
                }

            }
            else{
                if (!this.state.selectionError) {
                    isSaveDisabled = false;
                } else {
                    isSaveDisabled = true;
                }
            }
            this.setState({ 
                isSaveDisabled: isSaveDisabled,
                responseSelected: responseSelected,
                selectionError:selectionError,
            });
            this.props.setResponseAction(null, e.target.value);
        } else {
            global.recordAudit("Response clip selection change reset");
            this.setState({ 
                isSaveDisabled: true,
                responseSelected: null,
            });
            this.props.setResponseAction(null);
        }
    }

    onHide = () => {
        global.recordAudit("Response modal closed");
        console.log('onHide')
        this.props.emptyFilteredResponses();
        this.props.showResponseModal(false);
        this.props.setResponseAction(null);
        this.resetStates();
        
        
        this.props.propsOwned.onHide();
        this.setState({canNewNodeBeCreated:false})  

    }

    resetStates = () => {
        this.setState({ 
            responsesForModal: [],
            modalResponseClips: [], 
            selectedResponseTextKeyJson: null,
            isSaveDisabled: true,
            intentSelected: null,
            responseSelected: null,
            selectionError: null,
            isGuidedPrompt: false,
            haveClips: false
        });
    }

    addNewNode = () => {

        var selectedResponse = this.props.propsRedux.responseAction.selectedId;
        var newRuleJson = this.props.propsRedux.rulesJson;
        var newIntentName = this.state.intentSelected;
        var defaultEvalFalse = {
            failureResponse: {
                id: 0,
                defaultText: "",
                defaultClip: "",
                responseUsed: "False",
            }
        }

        if(newRuleJson.hasOwnProperty('metadata') && newRuleJson.metadata.hasOwnProperty('default_eval_false')) {
            defaultEvalFalse = newRuleJson.metadata['default_eval_false'];
        }
        
        var responsesListToTake = this.props.propsRedux[this.props.propsRedux.responsesKeyToPick];

        var newResponse = {
            id: selectedResponse,
            BRText: responsesListToTake[selectedResponse].utterance_text,
            BRClip: responsesListToTake[selectedResponse].filepath,
            responseUsed: "False",
        };

        newRuleJson.ruleResponses[newIntentName] = {
            generic: {
                evalTrue: {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                        [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: [
                            { ...newResponse }
                        ]
                    }
                },
                priority: 1,
            }
        }
        if (newIntentName != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) {
            newRuleJson.rule[newIntentName] = {
                rule: {
                    lhs: "CurrentIntentAttempted",
                    op: "EQUALSTO",
                    rhs: newIntentName
                },
                type: 1,
            }
            newRuleJson.ruleResponses[newIntentName].generic['evalFalse'] = defaultEvalFalse;
        }
        
        /** Setting selected node accordingly for updating call flow while changing rule json. */
        var responseJson = { ...newRuleJson.ruleResponses[newIntentName] };
        var pathsData = [{
            evalKey: global.CUST_CONSTANTS.DEFAULT_KEYS.EVAL_TYPE, 
            index: 0,
            iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
            nodeKey: newIntentName,
            referenceId: selectedResponse,
            responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
            ruleKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RULE_KEY,
            simpleViewRowIndex: 0
        }];

        this.props.setSelectedRule(newIntentName, responseJson, false, null, null, pathsData, global.CUST_CONSTANTS.CRUD_MODE.ADD, newIntentName);

        this.props.setRules(newRuleJson, global.CUST_CONSTANTS.ACTION_LEVEL.NODE);
             
        
        
    }

    /**
     * method when save button is clicked on bot response modal
     */
    onSaveResponse = () => {
        // alert();
        global.recordAudit("Response modal save button clicked response Action : " + JSON.stringify(this.props.propsRedux.responseAction));

        var selectedResponse = this.props.propsRedux.responseAction.selectedId;
        var responsesListToTake = this.props.propsRedux[this.props.propsRedux.responsesKeyToPick];
        var error = false;
        if ( this.props.propsRedux.responseAction && this.props.propsRedux.responseAction.referencePaths && this.props.propsRedux.responseAction.referencePaths.length > 0) {
            if (this.props.propsRedux.responseAction.mode == global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION)  {
                /** Condition if call flow interruption is being set */
                /** Call Flow version 1.1 and old support */
                let newCallFlow = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
                var newResponse = {
                    id: selectedResponse,
                    BRText: responsesListToTake[selectedResponse].utterance_text,
                    BRClip: responsesListToTake[selectedResponse].filepath,
                    responseUsed: "False",
                };
                this.props.propsRedux.responseAction.referencePaths.forEach((stepObj)=>{
                    if (!newCallFlow[stepObj.step].settings) {
                        newCallFlow[stepObj.step].settings = {};
                    }
                    newCallFlow[stepObj.step].settings["interruption"] = {
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE]: {
                            [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: [
                                { ...newResponse }
                            ]
                        }
                    };
                    /** Kept inside the loop as it will be hardly 1-2 elements in loop thus would save replacing whole call flo json and will just replace a single step settings */
                    this.props.setCallFlowJsonStepSettings(stepObj.step, newCallFlow[stepObj.step].settings);
                });
            } else {
                /** Condition id any response is being changed or beig added on rules/node */
                var data = getChangedRuleJson(this.props.propsRedux.rulesJson, this.props.propsRedux.responseAction.referencePaths, this.props.propsRedux.responseAction.mode, selectedResponse, responsesListToTake, this.props.propsRedux.detailedVsSimpleViewIndexesMapper);
                this.props.setRules(data.newRulesJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
                this.props.setLoaders(data.loadersPayloads);
            }
        } else if (this.props.propsRedux.showIntentInModal && this.state.intentSelected) {
            /** Condition when node is being added */
            var oldRuleJson = this.props.propsRedux.rulesJson;
            var newIntentName = this.state.intentSelected;
            if (oldRuleJson.rule[newIntentName]) {
                this.props.setAlert(messages.intentAlreadyPresent);
            } else {
                this.addNewNode();
            }
        }

        if (!error) {
            this.onHide();
        }
        
      
        //if New Node Creation is allowed 
        if(this.state.canNewNodeBeCreated){
            if(this.state.intentSelected){
                var newResponse = {
                    BRText: responsesListToTake[selectedResponse].utterance_text,
                    BRClip: responsesListToTake[selectedResponse].filepath,
                };
                this.props.setAddIntentInUntrainedLuisObj(this.state.intentSelected.trim());
                
                this.props.setSelectedBlockCallInfo({
                    node:this.state.intentSelected.trim(),botResponse:newResponse,
                    utteranceText:messages.noUtteranceTextIsConfigured,
                    entities:[]
                })
                this.props.setViewType(2);
                this.props.setShowUtteranceSideBar(true,{}); 
                this.setState({canNewNodeBeCreated:false})  
            }
         }
    }

    onViewAllResponse = () => {
        global.recordAudit("Response modal view all button clicked response Action  ");
        /** Changing the view to Detailed view */
        this.props.setViewType(1);
        var error = false;
        var from = this.props.propsRedux.responseAction.referencePaths.length > 0 ? this.props.propsRedux.responseAction.referencePaths[0].referenceId:null;
        let fromResponseValue = BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses, [from]);
        fromResponseValue = fromResponseValue[0] ? fromResponseValue[0].label : '';

        let [responseId, to, respJson, ruleJson] = listResponseOccurrence(fromResponseValue, from, global.CUST_CONSTANTS.CRUD_MODE.EDIT, {"rule":this.props.propsRedux.rulesJson.rule,"ruleResponses":this.props.propsRedux.rulesJson.ruleResponses},this.props.propsRedux.responseAction.selectedId);

        let nodeKey = (
            this.props.propsRedux.responseAction &&
            this.props.propsRedux.responseAction.referencePaths &&
            this.props.propsRedux.responseAction.referencePaths[0] &&
            (
                this.props.propsRedux.responseAction.referencePaths[0].nodeKey ||
                this.props.propsRedux.responseAction.referencePaths[0].node
            )
        ) ? 
                (this.props.propsRedux.responseAction.referencePaths[0].nodeKey) ? 
                this.props.propsRedux.responseAction.referencePaths[0].nodeKey :
                this.props.propsRedux.responseAction.referencePaths[0].node
            : null;

        this.props.setSelectedRule(nodeKey);

        this.props.setBulkEdit({
            state: true,
            action: global.CUST_CONSTANTS.CRUD_MODE.EDIT,
            type: global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE,
            from: responseId,
            to: to,
            responseJson: typeof respJson != "undefined" ? respJson[0].response : {},
            keyMapper: typeof respJson != "undefined" ? respJson[0].keyMapper : {},
            selectedNodeBulk: [],
            ruleResponse: typeof ruleJson != "undefined" ? ruleJson : {},
            applyChanges: false,
        });


        this.setState({ selectedResponseTextKeyJson: this.props.propsRedux.responseAction.selectedId});
        
        if (!error) {
            this.onHide();
        }
    }

    formatOptionLabelResponses = ({ value, label, tags }) => {
        return (
            <div className={ [ classes.Option ].join(' ') }>
                { label }
                <Tags keyBase={ "response_modal_tag_" + value } tags={ tags } />
            </div>
        )
    };

    onIntentChange = (intentSelected) => {       
        if(intentSelected && intentSelected.value){
            const intentSelectedValue = intentSelected.value.trim()
            if(!validateIntentText(intentSelectedValue)){
                this.props.setAlert(messages.nodeValidationMessage);
                return
            }
            global.recordAudit("Response modal Intent changed : " + intentSelectedValue);
            var isSaveDisabled = true;
            var selectionError = null;
    
            if(this.props.propsRedux.rulesJson.ruleResponses[intentSelectedValue]){
                selectionError = messages.intentAlreadyPresent;
                isSaveDisabled = true;
            } else {
                if (this.state.responseSelected) {
                    isSaveDisabled = false;
                } else {
                    isSaveDisabled = true;
                }
    
            }
            
           //track createable select state for new node creation
            if (intentSelected && intentSelected.__isNew__) {
                this.setState({ canNewNodeBeCreated: true })
            } else {
                this.setState({ canNewNodeBeCreated: false })
            }

            this.setState({
                isSaveDisabled: isSaveDisabled,
                intentSelected: intentSelectedValue, //trimmed
                selectionError : selectionError,
            });
        }
        
    }

    formatOptionLabel = ({label, isDisabled}) => (
        <div className= { (isDisabled == true) ? [ classes.intentInJson ].join(' ') : '' }>
            <span>{ label }</span>
        </div>
    );

    // static listResponseOccurrence = (needle, responseId, action = global.CUST_CONSTANTS.CRUD_MODE.EDIT, data = {}, changeTo = null) => {
    //     let to = null;
    //     let ruleData = data["rule"] ?  data["rule"] :{};
    //     let responseData = data["ruleResponses"] ?  data["ruleResponses"] :{};

    //     if(action === global.CUST_CONSTANTS.CRUD_MODE.EDIT && changeTo){
    //         to = changeTo;
    //     }
        
    //     if (needle) {
    //         var respJson = findAllOccurrence(responseData, needle, global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE, false);
    //         var ruleJson = findAllOccurrence(ruleData, needle, global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE, true);
    //     }

    //     if ((respJson || ruleJson) && (typeof respJson != "undefined" || typeof ruleJson != "undefined")) {
    //         return [responseId, to, respJson, ruleJson];
    //     }
    // }

    // onKeyDown = (e) => {
    //     // console.log("DEBUGGER:: keydown e.keyCode, e.target.value, e.key", e.keyCode, e.target.value, e.key);
    //     if (!this.props.propsRedux.botResponsesSyncedAll) {

    //         if ((e.keyCode==32) || (e.keyCode == 13)) {
    //             console.log("DEBUGGER:: keydown e.keyCode, e.target.value, e.key", e.keyCode, e.target.value, e.key);
    //             this.props.getResponsesFiltered(this.props.propsRedux.storyId, e.target.value, false);
    //             this.clearTimer();
    //         } else {
    //             this.setState({keyDownVal: e.target.value});
    //             this.initTimer();
    //         }
    //     }
    // }

    onInputChange = (value) => {    
        var valToSearch = value;
        
        if (valToSearch !== "") {
            valToSearch = valToSearch.replace("`","'").replace("’","'");
        }
    
        this.setState({inputSearchResValue: valToSearch});
        console.log("DEBUGGER:: Input changed valToSearch :", valToSearch);
        if ((!this.props.propsRedux.botResponsesSyncedAll) && (valToSearch.trim() !== "")) {
            var lastChar = valToSearch.substr(valToSearch.length - 1);
            let splitText={}
            if(valToSearch.includes(" @")){
                /* Splits keyword, tagCollection and tag*/
              var symbol=/@(.*?):/;
              var tagCollection= valToSearch.split(symbol)
              console.log("DEBUGGER:: Array of voice",tagCollection)
              splitText={keyword:tagCollection[0].trim(),tagCollection:tagCollection[1],tag:tagCollection[2]}
            }
            else if(valToSearch.includes(" :")){
                /* Splits keyword and tag*/
                var symbol=":";
                var tagCollection= valToSearch.split(symbol)
                console.log("DEBUGGER:: Array contains keyword and tag",tagCollection)
                splitText={keyword:tagCollection[0].trim(),tag:tagCollection[1]}
            }
            else{
                var tagCollection= valToSearch
                console.log("DEBUGGER:: Array of keyword",tagCollection)
                splitText={keyword:tagCollection}
            }
            
            if ((lastChar == " ")) {
                console.log("DEBUGGER:: Input changed space :", valToSearch);
                const isGuidedPrompt = this.props.propsRedux.responseAction.mode === global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION || this.state.isGuidedPrompt
                this.props.getResponsesFiltered(this.props.propsRedux.storyId, splitText, false, isGuidedPrompt);
                this.clearTimer();
            } 
            else {
                console.log("DEBUGGER:: Input changed normal :", valToSearch);
                this.setState({keyDownVal: splitText});
                this.initTimer();
            }
        }
    }


    /** Adding auto filter functionality */
    // var timer = null;
    clearTimer = () => {
        if (this.state.keyTimer) {
            clearInterval(this.state.keyTimer);
        }
    }

    initTimer = () => {
        this.clearTimer();
        var timer = setTimeout(() => {
            const isGuidedPrompt = this.props.propsRedux.responseAction.mode === global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION || this.state.isGuidedPrompt
            this.props.getResponsesFiltered(this.props.propsRedux.storyId, this.state.keyDownVal, false, isGuidedPrompt);
        }, ( params.keyGapTimeForFilter));
        this.setState({keyTimer:timer});
    }

    filterOption = (candidate, input) => {
        console.log("Value for input:",input)
        console.log("Value for candidate:",candidate)
        let entry={}
        if(input.includes(" @")){
            /* Filter the response we need for matching candidate and input value*/
          var symbol=/@(.*?):/;
          var tagCollection= input.split(symbol)
          console.log("DEBUGGER:: Entry based on @",tagCollection)
          entry={text:tagCollection[0].trim()}
        }
        else if(input.includes(" :")){
             /* Filter the response we need for matching candidate and input value*/
            var symbol=":";
            var tagCollection= input.split(symbol)
            console.log("DEBUGGER:: Entry based on :",tagCollection)
            entry={text:tagCollection[0].trim()}
        }
        else{
            console.log("DEBUGGER:: Entry based on keyword",tagCollection)
            entry={text:input}
        }
        return candidate.data.__isNew__ || (candidate.label && candidate.label.replace("`","'").replace("’","'").toLowerCase().includes(entry.text.toLowerCase()));
    };

    noOptions = (inputObj) => {
        console.log("DEBUGGER:: noOptions, inputObj", inputObj); 
        if (!(this.props.propsRedux.filteringInProgress) && (inputObj.inputValue.trim() != "") && (this.props.propsRedux.lastFilteredPage != 0)) {
            var nextPageNo = this.props.propsRedux.lastFilteredPage + 1;
            const isGuidedPrompt = this.props.propsRedux.responseAction.mode === global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION || this.state.isGuidedPrompt
            this.props.addResponsesFiltered(this.props.propsRedux.storyId, inputObj.inputValue, nextPageNo, isGuidedPrompt);
        }
        return (this.props.propsRedux.filteringInProgress ? "Loading..." : "No responses available.");
    };

    onMenuScrollToBottom = () => {
        if (!(this.props.propsRedux.filteringInProgress) && (this.state.inputSearchResValue.trim() != "") && (this.props.propsRedux.lastFilteredPage != 0)) {
            var nextPageNo = this.props.propsRedux.lastFilteredPage + 1;
            const isGuidedPrompt = this.props.propsRedux.responseAction.mode === global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION || this.state.isGuidedPrompt
            this.props.addResponsesFiltered(this.props.propsRedux.storyId, this.state.inputSearchResValue, nextPageNo, isGuidedPrompt);
        }
        console.log("DEBUGGER:: onMenuScrollToBottom"); 
    };

    guidedPromptHanlder = (e) => {
        global.recordAudit('Guilded Prompt Only checkbox checked' + e.target.checked )
        this.props.emptyFilteredResponses();
        this.setState({
            isGuidedPrompt: e.target.checked,
            responsesForModal: [],
            filteredResponses: {},
            modalResponseClips: [],
            selectedResponseTextKeyJson: null,
            haveClips: false
        })
    }
    
    render() {
        var modalButtons = [
            {
                type: 'add',
                text: 'Save Response',
                variant: 'primary',
                onClick: this.onSaveResponse,
                className: 'gl-btnPrimaryCust',
                isDisabled: this.state.isSaveDisabled
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: this.onHide
            }
        ];
        if(
            this.props.propsRedux.responseAction &&
            this.props.propsRedux.responseAction.referencePaths &&
            this.props.propsRedux.responseAction.referencePaths[0] &&
            (this.props.propsRedux.responseAction["mode"] ===  global.CUST_CONSTANTS.CRUD_MODE.EDIT ) &&
            (this.props.propsRedux.responseAction.referencePaths[0].evalKey !== global.CUST_CONSTANTS.PREDEFINED_KEYS.EVAL_KEYS.EVAL_FALSE) &&
            (this.props.propsRedux.responseAction.referencePaths[0].referenceId !== 0) &&
            (!this.props.propsRedux.responseAction.referencePaths[0].isMetadata) &&
            (this.props.propsRedux.activeTab === 1)
         ) {
            modalButtons = [
                {
                    type: 'view',
                    text: 'Show Occurrences',
                    variant: 'primary',
                    onClick: this.onViewAllResponse,
                    className: 'gl-btnPrimaryCust',
                    isDisabled: this.state.isSaveDisabled
                } ,
                ...modalButtons
            ];
        }
        console.log('this.state.responsesForModal', this.state.responsesForModal)
        
        return (
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.props.propsOwned.onShow}
                onHide={ this.onHide }
                title={ (this.props.propsRedux.showIntentInModal) ? "Add Node" : "Responses" }
                footerComponents={modalButtons}
            >
 <div className={classes.Center}>
                    {
                        this.props.propsRedux.showIntentInModal && (
                            this.props.propsRedux.isLuisActionsEnabled ? <CreatableSelect
                                isClearable
                                name="intents"
                                className={[classes.IntentSelect].join(' ')}
                                options={this.state.intentsForModal}
                                onChange={(intentSelected) => this.onIntentChange(intentSelected)}
                                placeholder={"Select or Create Intent"}
                                formatOptionLabel={this.formatOptionLabel}
                            /> : <SearchableSelect
                                name="intents"
                                className={[classes.IntentSelect].join(' ')}
                                options={this.state.intentsForModal}
                                onChange={(intentSelected) => this.onIntentChange(intentSelected)}
                                placeholder={"Select Intent"}
                                formatOptionLabel={this.formatOptionLabel}
                            />
                        )
                    }

                    <div className={ [ classes.TypeMessageDiv ].join(' ') }>Type keyword(s) to search..</div>
                    
                    <SearchableSelect
                    
                        name="responses" 
                        value={ this.state.selectedResponseTextKeyJson }
                        hideSelectedOptions={ true }
                        options={ this.state.responsesForModal } 
                        onChange={ (response) => this.onResponseChange(response, false) } 
                        inputValue={this.state.inputSearchResValue}
                        onInputChange={ (value) => this.onInputChange(value)}
                        noOptionsMessage={this.noOptions}
                        formatOptionLabel={ this.formatOptionLabelResponses }
                        filterOption={ this.filterOption }
                        onMenuScrollToBottom={ this.onMenuScrollToBottom }
                        isLoading={ this.props.propsRedux.filteringInProgress }
                    />

                    {this.props.propsRedux.responseAction.mode != global.CUST_CONSTANTS.ACTION_LEVEL.CALL_FLOW_INTERRUPTION && <div className='w-100 text-left'>
                        <input type="checkbox" id = "guidedPrompt-checkbox" onClick={ (e) => this.guidedPromptHanlder(e) } value = {this.state.isGuidedPrompt}/>
                        &nbsp;
                        <label htmlFor='guidedPrompt-checkbox'>Use Guided Prompt Only</label>
                    </div>}

                    

                    <ResponseClips
                        modalResponseClips={ this.state.modalResponseClips }
                        haveClips = {this.state.haveClips}
                        onClipSelection={ this.onClipSelection }
                        selectedResponse={ this.props.propsRedux.currentResponse && this.props.propsRedux.currentResponse.selectedResponse }
                    />
                </div>
                <div className={classes.Center}>OR</div>
                <div className={classes.Center}>
                    <a href={params["phpUrlBase"] + 'bot/admin'} className="mt-1 ml-3 btn btn-primary btn-sm assign-button add-node-button" target="_blank">
                        <Icon name="plus-square" className={ [ "icon-btn" ].join(' ') } ></Icon>
                        Add More Responses 
                    </a>
                </div>
                {
                    this.state.selectionError &&
                    <div className={ [ 'alert alert-danger' ].join(' ') } style={{marginTop : '10px', marginBottom: '0px' }}>
                        {this.state.selectionError}
                    </div>
                }

            </Modal>
        );
    }
}

/** Defining prop types requirements */
BotResponsesModal.propTypes = {
    propsRedux: PropTypes.shape({
        botResponses: PropTypes.object.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            botResponses: state.rules.botResponses,
            filteredBotResponses: state.rules.filteredBotResponses,
            responsesKeyToPick: state.rules.botResponsesLoadingVars.responsesKeyToPick,
            botResponsesSyncedAll: state.rules.botResponsesLoadingVars.botResponsesSyncedAll,
            filteringInProgress: state.rules.botResponsesLoadingVars.filteringInProgress,
            lastFilteredPage: state.rules.botResponsesLoadingVars.lastFilteredPage,
            responseAction: state.rules.responseAction,
            selectedNode: state.rules.selectedNode,
            storyId: state.story.storyId,
            rulesJson: state.rules.rulesJson,
            detailedVsSimpleViewIndexesMapper: state.rules.detailedVsSimpleViewIndexesMapper,
            showIntentInModal: state.rules.showIntentInModal,
            selectedStorySetting: state.rules.selectedStorySetting,
            intents: state.rules.intents,
            isChanged: state.rules.isChanged,
            callFlowJson: state.rules.callFlow.callFlowJSON,
            bulkEdit: state.bulkEdit,
            activeTab: state.rules.activeTab,
            isLuisActionsEnabled: state.rules.untrainedLuisObj.isActionEnabled,
            isNewNodeCreated:state.rules.isNewNodeCreated
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { showResponseModal, setResponseAction, setRules, getChangedRuleJson, setLoaders, setSelectedRule, setAlert, setCallFlowJson, setCallFlowJsonStepSettings, setBulkEdit, setViewType, findAllOccurrence, getResponsesFiltered, addResponsesFiltered ,onNewIntentSetForInfoSideBar, setAddIntentInUntrainedLuisObj,setShowUtteranceSideBar,setSelectedBlockCallInfo, emptyFilteredResponses})(BotResponsesModal);