import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import { composeWithDevTools } from "remote-redux-devtools";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

const initialState = {};


const logger = createLogger({
    // .....options
});

var middleware = [];

// const actionSanitizer = (action) => (
//     action.payload ?
//     { ...action, payload: '<<LONG_BLOB>>' } : action
// );

// const composeEnhancers = composeWithDevTools({
//     actionSanitizer,
//     stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
// });
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, thunk, logger];
} else {
    middleware = [...middleware, thunk];
}
//creating store
const store = createStore(
    rootReducer,
    initialState,
    // composeEnhancers(applyMiddleware(...middleware)),
    applyMiddleware(...middleware)
);

export default store;
