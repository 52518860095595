/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import ReactTooltip from "react-tooltip";
import ReactDiffViewer from "react-diff-viewer";


/** Importing custom Components */
import SearchableSelect from "react-select";
import LoadingIcon from "../../../../UI/LoadingIcon/LoadingIcon";
import Modal from "../../../../UI/Modal/Modal";
import {saveRulesToDb,setSelectedRule, setResponseAction, showResponseModal,setRules, setWarnOverwriting,setSaveAsSelected,setJsonName } from "../../../../../store/actions/rules/rulesActions";
import { messages } from "../../../../../data/strings";
import { getGlobalRules } from "../../../../../utils/sequencingUtils";

import classes from './Save.module.css'

class Save extends React.Component {

    constructor (props) {
        super(props);
        ReactTooltip.rebuild();
        this.saveAsOptions = [
            {
                label: 'Production Json', 
                value: 1
            },
            {
                label: 'Staging Json' ,
                value: 0
            },
            {
                label: 'Restore Point' ,
                value: 2
            }
        ];

        this.state = {
            saveModalShow: false,
            jsonName: this.props.propsRedux.jsonName,
            recordingType: 4,
            /** State = 0: none, 1: to show comparision, 2: compared/comparing (diff view open) */
            cf_state: 0,
            cf_createCallFlowRules: false,
            cf_callFlowRulesJson: "",
            cf_oldRulesJson: "",
            cf_callFlowResponseJson: "",
            cf_oldResponseJson: "",
            cf_fullRulesJson: {},
            /** list to check if same response exists in different steps while creating rules */
            nonUniqueResponsesList: [],
        }
    }
    saveRules = () => {
        global.recordAudit("Save button clicked on header");
        this.setState({
            saveModalShow: true,
        });
    }
    onSaveAsOptionChange = (opt) => {
        global.recordAudit("Saveas option changed to " + JSON.stringify(opt));
        this.setState({
            jsonName: null,
            saveAsSelected: opt,
        });
        this.props.setSaveAsSelected(opt);
        this.props.propsRedux.jsonName !==null && this.props.setJsonName(null);
    }
    onSaveAsModalHide = () => {
        global.recordAudit("Saveas modal closed");
        this.setState({
            jsonName: null,
            saveModalShow: false,
            cf_state: 0,
            cf_createCallFlowRules: false,
            cf_callFlowRulesJson: "",
            cf_oldRulesJson: "",
            cf_callFlowResponseJson: "",
            cf_oldResponseJson: "",
        });
        this.props.setSaveAsSelected(this.saveAsOptions[0]);
        this.props.propsRedux.jsonName !==null && this.props.setJsonName(null);
    }

    /** New functions to create call flow json */

    onCallFlowRulesCheck = (e) => {
        if (e.target.checked) {
            this.setState({
                cf_createCallFlowRules: true,
                cf_state: 1,
            });
        } else {
            this.setState({
                cf_createCallFlowRules: false,
                cf_state: 0,
            });
        }
    }

    saveRulesFinal = (forceSave=0) => {
        global.recordAudit("Final Save Rules button clicked with forceSave as " + forceSave);
        this.props.saveRulesToDb(false, forceSave, this.props.propsRedux.saveAsSelected.value, this.state.jsonName);
        this.onSaveAsModalHide();
    }

    saveRulesCreatingGlobal = (forceSave=0) => {
        if (this.state.cf_state == 1) {
            global.recordAudit("Save rules with call flow rules state 1 : forceSave as " + forceSave);
            /** Call Flow version 1.1 and old support */
            let callFlowPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
            const callFlowMetaData = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA];
            let {newRulesJson, nonUniqueResponsesList} = getGlobalRules({callFlowStepsObj: callFlowPicked, callFlowMetaData: callFlowMetaData, stepSequence: this.props.propsRedux.stepSequence, rulesJson: this.props.propsRedux.rulesJson, currentCallFlowMapping: this.props.propsRedux.callFlowMapping});
            this.setState({
                cf_state: 2,
                cf_callFlowRulesJson: JSON.stringify(newRulesJson.rule, null, 2),
                cf_oldRulesJson: JSON.stringify(this.props.propsRedux.rulesJson.rule, null, 2),
                cf_callFlowResponseJson: JSON.stringify(newRulesJson.ruleResponses, null, 2),
                cf_oldResponseJson: JSON.stringify(this.props.propsRedux.rulesJson.ruleResponses, null, 2),
                cf_fullRulesJson: newRulesJson,
                nonUniqueResponsesList: nonUniqueResponsesList,
            });
            // this.onSaveAsModalHide();
        } else if (this.state.cf_state == 2) {
            global.recordAudit("Save rules with call flow rules state 2 : forceSave as " + forceSave);
            this.props.setRules(this.state.cf_fullRulesJson);
            this.props.setWarnOverwriting(false);
            this.saveRulesFinal(forceSave);
        } else {
            global.recordAudit("Save rules without call flow rules : forceSave as " + forceSave);
            this.saveRulesFinal(forceSave);
        }
    }

    /** cases that checks the appearence of pop op(luis app info) luis-app-json api is called */
    handleIsSaveRulesDisabled = () => {
        const isAddLuisUntrainedDataExists = this.props.propsRedux.untrainedLuisObjData.ADD.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.ADD.utterances.length >0 || ( this.props.propsRedux.untrainedLuisObjData.ADD.entities && this.props.propsRedux.untrainedLuisObjData.ADD.entities.length >0 )
        const isDeleteLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.DELETE.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.DELETE.utterances.length >0  || ( this.props.propsRedux.untrainedLuisObjData.DELETE.entities && this.props.propsRedux.untrainedLuisObjData.DELETE.entities.length >0 )
        const isEditLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.EDIT.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.EDIT.utterances.length >0 || (this.props.propsRedux.untrainedLuisObjData.EDIT.entities && this.props.propsRedux.untrainedLuisObjData.EDIT.entities.length >0 )
        const isDataExists = isAddLuisUntrainedDataExists || isDeleteLuisUntrainedDataExists || isEditLuisUntrainedDataExists

        return isDataExists
    }

    render(){
        
            const globalRulesPresent = (this.props.propsRedux.rulesJson && this.props.propsRedux.rulesJson.rule && this.props.propsRedux.rulesJson.ruleResponses && (this.props.propsRedux.rulesJson.rule[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE] || this.props.propsRedux.rulesJson.ruleResponses[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE]));
            const modalButtons = [
                {
                    type: 'save',
                    text: ((this.state.cf_state == 1) ? "Compare" : "Save"),
                    variant: 'primary',
                    onClick: () => this.saveRulesCreatingGlobal(),
                    className: 'gl-btnPrimaryCust',
                    isDisabled: ( ((this.props.propsRedux.saveAsSelected.value < 2) || ((this.props.propsRedux.saveAsSelected.value == 2) && (this.state.jsonName != null ))) ) ? false : true,
                },
                {
                    type: 'ignoresave',
                    text: 'Ignore and Save',
                    variant: 'danger',
                    onClick: () => this.saveRulesCreatingGlobal(1),
                    className: [  "btn", "btn-danger" ].join(' '),
                    isDisabled: ( 
                            (this.state.cf_state != 1) &&
                            (
                                (this.props.propsRedux.saveAsSelected.value < 2) || 
                                (
                                    (this.props.propsRedux.saveAsSelected.value == 2) && 
                                    (this.state.jsonName != null )
                                )
                            ) 
                        ) ? false : true,
                    otherProps: {
                        "data-class": "tooltip_cust",
                        "data-multiline": "true",
                        "data-tip": "This will forcefully save the rules json regardless it is corrupted or not and will reflect the same in practice sessions too."
                    } 
                },
                {
                    type: 'close',
                    text: 'Close',
                    variant: 'secondary',
                    onClick: this.onSaveAsModalHide
                }
            ];
    
            let isCFCheckboxDisabled = true;
            let cfjson = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
            if (cfjson && ( Object.keys(cfjson).length > 1 )) {
                let stepKeysTemp = Object.keys(cfjson);
                for (let indexTemp = 0; indexTemp < stepKeysTemp.length; indexTemp++) {
                    const step = stepKeysTemp[indexTemp];
                    if (cfjson[step] && cfjson[step].list && Array.isArray(cfjson[step].list) && (cfjson[step].list.length > 0)) {
                        isCFCheckboxDisabled = false;
                        break;
                    }
                }
            }
            return (
                <>
                    <div className="d-flex align-items-center">
                        {
                            this.props.propsRedux.loading && <LoadingIcon className={ [ classes.Icon ].join(' ') } />
                        }
                        {
                            (
                                this.props.propsRedux.isDraftInDb || 
                                this.props.propsRedux.isCallFlowDraftInDb || 
                                this.props.propsRedux.unsavedChanges
                            ) && 
                            <Icon 
                                name="warning" 
                                className={ [ classes.isDraftIcon, classes.Icon, (this.props.propsRedux.unsavedChanges) ? classes.UnsavedChanges : "" ].join(' ') } 
                                data-tip={ (this.props.propsRedux.unsavedChanges) ? messages.unsavedChanges : messages.isDraftIcon } 
                                data-class="tooltip_cust"  
                                data-place="top" />
                        }
                        <span data-class="tooltip_cust"   data-tip={this.handleIsSaveRulesDisabled() ? messages.restrictingSaveRulesButton : ""} >
                        <button disabled ={this.handleIsSaveRulesDisabled()} className={"btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue " + (this.handleIsSaveRulesDisabled() && classes.buttonDisabled) }onClick={ () => this.saveRules() }>
                            <Icon name="save" className="icon-btn"/>
                            Save As
                        </button>
                        </span>
                    </div>
                    <Modal
                        show={ this.state.saveModalShow }
                        onShow={ () => {} }
                        onHide={ this.onSaveAsModalHide }
                        title="Save"
                        footerComponents={ modalButtons }
                        className={ [ (this.state.cf_state == 2 ) ? "modal-full" : "" ].join(' ') }
                    >
                        {
                            (this.state.cf_state < 2) &&
                            <div>
                                In which form you want to save the json?
                                <SearchableSelect 
                                    name="saveAs" 
                                    defaultValue={ this.saveAsOptions[0] }
                                    options={ [...this.saveAsOptions] }
                                    onChange={ (opt) => this.onSaveAsOptionChange(opt) }
                                />
                                <div className={ [ 'mt-10' ].join(' ') }>
                                    <label>
                                        Friendly Name : 
                                    </label>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        value={ this.state.jsonName || "" }
                                        className={ [ "form-control" ].join(' ') }
                                        readOnly={ ( this.props.propsRedux.saveAsSelected.value != 2) } 
                                        disabled={ ( this.props.propsRedux.saveAsSelected.value != 2) } 
                                        onChange={ (e) => { this.setState({ jsonName: e.target.value }) } } 
                                    />
                                </div>
                                <div>
                                    <input type="checkbox" onClick={ (e) => this.onCallFlowRulesCheck(e) } disabled={ isCFCheckboxDisabled } />
                                    &nbsp; { (globalRulesPresent) ? "Overwrite" : "Create" } the Rules according to the Call Flow ?
                                </div>
        
                            </div>
                        }
                        {
                            (this.state.cf_state == 2 ) &&
                            <div>
                                <div>
                                    {
                                        (this.state.nonUniqueResponsesList.length > 0) && 
                                        <div className={ [ 'alert', 'alert-danger', classes.ResponseAlertListDiv ].join(' ') }>
                                            <h6>These responses are repeated in different steps. Thus might not work according to sequence.</h6>
                                            <ul className={ [ classes.ResponseAlertList ].join(' ') }>
                                                {
                                                    this.state.nonUniqueResponsesList.map( (responseText, rti) => {
                                                        return (
                                                            <li key={ "rt_"+rti }>
                                                                { responseText }
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <h5>Rules</h5>
                                <div className={ [ classes.DiffCheckerDiv ].join(' ') }>
                                    <ReactDiffViewer oldValue={ this.state.cf_oldRulesJson } newValue={ this.state.cf_callFlowRulesJson } splitView={true} />
                                </div>
                                <h5>Responses</h5>
                                <div className={ [ classes.DiffCheckerDiv ].join(' ') }>
                                    <ReactDiffViewer oldValue={ this.state.cf_oldResponseJson } newValue={ this.state.cf_callFlowResponseJson } splitView={true} />
                                </div>
                            </div>
                        }
                        {
                            (this.state.cf_state == 1 ) &&
                            (this.props.propsRedux.warnOverwriting) &&
                            <div className={ [ "alert", "alert-danger" ].join(' ') }>
                                { messages.warnOverwritingMessage }
                            </div>
                        }
                    </Modal>
                </>
        
            )
    }
        
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
            callFlowJSON: state.rules.callFlow.callFlowJSON,
            isDraftInDb: state.rules.isDraftInDb,
            isCallFlowDraftInDb: state.rules.callFlow.callFlowIsDraft,
            callFlowMapping: state.rules.callFlowResponseMappings,
            warnOverwriting: state.rules.warnOverwriting,
            unsavedChanges: state.rules.unsavedChanges,
            saveAsSelected: state.rules.saveAsSelected,
            jsonName: state.rules.jsonName,
            loading: state.rules.loading,
            stepSequence: state.rules.stepSequence,
            untrainedLuisObjData : state.rules.untrainedLuisObj.data
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { saveRulesToDb, setSelectedRule, setResponseAction, showResponseModal, setRules, setWarnOverwriting, setSaveAsSelected,setJsonName })(Save);