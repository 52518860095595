/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

/** Importing custom Components */
import RulesResponses from "../../../RulesResponses/RulesResponses";
import ResponseTypes from "../../../Inputs/ResponseTypes/ResponseTypes";
import IterationTypes from "../../../Inputs/IterationTypes/IterationTypes";
import Prioirity from "../../../Inputs/Prioirity/Prioirity";
import { setBulkEdit } from '../../../../../store/actions/rules/rulesActions';

import { getResponseKeyFromObj, getIterationKeyFromObj } from "../../../../../utils/rulesUtils";

/** Importing custom Styles */
import classes from './Generic.module.css'

/** Main Components */
class GenericTable extends React.Component {
    constructor (props) {
        super(props);
        this.state = {checked:false, eventName:null}
    }
    
    handleCheck = (e) => {
        this.setState({ checked: !this.state.checked, eventName: e.target.name });
    }
    
    // Meghana(tbd) : should not use this method just on checking checkbox
    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.checked !== this.state.checked){
            let selectNode = this.props.propsRedux.bulkEdit.selectedNodeBulk?this.props.propsRedux.bulkEdit.selectedNodeBulk:[];
            if(this.state.checked){
                selectNode.push(this.state.eventName)
                this.props.setBulkEdit({...this.props.propsRedux.bulkEdit,selectedNodeBulk:selectNode})
            }else{
                this.props.setBulkEdit({...this.props.propsRedux.bulkEdit,
                selectedNodeBulk: selectNode.filter(x => x !== this.state.eventName)});
            }
            
        }
    }

    render() {
        if(this.props.error){
            var message = this.props.error;
            return (<Table striped bordered hover className={ this.props.className }>
                <thead>
                    <tr>
                        <th > Error: {this.props.error} </th>
                    </tr>
                    
                </thead>
                </Table>);
        }
        
        var priority = (this.props.priorityOfGeneric) ? this.props.priorityOfGeneric : 1;
        var evalTrueResponseKey = '';
        var evalTrueIterationKey = '';
        if(this.props.genericResponse.evalTrue){
            evalTrueResponseKey =  getResponseKeyFromObj(this.props.genericResponse.evalTrue);
            evalTrueIterationKey = getIterationKeyFromObj(this.props.genericResponse.evalTrue[evalTrueResponseKey]);
        }
        var evalFalseResponseKey = this.props.genericResponse.evalFalse ? getResponseKeyFromObj(this.props.genericResponse.evalFalse) : '';
        var genericValues = {
            priority: priority,
            evalTrueResponseKey: evalTrueResponseKey,
            evalTrueIterationKey: evalTrueIterationKey,
            evalFalseResponseKey: evalFalseResponseKey,
            evalTrueResponseKey: evalTrueResponseKey,
            evalTrueResponses: ( 
                    this.props.genericResponse.evalTrue && 
                    this.props.genericResponse.evalTrue[evalTrueResponseKey] && 
                    this.props.genericResponse.evalTrue[evalTrueResponseKey][evalTrueIterationKey] && this.props.genericResponse.evalTrue[evalTrueResponseKey][evalTrueIterationKey]
                ) 
                ? [ ...(this.props.genericResponse.evalTrue[evalTrueResponseKey][evalTrueIterationKey]) ]
                : [],
            evalFalseResponse: ( 
                    this.props.genericResponse.evalFalse && 
                    this.props.genericResponse.evalFalse[evalFalseResponseKey] 
                ) 
                ? (this.props.genericResponse.evalFalse[evalFalseResponseKey]) 
                : [],
        };

        return (
            <Table striped bordered hover className={ this.props.className }>
                <thead>
                    <tr>
                        <th colSpan={5} > Generic Rules: {this.props.node} </th>
                    </tr>
                    <tr>
                        {this.props.propsRedux.bulkEdit.state && !this.props.propsRedux.bulkEdit.applyChanges && <th rowSpan={1} ></th>}
                        <th rowSpan={2} >Priority</th>
                        <th>Evaluation type</th>
                        <th>Response Type</th>
                        <th>Iteration Type</th>
                        <th>Response (s)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={ 'response_row_evalTrue' }>
                        {
                            this.props.propsRedux.bulkEdit.state  && 
                            !this.props.propsRedux.bulkEdit.applyChanges && 
                            <td rowSpan={1} >
                                <input 
                                    type="checkbox" 
                                    className={ classes.CheckBox } 
                                    name={this.props.node} 
                                    onChange={this.handleCheck} 
                                    defaultChecked={this.state.checked} 
                                    disabled={this.props && this.props.enable} 
                                />
                            </td>
                        }
                        <td rowSpan={2} > 
                            <Prioirity 
                                name = "priority"
                                selectedValue={ genericValues.priority }
                                onChange={ this.props.onInputValueChange }
                                dataRulekey={ "generic" }
                                dataNode= { this.props.node }
                            />
                        </td>
                        <td> Eval True </td>
                        <td>
                            <ResponseTypes 
                                name = "evalTrueResponseType"
                                selectedValue={ genericValues.evalTrueResponseKey }
                                onChange={ this.props.onInputValueChange }
                                dataRulekey={ "generic" }
                                dataNode= { this.props.node }
                            />
                        </td>
                        <td>
                            <IterationTypes 
                                name = "evalTrueIterationType"
                                selectedValue={ genericValues.evalTrueIterationKey }
                                onChange={ this.props.onInputValueChange }
                                dataRulekey={ "generic" }
                                dataNode= { this.props.node }
                            />
                        </td>
                        <td className={ [ "no-padding" ].join(' ') }>
                            <RulesResponses
                                responsesArr={ genericValues.evalTrueResponses }
                                showResponseModal={ this.props.showResponseModal }
                                iterationKey={ genericValues.evalTrueIterationKey }
                                dataRulekey={ "generic" }
                                evalKey={ "evalTrue" }
                                responseKey={ genericValues.evalTrueResponseKey }
                                isAddResponseVisible={ true }
                                dataNode={ this.props.node }
                            />
                        </td>
                    </tr>
                    {
                        this.props.node != global.CUST_CONSTANTS.STORY_NONE_RESPONSE &&
                        <tr key={ 'response_row_evalFalse' }>
                            {this.props.propsRedux.bulkEdit.state && !this.props.propsRedux.bulkEdit.applyChanges && <td rowSpan={1} ></td>}
                            <td> Eval False </td>
                            <td>
                                <ResponseTypes 
                                    name = "evalFalseResponseType"
                                    selectedValue={ genericValues.evalFalseResponseKey }
                                    onChange={ this.props.onInputValueChange }
                                    dataRulekey={ "generic" }
                                    dataNode= { this.props.node }
                                />
                            </td>
                            <td>
                                -
                            </td>
                            <td className={ [ "no-padding" ].join(' ') }>
                                <RulesResponses
                                    responsesArr={ [ genericValues.evalFalseResponse ] }
                                    showResponseModal={ this.props.showResponseModal }
                                    isAddResponseVisible={ false }
                                    iterationKey={ null }
                                    dataRulekey={ "generic" }
                                    evalKey={ "evalFalse" }
                                    responseKey={ genericValues.evalFalseResponseKey }
                                    dataNode={ this.props.node }
                                />
                            </td>
                        </tr>
                    }
                </tbody>
            </Table>
        );
    }
}

/** Defining prop types requirements */
GenericTable.propTypes = {
    genericResponse: PropTypes.object.isRequired,
    onInputValueChange: PropTypes.func.isRequired,
    showResponseModal: PropTypes.func.isRequired,
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            bulkEdit: state.rules.bulkEdit,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setBulkEdit })(GenericTable);

