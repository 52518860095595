/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';

/** Importing custom Components */
import PreloaderImage from "../../../assets/images/ajax-loader.gif";

/** Importing custom Styles */
import classes from './Preloader.module.css';

/** Main Components */
const Preloader = props => {
    return (
        <div className={ [ classes.Preloader ].join(' ') }>
            <div className={ [ classes.LoaderDiv ].join(' ') }>
                <img className={ [ classes.PreloaderImage ].join(' ') } src={ PreloaderImage } alt="Loading..." />
                <br />
                <span className={ [ classes.LoadingText ].join(' ') }>Loading...</span>
            </div>
        </div>
    );
}

/** Exporting main component */
export default Preloader;