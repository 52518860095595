/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Icon } from '@iconify/react';

import PropTypes from 'prop-types';

/** Importing custom Components */

/** Importing custom Styles */
import classes from './TrainAndPublishLoader.module.css';

import { trainNPublishProcess } from '../../../../store/actions/ai-app/aiAppActions';


/** Main Components */
const TrainNPublishLoader = ({ _propsRedux, _propsOwned, trainNPublishProcess }) => {

    let message = {};
    for (const stepKey in global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS) {
        if (Object.hasOwnProperty.call(global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS, stepKey)) {
            const stepJson = global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS[stepKey];
            message[stepJson.stepNo] = stepJson.msg;
        }
    }


    useEffect(()=>{
        //debugger;
        // debugger;
        if (_propsRedux.currentStep > 0) {
            // debugger;
            window.stopFromUnload = true;
            let trainObj = { 
                stepNo: _propsRedux.currentStep, 
                luisAppDbId: _propsRedux.luisAppDbId, 
                // prId: _propsRedux.prId, 
                newTempVersion:_propsRedux.tempJsonVersionNew, 
                doEndSession: _propsRedux.endSession ,
                versionList: _propsRedux.aiAppVersionsList,
                storyId: _propsRedux.storyId,
            };
            trainNPublishProcess({...trainObj});
        }
    }, [_propsRedux.currentStep]);

    // const message = {
    //     1: "Exporting App",
    //     2: "Creating New Dataset",
    //     3: "Importing Dataset",
    //     4: "Training",
    //     5: "Training",
    //     6: "Publishing",
    //     7: "Finalising",
    //     8: "Finalising",
    //     9: "Finalising",
    //     10: "Ending Session"
    // };
    return (
        <>
        {
            (_propsRedux.currentStep > 0) &&

            <div className={ [ classes.Overlay ].join(' ') }> 
                <h3>{message[_propsRedux.currentStep]}</h3>
                <Icon icon="line-md:loading-twotone-loop" className={ [ classes.LoadingIcon ].join(' ') } />
            </div>
        }
        </>
    );
}

/** Defining prop types requirements */
TrainNPublishLoader.propTypes = {
    _propsRedux: PropTypes.shape({
        currentStep: PropTypes.number.isRequired,
        tempJsonVersionOld: PropTypes.string,
        tempJsonVersionNew: PropTypes.string,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    //debugger;
    return {
        _propsRedux: {
           currentStep: state.aiApp.currentStep,
            tempJsonVersionNew: state.aiApp.tempJsonVersionNew,
            endSession: state.aiApp.endSession,
            luisAppDbId: state.luis_app.appDbId,
            // prId: state.prId,
            aiAppVersionsList: state.aiApp.versionsList,
            storyId:state.story.storyId,
        },
        _propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { trainNPublishProcess })(TrainNPublishLoader);