
import { params } from "../../../data/params";
import { INCREMENT_TRAINING_STEP, SET_TEMP_VERSION_NEW, SET_TEMP_VERSION_OLD, SET_VERSION_LIST ,SET_END_SESSION} from "../../actions/types";

const initialState = {
    /**
     * ongoingAxiosCalls : cancel token if ongoing post request of saving rules
     */
    currentStep: 0,
    // endSession: false,
    tempJsonVersionOld: null,
    tempJsonVersionNew: null,
    versionsList: null
}


const aiAppReducer = (state = initialState, action) => {
// debugger;
console.log("action",action);
    switch (action.type) {
        case INCREMENT_TRAINING_STEP:
            if (action.payload && action.payload.reset) {
                return {
                    ...state,
                    currentStep: 0
                }
            }
            let nextStep = state.currentStep+1;
            return {
                ...state,
                currentStep: nextStep
            }
        case SET_TEMP_VERSION_OLD:
            return {
                ...state,
                tempJsonVersionOld: action.payload
            }
        case SET_TEMP_VERSION_NEW:
            return {
                ...state,
                tempJsonVersionNew: action.payload
            }
        case SET_END_SESSION:
            return {
                ...state,
                endSession: action.payload
            }
        case SET_VERSION_LIST:
            return {
                ...state,
                versionsList: action.payload
            }
    
        default:
            return state;
    }
}

export default aiAppReducer;
