/*
 * Component Description : Sync luis app process and button
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { messages } from '../../../../data/strings';
import LoadingIcon from '../../../UI/LoadingIcon/LoadingIcon';
// import Icon from 'react-fa';
import { Icon } from '@iconify/react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import classes from './SyncApp.module.css'
import { getNoOfExpectedCalls, importableProperties, syncingStepsDict } from '../../../../utils/luisAppUtils';
import { updateFetchPercentage,checkIsInSync, endSyncProcess, exportAppJson, exportUtterances, importProp, incrementSyncingSteps, makeOldInactive, importUtterances } from '../../../../store/actions/luis-app/luisAppActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { getEntites, getIntents, getSampleUtterances, setViewType } from '../../../../store/actions/rules/rulesActions';
import { checkIfPublishedLater } from '../../../../store/actions/ai-app/aiAppActions';


/** Importing custom Components */

/** Importing custom Styles */
const outOfSyncColor = '#ff726f'

/** Main Components */
class SyncApp extends React.Component {
    
    /** Lifecycle methods start */
        
        constructor (props) {
        super(props);
                
        this.state = {
            currentSyncingStep: 0,
            currentOngoingCall: 0,
            currentExpectedTotalCalls: 0,
            appName: null,
            showProgress:null,
            totalPropsCount:{
                [importableProperties.INTENTS]: 0,
                [importableProperties.ENTITIES]: 0,
                [importableProperties.UTTERANCES]: 0,
            }


        }
        // props.checkIsInSync(props.propsRedux.appId);
    }
    componentDidUpdate(prevProps){
        const isEntitesCountChanged = this.props.propsRedux.totalPropsCount[importableProperties.ENTITIES] !== prevProps.propsRedux.totalPropsCount[importableProperties.ENTITIES]
        const isIntentsCountChanged = this.props.propsRedux.totalPropsCount[importableProperties.INTENTS] !== prevProps.propsRedux.totalPropsCount[importableProperties.INTENTS]
        const isUtterancesCountChanged = this.props.propsRedux.totalPropsCount[importableProperties.UTTERANCES] !== prevProps.propsRedux.totalPropsCount[importableProperties.UTTERANCES]

        // const isAppInSyncChanged = this.props.propsRedux.isAppInSync !== prevProps.propsRedux.isAppInSync
        

        if(isEntitesCountChanged){
            if(this.props.propsRedux.totalPropsCount[importableProperties.ENTITIES]>0){
                this.setState({
                    totalPropsCount:{
                        ...this.state.totalPropsCount,
                        [importableProperties.ENTITIES]: this.props.propsRedux.totalPropsCount[importableProperties.ENTITIES],
                        
                    }
                })
            }
        }
       
        if(isIntentsCountChanged){
            if(this.props.propsRedux.totalPropsCount[importableProperties.INTENTS]>0){
                this.setState({
                    totalPropsCount:{
                        ...this.state.totalPropsCount,
                        [importableProperties.INTENTS]: this.props.propsRedux.totalPropsCount[importableProperties.INTENTS],
                        
                    }
                })
            }
        }

        if(isUtterancesCountChanged){
            if(this.props.propsRedux.totalPropsCount[importableProperties.UTTERANCES]>0){
                this.setState({
                    totalPropsCount:{
                        ...this.state.totalPropsCount,
                        [importableProperties.UTTERANCES]: this.props.propsRedux.totalPropsCount[importableProperties.UTTERANCES],
                    }
                })
            }
        }




    }
    
   
    static getDerivedStateFromProps = (nextProps, prevState) => {
        
        
        if (prevState.currentSyncingStep != nextProps.propsRedux.syncingStep) {
            if (nextProps.propsRedux.syncingStep == syncingStepsDict.EXPORT_JSON.stepNo) {
                nextProps.exportAppJson(nextProps.propsRedux.appId);
                return {
                    currentSyncingStep: nextProps.propsRedux.syncingStep,
                    currentOngoingCall: 0,
                    currentExpectedTotalCalls: 0
                }
            } else if (nextProps.propsRedux.syncingStep == syncingStepsDict.MAKE_OLD_INACTIVE.stepNo) {
                nextProps.makeOldInactive(nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId);
                return {
                    currentSyncingStep: nextProps.propsRedux.syncingStep,
                    currentOngoingCall: 0,
                    currentExpectedTotalCalls: 0
                }
            } else if (nextProps.propsRedux.syncingStep == syncingStepsDict.SYNC_PROCESS_END.stepNo) {
                nextProps.endSyncProcess(nextProps.propsRedux.appDbId, false, nextProps.propsRedux.storyId);
                
                return {
                    currentSyncingStep: nextProps.propsRedux.syncingStep,
                    currentOngoingCall: 0,
                    currentExpectedTotalCalls: 0
                }
            } else {
                let actionLimit = 0;
                let totalPropCount = 0
                // if (nextProps.propsRedux.syncingStep == syncingStepsDict.EXPORT_UTTERANCES.stepNo) {
                //     actionLimit = 500;
                //     totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.UTTERANCES];
                // } else if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_INTENTS.stepNo) {
                //     actionLimit = 100;
                //     totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.INTENTS];
                // } else if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_ENTITIES.stepNo) {
                //     actionLimit = 100;
                //     totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.ENTITIES];
                // } else if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_UTTERANCES.stepNo) {
                //     actionLimit = 20;
                //     totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.UTTERANCES];
                // }
                // let noOfExpectedCalls = getNoOfExpectedCalls(totalPropCount, actionLimit);
                
                // /** Making multiple calls */
                // for (let index = 0; index < noOfExpectedCalls; index++) {
                //     let offset = (index * actionLimit);
                //     if (nextProps.propsRedux.syncingStep == syncingStepsDict.EXPORT_UTTERANCES.stepNo) {
                //         nextProps.exportUtterances( nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, actionLimit, offset);
                //     }
                //     if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_INTENTS.stepNo) {
                //         nextProps.importProp(importableProperties.INTENTS ,nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, actionLimit, offset);
                //     }
                //     if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_ENTITIES.stepNo) {
                //         nextProps.importProp(importableProperties.ENTITIES ,nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, actionLimit, offset);
                //     }
                // }
                
                let currentOngoingCall = 0
                if (nextProps.propsRedux.syncingStep == syncingStepsDict.EXPORT_UTTERANCES.stepNo) {
                    totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.UTTERANCES];
                    nextProps.exportUtterances( nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, 0, totalPropCount);
                }
                if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_INTENTS.stepNo) {
                    totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.INTENTS];
                    nextProps.importProp(importableProperties.INTENTS ,nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, 0, totalPropCount);
                    
                }
                if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_ENTITIES.stepNo) {
                    totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.ENTITIES];
                    nextProps.importProp(importableProperties.ENTITIES ,nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, 0, totalPropCount);
                    
                }
                if (nextProps.propsRedux.syncingStep == syncingStepsDict.IMPORT_UTTERANCES.stepNo) {
                    totalPropCount = nextProps.propsRedux.totalPropsCount[importableProperties.UTTERANCES];
                    nextProps.importUtterances(importableProperties.UTTERANCES ,nextProps.propsRedux.appDbId, nextProps.propsRedux.moduleSessionId, 0, totalPropCount)
                }
                
                return {
                    currentSyncingStep: nextProps.propsRedux.syncingStep,
                    currentExpectedTotalCalls:(Object.keys(syncingStepsDict).length-3)*100
                } 
            }
        } 
        // else if (prevState.currentOngoingCall != nextProps.propsRedux.ongoingCallCount[nextProps.propsRedux.syncingStep]) {
        //     let currentOngoingCall = nextProps.propsRedux.ongoingCallCount[nextProps.propsRedux.syncingStep];
        //     if (currentOngoingCall == prevState.currentExpectedTotalCalls) {
        //         nextProps.incrementSyncingSteps();
        //         return {
        //             currentOngoingCall: 0,
        //         }
        //     }
        // } 
        
        if (prevState.appName != nextProps.propsRedux.appName) {
            return {
                appName: nextProps.propsRedux.appName,
            }
        }
        return null;
    }

    /** Lifecycle methods end */

    // makeMultipleApiCalls = (noOfExpectedCalls) => {
    //     for (let index = 0; index < noOfExpectedCalls; index++) {
    //         let limit = limit;
    //         let offset = (index * limit);
    //         if (this.props.propsRedux.syncingStep == syncingStepsDict.EXPORT_UTTERANCES.stepNo) {
    //             this.props.exportUtterances( this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
    //         }
    //         if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_INTENTS.stepNo) {
    //             this.props.importProp(importableProperties.INTENTS ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
    //         }
    //         if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_ENTITIES.stepNo) {
    //             this.props.importProp(importableProperties.ENTITIES ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
    //         }
    //         if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_UTTERANCES.stepNo) {
    //             this.props.importProp(importableProperties.UTTERANCES ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
    //         }
    //     }
    // }
    showHideProgess = () => {
        this.setState({showProgress:!this.state.showProgress})
    }
    syncLuisApp = () => {
        this.props.checkIfPublishedLater({luisAppDbId:this.props.propsRedux.appDbId,mode:global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_SYNC})
    }

    formatDataCount = () => {
        const intentString = `${importableProperties.INTENTS} : ${this.state.totalPropsCount[importableProperties.INTENTS]} `
        const utteranceString = `${importableProperties.UTTERANCES} : ${this.state.totalPropsCount[importableProperties.UTTERANCES]} `
        const entityString = `${importableProperties.ENTITIES} : ${this.state.totalPropsCount[importableProperties.ENTITIES]} `

        return '<br/>' + intentString + '<br/>'  + utteranceString + '<br/>' + entityString
    }
    
    render() {
       
        return (
            <>
            <div className={classes.progressBarButton}>
             {  this.state.showProgress &&
             
                    < button
                        
                        className={[classes.progressBarContainer ,'btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue'].join(' ')}
                        data-tip={importableProperties && this.state.totalPropsCount ?["Intent:",this.state.totalPropsCount[importableProperties.INTENTS] ,"Entity:",this.state.totalPropsCount[importableProperties.ENTITIES],"Utterrance:",this.state.totalPropsCount[importableProperties.UTTERANCES]].join(' '):"loading..."}
                        data-class="tooltip_cust"
                        data-place = "top"
                        disabled = {this.props.propsRedux.percentageFetch!==100}
                        onClick = {this.showHideProgess}
                    >
                        
                        <ProgressBar 
                            data-tip={importableProperties && this.state.totalPropsCount ?["Intent:",this.state.totalPropsCount[importableProperties.INTENTS] ,"Entity:",this.state.totalPropsCount[importableProperties.ENTITIES],"Utterrance:",this.state.totalPropsCount[importableProperties.UTTERANCES]].join(' '):"loading..."}
                            data-class="tooltip_cust"
                            data-place = "top" 
                            className={classes.progressBar} Bar = 'success' label={`${this.props.propsRedux.percentageFetch}%`} animated now={this.props.propsRedux.percentageFetch} max={100} /> 
                            <div className={classes.closeButton}>
                            <Icon icon="system-uicons:cross-circle" width="14"/>
                            </div>
                            
                              
                    </button>
                }
                </div>
                    {
                        <button 
                        className={[classes.syncButton , 'btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue'].join(' ')}                          
                            data-tip={ messages.syncLuisApp + " : " + this.state.appName + this.formatDataCount() }
                            data-class="tooltip_cust"
                            data-place = "top"
                            disabled = {this.props.propsRedux.syncingInProgress}
                            onClick = {this.syncLuisApp}
                        >
                            
                            {
                                this.props.propsRedux.syncingInProgress
                                ? <LoadingIcon/>
                                :  <Icon  color={!this.props.propsRedux.isAppInSync && outOfSyncColor}  icon="ep:refresh" 
                                />
                                
                            }
                                
                        </button>
                    }
            </>
        );
    }
}

/** Defining prop types requirements */
SyncApp.propTypes = {
    propsRedux: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        appDbId: PropTypes.number.isRequired,
        moduleSessionId: PropTypes.string.isRequired,
        syncingStep: PropTypes.number.isRequired,
        totalPropsCount: PropTypes.object.isRequired,
        ongoingCallCount: PropTypes.object.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            appId: state.luis_app.appId,
            appDbId: state.luis_app.appDbId,
            appName: state.luis_app.appName,
            moduleSessionId: state.luis_app.moduleSessionId,
            syncingInProgress: state.luis_app.syncingInProgress,
            syncingStep: state.luis_app.syncingStep,
            totalPropsCount: state.luis_app.totalPropsCount,
            ongoingCallCount: state.luis_app.ongoingCallCount,
            storyId: state.story.storyId,
            percentageFetch:state.luis_app.percentageFetch,
            isAppInSync:state.luis_app.isAppInSync
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { endSyncProcess, exportAppJson, exportUtterances, importProp, incrementSyncingSteps, makeOldInactive, checkIsInSync, importUtterances, updateFetchPercentage,setViewType, getSampleUtterances,getIntents,getEntites ,checkIfPublishedLater})(SyncApp);
