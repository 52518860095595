/*
 * Component Description :  Underline text with attached entity
 * Author: Akash Gupta
 */
/** Importing pre defined Libraries/Components */
import React, {useState, useEffect, useRef} from 'react';

/** Importing third party packages  */
import ReactTooltip from "react-tooltip";

/** Importing custom Styles */
import classes from './HighlightText.module.css';


const HighlightText = ({sentence, entity, entityPositions}) =>{
    const containerRef = useRef([]);
    const firstHighlightedRef = useRef([]);
    const [entityWidth,setEntityWidth] = useState([]);

    useEffect(()=>{
        ReactTooltip.rebuild();

        // Subtracting end position of the highlighted span from starting position  of it
        
        setEntityWidth(entityPositions.map((el,index)=>{
          return(containerRef.current[index].getBoundingClientRect().right -
          firstHighlightedRef.current[index].getBoundingClientRect().left
          )
        }))
    },[entity])

    
    // recursive function to add entities in utterance
    const  highlight = (sentence,start,end,entity,posIndex) => {
      
        if(posIndex === entityPositions.length ){
          return sentence; 
        }

        let subs = sentence.substring(start, end + 1);
        let splitted = [];
        // getting the string upto highlighted text
        splitted.push(sentence.slice(0, start));
        // getting the string after highlighted text
        splitted.push(sentence.slice(end+1));

        // appending highlighted span between start and end strings
        return splitted.map((item,index) => {
          if(index === splitted.length-1){

            if(entityPositions[posIndex+1]){
              const nextStartPos = entityPositions[posIndex+1].startPos - entityPositions[posIndex].endPos
              const nextEndPos = entityPositions[posIndex+1].endPos-entityPositions[posIndex].endPos
              return highlight(item,nextStartPos,nextEndPos,entity,posIndex+1)

            }else{
              return item
            }
          }
          else{
            return <React.Fragment 
                        key={entity + index}
                    >
                        {item}
                        <span
                          ref={el => containerRef.current[posIndex] = el}
                          className={classes.underline} 
                        >
                            {entityAddition(subs,posIndex)}
                        </span>
                    </React.Fragment>
          }
      });

    }

    /**
     * helper function to add entity span below first word
     * @param {string}  subs - substring which will be highlighted
     */
    const entityAddition = (subs,posIndex) => {
        
        let subsArr = subs.split(" ");
        let entityDiv = (
          <span
            className={classes.entityContainer}
          >
            <span
              ref={el => firstHighlightedRef.current[posIndex] = el}
            >
              {subsArr[0]}
            </span>
            <span
              data-tip={entity} 
              data-class="tooltip_cust"
              data-place = "top"
              className={classes.entity}
              style={{
                width: entityWidth[posIndex],
              }}
            >
              {entity}
            </span>
          </span>
        );
        subsArr.shift();

        return (
          <span 
          >
            {entityDiv} {subsArr.join(" ")}
          </span>
        );
    };


      return highlight(sentence,entityPositions[0].startPos,entityPositions[0].endPos,entity,0);
}

export default HighlightText;