import React from 'react';
import classes from './EntityChip.module.css'
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { truncateString } from '../../../../../utils/rulesUtils';


const EntityChip = ({entity,editHandler,tools, deleteHandler}) =>{
    const iconStyles = {
        color:'white',
        cursor:'pointer',
        fontSize:'1.2rem'
    }

    const tooltipString = (entity) =>{
        return `${entity.value}
            ${entity.interruption && entity.interruption.id ? 'Interruption:' + ' ' + entity.interruption.BRText: ''}
        `
    }

    return (
        <div className={[classes.entityChipStyle]}
           
        >
        
        
        <span className={classes.entityChip } 
                data-tip = {tooltipString(entity)} 
                data-place = "top" 
                data-class= "tooltip_cust" >
                <span onClick={()=> tools.editable && editHandler(entity)} >
                Entity:<span  className={[classes.entityValueStyle,(entity.criteria===0 ? classes.ShouldHave:classes.ShouldNotHave)].join(' ')} >
                
                        {entity["value"].length > 10 ?
                            (
                            <>
                                {`${entity["value"].substring(0, 10)}...`}</>
                            ) :
                            <>{entity["value"]}</>
                        }
                        </span></span>
                        <Icon 
                                onClick={(e)=>deleteHandler(e,entity)} 
                                icon="gridicons:cross" width="12" height="12" color='black'  hFlip={true} className={[classes.IconStyle].join(' ')} />
                
            </span> 
        </div>
    )
}

EntityChip.propTypes = {
    entity: PropTypes.object,
    tools: PropTypes.object.isRequired,
    stringLength: PropTypes.number.isRequired

}

export default EntityChip