/*
 * Component Description : Utterance populated modal used to configure or add utterances in Block
 * Author: Ankit Aditya
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

/** Importing third party packages  */
import SearchableSelect from "react-select";

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";

/** Importing custom Styles */
import classes from './UtteranceModal.module.css';


import { setShowUtteranceModal,setRulesAndCallFlowUpdatedState, setCallFlowJson } from "../../../store/actions/rules/rulesActions";
import { getUtterancesForModal } from "../../../utils/rulesUtils";
import { setAlert } from "../../../store/actions/common/commonActions";
import { messages } from "../../../data/strings";

/** Main Components */
class UtteranceModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newUtteranceName: null,
            newUtteranceId: null,
            relatedIntent: null,
            intentUtterances: null,
            selectionError: null,
            utterancesForModal: getUtterancesForModal(this.props.propsRedux.sampleUtterances)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.propsRedux.sampleUtterances !==this.props.propsRedux.sampleUtterances){
            this.setState({utterancesForModal: getUtterancesForModal(this.props.propsRedux.sampleUtterances)})
        }
    }
    onHide = () => {
        global.recordAudit("Utterance modal closed");
        this.setState({ newUtteranceName: null, intentUtterances: null, newUtteranceId: null, relatedIntent: null });
        this.props.setShowUtteranceModal(false, null,this.props.propsRedux.statements.key,this.props.propsRedux.statements.block);
    }

    onShow = () => {
        global.recordAudit("Utterance modal Opened");
        var intentUtterance = [];
        this.state.utterancesForModal.map((value, index) => {
            if (value.intent === (this.props.propsRedux.statements ? this.props.propsRedux.statements['intent'] : null)) {
                intentUtterance.push(value.utterance);
            }
        });
        this.setState({ intentUtterances: intentUtterance });
    }

    onAddConfigureUtterance = () => {
        global.recordAudit("Utterance selected : " + this.state.newUtteranceName);
        /** Call Flow version 1.1 and old support */
        var newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;

        /* If found sample utterances in callflow step then change to new else configure sample utterances in step */
        if (newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances) {
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0].utteranceText = this.state.newUtteranceName;
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]['utteranceId'] = this.state.newUtteranceId;
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]['relatedIntent'] = this.state.relatedIntent;
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]['blockId'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].id;

            if(newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']){
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance']?(newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'].utteranceText!=""?newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance']:newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]):newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0];
                if (newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'].blockId==newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].id&&newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'].utteranceId!=newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0].utteranceId){
                    newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0];
                }
            }else{
                newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings'] = {}
                newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance']?newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance']:{blockId:newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].id,...newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]};
            }
        } else {
            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']]['sampleUtterances'] = [{ blockId:newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].id,utteranceText: this.state.newUtteranceName, utteranceId: this.state.newUtteranceId, relatedIntent: this.state.relatedIntent }];

            newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']?{ ...newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings'] }:{ defaultUtterance: {blockId:newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].id,...newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0]} };
            if (newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'].utteranceText==""){
                newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')]['settings']['defaultUtterance'] = newCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list[this.props.propsRedux.statements['block']].sampleUtterances[0];
            }
        }
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJson,true,true);
        this.onHide();
    }   

    onUtteranceChange = (selectedJson) => {
        global.recordAudit("Utterance selection changed : " + selectedJson.value);
        var havingFlag = false;
        /** Call Flow version 1.1 and old support */
        // var checkCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;

        // checkCallFlowJson[(this.props.propsRedux.statements ? this.props.propsRedux.statements['key'] : 'step0')].list.map((value, key) => {

        //     if (value.sampleUtterances) {
        //         if (value.sampleUtterances[0].utteranceText == selectedJson.value.utteranceText) {
        //             havingFlag = true
        //         }
        //     }
        // });
        // if (havingFlag) {
        //     this.setState({ selectionError: messages.UtteranceAlreadyPresent });
        //     havingFlag = false;
        // } else {
            this.setState({ newUtteranceName: selectedJson.value.utteranceText, newUtteranceId: selectedJson.value.utteranceId, relatedIntent: selectedJson.value.relatedIntent, selectionError: null });
        // }
    }

    formatOptionLabel = ({ label, isDisabled }) => (
        <div className={(isDisabled == true) ? [classes.UtteranceInJson].join(' ') : ''}>
            <span>{label}</span>
        </div>
    );

    render() {
        var modalButtons = [
            {
                type: 'add',
                text: 'Add Utterance',
                variant: 'primary',
                onClick: this.onAddConfigureUtterance,
                className: 'gl-btnPrimaryCust',
                isDisabled: ((this.state.newUtteranceName == null) || (this.state.selectionError != null)) ? true : false
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: this.onHide
            }
        ];
        return (
            this.props.propsRedux.sampleUtterances &&
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.onShow}
                onHide={this.onHide}
                title={"Utterances For " + (this.props.propsRedux.statements ? this.props.propsRedux.statements['intent'] : null)}
                footerComponents={modalButtons}
            >
            {
                    (this.state.intentUtterances!==null) &&
                    (this.state.intentUtterances.length===0) &&
                    <div className={['alert alert-danger'].join(' ')} style={{ marginTop: '10px', marginBottom: '0px' }}>
                       { "No Sample Utterances Found for " + this.props.propsRedux.statements['intent'] }
                    </div>
            }
            {
                (this.state.intentUtterances!==null) &&
                (this.state.intentUtterances.length>0) &&
                <SearchableSelect
                    name="Utterances"
                    options={this.state.intentUtterances}
                    onChange={this.onUtteranceChange}
                    formatOptionLabel={this.formatOptionLabel}
                />
            }
                {
                    this.state.selectionError &&
                    <div className={['alert alert-danger'].join(' ')} style={{ marginTop: '10px', marginBottom: '0px' }}>
                        {this.state.selectionError}
                    </div>
                }
            </Modal>
        );

    }
}

/** Defining prop types requirements */
UtteranceModal.propTypes = {
    propsRedux: PropTypes.shape({
        sampleUtterances: PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            sampleUtterances: state.rules.sampleUtterances,
            rulesJson: state.rules.rulesJson,
            statements: state.rules.statements,
            isChanged: state.rules.isChanged,
            selectedNode: state.rules.selectedNode,
            callFlowJson: state.rules.callFlow.callFlowJSON
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setCallFlowJson,setRulesAndCallFlowUpdatedState, setShowUtteranceModal, setAlert })(UtteranceModal);