/*
 * Component Description : Component to show tag
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';

/** Importing custom Components */
import classes from './Tags.module.css';

/** Importing custom Styles */

/** Main Components */
const Tags = ({ propsRedux, propsOwned }) => {

    return (
        
        <div className={ [ propsOwned.className, "voice-by" ].join(' ') } >
            
            {
                propsOwned.tags && 
                Object.keys(propsOwned.tags).map( tagCollection => (
                    propsOwned.tags[tagCollection].map( ( tag, index ) => (
                        <span 
                            key={ propsOwned.keyBase + "_" + index } 
                            className={ [ '', classes.Tag ].join(' ') }
                            style={ 
                                (
                                    propsRedux.brandTags &&
                                    propsRedux.brandTags[tagCollection] &&
                                    propsRedux.brandTags[tagCollection][tag] &&
                                    propsRedux.brandTags[tagCollection][tag].bg_color
                                ) ? { backgroundColor: propsRedux.brandTags[tagCollection][tag].bg_color, color: "#ffffff" } : {} 
                            }
                        >
                            { tagCollection }:{ tag }
                        </span>
                    ))
                ))
            }
        </div>
    );
}

/** Defining prop types requirements */
Tags.propTypes = {
    propsRedux: PropTypes.shape({
        brandTags: PropTypes.object.isRequired
    }),
    propsOwned: PropTypes.shape({
        tags: PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            brandTags: state.rules.brandTags,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  })(Tags);