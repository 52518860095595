import React from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import {showResponseModal} from "../../../../store/actions/rules/rulesActions";

const AddNode = (props) =>{

    const onAddNode = () => {

        global.recordAudit("Add node clicked");
        props.showResponseModal(true, true);
    }

    return (
        props.propsRedux.rulesJson &&
        <button className={"btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue"} 
                onClick={ onAddNode }  
        >
        <Icon name="plus" className="icon-btn"/>
        Node
        </button>

    )    
    }
const mapStateToProps = (state, props) => {

    return {
        propsRedux: {
            // switchOptions: state.rules.switchOptions,
            rulesJson: state.rules.rulesJson
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  showResponseModal })(AddNode);