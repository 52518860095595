/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useEffect } from 'react';
import { useTable, useFilters, useSortBy, useGlobalFilter } from 'react-table';
import ReactTooltip from "react-tooltip";

/** Importing custom Components */
import DefaultColumnFilter from "../TableComponents/DefaultColumnFilter";
import TablesHead from "../TablesHead/TablesHead";
import SimpleViewRow from "./SimpleViewRow/SimpleViewRow";

/** Importing custom Styles */
import classes from './SimpleViewTable.module.css';

/** Main Components */
const SimpleViewTable = props => {
    const columns = React.useMemo(() => [...props.columns],[]);
    const data = props.tableData;
    
    useEffect(()=>{
        ReactTooltip.rebuild();
        setHiddenColumns(props.hiddenColumns);
    }, [props.hiddenColumns]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        setHiddenColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                hiddenColumns: props.hiddenColumns
            },
        },
        useGlobalFilter,
        useFilters, // useFilters!
        useSortBy,
    );
    ReactTooltip.rebuild();

    return (
        <>
            <table {...getTableProps()} className={ [ classes.Table ].join(' ') } >
                <TablesHead 
                    visibleColumns={ visibleColumns }
                    state={ state }
                    headerGroups={ headerGroups }
                    preGlobalFilteredRows={ preGlobalFilteredRows }
                    globalFilter={ state.globalFilter }
                    setGlobalFilter={ setGlobalFilter }
                    rows={ rows }
                />
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <SimpleViewRow
                                    key={ "Simple_view_table_row_" + rowIndex }
                                    row={ row }
                                    rows={ rows }
                                    rowIndex={ rowIndex }
                                    parentProps={ props }
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

/** Exporting main component */
export default SimpleViewTable;