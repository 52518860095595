/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { messages } from '../../../data/strings';
import Select from '../Inputs/RulesSelect/RulesSelect';

/** Importing custom Components */

/** Importing custom Styles */
import classes from './SyncPopUp.module.css';
/** Main Components */
class SyncPopUp extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        this.choices = {
            syncCallFlowWithResponses : {
                label: 'Sync Call Flow Responses from Rules',
                className: [ "", "" ].join(' '),
                message: messages.syncCallFlowWithResponses,
                onClick: this.props.syncCallFlow,
            },
            resetCallFlow : {
                label: 'Start Over',
                className: [ classes.BtnStartOver, "btn-danger" ].join(' '),
                message: messages.resetCallFlow,
                onClick: this.props.resetCallFlow,
            }
        };
        this.state = {
            choiceKey: "syncCallFlowWithResponses"
        }
    }
    /** Lifecycle methods end */

    onSelectionChange = (e) => {
        console.log("DEBUGGER:: choice changes e.target.value", e.target.value);
        this.setState({choiceKey: e.target.value});
    }

    onSyncClick = () => {
        console.log("DEBUGGER:: onSyncClick this.state.choiceKey", this.state.choiceKey);
        console.log("DEBUGGER:: onSyncClick this.choices[this.state.choiceKey]", this.choices[this.state.choiceKey]);
        console.log("DEBUGGER:: onSyncClick this.choices[this.state.choiceKey].onClick", this.choices[this.state.choiceKey].onClick);
        this.choices[this.state.choiceKey].onClick();
        this.props.onClose();
    }

    render() {
        return (
            <div className="react-confirm-alert-body">
                <h1>
                    Reset Call Flow
                </h1>
                <select value={ this.state.choiceKey ? this.state.choiceKey : 0} onChange={ e => this.onSelectionChange(e) } >
                    {
                        Object.keys(this.choices).map( ( choiceKey ) => {
                            let choiceObj = this.choices[choiceKey]
                            return <option key={ choiceKey }  value={ choiceKey } >{ choiceObj.label }</option>
                        })
                    }
                </select>
                <br />
                <br />
                {
                    this.choices[this.state.choiceKey].message
                }
                
                <div className="react-confirm-alert-button-group">
                    <button className={ [ (this.state.choiceKey === "resetCallFlow" ? classes.BtnStartOver : "") ].join(' ') } onClick={ this.onSyncClick } >Sync</button>
                    <button onClick={ this.props.onClose } >Cancel</button>
                </div>
            </div>
        );
    }
}

/** Exporting main component */
export default SyncPopUp;