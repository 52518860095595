/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';

/** Importing custom Components */

/** Importing custom Styles */
import classes from './TablesHead.module.css';
import Icon from 'react-fa';
import GlobalFilter from "../TableComponents/GlobalFilter";

/** Main Components */
const TablesHead = props => {
    return (
        <thead>
            <tr className={ [ classes.TRow ].join(' ') } >
                <th className={ [ classes.THead ].join(' ') } colSpan={ (props.headerGroups && (props.headerGroups.length > 0) && props.headerGroups[0].headers && props.headerGroups[0].headers.length) ? props.headerGroups[0].headers.length : 3 } >
                    <GlobalFilter
                        preGlobalFilteredRows={ props.preGlobalFilteredRows }
                        globalFilter={ props.globalFilter }
                        setGlobalFilter={ props.setGlobalFilter }
                        rows={ props.rows }
                    />
                </th>
            </tr>
            {
                props.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className={ [ classes.TRow ].join(' ') } >
                        {
                            headerGroup.headers.map(column => {
                                let custClass = "";
                                switch (column.id) {
                                    case "node":
                                        custClass = classes.NodeCell;
                                        break;
                                    case "responseText":
                                        custClass = classes.ResponseTextCell;
                                        break;
                                    case "responseClip":
                                        custClass = classes.ResponseClipCell;
                                        break;
                                    case "responseTags":
                                        custClass = classes.ResponseTagCell;
                                        break;
                                    case "alternateResponseClip":
                                        custClass = classes.AltResponseCell;
                                        break;
                                    case "actions":
                                        custClass = classes.ResponseActionsCell;
                                        break;
                                
                                    default:
                                        break;
                                }
                                return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={ [ classes.THead, custClass ].join(' ') }>
                                        {column.render('Header')}
                                        {/* // Add the sorting props to control sorting. For this example */}
                                        {
                                            !column.disableSortBy &&
                                            <Icon name={ column.isSorted ? (column.isSortedDesc ? "sort-asc" : "sort-desc") : "sort" } className={ [ "pull-right" ].join(' ') } />
                                        }
                                    </th>
                                )
                            })
                        }
                    </tr>
                ))
            }
            {
                props.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className={ [ classes.TRow ].join(' ') } >
                        {
                            headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={ [ classes.THead, classes.FilterHead ].join(' ') }>
                                    {/* Render the columns filter UI */}
                                    {/* // we can add them into the header props */}
                                    {
                                        (column.id !== "actions") &&
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    }
                                </th>
                            ))
                        }
                    </tr>
                ))
            }
        </thead>
    );
}

/** Exporting main component */
export default TablesHead;