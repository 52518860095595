/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

/** Importing custom Components */

/** Importing custom Styles */
import classes from './ResponseClips.module.css';
import PlayIcon from '../../../UI/PlayIcon/PlayIcon';
import Tags from "../../../UI/Tags/Tags";

/** Main Components */
const ResponseClips = props => {
    return (
        <Container fluid className={ [ classes.ClipsDiv ].join(' ') }>
            {
                 props.haveClips ? props.modalResponseClips.map( clipsJson => (
                    clipsJson.audio_clip ? <Row key={ "clipDiv_" + clipsJson.id } className={ [ classes.Clip ].join(' ') } >
                        <Col sm={1}>
                            <input className="m-0" name="clip" type="radio" value={ clipsJson.id } onClick={ props.onClipSelection } defaultChecked={ ( props.selectedResponse == clipsJson.id ) } />
                        </Col>
                         <Col sm={11} className={ [ classes.ClipDetails, "d-flex align-items-center" ].join(' ') }>
                            
                                    <div className="d-flex align-items-center">
                                        <PlayIcon uid={ "responseModal_"+clipsJson.id } src={ clipsJson.filepath } name="play" className={ [ "play-icon" ].join(' ') } />
                                        <label className={ [ "ml-3", "mb-0", classes.ClipLabel ].join(' ') } > 
                                            { (clipsJson.clip_title) ? clipsJson.clip_title : clipsJson.audio_clip } 
                                        </label>
                                    </div>
                                    <div className="">
                                        {
                                            clipsJson.TagDetails && 
                                            <Tags keyBase={ "response_clip_" } tags={ clipsJson.TagDetails } />
                                        }
                                    </div>
                        </Col>
                    </Row>: ''
                ) )
                : 
                    props.modalResponseClips[0] && <Row key={ "clipDiv_" + props.modalResponseClips[0].id } className={ [ classes.Clip ].join(' ') } >
                        <Col sm={1}>
                            <input className="m-0" name="clip" type="radio" value={ props.modalResponseClips[0].id } onClick={ props.onClipSelection } defaultChecked={ ( props.selectedResponse == props.modalResponseClips[0].id ) } />
                        </Col>
                        <Col sm={11} className={ [ classes.ClipDetails, "d-flex align-items-center" ].join(' ') }>
                            
                                <div className="text-danger"> {global.CUST_CONSTANTS.NO_AUDIO_CLIPS} </div>
                        </Col>
                    </Row>
            }
        </Container>
    );
}

/** Exporting main component */
export default ResponseClips;