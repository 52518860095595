/*
 * File Description : Have common util functions being used in rules UI for call flow particularly
 * Author: Meghana Aggarwal
 */

import { params } from "../data/params";
import { getIterationKeyFromObj, getResponseKeyFromObj } from "./rulesUtils";


const getPathKey = ({nodeKey, ruleKey, responseKey, responseIterartionKey, responseObjIndex=null, evalType=null}) => {
    // # defining separator for new paths
    let separator = params.callFlowSeparator

    let pathArr = ["path", "ruleResponses"]

    if (nodeKey) { 
        pathArr.push(nodeKey)
    }
    if (ruleKey) { 
        pathArr.push(ruleKey)
    }
    if (evalType) { 
        pathArr.push(evalType)
    }
    if (responseKey) { 
        pathArr.push(responseKey)
    }
    if (responseIterartionKey) { 
        pathArr.push(responseIterartionKey)
    }
    if (responseObjIndex || responseObjIndex == 0) { 
        pathArr.push(responseObjIndex.toString())
    }

    // # pathArr = ["path", "ruleResponses", responseNode, nodeRespType, responseType, responseIterartionType, str(responseObjIndex)]
    
    // # pathKey = 'path-ruleResponses-'+responseNode+'-'+nodeRespType+'-evalTrue-'+responseType+'-'+responseIterartionType+'-'+str(responseObjIndex)

    let pathKey = pathArr.join(separator)
    return pathKey
}


export const getPathString = (referencePathObj, includeIndex=true) => {

    if (!referencePathObj.nodeKey &&  referencePathObj.node) {
        referencePathObj.nodeKey = referencePathObj.node;
    }
    
    // let pathString = "path-ruleResponses-" + referencePathObj.nodeKey + "-" + referencePathObj.ruleKey;
    // if (referencePathObj.evalKey) {
    //     pathString += "-" + referencePathObj.evalKey;
    // }
    // pathString += "-"+referencePathObj.responseKey + "-"+referencePathObj.iterationKey;
    let index = null;
    if ((parseInt(referencePathObj.index) > -1) && (includeIndex)) {
        index = referencePathObj.index;
    }
    let pathString = getPathKey({
        nodeKey:referencePathObj.nodeKey, 
        ruleKey:referencePathObj.ruleKey, 
        responseKey:referencePathObj.responseKey, 
        responseIterartionKey:referencePathObj.iterationKey, 
        responseObjIndex:index, 
        evalType:referencePathObj.evalKey
    });
    return pathString;
}

const getResponsesFromPath = (referencePathObj, rulesJson) => {
    let responses = null;
    if (referencePathObj.ruleKey === global.CUST_CONSTANTS.DEFAULT_KEYS.RULE_KEY) {
        if (
            rulesJson.ruleResponses &&
            rulesJson.ruleResponses[referencePathObj.nodeKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey]
        ) {
            responses = rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey];

        }
    } else {
        if (
            rulesJson.ruleResponses &&
            rulesJson.ruleResponses[referencePathObj.nodeKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey] &&
            rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey]
        ) {
            responses = rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey];

        }
    }
    return responses;
}

/**
 * Function to add the response in call flow when it is added in json
 * @param {*} referencePathObj 
 * @param {*} responseObj 
 * @param {*} callFlowMappings 
 * @param {*} callFlowJson 
 * @param {*} rulesJson 
 * @param {*} node default null
 * @returns 
 */
export const addResponseInCallFlow = (referencePathObj, responseObj, callFlowMappings, callFlowJson, rulesJson, node=null, stepKey = null, latestStep = 0) => {
    let totalResponses = {};
    if (referencePathObj.evalKey) {
        totalResponses = Object.keys(rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey]);
    } else {
        totalResponses = Object.keys(rulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey]);
    }
    /** Call Flow version 1.1 and old support */
    let callFlowMetaData = callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]
    let mainSequnece = callFlowMetaData['main-sequence']
    let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;
    let callFlowJsonSteps = Object.keys(callFlowJsonStepsJson);
    let nodeKey  = referencePathObj.nodeKey;
    if (node) {
        nodeKey = node;
        referencePathObj.nodeKey = node;
    }

    if (stepKey == null) {
        /** getting last step number to be added in call flow */
        let lastStep = latestStep;
        // lastStep = lastStep.replace("step","");
        lastStep = parseInt(lastStep);
        lastStep++;
        // if(callFlowJsonSteps.length > 0) {
        //     lastStep = lastStep.replace("step","");
        //     lastStep = parseInt(lastStep);
        //     lastStep++;
        // }
        stepKey = "step"+lastStep;
    }
    
    if(typeof callFlowJsonStepsJson[stepKey] == "undefined"){
        callFlowJsonStepsJson[stepKey] = {};
        callFlowJsonStepsJson[stepKey]["list"] = [];
        callFlowJsonStepsJson[stepKey]["settings"] = {};
        mainSequnece.push(stepKey)
    }

    /** getting new block according to bot response and rules */
    let { newBlock, pathString, mapperBlock } = getSyncedBlock({
        botResponseObj: responseObj, 
        newStepKey: stepKey, 
        newStepIndex: callFlowJsonStepsJson[stepKey]["list"].length, 
        pathObj: referencePathObj, 
        nodeKey: nodeKey, 
        rulesJson: rulesJson, 
        totalResponses: totalResponses,
    });
    if (newBlock) {
        callFlowJsonStepsJson[stepKey]["list"].push(newBlock);
        
        if(typeof callFlowMappings[pathString] == "undefined"){
            callFlowMappings[pathString] = { ...mapperBlock };
        }
    }

    return {
        callFlowJson: callFlowJson,
        callFlowMappings: callFlowMappings
    }
}

/**
 * Function to add the response in call flow when it is changed in rule json
 * @param {*} referencePathObj 
 * @param {*} responseObj 
 * @param {*} callFlowMappings 
 * @param {*} callFlowJson 
 * @returns 
 */
export const editResponseInCallFlow = (referencePathObj, responseObj, callFlowMappings, callFlowJson) => {

    let pathString = getPathString(referencePathObj);
    console.log('pathString in editResponseInCallFlow', pathString, 'responseObj', responseObj)
    /** Call Flow version 1.1 and old support */
    let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;
    console.log('callFlowJsonStepsJson', callFlowJsonStepsJson, 'callFlowMappings', callFlowMappings)
    if(callFlowMappings[pathString]) {
        callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"][callFlowMappings[pathString]["stepIndex"]]["id"] = responseObj["id"]
        callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"][callFlowMappings[pathString]["stepIndex"]]["BRText"] = responseObj["utterance_text"]
        if(callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"][callFlowMappings[pathString]["stepIndex"]]["metadata"] && responseObj["TagDetails"]){
            callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"][callFlowMappings[pathString]["stepIndex"]]["metadata"]["tags"] = responseObj["TagDetails"]
        }
        callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"][callFlowMappings[pathString]["stepIndex"]]["BRClip"] = responseObj["filepath"];
        callFlowMappings[pathString]["referenceId"] = responseObj["id"];
    }

    return {
        callFlowJson: callFlowJson,
        callFlowMappings: callFlowMappings
    }
}

/**
 * Function to add the response in call flow when it is removed from rule json or anywhere else
 * @param {*} referencePathObj 
 * @param {*} callFlowMappings 
 * @param {*} callFlowJson 
 * @param {*} actionLevel 
 * @returns 
 */
export const deleteResponseInCallFlow = (referencePathObj, callFlowMappings, callFlowJson, actionLevel = global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE) => {

    if ((actionLevel == global.CUST_CONSTANTS.ACTION_LEVEL.NODE) && (referencePathObj.index > 0)) {
        referencePathObj.index = 0;
    }
    let pathString = getPathString(referencePathObj);
    let newCallFlowResponseMappings = JSON.parse(JSON.stringify(callFlowMappings));
    if(callFlowMappings[pathString]){
        let stepToReset =  callFlowMappings[pathString]["step"];
        let stepIndexToDelete =  callFlowMappings[pathString]["stepIndex"];

        let resetSteps = true;

        /** Call Flow version 1.1 and old support */
        let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;

        if (callFlowJsonStepsJson && callFlowJsonStepsJson[stepToReset] && callFlowJsonStepsJson[stepToReset]["list"] && callFlowJsonStepsJson[stepToReset]["list"].length > 1) {
            resetSteps = true;
        }

        callFlowJsonStepsJson[callFlowMappings[pathString]["step"]]["list"].splice(stepIndexToDelete, 1);
        delete newCallFlowResponseMappings[pathString];

        if (resetSteps) {
            /** Shifting next object by minus 1 */
            let sequenceCounter = stepIndexToDelete;
            Object.keys(callFlowMappings).forEach((newCallFlowMappingKey, mapIndex) => {
                if (
                    (newCallFlowResponseMappings[newCallFlowMappingKey]) &&
                    (newCallFlowResponseMappings[newCallFlowMappingKey]["step"]) &&
                    (newCallFlowResponseMappings[newCallFlowMappingKey]["step"] === stepToReset) &&
                    (newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"] > stepIndexToDelete)
                ) {
                    let newPathMappingString = newCallFlowMappingKey;
                    /** if deleted response of same node, sequence/key also changed */
                    if (
                        callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]] &&
                        callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"] &&
                        (
                            (
                                callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][sequenceCounter] &&
                                (callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][sequenceCounter]["node"] === referencePathObj.nodeKey)
                            ) ||
                            (
                                callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"]] &&
                                (callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"]]["node"] === referencePathObj.nodeKey)
                            )
                        )
                    ) {
                        let separator = params.callFlowSeparator
                        let newPathStringArr = newCallFlowMappingKey.split(separator);
                        if (newPathStringArr[(newPathStringArr.length)-1]) {
                            let oldIndex = parseInt(newPathStringArr[(newPathStringArr.length)-1]);
                            oldIndex--;
                            newPathStringArr.splice((newPathStringArr.length-1), 1);
                            newPathMappingString = newPathStringArr.join(separator);
                            newPathMappingString += (separator + oldIndex);
                            newCallFlowResponseMappings[newPathMappingString] = newCallFlowResponseMappings[newCallFlowMappingKey];
                            delete newCallFlowResponseMappings[newCallFlowMappingKey];
                        }
                        sequenceCounter++;
                    }

                    newCallFlowResponseMappings[newPathMappingString]["stepIndex"] = newCallFlowResponseMappings[newPathMappingString]["stepIndex"]-1;
                } 
                else if (
                    (newCallFlowResponseMappings[newCallFlowMappingKey]) &&
                    (newCallFlowResponseMappings[newCallFlowMappingKey]["step"])
                    //  &&
                    // (newCallFlowResponseMappings[newCallFlowMappingKey]["step"] === stepToReset) &&
                    // (newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"] > stepIndexToDelete)
                ) {
                    let newPathMappingString = newCallFlowMappingKey;
                    /** if deleted response of same node, sequence/key also changed */
                    if (
                        callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]] &&
                        callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"] &&
                        (
                            (
                                callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][sequenceCounter] &&
                                (callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][sequenceCounter]["node"] === referencePathObj.nodeKey)
                            ) ||
                            (
                                callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"]] &&
                                (callFlowJsonStepsJson[newCallFlowResponseMappings[newCallFlowMappingKey]["step"]]["list"][newCallFlowResponseMappings[newCallFlowMappingKey]["stepIndex"]]["node"] === referencePathObj.nodeKey)
                            )
                        )
                    ) {
                        let separator = params.callFlowSeparator
                        let newPathStringArr = newCallFlowMappingKey.split(separator);
                        if (newPathStringArr[(newPathStringArr.length)-1]) {
                            let oldIndex = parseInt(newPathStringArr[(newPathStringArr.length)-1]);
                            if (oldIndex > referencePathObj.index) {
                                oldIndex--;
                                newPathStringArr.splice((newPathStringArr.length-1), 1);
                                newPathMappingString = newPathStringArr.join(separator);
                                newPathMappingString += (separator + oldIndex);
                                newCallFlowResponseMappings[newPathMappingString] = newCallFlowResponseMappings[newCallFlowMappingKey];
                                delete newCallFlowResponseMappings[newCallFlowMappingKey];
                            }
                        }
                        sequenceCounter++;
                    }
                }
            });

        }
    }

    return {
        callFlowJson: callFlowJson,
        callFlowMappings: newCallFlowResponseMappings
    }
}

/**
 * Function to add the response in call flow when it is changed in rule json
 * @param {*} referencePathObj 
 * @param {*} newIntent 
 * @param {*} callFlowJson 
 * @param {*} callFlowMappings 
 * @returns 
 */
export const editNodeInCallFlow = (referencePathObj, newIntent, callFlowJson, callFlowMappings) => {
    var oldIntent = referencePathObj.nodeKey;
    let pathString = getPathString(referencePathObj);

    var newPathString = pathString.replace(oldIntent, newIntent);
    let newCallFlowResponseMappings = JSON.parse(JSON.stringify(callFlowMappings));

    /** Call Flow version 1.1 and old support */
    let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;

    if(newCallFlowResponseMappings[pathString]) {
        callFlowJsonStepsJson[newCallFlowResponseMappings[pathString]["step"]]["list"][newCallFlowResponseMappings[pathString]["stepIndex"]]["node"] = newIntent;
    }
    newCallFlowResponseMappings[newPathString] = { ...newCallFlowResponseMappings[pathString] };
    delete newCallFlowResponseMappings[pathString];

    return {
        callFlowJson: callFlowJson,
        callFlowMappings: newCallFlowResponseMappings
    }
}

/**
 * When you need to just edit the mapper
 * @param {*} referencePathObj 
 * @param {*} actionLevel 
 * @param {*} actionNewValue 
 * @param {*} callFlowMappings 
 * @returns 
 */
export const editMapperInCallFlow = (referencePathObj, actionLevel, actionNewValue, callFlowMappings, callFlowJson = null) => {
    let pathString = getPathString(referencePathObj);

    var oldActionValue = null;

    switch (actionLevel) {
        case global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE_TYPE:
            oldActionValue = referencePathObj.responseKey;
            break;
        case global.CUST_CONSTANTS.ACTION_LEVEL.ITERATION_TYPE:
            oldActionValue = referencePathObj.iterationKey;
            break;
    
        default:
            break;
    }

    var newPathString = pathString.replace(oldActionValue, actionNewValue);
    let newCallFlowResponseMappings = JSON.parse(JSON.stringify(callFlowMappings));

    newCallFlowResponseMappings[newPathString] = { ...newCallFlowResponseMappings[pathString] };
    delete newCallFlowResponseMappings[pathString];

    if (callFlowJson) {
        /** Call Flow version 1.1 and old support */
        let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;

        if (
            newCallFlowResponseMappings[newPathString]["step"] && 
            newCallFlowResponseMappings[newPathString]["stepIndex"] &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[newPathString]["step"]] &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[newPathString]["step"]].list &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[newPathString]["step"]].list[newCallFlowResponseMappings[newPathString]["stepIndex"]]
        ) {
            callFlowJsonStepsJson[newCallFlowResponseMappings[newPathString]["step"]].list[newCallFlowResponseMappings[newPathString]["stepIndex"]][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = newPathString;
        }
    }

    return {
        callFlowJson: callFlowJson,
        callFlowMappings: newCallFlowResponseMappings
    }
}

export const checkPathInCallflow = (path, callFlowMapping) => {
    var pathString = getPathString(path);
    if (callFlowMapping[pathString]) {
        return true;
    }
    return false;
}

/**
 * Any updations in rules responses would trigger this function and this will give the updated call flow json and mapper according to the action
 * @param {json} rulesJson Rules Json expected
 * @param {json} callFlowJSON existsing call flow json
 * @param {json} callFlowResponseMappings existing mapping of call flow json vs rules json
 * @param {json/null} responseAction if response action is performed then it is expected from state otherwise it is expected as null
 * @param {json} botResponses if response action is performed then it is expected from state otherwise it is expected as null
 */
 export const updateCallFlowJSONResponse = (rulesJson, callFlowJson = {}, callFlowResponseMappings = {}, responseAction = null, botResponses = {}, bulkEditObj = {}, lastKey=0) => {
     
    let returnObj = {
        callFlowJson: null,
        callFlowMappings: null
    }

    let actionMode = null;
    var selectedId = 0;
    let referencePathsOrKeys = [];
    let isBulkEdit = false;
    

    if (responseAction && responseAction.referencePaths) {
        if (responseAction.referencePaths && Array.isArray(responseAction.referencePaths) && (responseAction.referencePaths.length > 0)) {
            referencePathsOrKeys = responseAction.referencePaths;
            isBulkEdit = false;
            actionMode = responseAction.mode;
            if(typeof(responseAction.selectedId) === 'number'){
                selectedId = ((responseAction.selectedId != "") && (responseAction.selectedId > 0)) ? responseAction.selectedId : 0;
            }else if(typeof(responseAction.selectedId) === 'string'){
                selectedId = (responseAction.selectedId != "") ? responseAction.selectedId: 0
            }
        } else {
            isBulkEdit = true;
            if (bulkEditObj.selectedNodeBulk && Array.isArray(bulkEditObj.selectedNodeBulk)) {
                referencePathsOrKeys = [ ...bulkEditObj.selectedNodeBulk ];
                console.log("DEBUGGER:: updateCallFlowjr referencePathsOrKeys", referencePathsOrKeys);
            }
            actionMode = bulkEditObj.action;
            selectedId = bulkEditObj.to;
        }
    }

    if (referencePathsOrKeys.length > 0) {
        referencePathsOrKeys.forEach(function (pathOrKey) {

            let path = null;

            if (isBulkEdit) {
                let separator = params.callFlowSeparator
                let selectedNodeArray = pathOrKey.split(separator);
                if (selectedNodeArray[0] == "addon") {
                    /** case of addon responses */
                    let nodekey = selectedNodeArray[2];
                    let resp = bulkEditObj.responseJson.responseAddon[nodekey];
                    let pathsData = getPathsDataFromResponseNodeObj({node:nodekey, obj: resp});
                    path = pathsData[0];
                } else if (selectedNodeArray[0] == "global_addon") {
                    /** case of global addon responses
                     * no to do anything as these are not included in call flow for now
                     */
                } else {
                    let selectedNode = pathOrKey;
                    let selectval = bulkEditObj.keyMapper.responseNode[selectedNode];
                    let resp = bulkEditObj.responseJson.responseNode[selectval];
                    resp[global.CUST_CONSTANTS.PREDEFINED_KEYS.RULE_KEY.GENERIC] = resp["genericResponse"];
                    let pathsData = getPathsDataFromResponseNodeObj({node:selectedNode, obj: resp});
                    path = pathsData.find(item=>item.referenceId=== selectedId);
                }
            } else {
                path = pathOrKey;
            }
            console.log("DEBUGGER:: test pathOrKey path", path);
            if ( path && !path.isMetadata) {
                switch (actionMode) {
                    case global.CUST_CONSTANTS.CRUD_MODE.ADD:
                        /** Not changing the call flow if the added response is under evalFalse, globalRule, None, or attached with Interruptions tag  */
                        if(
                            (path.evalKey != "evalFalse") && 
                            (path.nodeKey != "globalRule") && 
                            (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                            (selectedId) &&
                            getResponsesFromPath(path, rulesJson)
                        ) {
                            var allowAction = true;
            
                            if(
                                (botResponses[selectedId]) &&
                                (botResponses[selectedId]["TagDetails"]) && 
                                (botResponses[selectedId]["TagDetails"]["Interruptions"])
                            ) {
                                allowAction = false;
                            }
                            
                            console.log('all bot responses--> ', botResponses, 'selectedId', selectedId)
                            if (allowAction) { 
                                returnObj = addResponseInCallFlow(path, botResponses[selectedId], callFlowResponseMappings, callFlowJson, rulesJson, null, responseAction.step,lastKey);
                            }
                        }
                        break;

                    case global.CUST_CONSTANTS.CRUD_MODE.EDIT : 
                        /** just deleting the response from call flow if the edited new response is under evalFalse, globalRule, None, or attached with Interruptions tag  otherwise changing the response*/
                        if(
                            (path.evalKey != "evalFalse") && 
                            (path.nodeKey != "globalRule") && 
                            (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE)
                        ) {
                            let acceptableForCallFlow = true;
                            if(
                                (selectedId) &&
                                (botResponses[selectedId]) &&
                                (botResponses[selectedId]["TagDetails"]) && 
                                (botResponses[selectedId]["TagDetails"]["Interruptions"])
                            ) {
                                acceptableForCallFlow = false;
                            }
                            // if(
                            //     (
                            //         (
                            //             (path.referenceId) &&
                            //             (botResponses[path.referenceId]) &&
                            //             (botResponses[path.referenceId]["TagDetails"]) && 
                            //             (botResponses[path.referenceId]["TagDetails"]["Interruptions"])
                            //         ) || 
                            //         (
                            //             !checkPathInCallflow(path, callFlowResponseMappings)
                            //         )
                            //     ) &&
                            //     acceptableForCallFlow
                            // ) {
                            //     returnObj = addResponseInCallFlow(path, botResponses[selectedId], callFlowResponseMappings, callFlowJson, rulesJson);
                            // } else 
                            if (acceptableForCallFlow) {
                                console.log('all bot responses--> ', botResponses, 'selectedId', selectedId)
                                returnObj = editResponseInCallFlow(path, botResponses[selectedId], callFlowResponseMappings, callFlowJson);
                            } else {
                                returnObj = deleteResponseInCallFlow(path, callFlowResponseMappings, callFlowJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
                            }
                        }
                        break;

                    case global.CUST_CONSTANTS.CRUD_MODE.DELETE : 
                        if (
                            /** Conditions for non added response in call flow */
                            (path.evalKey != "evalFalse") && 
                            (path.nodeKey != "globalRule") && 
                            (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                            (path.referenceId) &&
                            getResponsesFromPath(path, rulesJson)
                        ) {
                            /** just deleting the response box from call flow */
                            returnObj = deleteResponseInCallFlow(path, callFlowResponseMappings, callFlowJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
                        }
                        break;
                
                    default:
                        break;
                }
                if (returnObj.callFlowJson) {
                    callFlowJson = returnObj.callFlowJson;
                }
                if (returnObj.callFlowMappings) {
                    callFlowResponseMappings = returnObj.callFlowMappings;
                }
            }
        });
        
    } else {
        global.recordAudit("updateCallFlowJSON : invalid action attempted");
    }
    return returnObj;
}


/**
 * Based on conditions redirect to the correct function when updating the Node in rules json to update call flow
 * @param {*} rulesJson 
 * @param {*} callFlowJson 
 * @param {*} callFlowResponseMappings 
 * @param {*} selectedNode 
 * @param {*} botResponses 
 * @returns 
 */
export const updateCallFlowJSONNode = (rulesJson, callFlowJson = {}, callFlowResponseMappings = {}, selectedNode = null, botResponses=[], lastKey=0) => {
    let returnObj = {
        callFlowJson: null,
        callFlowMappings: null
    }

    if (selectedNode.action) {

        /** case if no rules have been made yet or call flow is empty */
        if (Object.keys(callFlowJson).length === 0) {
            callFlowJson = upgradeCallFlow(callFlowJson, callFlowResponseMappings);
        }

        selectedNode.pathsData.forEach(function (path) {
            switch (selectedNode.action) {
                case global.CUST_CONSTANTS.CRUD_MODE.ADD:
                    /** Not changing the call flow if the added response is under evalFalse, globalRule, None, or attached with Interruptions tag  */
                    if(
                        /** Conditions for non added response in call flow */
                        (path.evalKey != "evalFalse") && 
                        (path.nodeKey != "globalRule") && 
                        (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                        (path.referenceId) &&
                        getResponsesFromPath(path, rulesJson)
                    ) {
                        var allowAction = true;
        
                        if(
                            (botResponses[path.referenceId]) &&
                            (botResponses[path.referenceId]["TagDetails"]) && 
                            (botResponses[path.referenceId]["TagDetails"]["Interruptions"])
                        ) {
                            allowAction = false;
                        }
                        
                        if (allowAction) { 
                            returnObj = addResponseInCallFlow(path, botResponses[path.referenceId], callFlowResponseMappings, callFlowJson, rulesJson, selectedNode.node, null, lastKey);
                        }
                    }
                    break;
            
                case global.CUST_CONSTANTS.CRUD_MODE.EDIT : 
                    /** just deleting the response from call flow if the edited new response is under evalFalse, globalRule, None, or attached with Interruptions tag  otherwise changing the response*/
                    if(
                        (path.evalKey != "evalFalse") && 
                        (path.nodeKey != "globalRule") && 
                        (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE)
                    ) {
                        let addIntoCallFlow = true;
                        if(
                            (path.referenceId) &&
                            (botResponses[path.referenceId]) &&
                            (botResponses[path.referenceId]["TagDetails"]) && 
                            (botResponses[path.referenceId]["TagDetails"]["Interruptions"])
                        ) {
                            addIntoCallFlow = false;
                        }
                        if (addIntoCallFlow) {
                            returnObj = editNodeInCallFlow(path, selectedNode.actionNewValue, callFlowJson, callFlowResponseMappings);
                        } else {
                            global.recordAudit("Node not to be added in call flow as chosen response have interruption in it");
                        }
                    }
                    break;
                case global.CUST_CONSTANTS.CRUD_MODE.DELETE : 
                    if(
                        /** Conditions for non added response in call flow */
                        (path.evalKey != "evalFalse") && 
                        (path.nodeKey != "globalRule") && 
                        (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                        (path.referenceId) &&
                        rulesJson.ruleResponses
                    ) {
                        /** deleting the response boxes from call flow */
                        returnObj = deleteResponseInCallFlow(path, callFlowResponseMappings, callFlowJson, global.CUST_CONSTANTS.ACTION_LEVEL.NODE);
                    }
                    break;

                default:
                    global.recordAudit("Invalid action1 " + selectedNode.action);
                    break;
            }
            if (returnObj.callFlowJson) {
                callFlowJson = returnObj.callFlowJson;
            }
            if (returnObj.callFlowMappings) {
                callFlowResponseMappings = returnObj.callFlowMappings;
            }
        });
    }

    return returnObj;
}


/**
 * Function to be called and handle the config type changes like iteration type or response type in rules json
 * @param {*} callFlowResponseMappings 
 * @param {*} selectedNode 
 * @param {*} botResponses 
 * @returns 
 */
export const updateResponseConfigType = (callFlowResponseMappings = {}, selectedNode = null, botResponses = {}, callFlowJson={}) => {
    let returnObj = {
        callFlowJson: null,
        callFlowMappings: null
    }

    if (selectedNode) {
        var referencePaths = (selectedNode.pathsData) ? selectedNode.pathsData : [];

        referencePaths.forEach(function (path) {
            if(
                /** Conditions for non added response in call flow */
                (path.evalKey != "evalFalse") && 
                (path.nodeKey != "globalRule") && 
                (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                (path.referenceId)
            ) {
                var allowAction = true;

                if(
                    (botResponses[path.referenceId]) &&
                    (botResponses[path.referenceId]["TagDetails"]) && 
                    (botResponses[path.referenceId]["TagDetails"]["Interruptions"])
                ) {
                    allowAction = false;
                }
                
                if (allowAction) { 
                    returnObj = editMapperInCallFlow(path, selectedNode.action, selectedNode.actionNewValue, callFlowResponseMappings, callFlowJson);
                }
            }
            if (returnObj.callFlowMappings) {
                callFlowResponseMappings = returnObj.callFlowMappings;
            }
        });
    }

    return returnObj;
}


/**
 * Function to update the stattement type i.e. it's last statement or not
 * @param {*} callFlowResponseMappings 
 * @param {*} callFlowJson 
 * @param {*} selectedNode 
 * @returns 
 */
export const updateResponseStatementType = (callFlowResponseMappings = {}, callFlowJson = {}, selectedNode = null) => {
    let returnObj = {
        callFlowJson: null,
        callFlowMappings: null
    }

    if (selectedNode) {
        var referencePaths = (selectedNode.pathsData) ? selectedNode.pathsData : [];

        /** Call Flow version 1.1 and old support */
        let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;

        referencePaths.forEach(function (path) {
            if(
                /** Conditions for non added response in call flow */
                (path.evalKey != "evalFalse") && 
                (path.nodeKey != "globalRule") && 
                (path.nodeKey != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                (path.referenceId)
            ) {
                let pathString = getPathString(path);
                
                if (
                    callFlowResponseMappings[pathString]
                    && callFlowResponseMappings[pathString]["step"] 
                    && ("stepIndex" in callFlowResponseMappings[pathString])
                    && callFlowJsonStepsJson[callFlowResponseMappings[pathString]["step"]]
                    && callFlowJsonStepsJson[callFlowResponseMappings[pathString]["step"]]["list"]
                    && callFlowJsonStepsJson[callFlowResponseMappings[pathString]["step"]]["list"][callFlowResponseMappings[pathString]["stepIndex"]]
                ) { 
                    if (selectedNode.actionNewValue == 2) {
                        callFlowJsonStepsJson[callFlowResponseMappings[pathString]["step"]]["list"][callFlowResponseMappings[pathString]["stepIndex"]]["type"] = selectedNode.actionNewValue;
                    } else {
                        delete callFlowJsonStepsJson[callFlowResponseMappings[pathString]["step"]]["list"][callFlowResponseMappings[pathString]["stepIndex"]]["type"];
                    }
                }
            }
        });
    }

    returnObj.callFlowJson = callFlowJson;

    return returnObj;
}

/**
 * Previous function for resetting call flow mapping in case of drag and drop of blocks in call flow
 * @param {*} callFlowJson 
 * @param {*} callFlowResponseMappings 
 * @param {*} steps 
 * @param {*} indexes 
 * @returns 
 */
export const resetCallFlowMapping = (callFlowJson = {}, callFlowResponseMappings = {}, steps = [], indexes = []) =>{
    let newCallFlowResponseMappings = JSON.parse(JSON.stringify(callFlowResponseMappings));

    /** this is getting dragged */
    let sourceIndex = indexes[0]; 
    /** this is where source is getting dropped */
    let destinationIndex = indexes[1]; 
    // let indexFound = false;
    // let currentIndex = destinationIndex;
    let currentSourceIndexCounter = sourceIndex;
    let destinationIndexCounter = destinationIndex;
    var copyObject = {};
    let callFlowJsonStepsJson = null;

    if (callFlowJson) {
        /** Call Flow version 1.1 and old support */
        callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;
    }


    Object.keys(callFlowResponseMappings).forEach(function(callFlowMappingPathStr, index) {
        if(steps.length > 1) {
            // case of dragging across the steps
            //first step is to remove step from the source list
            if(callFlowResponseMappings[callFlowMappingPathStr]["step"] == steps[0]) {
                if(callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] == sourceIndex ) {
                    //Object to copy
                    copyObject = {"key":callFlowMappingPathStr, "value": newCallFlowResponseMappings[callFlowMappingPathStr]};
                    newCallFlowResponseMappings[copyObject["key"]]["step"] = steps[1];
                    newCallFlowResponseMappings[copyObject["key"]]["stepIndex"] = destinationIndex;
                }
                else if (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] > sourceIndex ) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]-1;
                    currentSourceIndexCounter++;
                }
            }
            //second step is to add the dragged item to destination list
            else if(callFlowResponseMappings[callFlowMappingPathStr]["step"] == steps[1]) {
                if(callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] == destinationIndex ) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = destinationIndex+1;

                    if(copyObject["value"]){
                        newCallFlowResponseMappings[copyObject["key"]] = {};
                        newCallFlowResponseMappings[copyObject["key"]]["step"] = steps[1];
                        newCallFlowResponseMappings[copyObject["key"]]["stepIndex"] = destinationIndex;
                        newCallFlowResponseMappings[copyObject["key"]]["referenceId"] = copyObject["value"]["referenceId"];
                    }
                    
                }
                else if (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] > destinationIndex ) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]+1;
                    destinationIndexCounter++;
                }
            }
        }
        else {
            /** case of dragging in the step */
            if(callFlowResponseMappings[callFlowMappingPathStr]["step"] == steps[0]) {
                /** here we need to update the mapping */
                
                if(callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] == (sourceIndex) ) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = destinationIndex;
                }
                else if(callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] == (destinationIndex) && (sourceIndex < destinationIndex)) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]-1;
                }
                else if(callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] == (destinationIndex) && (sourceIndex > destinationIndex)) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]+1;
                }
                else if( (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] > destinationIndex) && (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] < sourceIndex)) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] + 1;
                }
                else if( (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] < destinationIndex) && (callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] > sourceIndex)) {
                    newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] = callFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] - 1;
                }
            }
        }

        if (
            callFlowJsonStepsJson &&
            newCallFlowResponseMappings[callFlowMappingPathStr]["step"] && 
            newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"] &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[callFlowMappingPathStr]["step"]] &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[callFlowMappingPathStr]["step"]].list &&
            callFlowJsonStepsJson[newCallFlowResponseMappings[callFlowMappingPathStr]["step"]].list[newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]]
        ) {
            callFlowJsonStepsJson[newCallFlowResponseMappings[callFlowMappingPathStr]["step"]].list[newCallFlowResponseMappings[callFlowMappingPathStr]["stepIndex"]][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = callFlowMappingPathStr;
        }
    });

    return {
        callFlowJson: callFlowJson,
        newCallFlowResponseMappings: newCallFlowResponseMappings
    };
}


export const addStepCallFlowMapping = (callFlowJSON = {}, callFlowResponseMappings = {}, stepName = "") =>{
    /** version 1.1 handling not done as callFlowJSON variable is not being used in this function. Need to handle accordingly if used in future */
    let newCallFlowResponseMappings = JSON.parse(JSON.stringify(callFlowResponseMappings));
    if(stepName!= "" ) {
        let oldStepIndex = stepName.replace("step","");
        Object.keys(callFlowResponseMappings).forEach(function(callFlowMapping, index) {    
                // case of dragging in the step
                let currentStepIndex = callFlowResponseMappings[callFlowMapping]["step"].replace("step","");
                // if(currentStepIndex > oldStepIndex) {
                //     currentStepIndex++;
                //     newCallFlowResponseMappings[callFlowMapping]["step"] = "step"+currentStepIndex;
                // }
        });
    }
    
    return newCallFlowResponseMappings;
}

/**
 * Function to do the following  : 
 * convert the step to passable if all its block were passable acc to earlier version
 * add settings key {}
 * add key "path" Mapper inside list block for easy mapping and searching
 * changing direct step keys and put them in "steps"
 * add new key version as 1.1
 * @param {*} callFlowJson 
 * @param {*} callFlowResponseMappings 
 * @returns 
 */
export const upgradeCallFlow = (callFlowJson, callFlowResponseMappings) => {
    var newCallFlow = {};
    /** changed in version 1.1 */
    /** adding version key */
    newCallFlow["version"] = params.currentCallFlowJsonVersion;
    /** adding step keys to "steps" */
    /** just add on check for a case occuring unknowingly */
    if (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) {
        newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = JSON.parse(JSON.stringify(callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]));
    } else {
        newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = JSON.parse(JSON.stringify(callFlowJson));
    }
    var stepChecked = {};

    Object.keys(callFlowResponseMappings).forEach((mappingKey) => {
        let stepKey = callFlowResponseMappings[mappingKey].step;
        let stepIndex = callFlowResponseMappings[mappingKey].stepIndex;
        if (!stepChecked[stepKey] && newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey] && newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey]) {
            /** Adding settings blank json with key for consistency */
            if (!newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].settings) {
                newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].settings = {};
            }
            /** Adding mapping to block */
            if (newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list && newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list[stepIndex]) {
                newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list[stepIndex][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = mappingKey;
            }

            /** loop to check if all passable blocks then make it a passable step */
            let isStepPassable = true;
            for (let indexTemp = 0; indexTemp < newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list.length; indexTemp++) {
                const callFlowBlock = newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list[indexTemp];
                if (callFlowBlock[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY] && isStepPassable) {
                    isStepPassable = true;
                } else {
                    isStepPassable = false;
                    break;
                }
                
            }
            // newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].list.forEach((callFlowBlock) => {
            //     if (callFlowBlock[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY] && isStepPassable) {
            //         isStepPassable = true;
            //     } else {
            //         isStepPassable = false;
            //         break;
            //     }
            // });
            stepChecked[stepKey] = true;
            if (isStepPassable) {
                newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][stepKey].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY] = true;
            }
        }
    });
    
    return newCallFlow;
}



/**
 * Function to get paths data array from ruleResponse node object for making call flow
 * @param {json} obj ruleResponse Object of the node
 */
 export const getPathsDataFromResponseNodeObj = ({node, obj, isMetadata = false, responseTextArrOnly=false}) => {
    let ruleKeys = obj ? Object.keys(obj) : 0;
    let pathsData = [];
    if (ruleKeys.length > 0) {
        ruleKeys.forEach( ruleKey => {
            let jsonToPass = obj[ruleKey];
            let evalKey = null;
            if (ruleKey == global.CUST_CONSTANTS.PREDEFINED_KEYS.RULE_KEY.GENERIC) {
                evalKey = global.CUST_CONSTANTS.PREDEFINED_KEYS.EVAL_KEYS.EVAL_TRUE;
                jsonToPass = jsonToPass[evalKey];
            }
            let responseKey = getResponseKeyFromObj(jsonToPass);
            let iterationKey = null;
            if (jsonToPass && responseKey && jsonToPass[responseKey]) {
                jsonToPass = jsonToPass[responseKey];
                iterationKey = getIterationKeyFromObj(jsonToPass);
                if (jsonToPass && iterationKey && jsonToPass[iterationKey] && Array.isArray(jsonToPass[iterationKey])) {
                    jsonToPass[iterationKey].forEach( (responseObj, indexTemp) => {
                        if (responseObj.id) {
                            let pathDataObj = null;
                            if (responseTextArrOnly) {
                                pathDataObj = responseObj.BRText;
                            } else {
                                pathDataObj = {
                                    referenceId: responseObj.id,
                                    nodeKey: node,
                                    ruleKey: ruleKey,
                                    evalKey: evalKey,
                                    responseKey: responseKey,
                                    iterationKey: iterationKey,
                                    index: indexTemp,
                                    isMetadata: isMetadata,
                                    simpleViewRowIndex: false,
                                    /** BRText is added just for call flow mapping formation point of view. It will not be there in other pathsData objects. BRClip is added for sequencing rules needs */
                                    BRText: responseObj.BRText,
                                    BRClip: responseObj.BRClip,
                                };
                            }
                            pathsData.push(pathDataObj);
                        }
                    });
                }
            }
        });
    }

    return pathsData;
}

/** Function iterating rules call flow steps and recreating mapping and adding path key to call flow  */
export const recreateCallFlowMappingByRules = ({rulesJson = null, callFlowJson = null }) => {
    let newCallFlowMapping = {};
    let newCallFlowJson = callFlowJson;
    let ruleResponses = (rulesJson.ruleResponses) ? rulesJson.ruleResponses : null;
    let nodesIncluded = {};
    /** Call Flow version 1.1 and old support */
    let newcallFlowJsonStepsJson = (newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : newCallFlowJson;
    if (ruleResponses && newcallFlowJsonStepsJson) {
        Object.keys(newcallFlowJsonStepsJson).forEach( stepKey => {
            if (newcallFlowJsonStepsJson[stepKey].list && Array.isArray(newcallFlowJsonStepsJson[stepKey].list)) {
                newcallFlowJsonStepsJson[stepKey].list.forEach( (blockObj, blockIndex) => {
                    if (blockObj.node && ruleResponses[blockObj.node]) {
                        if (!nodesIncluded[blockObj.node]) {
                            nodesIncluded[blockObj.node] = {};
                            let pathsData = getPathsDataFromResponseNodeObj({node: blockObj.node, obj: ruleResponses[blockObj.node]});
                            pathsData.forEach(pathObj => {
                                let pathString = getPathString(pathObj, true);
                                if (blockObj.id === pathObj.referenceId) {
                                    newCallFlowMapping[pathString] = {
                                        referenceId: pathObj.referenceId,
                                        step: stepKey,
                                        stepIndex: blockIndex
                                    };
                                    newcallFlowJsonStepsJson[stepKey].list[blockIndex][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = pathString;
                                }
                                nodesIncluded[blockObj.node][pathObj.referenceId] = pathString;
                            })
                        } else {
                            if (nodesIncluded[blockObj.node][blockObj.id]) {
                                let pathString = nodesIncluded[blockObj.node][blockObj.id];
                                newCallFlowMapping[pathString] = {
                                    referenceId: blockObj.id,
                                    step: stepKey,
                                    stepIndex: blockIndex
                                };
                                newcallFlowJsonStepsJson[stepKey].list[blockIndex][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = pathString;
                            }
                        }
                    }
                });
            }
        });
    }
    console.log("DEBUGGER:: recreateCallFlowMappingByRules nodesIncluded", nodesIncluded);
    return {
        callFlowJSON:  newCallFlowJson,
        callFlowResponseMappings: newCallFlowMapping
    };
}


/**
 * 
 * @param {json} botResponseObj favgv def
 * @param {hb} newStepKey jbhb
 * @param {*} newStepIndex 
 * @param {*} pathObj 
 * @returns {
        newBlock: newBlock,
        pathString: pathString,
        mapperBlock: mapperBlock
    }
 */
export const getSyncedBlock = ({botResponseObj, newStepKey, newStepIndex, pathObj, nodeKey, rulesJson, totalResponses = 0, sampleUtterances=[{blockId: null,utteranceId:"",relatedIntent:"",utteranceText:""}], entities}) => {
    let newBlock = null;
    let pathString = null;
    let mapperBlock = null;
    console.log('botResponseObj', botResponseObj)
    if (
        (pathObj.nodeKey !== global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE) &&
        (pathObj.nodeKey !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
        (!pathObj.isMetadata) &&
        !(
            (botResponseObj["TagDetails"]) && 
            (botResponseObj["TagDetails"]["Interruptions"])
        )
    ) {
            
        newBlock = {};
        newBlock["node"] = nodeKey;
        newBlock["id"] = botResponseObj["id"];
        newBlock["BRText"] = botResponseObj["utterance_text"];
        newBlock["BRClip"] = botResponseObj["filepath"];
        /** setting type as it is if response have it's own type */
        if (botResponseObj["type"]) {
            newBlock["type"] = botResponseObj["type"];
        } else if (rulesJson.rule && rulesJson.rule[nodeKey] && rulesJson.rule[nodeKey].type) {
            /** setting type if node has it's type */
            newBlock["type"] = rulesJson.rule[nodeKey].type;
        }
        newBlock["metadata"] = {};
        if(botResponseObj["TagDetails"]){ 
            newBlock["metadata"]["tags"] = botResponseObj["TagDetails"]
        }
        if(entities){
            newBlock["entities"] = entities
        }
        
        pathString = "";
        if (pathObj.index >= 0) {
            pathString = getPathString(pathObj, true);
        } else {
            pathString = getPathString(pathObj, false);
            let pathIndex = totalResponses.length-1;
            let separator = params.callFlowSeparator
            pathString += separator + pathIndex;
        }
        
        newBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP] = pathString;
        mapperBlock = {
            referenceId: botResponseObj["id"],
            step: newStepKey,
            stepIndex: newStepIndex
        }
        newBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES] = sampleUtterances
    }

    return {
        newBlock: newBlock,
        pathString: pathString,
        mapperBlock: mapperBlock
    };
}


/**
 * Functions does the following while Creating new call flow json according to 
 *  1. rules responses
 *  2. deleted steps
 * 
 * @param {json} param0 having the following : 
 * 
 */
export const syncCallFlowWithRuleResponses = ({rulesJson = null, callFlowJson = null, botResponses = null, stepSequence}) => {
    console.log('callFlowJson', callFlowJson)
    let ruleResponses = (rulesJson.ruleResponses) ? rulesJson.ruleResponses : null;
    let nodeResPathsUnused = {};
    let unfoundBlockDetails = {};
    let newCallFlowMapping = {};
    let stringifiedCF = JSON.stringify(callFlowJson)
    let newCallFlowJson = JSON.parse(stringifiedCF);
    // let isChanged = false;
    
    /** Call Flow version 1.1 and old support */
    let callFlowJsonStepsJson = (callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJson;
    let newMainSequence = [...callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']];
    const relations = {...callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations']};
    let newStepSequence = [...stepSequence];
    let newCallFlowJsonStepsJson = {};
    const stepsToBeDeleted = []
    console.log(' all-botResponses-->', botResponses);
    if (ruleResponses && callFlowJsonStepsJson) {
        let stepCounter = -1;
        /** to maintain step count after deletion too */
        let newStepKey = "";
        Object.keys(callFlowJsonStepsJson).forEach( stepKey => {
            // newStepKey = "step" + (stepCounter + 1);
            newStepKey = stepKey
            
            if (callFlowJsonStepsJson[stepKey].list && Array.isArray(callFlowJsonStepsJson[stepKey].list)) {
                if (Array.isArray(callFlowJsonStepsJson[stepKey].list) && (callFlowJsonStepsJson[stepKey].list.length > 0)) {
                    let hasUtteranceFlag = false;
                    let utteranceBlockIds = {};
                    let utteranceBlock = null;
                    callFlowJsonStepsJson[stepKey].list.forEach( (blockObj, blockIndex) => {
                        let addBlockId = 0;
                        let pathDataObj = {};
                        if (blockObj.node && blockObj.id) {
                            /** if sample utterances are not present in any of the block **/

                            if((blockObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]?blockObj.sampleUtterances[0].utteranceText:"")!=""){
                                hasUtteranceFlag = true;
                                
                                utteranceBlock=blockObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0];
                                utteranceBlockIds['oldId'] = utteranceBlock.blockId
                            }

                            /** add all the existing paths in nodeResPathsUnused */
                            if (!nodeResPathsUnused[blockObj.node]) {
                                nodeResPathsUnused[blockObj.node] = {};
                                let pathsData = getPathsDataFromResponseNodeObj({node: blockObj.node, obj: ruleResponses[blockObj.node]});
                                pathsData.forEach(pathObj => {
                                    if (!nodeResPathsUnused[blockObj.node]) {
                                        nodeResPathsUnused[blockObj.node] = {};
                                    }
                                    nodeResPathsUnused[blockObj.node][pathObj.referenceId] = {...pathObj};
                                });
                            }

                            /** if the response present in call flow is present in rule responses among unused, thus path will be corrected even if wrong by this block */
                            if (nodeResPathsUnused[blockObj.node][blockObj.id]) {
                                pathDataObj = nodeResPathsUnused[blockObj.node][blockObj.id];
                                /** if the response matches correctly i.e. call flow response is there under node */
                                /** this means that right response is mapped */
                                /** putting the same block in new call flow as it is */
                                addBlockId = blockObj.id;
                            } else {
                                if (nodeResPathsUnused[blockObj.node]) {
                                    Object.keys(nodeResPathsUnused[blockObj.node]).some( responseId => {
                                        let responseObjTemp = nodeResPathsUnused[blockObj.node][responseId];
                                        /** if call response is allowed in call flow (basis None, globalRule, Interruptions, isMetadata etc.) */
                                        if (!responseObjTemp["declined"]) {
                                            /** Do text matching i.e. clip changed */
                                            if (responseObjTemp[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT] === blockObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT]) {
                                                // add block
                                                addBlockId = blockObj.id;
                                                pathDataObj = responseObjTemp;
                                            } else {
                                                /** check if path matches i.e. the response is updated in rules and not in call flow */
                                                let pathStrTemp = getPathString(responseObjTemp);
                                                if (pathStrTemp === blockObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP]) {
                                                    // add block
                                                    addBlockId = responseObjTemp.referenceId;
                                                    pathDataObj = responseObjTemp;
                                                    return true;
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                            if (addBlockId) {
                                if (!newCallFlowJsonStepsJson[newStepKey]) {
                                    /** incrementing counter if new steps is being created */
                                    stepCounter++;
                                    newCallFlowJsonStepsJson[newStepKey] = {}
                                    newCallFlowJsonStepsJson[newStepKey].list = [];
                                    newCallFlowJsonStepsJson[newStepKey].settings = {...callFlowJsonStepsJson[stepKey].settings};
                                }
                                console.log('addBlockId', addBlockId);
                                if(typeof(addBlockId) === 'number'){
                                    addBlockId = String(addBlockId);
                                    let splittedBlockId = addBlockId.split('_');
                                    if(splittedBlockId.length <=1){
                                        addBlockId = findBlockIdFromBotResponses({botResponses:botResponses, oldBlockId: addBlockId, blockObj: blockObj});
                                        console.log('newBlockId', addBlockId);
                                        
                                    }
                                }
                                console.log('newStepKey', newStepKey)
                                /** getting new block according to bot response and rules */
                                let { newBlock, pathString, mapperBlock } = getSyncedBlock({
                                    botResponseObj: botResponses[addBlockId], 
                                    newStepKey: newStepKey, 
                                    newStepIndex: newCallFlowJsonStepsJson[newStepKey].list.length, 
                                    pathObj: pathDataObj, 
                                    nodeKey: blockObj.node, 
                                    rulesJson: rulesJson, 
                                    sampleUtterances: blockObj.sampleUtterances?blockObj.sampleUtterances:[{blockId:null,utteranceId:"",relatedIntent:"",utteranceText:""}],
                                    entities: blockObj.entities
                                });
                                console.log('newBlock', newBlock, 'newStepKey', newStepKey, 'newCallFlowJsonStepsJson', newCallFlowJsonStepsJson)
                                if(utteranceBlockIds.oldId === blockObj.id){
                                    utteranceBlockIds['newId'] = newBlock.id
                                }
                                
                                if (newBlock) {
                                    newCallFlowJsonStepsJson[newStepKey].list.push(newBlock);
    
                                    /** similarly putting mapping with new created path */
                                    newCallFlowMapping[pathString] = { ...mapperBlock };
    
                                    /** delete response from nrpu */
                                    delete nodeResPathsUnused[blockObj.node][blockObj.id];
                                } else {
                                    nodeResPathsUnused[blockObj.node][blockObj.id]["declined"] = true;
                                }
                            } else {
                                /** IF call flow's exact response not found under existing node, put it in unused */
                                if (!unfoundBlockDetails[blockObj.node]) {
                                    unfoundBlockDetails[blockObj.node] = {};
                                }
                                unfoundBlockDetails[blockObj.node][blockObj.id] ={
                                    prevStepKey: stepKey,
                                    stepCounter: stepCounter,
                                    prevStepIndex: blockIndex,
                                    blockObj: {...blockObj}
                                };
                                stepsToBeDeleted.push(stepKey)
                            }
                        }
                    });
                    if(!hasUtteranceFlag && (callFlowJsonStepsJson[stepKey].list.length!=0) && (newCallFlowJsonStepsJson[stepKey])){    
                        delete newCallFlowJsonStepsJson[stepKey][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SETTINGS][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE];
                        hasUtteranceFlag = false;
                    }else{
                        if(hasUtteranceFlag && (callFlowJsonStepsJson[stepKey].list.length!=0) && (newCallFlowJsonStepsJson[stepKey]) && !(newCallFlowJsonStepsJson[stepKey][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SETTINGS][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE])){
                            utteranceBlock.blockId = utteranceBlockIds['newId']
                            newCallFlowJsonStepsJson[stepKey][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SETTINGS][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]={...utteranceBlock};
                            
                        }
                    }
                    if(newCallFlowJsonStepsJson[stepKey] && newCallFlowJsonStepsJson[stepKey][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SETTINGS]){
                        let interruptionBlock = newCallFlowJsonStepsJson[stepKey][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SETTINGS]["interruption"];
                        console.log('interruptionBlock ', interruptionBlock)
                        if(interruptionBlock && interruptionBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE] && interruptionBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]
                            && interruptionBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0] && interruptionBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0].id
                        ){
                            let interruptionResponse = interruptionBlock[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE];
                            console.log('interruptionResponse.id ', interruptionResponse[0].id);
                            let newId = findBlockIdFromBotResponses({botResponses:botResponses, oldBlockId: interruptionResponse[0].id, blockObj: interruptionResponse[0]});
                            console.log('newId', newId)
                            if(newId){
                                interruptionResponse[0].id = newId
                            }
                        }
                    }
                }else{
                    console.log('stepKey in syncCallFlowWithRuleResponses', stepKey)
                    stepsToBeDeleted.push(stepKey)
                    stepCounter++
                }
                
            }
        })
    }

    console.log('stepsToBeDeleted in syncCallFlowWithRuleResponses', stepsToBeDeleted)

    let newRelations = {...relations};

    const replaceKeys = []; // If any relation key needs to be change

    stepsToBeDeleted.forEach(step=>{
        let mainSeqIdx = newMainSequence.indexOf(step);
        // Delete step in main-sequence
        if(mainSeqIdx > -1){
            newMainSequence.splice(mainSeqIdx,1)
        }

        let stepSeqIdx = newStepSequence.indexOf(step);
        // Delete step in stepSequence
        if(stepSeqIdx > -1){
            newStepSequence.splice(stepSeqIdx,1)
        }

        // Delete step in relations
       
        for(let key in relations){
            if(newRelations[key] && newRelations[key].length > 0){
                let relArray = [];
                let newKey = null;
                newRelations[key] && newRelations[key].length > 0 && newRelations[key].forEach(rel=>{
                    console.log( 'rel',rel)
                    let path1Idx = rel.path1.indexOf(step);
                    if(path1Idx > -1){
                        rel.path1.splice(path1Idx,1);
                        if(path1Idx ===0 && rel.path1.length > 0){
                            newKey = rel.path1[0]
                        }                
                    }
                    let path2Idx = rel.path2.indexOf(step);
                    if(path2Idx > -1){
                        rel.path2.splice(path2Idx,1)
                    }
                    if(rel.path1.length && rel.path2.length){
                        relArray.push({...rel})
                    }
                });
                if(relArray.length > 0){
                    if(newKey){
                        replaceKeys.push({newKey:newKey, oldKey:key})
                    }
                    newRelations[key] = relArray
                    
                } else {
                    delete newRelations[key];
                }
            }
        }        
    });

    replaceKeys.forEach(obj=>{
        if(newRelations[obj.oldKey]){
            newRelations[obj.newKey] = newRelations[obj.oldKey];
            delete newRelations[obj.oldKey];
        }
    })

    if (newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) {
        newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = { ...newCallFlowJsonStepsJson };
        newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence'] = [...new Set(newMainSequence)];
        newCallFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'] = {...newRelations};
    }

    const {accordionStates} =  getLastStepAndAccordionStates(newCallFlowJson)

    // let newStringifiedCF = JSON.stringify(newCallFlowJson)
    console.log("DEBUGGER:: syncCallFlowWithRuleResponses newCallFlowJson, newCallFlowMapping", newCallFlowJson, newCallFlowMapping);
    return {
        newCallFlowJson: newCallFlowJson,
        newCallFlowMapping: newCallFlowMapping,
        stepSequence: newStepSequence,
        accordionStates: accordionStates,
        // For phase1, give is changed as true always
        isChanged: true
    }
}

export const findBlockIdFromBotResponses = ({botResponses, oldBlockId, blockObj}) => {
    let botResponseArr = Object.keys(botResponses);
    if(blockObj){
        for(let i=0; i<botResponseArr.length; i++){
            let splittedId =  botResponseArr[i].split('_');
            if(oldBlockId == splittedId[0]){
                
                let responseobj = {
                    text: blockObj.BRText || blockObj.defaultText || '',
                    clip: blockObj.BRClip || blockObj.defaultClip || ''
                }
                let clipName = responseobj.clip
                if(clipName){
                    clipName = responseobj.clip.split('.')[0]
                }
                let botAudioClip = botResponses[botResponseArr[i]].audio_clip
                if(botAudioClip){
                    botAudioClip = botAudioClip.split('.')[0]
                }
                let filepath = botResponses[botResponseArr[i]].filepath
                if(filepath){
                    filepath = filepath.split('.')[0]
                }
                if((botResponses[botResponseArr[i]].utterance_text === responseobj.text && (botAudioClip === clipName || filepath === clipName))){
                    return botResponseArr[i]
                }
            }
        }
    }
}

export const callFlowDownload = (tableJSON,storyName) =>{
    /**Table Creation */
    let table = document.createElement("table");
    tableJSON.attributes && attributesHelperFn(table, tableJSON.attributes);

    tableJSON &&
    tableJSON.rows &&
    tableJSON.rows.forEach((item) => {
    let row = document.createElement("tr");
    
    // Header cells creation
    item.attributes.isHeader &&
      item.cells.forEach((header) => {
        let headerCell = document.createElement("th");
        headerCell.innerHTML = header.text;
        header.attributes && header.attributes['rowspan'] && header.attributes.key !=="group" && delete header.attributes.rowspan
        header.attributes && attributesHelperFn(headerCell, header.attributes);
        headerCell.style.width = "250px"
        row.appendChild(headerCell);
      });

    // Non Header cell creation
    !item.attributes.isHeader &&
      item.cells.forEach((cell) => {
        let tableCell = document.createElement("td");
        tableCell.innerHTML = cell.text;
        cell.attributes && attributesHelperFn(tableCell, cell.attributes);
        row.appendChild(tableCell);
      });
    table.appendChild(row);
  });

    /** Element attributes addition helper function */
    function attributesHelperFn(el, attributesObj) {
        Object.keys(attributesObj).length && Object.keys(attributesObj).forEach((item) => {
            if (["color"].indexOf(item) === -1) {
                el.setAttribute(item, attributesObj[item]);
            }
            if (item === "color") {
                el.style.color = attributesObj[item];
            }
            el.style.wordWrap = "break-word";
        });
    }

    var tableToExcel = (function() {
        var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
            , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
        return function(name) {
            // if (!table.nodeType) table = document.getElementById(table);
            const fileName = storyName.replace(/[^A-Z0-9\s]+/ig,"").split(" ").join("-")+'-callflow';
            var ctx = {worksheet: fileName || 'Call Flow', table: table.innerHTML}
            var link = document.createElement("a");
            link.download = `${fileName}.xls`;
            link.href = uri + base64(format(template, ctx));
            link.click();
        }
    })()

    tableToExcel()

}

/**
 * Functions does the following from the call flow 
 *  1. find last index from the metadata and relations
 *  2. make accordion states
 * 
 * @param {json} callflowJSON  
 * 
 */
export const getLastStepAndAccordionStates = (callFlowJSON) =>{
    const callFlowMetaData = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA;
    let stepsArr = callFlowJSON[callFlowMetaData]['main-sequence'];
    let relationsStepArr = [];
    const accordionStates = {}
    if(callFlowJSON[callFlowMetaData].relations && Object.keys(callFlowJSON[callFlowMetaData].relations).length){
        Object.keys(callFlowJSON[callFlowMetaData].relations).forEach(stepKey=>{
            if(callFlowJSON[callFlowMetaData].relations[stepKey].length){
                callFlowJSON[callFlowMetaData].relations[stepKey].forEach((relation,relationIndex)=>{
                    relationsStepArr.push(...relation.path1,...relation.path2);
                    accordionStates[stepKey+ '-' +relationIndex] = false
                })
            }
        })
    }
    console.log('stepsArr before', stepsArr);
    stepsArr = [...new Set([...stepsArr,...relationsStepArr])];
    console.log('stepsArr after', stepsArr);
    const sortedSteps = stepsArr.length ? stepsArr.sort((a,b)=> a.replace('step',"")-b.replace('step',"")) : [];
    const latestStepStr = sortedSteps.length ? sortedSteps[sortedSteps.length-1].replace("step", "") : '';
    const latestStep = latestStepStr ?  parseInt(latestStepStr) :0;

    return {
        latestStep: latestStep,
        accordionStates: accordionStates
    }
}

export const accordionStepDeletionAllowed = (stepToFind, callFlowJSON, accordionState) =>{
    console.log('stepToFind in accordionStepDeletionAllowed', stepToFind)
    let callFlowJSONPicked = callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW];
    let relations = callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'];
    let mainSequence = callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence'];
    let accordionStates = Object.keys(accordionState);


    
    const path1LastArr = [];
    if(relations){
        for(let accordionId of accordionStates){
            let [step,relationIdx] = accordionId.split('-');
            relationIdx = parseInt(relationIdx);
            
            const relation = relations[step] && relations[step][relationIdx];
            if(relation){
                const mainAccordionFirst = relation.path1[0];
                
                // If its accordion firsrt step and below step is passable for main-sequence or below step is empty
                if(stepToFind === mainAccordionFirst && callFlowJSONPicked[mainAccordionFirst].list.length < 2){
                    const mainAccordionSecond = relation.path1[1];
                    console.log('inside main sequence check mainAccordionSecond',mainAccordionSecond)
                    if((mainAccordionSecond && callFlowJSONPicked[mainAccordionSecond] && callFlowJSONPicked[mainAccordionSecond].settings['passable']) || (mainAccordionSecond && !callFlowJSONPicked[mainAccordionSecond]) || (mainAccordionSecond && callFlowJSONPicked[mainAccordionSecond].list && callFlowJSONPicked[mainAccordionSecond].list.length <= 0)){
                        return false
                    }
                }
                const mainAccordionLast = relation.path1[relation.path1.length-1];
                path1LastArr.push(mainAccordionLast); 
                // If its accordion last step and above step is passable for main-sequence
                if(stepToFind === mainAccordionLast && callFlowJSONPicked[mainAccordionLast].list.length < 2){
                    const mainAccordionSecondLast = relation.path1[relation.path1.length-2];
                    console.log('inside main sequence check mainAccordionSecondLast',mainAccordionSecondLast)
                    if((mainAccordionSecondLast && callFlowJSONPicked[mainAccordionSecondLast] && callFlowJSONPicked[mainAccordionSecondLast].settings['passable']) || (mainAccordionSecondLast && !callFlowJSONPicked[mainAccordionSecondLast])){
                        return false
                    }
                }
                const relationFirstStep = relation.path2[0];
                // If its accordion first step and below step is passable for relations
                if(stepToFind === relationFirstStep && callFlowJSONPicked[relationFirstStep].list.length < 2){
                    const relationAccordionSecond = relation.path2[1];
                    console.log('inside relation check relationAccordionSecond',relationAccordionSecond)
                    if((relationAccordionSecond && callFlowJSONPicked[relationAccordionSecond] && callFlowJSONPicked[relationAccordionSecond].settings && callFlowJSONPicked[relationAccordionSecond].settings['passable']) || (relationAccordionSecond && !callFlowJSONPicked[relationAccordionSecond])){
                        return false
                    }
                }
                const relationLastStep = relation.path2[relation.path2.length-1];
                // If its accordion last step and above step is passable for relations
                if(stepToFind === relationLastStep && callFlowJSONPicked[relationLastStep].list.length < 2){
                    const relationAccordionSecondLast = relation.path2[relation.path2.length-2];
                    console.log('inside relation check relationAccordionSecondLast',relationAccordionSecondLast)
                    if((relationAccordionSecondLast && callFlowJSONPicked[relationAccordionSecondLast] && callFlowJSONPicked[relationAccordionSecondLast].settings && callFlowJSONPicked[relationAccordionSecondLast].settings['passable']) || (relationAccordionSecondLast && !callFlowJSONPicked[relationAccordionSecondLast])){
                        return false
                    }
                }
            }
        }
        if(mainSequence.length && path1LastArr.length){
            const mainSequenceLast = mainSequence[mainSequence.length-1];
            if(stepToFind === mainSequenceLast && callFlowJSONPicked[mainSequenceLast].list.length < 2){
                const mainSequenceSecondLast = mainSequence[mainSequence.length-2];
                console.log('inside main sequence check ',mainSequenceSecondLast)
                if(mainSequenceSecondLast && path1LastArr.includes(mainSequenceSecondLast)){
                    return false
                }
            }
        }
    }

    return true

}

export const deletionRestricted = ({selectedNode, callFlowJSON, accordionState, callFlowMappings  }) =>{
    console.log('selectedNod in deletionRestricted', selectedNode, 'callFlowMappings in deletionRestricted', callFlowMappings);
    if(selectedNode.pathsData && Array.isArray(selectedNode.pathsData)){
        for(let referencePathObj of  selectedNode.pathsData){
            
            let pathString = getPathString(referencePathObj);
            if(callFlowMappings[pathString]){
                const step = callFlowMappings[pathString].step;
                console.log('step in callFlowMappings[pathString]', step)
                if(!accordionStepDeletionAllowed(step, callFlowJSON, accordionState )){
                    console.log('deletion not allowed for this step')
                    return true;
                }
            }
        }
        
    }
    return false
}

export const callFlowIterator = ({callFlow, cb}) => {
    if(callFlow && callFlow.callPlan){
        let callFlowSteps = Object.keys(callFlow.callPlan)
        for(let i=0; i < callFlowSteps.length; i++){
            if(cb(callFlowSteps[i], callFlow.callPlan)){
                return true
            }
        }
    }
    return false
}

export const audioClipNotPresentInStep = (step, callPlan) => {
    const  failureResponse = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE;
    const  iterationType = global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE;
    const responseClip = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP;
    const responseText = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT
    if(callPlan[step]){
        if((callPlan[step].settings && callPlan[step].settings.interruption && callPlan[step].settings.interruption[failureResponse] && 
            callPlan[step].settings.interruption[failureResponse][iterationType] && callPlan[step].settings.interruption[failureResponse][iterationType] &&  
            callPlan[step].settings.interruption[failureResponse][iterationType][0] && callPlan[step].settings.interruption[failureResponse][iterationType][0].BRText &&  
            !callPlan[step].settings.interruption[failureResponse][iterationType][0][responseClip]) || (callPlan[step].settings && audioClipNotPresentInEntity(callPlan[step].settings.entities))
        ){
            return true
        }
        if(callPlan[step].list && Array.isArray(callPlan[step].list) &&
         callPlan[step].list.length > 0){
            for(let j=0; j < callPlan[step].list.length; j++){
                if((callPlan[step].list[j][responseText] && !callPlan[step].list[j][responseClip])
                    || audioClipNotPresentInEntity(callPlan[step].list[j].entities)
                ){
                    return true
                }
            }
        }
    }
}

const audioClipNotPresentInEntity = (entities) => {
    const responseClip = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP;
    const responseText = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT
    if(entities && Array.isArray(entities)){
        for(let i=0; i< entities.length; i++){
            if(entities[i].interruption && entities[i].interruption[responseText] && !entities[i].interruption[responseClip]){
                return true;
            }
        }
    }
}

export const rulesJsonChecker = ({rulesJson}) => {
    if(rulesJson){
        if(rulesJson.ruleResponses && rulesJson.ruleResponses.None && rulesJson.ruleResponses.None.generic && rulesJson.ruleResponses.None.generic.evalTrue){
            if(checkAudioClipNotPresentInRule(rulesJson.ruleResponses.None.generic.evalTrue)){
                return true
            }
        }
    
        if(rulesJson.metadata && rulesJson.metadata.opening_statement){
            if(checkAudioClipNotPresentInRule(rulesJson.metadata.opening_statement)){
                return true
            }
        }
    
        if(rulesJson.metadata && rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE]){
            if(checkAudioClipNotPresentInRule(rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE])){
                return true
            }
        }
        return false
    }
}

const checkAudioClipNotPresentInRule = (rule) => {
    const responseClip = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP;
    const responseText = global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT;
    if(rule && rule[global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE] && Array.isArray(rule[global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE])){
        let ruleArr = rule[global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE];
        for(let i=0; i< ruleArr.length; i++){
            if(ruleArr[i] && ruleArr[i][responseText] && !ruleArr[i][responseClip]){
                return true
            }
        }
    }
    
}

export const encodeJson = (json) => {
    const encoded =  window.btoa(ToBinary(JSON.stringify(json)))
    return encoded
} 

function ToBinary(str) {
    let result="";

    str=encodeURIComponent(str);

    for(let i=0;i<str.length;i++)
        if(str[i]=="%")
        {
            result+=String.fromCharCode(parseInt(str.substring(i+1,i+3),16));
            i+=2;
        }
        else
            result+=str[i];

    return result;
}
 