import axiosCall from "../../../utils/axiosCall";
import { axiosErrorFunction, setAlert } from "../common/commonActions";
import getApiUrl  from "../../../api-urls/apiUrlsUtils";
import { aiBase, aiBaseNew, aiUrls } from "../../../api-urls/luis-app-apis.js";
import { params } from "../../../data/params";
import { INCREMENT_TRAINING_STEP, SET_END_SESSION, SET_TEMP_VERSION_NEW, SET_TEMP_VERSION_OLD, SET_VERSION_LIST } from "../types";
import { checkIfValidTempVersion } from "../../../utils/rulesUtils";
import { getEntites, getIntents, getSampleUtterances, resetUntrainedLuisObjState , saveUntrainedLuisObjToDb, setAiVersion, changeActionsForUntrainedLuisState, changeHideStartTrainingBtn } from "../rules/rulesActions";
import { messages } from "../../../data/strings";
import keepalive from "../../../assets/global-scripts/keep-alive";
import { setIsAppInSync } from "../luis-app/luisAppActions";
import store from "../../store";

/**
 * To Increment step
 */
export const incrementStep = () => dispatch => {

    const auditData = "incrementStep called "  ;  
        global.recordAudit(auditData);

    dispatch({
        type: INCREMENT_TRAINING_STEP,
        payload: null
    });
}

/**
 * To Increment step
 */
const setAppVersionListInRed = (list) => dispatch => {

    const auditData = "setAppVersionListInRed called with list: " + JSON.stringify(list) ;  
    global.recordAudit(auditData);

    dispatch({
        type: SET_VERSION_LIST,
        payload: list
    });
    dispatch(incrementStep());
}

/**
 * To Increment step
 */

const resetStep = (isSuccess=false, doEndSession=false) => dispatch => {

    const auditData = "resetStep called : isSuccess, doEndSession :" + isSuccess + "," + doEndSession ;  
    global.recordAudit(auditData);

    dispatch({
        type: INCREMENT_TRAINING_STEP,
        payload: {reset:true}
    });
    if (isSuccess) {
        dispatch(setAlert("AI APP Trained & published successfully", 1));
        // dispatch(resetUtteranceScores());
    }
    window.stopFromUnload = false;
}



const trainnPublishErrorFunction = ({error=null, message="", stepNo=0}) => dispatch => {
    const auditData = "trainnPublishErrorFunction called : error, message, stepNo  :" + error + "," + message + "," + stepNo;  
    global.recordAudit(auditData);
    
    console.error("Error occured while training & Publishing  : stepNo, message, error" , stepNo, message, error);
    dispatch(setAlert("Some Issue Occured while training the app."));
    dispatch(resetStep());
    return false;
};

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const getAppVersionsList = ({stepNo, luisAppDbId}) => dispatch => {
    const auditData = "getAppVersionsList called : stepNo, luisAppDbId :" + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);

    let url = getApiUrl(aiBase, aiUrls.GET_APP_VERSIONS_LIST, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {
        const auditData = "getAppVersionsList success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data && response.data.version_list) {
            if (response.data.version_list && response.data.version_list.length >= 1) {
                dispatch(setAppVersionListInRed(response.data.version_list));
            } else {
                axiosErrorFunction(response);
            }
        } else {
            axiosErrorFunction(response);
        }
    };


    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while getting app versions."}));
    }

    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const deleteExtraAppVersions = ({stepNo, luisAppDbId, versionList, doEndSession=false}) => dispatch => {

    const auditData = "deleteExtraAppVersions called : stepNo, luisAppDbId, versionList, doEndSession :" + stepNo + "," + luisAppDbId + "," + JSON.stringify(versionList) + "," + doEndSession ;  
    global.recordAudit(auditData);

    const axiosSuccessFunction = (response) => {
        const auditData = "deleteAppVersion success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);
        
        if ((response.code === 200) && response.data) { 
          
            versionList.shift();
            dispatch(deleteExtraAppVersions({stepNo: stepNo, luisAppDbId: luisAppDbId, versionList: versionList}));
        } else {
            axiosErrorFunction(response);
        }
    };
    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while DELETING OLD."}));
    }
    
    if (versionList && Array.isArray(versionList) && (versionList.length >=3 )) {
        let versionToRemove = versionList[0];

      
        if ((versionToRemove != params.luisVersions.defaultVersion) ) {
            let url = getApiUrl(aiBase, aiUrls.DELETE_OLD_VERSION, {luisAppDbId:luisAppDbId, versionId:versionToRemove});
            axiosCall(url, axiosSuccessFunction,  axiosErrorFunction);
        } else {
            versionList.shift();
            dispatch(deleteExtraAppVersions({stepNo: stepNo, luisAppDbId: luisAppDbId, versionList: versionList}));
        }
    } else {
        dispatch(resetStep(true));
    }
}

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const backupExportAppJson = ({stepNo, luisAppDbId}) => dispatch => {
  
    const {rules:{aiVersion}} = store.getState()
    const auditData = "exportAppJson called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.EXPORT_APP_JSON, {luisAppDbId:luisAppDbId,aiVersion:aiVersion});

    const axiosSuccessFunction = (response) => {
        const auditData = "exportAppJson success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);
        

        if ((response.code === 200) && response.data) {
            
            dispatch(incrementStep());
        } else {
            axiosErrorFunction(response);
        }
    };

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while EXPORTING."}));
    }

    axiosCall(url, axiosSuccessFunction,  axiosErrorFunction);
}


/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const createNewJson = ({stepNo, luisAppDbId}) => dispatch => {

    const auditData = "createNewJson called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.CREATE_NEW_JSON, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {
        const auditData = "createNewJson success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data) {
            
            dispatch(incrementStep());
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while CREATING NEW JSON."}));
    }
    
    axiosCall(url,  axiosSuccessFunction, axiosErrorFunction);
}



/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const renameOldToTemp = ({stepNo, luisAppDbId}) => dispatch => {
    const auditData = "renameOldToTemp called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.RENAME, {luisAppDbId:luisAppDbId});
    const data = {
        from_version: params.luisVersions.defaultVersion,
        to_version: params.luisVersions.newTemp
    }

    const axiosSuccessFunction = (response) => {
        const auditData = "renameOldToTemp success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data && response.data.version_id) {
            
            dispatch(incrementStep());

            dispatch({
                type: SET_TEMP_VERSION_OLD,
                payload: response.data.version_id
            });
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while RENAMING TO OLD."}));
    }
    
    axiosCall(url, axiosSuccessFunction,  axiosErrorFunction, data, "POST");
}

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
//  export const renameNewToActiveVersion = ({stepNo, luisAppDbId, newVersionId}) => dispatch => {
//     const auditData = "renameNewToActiveVersion called with : stepNo, luisAppDbId, newVersionId: " + stepNo + "," + luisAppDbId + "," + newVersionId ;  
//     global.recordAudit(auditData);
    
//     let url = getApiUrl(aiBase, aiUrls.RENAME, {luisAppDbId:luisAppDbId});
//     const data = {
//         to_version: params.luisVersions.defaultVersion,
//         from_version: newVersionId,
//     }

//     const axiosSuccessFunction = (response) => {
//         const auditData = "renameNewToActiveVersion success called  : response :" + JSON.stringify(response);   
//         global.recordAudit(auditData);

//         if ((response.code === 200) && response.data && response.data.version_id) {
//             dispatch(incrementStep());
//         } else {
//             axiosErrorFunction(response);
//         }
//     };

    

//     const axiosErrorFunction = (error) => {
//         dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while RENAMING NEW TO ACTIVE."}));
//     }
    
//     axiosCall(url, axiosSuccessFunction,  axiosErrorFunction, data, "POST");
// }

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const importNewAppJson = ({stepNo, luisAppDbId,storyId}) => dispatch => {
    const auditData = "importNewAppJson called with : stepNo, luisAppDbId " + stepNo + "," + luisAppDbId  ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.IMPORT_APP_JSON, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {
        const auditData = "importNewAppJson success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data && response.data.version_id && !response.data.version_id.error) {
            
            dispatch(incrementStep());

            dispatch({
                type: SET_TEMP_VERSION_NEW,
                payload: response.data.version_id
            });
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while IMPORTING."}));
        //call end session here
        dispatch(endSession({stepNo: null, luisAppDbId: luisAppDbId, storyId: storyId,isAbrupt:true}));

        

    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}



/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const startTrainHit = ({stepNo, luisAppDbId}) => dispatch => {

    const auditData = "startTrainHit called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.TRAIN, {luisAppDbId:luisAppDbId})+1;

    const axiosSuccessFunction = (response) => {
        const auditData = "startTrainHit success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data) {
            
            dispatch(incrementStep());
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while TRAIN HIT."}));
    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}



/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const trainingStatusCheck = ({stepNo, luisAppDbId}) => dispatch => {
    const auditData = "trainingStatusCheck called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);

    //to be called for training status failure case
    const getLuisAppInfoAndExportJSON = ({luisAppDbId:luisAppDbId}) => {
        let luisAppInfoUrl = getApiUrl(aiBase, aiUrls.CHECK_INFO, {luisAppDbId:luisAppDbId});
    
        const axiosSuccessFunctionForExportJSON = (response) => {
            const auditData = "Latest AI version was passed to Export App JSON : Success  : response :" + JSON.stringify(response);   
            global.recordAudit(auditData);
        }
    
        const axiosSuccessFunction = (response) => {
            const auditData = "Get Luis App Info success called  : response :" + JSON.stringify(response);   
            global.recordAudit(auditData);
            
            if ((response.code === 200) && response.data && response.data.luis_app_version) {
               let currentLuisAppVersion = response.data.luis_app_version
               let exportAppVersionUrl = getApiUrl(aiBase, aiUrls.EXPORT_APP_JSON, {luisAppDbId:luisAppDbId,aiVersion:currentLuisAppVersion});
               
                //pass the version to export-json api
                axiosCall(exportAppVersionUrl, axiosSuccessFunctionForExportJSON,  axiosErrorFunction);
            } else {
                axiosErrorFunction(response);
            }
        };
    
        const axiosErrorFunction = (error) => {
            dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while TRAIN HIT."}));
        }
    
        //to get the latest appVersion
        axiosCall(luisAppInfoUrl, axiosSuccessFunction,  axiosErrorFunction);
    }
    
    let url = getApiUrl(aiBase, aiUrls.STATUS, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {
        const auditData = "trainingStatusCheck success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data) {

            if (response.data.training_status && response.data.training_status.status) {
                if (global.CUST_CONSTANTS.TRAINING_STATUSES.STATUS_KEYS[response.data.training_status.status] === global.CUST_CONSTANTS.TRAINING_STATUSES.STATUS_VAL.SUCCESS) {
                    dispatch({
                        type: INCREMENT_TRAINING_STEP,
                        payload: null
                    });
                } else if (global.CUST_CONSTANTS.TRAINING_STATUSES.STATUS_KEYS[response.data.training_status.status] === global.CUST_CONSTANTS.TRAINING_STATUSES.STATUS_VAL.IN_PROGRESS) {
                    setTimeout(() => {
                        console.log("Hitting status check again");
                        dispatch(trainingStatusCheck({luisAppDbId:luisAppDbId}));
                    }, (params.luisTrainStatusCheckInterval*1000));
                } else {
                    axiosErrorFunction(response);
                }
            }
        } else {
            axiosErrorFunction(response);
        }
    };


    const axiosErrorFunction = (error) => {
        getLuisAppInfoAndExportJSON({luisAppDbId:luisAppDbId})
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while TRAINING STATUS CHECK."}));    
    }
    
    axiosCall(url, axiosSuccessFunction,  axiosErrorFunction);
}


/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const publishApp = ({stepNo, luisAppDbId}) => dispatch => {
    const auditData = "publishApp called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);

    let url = getApiUrl(aiBase, aiUrls.PUBLISH, {luisAppDbId:luisAppDbId})+1;

    const axiosSuccessFunction = (response) => {
        const auditData = "publishApp success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data) {
            
            dispatch(incrementStep());
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while PUBLISHING APP."}));
    }
    
    axiosCall(url,  axiosSuccessFunction,  axiosErrorFunction);
}




/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const checkInfo = ({stepNo, luisAppDbId}) => dispatch => {
    const auditData = "checkInfo called with : stepNo, luisAppDbId: " + stepNo + "," + luisAppDbId ;  
    global.recordAudit(auditData);
    
    let url = getApiUrl(aiBase, aiUrls.CHECK_INFO, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {
        const auditData = "checkInfo success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data) {
            
            dispatch(incrementStep());
        } else {
            axiosErrorFunction(response);
        }
    };

    

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while CHECKING INFO."}));
    }
    
    axiosCall(url,axiosSuccessFunction, axiosErrorFunction);
}


/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const endSession = ({stepNo, setExistingTrainingSessionObj,luisAppDbId, versionList, storyId,isAbrupt=false}) => dispatch => {
    const auditData = "set endSession called with : stepNo, setExistingTrainingSessionObj, luisAppDbId, versionList : " + stepNo + "," + setExistingTrainingSessionObj +  "," + luisAppDbId + "," + JSON.stringify(versionList) ;  
    global.recordAudit(auditData);
  
    let url = getApiUrl(aiBaseNew, aiUrls.END_SESSION, {luisAppDbId:luisAppDbId ,storyId:storyId });

    // let url = getApiUrl(prApiBase, prApiUrls.END_SESSION, {prId:prId});

    const axiosSuccessFunction = (response) => {
        const auditData = "endSession success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);
        const {luis_app:{appDbId}} = store.getState();

        if ((response.code === 200) && response.data) {
            if (stepNo) {
                dispatch(incrementStep());
            }
            
            if(!isAbrupt){
            dispatch(resetUntrainedLuisObjState())
            // dispatch(setIsAppInSync(false))
            dispatch(getSampleUtterances(appDbId));
            dispatch(getIntents(appDbId));
            dispatch(getEntites(appDbId));
            dispatch(changeHideStartTrainingBtn(false))
            dispatch(changeActionsForUntrainedLuisState(false))
        }

        } else {
            if (stepNo) {
                axiosErrorFunction(response);
            }
        }
    };

    const axiosErrorFunction = (error) => {
        dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: "while ENDING SESSION."}));
    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}
// step 0:
// export const updateReportPageInDb = ({luisAppDbId, practiceId, page, pageJson, reportJson}) => dispatch => {

//     const auditData = "set loading called with luisAppDbId : " + luisAppDbId + "luisAppDbId :" + practiceId + "page :" + page + "pageJson :" + pageJson + "reportJson :" + reportJson ;
//     global.recordAudit(auditData);

//     let url = getApiUrl(base, apiUrls.SAVE_REPORT_PAGE);
//     let data = {
//         storyId: storyId,
//         practice_id: practiceId,
//         page: page,
//         page_json: pageJson
//     }

//     const axiosSuccessFunction = (response) => {

//         const auditData = "set loading called with response : " + JSON.stringify(response) ;
//         global.recordAudit(auditData);

//         if ((response.code === 200) && response.data) {
//             let nextPage = page+1;
//             if (reportJson[practiceId][nextPage]) {
//                 pageJson = reportJson[practiceId][nextPage];
//                 dispatch(updateReportPageInDb({prId: prId, practiceId: practiceId, page: nextPage, pageJson: pageJson, reportJson: reportJson}));
//             } else {
//                 dispatch(checkNResetUnsavedChanges());
//             }
            
//         }
//     };

//     axiosCall({url:url, thenFunc: axiosSuccessFunction, axiosErrorFunction: axiosErrorFunction, data: data, method: "POST"});
// }

/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 * @param {string} mode ,ON_TRAIN_N_PUBLISH,ON_LOAD,ON_SYNC
 * 
 */
export const checkIfPublishedLater = ({luisAppDbId,mode}) => dispatch => {
    
    const auditData = "set loading called with luisAppDbId :" + luisAppDbId;
    global.recordAudit( auditData);

    let url = getApiUrl(aiBase, aiUrls.CHECK_INFO, {luisAppDbId:luisAppDbId});

    const axiosSuccessFunction = (response) => {

        const auditData = "set loading called with response : " + JSON.stringify(response) ;
        global.recordAudit(auditData);

        if ((response.code === 200) && response.data && response.data.published_date_time) {
            let aiVersionName ;
            aiVersionName = response.data.luis_app_version;
            if(aiVersionName){
               dispatch(setAiVersion(aiVersionName))
            }

            if(response.data.exported_date_time){
                let lastPublishedTime = new Date(response.data.published_date_time);
                let exportTime = new Date(response.data.exported_date_time);
              
                // this COMPULSORY  is IF isTrainAndPublish
                if(mode === global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_TRAIN_N_PUBLISH){
                    dispatch(exportAppJson({luisAppDbId, type: global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.TRAIN_N_PUBLISH_STEPS,aiVersion:aiVersionName}))
                }else{
                    if (exportTime < lastPublishedTime) {
                        //pass version to export   
                        dispatch(exportAppJson({luisAppDbId, type: global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.PUBLISHED_LATER,aiVersion:aiVersionName,mode:mode}))
                    }else{
                        //get latest data for on load and on sync anyway
                        dispatch(getEntites(luisAppDbId)) //api changed
                        dispatch(getIntents(luisAppDbId)) //api changed
                        dispatch(getSampleUtterances(luisAppDbId)) //api changed
                        
                        if(mode === global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_SYNC ){
                            dispatch(setAlert(messages.syncSuccess,1))
                        }
                    }
                }
            }else{
                // If null exported date time then export app and then again check info
                dispatch(exportAppJson({luisAppDbId, type: global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.NULL_EXPORTED_TIME,aiVersion:aiVersionName}))
            }
        }
    }; 

    axiosCall(url, axiosSuccessFunction);
}


/**
 * To Export app json
 * @param {int} luisAppDbId current luis app's db id
 */
 export const exportAppJson = ({stepNo, luisAppDbId, type = global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.TRAIN_N_PUBLISH_STEPS,aiVersion,mode}) => dispatch => {
    const auditData = "exportAppJson called  : stepNo, luisAppDbId :" + stepNo + "," + luisAppDbId ;   
    global.recordAudit(auditData);
    const errorMsg = "while EXPORTING."
    let url = getApiUrl(aiBase, aiUrls.EXPORT_APP_JSON, {luisAppDbId:luisAppDbId,aiVersion:aiVersion});

    const axiosSuccessFunction = (response) => {
        const auditData = "exportAppJson success called  : response :" + JSON.stringify(response);   
        global.recordAudit(auditData);
        if ((response.code === 200) && response.data) {
            if(type === global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.TRAIN_N_PUBLISH_STEPS){
                dispatch(incrementStep());
            }
            if(type === global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.NULL_EXPORTED_TIME){
                dispatch(checkIfPublishedLater({luisAppDbId}))
            }

            if(mode === global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_LOAD ||  mode === global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_SYNC){
                dispatch(getEntites(luisAppDbId)) //api changed
                dispatch(getIntents(luisAppDbId)) //api changed
                dispatch(getSampleUtterances(luisAppDbId)) //api changed

                if(mode === global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_SYNC ){
                    dispatch(setAlert(messages.syncSuccess,1))
                }
            }
        } else {
            axiosErrorFunction(response);
        }
    };
    
    let errorVar = type === global.CUST_CONSTANTS.EXPORT_APP_JSON_TYPE.TRAIN_N_PUBLISH_STEPS ? "trainNPublishErrorFn" : "axiosErrorFunction"

    const errorFn = {
        "axiosErrorFunction": ()=>{
            axiosErrorFunction("Something went wrong" + errorMsg)
        },
        "trainNPublishErrorFn": (error)=>{
            // dispatch(trainnPublishErrorFunction({stepNo: stepNo, error: error, message: errorMsg }))
        }
    }
   

    axiosCall(url,axiosSuccessFunction,errorFn[errorVar]);
}



export const startTrainNPublish = ({endSession=false}) => dispatch => {
    const auditData = " startTrainNPublish called with : endSession: " + endSession ;  
    global.recordAudit(auditData);

    dispatch({
        type: SET_END_SESSION,
        payload: endSession
    });
    dispatch(incrementStep());
}

export const trainNPublishProcess = ({ stepNo, luisAppDbId=null, newTempVersion=null, doEndSession=false, versionList=null, storyId = null }) => dispatch => {
    const auditData = "trainNPublishProcess called with : stepNo, luisAppDbId, newTempVersion, doEndSession, versionList: " + stepNo + "," + luisAppDbId +  "," + JSON.stringify(versionList) + "," + newTempVersion + "," + doEndSession;  
    global.recordAudit(auditData);
    console.log("trainNPublishProcess",stepNo ,luisAppDbId,newTempVersion,doEndSession,versionList);

    switch (stepNo) {

        /** Getting all versions list */
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.UPDATE_TRAINING_DATA.stepNo:
            /** Rename old active (0.1) to new temp */
            dispatch(saveUntrainedLuisObjToDb("ai"));
        break;
        /** Getting all versions list */
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.GET_VERSION_LIST.stepNo:
           
            /** Rename old active (0.1) to new temp */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(getAppVersionsList({stepNo: stepNo, luisAppDbId:luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;

        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.CHECK_VERSION_INFO.stepNo:
            /** check version info, */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(checkIfPublishedLater({luisAppDbId:luisAppDbId,mode:global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_TRAIN_N_PUBLISH}))
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;

        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.CREATE_BACKUP.stepNo:
            /** Export the app */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(backupExportAppJson({stepNo: stepNo, luisAppDbId: luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        // case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.EXPORT_APP.stepNo:
        //     /** Export the app */
        //     if (luisAppDbId) {
        //         /** Main function call */
        //         dispatch(exportAppJson({stepNo: stepNo, luisAppDbId: luisAppDbId}));
        //     } else {
        //         dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
        //     }
        // break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.CREATE_NEW_DATASET.stepNo:
            /** Create New json for the app */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(createNewJson({stepNo: stepNo, luisAppDbId:luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing prId, step no. :", stepNo: stepNo}));
            }
        break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.IMPORT_NEW_JSON.stepNo:
            /** Import new app json */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(importNewAppJson({stepNo: stepNo, luisAppDbId: luisAppDbId,storyId:storyId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.START_TRAIN_HIT.stepNo:
            /** Start train hit */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(startTrainHit({stepNo: stepNo, luisAppDbId: luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.TRAINING_STATUS_CHECK.stepNo:
            /** check training status until get a success message */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(trainingStatusCheck({stepNo: stepNo, luisAppDbId: luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.PUBLISH.stepNo:
            /** publish the luis app */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(publishApp({stepNo: stepNo, luisAppDbId: luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        // case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.DELETE_OLD.stepNo:
        //     /** delete the renamed old version */
        //     let defaultVersion = params.luisVersions.defaultVersion;
        //     if (luisAppDbId && defaultVersion) {
        //         /** Main function call */
        //         dispatch(deleteAppVersion({stepNo: stepNo, luisAppDbId: luisAppDbId, versionId: defaultVersion, doEndSession: doEndSession}));
        //     } else {
        //         dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId or newTempVersion, step no. :", stepNo: stepNo}));
        //     }
        // break;
        // case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.RENAME_NEW_TO_ACTIVE.stepNo:
        //     /** If new temp version is not 0.1 then rename else move ahead */
        //     if (luisAppDbId) {
        //         if (newTempVersion !== params.luisVersions.defaultVersion) {
        //             /** Main function call */
        //             dispatch(renameNewToActiveVersion({stepNo: stepNo, luisAppDbId: luisAppDbId, newVersionId: newTempVersion}));
        //         } else {
        //             dispatch(incrementStep());
        //         }
        //     } else {
        //         dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId or newTempVersion, step no. :", stepNo: stepNo}));
        //     }
        // break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.CHECK_INFO.stepNo:
            /** check info and update publish date */
            if (luisAppDbId) {
                /** Main function call */
                dispatch(checkInfo({stepNo: stepNo, luisAppDbId: luisAppDbId}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.END_SESSION.stepNo:
            if (doEndSession) {
                /** end session if asked for */
                if (luisAppDbId) {
                    /** Main function call */
                    dispatch(endSession({stepNo: stepNo, luisAppDbId: luisAppDbId, storyId: storyId }));
                } else {
                    dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing , step no. :", stepNo: stepNo}));
                }
            } else {
                dispatch(incrementStep());
            }
        break;

        /** Deleting extra versions */
        /** Last step */
        case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.DELETE_EXTRA_VERSIONS.stepNo:
            /** Rename old active (0.1) to new temp */
            if (luisAppDbId && versionList && Array.isArray(versionList)) {
                /** Main function call */
                // const versionList = ['v8','v7,v6','v5','v4','v3','v2','0.1']
                const newVersionList = versionList.slice(1) //since fist index is active version
                const acendingOrderVersionList = newVersionList.reverse() //since versionListIs DescOrder
                dispatch(deleteExtraAppVersions({stepNo: stepNo, luisAppDbId:luisAppDbId, versionList: acendingOrderVersionList, doEndSession: doEndSession}));
            } else {
                dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
            }
        break;

        /** Case not called now */
        // case global.CUST_CONSTANTS.TRAIN_N_PUBLISH_STEPS.RENAME_OLD_VERSION.stepNo:
        //     /** Rename old active (0.1) to new temp */
        //     if (luisAppDbId) {
        //         /** Main function call */
        //         dispatch(renameOldToTemp({stepNo: stepNo, luisAppDbId:luisAppDbId}));
        //     } else {
        //         dispatch(trainnPublishErrorFunction({error:"data missing", message:"ERRROR trainNPublish Process Missing luisAppDbId, step no. :", stepNo: stepNo}));
        //     }
        // break;
    
        default:
            break;
    }
}
