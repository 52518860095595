import axiosCall from "../../../utils/axiosCall";
import { setLoading, axiosErrorFunction } from "../common/commonActions";
import { USER_CHANGE } from "../types";
import { getCookie, setCookie } from "../../../utils/utils";

export const setUser = (key, value) => async dispatch => {
    if (key === 'jwtAccessToken') {
        setCookie("jwt_access_token", value, 120);
    } else {
        setLoading();
        var payload = {
            key: key,
            value: value,
        }
        dispatch({
            type: USER_CHANGE,
            payload: payload
        });
    }
};


export const getCentralizedRefreshedToken = () => dispatch => {
    const token = getCookie("central_jwt_refresh_token");
    const url = "/platform/auth/refresh-token";

    const payload = {
        refresh_token:token
    }

    const axiosSuccessFunction = (response) => {
        if (response.data.data && response.data.data.access_token) {
            setCookie("central_jwt_token", response.data.data.access_token, 120);
        }
    }

    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, payload, 'POST', 0, 'platform')
}


/**
 * Get current tokens of the user (Called first time only on page load to get the user tokens)
 */
export const getRefreshToken = () => async dispatch => {
    var token = getCookie("jwt_access_token");
    if (token) {
        setLoading();
        var url = 'auth/tokens';
        const axiosSuccessFunction = (response) => {
            if (response.data && response.data.refreshToken) {
                dispatch(setUser('id', response.data.userId));
                dispatch(setUser('jwtRefreshToken', response.data.refreshToken));
                dispatch(getRefreshedToken());
            }
        };
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    }
};

/**
 * Get latest token of the user by refreshing the access token
 */
export const getRefreshedToken = () => async dispatch => {
    var url = 'auth/refreshed-token';
    const axiosSuccessFunction = (response) => {
        if (response.data && response.data.accessToken) {
            dispatch(setUser('jwtAccessToken', response.data.accessToken));
        }
    };
    /** Axios call with custom method refresh */
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, { }, 'REFRESH');
};

export const setCsrf = () => async dispatch => {
    var url = 'auth/get-csrf';
    const axiosSuccessFunction = (response) => {
    };

    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}