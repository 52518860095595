import getApiUrl from "../../../api-urls/apiUrlsUtils";
import { newUIBase, newUIUrls } from "../../../api-urls/luis-app-apis";
import axiosCall from "../../../utils/axiosCall";
import { checkIfPublishedLater } from "../ai-app/aiAppActions";
import { setLoading, axiosErrorFunction } from "../common/commonActions";
import { setLuisAppDbId, setLuisAppId, setLuisAppName } from "../luis-app/luisAppActions";
import { getUntrainedLuisData } from "../rules/rulesActions";
import { SET_LOADER_OVERLAY, SET_STORY_VARS } from "../types";

export const setStoryVars = (key, value) => async dispatch => {
    setLoading();
    var payload = {
        key: key,
        value: value,
    }
    dispatch({
        type: SET_STORY_VARS,
        payload: payload
    });
};

export const checkForMachineLearnEntities = (appId, storyId) => dispatch => {
        const url = getApiUrl(newUIBase,newUIUrls.MACHINE_LEARN_ENTITY_CHECK,{luisAppId:appId, storyId:storyId})
        const axiosSuccessFunction = (response) => {
            if(response.data){
                response.data.is_mle_present && dispatch(setStoryVars("hasMachineLearnEntities", response.data.is_mle_present));
            }
        };
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}


export const setStoryTitle = (storyId) => async dispatch => {
    if (storyId) {
        setLoading();
        var url = 'workbench/story-details/' + storyId;
        const axiosSuccessFunction = (response) => {
            if(response.data){
                const luisAppDbId = response.data.luisAppDbId[0]
                
                response.data.title && dispatch(setStoryVars("title", response.data.title));
                response.data.storyType && dispatch(setStoryVars("storyType", response.data.storyType));
                response.data.brandId && dispatch(setStoryVars("brandId", response.data.brandId));
                response.data.luisAppId && dispatch(setStoryVars("luisAppId", response.data.luisAppId));
                response.data.luisAppId && dispatch(setLuisAppId(response.data.luisAppId));
                response.data.id && dispatch(setStoryVars("storyId", response.data.id));
                response.data.luisAppDbId[0] && dispatch(setLuisAppDbId(luisAppDbId));
                response.data.luisAppName[0] && dispatch(setLuisAppName(response.data.luisAppName[0]));

                dispatch(checkIfPublishedLater({luisAppDbId:luisAppDbId,mode:global.CUST_CONSTANTS.CHECK_INFO_FROM.ON_LOAD}))
                dispatch(checkForMachineLearnEntities(luisAppDbId,response.data.id))
            }
        };
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    }
}

export const setBackUrl = () => async dispatch => {
    setLoading();
    var url = 'workbench/back-url';
    const axiosSuccessFunction = (response) => {
        if (response.data) {
            dispatch(setStoryVars("backUrl", response.data));
        }
    };
    
    // axiosCall(url, axiosSuccessFunction, axiosErrorFunction, { "forRules": 1 });
}

export const startLoaderOverlay = (customMessage = "") => dispatch => {
    dispatch({
        type: SET_LOADER_OVERLAY,
        payload: {
            isOpen: true,
            customMessage: customMessage
        }
    })
}