/*
 * Component Description : Modal Used to configure entity of block or step
 * Author: Akash Gupta
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes, { object } from 'prop-types';
import { Col, Row } from 'react-bootstrap';

/** Importing third party packages  */
import SearchableSelect from "react-select";

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";
import Tags from "../../UI/Tags/Tags";


/** Importing custom Styles */
import classes from './EntityModal.module.css';


import { setShowEntityModal,setRulesAndCallFlowUpdatedState, setCallFlowJson, getResponsesFiltered, setStepCounter, setAccordionState, emptyFilteredResponses } from "../../../store/actions/rules/rulesActions";
import { setAlert } from "../../../store/actions/common/commonActions";
import { messages } from "../../../data/strings";
import HighlightText from './HighlightText/HighlightText';
import ResponseClips from "../BotResponsesModal/ResponseClips/ResponseClips";
import { params } from '../../../data/params';


/** Main Component */
class EntityModal extends React.Component {
    constructor(props) {
        super(props);
      

        this.CALLFLOW_STEPS = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW;
        this.META_DATA = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA;
        var responsesForModal = EntityModal.getResponsesForModal(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]);
        this.criterias = [
            {
                "value": 0,
                "label": "Should NOT have",
                "isDisabled": false
            },
            {
                "value": 1,
                "label": "Should have",
                "isDisabled": false
            }
        ]
         
        this.parallelStepCriterias = [
            {
                "value": 0,
                "label": "AND",
                "isDisabled": false
            },
            {
                "value": 1,
                "label": "OR",
                "isDisabled": false
            }
        ]
        
        this.configure = [
            {
                "value": 0,
                "label": "Entity",
                "isDisabled": false
            },
            {
                "value": 1,
                "label": "Parallel Steps",
                "isDisabled": false
            }
        ]
        

        
        this.state = {
            selectedConfig:{

                "value": 1,
                "label": "Entity",
                "isDisabled": false
            },

            selectionError: null,
            entityOptions: null,
            selectedCriteria:null,
            selectedFrom: null,
            selectedTo: null,
            fromOption:null,
            toOption:null,
            selectedEntity:null,
            utterances: null,
            isSettingsEntity:false,
            selectedResponseTextKeyJson: null,
            nodeLevelExistingResponses: [],
            responseClips : [],
            haveClips : false,
            responseSelected:null,
            responsesForModal: responsesForModal,
            inputSearchResValue: "",
            keyTimer: null,
            keyDownVal: null,
            selectedParallelStepCriterias:null,
            configureOptions: this.configure,
            overLappingError: null
        }
    }
    componentDidUpdate(prevProps){
        if(JSON.stringify(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]) !== JSON.stringify(prevProps.propsRedux[prevProps.propsRedux.responsesKeyToPick])){
            var responsesForModal = EntityModal.getResponsesForModal(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick]);
                this.setState({
                    responsesForModal: responsesForModal
                })
           }
    }
    // static getDerivedStateFromProps = (nextProps, prevState) => {
    //     var objChanged = null;
    //     if ( Object.keys(nextProps.propsRedux[nextProps.propsRedux.responsesKeyToPick]).length !== prevState.botResponsesLength ) {
    //         var responsesForModal = EntityModal.getResponsesForModal(nextProps.propsRedux[nextProps.propsRedux.responsesKeyToPick]);
    //         objChanged = { responsesForModal: responsesForModal };
    //     }

    //     // if ( nextProps.propsOwned.show !== prevState._mirrorShow ) {
    //     //     let nodeResponses = [];
    //     //     if (
    //     //         nextProps.propsOwned.show && 
    //     //         !prevState._mirrorShow &&
    //     //         nextProps.propsRedux.responseAction && 
    //     //         nextProps.propsRedux.responseAction.referencePaths && 
    //     //         nextProps.propsRedux.responseAction.referencePaths[0] && 
    //     //         !nextProps.propsRedux.responseAction.referencePaths[0].isMetadata
    //     //     ) {
    //     //         let node = ( 
    //     //                 nextProps.propsRedux.responseAction.referencePaths[0].node
    //     //                 ) 
    //     //                 ? nextProps.propsRedux.responseAction.referencePaths[0].node 
    //     //                 : (
    //     //                     (   
    //     //                         nextProps.propsRedux.responseAction.referencePaths[0].nodeKey
    //     //                     ) 
    //     //                     ? nextProps.propsRedux.responseAction.referencePaths[0].nodeKey
    //     //                     : null);
    //     //         if (node && 
    //     //             (node !== global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE) && 
    //     //             (node !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE)
    //     //         ) {
    //     //             nodeResponses = getPathsDataFromResponseNodeObj({node: node, obj: nextProps.propsRedux.rulesJson.ruleResponses[node], responseTextArrOnly: true});
    //     //         }
    //     //     }

    //         if (!objChanged) {
    //             objChanged = {};
    //         }
    //     //     objChanged = {
    //     //         ...objChanged,
    //     //         _mirrorShow: nextProps.propsOwned.show,
    //     //         nodeLevelExistingResponses: nodeResponses,
    //     //     }
    //     // }

    //     // if ( nextProps.propsRedux.isChanged ) {
    //     //     var intentsForModal = getIntentsForModal(nextProps.propsRedux.intents, nextProps.propsRedux.rulesJson);
    //     //     if (!objChanged) {
    //     //         objChanged = {};
    //     //     }
    //     //     return { ...objChanged, intentsForModal: intentsForModal };
    //     // } else if ( nextProps.propsRedux.intents && prevState.intentsForModal && Object.keys(nextProps.propsRedux.intents).length != Object.keys(prevState.intentsForModal).length ) {
    //     //     var intentsForModal = getIntentsForModal(nextProps.propsRedux.intents, nextProps.propsRedux.rulesJson);
    //     //     if (!objChanged) {
    //     //         objChanged = {};
    //     //     }
    //     //     return { ...objChanged, intentsForModal: intentsForModal };
    //     // }

    //     if (objChanged) {
    //         return objChanged;
    //     }

    //     return null;
        
    // }

    static getResponsesForModal = (botResponses, responseId = []) => {
        var responsesForModal = [];
        var uniqueKeyObj = {};
        var tagsTemp = [];
        if(Array.isArray(responseId) && responseId && responseId.length>0){
                responseId = responseId.map(String);
                botResponses = Object.keys(botResponses)
                .filter(key => responseId.includes(key))
                .reduce((obj, key) => {
                obj[key] = botResponses[key];
                return obj;
                }, {});
        }
        Object.keys(botResponses).forEach((responseId) => {
            var label = botResponses[responseId]["utterance_text"];
            if (uniqueKeyObj[label]) {
                return null;
            }
            tagsTemp = {};
            if (botResponses[responseId]["relatedUtterances"].length > 0) {
                botResponses[responseId]["relatedUtterances"].forEach(idtemp => {
                    if ( botResponses[idtemp] && botResponses[idtemp].TagDetails && Object.keys(botResponses[idtemp].TagDetails).length > 0) {
                        Object.keys(botResponses[idtemp].TagDetails).forEach(tagCollection => {
                            if (!tagsTemp[tagCollection]) {
                                tagsTemp[tagCollection] = [];
                            }
                            tagsTemp[tagCollection] = [ ...tagsTemp[tagCollection], ...botResponses[idtemp].TagDetails[tagCollection] ];
                            tagsTemp[tagCollection] = [ ...new Set(tagsTemp[tagCollection]) ];
                        })
                    }
                });
            }
            uniqueKeyObj[label] = responseId;
            responsesForModal.push({ value: responseId, label: label, tags: tagsTemp });
        });

        return responsesForModal;
    }

    formatOptionLabelResponses = ({ value, label, tags }) => (
        <div className={ [ classes.Option ].join(' ') }>
            { label }
            <Tags keyBase={ "response_modal_tag_" + value } tags={ tags } />
        </div>
    );

    onHide = () => {
        global.recordAudit("Entity modal closed");
        this.props.emptyFilteredResponses();
        this.setState({ 
            selectedCriteria: null,
            selectedEntity:null,
            utterances:null,
            isSettingsEntity:false,
            selectedResponseTextKeyJson: null,
            nodeLevelExistingResponses: [],
            responseClips : [],
            responseSelected:null,
            selectionError: null,
            configureOptions: this.configure,
            selectedParallelStepCriterias: null,
            overLappingError: null, 
            responsesForModal: [],
            haveClips : false,
        });
        this.props.setShowEntityModal(false, null,this.props.propsRedux.statements.key,this.props.propsRedux.statements.block);
    }

    // TODO: Configure interruption for editing mode
    onShow = () => {
        const callFlowStepJSON = this.props.propsRedux.callFlowJson[this.CALLFLOW_STEPS];
        const mainSequence = this.props.propsRedux.callFlowJson[this.META_DATA]['main-sequence'];          
        let stepIdx =0;
        const options = []
         mainSequence.forEach((step, index)=>{
            if(callFlowStepJSON[step]){
                stepIdx++;
                options.push({
                    "value": step,
                    "label": 'A'+(stepIdx),
                    "isDisabled": false
                }) 
            }
        });
        options.pop();
        this.setState({fromOption:options, toOption:options })
       
        global.recordAudit("Entity modal Opened");
        const { statements: {key, block, type, selectedSteps} = {}, entities = []} = this.props.propsRedux;

        if(type === 'multipath'){
            let filteredMainSequnce = mainSequence.filter(step=> callFlowStepJSON[step]);
            const selectedFrom = {
                "value": selectedSteps[0],
                "label": 'A'+(filteredMainSequnce.indexOf(selectedSteps[0]) + 1) ,
                "isDisabled": false
            };
            const selectedTo = {
                "value": selectedSteps[selectedSteps.length-1],
                "label": 'A'+(filteredMainSequnce.indexOf(selectedSteps[selectedSteps.length-1]) + 1) ,
                "isDisabled": false
            }
            const parallelStepObj = {
                "value": 1,
                "label": "Parallel Steps",
                "isDisabled": false
            }
            let optionIdx = 0
            for(let i=0; i < options.length; i++){
                if(options[i].value === selectedSteps[0]){
                    optionIdx = i;
                    break
                }
            }
            const toOptions= options.slice(optionIdx);
            this.setState({
                configureOptions: [parallelStepObj],
                selectedConfig : parallelStepObj,
                selectedFrom: selectedFrom, 
                selectedTo: selectedTo,
                toOption: toOptions
            })
        }else{
            const containerObj = this.entitiesContainer();
            const requiredEntitesArr = this.getRequiredEntities(containerObj.entities, entities);
            const entityConfigObj = this.configure[0]
                
            // For editing existing entity
            let deafultCriteria ={value: 1, label: "Should have", isDisabled: false}
            let defaultValue = this.props.propsRedux.statements.defaultValue;
            if(defaultValue){
                let defaultEntity = {value: defaultValue.value, label:defaultValue.value, isDisabled: false};
                deafultCriteria= {value: defaultValue.criteria, label: defaultValue.criteria===0?"Should NOT have": "Should have",isDisabled: false}
                this.onEntityChange(defaultEntity);
            }
            this.setState({
                entityOptions: requiredEntitesArr,
                selectedFrom:options[0], 
                selectedTo:options[options.length-1],
                configureOptions: [entityConfigObj]
                // selectedCriteria: deafultCriteria,
            })
        }
    }

    getRequiredEntities =(blockEntites=[],entities=[]) =>{
        let blockEntitesValues = blockEntites.map(item=>item.value);
        return entities ? entities.map(entity=>{
            return {value: entity ,label: entity, isDisabled: blockEntitesValues.includes(entity)}
        }):[]
    }

    entitiesContainer = () => {
        const { statements: {key, block, isStepEntity} = {}, callFlowJson = {}} = this.props.propsRedux;
        /** Call Flow version 1.1 and old support */
        const newCallFlowJson = (callFlowJson[this.CALLFLOW_STEPS]) ? callFlowJson[this.CALLFLOW_STEPS] : callFlowJson;
        const obj = newCallFlowJson[key] ? newCallFlowJson[key] : {};
        
        return isStepEntity ? obj.settings : (obj.list && obj.list[block]);
    }

    onAddConfigureEntity = () => {  
        if(this.state.selectedConfig.label==="Entity"){    

        const { statements={}, callFlowJson = {} } = this.props.propsRedux;
        const { selectedEntity = {}, selectedCriteria } = this.state;
        
        const newCallFlowJson = (callFlowJson[this.CALLFLOW_STEPS]) ? callFlowJson[this.CALLFLOW_STEPS] : callFlowJson;

        const  newInterruption = this.state.responseSelected ? {
            id: this.state.responseSelected,
            BRText: this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][this.state.responseSelected].utterance_text,
            BRClip: this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][this.state.responseSelected].filepath,
            responseUsed: "False",
        }: null;

        const newEntityObj = {
            value:selectedEntity.value,
            criteria:selectedCriteria.value,
            interruption: newInterruption
        };

        const containerObj = this.entitiesContainer();
        if (containerObj) {

            if(containerObj.entities){
                if(statements.defaultValue){
                    containerObj.entities.forEach(item=>{
                        if(item.value===statements.defaultValue.value){
                            item.value =  selectedEntity.value
                            item.criteria = selectedCriteria.value
                            item.interruption = {...newInterruption}
                        }
                    })
                }
                else{
                    containerObj.entities = [...containerObj.entities,newEntityObj]
                }
            }
            else{
                containerObj['entities'] = [newEntityObj]
            }            
        }
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJson,true,true);
        }
        else{
            let parallelStepObj = {
                selectedFrom:this.state.selectedFrom.value,
                selectedTo:this.state.selectedTo.value,
                selectedCondition:this.state.selectedParallelStepCriterias 
            }
            this.relationHandler(parallelStepObj)
        }
        this.onHide();
    }
    
    relationHandler = parallelStepObj =>{
        const newCallFlowJson = {...this.props.propsRedux.callFlowJson};
        const accordionState = this.props.propsRedux.accordionState
        let relations = newCallFlowJson[this.META_DATA]['relations'] ? newCallFlowJson[this.META_DATA]['relations'] : {};
        let mainSequence = newCallFlowJson[this.META_DATA]['main-sequence']
        let firstElIdx = mainSequence.indexOf(parallelStepObj.selectedFrom);
        let lastElIdx = mainSequence.indexOf(parallelStepObj.selectedTo);
        let relationPath = mainSequence.slice(firstElIdx, lastElIdx+1 );

        // If first step of relation is already passable make it strict
        if(newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedFrom] &&
            newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedFrom].settings){
                newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedFrom].settings['passable'] = false
        }
         // If last step of relation is already passable make it strict
        if(newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedTo] &&
            newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedTo].settings){
                newCallFlowJson[this.CALLFLOW_STEPS][parallelStepObj.selectedTo].settings['passable'] = false
        }
        this.props.propsRedux.statements.defaultValue ? this.editRelation(newCallFlowJson ,relations, parallelStepObj, relationPath, accordionState) : this.addRelation(newCallFlowJson , relations, parallelStepObj, relationPath, accordionState)

        newCallFlowJson[this.META_DATA]['relations'] = {...relations};
        
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJson,true,true);
        this.props.setAccordionState(accordionState, true)

    }

    addRelation = (newCallFlowJson, relations, parallelStepObj, relationPath, accordionState, relationObj= null) =>{
        let newStep = 'step'+ (this.props.propsRedux.stepCounter+1);
        global.recordAudit("Adding new relation:" + newStep);
        if(!relationObj){
            relationObj = {
                path1: relationPath,
                path2: [newStep],
                relation: parallelStepObj.selectedCondition.label
            }
        }
        if(relations[parallelStepObj.selectedFrom]){
            relations[parallelStepObj.selectedFrom].push(relationObj);
            const relationIdx = relations[parallelStepObj.selectedFrom].length-1
            accordionState[`${parallelStepObj.selectedFrom}-${relationIdx}`] = false
        }else{
            relations[parallelStepObj.selectedFrom] = [relationObj]
            accordionState[`${parallelStepObj.selectedFrom}-0`] = false
        }
        newCallFlowJson[this.CALLFLOW_STEPS][newStep] = {};
        newCallFlowJson[this.CALLFLOW_STEPS][newStep]['list'] = [];
        this.props.setStepCounter(this.props.propsRedux.stepCounter+1);
    }

    editRelation = (newCallFlowJson, relations, parallelStepObj, relationPath, accordionState) => {
        let {step, relationIdx} = this.props.propsRedux.statements.defaultValue;
        relationIdx = parseInt(relationIdx)
        let previousPath2 = relations[step][relationIdx].path2
        let relationObj = {
            path1: relationPath,
            path2: [...previousPath2],
            relation: parallelStepObj.selectedCondition.label
        }
        if(step === parallelStepObj.selectedFrom){
            relations[step][relationIdx] = relationObj
            global.recordAudit("Edit the relation:" + this.props.propsRedux.statements.defaultValue);
            accordionState[`${parallelStepObj.selectedFrom}-${relationIdx}`] = false; 
        }else{
            relations[step].splice(relationIdx,1);
            if(relations[step].length <= 0){
                delete relations[step]
            }
            delete accordionState[`${step}-${relationIdx}`]
            this.addRelation(newCallFlowJson, relations, parallelStepObj, relationPath, accordionState, relationObj)
        }
    }

    onCriteriaChange = (selectedCriteria) =>{
        this.setState({selectedCriteria:selectedCriteria})
    }

    onConfigChange = (selectedConfig) =>{
        // const mainSequence = this.props.propsRedux.callFlowJson[this.META_DATA]['main-sequence'];          
        // // let options= mainSequence.map((step, index)=>{return {
        // //     "value": step,
        // //     "label": 'A'+(index+1),
        // //     "isDisabled": false
        // // }});

        this.setState({
            selectedConfig:selectedConfig,selectedCriteria: null, selectedParallelStepCriterias:null,
            // selectedFrom:options[0],
            // selectedTo:options[options.length-1],
            selectedEntity:null,
            overLappingError: null
            
        })
    }

    onFromChange = (selectedFrom) =>{
        const callFlowStepJSON = this.props.propsRedux.callFlowJson[this.CALLFLOW_STEPS]
        const mainSequence = this.props.propsRedux.callFlowJson[this.META_DATA]['main-sequence'];
        let stepIdx =0;          
        
        let optionIdx = 0
        for(let i=0; i < this.state.fromOption.length; i++){
            if(this.state.fromOption[i].value === selectedFrom.value){
                optionIdx = i;
                break
            }
        }
        let overLappingError = ''
        const options= this.state.fromOption.slice(optionIdx);
        if(!this.isNotOverlappingAnd(selectedFrom, options[0], this.state.selectedParallelStepCriterias )){
            overLappingError = messages.overLappingRelations
        }
        this.setState({selectedFrom:selectedFrom,toOption:options, selectedTo:options[0], overLappingError: overLappingError})
    }

    onToChange = (selectedTo) =>{
        let overLappingError = ''
        if(!this.isNotOverlappingAnd(this.state.selectedFrom, selectedTo, this.state.selectedParallelStepCriterias )){
            overLappingError = messages.overLappingRelations
        }
        this.setState({selectedTo:selectedTo,overLappingError: overLappingError })
    }

    onChangeParallelStepCriteria = (selectedParallelStepCriterias) =>{
        let overLappingError = ''
        if(!this.isNotOverlappingAnd(this.state.selectedFrom, this.state.selectedTo, selectedParallelStepCriterias )){
            overLappingError = messages.overLappingRelations
        }
        this.setState({selectedParallelStepCriterias:selectedParallelStepCriterias, overLappingError: overLappingError })
    }
    

    onEntityChange = (selectedEntity) =>{

        let intent = this.props.propsRedux.statements && this.props.propsRedux.statements['intent'];
        let requiredUtterances = [];
        if(this.props.propsRedux.isStepEntity){
            let utterancesArr = [];
            intent.forEach(el=>{
                if(this.props.propsRedux.entitiesUtterances){
                    const entitiesUtterances = this.props.propsRedux.entitiesUtterances[`${el}::${selectedEntity.value}`];
                    if(entitiesUtterances){
                        utterancesArr = [...utterancesArr,...entitiesUtterances ] 
                    }
                }
            });
            requiredUtterances = utterancesArr  ? this.filterUtteranceArr(utterancesArr): []
        }
        else{
            let utterancesArr = []
            utterancesArr = this.props.propsRedux.entitiesUtterances && this.props.propsRedux.entitiesUtterances[`${intent}::${selectedEntity.value}`]; 
            requiredUtterances = utterancesArr  ? this.filterUtteranceArr(utterancesArr): [];
        }
        
        this.setState({utterances: requiredUtterances,selectedEntity:selectedEntity})
    }

    filterUtteranceArr = (utteranceArr) => {
        const utteranceIdMap = {};
        utteranceArr.forEach((utterance) => {
          if (utteranceIdMap[utterance.utterance_id]) {
            utteranceIdMap[utterance.utterance_id] = [
              ...utteranceIdMap[utterance.utterance_id],
              utterance
            ];
          } else {
            utteranceIdMap[utterance.utterance_id] = [utterance];
          }
        });
    
        const requiredArr = Object.keys(utteranceIdMap).map((utteranceId) => {
          if (utteranceIdMap[utteranceId].length > 1) {
            return {
              ...utteranceIdMap[utteranceId][0],
              newPos: utteranceIdMap[utteranceId].map((item) => {
                return { startPos: item.startPos, endPos: item.endPos };
              })
            };
          } else {
                return { ...utteranceIdMap[utteranceId][0] };
          }
        });
    
        return requiredArr
    }; 

    formatOptionLabel = ({ label, isDisabled }) => (
        <div className={(isDisabled == true) ? [classes.entityInJson].join(' ') : ''}>
            <span>{label}</span>
        </div>
    );


    onResponseChange = (response) => {
        var key = response.value;
        this.props.getResponsesFiltered(this.props.propsRedux.storyId, {keyword:response.label}, true, true);
        global.recordAudit("Response selection change : " + key);
        this.setClips(key);
        let selectionError = this.state.selectionError;
        let obj=this.state.nodeLevelExistingResponses.filter(item=>item.BRText==response.label)[0];
        let resAction = this.props.propsRedux.responseAction.referencePaths[0];
        if (
            obj &&
            resAction &&
            ( obj.nodeKey === resAction.nodeKey ) && 
            (
                (this.props.propsRedux.responseAction.mode != global.CUST_CONSTANTS.CRUD_MODE.EDIT) || 
                ( obj.ruleKey != resAction.ruleKey ) ||
                ( obj.responseKey != resAction.responseKey ) ||
                ( obj.iterationKey != resAction.iterationKey ) ||
                ( obj.index != resAction.index )
            )
        ) {
            if (
                (this.state.nodeLevelExistingResponses.length > 0) &&
                (obj) && 
                (response.label == obj.BRText)
            ) {
                selectionError = messages.responseAlreadyOnNode;
            } else if (selectionError === messages.responseAlreadyOnNode) {
                selectionError = null;
            }
        }
        this.setState({ 
            selectedResponseTextKeyJson: response,
            selectionError: selectionError,
            responseSelected: null
        });
    }

    setClips = (key, selectedId = null) => {
        if (this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][key] && this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][key]["relatedUtterances"]) {
            let haveClips = false
            var clipsArr = this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][key]["relatedUtterances"].map(keyInner => {
                if(this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][keyInner].audio_clip){
                    haveClips = true
                }
                return this.props.propsRedux[this.props.propsRedux.responsesKeyToPick][keyInner];
            })
            this.setState({responseClips: [...clipsArr],haveClips: haveClips });
        }
    }

    onClipSelection = (e) => {
        if (e.target.value) {
            // global.recordAudit("Response clip selection changed : " + e.target.value);
            /** Considering that all response editing ids are same for now like first */
            // if ((this.props.propsRedux.responseAction.referencePaths.length > 0) && (e.target.value == this.props.propsRedux.responseAction.referencePaths[0].referenceId)) {
            //     this.setState({ 
            //         isSaveDisabled: true,
            //         responseSelected: null,
            //     });
            // } else {
                // var isSaveDisabled = false;
                
                // if (!this.state.selectionError) {
                //     isSaveDisabled = false;
                // } else {
                //     isSaveDisabled = true;
                // }
                this.setState({ 
                    // isSaveDisabled: isSaveDisabled,
                    responseSelected: e.target.value,
                });
            // }
            // this.props.setResponseAction(null, parseInt(e.target.value));
        } else {
            // global.recordAudit("Response clip selection change reset");
            this.setState({ 
                // isSaveDisabled: true,
                responseSelected: null,
            });
            // this.props.setResponseAction(null);
        }
    }

    // onKeyDown = (e) => {
    //     // console.log("DEBUGGER:: keydown e.keyCode, e.target.value, e.key", e.keyCode, e.target.value, e.key);
    //     if (!this.props.propsRedux.botResponsesSyncedAll) {

    //         if ((e.keyCode==32) || (e.keyCode == 13)) {
    //             console.log("DEBUGGER:: keydown e.keyCode, e.target.value, e.key", e.keyCode, e.target.value, e.key);
    //             this.props.getResponsesFiltered(this.props.propsRedux.storyId, e.target.value, false);
    //             this.clearTimer();
    //         } else {
    //             this.setState({keyDownVal: e.target.value});
    //             this.initTimer();
    //         }
    //     }
    // }

    onInputChange = (value) => {
        var valToSearch = value;
        
        if (valToSearch !== "") {
            valToSearch = valToSearch.replace("`","'").replace("’","'");
        }
        this.setState({inputSearchResValue: valToSearch});
        console.log("DEBUGGER:: Input changed valToSearch :", valToSearch);
        if ((!this.props.propsRedux.botResponsesSyncedAll) && (valToSearch.trim() !== "")) {
            var lastChar = valToSearch.substr(valToSearch.length - 1);
            let splitText={}
            if(valToSearch.includes(" @")){
                /* Splits keyword, tagCollection and tag*/
              var symbol=/@(.*?):/;
              var tagCollection= valToSearch.split(symbol)
              console.log("DEBUGGER:: Array of voice",tagCollection)
              splitText={keyword:tagCollection[0].trim(),tagCollection:tagCollection[1],tag:tagCollection[2]}
            }
            else if(valToSearch.includes(" :")){
                /* Splits keyword and tag*/
                var symbol=":";
                var tagCollection= valToSearch.split(symbol)
                console.log("DEBUGGER:: Array contains keyword and tag",tagCollection)
                splitText={keyword:tagCollection[0].trim(),tag:tagCollection[1]}
            }
            else{
                var tagCollection= valToSearch
                console.log("DEBUGGER:: Array of keyword",tagCollection)
                splitText={keyword:tagCollection}
            }
            
            if ((lastChar == " ")) {
                console.log("DEBUGGER:: Input changed space :", valToSearch);
                this.props.getResponsesFiltered(this.props.propsRedux.storyId, splitText, false, true);
                this.clearTimer();
            } 
            else {
                console.log("DEBUGGER:: Input changed normal :", valToSearch);
                this.setState({keyDownVal: splitText});
                this.initTimer();
            }
        }
    }

    filterOption = (candidate, input) => {
        let entry={}
        if(input.includes(" @")){
            /* Filter the response we need for matching candidate and input value*/
          var symbol=/@(.*?):/;
          var tagCollection= input.split(symbol)
          entry={text:tagCollection[0].trim()}
        }
        else if(input.includes(" :")){
             /* Filter the response we need for matching candidate and input value*/
            var symbol=":";
            var tagCollection= input.split(symbol)
            entry={text:tagCollection[0].trim()}
        }
        else{
            entry={text:input}
        }
        return candidate.data.__isNew__ || (candidate.label &&candidate.label.replace("`","'").replace("’","'").toLowerCase().includes(entry.text.toLowerCase()));
    };

    /** Adding auto filter functionality */
    // var timer = null;
    clearTimer = () => {
        if (this.state.keyTimer) {
            clearInterval(this.state.keyTimer);
        }
    }

    initTimer = () => {
        this.clearTimer();
        var timer = setTimeout(() => {
            this.props.getResponsesFiltered(this.props.propsRedux.storyId, this.state.keyDownVal, false, global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.INTERRUPTION);
        }, ( params.keyGapTimeForFilter));
        this.setState({keyTimer:timer});
    }

    isNotOverlappingAnd = (selectedFrom = null, selectedTo = null, selectedParallelStepCriterias = null ) =>{
        let isDisabled = true;
        if(!selectedParallelStepCriterias){
            return isDisabled
        }
        const callFlowMetaData =  this.props.propsRedux.callFlowJson && this.props.propsRedux.callFlowJson[this.META_DATA];
        if(callFlowMetaData && selectedFrom && selectedTo && selectedParallelStepCriterias){
            const relations = callFlowMetaData['relations'];
            if(relations){
                let andMainSeuencePaths = [];
                let allMainSequencePaths = [];
                Object.keys(relations).forEach(key=>{
                    relations[key].forEach(rel=>{
                        if(rel.relation === 'AND'){
                            andMainSeuencePaths.push(...rel.path1)
                        }
                        allMainSequencePaths.push(...rel.path1)
                    })
                });
                let mainSequence = callFlowMetaData['main-sequence'];
                let idx1 = mainSequence.indexOf(selectedFrom.value);
                let idx2 = mainSequence.indexOf(selectedTo.value);
                let requiredArr = mainSequence.slice(idx1,idx2+1);
                let compareArr = selectedParallelStepCriterias.label === 'AND' ? allMainSequencePaths :  andMainSeuencePaths;
                let editPath1 = []
                if(this.props.propsRedux.statements.defaultValue){
                    let {step, relationIdx} = this.props.propsRedux.statements.defaultValue;                    
                    editPath1 = relations[step] && relations[step][relationIdx] ? relations[step][relationIdx].path1 : []
                }
                for(const step of requiredArr){
                    if(compareArr.includes(step) && !editPath1.includes(step)){
                        isDisabled = false;
                        return isDisabled
                    }
                }
            }
            return isDisabled
        }
        
    }

    isDisabled = () =>{
        if(this.state.selectedConfig["label"]=="Parallel Steps"){
            return !(this.state.selectedParallelStepCriterias && this.state.selectedFrom && this.state.selectedTo)
        }else{
            return !this.state.selectedEntity ||  (this.state.selectedEntity && !(this.state.utterances && this.state.utterances.length))
        }
    }
    render() {
        
        var modalButtons = [
            {
                type: 'cancel',
                text: 'Discard',
                variant: 'light',
                onClick: this.onHide,
                
                
            },
            {
                type: 'save',
                text: 'Save',
                variant: 'primary',
                onClick: this.onAddConfigureEntity,
                className: 'lg-btnPrimaryCust',
                isDisabled: this.isDisabled() || this.state.overLappingError
            } 

            
            
        ];

        return (
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.onShow}
                onHide={this.onHide}
                title={"Rules"}
                footerComponents={modalButtons}
                size={"lg"}
                className={classes.EntityModalContent}                
            >

                
                <Row className={ [ 'mt-10','mb-3','d-flex','align-item-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    <Col md={3} className={classes.labelFont}>
                        Configure
                    </Col>
                    <Col  className={classes.selectInput}>
                        <SearchableSelect
                            name="Configure"
                            className={classes.EntityModalLabel}
                            options={this.state.configureOptions}
                            value={this.state.selectedConfig}
                            onChange={this.onConfigChange}
                            formatOptionLabel={this.formatOptionLabel}
                        />
                    </Col>
                </Row>

                {(this.state.selectedConfig["label"]=="Entity") &&
                <Row className={ [ 'mt-10','mb-3','d-flex','align-item-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    <Col md={3}  className={classes.labelFont}>
                        Add a Condition
                    </Col>
                    <Col className={classes.selectInput}>
                        <SearchableSelect
                            name="criteria"
                            className={classes.EntityModalLabel}
                            options={this.criterias}
                            value={this.state.selectedCriteria}
                            onChange={this.onCriteriaChange}
                            formatOptionLabel={this.formatOptionLabel}
                        />
                    </Col>
                </Row>}

                {(this.state.selectedConfig["label"]=="Parallel Steps") &&
                <Row className={ [ 'mt-10','mb-3','d-flex','align-item-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    <Col sm={3}  className={classes.labelFont}>
                        From
                    </Col>
                    <Col sm={4} className={classes.selectInput} >
                        <SearchableSelect
                            name="from"
                            className={classes.EntityModalLabel}
                            options={this.state.fromOption}
                            value={this.state.selectedFrom}
                            onChange={this.onFromChange}
                            formatOptionLabel={this.formatOptionLabel}
                        />
                    </Col>
                    <Col sm={1} className={classes.labelFont} style={{marginLeft:"-58px"}}>
                        To
                    </Col>
                    <Col sm={4} className={classes.selectInput} style={{marginLeft:"-12px"}} >
                        <SearchableSelect
                            name="to"
                            className={classes.EntityModalLabel}
                            options={this.state.toOption}
                            value={this.state.selectedTo}
                            onChange={this.onToChange}
                            formatOptionLabel={this.formatOptionLabel}
                        />
                    </Col>
                    
                </Row>}

                {(this.state.selectedConfig["label"]=="Parallel Steps") &&
                    <>
                        <Row className={ [ 'mt-10','mb-3','d-flex','align-item-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                            <Col md={3}  className={classes.labelFont}>
                                Add a Condition
                            </Col>
                            <Col className={classes.selectInput}>
                                <SearchableSelect
                                    name="criteria"
                                    className={classes.EntityModalLabel}
                                    options={this.parallelStepCriterias}
                                    value={this.state.selectedParallelStepCriterias}
                                    onChange={this.onChangeParallelStepCriteria}
                                    formatOptionLabel={this.formatOptionLabel}
                                />
                            </Col>

                        </Row>
                    
                        {this.state.overLappingError  &&
                        <Row className={ [ 'mt-10','mb-3','d-flex','align-item-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                            <Col md={10}>
                                <div className={['alert', 'alert-danger', 'w-100'].join(' ')}>
                                    { this.state.overLappingError }
                                </div>
                            </Col>
                        </Row>
                        }
                    </>
                }

                

                {this.state.selectedCriteria &&
                <Row className={ [ 'mt-10','d-flex','align-items-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    
                    <Col md={3} className={classes.labelFont}>
                        Entity 
                    </Col>
                    <Col className={classes.selectInput}>
                        <SearchableSelect
                            name="Entity"
                            className={classes.EntityModalLabel}
                            options={this.state.entityOptions}
                            value={this.state.selectedEntity}
                            onChange={this.onEntityChange}
                            formatOptionLabel={this.formatOptionLabel}
                        />
                    </Col>
                </Row>
                }

                {this.state.selectedEntity &&
                <Row className={ [ 'mt-10','d-flex','align-items-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    
                    <Col md={3} className={[classes.labelFont, 'mr-4'].join(' ')}>
                        Utterance 
                    </Col>
                    <Col className={[classes.utterancesContainer].join(' ')}>
                            {this.state.utterances && this.state.utterances.length ? 
                                this.state.utterances.map(item=>{
                                    const positionArr = item.newPos && item.newPos.length ? item.newPos : [{ startPos:item.startPos, endPos:item.endPos }]
                                    return(
                                        <div key={item.utterance_id} className={[classes.utterances].join(' ')}>
                                            <HighlightText 
                                                sentence={item.utterance_text} 
                                                entityPositions = {positionArr}
                                                entity={item.name}
                                                className={classes.EntityModalLabel}
                                            />
                                        </div>
                                    )
                            })
                            : 
                            <div className={[classes.noUtteranceMessage].join(' ')}>
                                No Utterance Found!
                            </div>
                        }
                    </Col>
                </Row>}
                
                {this.state.selectedEntity &&
                <Row className={ [ 'mt-10','d-flex','align-items-center' ].join(' ') } style={{paddingLeft:"40px"}}>
                    
                    <Col md={3} className={classes.labelFont}>
                    Interruption  
                    </Col>
                    <Col className={classes.selectInput}>
                        <SearchableSelect
                            name="interruption"
                            className={classes.EntityModalLabel}
                            value={ this.state.selectedResponseTextKeyJson }
                            hideSelectedOptions={ true }
                            options={ this.state.responsesForModal } 
                            onChange={ (response) => this.onResponseChange(response, false) } 
                            // onKeyDown={ (e) => this.onKeyDown(e)}
                            noOptionsMessage={() => (this.props.propsRedux.filteringInProgress ? "Loading..." : "No responses available.")}
                            formatOptionLabel={ this.formatOptionLabelResponses }
                            inputValue={this.state.inputSearchResValue}
                            onInputChange={ (value) => this.onInputChange(value)}
                        />
                    </Col>
                </Row>
                }
                
                
                
                <ResponseClips
                    modalResponseClips={ this.state.responseClips }
                    haveClips = {this.state.haveClips}
                    onClipSelection={ this.onClipSelection }
                    selectedResponse={ this.props.propsRedux.currentResponse && this.props.propsRedux.currentResponse.selectedResponse }
                />
                
            </Modal>
        );

    }
}

/** Defining prop types requirements */
// EntityModal.propTypes = {
//     propsRedux: PropTypes.shape({
//         // sampleUtterances: PropTypes.object.isRequired
//     }),
// };

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            configureEntity: state.rules.configureEntity,
            statements: state.rules.statements,
            callFlowJson: state.rules.callFlow.callFlowJSON,
            entitiesUtterances: state.rules.entitiesUtterances,
            entities: state.rules.entities,
            botResponses: state.rules.botResponses,
            filteredBotResponses: state.rules.filteredBotResponses,
            responsesKeyToPick: state.rules.botResponsesLoadingVars.responsesKeyToPick,
            botResponsesSyncedAll: state.rules.botResponsesLoadingVars.botResponsesSyncedAll,
            filteringInProgress: state.rules.botResponsesLoadingVars.filteringInProgress,
            storyId: state.story.storyId,
            stepCounter: state.rules.stepCounter,
            responseAction: state.rules.responseAction,
            accordionState: state.rules.accordionState
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setCallFlowJson,setRulesAndCallFlowUpdatedState, setShowEntityModal, setAlert, getResponsesFiltered, setStepCounter, setAccordionState, setAccordionState, emptyFilteredResponses })(EntityModal);