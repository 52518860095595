/** Importing pre defined Libraries/Components */
import React from 'react';
import Icon from 'react-fa';
import PropTypes from "prop-types";

/** Importing custom Components */

/** Importing custom Styles */
import classes from './BtnBack.module.css';

/** Main Components */
const BtnBack = (props) => {
    return (
        <a href={ window.location.origin + "/index.php?r=" + props.url } className={ [ classes.BtnBack, props.className ].join(' ') }>
            <Icon name="chevron-left" /> Back
        </a>
    );
}

/** Defining prop types requirements */
BtnBack.propTypes = {
    url: PropTypes.string.isRequired,
};

/** Exporting main component */
export default BtnBack;