/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Icon } from "react-fa";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
/** Importing alert modal  */
import { confirmAlert } from 'react-confirm-alert';


/** Importing custom Components */
import RuleType from "../NodeRules/RulesTable/RuleType/RuleType";
import { removeResponse, setResponseAction, showResponseModal, changeRuleResponse, setSelectedRule, setBulkEdit } from "../../../store/actions/rules/rulesActions";
import { getDetailedViewLoaderPathStr , findAllOccurrence, listResponseOccurrence } from "../../../utils/rulesUtils";
import BotResponsesModal from "../BotResponsesModal/BotResponsesModal";
import {findBlockIdFromBotResponses} from '../../../utils/callFlowUtils';

/** Importing custom Styles */
import classes from './RulesResponses.module.css';
import PlayIcon from '../../UI/PlayIcon/PlayIcon';
import Tags from "../../UI/Tags/Tags";
import LoadingIcon from "../../UI/LoadingIcon/LoadingIcon";
import difference from 'lodash/difference';
import { setAlert } from '../../../store/actions/common/commonActions';
import { messages } from '../../../data/strings';
import { params } from '../../../data/params';

/** Main Components */
const RulesResponses = ({ propsRedux, propsOwned, removeResponse, setResponseAction, showResponseModal, changeRuleResponse , setBulkEdit }) => {

    var responsesArr = propsOwned.responsesArr;
    var toDeleteIndex = null
    var tagKeyBase = "" + propsOwned.dataRulekey + "_" + propsOwned.evalKey + "_" + propsOwned.responseKey + "_" + propsOwned.iterationKey;
    const [deleteResponseId, setDeleteResponseId] = useState(0);

    
    const _onRemoveClick = (responseId = -1, index = -1, pathObj = {}) => {
        console.log("DEBUGGER:: onremlcik pathObj", pathObj);
        if (responsesArr.length > 1) {
            var refPathObj = {
                referenceId: responseId,
                index: index,
                nodeKey: propsRedux.selectedNode.node,
                isMetadata: propsRedux.selectedNode.isMetadata,
                ruleKey: propsOwned.dataRulekey,
                evalKey: propsOwned.evalKey,
                responseKey: propsOwned.responseKey,
                iterationKey: propsOwned.iterationKey,
            };
            global.recordAudit("Delete response clicked for Response Id" + responseId + ", Index :" + index + JSON.stringify(refPathObj))
            setResponseAction({
                mode: global.CUST_CONSTANTS.CRUD_MODE.DELETE,
                isSimpleView: false,
                selectedId: -1,
                referencePaths: [{ ...refPathObj }]
            });
            setDeleteResponseId(responseId);
            let buttons = [
                {
                  label: 'Show Occurences',
                  onClick: () => { 
                      bulkDelete(responseId, propsRedux, propsOwned, toDeleteIndex, setBulkEdit);
                  },
                },
                {
                  label: 'Delete This',
                  onClick: () => { removeResponse(propsOwned.dataRulekey, propsOwned.evalKey, propsOwned.responseKey, propsOwned.iterationKey, toDeleteIndex); toDeleteIndex = null }
                },
                {
                  label: 'Cancel',
                  onClick: () => {}
                },
              ];
            if (pathObj.isMetadata) {
                buttons = [
                    {
                      label: 'Confirm',
                      onClick: () => { removeResponse(propsOwned.dataRulekey, propsOwned.evalKey, propsOwned.responseKey, propsOwned.iterationKey, toDeleteIndex); toDeleteIndex = null }
                    },
                    {
                      label: 'Cancel',
                      onClick: () => {}
                    },
                  ]
            }
            confirmAlert({
                title: 'Confirm',
                message: 'Are you sure you want to delete the response?',
                buttons: [...buttons],
                childrenElement: () => <div />,
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => {},
                afterClose: () => {},
                onClickOutside: () => {},
                onKeypressEscape: () => {}
              });
        }
    }

    const _onEditClick = (responseId, index=0) => {
        var refPathObj = {
            referenceId: responseId,
            index: index,
            nodeKey: propsRedux.selectedNode.node,
            isMetadata: propsRedux.selectedNode.isMetadata,
            ruleKey: propsOwned.dataRulekey,
            evalKey: propsOwned.evalKey,
            responseKey: propsOwned.responseKey,
            iterationKey: propsOwned.iterationKey,
        };
        global.recordAudit("Edit response clicked for Response Id" + responseId + ", Index :" + index + JSON.stringify(refPathObj))
        setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.EDIT,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{ ...refPathObj }]
        });
        setSelectedRule(propsRedux.selectedNode.node, null, false, null, null, [], global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK, null, true);
        showResponseModal(true);
    }

    const _onAddClick = () => {
        var refPathObj = {
            referenceId: null,
            index: -1,
            nodeKey: propsRedux.selectedNode.node,
            isMetadata: propsRedux.selectedNode.isMetadata,
            ruleKey: propsOwned.dataRulekey,
            evalKey: propsOwned.evalKey,
            responseKey: propsOwned.responseKey,
            iterationKey: propsOwned.iterationKey,
        };
        global.recordAudit("Add response clicked for " + JSON.stringify(refPathObj))
        setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{ ...refPathObj }]
        });
        showResponseModal(true);
    }

    const onResponseRuleTypeChange = (event, pathObj) => {
        var value = event.target.value;
        var newResponseJson = { ...propsRedux.selectedNode.responseJson };
        _onResponseRuleTypeChange(newResponseJson, pathObj, value, propsRedux, changeRuleResponse);
    }


    /** Functions for Bulk operations start */

    const bulkDelete = (deleteResponseId, propsRedux, propsOwned, toDeleteIndex, setBulkEdit) => {
        if (deleteResponseId) {
            let responseid = deleteResponseId;
            let needle = (propsRedux.botResponses[deleteResponseId] && propsRedux.botResponses[deleteResponseId]['utterance_text']) ? propsRedux.botResponses[deleteResponseId]['utterance_text'] : '';

            global.recordAudit("Delete All response clicked " + JSON.stringify(propsOwned) + "rule Key " + propsOwned.dataRulekey + " eval key " + propsOwned.evalKey + " response Key " + propsOwned.responseKey + " iteration key " + propsOwned.iterationKey + " To Delete Index " + toDeleteIndex);

            let [responseId, to, respJson, ruleJson] = listResponseOccurrence(needle, responseid, global.CUST_CONSTANTS.CRUD_MODE.DELETE, propsRedux.rulesJson);

            var [_, flatResponse] = findAllOccurrence(propsRedux.rulesJson["ruleResponses"], needle, global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE, false, "DELETE");
            let validResp = [];
            let validAddon = [];
            let invalidGlobal = [];
            let validGlobal = [];

            _decomposeResponse(flatResponse, propsRedux, validAddon, validResp);


            _decomposeRule(ruleJson, validGlobal, invalidGlobal);

            validResp = difference(validResp, intersection(invalidGlobal, validResp));

            let removeableResponse = { "global": [...new Set(validGlobal)], "addon": [...new Set(validAddon)], "response": [...new Set(validResp)] };

            setBulkEdit({
                state: true,
                action: global.CUST_CONSTANTS.CRUD_MODE.DELETE,
                type: global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE,
                from: responseId,
                to: null,
                responseJson: typeof respJson != "undefined" ? respJson[0].response : {},
                keyMapper: typeof respJson != "undefined" ? respJson[0].keyMapper : {},
                selectedNodeBulk: [],
                ruleResponse: typeof ruleJson != "undefined" ? ruleJson : {},
                applyChanges: false,
                removeableResponse: removeableResponse,
            });
        } else {
            console.log("DEBUGGER::  messages.switchInvalid", messages.switchInvalid);
            setAlert(messages.switchInvalid, 0);
        }
    }

    const _decomposeRule = (ruleJson, validGlobal, invalidGlobal) => {
        Object.keys(ruleJson).forEach(elem => {
            let rhs = ruleJson[elem]["response"]["rhs"];
            let separator = params.callFlowSeparator;
            let intent = ruleJson[elem]["path"].split(separator) ? ruleJson[elem]["path"].split(separator)[1] : '';

            let isArray = Array.isArray(rhs) ? true : false;
            console.log("is string ", isArray, " len ", rhs.length);
            if (isArray) {
                let len = rhs.length;
                if (len > 1) {
                    validGlobal.push(elem);
                } else {
                    invalidGlobal.push(intent);
                }
            } else {
                invalidGlobal.push(intent);
            }
        });
    }

    const _decomposeResponse = (flatResponse, propsRedux, validAddon, validResp) => {
        Object.keys(flatResponse).forEach(flatResp => {
            let separator = params.callFlowSeparator;
            let resp = flatResp.split(separator);
            let index = resp[6];
            let type = resp[2];
            if (type !== "generic") {
                index = resp[5];
            }
            if (parseInt(index) === 0) {
                let len = [];
                if (type !== "generic") {
                    len = propsRedux.rulesJson["ruleResponses"][resp[1]][resp[2]][resp[3]][resp[4]];
                } else {
                    len = propsRedux.rulesJson["ruleResponses"][resp[1]][resp[2]][resp[3]][resp[4]][resp[5]];
                }
                if (len.length > 1) {
                    if (type !== 'generic') {
                        validAddon.push(resp[1]);
                    } else {
                        validResp.push(resp[1]);
                    }
                }
            } else if (parseInt(index) > 0) {
                if (type !== 'generic') {
                    validAddon.push(resp[1]);
                } else {
                    validResp.push(resp[1]);
                }

            }

        });
    }

    const _onResponseRuleTypeChange = (newResponseJson, pathObj, value, propsRedux, changeRuleResponse) => {
        var referencePointer = newResponseJson;
        if (pathObj.ruleKey) {
            referencePointer = referencePointer[pathObj.ruleKey];
        }
        if (pathObj.evalKey) {
            referencePointer = referencePointer[pathObj.evalKey];
        }
        if (pathObj.responseKey) {
            referencePointer = referencePointer[pathObj.responseKey];
        }
        if (pathObj.iterationKey) {
            referencePointer = referencePointer[pathObj.iterationKey];
        }
        if (pathObj.index > -1) {
            referencePointer = referencePointer[pathObj.index];
        }
        if (value === propsRedux.selectedNode.type) {
            if (referencePointer.type) {
                delete referencePointer.type;
            }
        } else {
            referencePointer.type = value;
        }
        changeRuleResponse({ ...newResponseJson }, global.CUST_CONSTANTS.ACTION_LEVEL.STATEMENT_TYPE);
    }
    /** Functions for Bulk operations end */

    return (
        responsesArr && 
        <Table striped bordered hover className={ propsOwned.className }>
            <tbody>
                {
                    Array.isArray(responsesArr) && responsesArr.map(( responseJson, index ) => {
                        var isCorrupted = false;
                        var clip = "";
                        var text = "";
                        var tags = {};
                        var audioClipNotPresent = false
                        var id = responseJson["id"];
                        if ( ( 'BRText' in responseJson ) && ( 'BRClip' in responseJson ) ) {
                            text = responseJson['BRText'];
                            clip = responseJson['BRClip'];
                        } else if ( ( 'defaultText' in responseJson ) && ( 'defaultClip' in responseJson ) ) {
                            text = responseJson['defaultText'];
                            clip = responseJson['defaultClip'];
                        }

                        if ((id === 0) || (id === null) || (id === "") || (text === null) || (text === "")) {
                            isCorrupted = true;
                            id = 0;
                            text = global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT;
                            clip = global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT;
                        }else if((clip === null) || (clip === "")){
                            audioClipNotPresent = true;
                            clip = global.CUST_CONSTANTS.NO_AUDIO_CLIPS;
                        }

                        var uid = propsOwned.dataRulekey+ '_' + 
                        (propsOwned.evalKey? propsOwned.evalKey: 'null') + '_' 
                        + propsOwned.responseKey+ '_' +
                        (propsOwned.iterationKey ? propsOwned.iterationKey : 'null') + '_' +
                        index;
                        let newId = id
                        if(typeof(newId) === 'number'){
                            newId = String(newId);
                            let splittedBlockId = newId.split('_');
                            if(splittedBlockId.length <=1){
                                newId = findBlockIdFromBotResponses({botResponses: propsRedux.botResponses, oldBlockId: id,blockObj: responseJson }); 
                            }
                        }
                        if (propsRedux.botResponses && propsRedux.botResponses[newId]){
                            if (propsRedux.botResponses[newId].TagDetails) {
                                tags = { ...propsRedux.botResponses[newId].TagDetails };
                            }
                        }

                        var pathObj = {};
                        if (propsOwned.dataRulekey) {
                            pathObj.ruleKey = propsOwned.dataRulekey;
                        }
                        if (propsOwned.evalKey) {
                            pathObj.evalKey = propsOwned.evalKey;
                        }
                        if (propsOwned.responseKey) {
                            pathObj.responseKey = propsOwned.responseKey;
                        }
                        if (propsOwned.iterationKey) {
                            pathObj.iterationKey = propsOwned.iterationKey;
                        }
                        pathObj.index = index;
                        if (propsRedux.selectedNode && propsRedux.selectedNode.node) {
                            pathObj.nodeKey = propsRedux.selectedNode.node;
                            pathObj.isMetadata = propsRedux.selectedNode.isMetadata;
                        }
                        console.log("FInally makign path object ",pathObj);
                        var pathKey = getDetailedViewLoaderPathStr(pathObj);
                        var innerHTML = "";
                        if (propsRedux.loaders.detailedView[pathKey]) {
                            innerHTML = (
                                <LoadingIcon />
                            )
                        } else {
                            innerHTML = (
                                    <>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center w-100 pr-2">
                                                {
                                                    !isCorrupted && !audioClipNotPresent &&
                                                    <PlayIcon
                                                        uid={ uid } src={ clip }
                                                    />
                                                }
                                                <label className={ [ classes.ResponseText , 'p-0 mb-0 ml-2 font-weight-bold responsetxt', (isCorrupted) ? "text-danger" : "" ].join(' ') }> { text } </label>

                                            </div>
                                            {
                                            (!propsRedux.bulkEdit.state)  &&
                                            <div className="d-flex">
                                                <Icon name="edit" className={ [ 'cursor-pointer', 'action-btn', 'd-flex', 'align-items-center', 'justify-content-center' ].join(' ') } onClick={ () => _onEditClick(id, index) } ></Icon>
                                                
                                                <Icon 
                                                    name="trash" 
                                                    className={ [ 
                                                        'ml-1',
                                                        'cursor-pointer',
                                                        'action-btn',
                                                        'd-flex',
                                                        'align-items-center',
                                                        'justify-content-center',
                                                        (responsesArr.length <= 1) ? 'disabled' : "" 
                                                    ].join(' ') }onClick={ () => { _onRemoveClick(id, index, pathObj) } }
                                                ></Icon>
                                            </div>
                        }
                                        </div>
                                        
                                            <div className={ [ classes.RuleResponsesTags ].join(' ') }>
                                        {
                                            !isCorrupted &&
                                            !propsRedux.selectedNode.isMetadata && 
                                                <RuleType
                                                    onChange={ (e) => onResponseRuleTypeChange(e, pathObj) }  
                                                    type={ (responseJson.type) ? parseInt(responseJson.type) : parseInt(propsRedux.selectedNode.type) }
                                                    readOnly={ false }
                                                    isResponseRuleType={ true }
                                                    dataNode= { propsOwned.dataNode}
                                                />
                                        }
                                        <Tags keyBase={ tagKeyBase } tags={ tags } />
                                            </div>
                                    </>
                            );
                        }

                        return (
                            <tr key={ 'response_' + id + "_" + index } data-responseid={ id } >
                                <td>
                                    { innerHTML }
                                </td>
                            </tr>
                        );
                    })
                }
                {
                    propsOwned.isAddResponseVisible &&
                    !propsRedux.bulkEdit.state &&
                    (
                        (
                            // change this condition to an array based dynamic so that can just add values to allow at several places in future
                            (propsOwned.dataRulekey === "generic") &&
                            (propsOwned.evalKey === "evalTrue")
                        ) ||
                        (propsOwned.responsesArr.length === 0)
                    ) &&
                    <tr>
                        <td>
                            <button onClick={ () => _onAddClick() } className={ [ "btn", "btn-primary", "btn-sm" , "assign-button", "add-node-button" ].join(' ') } >
                                <Icon name="plus-square" className={ [ "icon-btn" ].join(' ') } ></Icon>
                                Add Response
                            </button>
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    );
}

/** Defining prop types requirements */
RulesResponses.propTypes = {
    propsRedux: PropTypes.shape({
        botResponses: PropTypes.object.isRequired
    }),
    propsOwned: PropTypes.shape({
        className: PropTypes.string,
        responsesArr: PropTypes.array.isRequired,
        showResponseModal: PropTypes.func.isRequired,
        // dataRulekey: PropTypes.string.isRequired,
        evalKey: PropTypes.string,
        responseKey: PropTypes.string,
        iterationKey: PropTypes.string,
        isAddResponseVisible: PropTypes.bool,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            botResponses: state.rules.botResponses,
            loaders: state.rules.loaders,
            selectedNode: state.rules.selectedNode,
            bulkEdit: state.rules.bulkEdit,
            rulesJson: state.rules.rulesJson,
        },
        propsOwned: {...props}
    }
}

export default connect(mapStateToProps, { removeResponse, setResponseAction, showResponseModal, changeRuleResponse, setSelectedRule, setBulkEdit, setAlert })(RulesResponses);
