/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import SearchableSelect from "react-select";
import Icon from 'react-fa';

/** Importing custom Components */
import { setSimpleRows } from "../../../../store/actions/rules/rulesActions";

/** Importing custom Styles */
import classes from './SelectTagsColumnFilter.module.css';

/** Main Components */
class SelectTagsColumnFilter extends React.Component {
    /**
     * Instead of destructuring getting props directly
     * @param {*} props {
     *      column: { filterValue, setFilter, preFilteredRows, id },
     *  }
     */
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        var tagsForFilter = SelectTagsColumnFilter.getTagsForFilter(props);
        
        this.state = {
            isChanged: props.propsRedux.isChanged,
            options: tagsForFilter,
            filterValue: null,
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if ( ( nextProps.propsRedux.isChanged == 1 ) ) {
            var options = SelectTagsColumnFilter.getTagsForFilter(nextProps);
            return { 
                options:  options,
                filterValue: null,
            };
        }
        return null;
    }
    /** Lifecycle methods end */

    static getTagsForFilter = (propsPassed) => {
        var tagsForFilter = [];
        var uniqueKeysObj = {};
        uniqueKeysObj['clipKeys'] = {};
        propsPassed.propsOwned.column.preFilteredRows.forEach(row => {
            if (row.original.responseTagsObj) {
                Object.keys(row.original.responseTagsObj).forEach( tagCollection => {
                    row.original.responseTagsObj[tagCollection].forEach( tag => {
                        if (!uniqueKeysObj[tagCollection]) {
                            uniqueKeysObj[tagCollection] = {};
                        }
                        uniqueKeysObj[tagCollection][tag] = true;
                    })
                })
            }
            if (row.original.responseClip) {
                var labelVal = row.original.responseClip;
                if (uniqueKeysObj['clipKeys'][labelVal]) {
                    return false;
                }
                uniqueKeysObj['clipKeys'][labelVal] = true;
            }
        });

        var { clipKeys, ...tagsKeys } = uniqueKeysObj;

        Object.keys(tagsKeys).forEach(tagCollection => {
            var tags = [];
            Object.keys(uniqueKeysObj[tagCollection]).forEach(item => {
                tags.push({
                    label: item,
                    value: tagCollection + ":" + item,
                    valueObj: {
                        tagCollection: tagCollection,
                        tag: item
                    },
                });
            });
            tagsForFilter.push({
                label: tagCollection,
                options: [ ...tags ]
            });
        });

        var clips = [];
        Object.keys(clipKeys).forEach(item => {
            clips.push({
                label: item,
                value: "Clip:" + item,
                valueObj: {
                    tagCollection: "Clip",
                    tag: item
                },
            });
        });
        tagsForFilter.push({
            label: "Clip",
            options: [ ...clips ]
        });
        return tagsForFilter;
    }

    onFilterSelect = (filterChosen = null) => {
        if ( filterChosen && (filterChosen.length > 0)) {
            var stringToFilter = filterChosen.map (filterTemp => {
                return ((filterTemp.value) ? filterTemp.value : undefined);
            });


            this.props.propsOwned.column.setFilter( stringToFilter );
            this.setState({ filterValue: filterChosen });
        } else {
            this.props.propsOwned.column.setFilter(undefined); 
            this.setState({ filterValue: null });
        }
        this.props.setSimpleRows(global.CUST_CONSTANTS.READY_STATE);
    }

    formatOptionLabel = ({ valueObj, label }) => (
        <div className={ [ (valueObj && (valueObj.tagCollection == "Clip")) ? classes.ClipOption : "" ].join(' ') }>
            {
                (valueObj && (valueObj.tagCollection != "Clip")) &&
                <Icon name="tint"
                    className={ [ classes.SwitchTagOptionColor ].join(' ') } 
                    style={ (
                        valueObj &&
                        valueObj.tagCollection &&
                        (valueObj.tagCollection != "Clip") &&
                        this.props.propsRedux.brandTags &&
                        this.props.propsRedux.brandTags[valueObj.tagCollection] &&
                        this.props.propsRedux.brandTags[valueObj.tagCollection][valueObj.tag] &&
                        this.props.propsRedux.brandTags[valueObj.tagCollection][valueObj.tag].bg_color
                    ) ? { color: this.props.propsRedux.brandTags[valueObj.tagCollection][valueObj.tag].bg_color } : { color: "transparent" } }
                />
            }
            { label }
        </div>
    );

    render() {
        return (
            <SearchableSelect 
                value={ this.state.filterValue }
                isMulti={ true }
                isDisabled={ this.props.propsRedux.freezeColumnFilter }
                name="table_fiter" 
                hideSelectedOptions={false} 
                options={ this.state.options } 
                onChange={ e => this.onFilterSelect(e) } 
                formatOptionLabel={ this.formatOptionLabel }
            />
        );
    }
}

/** Defining prop types requirements */
SelectTagsColumnFilter.propTypes = {
    propsRedux: PropTypes.shape({
        freezeColumnFilter: PropTypes.bool.isRequired,
        isChanged: PropTypes.number.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            freezeColumnFilter: state.rules.freezeColumnFilter,
            isChanged: state.rules.isChanged,
            brandTags: state.rules.brandTags,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setSimpleRows })(SelectTagsColumnFilter);