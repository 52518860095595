/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

/** Importing custom Components */
import JSONEditorReact from "../JSONEditorReact/JSONEditorReact";
import { setAlert } from "../../../store/actions/common/commonActions";
import { setSelectedRule } from "../../../store/actions/rules/rulesActions";

/** Main Components */
class RulesJsonEditor extends React.Component {
    constructor (props) {
        super(props);
    }

    getParentRuleKey = (node) => {
        var reservedKeys = {
            "rule": true,
            "lhs": true,
            "op": true,
            "LO": true,
            "defaultResponse": true,
            "faliureResponse": true,
            "successResponse": true,
            "evalFalse": true,
            "evalTrue": true,
            "random": true,
            "random_once": true,
            "sequence": true,
            "id": true,
            "BRText": true,
            "BRClip": true,
            "defaultText": true,
            "defaultClip": true,
            "responseUsed": true,
            "type": true,
            "priority": true,
        };

        var indexToCheck = node.path.length - 1;
        var ruleKey = null;

        do {
            if ( !isNaN(node.path[indexToCheck]) || (reservedKeys[node.path[indexToCheck]])) {
                indexToCheck--;
            } else {
                ruleKey = node.path[indexToCheck];
            }
        } while ((ruleKey === null) && (indexToCheck>1));
        return ruleKey;
    }

    onEvent = (node, event) => {
        if (event.type === 'click') {
            global.recordAudit("Node Clicked for : " + JSON.stringify(node));
            if (node.path && Array.isArray(node.path) && ( node.path[0] == "rule" || node.path[0] == "ruleResponses") ) {
                var intentSelected = null;
                var selectedRule = null;
                var scenario = null;
                var setTagRuleVar = false;
                /** Condition added for scenario Tags */

                if (((node.path[1] == "tagRule") && (node.path[2] == "scenarioRule")) || (node.path[1] == "tagRule")) {
                    if (node.path[3] && node.path[4]) {
                        scenario = node.path[3];
                        intentSelected = node.path[4];
                        if (intentSelected === 'priority') {
                            intentSelected = null;
                            setTagRuleVar = false;
                            setAlert("Click on any node within the scenario", 2);
                        } else {
                            selectedRule = (this.props.propsRedux.rulesJson.ruleResponses.tagRule.scenarioRule[scenario]) ? { ...this.props.propsRedux.rulesJson.ruleResponses.tagRule.scenarioRule[scenario][intentSelected] } : null;
                            setTagRuleVar = true;
                        }
                    } else {
                        intentSelected = null;
                        setTagRuleVar = false;
                        this.props.setAlert("Click on any node within the scenario", 2);
                    }
                } else {
                    intentSelected = node.path[1];
                    if(this.props.propsRedux.rulesJson.ruleResponses[intentSelected]) {
                        if(node.path.length > 2) {
                            let ruleField = this.getParentRuleKey(node);
                            let ruleObject = {}
                            if (this.props.propsRedux.rulesJson.ruleResponses && this.props.propsRedux.rulesJson.ruleResponses[intentSelected] && this.props.propsRedux.rulesJson.ruleResponses[intentSelected][ruleField]) {
                                ruleObject[ruleField] = this.props.propsRedux.rulesJson.ruleResponses[intentSelected][ruleField];
                            } else {
                                ruleObject[ruleField] =  {
                                    [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                                        [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                                    }};
                            }
                            selectedRule = ruleObject;
                        } else {
                            selectedRule = this.props.propsRedux.rulesJson.ruleResponses[intentSelected];
                        }
                    }
                    else {
                        selectedRule = null;
                    }
                }
                if (setTagRuleVar) {
                    this.props.setSelectedRule(intentSelected, selectedRule, false, scenario, null, [], global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK);
                } else {
                    this.props.setSelectedRule(intentSelected, selectedRule, false, null, null, [], global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK);
                }
            } else if (node.path && Array.isArray(node.path) && ( node.path[0] == "metadata") && (node.path[1]) ) {
                intentSelected = node.path[1];
                selectedRule = (this.props.propsRedux.rulesJson && this.props.propsRedux.rulesJson.metadata && this.props.propsRedux.rulesJson.metadata[intentSelected]) ? { ...this.props.propsRedux.rulesJson.metadata[intentSelected] } : null;
                this.props.setSelectedRule(intentSelected, selectedRule, true, null, null, [], global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK);
            }
        }
    }

    render() {
        const schema = {
            title: 'Rules Schema',
            type: 'object',
            properties: {
                rule: {
                    type: 'object'
                },
                ruleResponses: {
                    type: 'object'
                },
            },
            required: ['rule', 'ruleResponses']
        };
        
        const mode = 'view';
    
        return (
            <JSONEditorReact
                schema={ schema }
                text={ this.props.propsRedux.stringifiedJson }
                mode={ mode }
                indentation={ 4 }
                onEvent = { this.onEvent }
            />
        );
    }
}

/** Defining prop types requirements */
RulesJsonEditor.propTypes = {
    propsRedux: PropTypes.shape({
        // rulesJson: PropTypes.object.isRequired,
        stringifiedJson: PropTypes.string.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
            stringifiedJson: state.rules.stringifiedJson,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setSelectedRule, setAlert })(RulesJsonEditor);