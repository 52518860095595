import React, { useState, Fragment } from 'react';
import { connect } from "react-redux";

import ReactPlayer from "react-player";

import { handlePlayPause } from '../../../store/actions/rules/rulesActions';
import { setAlert } from '../../../store/actions/common/commonActions';


const ReactAudioPlayer = ({ propsRedux, propsOwned, handlePlayPause, setAlert }) => {

    /** Function to handle error in audio or audio ended */
    const _handlePlayerEvents = (event, error=null) => {
        var newPlayerState = null;

        switch (event) {
            case 'on-ready':
                /** Setting the state to playing current audio if ready and not already playing */
                if (propsRedux.playerState < 2)
                    newPlayerState = 2;
                    break;
            case 'buffering':
                /** Setting the state to playing current audio */
                newPlayerState = 1;
                break;
            // case 'play':
            //     /** Setting the state to playing current audio */
            //     newPlayerState = 2;
            //     break;
            // case 'pause':
            //     /** Setting the state to paused current audio */
            //     newPlayerState = 3;
            //     break;
            case 'ended':
                /** Reset state to initial */
                newPlayerState = 0;
                break;
            case 'error':
                console.error(' React player Error ', error);
                setAlert("Some error occured in the player!");
                /** Reset state to initial */
                newPlayerState = 0;
            default:
                break;
        }
        if (newPlayerState !== null) {
            handlePlayPause(newPlayerState);
        }
    }

    return (
        <ReactPlayer
            id="BotClipAudioPlayer"
            url={propsRedux.audiosource}
            playing={ (propsRedux.playerState === 2) }
            height="0px"
            width="0px"
            onBuffer={() => _handlePlayerEvents('buffering')}
            onReady={() => _handlePlayerEvents('on-ready')}
            onPause={() => _handlePlayerEvents('pause')}
            onStart={() => _handlePlayerEvents('start')}
            onPlay={() => _handlePlayerEvents('play')}
            onEnded={() => _handlePlayerEvents('ended')}
            onError={(error) => _handlePlayerEvents('error', error)}
        />
    );
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            audiosource: state.rules.audioclipSource,
            playerState: state.rules.playerState,
            playerId: state.rules.playerId
        },
        propsOwned: { ...props }
    }
}

export default connect(mapStateToProps, { handlePlayPause, setAlert })(ReactAudioPlayer);