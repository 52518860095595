export const base = "luis-apps"
export const aiBase = "/ai-services-apis/api/v1/luis-app"
export const aiBaseNew = "/ai-services-apis/api/v1"
export const newUIBase = "/apis/api/v2"


export const apiUrls = {
    CHECK_IN_SYNC: "info/in-sync/{{storyId}}",
    EXPORT_JSON: "export-app-json/{{luisAppId}}",
    MAKE_OLD_INACTIVE: "make-old-inactive/{{luisAppDbId}}?moduleSessionId={{moduleSessionId}}",
    EXPORT_UTTERANCES: "export-utterances/{{luisAppDbId}}/{{offset}}?moduleSessionId={{moduleSessionId}}",
    IMPORT_INTENTS: "import/intents/{{luisAppDbId}}/{{offset}}?moduleSessionId={{moduleSessionId}}",
    IMPORT_ENTITIES: "import/entities/{{luisAppDbId}}/{{offset}}?moduleSessionId={{moduleSessionId}}",
    IMPORT_UTTERANCES: "import/utterances/{{luisAppDbId}}/{{offset}}?moduleSessionId={{moduleSessionId}}",
    SYNC_PROCESS_END : "sync-progress/end/{{luisAppDbId}}",

   // apis for train and publish   

};
export const aiUrls = {
    
    GET_APP_VERSIONS_LIST:"get-luis-app-version/{{luisAppDbId}}/",
    EXPORT_APP_JSON: "export-json/{{luisAppDbId}}/{{aiVersion}}/",
    CREATE_NEW_JSON: "create-app-json/{{luisAppDbId}}/",
    IMPORT_APP_JSON: "import-json/{{luisAppDbId}}/",
    RENAME: "rename/{{luisAppDbId}}/",
    TRAIN: "train/{{luisAppDbId}}/",
    STATUS: "status/{{luisAppDbId}}/",
    PUBLISH: "publish/{{luisAppDbId}}/",
    DELETE_OLD_VERSION: "delete-old/{{luisAppDbId}}/{{versionId}}/",
    CHECK_INFO: "get-luis-app-info/{{luisAppDbId}}/",
    END_SESSION: "luis-app/end-training-session/{{luisAppDbId}}/{{storyId}}/",
    // END_SESSION: "reports/prediction-reports/dummy-api/1163/",
    GET_LUIS_APP: "get-luis-app-json/{{luisAppDbId}}/",
    SAVE_LUIS_APP: "save-luis-app-json/{{storyId}}/",

    GET_INTENTS:'get-luis-app-intents/{{luisAppDbId}}/{{pageNumber}}/',
    GET_ENTITIES: 'get-luis-app-entities/{{luisAppDbId}}/{{pageNumber}}/',
    GET_UTTERANCES:'get-luis-app-utterances/{{luisAppDbId}}/{{pageNumber}}/',
}

export const newUIUrls = {
    V1_TO_V2_UPGRADE: 'new-ui-rules/v1-upgrade/{{storyId}}',
    MACHINE_LEARN_ENTITY_CHECK: 'new-ui-rules/get-mle-check/{{luisAppId}}/{{storyId}}',
    PRECHECK_BEFORE_UPGRADE: 'new-ui-rules/pre-check-v1-upgrade/{{storyId}}'
}