import { SET_LOADING, SET_ERROR, COACHING_INIT, COACHING_SET_CURRENT_COACH, COACHING_SET } from "../../actions/types";


const initialState = {
    loading: false,
    error: null,
    scores: null,
    currentCoachId: null
};

const conversationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING :
            return {
                ...state,
                loading: true
            }
        case SET_ERROR :
            return {
                ...state,
                error: action.payload.error
            }
        case COACHING_SET : 
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                loading: false
            }
        case COACHING_INIT : 
            return {
                ...state,
                scores: action.payload.scores,
                loading: false
            }
        case COACHING_SET_CURRENT_COACH : 
            return {
                ...state,
                currentCoachId: action.payload.coachId,
                loading: false
            }
    
        default:
            return state;
    }
};

export default conversationReducer;