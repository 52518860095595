import Pusher from 'pusher-js';
import { params } from "../data/params";
import { toUnicode } from "punycode";



const pusherCall = async (successFunc,data) => {

    const apiUrl = params.pythonApiUrl;

    const pusherKeys = {
      PUSHER_KEY: data.pusher_key,
      PUSHER_CLUSTER: data.cluster_name
    }

    const channelData = {
      channel_name : data.channel_name,
      event_name: data.event_name
    }



    // const pusher = new Pusher({
    //   appId:pusherKeys.PUSHER_APP_ID,
    //   cluster: pusherKeys.PUSHER_CLUSTER,
    //   enabledTransports: ['ws'],
    //   forceTLS: true,
    //   authEndpoint: apiUrl+'rules/get-testplans/auth'
    // });
    const pusher = new Pusher(
      pusherKeys.PUSHER_KEY, {
        cluster: pusherKeys.PUSHER_CLUSTER,
        authEndpoint: apiUrl+'callflow/auth',
        // authEndpoint: 'https://uat-app.zenarate.com/apis/api/v1/callflow/auth',
        encrypted:true
      }
    );
    
    console.log(pusher);

    Pusher.log = (msg) => {
      console.log("Push",msg);
    };

    // pusher.connection.bind( 'error', function( err ) {
    //       global.recordAudit("Pusher Over Limit error code: ",err);
    // });

   
        successFunc(pusher,channelData,pusherKeys);

}

export default pusherCall;