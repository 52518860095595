/*
 * Component Description : 
 * Author: Ankit Aditya
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Icon } from '@iconify/react';  
import { Link } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { setShowTestPlans,fetchTestPlans, resetTestPlan, fetchTestReport,runTestPlans,setSelectedTestPlan, setShowUtteranceSideBar,syncTestPlansReports, setSavedTestPlanData, setFromSavedTPData } from '../../../../store/actions/rules/rulesActions';
import { setAlert, pusherErrorFunction } from '../../../../store/actions/common/commonActions';
import { connect } from "react-redux";
import { messages } from '../../../../data/strings';


/** Importing custom Components */

/** Importing custom Styles */

/** Main Components */
class TestPlanSideBar extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        this.state = {
            error:null,
            testPlanList:{},
            testPlanFetch:[],
            testReportFetch:[],
            reportStatus:{
                running:0,
                failed:0,
                passed:0,
              },
            openAccordion:{}
        }
        
    }

    onlyUnique = (value,index,self) =>{
        return self.indexOf(value) === index;
    }

    componentDidMount(){

        var listOfPlans = {}
        Object.keys(this.state.testPlanList).map( (key) => {
            listOfPlans[key] = false
        });
        this.setState({openAccordion:listOfPlans});

        this.webhooksFlow();

        if(this.props.propsRedux.error && this.props.propsRedux.error.old_event){
            // old events flow
            let countRunning = this.state.reportStatus.running;
            let oldEventCountPassed = this.state.reportStatus.passed;
            let oldEventCountFailed = this.state.reportStatus.failed;

            // global.recordAudit("Successfully data retrieve: ",data);
            var testPlanListCurrentState = this.state.testPlanList;
            Object.keys(this.props.propsRedux.error.old_event).map((key)=>{

                testPlanListCurrentState[key] = this.props.propsRedux.error.old_event[key];
                countRunning+=1;
                if(testPlanListCurrentState[key].action === 'testcaseGeneration'){
                    if(!this.state.testPlanFetch.includes(key)){
                        this.props.fetchTestPlans([key]);
                    }
                }
                this.setState({testPlanFetch:[...this.state.testPlanFetch,key]});
                if(testPlanListCurrentState[key].action === 'testcaseReport'){
                    countRunning-=1;
                    if(countRunning==0){
                        this.props.enableButton(false);
                    }
                    if(testPlanListCurrentState[key].status){
                        oldEventCountPassed+=1;
                        if(!this.state.testReportFetch.includes(key)){
                            this.props.syncTestPlansReports([key]);
                        }
                        this.setState({testReportFetch:[...this.state.testReportFetch,key]});
                        
                    }
                    if(!testPlanListCurrentState[key].status){
                        oldEventCountFailed+=1;
                        if(!this.state.testReportFetch.includes(key)){
                            this.props.syncTestPlansReports([key])
                        }
                        this.setState({testReportFetch:[...this.state.testReportFetch,key]});
                    }
                }
                
            });
            this.setState({reportStatus:{...this.state.reportStatus,running:countRunning,passed:oldEventCountPassed,failed:oldEventCountFailed},testPlanList:testPlanListCurrentState});
        }

        // previous reports flow
        if(Object.keys(this.props.propsRedux.testReports).length > 0){
            let oldReportsCountPassed = this.state.reportStatus.passed;
            let oldReportsCountFailed = this.state.reportStatus.failed;
            var testPlanListCurrentState = this.state.testPlanList
            this.props.propsOwned.changeStatus()
            Object.keys(this.props.propsRedux.testReports).forEach(key=>{
                testPlanListCurrentState[key] = {id: Number(key), action: "testcaseReport",done: true,"status": false }
                if(this.props.propsRedux.testReports[key].detail_json.status === 2){
                    oldReportsCountFailed+=1;
                    testPlanListCurrentState[key].status = false
                }
                else{
                    oldReportsCountPassed+=1
                    testPlanListCurrentState[key].status = true
                }
                this.setState({testReportFetch:[...this.state.testReportFetch,key]});
            })
            this.props.setSavedTestPlanData('add','onLoad',true)
            this.setState({reportStatus:{...this.state.reportStatus,running:0,passed:oldReportsCountPassed,failed:oldReportsCountFailed},testPlanList:testPlanListCurrentState});
        }
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.propsRedux.allTestPlans !==this.props.propsRedux.allTestPlans){
            if(this.props.propsRedux.savedTestPlanData.all && Object.keys(this.props.propsRedux.savedTestPlanData.all).length && this.props.propsRedux.allTestPlans){
                this.testPlanListFromReport(this.props.propsRedux.savedTestPlanData.all.testReports);
            }

            if(this.props.propsRedux.savedTestPlanData.basic && Object.keys(this.props.propsRedux.savedTestPlanData.basic).length && !this.props.propsRedux.allTestPlans){
                this.testPlanListFromReport(this.props.propsRedux.savedTestPlanData.basic.testReports);
            }
            
        }
    }

    componentWillUnmount(){
        if(this.props.content){
            this.props.content.unbind();
        }
    }

    webhooksFlow = () =>{
        var testPlanFetch = [];
        var testReportFetch = [];
        if(this.props.content){
            this.props.content.bind(this.props.event_name,(data)=>{
                var countRunning = this.state.reportStatus.running;
                // global.recordAudit("Successfully data retrieve: ",data);
                var testPlanListCurrentState = this.state.testPlanList;
                
                Object.keys(data).map((key)=>{

                    testPlanListCurrentState[key] = data[key];
                    countRunning+=1;
                    if(!testPlanFetch.includes(key)){
                        this.props.fetchTestPlans([key]);
                        testPlanFetch.push(key);
                        this.setState({testPlanFetch:[...testPlanFetch]});
                    }
                    
                });
                
                this.setState({reportStatus:{...this.state.reportStatus,running:countRunning},testPlanList:testPlanListCurrentState});
            });

            this.props.content.bind('testcaseReport',(data)=>{
                // global.recordAudit("Successfully data retrieve: ",data);
                console.log('testReport','data',data);
                var countPassed = this.state.reportStatus.passed;
                var countRunning = this.state.reportStatus.running;
                var countFailed = this.state.reportStatus.failed;
                var testPlanListCurrentState = this.state.testPlanList;
                Object.keys(data).map((key)=>{

                    testPlanListCurrentState[key] = data[key];
                    countRunning-=1;
                    if(countRunning==0){
                        this.props.enableButton(false);
                    }
                    if(!!data[key].status){
                        countPassed+=1;
                        if(!testReportFetch.includes(key)){
                            this.props.fetchTestReport([key]);
                            testReportFetch = [...testReportFetch,key]
                            this.setState({testReportFetch:[...testReportFetch]});
                        }
                        
                    }
                    if(!data[key].status){
                        countFailed+=1;
                        if(!testReportFetch.includes(key)){
                            this.props.fetchTestReport([key]);
                            testReportFetch = [...testReportFetch,key]
                            this.setState({testReportFetch:[...testReportFetch]});
                        }
                    }
                    

                });
                
                this.setState({reportStatus:{passed:countPassed,failed:countFailed,running:countRunning},testPlanList:testPlanListCurrentState});
            });

            this.props.content.bind('testcaseError',(error)=>{
                if(error.error_message){
                    global.recordAudit('error',error.error_message)
                    pusherErrorFunction("PUSHERERROR FROM BACKEND: "+error.error_message)
                    this.setState({error:"PUSHERERROR FROM BACKEND: "+error.error_message})
                    this.props.setAlert("Something went wrong");
                    this.props.stopLoading()
                }
                
            });
            
        }
    }

    testPlanListFromReport = (reports) =>{
            let oldReportsCountPassed = 0;
            let oldReportsCountFailed = 0;
            var testPlanListCurrentState = {}
            Object.keys(reports).forEach(key=>{
                testPlanListCurrentState[key] = {id: Number(key), action: "testcaseReport",done: true,"status": false }
                if(reports[key].detail_json.status === 2){
                    oldReportsCountFailed+=1;
                    testPlanListCurrentState[key].status = false
                }
                else{
                    oldReportsCountPassed+=1
                    testPlanListCurrentState[key].status = true
                }
                this.setState({testReportFetch:[...this.state.testReportFetch,key]});
            })
            this.setState({reportStatus:{...this.state.reportStatus,running:0,passed:oldReportsCountPassed,failed:oldReportsCountFailed},testPlanList:testPlanListCurrentState});
        
    }

    /** Lifecycle methods end */


    onToggleTestPlan = (key,testPlanSelected) => {

        // setTimeout(()=>{
        //     this.props.propsOwned.execScroll();
        // },500);
        this.props.execScroll();
        var editedList = {...this.state.openAccordion};
        if(key in editedList){
            editedList = {}
        }
        else{
            editedList = {[key]: true}
        }
        global.recordAudit("On Close Test Plans Side Bar for StoryId: ",this.props.storyId);
        this.setState({openAccordion:{...editedList}});

        if(testPlanSelected && editedList[key]){
            var selectTestPlan = {[key]:{}}
            testPlanSelected.detail_json.test_case_flow.map((value, index)=>{
                selectTestPlan[key][value.index.step_index] = value;
                });
            this.props.setSelectedTestPlan(selectTestPlan);
        }else{
            this.props.setSelectedTestPlan(null);
        }
    }
    render() {
        const{openAccordion}=this.state;
        return (
            <div className="p-3" style={{height:"calc(100vh - 250px)",overflow:"scroll"}}>
                    { this.props.propsOwned.showStatus && <div className="row justify-content-start align-items-center">
                        <div className="col-md-4 mb-2">
                            <div className="border p-1 rounded w-100">
                                <span data-tip = {messages.successTestCases} data-place = "top" data-class= "tooltip_cust"><Icon icon="carbon:checkmark-outline" className="text-success"/></span> 
                                <span className="blue"> : {this.state.reportStatus.passed}</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                        <div className="border p-1 rounded">
                                <span data-tip = {messages.failedTestCases} data-place = "top" data-class= "tooltip_cust"><Icon icon="carbon:warning-alt" className="text-danger"/></span> 
                                <span className="blue"> : {this.state.reportStatus.failed}</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                        <div className="border p-1 rounded">
                                <span data-tip = {messages.underProcessTestCases} data-place = "top" data-class= "tooltip_cust"><Icon icon="carbon:renew" className="text-danger"/></span> 
                                <span className="blue"> : {this.state.reportStatus.running}</span>
                            </div>
                        </div>
                    </div>}
                    
                    { 
                    this.state.testPlanList&&
                    Object.keys(this.state.testPlanList).map( (key) => {
                        // Test Report code 500 case 
                        let testCaseFail = this.props.propsRedux.testReports[key] && this.props.propsRedux.testReports[key].success !==undefined && this.props.propsRedux.testReports[key].success === false ? true : false;

                        let passFailToolTipProps = {"data-tip": "","data-place": "top","data-class": "tooltip_cust"} 
                        let happySadToolTipProps = {"data-tip": "","data-place": "top","data-class": "tooltip_cust"}
                        var design = {}
                        
                        if(testCaseFail){
                            passFailToolTipProps["data-tip"] = messages.testCaseFailedLuis
                        }
                        if(this.props.propsRedux.testReports[key] && this.props.propsRedux.testReports[key].detail_json && this.props.propsRedux.testReports[key].detail_json.is_happy){
                            design["icon"] = "carbon:face-satisfied";
                            design['className'] = "text-success";
                            happySadToolTipProps["data-tip"] = messages.happyPath
                        }
                        
                        if(this.props.propsRedux.testReports[key] && this.props.propsRedux.testReports[key].detail_json && !this.props.propsRedux.testReports[key].detail_json.is_happy){
                            design["icon"] = "carbon:face-dissatisfied";
                            design['className'] = "text-danger";
                            happySadToolTipProps["data-tip"] = messages.nonHappytPath
                        }
                        
                        if(!this.state.testPlanList[key].done){
                            design["overlay"] = <div className="TP-overlay text-center"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                        }else{
                            design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                        }
                        if(this.props.propsRedux.testReports[key]?this.props.propsRedux.testReports[key].detail_json.status==0:false){
                            // design["overlay"] = <div className="TP-overlay text-center"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                            design['passFail'] = {"icon":"carbon:checkmark-outline","className":"ml-2 text-success"}
                            passFailToolTipProps["data-tip"] = messages.testCaseSuccess;
                        }else if(this.props.propsRedux.testReports[key]?this.props.propsRedux.testReports[key].detail_json.status==2:false){
                            // design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                            // design["icon"] = "carbon:face-dissatisfied";
                            // design['className'] = "text-danger";
                            design['passFail'] = {"icon":"carbon:warning-alt","className":"ml-2 text-danger"};
                            // happySadToolTipProps["data-tip"] = messages.nonHappytPath;
                            passFailToolTipProps["data-tip"] = messages.testCaseFailed
                        }else if(this.props.propsRedux.testReports[key]?this.props.propsRedux.testReports[key].detail_json.status==1:false){
                            // design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                            design['passFail'] = {"icon":"carbon:checkmark-outline","className":"ml-2 text-success"}
                            passFailToolTipProps["data-tip"] = messages.testCaseSuccess
                        }else{
                            // design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                        }

                        return (
                            <div key={key} className={`row align-items-center mb-2 border p-2 rounded m-0 position-relative ${testCaseFail && 'border-danger'}`}>
                            <div className="col-md-3 pl-0"> 
                            <div className="bg-light text-center rounded p-1">
                            <Icon icon={design['icon']} className={design['className']} {...happySadToolTipProps}></Icon>
                            </div>
                            </div>
                            <div className="col-md-9 pl-0">
                                <Link className="blue" onClick={() => !testCaseFail && this.onToggleTestPlan(key,this.props.propsRedux.testReports[key])}>{this.props.propsRedux.testPlanJsons[key]?this.props.propsRedux.testPlanJsons[key].title:"Test Plan"} <span {...passFailToolTipProps}><Icon  icon={design["passFail"]?design["passFail"]["icon"]:""} className={design["passFail"]?design["passFail"]["className"]:"ml-2 text-warning"}></Icon></span></Link>
                            </div>
                            <div className="TestPlanMore" style={{top:'10px'}}>                            
                            {/* <Link onClick={() => !testCaseFail && this.onToggleTestPlan(key,)}><Icon icon="carbon:information"/></Link>
                            <DropdownButton
                                align="end"
                                title={(<span className="text-center pt-1" aria-label="More Menu"><Icon icon="carbon:overflow-menu-vertical" /></span>)}
                                aria-label="More Menu"
                                id="dropdown-menu-align-left" size="sm" variant="lights" className="p-0 d-inline-block ml-1 cardMenu font-12"
                                >
                                <Dropdown.Item eventKey="1" onClick={()=>this.onRunTestPlan(this.state.testPlanList,key)} disabled={true}>Run</Dropdown.Item>
                                <Dropdown.Item eventKey="2" disabled={true}>Add to Test Plan</Dropdown.Item>                                    
                            </DropdownButton> */}
                        </div>
                        {this.props.propsRedux.selectedTestPlanKey === key && this.props.propsRedux.testPlanJsons[key] && this.props.propsRedux.testReports[key] && <div className="openAccordion overflow-auto" style={{maxHeight:'50vh'}}>
                            {
                                this.props.propsRedux.testReports[key].detail_json.test_case_flow.map((value, index)=> {
                                    return (
                                        <div key={index} className="col-md-12 position-relative mb-2 mt-3">
                                        <div className="border p-2 rounded">
                                            <div className="pos-rel ml-50 mr-100">
                                                <div className={["sampleUtterance font-14 p-2 w-100 ml-0",value.code ===200?"":"failure"].join(' ')}>
                                                {value.response.sample_utterances[0].utteranceText}
                                                </div>
                                                <div className={["intent font-14 p-2 w-100 ml-0",value.code ===401?"failure":"" ,"text-truncate"].join(' ')}>{value.node}</div>
                                                <div className="user-icon rounded-circle text-center p-2">
                                                <Icon icon="carbon:user-avatar"></Icon>
                                                </div>
                                            </div>
                                            <div className="pos-rel mr-50 ml-100">
                                            <div className={["BotChat font-14 p-2 w-100",value.code ===423?"failure":""].join(' ')}>{value.response.BRText}</div>
                                            <div className="bot-icon rounded-circle text-center p-2">
                                            <Icon icon="carbon:bot"></Icon>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="CallFlowStepicon position-absolute" style={{right:'30px',top:"5px"}}>                            
                                            {/* <Link onClick={() => this.props.propsOwned.infoBar(this.props.propsRedux.setShowUtteranceSideBar,{utteranceText:(value.response.sample_utterances[0].utteranceText?(value.response.sample_utterances[0].utteranceText[0].utteranceText?value.response.sample_utterances[0].utteranceText[0].utteranceText:"No Utterance is Configured"):"No Utterance is Configured"),intent:(value.node),botResponse:((value.id!=0)?{BRText:value.response.BRText,BRClip:value.response.BRClip}:"Corrupted Response"),block:{step:value.index.stepIndex,index:value.nodeIndex}})}><Icon icon="carbon:information"></Icon></Link> */}
                                            {value.code !==200 &&<Icon icon="carbon:warning-alt" className="text-danger ml-2" data-tip={value.error_message} data-class="tooltip_cust"  data-place="top"></Icon>}
                                        </div>
                                    </div>
                                    )
                                })
                            }
                                    
                                </div>}
                                {
                                design["overlay"]
                                }
                        </div>)
                     })
                    }
            </div>
                    
        );
    }
}


/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            storyId: state.story.storyId,
            selectedTestPlanKey : state && state.rules && state.rules.selectedTestPlan && Object.keys(state.rules.selectedTestPlan).length ? Object.keys(state.rules.selectedTestPlan)[0]: null,
            testPlanChannel: state.rules.testPlanChannel,
            testPlanJsons: state.rules.testPlanJsons,
            testReports: state.rules.testReports,
            allTestPlans: state.rules.allTestPlans,
            savedTestPlanData: state.rules.savedTestPlanData,
            error: state.rules.error,

        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setShowTestPlans,fetchTestPlans, fetchTestReport,runTestPlans, setSelectedTestPlan, setShowUtteranceSideBar, setAlert, resetTestPlan, syncTestPlansReports,setSavedTestPlanData,setFromSavedTPData })(TestPlanSideBar);