/** Importing pre defined Libraries/Components */
import React, { useEffect } from 'react';
import { Popover, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { Icon } from 'react-fa';
import PropTypes from "prop-types";

/** Connection import for redux */
import { connect } from "react-redux";

/** Importing custom Components */
import { getTodoList, changeView } from "../../../../store/actions/common/todoListActions";
import Task from "./Task/Task";

/** Importing custom Styles */
import classes from './TodoList.module.css';

/** Main Components */
const TodoList = React.memo(({ propsRedux, propsOwned, getTodoList, changeView }) => {

    var assignmentTypes = {
        0: {'id': '', 'title': 'All'},
        1: {'id': '1', 'title': 'Practice '},
        2: {'id': '2', 'title': 'View '},
        3: {'id': '3', 'title': 'Collect '}
    };

    useEffect( () => {
        /** getting todolist for Current assignments: hasCompleted as 0 */
        getTodoList(0);
        /** getting todolist for Past assignments: hasCompleted as 1 */
        getTodoList(1);
    },[]);

    var switchViewElement = '';
    if (propsRedux.todoList.view === 1) {
        switchViewElement = <strong onClick={ () => changeView(0) } className={ [ classes.SwitchViewHeading ].join(' ') }>Current Assignments</strong>
    } else {
        switchViewElement = <strong onClick={ () => changeView(1) } className={ [ classes.SwitchViewHeading ].join(' ') }>Past Assignments</strong>
    }

    const listToShow = (propsRedux.todoList.view===1) ? propsRedux.todoList.list.past : propsRedux.todoList.list.current;

    const popover = (
        <Popover id="popover-basic" className={ [ classes.Popover ].join(' ') }>
            <Popover.Title as="h3">{ (propsRedux.todoList.view===1) ? 'Past' : 'Current' } Assignments</Popover.Title>
            <Popover.Content>
                <ul className={ [ classes.List ].join(' ') }>
                    {
                        // listToShow.map( (listObj, index) => (
                        //     <Task key={ 'todo_'+index }  details={ listObj } assignmentTypes={ assignmentTypes } />
                        // ))
                    }
                    
                </ul>
                <hr />
                
                { switchViewElement }
            </Popover.Content>
        </Popover>
    );

    return (
        <div className={ [ classes.TodoList, 'pull-right' ].join(' ') }>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <div className={ [ classes.TodoNumber ].join(' ') }>{ propsRedux.todoList.list.current.length }</div>
            </OverlayTrigger>
        </div>
    );
});

/** Defining prop types requirements */
TodoList.propTypes = {
    propsRedux: PropTypes.shape({
        todoList: PropTypes.object.isRequired
    }),
    getTodoList: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
};

/** Redux store management  */
const mapStateToProps = (state, propsOwned) => {
    return {
        propsRedux : {
            todoList: state.todolist,
        },
        propsOwned : {...propsOwned}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { getTodoList, changeView })(TodoList);