import axiosCall from "../../../utils/axiosCall";
import { setLoading, axiosErrorFunction, setError, setAlert } from "../common/commonActions";
import getApiUrl from "../../../api-urls/apiUrlsUtils";
import { newUIBase, newUIUrls } from "../../../api-urls/luis-app-apis";

import store from "../../store";
import { CLOSE_LOADER_OVERLAY, SET_IS_ERROR_MODAL_OPEN, SET_IS_UPGRADING } from "../types";
import { startLoaderOverlay } from "../common/storyActions";


export const promoteToGuide = (guideMode) => async dispatch => {
    setLoading();
    
    if (guideMode) {
        var state = store.getState(); 
        console.log('promoteToGuide state', state);
        var storyId = state.workbench.storyId;
        var videoId = state.workbench.videoId;

        var data = {
            "topicId" : state.workbench.topicId,
            "sectionId" : state.workbench.sectionId,
            "assignmentId" : state.workbench.assignmentId,
            "guideMode" : guideMode
        };

        var url = 'workbench/promote-to-guide/' + storyId + '/' + videoId;
        const axiosSuccessFunction = (response) => {
            /** redirecting to new link on success promotion */
            if (response.data && response.data.returnUrl) {
                window.location.href = response.data.returnUrl;
            }
        };
        
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction, data);
    }
};

export const precheckBeforeUpgrade = () => dispatch => {
    const state = store.getState();
    const storyId = state.story.storyId

    const url = getApiUrl(newUIBase,newUIUrls.PRECHECK_BEFORE_UPGRADE,{storyId:storyId})

    dispatch(startLoaderOverlay())
    const axiosSuccessFunction = (response) => {
        if(Object.keys(response.data.pre_check_failures).length > 0){
            const payload = {
                isOpen : true,
                errors : response.data.pre_check_failures
            }
            dispatch({
                    type: SET_IS_ERROR_MODAL_OPEN,
                    payload: payload
            })
            dispatch({
                type: CLOSE_LOADER_OVERLAY
            })
        }else{
            dispatch(upgradeStoryToV2Editor())
        }
    }

    const axiosErrorFunction = (error) => {
        dispatch({
            type: CLOSE_LOADER_OVERLAY
        })
        dispatch({
            type: SET_IS_UPGRADING,
            payload: false
        })
        dispatch(setAlert(error))
        dispatch(setError(error))
    }

    axiosCall(url,axiosSuccessFunction, axiosErrorFunction,{},'POST')
}

export const upgradeStoryToV2Editor = () => dispatch => {
    const state = store.getState();
    const storyId = state.story.storyId

    const url = getApiUrl(newUIBase,newUIUrls.V1_TO_V2_UPGRADE,{storyId:storyId})

    dispatch({
        type: SET_IS_UPGRADING,
        payload: true
    })

    const axiosSuccessFunction = (response) => {
        console.log("story has been successfully upgraded")
        dispatch({
            type: CLOSE_LOADER_OVERLAY
        })
        window.location.replace(`${window.location.origin}/new-ui/#/callflow/${response.data.unguided_story_id}`)
        dispatch({
            type: SET_IS_UPGRADING,
            payload: false
        })
    }

    const axiosErrorFunction = (error) => {
        dispatch({
            type: SET_IS_UPGRADING,
            payload: false
        })
        dispatch({
            type: CLOSE_LOADER_OVERLAY
        })
        console.log(error)
        dispatch(setAlert(error))
        dispatch(setError(error))
    }

    axiosCall(url,axiosSuccessFunction, axiosErrorFunction,{},'POST')
}
