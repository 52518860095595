import React from 'react';
import { connect } from "react-redux";

import { Icon } from "react-fa";
import { handlePlayPause } from "../../../store/actions/rules/rulesActions";

/** Importing custom Styles */
import classes from './PlayIcon.module.css';


const PlayIcon = ({ propsRedux, propsOwned, handlePlayPause }) => {
    var isCurrentPlayer = (propsRedux.playerId === propsOwned.uid)

    const onClickListener = () => {
        var src = propsOwned.src;
        var newPlayerState = 1;
        var changeState = true;
        if(isCurrentPlayer || propsRedux.playerState === 0) {
            switch (propsRedux.playerState) {
                /** If ended/initial state : player state to be initialised and source to be loaded */
                case 0:
                    src = propsOwned.src;
                    newPlayerState = 1;
                    break;
                /** If buffering : Do not change state at all */
                case 1:
                    src = null;
                    newPlayerState = 1;
                    changeState = false;
                    break;
                /** If playing : Do not change the source and simply pause */
                case 2:
                    src = null;
                    newPlayerState = 3;
                    changeState = true;
                    break;
                /** If paused : Do not change the source and simply play */
                case 3:
                    src = null;
                    newPlayerState = 2;
                    changeState = true;
                    break;
                
                default:
                    break;
            }
        } else{
            changeState = true;
            newPlayerState = 1;
        }
        if (changeState) {
            handlePlayPause(newPlayerState, propsOwned.uid, propsOwned.src);
        }
    }
//1 //2
    return (
        <Icon name={ (isCurrentPlayer && propsRedux.playerState === 1) ? 'spinner' : (isCurrentPlayer && propsRedux.playerState === 2) ? 'pause' : 'play'} onClick={ () =>  onClickListener() } className={[ classes.PlayIcon, 'm-0 d-flex justify-content-center align-items-center icon-play-custom', (isCurrentPlayer && propsRedux.playerState === 1) ? 'fa-spin' : "" ].join(' ')} />
    );
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            playerState: state.rules.playerState,
            playerId: state.rules.playerId
        },
        propsOwned: { ...props }
    }
}

export default connect(mapStateToProps, { handlePlayPause })(PlayIcon);