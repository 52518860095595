/** Importing pre defined Libraries/Components */
import React from 'react';
import Select from "react-select";

/** Importing custom Components */

/** Importing custom Styles */
import classes from './SearchableSelect.module.css';

/** Main Components */
const SearchableSelect = props => {
    return (
        <Select {...props} />
    );
}

/** Exporting main component */
export default SearchableSelect;