import noneIcon from "../assets/images/transprent-image.png";
import botIcon from "../assets/images/bot_icon.jpg";
import webcamIcon from "../assets/images/webcam.jpg";
import screencastIcon from "../assets/images/screencast.jpg";
import audioWaveIcon from "../assets/images/audio-wave.png";
import { params } from "../data/params";


/**
 * Function to convert tiestamp into Hour:Min:sec format 
 * @param {timestamp} videoTime Time returned via transcoder or api
 */
export const returnVideoTimeInHumanForm = videoTime => {
    var hour = Math.floor(videoTime/3600000);
    var minutes = Math.floor((videoTime%3600000)/60000);
    var seconds = Math.floor(((videoTime%3600000)%60000)/1000);

    var result = '';
    if(hour > 0)
    {
        result += hour + ":";
    }
    if(minutes < 10)
    {
        minutes = "0" + minutes;
    }
    if(seconds < 10)
    {
        seconds = "0" + seconds;
    }
    result += minutes + ":" + seconds;
    return result;
}


/**
 * Function to convert date time stamp in zone specific date time
 * @param {datetime} dateToConvert date in any form
 * @param {string} timeZone timezone to be added
 *  Not used right now and considered format = 'M d Y' {string} format Format to be converted into
 */
export const newDateFormat = (dateToConvert, timeZone = null) => {
    dateToConvert = new Date(dateToConvert);
    if (timeZone === null) {
        timeZone = 0;   // get it from user state (thought set as 0 only everywhere)
    }
    var timeZoneDifference = timeZone * 60 * 60;
    dateToConvert = dateToConvert.getTime() + timeZoneDifference;

    var dateToConvert = new Date(dateToConvert);

    var monthsEnum = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]

    var newDate = monthsEnum[dateToConvert.getMonth()] + " " + dateToConvert.getDate() + " " + dateToConvert.getFullYear();
    return newDate.toString();
}

/**
 * Function to get default images according to the keyword given.
 * @param {string} keyword keyword to get the image
 */
export const getImageFromVar = (keyword) => {
    switch (keyword) {
        case 'default-interactive':
            return botIcon;
        case 'default-audiowave':
            return audioWaveIcon;
        case 'default-screencast':
            return screencastIcon;
        case 'default-webcam':
            return webcamIcon;
        case 'default-transparent':
        case 'NoneImage':
            return noneIcon;
        default:
            break;
    }
}

export const getCookie = (name) => {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

/**
 * 
 * @param {string} name name of the cookie
 * @param {string} value value of the cookie
 * @param {int} expirationTime expiration time in minutes
 */
export const setCookie = (name, value, expirationTime=null) => {
    if (expirationTime) {
        var date = new Date();
        date.setTime(date.getTime() + (expirationTime * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

/**
 * 
 * @param {string} baseUrl controller/function
 * @param {string} parameters in key value format
 */
export const getPhpUrl = (baseUrl, parameters = {}) => {
    var link = params["phpUrlBase"] + baseUrl;
    Object.keys(parameters).forEach( key => {
        link += "&" + key + "=" + parameters[key]
    } )
    return link;
}


export const getRandomID = (length) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}


export const validateIntentText = (str)=> {
    //checks for a-z,nums and some special chars  @ # _ . , ^ \\ [ ] and spaces
    var allowedChars = /^[a-zA-Z0-9@#^_\\[\]\\|,.\-\\/ ]*$/
    return allowedChars.test(str);
  }


  export const getWordRange = ({startIndex,endIndex,str}) => {
    let underlinedSentenceWords = str.slice(startIndex+1,endIndex+1).split(' ')
    let wordsOfSentenceTillStartIndex = str.slice(0,startIndex-1).split(' ')
    let firstWordIndex = wordsOfSentenceTillStartIndex.length
    let lastWordIndex = firstWordIndex + underlinedSentenceWords.length

    if(startIndex === 0){
        underlinedSentenceWords = str.slice(startIndex,endIndex+1).split(' ')
        wordsOfSentenceTillStartIndex = str.slice(0,startIndex).split(' ')
        firstWordIndex = startIndex
        lastWordIndex = firstWordIndex + underlinedSentenceWords.length
       }

    return {firstWordIndex:firstWordIndex,lastWordIndex:lastWordIndex}
  }

  export const getCharacterRangeNew = ({start,end,str}) => {
    let words = str.split(" ");
    let startIndex = words.slice(0,start).join(" ").length + 1;
    let endIndex = words.slice(0,end).join(" ").length;

    return {startIndex:startIndex-1,endIndex:endIndex-1}

 }


 /**
    export const getCharacterRange = (start,end,str) => {
         let words = str.split(" ");
         let sentence = words.slice(start,end);
         let startIndex = str.indexOf(sentence.join(" "))
         let endIndex = startIndex + sentence.join(" ").length
         
         return {startIndex,endIndex}
    
      }
  */

export const addSpacesToSpecialCharacters = (str) => {
    let cpy = str;
    let indexes = [];
    cpy = cpy.replace(/\s+/g, 'i');
    while (/\W/.test(cpy)) {
        indexes.push(cpy.search(/\W/));
        cpy = cpy.replace(/\W/, 'i');
    }

    let finalString = ""

    for (let i = 0; i < str.length; i++) {
        if (indexes.includes(i)) {
            finalString = finalString + " " + str[i] + " "
        }
        else {
            finalString = finalString + str[i]
        }
    }

    return finalString

}
    


export const isStrEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const makeKeysReadable = (str) => {
    return str.split("_").filter(x => x.length > 0).map((x) => (x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())).join(" ");
}