import { combineReducers } from 'redux';

import thumbnailReducer from './workbench/thumbnailsReducer';
import uiReducer from './workbench/ui';
// import conversationReducer from './workbench/conversation';
import conversationReducer from './workbench/conversationReducer';
import todolistReducer from './common/todolistReducer';
import userReducer from "./common/userReducer";
import initReducer from "./workbench/initReducer";
import notesReducer from "./workbench/notesReducer";
import resourcesReducer from "./workbench/resourcesReducer";
import feedbackReducer from "./workbench/feedbackReducer";
import shareReducer from "./workbench/shareReducer";
import coachingReducer from "./workbench/coachingReducer";
import rulesReducer from "./rules/rulesReducer";
import storyReducer from "./common/storyReducer";
import luisAppReducer from "./luis-app/luisAppReducer";
// import aiAppReducer from "./aiApp/aiAppReducer";
import aiAppReducer from '../reducers/aiAppReducer/aiAppReducer';

export default combineReducers({
	user: userReducer,
	// workbench: initReducer,
	// thumbnails: thumbnailReducer,
	// ui: uiReducer,
	// conversation: conversationReducer,
	// todolist: todolistReducer,
	// notes: notesReducer,
	// resources: resourcesReducer,
	// feedback: feedbackReducer,
	// share: shareReducer,
	// coaching: coachingReducer,
	rules: rulesReducer,
	story: storyReducer,
	luis_app: luisAppReducer,
	aiApp: aiAppReducer,
});
