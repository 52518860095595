/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import SearchableSelect from "react-select";
import set from 'lodash/set';
import unset from 'lodash/unset'
import get from 'lodash/get'

import { Row, Col, Table } from 'react-bootstrap';
import Icon from 'react-fa';
import Tags from "../../UI/Tags/Tags";

/** Importing alert modal  */
import { confirmAlert } from 'react-confirm-alert';

/** Importing custom Components */

import BotResponsesModal from "../BotResponsesModal/BotResponsesModal";
import RuleResponseNodeModal from "../RuleResponseNodeModal/RuleResponseNodeModal";
import RulesTable from "./RulesTable/RulesTable";
import RuleType from "./RulesTable/RuleType/RuleType";

import { setRules, changeRuleType, setShowIntentModal, changeNodeAction, setSelectedRule, setBulkEdit, setLoaders, removeResponse} from "../../../store/actions/rules/rulesActions";
import { getMetadataTitleToShow, getChangedRuleJson, createFlatJson } from "../../../utils/rulesUtils";

/** Importing custom Styles */
import classes from './NodeRules.module.css';
import ReactAudioPlayer from '../../UI/ReactPlayer/ReactPlayer';
import { params } from '../../../data/params';

/** Main Components */
class NodeRules extends React.Component {
    constructor (props) {
        super(props);
        var responsesForModal = BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses);
        var selectedResponse = BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses, [this.props.propsRedux.bulkEdit.to]);

        var selectedResponseFrom = BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses, [this.props.propsRedux.bulkEdit.from]);

        this.confirmDeleteIntentAlert = {
            title: 'Confirm',
            message: 'Are you sure you want to delete the Intent?',
            buttons: [
              {
                label: 'Confirm',
                onClick: this.onRemoveIntent
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };

        this.confirmDeleteBulkEdit = {
            title: 'Confirm',
            message: 'Are you sure you want to Cancel Operation?',
            buttons: [
              {
                label: 'Confirm',
                onClick: this.onCancelEdit
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        
        
        this.nonCopyableKeys = {
            "globalRule": true,
            "None": true
        }
        this.state = {
            selectedResponseTextKeyJson: selectedResponse,
            responsesForModal: responsesForModal,
            responseSelected: null,
            fromResponse: selectedResponseFrom,
        };

    }

    applyBulkChanges = ()=> {
        let props = this.props.propsRedux;
        global.recordAudit("Response modal Apply Changes button clicked Action ");

        let selectedResponse = props.bulkEdit.to;
        var error = false;
        var refPathObj = [];

        this.props.setBulkEdit({ ...this.props.propsRedux.bulkEdit, applyChanges: true });

        props.bulkEdit.selectedNodeBulk.forEach(selectedNode => {
            refPathObj = this.createRefPath(selectedNode, props, refPathObj);
        });
        global.recordAudit("Response modal Apply Changes button clicked Action refpath ",refPathObj);

        /** Bulk Edit Response- Rule and Response Node */
        if (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.EDIT) {
            this.bulkResponseEditWrapper(props, selectedResponse, refPathObj);
        }

        /** Bulk Delete Response - Rule and Response Node */
        if (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE) {
            this.bulkResponseDelete(refPathObj);
        }
    }


    onRemoveIntent = () => {
        global.recordAudit("Remove intent clicked : " + JSON.stringify(this.props.propsRedux.selectedNode));
        var newRuleJson = { ...this.props.propsRedux.rulesJson };
        if (this.props.propsRedux.selectedNode.isMetadata) {
            delete newRuleJson["metadata"][this.props.propsRedux.selectedNode.node];
        } else {
            this.props.setSelectedRule(this.props.propsRedux.selectedNode.node, newRuleJson["ruleResponses"][this.props.propsRedux.selectedNode.node], false, null, null, [], global.CUST_CONSTANTS.CRUD_MODE.DELETE, null, true);
            delete newRuleJson["rule"][this.props.propsRedux.selectedNode.node];
            delete newRuleJson["ruleResponses"][this.props.propsRedux.selectedNode.node];
            this.props.changeNodeAction(global.CUST_CONSTANTS.CRUD_MODE.DELETE);
        }
        this.props.setRules(newRuleJson, global.CUST_CONSTANTS.ACTION_LEVEL.NODE);
    }

    onCancelEdit = () => {
        global.recordAudit("Cancel BulkEdit  clicked : " + JSON.stringify(this.props.propsRedux.selectedNode));
        
        this.props.setBulkEdit({state: false,
                action: global.CUST_CONSTANTS.CRUD_MODE.EDIT,
                type: global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE,
                from: null,
                to: null,
                responseJson: {},
                keyMapper: {},
                selectedNodeBulk: [],
                ruleResponse:{},
                applyChanges: false, });

    }

    onDuplicateClick = () => {
        this.props.setSelectedRule(this.props.propsRedux.selectedNode.node, null, false, null, null, [], global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK, null, true);
        this.props.setShowIntentModal(true, global.CUST_CONSTANTS.CRUD_MODE.ADD);
        global.recordAudit("Duplicate clicked : " + JSON.stringify(this.props.propsRedux.selectedNode));
    }

    onRuleTypeChange = (event) => {
        var value = event.target.value;
        global.recordAudit("Rule Type changed for selected node : " + JSON.stringify(this.props.propsRedux.selectedNode) + " value : " + value);
        this.props.changeRuleType(value);
    }
    formatOptionLabelResponses = ({ value, label, tags }) => (
        <div className={ [ classes.Option ].join(' ') }>
            { label }
            <Tags keyBase={ "response_modal_tag_" + value } tags={ tags } />
        </div>
    );

    onResponseChange = (response) => {
        var key = parseInt(response.value);
        // this.setClips(key);
        this.setState({ isSaveDisabled: true });
        this.setState({ selectedResponseTextKeyJson: response});
        this.props.setBulkEdit({...this.props.propsRedux.bulkEdit,to:key})
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        var objChanged = null;
        if ( Object.keys(nextProps.propsRedux.botResponses).length !== prevState.botResponsesLength ) {
            var responsesForModal = BotResponsesModal.getResponsesForModal(nextProps.propsRedux.botResponses);
            return { responsesForModal: responsesForModal };
        }

        return objChanged;
        
    }

    componentDidUpdate = ( prevState) => {
        if((prevState.propsRedux.bulkEdit.to !== this.props.propsRedux.bulkEdit.to)){
            this.setState({selectedResponseTextKeyJson: BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses,[this.props.propsRedux.bulkEdit.to])});
        }

         if((prevState.propsRedux.bulkEdit.from !== this.props.propsRedux.bulkEdit.from)){
             let stateTemp = BotResponsesModal.getResponsesForModal(this.props.propsRedux.botResponses,[this.props.propsRedux.bulkEdit.from]);
             console.log("state temp ",stateTemp, " label ")
            this.setState({fromResponse: (stateTemp && stateTemp.length>0)?stateTemp[0]['label']:null});
        }

        
}


    bulkResponseDelete(refPathObj) {
        if (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE) {
            if( this.props.propsRedux.bulkEdit.selectedNodeBulk && this.props.propsRedux.bulkEdit.selectedNodeBulk.length > 0 ){
                let data = getChangedRuleJson(this.props.propsRedux.rulesJson, refPathObj, global.CUST_CONSTANTS.CRUD_MODE.DELETE);
                this.props.setRules(data.newRulesJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
            }
            
            if (this.props.propsRedux.bulkEdit.selectedNodeGlobal && this.props.propsRedux.bulkEdit.selectedNodeGlobal.length > 0) {
                this.props.propsRedux.bulkEdit.selectedNodeGlobal.forEach(globalNode => {
                    let path = this.props.propsRedux.bulkEdit.ruleResponse[globalNode]["path"];
                    let separator = params.callFlowSeparator;
                    path = path.split(separator);
                    if (path[0] == 0) { path.shift(); }
                    let index = path.splice(-1, 1);
                    let val = get(this.props.propsRedux.rulesJson['rule'], path);
                    val.splice(index, 1);
                    set(this.props.propsRedux.rulesJson['rule'], path, val);
                    this.props.setRules(this.props.propsRedux.rulesJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
                    /** Call Call Flow here */
                });
            }
        }
    }

    bulkResponseEditWrapper(props, selectedResponse, refPathObj) {
        this.editResponseIntent(props, selectedResponse);
        this.bulkResponseEdit(props, refPathObj, selectedResponse);
    }

    bulkResponseEdit(props, refPathObj, selectedResponse) {
        if (props.bulkEdit.state && refPathObj) {
            refPathObj.forEach(refPath => {
                if (!Array.isArray(refPath)) { refPath = [refPath]; }
                var data = getChangedRuleJson(this.props.propsRedux.rulesJson, refPath, "EDIT", selectedResponse, props.botResponses, props.detailedVsSimpleViewIndexesMapper);
                this.props.setRules(data.newRulesJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);
                this.props.setLoaders(data.loadersPayloads);
            });

        }
    }

    editResponseIntent(props, selectedResponse) {
        if (props.bulkEdit.selectedNodeGlobal && props.bulkEdit.selectedNodeGlobal.length > 0) {
            props.bulkEdit.selectedNodeGlobal.forEach(globalNode => {
                let separator = params.callFlowSeparator;
                let path = props.bulkEdit.ruleResponse[globalNode]["path"].split(separator);
                if (path[0] == 0) {
                    path.shift();
                }
                let responseText = props.botResponses[selectedResponse]["utterance_text"].trim();
                set(this.props.propsRedux.rulesJson["rule"], path, responseText);
                this.props.setRules(this.props.propsRedux.rulesJson, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE);

            });
        }
    }

    createRefPath(selectedNode, props, refPathObj) {
        let selectval = '';
        let resp = '';
        let res = '';
        var nodekey = selectedNode;
        let ruleKey = "generic";
        let type = "generic";
        let evalKey = null;
        let index = 0;
        let selectedAddonKey = null;

        let separator = params.callFlowSeparator;
        let selectedNodeArray = selectedNode.split(separator);
        if (selectedNodeArray.length > 1 && selectedNodeArray[0] == 'addon') {
            type = "addon";
            nodekey = selectedNodeArray[2];
            selectedAddonKey = selectedNodeArray[1];
            resp = props.bulkEdit.responseJson.responseAddon[nodekey];
            res = createFlatJson(resp);
        } else if (selectedNodeArray.length > 1 && selectedNodeArray[0] == 'global_addon') {
            type = "global_addon";
            nodekey = "globalRule";
            ruleKey = selectedNodeArray[2];
            selectedAddonKey = selectedNodeArray[1];
            resp = props.bulkEdit.responseJson.responseGlobal[ruleKey];
            res = createFlatJson(resp);
        } else {
            //Generic Node
            selectval = props.bulkEdit.keyMapper.responseNode[selectedNode];
            resp = props.bulkEdit.responseJson.responseNode[selectval];
            res = createFlatJson(resp);
        }

        console.log("flat json ",res);
        Object.keys(res).forEach(function (key) {
            if (res[key] === props.bulkEdit.from) {
                let separator = params.callFlowSeparator;
                let keysArr = key.split(separator);
                if (type === "addon" && keysArr[0] === selectedAddonKey) {
                    index = keysArr[3] ? parseInt(keysArr[3]) : parseInt(0);
                    var iterationKey = keysArr[2];
                    var responseKey = keysArr[1];
                    ruleKey = keysArr[0];

                } else if (type === "global_addon") {
                    index = keysArr[2] ? parseInt(keysArr[2]) : parseInt(0);
                    var iterationKey = keysArr[1];
                    var responseKey = keysArr[0];
                    // ruleKey = keysArr[0];

                } else {
                    index = keysArr[4] ? keysArr[4] : parseInt(0);
                    evalKey = keysArr[1];
                    var responseKey = keysArr[2];
                    var iterationKey = keysArr[3];
                }
                if (keysArr.length >= 0) {
                    refPathObj.push({
                        referenceId: props.bulkEdit.to,
                        index: index,
                        nodeKey: nodekey,
                        isMetadata: false,
                        ruleKey: ruleKey,
                        evalKey: evalKey,
                        responseKey: responseKey,
                        iterationKey: iterationKey,
                    });
                }
            }
        });
        return refPathObj;
    }

    onDoneBulkEdit = () => {
        this.props.setBulkEdit({
            state: false,
            applyChanges: false,
            action: null,
            type: null,
            from: null,
            to: null,
            responseJson: {},
            keyMapper: {},
            selectedNodeBulk: {},
            ruleResponse:{} 
        });
    }

    render() {

        var intentToShow = this.props.propsRedux.selectedNode.node;
        var selectedNodeResponseNotEmpty = (
            this.props.propsRedux.selectedNode && 
            this.props.propsRedux.selectedNode.node && 
            (
                (
                    !(this.props.propsRedux.selectedNode.isMetadata) &&
                    this.props.propsRedux.selectedNode.responseJson && 
                    (Object.keys(this.props.propsRedux.selectedNode.responseJson).length > 0)
                ) ||
                (
                    this.props.propsRedux.selectedNode.isMetadata &&
                    this.props.propsRedux.rulesJson.metadata && 
                    this.props.propsRedux.rulesJson.metadata[this.props.propsRedux.selectedNode.node]
                )
            )
        );

        if (this.props.propsRedux.selectedNode.scenario) {
            intentToShow = "Scenario -> " + this.props.propsRedux.selectedNode.scenario + " -> " + this.props.propsRedux.selectedNode.node;
        }

        if (this.props.propsRedux.selectedNode.isMetadata) {
            intentToShow = getMetadataTitleToShow(this.props.propsRedux.selectedNode.node);
        }

        const type = (this.props.propsRedux.rulesJson && this.props.propsRedux.rulesJson.rule && this.props.propsRedux.rulesJson.rule[this.props.propsRedux.selectedNode.node] && this.props.propsRedux.rulesJson.rule[this.props.propsRedux.selectedNode.node].type) ? this.props.propsRedux.rulesJson.rule[this.props.propsRedux.selectedNode.node].type : 1;

        console.log("DEBUGGER:: this.state.selectedResponseTextKeyJson this.state.selectedResponseTextKeyJson", this.state.selectedResponseTextKeyJson);

        return (
            <Fragment>
                {/** To be discussed: */}
                <div className={ [ ( selectedNodeResponseNotEmpty && this.nonCopyableKeys && (!this.nonCopyableKeys[this.props.propsRedux.selectedNode.node])) ? "pl-3 pr-3 pt-3 box-shadow border-radius-2" : "" ].join(' ') } >
                <Row>
                    <Col sm={12}>
                        <div className={ [ classes.ActionDiv ].join(' ') }>
                            {   (!this.props.propsRedux.bulkEdit.state) 
                                ?
                                    selectedNodeResponseNotEmpty &&
                                    <label className={ [ classes.NodeName ].join(' ') }><strong> Node/Intent Name :</strong> { intentToShow } </label> 

                                : 
                                    (
                                        !this.props.propsRedux.bulkEdit.applyChanges && 
                                        <div className={ [ classes.FromNChangeTo ].join(' ') }>
                                            <label className={ [ classes.NodeName ].join(' ') }>
                                                <strong> 
                                                    {
                                                        (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.EDIT && this.props.propsRedux.bulkEdit.state) 
                                                        ? "From : " 
                                                        : "Delete : "
                                                    }
                                                </strong> 
                                                { !this.props.propsRedux.bulkEdit.applyChanges && this.state.fromResponse } 
                                            </label> 
                                        </div>
                                    )
                            }
                            {  
                                (this.props.propsRedux.bulkEdit.state) && 
                                (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.EDIT) &&
                                !this.props.propsRedux.bulkEdit.applyChanges  && 
                                this.state.selectedResponseTextKeyJson && 
                                this.state.selectedResponseTextKeyJson[0] && 
                                this.state.selectedResponseTextKeyJson[0].label &&
                                <div className={ [ classes.FromNChangeTo ].join(' ') }>
                                    <div>
                                        <strong> Change To :</strong> 
                                        <label>
                                            {this.state.selectedResponseTextKeyJson[0].label}
                                        </label>
                                    </div>


                                    
                                    {/* {  (this.props.propsRedux.bulkEdit.state) && !this.props.propsRedux.bulkEdit.applyChanges &&
                                            <SearchableSelect 
                                            className={classes.SelectableHeader}
                                            name="responses_node" 
                                            value={ this.state.selectedResponseTextKeyJson }
                                            hideSelectedOptions={ true }
                                            options={ this.state.responsesForModal } 
                                            onChange={ (response) => this.onResponseChange(response, false) } 
                                            formatOptionLabel={ this.formatOptionLabelResponses }
                                            />
                                    } */}
                                </div>
                            }
                            {
                                <span>
                                    { 
                                        this.props.propsRedux.bulkEdit.state && 
                                        this.props.propsRedux.bulkEdit.applyChanges && 
                                        (
                                            (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.EDIT) ||
                                            (this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE)
                                        ) &&
                                        <div>
                                        <label className={ classes.ListBulkHeader }> Changes Applied on  </label>
                                        <button className={ [ "btn btn-sm btn-primary assign-button", classes.DoneBtn ].join(' ') } onClick={ () => this.onDoneBulkEdit() } >Done</button>
                                        </div>
                                    }
                                </span>
                            }
                            
                            {
                                selectedNodeResponseNotEmpty && 
                                this.nonCopyableKeys && 
                                (
                                    !this.nonCopyableKeys[this.props.propsRedux.selectedNode.node] || 
                                    this.props.propsRedux.bulkEdit.state
                                ) &&
                                <span className={ [ "pull-right" ].join(' ') }>
                                    { (!this.props.propsRedux.bulkEdit.state) &&
                                        (!this.props.propsRedux.selectedNode.isMetadata) &&
                                        (this.props.propsRedux.selectedNode.node !== global.CUST_CONSTANTS.SAMPLE_NODEKEY) &&
                                        <button className={ [ "btn-primary", classes.ActionButton, "assign-button add-node-button btn btn-primary btn-sm" ].join(' ') } onClick={ () => this.onDuplicateClick()}>
                                            <Icon name="plus-square" className={ [ "icon-btn" ].join(' ') } />
                                            Duplicate
                                        </button>
                                    }
                                    {
                                        (!this.props.propsRedux.bulkEdit.state) &&
                                        (this.props.propsRedux.selectedNode.node !== global.CUST_CONSTANTS.SAMPLE_NODEKEY) && 
                                        selectedNodeResponseNotEmpty &&
                                        <button className={ [ "btn-danger", classes.ActionButton, "btn", "btn-sm" ].join(' ') } onClick={ () => { confirmAlert(this.confirmDeleteIntentAlert)} } >
                                            <Icon name="trash" className={ [ "icon-btn" ].join(' ') } />
                                            Remove
                                        </button>
                                    }
                                    {
                                        (this.props.propsRedux.bulkEdit.state) &&
                                        (this.props.propsRedux.selectedNode.node !== global.CUST_CONSTANTS.SAMPLE_NODEKEY) && 
                                        selectedNodeResponseNotEmpty && 
                                        !this.props.propsRedux.bulkEdit.applyChanges &&
                                        <div className={ [ classes.BulkActions ].join(' ') }>
                                            <button className={ [ "btn-danger", classes.ActionButton, "btn", "btn-sm" ].join(' ') } onClick={ () => { confirmAlert(this.confirmDeleteBulkEdit)} } >
                                                <Icon name="" className={ [ "icon-btn" ].join(' ') } />
                                                Cancel
                                            </button>
                                            <button className={ [ "btn-primary", classes.ActionButton, "assign-button add-node-button btn btn-primary btn-sm", "btn-sm" ].join(' ') } onClick={ this.applyBulkChanges} >
                                            <Icon name="edit" className={"icon-btn"} />
                                                Apply Changes
                                            </button>
                                        </div>
                                    }
                                </span>
                            }
                            {   !this.props.propsRedux.bulkEdit.state  &&
                                this.props.propsRedux.selectedNode.node && 
                                (this.props.propsRedux.rulesJson.ruleResponses[this.props.propsRedux.selectedNode.node]) &&
                                (!this.props.propsRedux.selectedNode.isMetadata) &&
                                (this.props.propsRedux.selectedNode.node != global.CUST_CONSTANTS.STORY_NONE_RESPONSE) &&
                                (this.props.propsRedux.selectedNode.node != global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE) &&
                                <RuleType
                                    type={ parseInt(type) }
                                    onChange={ this.onRuleTypeChange }
                                />
                            }
                            <ReactAudioPlayer/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>

                        {
                            (
                                selectedNodeResponseNotEmpty ||
                                (this.props.propsRedux.bulkEdit.state)
                            ) && 
                            <div className={ ["genericrule-table", classes.NodeRules ].join(' ') }>
                                {  
                                    <RulesTable
                                        className={classes.Table + ' node-table statement-action'}
                                    />
                                }
                            </div>
                        }

                        <BotResponsesModal 
                            show={ this.props.propsRedux.showResponseModal } 
                            onShow={ () => {} } 
                            onHide={ () => {} } 
                            title="Responses"
                            // size="lg"
                        />

                        <RuleResponseNodeModal 
                            show={ this.props.propsRedux.showRulesNodeResModal } 
                            onShow={ () => {} } 
                            onHide={ () => {} } 
                            title="Add Response Block"
                            // size="lg"
                        />
                    </Col>
                </Row>
                </div>
            </Fragment>
        );
    }
}

/** Defining prop types requirements */
NodeRules.propTypes = {
    propsRedux: PropTypes.shape({
        selectedNode: PropTypes.shape({
            // node: PropTypes.string,
            responseJson: PropTypes.object,
            isMetadata: PropTypes.bool,
            scenario: PropTypes.string,
            isSelectedFromJsonView: PropTypes.bool,
        }).isRequired,
        intents: PropTypes.object.isRequired,
        rulesJson: PropTypes.object.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            selectedNode: state.rules.selectedNode,
            intents: state.rules.intents,
            rulesJson: state.rules.rulesJson,
            storyId: state.story.storyId,
            showResponseModal: state.rules.showResponseModal,
            showRulesNodeResModal: state.rules.showRulesNodeResModal,
            botResponses: state.rules.botResponses,
            bulkEdit: state.rules.bulkEdit,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setRules, changeRuleType, setShowIntentModal, changeNodeAction, setSelectedRule, setBulkEdit, getChangedRuleJson, setLoaders, createFlatJson })(NodeRules);