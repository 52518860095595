/*
 * Component Description : Block Action DropDown used for manupulating block actions
 * Author: Ankit Aditya
 */

import React from 'react';
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import { Dropdown, DropdownButton } from "react-bootstrap";
import { messages } from '../../../../data/strings';
import classes from "./BlockAction.module.css";
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';

/** Importing custom Components */
import { setCallFlowJson, setShowUtteranceModal, setShowUtteranceSideBar, setShowEntityModal } from "../../../../store/actions/rules/rulesActions";


/** Main Components */
class BlockAction extends React.Component {
    /** Lifecycle methods start */
    constructor(props) {
        super(props);
        this.state = {
            currentUtterance: "",
        }
    }
    /** Lifecycle methods end */

    
    showConfirmAlert = (step, block) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Set This Utterance as Sample Agent Utterance',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => { this.setDefaultUtt(step, block) }
                },
                {
                    label: 'Cancel',
                    onClick: () => { }
                }
            ]
        });
    }

    onAddConfigureUtterance = (stepKey,blockKey) => {
        global.recordAudit("Configure Utterance clicked" + stepKey + " And Block " + blockKey);
        console.log("block key is " + blockKey);
        /** Call Flow version 1.1 and old support */
        var newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
        if(newCallFlowJson[stepKey].settings)
        {
            if(newCallFlowJson[stepKey].settings.defaultUtterance&&newCallFlowJson[stepKey].list[blockKey].sampleUtterances){
            if(newCallFlowJson[stepKey].settings.defaultUtterance.blockId!=newCallFlowJson[stepKey].list[blockKey].sampleUtterances[0].blockId&&newCallFlowJson[stepKey].settings.defaultUtterance.utteranceText==""){
                newCallFlowJson[stepKey].settings.defaultUtterance = newCallFlowJson[stepKey].list[0].sampleUtterances?newCallFlowJson[stepKey].list[0].sampleUtterances[0]:{}
            }
        }else{
            newCallFlowJson[stepKey].settings.defaultUtterance = newCallFlowJson[stepKey].settings.defaultUtterance?newCallFlowJson[stepKey].settings.defaultUtterance:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}
        }}
        this.props.setShowUtteranceSideBar(false,{});
        this.props.setCallFlowJson(newCallFlowJson,true,true);
        this.props.setShowUtteranceModal(true, newCallFlowJson[stepKey].list[blockKey].node, stepKey,blockKey);
    }
    
    
    setDefaultUtt = (step, block) => {
        global.recordAudit("Default Utterance Button Clicked : " + this.state.newUtteranceName);
        /** Call Flow version 1.1 and old support */
      var newCallFlowJSON = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
        if (newCallFlowJSON[step]) {
            if (newCallFlowJSON[step].settings) {

                newCallFlowJSON[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE] = newCallFlowJSON[step].list[block][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0];
            }
            this.props.setShowUtteranceSideBar(false,{});
            this.props.setCallFlowJson(newCallFlowJSON,true,true);
        }

    }

    onConfigureEntity = (stepKey,blockKey) =>{
        var newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
        this.props.setShowUtteranceSideBar(false,{});
        this.props.setShowEntityModal(true, newCallFlowJson[stepKey].list[blockKey].node, stepKey,blockKey,false);
    }

    /*
     onShowHideToggle() {
       // write code for toggle show and hide 
    }
    */

    render() {
            let addConfigure = [];
            this.props.propsOwned.settings.map((value, index) => {
                if (value[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]?value[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText !== ""?true:false:false) {
                    addConfigure.push("Configure");
                } else {
                    addConfigure.push("Add");
                }
            });
        return (
                    <Dropdown drop="left">
                        <Dropdown.Toggle 
                            align="start"
                            aria-label="More Menu"
                            id="dropdown-menu-align-left" size="sm" variant="link" bsPrefix="p-0"
                            data-tip = {messages.callFlowSettings}
                            data-class="tooltip_cust"
                            data-place="top"
                        >
                            {this.props.propsOwned.title}
                        </Dropdown.Toggle>
                                        
                        <Dropdown.Menu className={[classes.DropMenuStyle].join(' ')}>
                        {
                            <Dropdown.Item
                                key={"delete_item"}
                                onClick={this.props.propsOwned.deleteBlock}
                            >
                                Delete
                            </Dropdown.Item>
                        }
                        {
                            <Dropdown.Item
                                key={"toggle_add_configure"}
                                onClick={() => this.onAddConfigureUtterance(this.props.propsOwned.stepKey,this.props.propsOwned.blockKey)}
                                disabled={this.props.isDisabled}
                            >
                                {addConfigure[this.props.propsOwned.blockKey] ? addConfigure[this.props.propsOwned.blockKey] : ""} Sample Utterance
                            </Dropdown.Item>
                        }
                        <Dropdown.Item key={"sample_utterance_default"} onClick={() => this.showConfirmAlert(this.props.propsOwned.stepKey, this.props.propsOwned.blockKey)} disabled = {this.props.isDisabled||this.props.noUtterance}>
                            Make Default Sample Agent Utterance
                        </Dropdown.Item>
                        <Dropdown.Item
                            key={"configure_entity"}
                            onClick={() => this.onConfigureEntity(this.props.propsOwned.stepKey,this.props.propsOwned.blockKey)}
                        >
                            Configure Rules
                        </Dropdown.Item>
                        {/* <Dropdown.Item key={"show_hide_toggle"} onClick={this.onShowHideToggle()} >
                            Toggle Show/Hide
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>

        );
    }
}

/** Defining prop types requirements */
BlockAction.propTypes = {
    propsRedux: PropTypes.shape({
        callFlowJson: PropTypes.object.isRequired
    }),
    propsOwned: PropTypes.shape({
        stepKey: PropTypes.string.isRequired,
        blockKey: PropTypes.number.isRequired,
        settings: PropTypes.array.isRequired,
        deleteBlock: PropTypes.func.isRequired

    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            callFlowJson: state.rules.callFlow.callFlowJSON,
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setCallFlowJson, setShowUtteranceModal, setShowUtteranceSideBar, setShowEntityModal })(BlockAction);