import React ,{useState} from 'react';
import { connect, useSelector } from "react-redux";
import Icon from 'react-fa';
import AlertDiv from "../../../UI/AlertDiv/AlertDiv";
import { setAlert } from "../../../../store/actions/common/commonActions";
import {setSelectedRule, setResponseAction, showResponseModal } from "../../../../store/actions/rules/rulesActions";
import BtnBack from '../../../UI/BtnBack/BtnBack';
import classes from './NewHeaderDiv.module.css'
import { luisDataOptions, messages } from "../../../../data/strings";
import IntentModal from '../../IntentModal/IntentModal';
import UtteranceModal from '../../UtteranceModal/UtteranceModal';
import EntityModal from '../../EntityModal/EntityModal';
import TestStaging from './TestStaging/TestStaging';
import ExistingRulesModal from '../../ExistingRulesModal/ExistingRulesModal';
import { useEffect } from 'react';


const NewHeaderDiv = (props) =>{
    const [untrainedLuisInfoLabel,setUntrainedLuisInfoLabel] = useState({});
    const luisAppName = useSelector(state => state.luis_app.appName)
    const onDismissAlert = () => {
        props.setAlert(null);
    }
    const formatUntrainedLuisInfoLabelOptions = () => {
        if (props.propsRedux.luisData.isActionEnabled) {
            return luisDataOptions.luisActionEnabled
        } else if (!props.propsRedux.luisData.isActionEnabled) {
            return luisDataOptions.luisActionDisabled
        } else if (props.propsRedux.luisData.isIncomingDataExist) {
            return luisDataOptions.luisDataExist
        } else if (!props.propsRedux.luisData.isIncomingDataExist) {
            return luisDataOptions.luisDataAbsent
        } else {
            return luisDataOptions.default
        }
    }

    useEffect(()=> {
        setUntrainedLuisInfoLabel(formatUntrainedLuisInfoLabelOptions())
    },[props.propsRedux.luisData])

    return (

        <div className="d-flex justify-content-between p-1 align-items-center">
            {
                props.propsRedux.alert.message &&
                <AlertDiv message={ props.propsRedux.alert.message } type={ props.propsRedux.alert.type } onHide={ () => onDismissAlert() } fadeout={ props.propsRedux.alert.fadeout } />
            }
            {
                props.propsRedux.loading && 
                <AlertDiv message={ messages.autoSavingAlertMessage } type={ 2 } onHide={ () => onDismissAlert() } fadeout={ false } />
            }
            {
                props.propsRedux.intents != undefined 
                && Object.keys(props.propsRedux.intents).length > 0  
                && props.propsRedux.rulesJson
                && Object.keys(props.propsRedux.rulesJson).length > 0  
                && <IntentModal
                    show={props.propsRedux.showIntentModal}
                    onShow={() => { }}
                    onHide={() => { }}
                    title="Intent Name"
                />
            }
            {
                props.propsRedux.utterances != undefined 
                && Object.keys(props.propsRedux.utterances).length > 0  
                && props.propsRedux.rulesJson
                && Object.keys(props.propsRedux.rulesJson).length > 0  
                && <UtteranceModal
                    show={props.propsRedux.showUtteranceModal}
                    onShow={() => { }}
                    onHide={() => { }}
                    title="Utterance Name"
                />
            }

            {
                // props.propsRedux.utterances != undefined 
                // && Object.keys(props.propsRedux.utterances).length > 0  
                // && 
                props.propsRedux.rulesJson
                && Object.keys(props.propsRedux.rulesJson).length > 0  
                && <EntityModal
                    show={props.propsRedux.configureEntity}
                    onShow={() => { }}
                    onHide={() => { }}
                    title="Entire Configuration"
                />
            }

{
                // props.propsRedux.utterances != undefined 
                // && Object.keys(props.propsRedux.utterances).length > 0  
                // && 
                props.propsRedux.callFlowJson
                && Object.keys(props.propsRedux.callFlowJson).length > 0  
                && <ExistingRulesModal
                    show={props.propsRedux.showExistingRulesModal}
                    onShow={() => { }}
                    onHide={() => { }}
                    rules={props.propsRedux.rulesJson}
                    title="Existing AddOn Rules"
                />
            }
            <div className="d-flex align-items-center">
                <BtnBack url={props.backUrl}/>
                <TestStaging/>
            </div>
            <div style={{width:'30%'}}>
                <span data-tip={props.title} data-class="tooltip_cust"  data-place="bottom" className={ classes.storyTitle }>{props.title}</span>
            </div>
            <div className='d-flex'>
                <div>
                    <label className={ [ classes.luisUntrainedInfoLabel].join(' ') } style={{background:untrainedLuisInfoLabel.color}}  data-tip={ "AI APP" + ": " + (luisAppName) + ": " +  untrainedLuisInfoLabel.shortText } data-class="tooltip_cust"  data-place="top">
                         AI Info : {
                            untrainedLuisInfoLabel.title
                        }
                    </label>
                </div>
                <div>
                    <label className={ [ classes.AutosaveLabel].join(' ') }  data-tip={ messages.autoSaveLabel } data-class="tooltip_cust"  data-place="top">
                        <Icon name="circle" className={ [ classes.AutosaveLabelFa ].join(' ') } />  Autosave Enabled : {
                            props.propsRedux.loading ? "Autosaving" : "Autosaved"
                        }
                    </label>
                </div>
            </div>
        </div>
    )    
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
            callFlowJson: state.rules.callFlow.callFlowJSON,
            intents: state.rules.intents,
            utterances: state.rules.sampleUtterances,
            showIntentModal: state.rules.showIntentModal,
            showUtteranceModal: state.rules.showUtteranceModal,
            configureEntity: state.rules.configureEntity,
            loading: state.rules.loading,
            alert: state.rules.alert,
            showExistingRulesModal: state.rules.showExistingRulesModal,
            luisData: state.rules.untrainedLuisObj
        },
    }
}


/** Exporting main component */
export default connect(mapStateToProps, {  setSelectedRule, setResponseAction, showResponseModal,setAlert })(NewHeaderDiv);