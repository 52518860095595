/*
 * Component Description : 
 * Author: Ankit Aditya
 */

import React, { Component } from "react";
import './ToggleSwitch.css';

class ToggleSwitch extends Component {
  render() {
    return (
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          value={this.props.ShowSampleUtt} 
          onChange={this.props.OnShowSampleUttClick}
          name={this.props.Name}
          id={this.props.Name}
          disabled={this.props.disabled}
        />
        <label className="toggle-switch-label" htmlFor={this.props.Name}>
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
      </div>
    );
  }
}

export default ToggleSwitch;