import React, { useState } from 'react'
import Modal from '../../UI/Modal/Modal'
import { SET_IS_ERROR_MODAL_OPEN } from '../../../store/actions/types'
import { upgradeStoryToV2Editor } from '../../../store/actions/workbench/workbenchActions'
import { useDispatch, useSelector } from 'react-redux'
import { makeKeysReadable } from '../../../utils/utils'
import classes from './UpgradeErrorsModal.module.css'

const UpgradeErrorsModal = (props) => {

  const dispatch = useDispatch();
  const {story:{isUpgrading}} = useSelector(state => state)
  const { isOpen, errors } = props;

  const upgradeToV2Editor = () => {
    dispatch(upgradeStoryToV2Editor())
  }

  const onHide = () => {
    dispatch({
        type: SET_IS_ERROR_MODAL_OPEN,
        payload:{
            isOpen : false,
            errors: null,
        }
    })
  }

  var modalButtons = [
    {
        type: 'upgrade',
        text: 'Ignore & Upgrade',
        variant: 'danger',
        onClick: upgradeToV2Editor,
        className: [  "btn", "btn-danger" ].join(' '),
        isDisabled: isUpgrading
    },
    {
        type: 'close',
        text: 'Close',
        variant: 'secondary',
        onClick: onHide,
        isDisabled: isUpgrading
    }
];

  return (
    <Modal 
    show = {isOpen}
    onShow={() => { }}
    onHide = {onHide}
    title = 'Upgrade Issues'
    footerComponents = {modalButtons}
    >
        <div className={classes.issues_container}>
          <div style={{ paddingLeft: '16px' }}>
            {Object.keys(errors).map((key, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <strong className={classes.issue_key}>{makeKeysReadable(key)}:</strong>
                <span style={{ marginLeft: '10px' }}>{errors[key]}</span>

                {index < Object.keys(errors).length - 1 && <hr style={{ margin: '0.5rem 0' }} />}
              </div>
            ))}
          </div>
        </div>
    </Modal>
  )
}

export default UpgradeErrorsModal
