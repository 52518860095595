/*
 * Component Description : Opens Entity Modal where the user can perform Entity related actions(add/delete) on the Utterance
 * Author: Piyush
 */


import React, { Children, useRef } from 'react'
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { TokenAnnotator } from "react-text-annotate";

import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from "react-select/creatable";

import classes from './EntityPopover.module.css';
import { useEffect } from 'react';
import { setAddUntrainedEntity, setAddUtteranceEntities } from '../../../../../store/actions/rules/rulesActions';
import { messages } from '../../../../../data/strings';
import { setAlert } from '../../../../../store/actions/common/commonActions';
import { Icon } from '@iconify/react';
import { addSpacesToSpecialCharacters, getCharacterRangeNew, getWordRange } from '../../../../../utils/utils';
import { getEntitesOfAnUtterance } from '../../../../../utils/rulesUtils';
import ReactTooltip from "react-tooltip";

const utteranceEntitiesInfoInitialState = {
  utteranceEntities: [],
  entitySelected: {
    name:"",
    isTrained:null
  }
}

function EntityPopover(props) {
  const dispatch = useDispatch()
  const { selectedUtterance, setShow ,show} = props;
  const {entitiesUtterances,utteranceEntities, selectedBlockCallInfo:{node}} = useSelector(state => state.rules)
  const {untrainedLuisObj:{data:{ADD:{entities:untrainedEntitiesDataInAddState},EDIT:{utterances:utterancesInLuisEditState},ADD:{utterances:utterancesInLuisAddState}}}} = useSelector(state => state.rules)
  const [isActionEnabled,setIsActionEnabled] = useState(false)

  const trainedLuisEntities = useSelector(state => state.rules.trainedLuisEntities)
  const trainedLuisEntitiesEntries = trainedLuisEntities ?  Object.entries(trainedLuisEntities) : []
  const [utteranceEntitiesInfo,setUtteranceEntitiesInfo] = useState(utteranceEntitiesInfoInitialState)
  const [globalEntitiesSelection,setGlobalEntitiesSelection] = useState([]) 

  const handleUtteranceTextSelect = (dataArr) => {
   
    //If Entity not selected, restrict
    if (!utteranceEntitiesInfo.entitySelected.name) {
      dispatch(setAlert(messages.addEntityWarning,0,true))
      return
    }

    const utteranceEntitiesData =  dataArr.map((data) => {
      const {startIndex,endIndex} = getCharacterRangeNew({start:data.start,end:data.end,str:selectedUtterance.label})
      const isEntityTrained =checkForTrainedEntity(data.tag)
      
      let entityInfo = {
        ...data,
        entity:data.tag,
        startPos:startIndex,
        endPos:endIndex,
        utterance_text:selectedUtterance.label,
        utterance_id:selectedUtterance.value.utteranceId,
        isEntityTrained:isEntityTrained,
        children:[]
      }

      if(isEntityTrained){
        let selectedEntity = trainedLuisEntitiesEntries.find(item => item[0] === data.tag)
        entityInfo.luisIndex = selectedEntity[1]['luis_index']
      }

      return (entityInfo)
    })

    setIsActionEnabled(true)
    setUtteranceEntitiesInfo({ ...utteranceEntitiesInfo, utteranceEntities: utteranceEntitiesData })
  };

  const handleEntitySelect = (e) => {
    if(e && e.value){
      setUtteranceEntitiesInfo({ ...utteranceEntitiesInfo,entitySelected: {name:e.value.trim(),isTrained:e.isTrained,luisIndex:e.luisIndex} });

    if (e && e.value && e.__isNew__) {
      let newEntity = e.value.trim()
      dispatch(setAddUntrainedEntity(newEntity))
    }
  }
  };

  const handleSave = () => {
    if(!isActionEnabled){
      dispatch(setAlert("No Actions have been performed on this Utterance yet",2))
      return 
    }
    global.recordAudit("Save Button was clicked by User on Entity Modal for Entity to Utterance related actions");
    dispatch(setAddUtteranceEntities({utterance:selectedUtterance,currentUtteranceEntities:utteranceEntitiesInfo.utteranceEntities}))
    handleClose()
  }

  const handleClose = () => {
    setShow(false);
    setIsActionEnabled(false)
    setUtteranceEntitiesInfo(utteranceEntitiesInfoInitialState)
  }

  const handleRemoveEntityFromUtterance = (entityInfo) => {
    if (window.confirm(messages.deleteEntityFromUtterance)) {
      const filteredItems =  utteranceEntitiesInfo.utteranceEntities.filter(item => {
        if((item.start !== entityInfo.start) && (item.end !== entityInfo.end)){
          return item
        }
      })

      setUtteranceEntitiesInfo({...utteranceEntitiesInfo,utteranceEntities:filteredItems})
      setIsActionEnabled(true)
    }
  }

  const checkForTrainedEntity = (entity)=> {
    const currEntity = trainedLuisEntitiesEntries.find(elem => elem[0] === entity)
    if (currEntity) {
      return true
    }else{
      return false
    }
  }

  useEffect(()=>{
    if(show){
      const globalUtteranceEntities = getEntitesOfAnUtterance({entitiesUtterances,node,selectedUtterance})
      const isCurrentUtteranceTrained = selectedUtterance.hasOwnProperty('isUntrained') ? !selectedUtterance.isUntrained : true
      
      const formatEntityObj = (item) => {
        const { firstWordIndex, lastWordIndex } = getWordRange({ startIndex: item.startPos, endIndex: item.endPos, str: item.utterance_text })
        return ({ 
          id: item.id,entity:item.name, 
          start: firstWordIndex, 
          end: lastWordIndex, 
          tag: item.name, 
          isEntityTrained: true,
          utterance_text: item.utterance_text ,
          startPos:item.startPos,endPos:item.endPos
        })
      }

      let allCurrentEntities;
      //TRAINED UTTERANCE
      if (isCurrentUtteranceTrained) {
        // utterance's entity that exist in deletedUtteranceEntities should BE REMOVED from globalUtteranceEntities
        // if utterance's entity that exists in luisObjState ,IF SAVED retrive them
        if (utterancesInLuisEditState.some(item => item.text === selectedUtterance.label)) {
          const utteranceOfInterest = utterancesInLuisEditState.find(item => item.text === selectedUtterance.label)
          allCurrentEntities = utteranceOfInterest.entities
        }
        else {
          // retrives api based state data , IF NOT SAVED YET, and add isEntityTrained as true
          allCurrentEntities = globalUtteranceEntities.map(item => formatEntityObj(item))
        }
      }

      //UNTRAINED UTTERANCE
      if (!isCurrentUtteranceTrained) {
        if (utterancesInLuisAddState.some(item => item.text === selectedUtterance.label)) {
          const utteranceOfInterest = utterancesInLuisAddState.find(item => item.text === selectedUtterance.label)
          allCurrentEntities = utteranceOfInterest.entities
        }
        else {
          // retrives api based state data , IF NOT SAVED YET, and add isEntityTrained as true
          allCurrentEntities = globalUtteranceEntities.map(item => formatEntityObj(item))
        }
      }

      setUtteranceEntitiesInfo({ ...utteranceEntitiesInfo,utteranceEntities:allCurrentEntities});
}
  },[show,utterancesInLuisEditState,utterancesInLuisAddState,entitiesUtterances])

  useEffect(()=> {
    const formatEntitiesOptions = () => {
      const trainedEntities = trainedLuisEntitiesEntries.map((item) => ({
        value: item[0], label: item[0], isDisabled: false, isTrained: true,luisIndex:item[1]['luis_index']
      }))
  
      const untrainedEntites = untrainedEntitiesDataInAddState.map(entity => ({
        value: entity.name, label: entity.name, isDisabled: false, isTrained: false
      }) )
  
      const formattedOptions = [{
        label: 'TRAINED ENTITIES',
        options: trainedEntities,
      },
      {
        label: 'UNTRAINED ENTITIES',
        options: untrainedEntites,
      }]
  
      return formattedOptions
  
    }
  
    setGlobalEntitiesSelection(formatEntitiesOptions)
  
  },[untrainedEntitiesDataInAddState])

  return (
    < >
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter" className={classes.TitleContainer}>
            Entity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <CreatableSelect
            onChange={handleEntitySelect} 
            name="entity"
            options={globalEntitiesSelection}
            placeholder={"Select or Create Entity"}
        />

         <Card className={classes.annotatorCardContainer}>
         <TokenAnnotator
              tokens={selectedUtterance && selectedUtterance.label.split(" ")}
              value={utteranceEntitiesInfo.utteranceEntities}
              onChange={(v)=> {handleUtteranceTextSelect(v)}}
              getSpan={(span) => ({
                ...span,
                tag: utteranceEntitiesInfo.entitySelected.name,
              })}
              renderMark={(props) => (
                  <mark
                    key={props.key}
                    className={classes.utteranceStyles}
                    >
                    {props.content}
                  <span>
                    <span className={checkForTrainedEntity(props.tag) ? classes.trainedEntity : classes.untrainedEntity}>
                <span className={classes.entityContainer}>
                    <span 
                    className={classes.entityStyles}
                    
                    >
                          {props.tag}
                      </span>
                      <span
                        className={classes.entityDelete}
                        onClick={(e) =>{
                          e.stopPropagation()
                          handleRemoveEntityFromUtterance(props)}}> &times;</span>
                    </span>
                </span>
                </span>
                  </mark>
                
              )}
            />
         </Card>
        </Modal.Body>
        <span className={classes.actionsContainer}>
          <span className={classes.actionsIcon}><Icon className={classes.infoIcon} data-tip={messages.entityColorCodeInfo} data-class="tooltip_cust" data-place="top" icon="carbon:information" /></span>
          <span className={classes.actionsText}>{messages.actionInfoForEntityAddition}</span>
        </span>
        <Modal.Footer>
            <Button type='close' onClick={handleClose} variant='light'>Discard</Button>    
            <Button type='add'   className={ `response-btn  ${!isActionEnabled && classes.buttonDisabled}`} disabled={!isActionEnabled} onClick={handleSave} variant='primary'>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default EntityPopover