/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useEffect, useState } from 'react';
import { Alert, Fade } from "react-bootstrap";

/** Importing custom Components */

/** Importing custom Styles */
import classes from './AlertDiv.module.css';

/** Main Components */
const AlertDiv = props => {

    // const [show, setShow] = useState(true);
    if (props.fadeout === true) {
        setTimeout( ()=>_handleOnDismiss(), 3000 );
    }

    const _handleOnDismiss = () => {
        // setShow(false);
        props.onHide();
    }

    var variant = "success";
    switch (props.type) {
        case 0:
            variant = "danger";
            break;
        case 1:
            variant = "success";
            break;
        case 2:
            variant = "warning";
            break;
    
        default:
            break;
    }
 
    return (
        // show &&
        <Alert className={ [ classes.Alert ].join(' ') } variant={ variant } onClose={ _handleOnDismiss }  dismissible={ true } transition={ Fade } >
            { props.message }
        </Alert>
    );
}

/** Exporting main component */
export default AlertDiv;