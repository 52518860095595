import get from 'lodash/get';
import { params } from '../data/params';

export var findInObjectResponse = (obj, str) => {
    var result = JSON.parse(JSON.stringify(obj));
    Object.keys(result).map(function (key) {
        /** Triming response as per the expectation */
        str = str.trim();
        if (typeof result[key] === "string") {
            result[key] = result[key].trim();
        }
        if ((typeof result[key] === "string") && (result[key] !== "") && (str !== "") && (result[key]===str)) {
            result[key] = true;
        } else if (result[key] !== undefined && result[key] != null && typeof result[key] === "object" && Object.keys(result[key]).length !== 0) {
            result[key] = findInObjectResponse(result[key], str);
            if (Object.keys(result[key]).length === 0) { delete result[key]; }
        } else { delete result[key]; }
    });
    return result;
};
export var polishResponse = (original, filtered, isGlobalIntent = false) => {
    if(isGlobalIntent){
        return _polishResponseGlobal(original, filtered);
    }
    var finalResponseNode = [];
    var finalResponseGlobal = {};
    var keyMapperObj = {};
    var counterNode = 0;
    var responseAddon = {};
    
    Object.entries(filtered[0]).forEach(([key, value]) => {
        if (key === "globalRule") {
            Object.keys(filtered[0][key]).forEach((k)=>{
                finalResponseGlobal[k]=original[0][key][k];
           });            
        } else {
            Object.keys(value).forEach(function (k){
                if(k === "generic"){
                    counterNode = _pushResponse(finalResponseNode, original[0][key][k], key, keyMapperObj, counterNode, false);  
                }else{
                    responseAddon[key] = {...responseAddon[key], ...{[k]:original[0][key][k]}};
                }
            });

            
        }
    });
    return [{ "response": {"responseNode": finalResponseNode, "responseGlobal": finalResponseGlobal, "responseAddon": responseAddon }, "keyMapper": { "responseNode": keyMapperObj }}];
};

function _pushResponse(finalNode, val, key, keyMapperObj, counter, isGlobal=false) {
    if(isGlobal){
        finalNode.push({[key]:val});
    }else{
        finalNode.push({
        genericResponse: val,
        node: key,
        priorityOfGeneric: val["priority"],
    });
    }
    keyMapperObj[key] = counter;
    counter = counter + 1;
    return counter;
}

function _polishResponseGlobal (original, filtered) {

    let path = '';
    let response = '';
    let index = -1;
    let finalData = {};

    Object.keys(filtered).forEach((key) => {
        let separator = params.callFlowSeparator
        path = key.split(separator);
        index = path.indexOf("rhs");
        if (index > -1) { path.splice(index);}
        response = get(original,path );
        let lastIntent = path[path.length-1];
        finalData[lastIntent] = {"response":response, "path":key};
        
    });
  return finalData;
};
