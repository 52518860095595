import axiosCall from "../../../utils/axiosCall";
import { setLoading, setAlert,setError, axiosErrorFunction, setSuccess } from "../common/commonActions";
import { SET_LOADING, SET_RULES_VARS, SET_RULES_VARS_MULTIPLE, SET_SUCCESS, SET_RESPONSE_MODAL_SHOW, SET_RULES_CHANGED, SET_RESPONSE_ACTION, SET_SELECTION_CHANGE, FREEZE_COLUMN_FILTER, SET_LOADERS, SET_SV_ROWS, SET_SELECTED_JSON, SET_CALL_FLOW_JSON, RULES_CALL_FLOW_UPDATED, SET_CALL_FLOW_MAPPINGS, SET_CALL_FLOW_ADDING_BLOCK, SET_CALL_FLOW_STEP_SETTINGS, NODE_RESPONSE_BLOCK_SHOW, SET_WARN_OVERWRITING, SET_UNSAVED_CHANGES, SET_CALLFLOW_DRAFT, SET_BULK_EDIT, SET_UTTERANCE_MODAL_SHOW, SET_SAMPLE_UTTERANCES, SET_UTTERANCE_SIDE_BAR, SET_SHOW_TESTPLANS, SET_SELECT_TESTPLAN,SET_TESTPLAN_LOADER, SET_TESTPLANS, SET_TESTREPORTS, TESTPLAN_RESET, SET_SAVE_AS_SELECTED, SET_JSON_NAME, SET_ALL_TESTPLANS, SET_SAVED_TESTPLAN_DATA, SET_TESTPLANSDATA_FROM_SAVED, SET_ENTITY_MODAL_SHOW, SET_ENTITIES, SET_FILTERED_RESPONSES, SET_BOT_RESPONSES, SET_FILTERING_RESPONSES_IN_PROGRESS, ADD_FILTERED_RESPONSES,SET_SELECTED_BLOCK_CALL_INFO , SET_NEW_NODE_INFO_SIDE_BAR_NEW,SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE,SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE,SET_DELETE_SAMPLE_UTTERANCE ,SET_UNTRAINED_LUIS_OBJ_ADD_INTENT
    ,SET_UNTRAINED_LUIS_OBJ_STATE,CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE,RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE,IS_INCOMING_LUIS_DATA_EXIST,IS_STORY_ID_MATCH_IN_LUIS_DATA, IS_INCOMING_LUIS_DATA_EMPTY_OBJ, EMPTY_FILTERED_RESPONSES} from "../types";

import {SET_STEP_COUNTER,SET_EXISTING_RULES_MODAL_SHOW,SET_ACCORDION_STATE,SET_STEP_SEQUENCE,RESET_UNTRAINED_LUIS_OBJ_STATE_DATA,SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD,SET_IS_NEW_NODE_CREATED,REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE,SET_UNTRAINED_LUIS_OBJ_STORY_TITLE,SET_TRAINED_LUIS_ENTITIES,SET_ADD_UNTRAINED_ENTITY,SET_ADD_UTTERANCE_ENTITIES,SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA,SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA,SET_UNTRAINED_LUIS_OBJ_USERNAME,SET_ENTITIES_UTTERANCES,SET_AI_VERSION, HIDE_START_TRAINING_BUTTON}  from "../types";
import { importableProperties } from "../../../utils/luisAppUtils";
import { setTotalDataCount } from "../luis-app/luisAppActions";

import store from "../../store";
import { getCookie } from '../../../utils/utils';
import { checkIfnewDataExistsInOldAndMap, getChangedRuleJson } from "../../../utils/rulesUtils";
import pusherCall from '../../../utils/pusherUtils';
import { updateCallFlowJSONResponse, updateCallFlowJSONNode, updateResponseConfigType, updateResponseStatementType, resetCallFlowMapping, addStepCallFlowMapping, recreateCallFlowMappingByRules, syncCallFlowWithRuleResponses, getLastStepAndAccordionStates,  deletionRestricted, callFlowIterator, rulesJsonChecker, audioClipNotPresentInStep, encodeJson } from "../../../utils/callFlowUtils";
import { messages } from "../../../data/strings";
import { params } from "../../../data/params";
import { toUnicode } from 'punycode'; 
import { type } from "os";
import getApiUrl from "../../../api-urls/apiUrlsUtils";
import { aiBase, aiUrls,aiBaseNew, apiUrls } from "../../../api-urls/luis-app-apis";
import { incrementStep } from "../ai-app/aiAppActions";
import { untrainedLuisObjInitialData } from "../../reducers/rules/rulesReducer";



/** UI and modal states start */

/**
 * To show/hide response modal and maintain state throughout
 * @param {boolean} show show or hide modal
 */
export const showResponseModal = (show, showIntent=false, selectedStorySetting=null) => dispatch => {

    var payload = { 
        show: show,
        showIntent: showIntent,
        selectedStorySetting: selectedStorySetting
    };


    dispatch({
        type: SET_RESPONSE_MODAL_SHOW,
        payload: payload
    });
}

/** UI and modal states end */

export const getResponses = (storyId, baseOnly=false, page = 1, totalResponses = {}) => dispatch => {
    var currentAccountId = getCookie("account_id");
    if (baseOnly) {
        dispatch({
            type: SET_LOADING
        });
        if (baseOnly) {
            var url = 'rules/search-bot-responses/' + storyId + '/story/' + page;
        } else {
            var url = 'rules/get-bot-responses/' + storyId;
        }
        const axiosSuccessFunction = (response) => {
            /** Handle success case */
            if (response.data) {
                /** Setting bot responses */
                totalResponses = {...totalResponses, ...response.data.responses}
                if(response.data.total_pages > page){
                    dispatch(getResponses(storyId, baseOnly, page+1, totalResponses))
                }else{
                    let payload = {}
                    payload = { 
                        responses: totalResponses,
                        baseOnly: baseOnly
                    }
                    dispatch({
                        type: SET_BOT_RESPONSES,
                        payload: payload
                    });
                }
            }
        };
        
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction, {accountid: currentAccountId});
    }
};


export const getResponsesFiltered = (storyId, searchedData, fullData = false, mode = false) => dispatch => {
    var currentAccountId = getCookie("account_id");

    dispatch({
        type: SET_FILTERING_RESPONSES_IN_PROGRESS
    });
    var url = 'rules/search-bot-responses/' + storyId + "/keyword/1";
    if(mode){
        url = 'rules/search-bot-responses/' + storyId + "/keyword/guided-prompts/1";
    }
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        
        if (response.data) {
            /** Setting bot responses */
            console.log("value of responses",response)
            payload = { responses: response.data.botutterancesObj, fullData: fullData }
            dispatch({
                type: SET_FILTERED_RESPONSES,
                payload: payload
            });
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, {keyword: searchedData.keyword,tagCollection: searchedData.tagCollection, tag: searchedData.tag, fullData: fullData, accountid: currentAccountId}, "POST");
};

export const emptyFilteredResponses = () => dispatch => {
    dispatch({
        type: EMPTY_FILTERED_RESPONSES
    });
}

export const addResponsesFiltered = (storyId, keyword, page = 0, mode) => dispatch => {
    var currentAccountId = getCookie("account_id");
    dispatch({
        type: SET_FILTERING_RESPONSES_IN_PROGRESS
    });

    var url = 'rules/search-bot-responses/' + storyId + "/keyword/" + page;
    if(mode){
        url = 'rules/search-bot-responses/' + storyId + "/keyword/guided-prompts/" + page;
    }
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        
        if (response.data) {
            if (response.data.count == 0) {
                page = 0;
            }
            /** Setting bot responses */
            payload = { responses: response.data.botutterancesObj, page: page }
            dispatch({
                type: ADD_FILTERED_RESPONSES,
                payload: payload
            });
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, {keyword: keyword, accountid: currentAccountId}, "POST");
};

export const getRules = (storyId, selectedJsonId = null) => dispatch => {

    dispatch({
        type: SET_LOADING
    });
    
    let payload = { key: "preloader", value: true }
    dispatch({
        type: SET_RULES_VARS,
        payload: payload
    });

    var url = 'rules/get-rule-json/' + storyId;
    if(selectedJsonId != null){

        if(selectedJsonId != 0 && selectedJsonId != 1){
            url += '?type=2'+'&restorePointId='+selectedJsonId
        }
        else url += '?type='+selectedJsonId
    }

    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        var state = store.getState(); 
        var previousJsons = [ ...state.rules.previousJsons ];   
        

        if (response.data && response.data.stringifiedJson) {
            var rulesJson = JSON.parse(response.data.stringifiedJson);
            var statements = {};
            if (rulesJson.statements) {
                statements = rulesJson.statements
                delete rulesJson.statements;
            }
            /** Setting story rule id */
            payload = { key: "storyRuleId", value: response.data.storyRuleId }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting stringified json */
            payload = { key: "stringifiedJson", value: JSON.stringify(rulesJson) }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting rule json in json format */
            payload = { key: "rulesJson", value: rulesJson };
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting statements for retaining */
            payload = { key: "statements", value: statements };
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });

            /** set if warning message of overwriting to be shown or not */
            if (response.data && response.data.warnOverwriting){
                dispatch(setWarnOverwriting(response.data.warnOverwriting));
            } else {
                dispatch(setWarnOverwriting(false));
            }

            if(response.data.restorePoints && Object.keys(response.data.restorePoints).length > 0){
                var selectedJson = {};

                for (const [key, value] of Object.entries(response.data.restorePoints)) {
                    previousJsons.push({'label': value, 'value': key});
                }
                /** Setting previous json versions */
                payload = { key: "previousJsons", value: previousJsons }
                dispatch({
                    type: SET_RULES_VARS,
                    payload: payload
                });
            }

            if(response.data.type == global.CUST_CONSTANTS.JSON_TYPE.RESTORE_POINT.value){
                selectedJson = previousJsons.find((ele) => ele.value == response.data.storyRuleId);
            }
            else if(response.data.type == global.CUST_CONSTANTS.JSON_TYPE.PRODUCTION.value || response.data.type == global.CUST_CONSTANTS.JSON_TYPE.STAGING.value){
                selectedJson = previousJsons.find((ele) => ele.value == response.data.type);
            }

            /** Setting selected json version */
            dispatch({
                type: SET_SELECTED_JSON,
                payload: { selectedJson: selectedJson }
            });

            /** Setting call flow json state */
            dispatch(getCallflowJson(storyId, response.data.storyRuleId));
            if(selectedJsonId != null) {
                dispatch(setIsChanged(1));
                dispatch(setSelectedRule());
            }

            
        } else {
            // show error
        }

        /** Setting isDraftInDb */
        var isDraftInDb = true;
        if (response.data && ("isDraft" in response.data)) {
            if ((!response.data.isDraft) || (response.data.isDraft === 0) || (response.data.isDraft === "0")) {
                isDraftInDb = false;
            }
        }
        payload = { key: "isDraftInDb", value: isDraftInDb }
        dispatch({
            type: SET_RULES_VARS,
            payload: payload
        });
    };
    
    const axiosErrorGetRuleFunction = (error) => {
        // handle success
        console.log("DEBUGGER:: error ::  error", error);
        if(error.response.data.code == 404){
            dispatch(setAlert(messages.errorInFetchingRules, 0));
            /** Removing preloader */
            var payload = { key: "preloader", value: false }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
        }
        else {
            dispatch(setAlert(error.response.data.message, 0));
        }    
    };

    axiosCall(url, axiosSuccessFunction, axiosErrorGetRuleFunction);
};

export const getCallflowJson = (storyId, ruleId, resetToDefault=false) => dispatch => {
    var state = store.getState(); 
    var detailedVsSimpleViewIndexesMapper = state.rules.detailedVsSimpleViewIndexesMapper;
    dispatch({
        type: SET_LOADING
    });
    var url = 'rules/callflow-json/' + storyId + "/" + ruleId;
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        
        if (response.data && response.data.stringifiedJson) {
            var callFlowJSON = JSON.parse(response.data.stringifiedJson);
            var callConfig = JSON.parse(response.data.callConfig);
            var responseMappings = {};
            if (callConfig.responseMappings) {
                responseMappings = callConfig.responseMappings;
            }
             
            const callFlowMetaData = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA;
            // Adding main-sequence if not present
            if(!(callFlowJSON && callFlowJSON[callFlowMetaData] && callFlowJSON[callFlowMetaData]['main-sequence'])){
                callFlowJSON[callFlowMetaData] = {
                    ['main-sequence']: callFlowJSON.callPlan && Object.keys(callFlowJSON.callPlan).length > 0 ?  Object.keys(callFlowJSON.callPlan) : [],
                    ...callFlowJSON[callFlowMetaData]
                }
            }
            
            // Finding the last step for step counter and adding accordion state
            const {latestStep, accordionStates} = getLastStepAndAccordionStates(callFlowJSON);
            dispatch(setAccordionState(accordionStates, true)); 
            dispatch(setStepCounter(latestStep))
            
            /** Removing preloader */
            var payload = { key: "preloader", value: false }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting call flow json state */
            dispatch(setCallFlowJson(callFlowJSON, response.data.isDraft, false, true));
            

            /** Setting call flow mapping state */
            dispatch(setCallFlowMapping(responseMappings));
            dispatch(setStepSequence([...callFlowJSON['metadata']['main-sequence']]))
        } else {
            // show error
        }
    };

    let paramData = {};
    if (resetToDefault) {
        paramData = {
            "reset": 1
        }
    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, paramData);
};

export const setStepCounter = (value = 0) => dispatch =>{
    dispatch({
        type: SET_STEP_COUNTER,
        payload: value
    })
}

export const changeRuleResponse = (newRuleResponse, actionLevel = null) => dispatch => {

    // mark conditions here
    var state = store.getState(); 
    var newRules = { ...state.rules.rulesJson };
    var isTagRule = (state.rules.selectedNode.scenario) ? true : false;
    var node = state.rules.selectedNode.node;

    if (isTagRule) {
        var scenario = state.rules.selectedNode.scenario;
        newRules.ruleResponses.tagRule.scenarioRule[scenario][node] = newRuleResponse;
    } else {
        newRules.ruleResponses[node] = newRuleResponse;
    }

    var payload = {}

    dispatch(setRules(newRules, actionLevel));
    
    // /** Setting rulesJson */
    // payload = { key: "rulesJson", value: newRules }
    // dispatch({
    //     type: SET_RULES_VARS,
    //     payload: payload
    // });
    // dispatch(initTimer());
};

/**
 * function to choose sleected rule
 * @param {*} node default null : node selected
 * @param {*} selectedRule default null
 * @param {*} isMetadata default false
 * @param {*} scenario default null
 * @param {*} type default null
 * @param {*} pathsData default []
 * @param {*} action default null
 * @param {*} actionNewValue default null
 * @param {*} refreshPathsData default false
 * @returns 
 */
export const setSelectedRule = (node=null, selectedRule=null, isMetadata=false, scenario=null, type=null, pathsData=[], action = null, actionNewValue=null, refreshPathsData = false) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    /** Handle success case */
    var payload = {}
    var state = null;
    var latestRules = null;

    if(node == null) {
        state = store.getState();
        node = state.rules.selectedNode.node;
        isMetadata = state.rules.selectedNode.isMetadata;
    }
    
    if (node && (selectedRule === null)) {
        if(state == null) {
            state = store.getState();
        } 
        if (latestRules == null) {
            latestRules = state.rules.rulesJson;
        }
        
        if (isMetadata) {
            if (latestRules.metadata) {
                selectedRule = latestRules.metadata[node];
                refreshPathsData = true;
            }
        } else {
            selectedRule = latestRules.ruleResponses[node];
            refreshPathsData = true;
        }
    }

    if ((type == null) && (!isMetadata)) { 
        if (state == null) {
            state = store.getState();
        }
        if (latestRules == null) {
            latestRules = state.rules.rulesJson;
        }
        if (latestRules.rule[node]) {
            type = latestRules.rule[node].type;
        } else {
            type = 1;
        }
    }

    if (node != null) {
        if (state == null) {
            state = store.getState();
        }
        if ((refreshPathsData) || (pathsData == null) || ((pathsData != null) && (pathsData.length === 0))) {
            if ((!refreshPathsData) && (state.rules.selectedNode.pathsData.length > 0) && (state.rules.selectedNode.pathsData[0].nodeKey === node)) {
                pathsData = state.rules.selectedNode.pathsData;
            } else {
                if (selectedRule != null) {
                    Object.keys(state.rules.detailedVsSimpleViewIndexesMapper).forEach(function (keyName) {
                        if(keyName.includes(node)) {
                            var responseIndex = state.rules.detailedVsSimpleViewIndexesMapper[keyName].responseIndex;
                            var tempPathsData = [];
                            if (
                                state.rules.simpleViewRows && 
                                Array.isArray(state.rules.simpleViewRows) && 
                                state.rules.simpleViewRows[responseIndex]
                            ) {
                                if (
                                    state.rules.simpleViewRows[responseIndex].original &&
                                    state.rules.simpleViewRows[responseIndex].original.pathsData
                                ) {
                                    tempPathsData = state.rules.simpleViewRows[responseIndex].original.pathsData;
                                } else if (
                                    state.rules.simpleViewRows[responseIndex] &&
                                    state.rules.simpleViewRows[responseIndex].pathsData
                                ) {
                                    tempPathsData = state.rules.simpleViewRows[responseIndex].pathsData;
                                }
                            }
                            if (Array.isArray(tempPathsData)) {
                                pathsData = [ ...pathsData, ...tempPathsData];
                            }
                        }
                    });
                }
            }
        }
        if ((action == null) && (state.rules.selectedNode.action != null)) {
            action = state.rules.selectedNode.action;
        }

        /** Checking if rules present against node */
        if (
            (action === global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK) && 
            !isMetadata && 
            state && 
            state.rules && 
            state.rules.rulesJson && 
            state.rules.rulesJson.rule && 
            (scenario === null) &&
            (node !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE) && 
            (state.rules.rulesJson.rule) && 
            (state.rules.rulesJson.rule[node] === undefined)
        ) {
            dispatch(setAlert(messages.invalidNode));
        }
    }
    
    var selectedNode = {
        node: node,
        type: type,
        responseJson: {},
        isMetadata: isMetadata,
        scenario: scenario,
        isSelectedFromJsonView: true,
        pathsData: (pathsData) ? [...pathsData] : [],
        action: action,
        actionNewValue: actionNewValue
    };
    if (node) {
        if (selectedRule) {
            selectedNode.responseJson = { ...selectedRule };
        }

        if (node == global.CUST_CONSTANTS.SAMPLE_NODEKEY) {
            dispatch(setAlert(messages.sampleNodeMessage, 2));
        }
        window.scrollTo(0, 0);
    } else {
        selectedNode = null;
    }

    dispatch(setSelectionChange(selectedNode));
};


export const changeNodeAction = (action, actionNewValue, selectedNode={}) => dispatch => {
    var state = null;
    if (selectedNode.node == undefined) {
        state = store.getState();
        selectedNode = state.rules.selectedNode;
    }

    selectedNode["action"] = action;
    selectedNode["actionNewValue"] = actionNewValue;

    dispatch(setSelectionChange(selectedNode));
}

export const setSelectionChange = (selectedNode) => dispatch => {
    var payload = { selectedNode: selectedNode };
    dispatch({
        type: SET_SELECTION_CHANGE,
        payload: payload
    });
}

//
export const setResponseAction = (responseActionJson, selectedId = -1) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    /** Handle success case */
    var payload = {}
    
    if (responseActionJson) {
        /** Setting response action */
        dispatch({
            type: SET_RESPONSE_ACTION,
            payload: { responseActionJson: responseActionJson }
        });
    } else {
        if ((typeof(selectedId) === 'number' &&  selectedId > -1) || (selectedId && typeof(selectedId) !== 'number')) {
            /** resetting response Action */
            dispatch({
                type: SET_RESPONSE_ACTION,
                payload: { selectedId: selectedId }
            });
        } else {
            /** resetting response Action */
            dispatch({
                type: SET_RESPONSE_ACTION,
                payload: { responseActionJson: null }
            });
        }
    }
};

export const removeResponse = () => dispatch => {
    var state = store.getState(); 
    var newRules = { ...state.rules.rulesJson };
    var responseAction = state.rules.responseAction;
    var toDelete = true;
    ;
    if (responseAction.referencePaths && (responseAction.referencePaths.length > 0)) {
        ;
        let deleteRestricted = false
        deleteRestricted = deletionRestricted({selectedNode: {pathsData: responseAction.referencePaths}, callFlowJSON: state.rules.callFlow.callFlowJSON, accordionState: state.rules.accordionState, callFlowMappings: state.rules.callFlowResponseMappings });
        if(!deleteRestricted){
            var data = getChangedRuleJson(state.rules.rulesJson, responseAction.referencePaths, global.CUST_CONSTANTS.CRUD_MODE.DELETE);
            toDelete = data.toDelete;
            newRules = { ...data.newRulesJson };
        
            if (toDelete) {
                dispatch(setRules(newRules, global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE));
            } else {
                dispatch(setAlert(messages.switchInvalid));
            }
        }else{
            dispatch(setAlert(messages.deleteRestricted));
        }
    }
};

export const removeIntent = (intent) => dispatch => {
    var state = store.getState(); 
    var newRules = { ...state.rules.rulesJson };
    var todelete = intent;

    var loadersPayloads = [{
        view: "simpleView",
        key: "index_" + todelete,
    }];

    dispatch(setLoaders(loadersPayloads));

    let deleteRestricted = false;

    deleteRestricted = deletionRestricted({selectedNode: state.rules.selectedNode, callFlowJSON: state.rules.callFlow.callFlowJSON, accordionState: state.rules.accordionState, callFlowMappings: state.rules.callFlowResponseMappings})

    if(!deleteRestricted){
        if (newRules.rule && newRules.rule.hasOwnProperty(todelete)) {
            delete newRules.rule[todelete];
        }
    
        if (newRules.ruleResponses && newRules.ruleResponses.hasOwnProperty(todelete)) {
            delete newRules.ruleResponses[todelete];
        }
    
        if(newRules.metadata && newRules.metadata.hasOwnProperty(todelete)){
            delete newRules.metadata[todelete];
        }
        /** setting node action change made */
        dispatch(changeNodeAction(global.CUST_CONSTANTS.CRUD_MODE.DELETE, todelete));
    
        /** Setting rulesJson */
        dispatch(setRules(newRules, global.CUST_CONSTANTS.ACTION_LEVEL.NODE));
    }else{
        dispatch(setAlert(messages.deleteRestricted));
    }


        
};

export const editIntent = (newIntent) => dispatch => {
    var state = store.getState(); 
    var newRules = { ...state.rules.rulesJson };
    var toEditIntent = state.rules.selectedNode.node;
    var loadersPayloads = [{
        view: "simpleView",
        key: "index_" + toEditIntent,
    }];

    dispatch(setLoaders(loadersPayloads));

    if(newRules.ruleResponses && newRules.ruleResponses.hasOwnProperty(newIntent)){
        delete newRules.ruleResponses[newIntent];
    }
    
    /* getting indexes where key will be replaced */
    let jsonKeys = Object.keys(newRules.rule);
    let toEditIndex = jsonKeys.indexOf(toEditIntent);

    let rules = Object.entries(newRules.rule);
    let responses = Object.entries(newRules.ruleResponses);

    /* replacing key in rule part and in CurrentIntentAttempted rule*/
    rules[toEditIndex][0] = newIntent;
    if(rules[toEditIndex][1]["rule"]["rhs"] === undefined){
        rules[toEditIndex][1]["rule"]["intent_compare"]["rhs"] = rules[toEditIndex][1]["rule"]["intent_compare"]["rhs"].replace(toEditIntent,newIntent);
    }
    else {
        rules[toEditIndex][1]["rule"]["rhs"] = rules[toEditIndex][1]["rule"]["rhs"].replace(toEditIntent,newIntent);
    }

    jsonKeys = Object.keys(newRules.ruleResponses);
    toEditIndex = jsonKeys.indexOf(toEditIntent);
    
    /* replacing key in responses part */
    responses[toEditIndex][0] = newIntent;

    dispatch(changeNodeAction(global.CUST_CONSTANTS.CRUD_MODE.EDIT, newIntent));

    /* assigning the changes to original object */
    newRules.rule = Object.fromEntries(rules);
    newRules.ruleResponses = Object.fromEntries(responses);

    // dispatch(setResponseAction(tempResponseAction));

    /* Setting rulesJson */
    dispatch(setRules(newRules, global.CUST_CONSTANTS.ACTION_LEVEL.NODE));
    
};

export const getIntents = (luisAppDbId) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    
    let totalIntentData= {};
    let initialPageNumber = 1
   
    const getIntentsRecursively = ({luisAppDbId,pageNumber}) => {
        const axiosInitialSuccessCall = (response) => {
            if (response.data) {
                if (response.data.total_pages > pageNumber) {
                    getIntentsRecursively({ luisAppDbId, pageNumber: pageNumber + 1 });
                }
                if (response.data.total_pages >= pageNumber) {
                    totalIntentData = { ...totalIntentData, ...response.data.intent_list }
                }
                if (response.data.total_pages === pageNumber) {
                    let payload = { key: "intents", value: totalIntentData }

                    dispatch({
                        type: SET_RULES_VARS,
                        payload: payload
                    });

                    dispatch(setTotalDataCount({key:importableProperties.INTENTS,value: response.data.intent_count && response.data.intent_count}))
                    
                }
            }
      }
      axiosCall(getApiUrl(aiBase, aiUrls.GET_INTENTS, {luisAppDbId:luisAppDbId ,pageNumber }), axiosInitialSuccessCall, axiosErrorFunction);
    }

    getIntentsRecursively({luisAppDbId,pageNumber:initialPageNumber})
};
   
/**
 * Player states :
 * 0 - ended (initial state)
 * 1 - buffering
 * 2 - playing
 * 3 - paused
 */

/** Get signed url for resource to load in audio element of BotClip Component */
export const handlePlayPause = (playerState, playerId=null, filepath = null) => dispatch => {
    /** First time the recorder is played */
    if (playerId === null) {
        dispatch({
            type: SET_RULES_VARS,
            payload: { key: "playerState", value: playerState }
        });
    } else {
        dispatch({
            type: SET_RULES_VARS_MULTIPLE,
            payload:{ 
                "playerState": playerState,
                "playerId":playerId
            }
        }); 
    }
    /** When audio clip has ended reset audioClip and playerId */
    if (playerState === 0) {
        dispatch({
            type: SET_RULES_VARS_MULTIPLE,
            payload:{ 
                "audioclipSource": null,
                "playerState": playerState,
                "playerId": ""
            }
        }); 
    } else {
        /** filepath is not null when fresh clip plays
         *  if same clip calls this function filepath will be empty 
         */
        if (filepath !== null) {
            var url = 'workbench/get-s3-signed-url' ;
            const axiosSuccessFunction = (response) => {
                if (response.data) {
                    dispatch({
                        type: SET_RULES_VARS_MULTIPLE,
                        payload:{ 
                            "audioclipSource": response.data,
                            "playerId":playerId
                        }
                    });
                }
            };
            /** get signed url */
            axiosCall(url, axiosSuccessFunction, axiosErrorFunction, {'object': filepath }, 'POST');
        }
    }
}

export const setRules = (rulesJson, actionLevel=null) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    dispatch({
        type: SET_RULES_CHANGED,
        payload: 1
    });
    /** Handle success case */
    var payload = {}
    
    /** Setting json */
    payload = { key: "rulesJson", value: rulesJson }
    dispatch({
        type: SET_RULES_VARS,
        payload: payload
    });

    /** Setting stringified json */
    payload = { key: "stringifiedJson", value: JSON.stringify(rulesJson) }
    dispatch({
        type: SET_RULES_VARS,
        payload: payload
    });
    var state = store.getState(); 
    var nodeResponsesResult = {
        callFlowJson: null,
        callFlowMappings: null
    };

    switch (actionLevel) {
        case global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE:
            nodeResponsesResult = updateCallFlowJSONResponse(rulesJson, state.rules.callFlow.callFlowJSON, state.rules.callFlowResponseMappings, state.rules.responseAction, state.rules.botResponses, state.rules.bulkEdit, state.rules.stepCounter);
            break;
        case global.CUST_CONSTANTS.ACTION_LEVEL.NODE:
            nodeResponsesResult = updateCallFlowJSONNode(rulesJson, state.rules.callFlow.callFlowJSON, state.rules.callFlowResponseMappings, state.rules.selectedNode, state.rules.botResponses, state.rules.stepCounter);
            break;
        case global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE_TYPE:
        case global.CUST_CONSTANTS.ACTION_LEVEL.ITERATION_TYPE:
            nodeResponsesResult = updateResponseConfigType(state.rules.callFlowResponseMappings, state.rules.selectedNode, state.rules.botResponses, state.rules.callFlow.callFlowJSON);
            break;
        case global.CUST_CONSTANTS.ACTION_LEVEL.STATEMENT_TYPE:
            nodeResponsesResult = updateResponseStatementType(state.rules.callFlowResponseMappings, state.rules.callFlow.callFlowJSON, state.rules.selectedNode);
            break;
    
        default:
            break;
    }
    var callFlowChanged = false;
    if (nodeResponsesResult.callFlowJson) {
        const { latestStep, accordionStates } = getLastStepAndAccordionStates(nodeResponsesResult.callFlowJson) 
        dispatch(setStepCounter(latestStep));
        console.log('nodeResponsesResult in setRules', nodeResponsesResult.callFlowJson)
        /** Setting call flow json state */
        const callFlowMainSequence = [...nodeResponsesResult.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']]
        dispatch(setCallFlowJson(nodeResponsesResult.callFlowJson));
        dispatch(setStepSequence(callFlowMainSequence))
        dispatch(setAccordionState(accordionStates, true));
        callFlowChanged = true;
    }
    if (nodeResponsesResult.callFlowMappings) {
        /** Setting call flow mapping state */
        dispatch(setCallFlowMapping(nodeResponsesResult.callFlowMappings));
        callFlowChanged = true;
    }
    
    if (callFlowChanged) {
        /** Tell call flow to update UI */
        dispatch(setRulesAndCallFlowUpdatedState(3));
    }

    /** Start timer for autosave call */
    dispatch(initTimer());
    dispatch(setSelectedRule(null, null, false, null, null, [], null, null, true));
};

export const setSaveAsSelected = (opt) => dispatch => {
    dispatch({
        type: SET_SAVE_AS_SELECTED,
        payload: opt
    })
}

export const setJsonName = (val) => dispatch =>{
    dispatch({
        type: SET_JSON_NAME,
        payload: val
    })
}

export const saveRulesToDb = (isDraft=false, forceSave=0, type=0, name=null) => dispatch => {
    /** Clearing timer if saveRulesToDb is already called */
    dispatch(clearTimer());
    var rulesJson = {};
    var storyId=0;
    dispatch({
        type: SET_LOADING
    });
    var state = store.getState(); 
    rulesJson = state.rules.rulesJson;
    storyId = state.story.storyId;
    
    var rulesAndCallFlowUpdated = state.rules.ruleAndCallFlowUpdated;
    var callFlowJSON = {};
    var callFlowIsDraft = false;
    let warnOverwriting = state.rules.warnOverwriting;
    callFlowJSON = state.rules.callFlow.callFlowJSON;
    if(rulesAndCallFlowUpdated > 1 && isDraft) { 
        callFlowIsDraft = state.rules.callFlow.callFlowIsDraft;
    }
    if(!isDraft){
        var audioClipNotPresent = callFlowIterator({callFlow: callFlowJSON, cb: audioClipNotPresentInStep}) || rulesJsonChecker({rulesJson: rulesJson})
        callFlowIsDraft = false
        rulesAndCallFlowUpdated = 3;
        if(audioClipNotPresent){
            callFlowIsDraft = true;
            isDraft = true
        }
    }

    let callFlowResponseMappings = {};
    if (state.rules.callFlowResponseMappings) {
        callFlowResponseMappings = state.rules.callFlowResponseMappings;
    }

    var callConfiguration = {};
    callConfiguration["responseMappings"] = callFlowResponseMappings;
    
    var newRulesJson = {
        "version": rulesJson.version,
        "rule": { ...rulesJson.rule },
        "ruleResponses": { ...rulesJson.ruleResponses },
        "statements": { ...state.rules.statements }
    }
    if (rulesJson.metadata) {
        newRulesJson["metadata"] = { ...rulesJson.metadata };
    }

    if (newRulesJson.rule[global.CUST_CONSTANTS.SAMPLE_NODEKEY]) {
        delete newRulesJson.rule[global.CUST_CONSTANTS.SAMPLE_NODEKEY];
    }

    if (newRulesJson.ruleResponses[global.CUST_CONSTANTS.SAMPLE_NODEKEY]) {
        delete newRulesJson.ruleResponses[global.CUST_CONSTANTS.SAMPLE_NODEKEY];
    }
    

    var url = 'rules/post-rule-json/' + storyId;
    var callFlowJSONurl = 'rules/callflow-json/' + storyId;
    
    const axiosSaveCallFlowJSONSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        if (response.data.stringifiedJson) {
            var callFlowJSON = JSON.parse(response.data.stringifiedJson);
            /** Setting call flow mapping json */
            var callConfig = JSON.parse(response.data.callConfig);
            var responseMappings = {};
            if (callConfig.responseMappings) {
                responseMappings = callConfig.responseMappings;
            }
            const callFlowMetaData = global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA;
            // Adding main-sequence if not present
            if(!(callFlowJSON && callFlowJSON[callFlowMetaData] && callFlowJSON[callFlowMetaData]['main-sequence'])){
                callFlowJSON[callFlowMetaData] = {
                    ['main-sequence']: callFlowJSON.callPlan && Object.keys(callFlowJSON.callPlan).length > 0 ?  Object.keys(callFlowJSON.callPlan) : [],
                    ...callFlowJSON[callFlowMetaData]
                }
            }
            /** Recreate mapping after saving the call flow */
            // if (newRulesJson && callFlowJSON && !isDraft) {
            //     let callFlowObjs = recreateCallFlowMappingByRules({rulesJson: newRulesJson, callFlowJson: callFlowJSON});
            //     responseMappings = callFlowObjs.callFlowResponseMappings;
            //     callFlowJSON = callFlowObjs.callFlowJSON;
            // }
            

            /** Setting call flow json */
            dispatch(setCallFlowJson(callFlowJSON, response.data.isDraft, false, true));
            /** Setting call flow mapping json */
            dispatch(setCallFlowMapping(responseMappings));
            dispatch(setRulesAndCallFlowUpdatedState(0));
    
            if (!response.data.isDraft) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: response.message }
                });

                // Finding accordion state
                const {accordionStates} = getLastStepAndAccordionStates(callFlowJSON);
                dispatch(setAccordionState(accordionStates, true)); 
                dispatch(setStepSequence([...callFlowJSON['metadata']['main-sequence']]));
                
            }
            if (response.message){
                if(response.message.indexOf("corrupted") > 0){
                    response.message = response.message.split('.')
                    dispatch(setAlert(response.message[0]));
                }
            }
        }
         /** Setting isCallFlowDraftInDb */
         var isCallFlowDraftInDb = true;
         if (response.data && ("isDraft" in response.data)) {
             if ((!response.data.isDraft) || (response.data.isDraft === 0) || (response.data.isDraft === "0")) {
                isCallFlowDraftInDb = false;
             }
         }
         payload = { key: "isCallFlowDraftInDb", value: isCallFlowDraftInDb }
         dispatch({
             type: SET_RULES_VARS,
             payload: payload
         });
    };
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        
        if (response.data.stringifiedJson) {
            var rulesJson = JSON.parse(response.data.stringifiedJson);
            var statements = {};
            if (rulesJson.statements) {
                statements = rulesJson.statements
                delete rulesJson.statements;
            }
            /** Setting stringified json */
            payload = { key: "stringifiedJson", value: JSON.stringify(rulesJson) }
            // payload = { key: "stringifiedJson", value: response.data.stringifiedJson }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting rule json */
            payload = { key: "rulesJson", value: rulesJson }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
            /** Setting changed to true */
            dispatch({
                type: SET_RULES_CHANGED,
                payload: 1
            });
            if (!isDraft) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: response.message }
                });
            }

            /** set if warning message of overwriting to be shown or not */
            if (response.data && response.data.warnOverwriting){
                dispatch(setWarnOverwriting(response.data.warnOverwriting));
            } else {
                dispatch(setWarnOverwriting(false));
            }

            if (response.message){
                if(response.message.indexOf("corrupted") > 0){
                    response.message = response.message.split('.')
                    dispatch(setAlert(response.message[0]));
                }
            }

            if (!isDraft && params.autoSync.includes(2)) {
                let { newCallFlowJson, newCallFlowMapping, stepSequence, isChanged } = syncCallFlowWithRuleResponses({rulesJson: rulesJson, callFlowJson: callFlowJSON, botResponses: state.rules.botResponses, stepSequence: state.rules.stepSequence});
                if (isChanged) {
                    callFlowJSON = newCallFlowJson;
                    callConfiguration = {};
                    callConfiguration["responseMappings"] = newCallFlowMapping;
                }
                // dispatch(setStepSequence(stepSequence))
            }

            var previousJsons = [ ...state.rules.previousJsons ];
            var selectedJson = {
                "label": "",
                "value": ""
            };
            var changeJsonSelection = false;

            if(response.data.type == global.CUST_CONSTANTS.JSON_TYPE.RESTORE_POINT.value) {
                selectedJson = { "label": response.data.name, "value": response.data.storyRuleId };
                
                previousJsons.push({...selectedJson});
                /** Setting previous json versions */
                payload = { key: "previousJsons", value: previousJsons }
                dispatch({
                    type: SET_RULES_VARS,
                    payload: payload
                });
                changeJsonSelection = true;
            } else if(response.data.type == global.CUST_CONSTANTS.JSON_TYPE.PRODUCTION.value || response.data.type == global.CUST_CONSTANTS.JSON_TYPE.STAGING.value){
                selectedJson = previousJsons.find((ele) => ele.value == response.data.type);
                changeJsonSelection = true;
            }

            if (changeJsonSelection) {
                /** Setting selected json version */
                dispatch({
                    type: SET_SELECTED_JSON,
                    payload: { selectedJson: selectedJson }
                });
            }

            /** resetting loaders */
            dispatch(setLoaders());
        }
        /** Setting isDraftInDb */
        var isDraftInDb = true;
        if (response.data && ("isDraft" in response.data)) {
            if ((!response.data.isDraft) || (response.data.isDraft === 0) || (response.data.isDraft === "0")) {
                isDraftInDb = false;
            }
        }
        payload = { key: "isDraftInDb", value: isDraftInDb }
        dispatch({
            type: SET_RULES_VARS,
            payload: payload
        });
        payload = { key: "storyRuleId", value: response.data.storyRuleId }
        dispatch({
            type: SET_RULES_VARS,
            payload: payload
        });

        callFlowJSON = encodeJson(callFlowJSON)
        callConfiguration = encodeJson(callConfiguration)
        //when both rules json and call flow json is being saved
        axiosCall(callFlowJSONurl, axiosSaveCallFlowJSONSuccessFunction, axiosErrorFunction, { callFlowJSON: callFlowJSON, callFlowIsDraft: callFlowIsDraft, storyRuleId: response.data.storyRuleId, forceSave: forceSave, callConfiguration:  callConfiguration}, 'POST', 1);
    
    };

    const axiosErrorSaveRuleFunction = (error) => {
        // handle success
        console.log("DEBUGGER:: AXIOS ERROR FUNCTION error", error);
        if(error.response.data.code == 404){
            dispatch(setAlert(messages.errorInSavingRules, 0));
            /** Removing preloader */
            var payload = { key: "preloader", value: false }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
        }
        else {
            dispatch(setAlert(error.response.data.message, 0));
        }    
    };

    if(rulesAndCallFlowUpdated != 2 ) {
        if(audioClipNotPresent){
            dispatch(setAlert(messages.audioClipNotPresentStr))
        }
        const stringifiedJson = encodeJson(newRulesJson)
        axiosCall(url, axiosSuccessFunction, axiosErrorSaveRuleFunction, { stringifiedJson: stringifiedJson, isDraft: isDraft, forceSave: forceSave, type: type, name: name, warnOverwriting: warnOverwriting }, 'POST', 1);
    }
    /** only when call flow json is being saved */
    if(rulesAndCallFlowUpdated == 2) {
        var storyRuleId = state.rules.storyRuleId;
        callFlowJSON = encodeJson(callFlowJSON)
        callConfiguration = encodeJson(callConfiguration)
        axiosCall(callFlowJSONurl, axiosSaveCallFlowJSONSuccessFunction, axiosErrorFunction, { callFlowJSON: callFlowJSON, callFlowIsDraft: callFlowIsDraft, storyRuleId: storyRuleId, callConfiguration: callConfiguration }, 'POST', 1);
    }
    
};

export const saveUntrainedLuisObjToDb = (mode) => dispatch => {
    const state =  store.getState()
    

    let url = getApiUrl(aiBase, aiUrls.SAVE_LUIS_APP, {storyId:state.luis_app.appDbId});
    const axiosSuccessFunction = (response) => {
        if (response.success) {          
            dispatch({
                type: RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE,
                payload: false
            });
            if(mode === "ai"){
                dispatch(incrementStep());
            }
        }
    };
    const payload = {
        app_json:state.rules.untrainedLuisObj.data,
        story_id:state.story.storyId,
    }

    axiosCall(url,axiosSuccessFunction,axiosErrorFunction,payload,'POST')

}

// ation start training

export const startTrainingData = () => dispatch => {
    const state =  store.getState()
    

    let url = getApiUrl(aiBase, aiUrls.SAVE_LUIS_APP, {storyId:state.luis_app.appDbId});
    const axiosSuccessFunction = (response) => {
        if (response.success) {          
        //   dispatch({
        //     alert();
        //   }) 
        }
    };
    const payload = {
        app_json:untrainedLuisObjInitialData,
        story_id:state.story.storyId,
    }

    axiosCall(url,axiosSuccessFunction,axiosErrorFunction,payload,'POST')

}

export const deleteUntrainedLuisObjFromDb = () => dispatch => {
    const state =  store.getState()
    let url = getApiUrl(aiBaseNew, aiUrls.END_SESSION,  {luisAppDbId:state.luis_app.appDbId ,storyId:state.story.storyId });
    const axiosSuccessFunction = (response) => {
        if (response.success) {
            global.recordAudit("Untrained Training Data Deleted Manually by User");
            dispatch(setAlert(messages.deleteUntrainedLuisObjFromDb, 1));
            dispatch({
                type: RESET_UNTRAINED_LUIS_OBJ_STATE_DATA,
            });
            dispatch(changeHideStartTrainingBtn(false))
            dispatch(changeActionsForUntrainedLuisState(false));
        }
    };
 
    axiosCall(url,axiosSuccessFunction,axiosErrorFunction)
}

/** Adding auto save functionality */
var timer = null;
export const clearTimer = (resetUnsavedChanges = true) => dispatch => {
    if (timer) {
        clearInterval(timer);
    }
    if (resetUnsavedChanges) {
        dispatch(setUnsavedChanges(false));
    }
    window.stopFromUnload = false;
}


export const initTimer = () => dispatch => {
    var state = store.getState();
    dispatch(clearTimer(false));
    dispatch(setUnsavedChanges(true));
    timer = setTimeout(() => {
        dispatch(saveRulesToDb(true));
        
    if(state.rules.untrainedLuisObj.isDataChanged){
        dispatch(saveUntrainedLuisObjToDb())
    }

    }, ( params.autosaveTime * 1000 ));
    window.stopFromUnload = true;
}

export const changeRuleType = (type) => dispatch => {
    var state = store.getState(); 
    var newRules = { ...state.rules.rulesJson };
    var intent = state.rules.selectedNode.node;
    if (newRules['rule'][intent] && newRules['rule'][intent]['type']) {
        newRules['rule'][intent]['type'] = type;
        dispatch(changeNodeAction(global.CUST_CONSTANTS.ACTION_LEVEL.STATEMENT_TYPE, type));
        dispatch(setRules(newRules, state.rules.selectedNode.action));
    }   
};

/**
 * Function to get all tag collection and tags for a brand
 */
export const getTags = (accountId) => dispatch => {
    var url = 'common/tags'
    dispatch({
        type: SET_LOADING
    });
    
    if(accountId)
        url += '/' + accountId ;

    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        
        if (response.data) {
            /** Setting rule tags */
            payload = { key: "brandTags", value: response.data }
            dispatch({
                type: SET_RULES_VARS,
                payload: payload
            });
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
};

/**
 * Changes the selected view tab
 */
export const setViewType = (tab) => dispatch => {
    if (tab > -1) {
        /** Setting view tab */
        var payload = { key: "activeTab", value: tab }
        dispatch({
            type: SET_RULES_VARS,
            payload: payload
        });
    }
};

/**
 * To show/hide response modal and maintain state throughout
 * @param {boolean} show show or hide modal
 */
export const setIsChanged = (changed = 0) => dispatch => {
    dispatch({
        type: SET_RULES_CHANGED,
        payload: changed
    });
}

/**
 * To show/hide response modal and maintain state throughout
 * @param {boolean} show show or hide modal
 */
export const setFreezeColumnFilter = (freeze=false) => dispatch => {
    dispatch({
        type: FREEZE_COLUMN_FILTER,
        payload: freeze
    });
}

/**
 * To show loaders in different sections of Rules UI
 * @param {boolean} show show or hide modal
 */
export const setLoaders = (loadersPayloads = []) => dispatch => {
    if (loadersPayloads.length > 0) {
        loadersPayloads.forEach( payload => {
            dispatch({
                type: SET_LOADERS,
                payload: payload
            });
        });
    } else {
        dispatch({
            type: SET_LOADERS,
            payload: null
        });
    }
}

/**
 * To fill up rules detailed view and simple view mapping
 * @param {boolean} show show or hide modal
 */
export const setSimpleViewRows = (tableData = {}) => dispatch => {
    var payload = { key: "simpleViewRows", value: tableData }
    dispatch({
        type: SET_RULES_VARS,
        payload: payload
    });
}

/**
 * To fill up rules detailed view and simple view mapping
 * @param {boolean} show show or hide modal
 */
export const setDetailedVsSimpleViewIndexesMapper = (mappingObj = {}) => dispatch => {
    var payload = { key: "detailedVsSimpleViewIndexesMapper", value: mappingObj }
    dispatch({
        type: SET_RULES_VARS,
        payload: payload
    });
}

/**
 * To fill up rules detailed view and simple view mapping
 * @param {boolean} show show or hide modal
 */
export const setSimpleRows = (rows = []) => dispatch => {
    var payload = rows;
    if (typeof rows == "object") {
        payload = [ ...rows ];
    }
    dispatch({
        type: SET_SV_ROWS,
        payload: payload
    });
}

/**
 * To show/hide intent modal and maintain state throughout
 * @param {boolean} show show or hide modal
 */
export const setShowIntentModal = (showModal, mode ) => dispatch => {
    const payload = { "showIntentModal": showModal, "nodeAction": mode }

    dispatch({
        type : SET_RULES_VARS_MULTIPLE,
        payload: payload
    });

    dispatch(changeNodeAction(mode));
}

/** CAL FLOW METHODS */


/**
 * To save call flow json
 * @param {json} callFlowJSON 
 */
// allKeys logic removed
 export const setCallFlowJson = (callFlowJSON = {}, isDraft = true, initTimerFlag =  false, saveAsItIs=false) => dispatch => {
    if (callFlowJSON != null) {        
        if ((!isDraft) || (isDraft === 0) || (isDraft === "0")) {
            isDraft = false;
        } else {
            isDraft = true;
        }
        let payload = { callFlowJSON: callFlowJSON, callFlowIsDraft: isDraft, saveAsItIs: saveAsItIs };
        dispatch({
            type: SET_CALL_FLOW_JSON,
            payload: payload
        });
        if (initTimerFlag) {
            dispatch(initTimer());
        }
    }
}



/**
 * To save call flow mappings
 * @param {json} responseMappings 
 */
export const setCallFlowMapping = (responseMappings = {}) => dispatch => {
    dispatch({
        type: SET_CALL_FLOW_MAPPINGS,
        payload: responseMappings
    });
}

/**
 * To reset call flow mappings in case of drag and drop
 * @param {array} steps
 * @param {array} index 
 */
export const resetCallMapping = (steps = [], index = [], callFlowJSON = null, callFlowResponseMappings = null) => dispatch => {
    if ((!callFlowJSON) || (!callFlowResponseMappings)) {
        var state = store.getState(); 
        if (!callFlowJSON) {
            callFlowJSON = state.rules.callFlow.callFlowJSON
        }
        if (!callFlowResponseMappings) {
            callFlowResponseMappings = state.rules.callFlowResponseMappings
        }
    }
    /** Call Flow version 1.1 and old support */
    let callFlowJsonPicked = (callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : callFlowJSON;
    var {callFlowJson, newCallFlowResponseMappings} = resetCallFlowMapping(callFlowJsonPicked, callFlowResponseMappings, steps, index);

    if (callFlowJson) {
        dispatch(setCallFlowJson(callFlowJson));
    }
    dispatch(setCallFlowMapping(newCallFlowResponseMappings));
    dispatch(setRulesAndCallFlowUpdatedState(2));
    dispatch(initTimer());
}

/**
 * To add step in call flow mappings
 * @param {string} newStepName
 */
export const addStepCallFlow = (newStepName = "") => dispatch => {
    var state = store.getState();
    
    /** Call Flow version 1.1 and old support */
    let callFlowJsonPicked = (state.rules.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? state.rules.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : state.rules.callFlow.callFlowJSON;

    var callflowMapping = addStepCallFlowMapping(callFlowJsonPicked, state.rules.callFlowResponseMappings, newStepName);
    dispatch(setCallFlowMapping(callflowMapping));
    dispatch(setRulesAndCallFlowUpdatedState(2));
    dispatch(initTimer());  
}

/**
 * To set adding bloak value of call flow json
 * @param {json} callFlowJSON 
 */
 export const setCallFlowAddingBlock = (addingBlock =  false) => dispatch => {
    dispatch({
        type: SET_CALL_FLOW_ADDING_BLOCK,
        payload: addingBlock
    });
}


/**
 * To save call flow json
 * @param {json} callFlowJSON 
 */
 export const setCallFlowJsonStepSettings = (step = "", settings = {}, initTimerFlag =  true) => dispatch => {
    if (settings != null && step != "") {
        
        let payload = {
            step: step,
            settings: settings
        }
        dispatch({
            type: SET_CALL_FLOW_STEP_SETTINGS,
            payload: payload
        });

        /** setting call flow as draft is settings have been changed */
        dispatch(setCallFlowIsDraft(true));

        if (initTimerFlag) {
            dispatch(initTimer());
        }
    }
}

/**
 * To maintian call flow changes state throughout
 * @param {boolean} isUpdated 
 */
export const setRulesAndCallFlowUpdatedState = (isUpdated=0) => dispatch => {
    var state = store.getState(); 
    
    if((state.rules.ruleAndCallFlowUpdated === 2 ) && (isUpdated === 1 )) {
        isUpdated = 3;
    }
    else if((state.rules.ruleAndCallFlowUpdated === 1 ) && (isUpdated === 2 )) {
        isUpdated = 3;
    }
    dispatch({
        type: RULES_CALL_FLOW_UPDATED,
        payload: isUpdated
    });
    if(isUpdated == 2){
        dispatch(initTimer());
    }
}


/**
 * To show/hide response bodal having nodes from rules for call flow
 * @param {boolean} show show or hide modal
 */
 export const showNodeResponseModal = (show) => dispatch => {
    dispatch({
        type: NODE_RESPONSE_BLOCK_SHOW,
        payload: {show:show}
    });
}

export const setCallFlow = (actionLevel=null) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    var state = store.getState(); 
    var nodeResponsesResult = {
        callFlowJson: null,
        callFlowMappings: null
    };
    nodeResponsesResult = updateCallFlowJSONResponse(state.rules.rulesJson, state.rules.callFlow.callFlowJSON, state.rules.callFlowResponseMappings, state.rules.responseAction, state.rules.botResponses);
    var callFlowChanged = false;
    if (nodeResponsesResult.callFlowJson) {
        const { latestStep, accordionStates } = getLastStepAndAccordionStates(nodeResponsesResult.callFlowJson) 
        dispatch(setStepCounter(latestStep));
        // dispatch(setStepSequence([...nodeResponsesResult.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']]));
        /** Setting call flow json state */;
        dispatch(setCallFlowJson(nodeResponsesResult.callFlowJson));
        callFlowChanged = true;
    }
    if (nodeResponsesResult.callFlowMappings) {
        /** Setting call flow mapping state */
        dispatch(setCallFlowMapping(nodeResponsesResult.callFlowMappings));
        callFlowChanged = true;
    }
    
    if (callFlowChanged) {
        /** Tell call flow to update UI */
        dispatch(setRulesAndCallFlowUpdatedState(2));
    }

    /** Start timer for autosave call */
    dispatch(initTimer());
    dispatch(setSelectedRule(null, null, false, null, null, [], null, null, true));
};

export const resetBootStrapedCallFlow = (storyId = 0, storyRuleId = 0) => dispatch => {
    if ((storyId == 0) || (storyRuleId == 0)) {
        var state = store.getState(); 
        storyId = state.story.storyId;
        storyRuleId = state.rules.storyRuleId;
    }
    dispatch(getCallflowJson(storyId, storyRuleId, true));
}

/**
 * To show overwriting Warning or not.
 * @param {boolean} warn show or hide modal
 */
export const setWarnOverwriting = (warn=false) => dispatch => {
    dispatch({
        type: SET_WARN_OVERWRITING,
        payload: warn
    });
}

/**
 * There are unsaved changes (not even saved by autosave changes) or not
 * @param {boolean} unsavedChanges show or hide modal
 */
export const setUnsavedChanges = (unsavedChanges=false) => dispatch => {
    dispatch({
        type: SET_UNSAVED_CHANGES,
        payload: unsavedChanges
    });
}

/**
 * There are unsaved changes (not even saved by autosave changes) or not
 * @param {boolean} unsavedChanges show or hide modal
 */
 export const setCallFlowIsDraft = (isDraft=false) => dispatch => {
    dispatch({
        type: SET_CALLFLOW_DRAFT,
        payload: isDraft
    });
}

/**
 * To fill up rules detailed view and simple view mapping
 * @param {boolean} show show or hide Bulk Edit
 */
export const setBulkEdit = (data={}) => dispatch => {
    dispatch({
        type: SET_BULK_EDIT,
        payload: data,
    });
}

export const getSampleUtterances = (luisAppDbId) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    
    let totalUtterancesData= {};
    let totalEntitiesData= {};
    let totalEntitiesUtterances = {};

    let initialPageNumber = 1
    const getUtterancesRecursively = ({luisAppDbId,pageNumber}) => {
        const axiosInitialSuccessCall = (response) => {
            if (response.data) {
                if (response.data.total_pages > pageNumber) {
                    getUtterancesRecursively({ luisAppDbId, pageNumber: pageNumber + 1 });
                }
                if (response.data.total_pages >= pageNumber) {
                    totalUtterancesData = { ...totalUtterancesData, ...response.data.utterance_list }

                    // to check if mapping already exists, if exists , then append if not , add a new mapping
                    totalEntitiesUtterances =  checkIfnewDataExistsInOldAndMap({oldData:totalEntitiesData,newData:response.data.entities})                 
                }
                if (response.data.total_pages === pageNumber) {
                   let payloadUtterance = { key: "sampleUtterances", value: {"data":totalUtterancesData} }
                   let payloadEntities = {"entitiesUtterances":totalEntitiesUtterances }

                    dispatch({
                        type: SET_SAMPLE_UTTERANCES,
                        payload: payloadUtterance
                    });

                    dispatch({
                        type: SET_ENTITIES_UTTERANCES,
                        payload: payloadEntities
                    });

                    dispatch(setTotalDataCount({key:importableProperties.UTTERANCES,value: response.data.utterance_count && response.data.utterance_count}))


                }
            }
      }
      axiosCall(getApiUrl(aiBase, aiUrls.GET_UTTERANCES, {luisAppDbId:luisAppDbId ,pageNumber }), axiosInitialSuccessCall, axiosErrorFunction);
    }

    getUtterancesRecursively({luisAppDbId,pageNumber:initialPageNumber})
};

export const getEntites = (luisAppDbId) => dispatch => {
    dispatch({
        type: SET_LOADING
    });
    
    let totalEntitiesData= {};
    let initialPageNumber = 1
    const getEntitiesRecursively = ({luisAppDbId,pageNumber}) => {
        const axiosInitialSuccessCall = (response) => {
            if (response.data) {
                if (response.data.total_pages > pageNumber) {
                    getEntitiesRecursively({ luisAppDbId, pageNumber: pageNumber + 1 });
                }
                if (response.data.total_pages >= pageNumber) {
                    totalEntitiesData = { ...totalEntitiesData, ...response.data.entity_list }
                }
                if (response.data.total_pages === pageNumber) {
                    let entityNames = { "entities": Object.keys(totalEntitiesData)}
                    

                    dispatch({
                        type: SET_ENTITIES,
                        payload: entityNames
                    });

                    dispatch({
                        type: SET_TRAINED_LUIS_ENTITIES,
                        payload: totalEntitiesData
                    });

                    dispatch(setTotalDataCount({key:importableProperties.ENTITIES,value:response.data.entity_count && response.data.entity_count}))
                    
                }
            }
      }
      axiosCall(getApiUrl(aiBase, aiUrls.GET_ENTITIES, {luisAppDbId:luisAppDbId ,pageNumber }), axiosInitialSuccessCall, axiosErrorFunction);
    }

    getEntitiesRecursively({luisAppDbId,pageNumber:initialPageNumber})
};

export const setShowUtteranceModal = (showModal, mode, stepKey,blockKey) => dispatch => {
    const payload = { "showUtteranceModal": showModal, "statements": {intent:mode,key:stepKey,block:blockKey} }

    dispatch({
        type : SET_UTTERANCE_MODAL_SHOW,
        payload: payload
    });

    dispatch(changeNodeAction(mode));
}


export const setShowUtteranceSideBar = (toggleInfoSideBar, mode) => dispatch => {
    const payload = { "showUtteranceSideBar": toggleInfoSideBar, "statements": {...mode} }

    dispatch({
        type : SET_UTTERANCE_SIDE_BAR,
        payload: payload
    });

    dispatch(changeNodeAction(mode));
}

export const setShowEntityModal = (showModal, mode, stepKey,blockKey,isStepEntity=false, defaultValue=null,type ='entity', selectedSteps) => dispatch => {
    const payload = { "showEntityModal": showModal, "statements": {intent: mode, key: stepKey, block: blockKey, defaultValue, isStepEntity, type, selectedSteps} }

    dispatch({
        type : SET_ENTITY_MODAL_SHOW,
        payload: payload
    });

    dispatch(changeNodeAction(mode));
}

export const setShowExistingRulesModal = (showModal,mode) => dispatch => {
    const payload = { "showExistingRulesModal": showModal, "statements": {} }

    dispatch({
        type : SET_EXISTING_RULES_MODAL_SHOW,
        payload: payload
    });

    dispatch(changeNodeAction(mode));
}

export const  setPreviousTestReports = (storyId) => dispatch =>{
    dispatch({
        type: SET_TESTPLAN_LOADER,
        payload: true
    })
    if(storyId){
        var url = 'callflow/acknowledge/' + storyId + '?onload=1&request_type=basic';
        const axiosSuccessFunction = (response) => {
            /** Handle success case */
            if(response && response.data && response.data.testcase){
                console.log("PREVIOUS REPORTS TESTCASES: ",response.data);
                let testCaseCount = response.data.testcase.length;
                let testCaseComplete = 0
                testCaseCount ? response.data.testcase.forEach(item=>{
                    let reportUrl = 'callflow/report/' + item.id;
                    dispatch({
                        type: SET_TESTPLANS,
                        payload: { key: "testPlanJsons", value: {[item.id] : item} }
                    });
                    const axiosSuccessFunction = (response) => {
                        /** Handle success case */
                        let payload = {}
                        if (response.data) {
                            /** Setting bot responses */
                            testCaseComplete++
                            console.log("TEST REPORT FETCHED: ",response.data);
                            payload = { key: "testPlanReports", value: {[response.data.test_plan_id]:response.data} }
                            dispatch({
                                type: SET_TESTREPORTS,
                                payload: payload
                            });
                            testCaseCount === testCaseComplete && dispatch({ type: SET_TESTPLAN_LOADER,payload: false})
                        }
                    };
            
                    const axiosCustomErrorFunction = (response) =>{
                        console.log('response in custom error',response)
                        if(response.response.data){
                            testCaseComplete++
                            if(response.response.data.code === 500){
                                var payload = {}
                                    /** Setting bot responses */
                                    payload = { key: "testPlanReports", value: {[response.response.data.data.test_plan_id]:{...response.response.data.data,success:response.response.data.success}} }
                                    dispatch({
                                        type: SET_TESTREPORTS,
                                        payload: payload
                                    });
                                    
                            }
                            else{
                                axiosErrorFunction(response)
                            }
                            testCaseCount === testCaseComplete && dispatch({ type: SET_TESTPLAN_LOADER,payload: false})
                        }
                    }
                    axiosCall(reportUrl, axiosSuccessFunction, axiosCustomErrorFunction);
                })
                :
                dispatch({
                    type: SET_TESTPLAN_LOADER,
                    payload: false
                })
                
            }
                
        }
        const axiosCustomErrorFunction = (errorResponse) =>{
            if(errorResponse && errorResponse.response && errorResponse.response.data && errorResponse.response.data.message){
                global.recordAudit(errorResponse.response.data.message);
                dispatch(setError(errorResponse.response.data)); 
            }
            else{
                global.recordAudit("Internal Server Error");
                dispatch(setError("Internal Server Error")); 
            }
            dispatch(setAlert("Something went wrong", 0));
            dispatch({
                type: SET_TESTPLAN_LOADER,
                payload: false
            })
        }
        axiosCall(url, axiosSuccessFunction, axiosCustomErrorFunction);
    }  
}

export const setShowTestPlans = (show,storyId,type) => dispatch => {
    dispatch({
        type: SET_TESTPLAN_LOADER,
        payload: true
    })
    if(storyId){
        let requestType = type ? 'all': 'basic';
        var url = 'callflow/acknowledge/' + storyId + '?onload=0' + '&request_type=' + requestType ; 
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        console.log("REQUEST TEST PLANS channel name: ",response.data);
        var payload = {};
        

        const pusherSuccessCall = (pusher,channelData,pusherKeys) => {
        // update testplans
            console.log(channelData);
            var channels = pusher.subscribe(channelData.channel_name.toString());
            payload = { "showTestPlans": show, "testPlans": channels,"testPlanChannel":channelData,"pusherKeys":pusherKeys }
            channels.bind("pusher:subscription_succeeded", () => {
                console.log(channelData.channel_name," subscribed successfully");
                subscribedSuccessFully(payload);
            });
            channels.bind("pusher:subscription_error", (error) => {
                console.log(channelData.channel_name," subscription error",error)
            });
           
        }
        pusherCall(pusherSuccessCall,response.data);
        
        
    };

    const subscribedSuccessFully = (payload) =>{
        const axiosSuccessFunction = (successResponse) =>{
            dispatch({
                    type : SET_SHOW_TESTPLANS,
                    payload: payload
            });
            dispatch({
                type: SET_TESTPLAN_LOADER,
                payload: false
            })
    }

    const axiosCustomErrorFunction = (errorResponse) =>{
        global.recordAudit(errorResponse.response.data.message);
        dispatch(setError(errorResponse.response.data)); 
        dispatch(setAlert("Something went wrong", 0));
        dispatch({
            type: SET_TESTPLAN_LOADER,
            payload: false
        })
    }
    
        let subscribedUrl = 'callflow/acknowledge/' + storyId+ '?onload=0' + '&request_type=' + requestType;
        axiosCall(subscribedUrl, axiosSuccessFunction,axiosCustomErrorFunction,{}, 'POST');

    }



    const oldEventsFunction = (response,payload) =>{
        
        const axiosOldEventsSuccessFunction = (oldEventsResponse) =>{
                dispatch(setError(oldEventsResponse.data)); 
                dispatch({
                        type : SET_SHOW_TESTPLANS,
                        payload: payload
                });
                dispatch({
                    type: SET_TESTPLAN_LOADER,
                    payload: false
                })
        }

        const axiosOldEventsErrorFunction = (errorResponse) =>{
            console.log('error in queued_events', errorResponse);
            global.recordAudit(errorResponse);
            dispatch(setError(errorResponse)); 
            dispatch(setAlert("Something went wrong", 0));
            dispatch({
                type: SET_TESTPLAN_LOADER,
                payload: false
            })
        }
        
        let oldEventsURL = `callflow/queued_events/${response.response.data.data.id}/${response.response.data.data.channel_name}`;
        axiosCall(oldEventsURL, axiosOldEventsSuccessFunction,axiosOldEventsErrorFunction);
    }

    const axiosCustomErrorFunction = (response) => {
        console.log('response in axiosCustomErrorFunction', response)
        if(response){
            global.recordAudit(response.response.data.message);
            if(response.response.data.code === 200){
                const pusherSuccessCall = (pusher,channelData,pusherKeys) => {
                    // update testplans
                        console.log('inside pusherSuccess call axiosCustomErrorFunction', channelData)
                        if(channelData){
                            var channels = pusher.subscribe(channelData.channel_name.toString());
                            payload = { "showTestPlans": true, "testPlans": channels,"testPlanChannel":channelData,"pusherKeys":pusherKeys  }
                            channels.bind("pusher:subscription_succeeded", () => {
                                console.log(channelData.channel_name," subscribed successfully");

                                if(response.response.data.data.id){
                                    oldEventsFunction(response,payload) 
                                }                                
                                
                            });
                            channels.bind("pusher:subscription_error", (error) => {
                                console.log(channelData.channel_name," subscription error",error)
                            });
                            
                           
                            if(!response.response.data.data.id){
                                dispatch({
                                    type : SET_SHOW_TESTPLANS,
                                    payload: payload
                                });
                                dispatch({
                                    type: SET_TESTPLAN_LOADER,
                                    payload: false
                                })
                            }
                        }
                                   
                }
                pusherCall(pusherSuccessCall,response.response.data.data);
                
            }
            else{
                dispatch(setAlert("Something went wrong", 0));
                dispatch(setError(response.response.data));
                dispatch({
                    type: SET_TESTPLAN_LOADER,
                    payload: false
                })
            }
            
        }
        else{
            global.recordAudit("Internal Server Error");
            dispatch(setError({response:{data:{message:'Something went wrong'}}}));
            dispatch({
                type: SET_TESTPLAN_LOADER,
                payload: false
            })
        }
    }
    
    axiosCall(url, axiosSuccessFunction, axiosCustomErrorFunction);
    var payload = { "showTestPlans": show }
           dispatch({
            type : SET_SHOW_TESTPLANS,
            payload: payload
            });
    
    }
    else{
        var payload = { "showTestPlans": show }
           dispatch({
            type : SET_SHOW_TESTPLANS,
            payload: payload
            });
            dispatch({
                type: SET_TESTPLAN_LOADER,
                payload: false
            })
    }
   
}

export const runTestPlans = (channel,state,id) => dispatch => {
    
    // Run single testplans
}

export const fetchTestPlans = (keys) => dispatch => {
    keys.map((item,index)=>{
        var url = 'callflow/testcase/' + item;
        const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        if (response.data) {
            /** Setting bot responses */
            console.log("TEST PLAN FETCHED: ",response.data);
            payload = { key: "testPlanJsons", value: {[response.data.id]:response.data} }
            dispatch({
                type: SET_TESTPLANS,
                payload: payload
            });
        }
    };
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    });
}

export const fetchTestReport = (keys) => dispatch => {
    keys.map((item,index)=>{
        var url = 'callflow/report/' + item;
        const axiosSuccessFunction = (response) => {
            /** Handle success case */
            var payload = {}
            if (response.data) {
                /** Setting bot responses */
                console.log("TEST REPORT FETCHED: ",response.data);
                payload = { key: "testPlanReports", value: {[response.data.test_plan_id]:response.data} }
                dispatch({
                    type: SET_TESTREPORTS,
                    payload: payload
                });
            }
        };

        const axiosCustomErrorFunction = (response) =>{
            console.log('response in custom error',response)
            if(response.response.data){
                
                if(response.response.data.code === 500){
                    var payload = {}
                        /** Setting bot responses */
                        payload = { key: "testPlanReports", value: {[response.response.data.data.test_plan_id]:{...response.response.data.data,success:response.response.data.success}} }
                        dispatch({
                            type: SET_TESTREPORTS,
                            payload: payload
                        });
                }
                else{
                    axiosErrorFunction(response)
                }
            }
        }
        axiosCall(url, axiosSuccessFunction, axiosCustomErrorFunction);
    });
}

export const setAllTestPlans = (value) => dispatch => {
    dispatch({
        type: SET_ALL_TESTPLANS,
        payload: value,
    });
}

export const setSavedTestPlanData = (type,key,value) =>dispatch => {
    dispatch({
        type: SET_SAVED_TESTPLAN_DATA,
        payload: {value:value, key:key, type:type},
    });
}

export const setFromSavedTPData = (value) =>dispatch =>{
    dispatch({
        type: SET_TESTPLANSDATA_FROM_SAVED,
        payload: value
    })
    
}

export const syncTestPlansReports = (keys) => dispatch =>{
    keys.map((item,index)=>{
        var url = 'callflow/testcase/' + item;
        const axiosSuccessFunction = (response) => {
        /** Handle success case */
        var payload = {}
        if (response.data) {
            /** Setting bot responses */
            console.log("TEST PLAN FETCHED: ",response.data);
            payload = { key: "testPlanJsons", value: {[response.data.id]:response.data} };
            dispatch({
                type: SET_TESTPLANS,
                payload: payload
            });
            fetchTestReport(keys)(dispatch);
        }
    };
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    });
}

export const resetTestPlan = () => dispatch => {
    const payload = {};

    dispatch({
        type : TESTPLAN_RESET,
        payload: payload
    });
}


export const setSelectedTestPlan = (testPlanSelected) => dispatch => {

    const payload = { "key": "selectedTestPlan", "value": {...testPlanSelected} }

    dispatch({
        type : SET_SELECT_TESTPLAN,
        payload: payload
    });
}



//state by Payal to open infosidebar 

export const onNewIntentSetForInfoSideBar = (NewIntentSetForInfoSideBar=false) => dispatch => {
  
    dispatch({
        type: SET_NEW_NODE_INFO_SIDE_BAR_NEW,
        payload: NewIntentSetForInfoSideBar
    });

}


export const setAddIntentInUntrainedLuisObj = (intentName) => dispatch => {
    if (intentName) {
        const intentText = intentName.trim()
        const intentObj = {
            name:intentText ,
            features: []
        }
        dispatch({
            type: SET_UNTRAINED_LUIS_OBJ_ADD_INTENT,
            payload: intentObj
        });

        dispatch(initTimer());
    }
    
    if(!intentName){
        console.log("INTENT WAS NOT PROVIDED FOR TRAINING DATA ")
    }
}




export const setSelectedBlockCallInfo = (data) => dispatch => {
    const payload = data
    dispatch({
        type : SET_SELECTED_BLOCK_CALL_INFO,
        payload: payload
    });

    
}



export const setAddUtteranceInUntrainedLuisObj = (utterance) => dispatch => {
   if(utterance){
    const {text,intent,tempId} = utterance;
    const utteranceText = text.trim()
    const utteranceObj = {
        tempId,
        text:utteranceText,
        intent,
        entities:[],
        training_metadata:[]
    }
    dispatch({
        type : SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE,
        payload: utteranceObj
    });  

    dispatch(initTimer());
   }

    if(!utterance){
        console.log("UTTERANCE WAS NOT PROVIDED FOR TRAINING DATA ")
    }
}

export const setRemoveUtteranceInUntrainedLuisObj = (utteranceTempId) => dispatch => {
    dispatch({
        type :SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE ,
        payload: utteranceTempId
    });

    dispatch(initTimer());
}

export const setRemoveIntentAndItsUtterancesFromUntrainedLuisObj = (intent) => dispatch => {
    const {rules:{untrainedLuisObj:{data}}} = store.getState();
    const utterancesInLuisObjAddState = data.ADD.utterances
    const utterancesInLuisObjDeleteState = data.DELETE.utterances
    
    //if utterances mapped to the intent is found ,remove them
    const filteredUtterancesInAddState = utterancesInLuisObjAddState.filter(utterance => utterance.intent !== intent)
    const filteredUtterancesInDeleteState = utterancesInLuisObjDeleteState.filter(utterance => utterance.intent !== intent)

    dispatch({
        type :SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD ,
        payload: intent
    });

    dispatch({
        type :REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE ,
        payload: {
            filteredUtterancesInAddState,filteredUtterancesInDeleteState
        }
    });

    dispatch(initTimer());
}

export const setDeleteSampleUtterance = (sampleUtterance) => dispatch => {
    const {value:{utteranceText,relatedIntent,utteranceId}} = sampleUtterance
    const utteranceObj = {
        utteranceId: utteranceId,
        text:utteranceText,
        intent:relatedIntent,
        entities:[]
    }
    dispatch({
        type :SET_DELETE_SAMPLE_UTTERANCE,
        payload: utteranceObj
    });

    dispatch(initTimer());
}


export const updateUntrainedLuisObjState = ({data}) => dispatch => {
    const addEntitiesField =   data.ADD.entities ?  data.ADD.entities : []
    const deleteEntitiesField =   data.DELETE.entities ?  data.DELETE.entities : []
    const editEntitiesField =   data.EDIT.entities ?  data.EDIT.entities : []

    //handle when incoming field called entites NOT coming from backend 
    const composeUntrainedLuisData = {          
        ADD:{...data.ADD,entities:addEntitiesField},
        EDIT:{...data.EDIT,entities:editEntitiesField},
        DELETE:{...data.DELETE,entities:deleteEntitiesField}
    }

        dispatch({
            type: SET_UNTRAINED_LUIS_OBJ_STATE,
            payload: composeUntrainedLuisData
        });

        dispatch(initTimer());
    }

export const updateUntrainedLuisObjStoryTitle = ({storyTitle}) => dispatch => {
    
        dispatch({
            type: SET_UNTRAINED_LUIS_OBJ_STORY_TITLE,
            payload: {storyTitle}
        });
}

export const updateUntrainedLuisObjUsername = ({username}) => dispatch => {
    
    dispatch({
        type: SET_UNTRAINED_LUIS_OBJ_USERNAME,
        payload: {username}
    });
}

export const resetUntrainedLuisObjState = () => dispatch => {
    dispatch({
        type:RESET_UNTRAINED_LUIS_OBJ_STATE_DATA
    })
}

export const changeActionsForUntrainedLuisState = (isChange) => dispatch => {
    dispatch({
        type:CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE,
        payload:isChange
    })
}

export const setIsIncomingLuisDataExist = (isExist) => dispatch => {
    dispatch({
        type:IS_INCOMING_LUIS_DATA_EXIST,
        payload:isExist
    })
}

export const setIsIncomingLuisDataEmptyObj = (isExist) => dispatch => {
    dispatch({
        type:IS_INCOMING_LUIS_DATA_EMPTY_OBJ,
        payload:isExist
    })
}


export const setIsStoryIdMatchInLuisData = (isMatch) => dispatch => {
    dispatch({
        type:IS_STORY_ID_MATCH_IN_LUIS_DATA,
        payload:isMatch
    })
}




export const getUntrainedLuisData = () => dispatch => {
    dispatch(changeHideStartTrainingBtn(true))
    var state = store.getState();
    const storyId = state.story.storyId;
    const appDbId = state.luis_app.appDbId;
    const incomingLuisDataEmptyObjcurrState = state.rules.untrainedLuisObj.isIncomingDataEmptyObj


    let url = getApiUrl(aiBase, aiUrls.GET_LUIS_APP, {luisAppDbId:appDbId});
    const axiosSuccessFunction = (response) => {
        const storyIdNew = response.data.story_id;
        const luisUntrainedData = response.data.app_json;
        const luisStoryTitle = response.data.story_title ? response.data.story_title : null;
        const username = response.data.username ? response.data.username : null;

        if (luisUntrainedData === null) {
            dispatch(setIsStoryIdMatchInLuisData(null)); 
            dispatch(setIsIncomingLuisDataExist(false));
            dispatch(changeActionsForUntrainedLuisState(true));
        } else {
            if (storyId != storyIdNew) {
                dispatch(setIsStoryIdMatchInLuisData(false));
                dispatch(setIsIncomingLuisDataExist(true)); 
                dispatch(changeActionsForUntrainedLuisState(false));
                dispatch(updateUntrainedLuisObjStoryTitle({storyTitle:luisStoryTitle})) //updates storyTitle to show in onscreen modal 
                dispatch(updateUntrainedLuisObjUsername({username})) // update username to show on onscreen modal
                dispatch(setIsIncomingLuisDataEmptyObj(!incomingLuisDataEmptyObjcurrState)); 
            } else {
                dispatch(setIsStoryIdMatchInLuisData(true));
                dispatch(setIsIncomingLuisDataExist(true));
                dispatch(updateUntrainedLuisObjState({data:luisUntrainedData})) //updates luisData when there's data and storyId matches
                dispatch(updateUntrainedLuisObjStoryTitle({storyTitle:luisStoryTitle}))
                dispatch(changeActionsForUntrainedLuisState(true)); 
            }
        }
    };
    axiosCall( url, axiosSuccessFunction);
}
// Train And Publish 


export const setAccordionState = (stateValue, isNew) =>dispatch => {
    dispatch({
        type : SET_ACCORDION_STATE,
        payload: {value: stateValue, isNew: isNew}
    });
}

export const setStepSequence = (sequence) => dispatch => {
    dispatch({
        type : SET_STEP_SEQUENCE,
        payload: sequence
    });
}



//setAddUntrainedEntity
export const setAddUntrainedEntity = (entityName) => dispatch => {
if(entityName){
    const entityText = entityName.trim()
    const newEntity = {
        name: entityText,
        children: [],
        roles:[],
        features:[]
    }
    
    dispatch({
        type:SET_ADD_UNTRAINED_ENTITY,
        payload:newEntity
    })
    
    dispatch(initTimer());
}

if(!entityName){
    console.log("ENTITY WAS NOT PROVIDED FOR TRAINING DATA ")
}

}


export const setAddUtteranceEntities = ({currentUtteranceEntities,utterance}) =>dispatch => {
    const {rules:{utteranceEntities,untrainedLuisObj:{data:{EDIT:{utterances:utterancesInEDITArray},ADD:{utterances:utterancesInADDArray}}}}} = store.getState()
    const isCurrentUtteranceTrained = utterance.hasOwnProperty('isUntrained') ? !utterance.isUntrained : true
    
    let utteranceEntitiesMapper={};
    let newLuisEditUtteranceArray=[];
    let newLuisAddUtteranceArray=[];

    const utteranceEntitiesObjForLuis = {
        text:utterance.label,
        intent:utterance.value.relatedIntent,
        entities:currentUtteranceEntities
    }

    //UTTERANCE ENTITY MAPPER
    if(utteranceEntities[utterance.label]){
        utteranceEntities[utterance.label] = currentUtteranceEntities;
        const clonedObj = {...utteranceEntities}
        utteranceEntitiesMapper = clonedObj
       
    }else{
        utteranceEntitiesMapper[utterance.label] = currentUtteranceEntities      
    }
    dispatch({
        type:SET_ADD_UTTERANCE_ENTITIES,
        payload:utteranceEntitiesMapper
    })
    

    //If UNTRAINED UTTERANCE (ADD UTTERANCE ARRAY CHANGE)
    //If utterance EXISTS in ADD UTTERANCE ARRAY , modify the utterance entities and save,
    if(!isCurrentUtteranceTrained){
        if (utterancesInADDArray.some(item => item.text === utterance.label)) {
            newLuisAddUtteranceArray = utterancesInADDArray.map(item => {
                if (item.text === utterance.label) {
                    item.entities = currentUtteranceEntities
                }   
                return item;
            })
        } else {
            newLuisAddUtteranceArray = [...utterancesInEDITArray]
        }

        dispatch({
            type:SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA,
            payload:newLuisAddUtteranceArray
        })

    }

    //FOR TRAINED UTTERANCE(EDIT UTTERANCE ARRAY ):
    //If utterance EXISTS in EDIT UTTERANCE ARRAY , modify the utterance entities and save,
    //If utterance NOT EXIST,retrive exisiting data and save a new entry to the EDIT UTTERANCE ARRAY
    if(isCurrentUtteranceTrained){
        if (utterancesInEDITArray.some(item => item.text === utterance.label)) {
            newLuisEditUtteranceArray = utterancesInEDITArray.map(item => {
                if (item.text === utterance.label) {
                    item.entities = currentUtteranceEntities
                }   
                return item;
            })
        } else {
            newLuisEditUtteranceArray = [...utterancesInEDITArray,utteranceEntitiesObjForLuis]
        }
        
        //another dispatch to save the utterance entities in untrainedLuisObjState
        dispatch({
            type:SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA,
            payload:newLuisEditUtteranceArray
        })
    }

    dispatch(initTimer());
    
}

//setDeletetrainedEntityFromUtterance
export const setDeleteUtteranceEntities = ({currentUtterance,isCurrentUtteranceTrained}) =>dispatch => {
    const {rules:{utteranceEntities,untrainedLuisObj:{data:{EDIT:{utterances:utterancesInEDITArray},ADD:{utterances:utterancesInADDArray}}}}} = store.getState()

    if(utteranceEntities[currentUtterance.label]){
        let clonedUtteranceObj = {...utteranceEntities}
        delete clonedUtteranceObj[currentUtterance.label]

        //DELETE FROM UTTERANCE ENTITY MAPPER
        dispatch({
            type:SET_ADD_UTTERANCE_ENTITIES,
            payload:clonedUtteranceObj
        })
    }

    //FOR TRAINED UTTERANCE(EDIT UTTERANCE ARRAY ):
    //If utterance EXISTS in EDIT UTTERANCE ARRAY , modify the utterance entities and save,
    //If utterance NOT EXIST,retrive exisiting data and save to the EDIT UTTERANCE ARRAY
    if(isCurrentUtteranceTrained){
        let filteredUtterances;
        if (utterancesInEDITArray.some(item => item.text === currentUtterance.label)) {
            filteredUtterances = utterancesInEDITArray.filter(item => item.text !== currentUtterance.label)
        }
        else {
            filteredUtterances = [...utterancesInEDITArray]
        }
        
        //another dispatch to save the filtered  utterance entities in untrainedLuisObjState
        dispatch({
            type:SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA,
            payload:filteredUtterances
        })
    }

    dispatch(initTimer());    
}



export const setAiVersion = (aiVersion) => dispatch => {
    dispatch({
        type : SET_AI_VERSION,
        payload: aiVersion
    });
}
export const changeHideStartTrainingBtn = (value) => dispatch => {
    dispatch({
        type: HIDE_START_TRAINING_BUTTON,
        payload: value
    })
}
