import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import classes from './ControlBar.module.css'
import InfoSideBar from '../../SideBar/InfoSideBar/InfoSideBar';

const ControlBar = (props) =>{
    return(
            <div className={`bg-light p-2 border ${classes.controlBarContainer}`}>
                <div className="float-left d-flex">
                    <h5 className="blue pt-2">{props.title}</h5>
                    <div style={{display:'inline-flex'}}>
                            {props.leftControls}
                    </div>
                </div>
               <div id="top-bar" className="float-right">   
                    <div className="ml-2 blue border-0 pt-2" style={{display:'inline-flex'}}>
                        {props.rightControls}
                    </div>
               </div>
               
                

                {
                    props.propsRedux.utterances != undefined 
                    && Object.keys(props.propsRedux.utterances).length > 0  
                    && props.propsRedux.rulesJson
                    && Object.keys(props.propsRedux.rulesJson).length > 0  
                    &&  
                    <InfoSideBar
                        show={props.propsRedux.showUtteranceSideBar}
                        title="Call Flow Information"
                    />
                }
               
        </div>
    )
}


/** Redux store management  */
const mapStateToProps = (state) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
            utterances: state.rules.sampleUtterances,
            showUtteranceSideBar: state.rules.showUtteranceSideBar,
            statements:state.rules.statements,
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps)(ControlBar);