/*
 * Component Description : Entity section in a block or step
 * Author: Akash Gupta
 */
import React,{useState} from 'react';
import { connect } from "react-redux";
import classes from './Entities.module.css';
import { Icon } from '@iconify/react';
import EntityChip from './EntityChip/EntityChip';
import { confirmAlert } from 'react-confirm-alert';
import { setShowEntityModal,setRulesAndCallFlowUpdatedState, setCallFlowJson, setShowUtteranceSideBar } from "../../../../store/actions/rules/rulesActions";
import { setAlert } from "../../../../store/actions/common/commonActions";
import { truncateString } from '../../../../utils/rulesUtils';

const Entities = ({entities, callFlowJSONPicked, blockKey, stepKey, isStepEntity, setShowEntityModal, setCallFlowJson, setShowUtteranceSideBar}) =>{
    const [showMore,setShowMore] = useState(false);

    const sectionStyles = isStepEntity ? {marginTop:'15px'} : {marginTop:'-26px',marginLeft:'-40px'}
    const iconStyles = {
        color:'black',
        cursor:'pointer',
        fontSize:'1.2rem',
        zIndex:'9999'
    } 
    
    const entitiesContainer = () => {
        return isStepEntity ? 
        callFlowJSONPicked[stepKey].settings : 
        (callFlowJSONPicked[stepKey].list && callFlowJSONPicked[stepKey].list[blockKey]);
    }

    const editHandler = (entityObj, modalShow=true) =>{
        
        setShowUtteranceSideBar(false,{});
        let intent = null;
        if(isStepEntity){
            const intentsInStep = callFlowJSONPicked[stepKey].list.map(block=>block.node);
            intent = [...new Set(intentsInStep)]
        }
        else{
            intent =  callFlowJSONPicked[stepKey].list[blockKey].node
        }
        const containerObj = entitiesContainer();
        if(containerObj.entities){
            
            setShowEntityModal(modalShow, intent, stepKey, blockKey, isStepEntity, entityObj);
        }
    }

    const deleteHandler =  (e, entityObj) =>{
       
       e.preventDefault();
        confirmAlert({
            title: 'Remove Entity',
            message: 'Are you sure about removing this entity from this block ?',
            buttons: [
                {
                  label: 'Remove',
                  onClick: ()=>deleteEntity(entityObj) 
                },
                {
                  label: 'Cancel',
                  onClick: () => {}
                }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
          });
    }

    const deleteEntity = (entityObj) =>{
        
        const containerObj = entitiesContainer();
        if(containerObj){
            const newCallFlowJson = {...callFlowJSONPicked};
            const entities = containerObj.entities;
            const newEntites = entities.filter(item=> item.value !== entityObj.value);
            if(isStepEntity){
                newCallFlowJson[stepKey].settings.entities = [...newEntites]
            }
            else{
                newCallFlowJson[stepKey].list[blockKey].entities = [...newEntites];
            }
            setCallFlowJson(newCallFlowJson,true,true);
        }
    }

    const tooltipString = (entity) =>{
        return `${entity.value}
            ${entity.interruption && entity.interruption.id ? 'Interruption:' + ' ' + entity.interruption.BRText: ''}
        `
    }

    
    return (
        <div className={classes.entityIcon}>

            <div>{entities.length > 1 && 
                (<span className="text-center pt-1" 
                    aria-label="More-Entity" 
                    data-tip={entities.map(el=>el.value).join(' , ')}
                    data-class="tooltip_cust"
                    data-place = "top"
                    onClick={()=>{ setShowMore(!showMore); editHandler({}, false)}}
                >
                    
                    <Icon icon="ant-design:expand-alt-outlined" width="15" height="15" cursor='pointer' />
                    
                </span>)
            }
            </div>
            
                  <div className={classes.EntityStyle}>  {showMore && entities.map( entity =>( 
                        <EntityChip
                        
                            entity = {entity}
                            editHandler = {editHandler}
                            deleteHandler = {deleteHandler}
                            tools = {{
                                editable: true,
                                deletable: true
                            }}

                        />
                       
                    
                    ))} </div>
                    {
                        !showMore && entities && 
                      <div>
                         
                          <EntityChip
                        
                        entity = {entities[0]}
                        editHandler = {editHandler}
                        deleteHandler = {deleteHandler}
                        tools = {{
                            editable: true,
                            deletable: true
                        }}
                       

                    />
                      </div>

                    }
              
           
        </div>
    )
}

export default connect(null, { setCallFlowJson,setRulesAndCallFlowUpdatedState, setShowEntityModal, setAlert, setShowUtteranceSideBar })(Entities);
