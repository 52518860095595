import React from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import {setSelectedRule, setResponseAction, showResponseModal } from "../../../../store/actions/rules/rulesActions";



const NoneResponse = (props) =>{

    const onAddNoneResponse = () => {
        global.recordAudit("Add None Response clicked");

        var selectedRule = (props.propsRedux.rulesJson.ruleResponses && props.propsRedux.rulesJson.ruleResponses.None) 
            ?  props.propsRedux.rulesJson.ruleResponses.None 
            :   {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                        [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                    }
                };
        props.setSelectedRule(global.CUST_CONSTANTS.STORY_NONE_RESPONSE, selectedRule);
        props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                ruleKey: "generic",
                evalKey: "evalTrue",
                nodeKey: global.CUST_CONSTANTS.STORY_NONE_RESPONSE,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: false,
            }]
        });
        props.showResponseModal(true);
    }

    return (
        props.propsRedux.rulesJson &&
        <button className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue" onClick={ onAddNoneResponse }>
            <Icon name="plus" className="icon-btn"/>
            None Response
        </button>

    )    
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  setSelectedRule, setResponseAction, showResponseModal })(NoneResponse);