/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

/** Importing custom Components */
import AddOnTagRulesRow from "../AddOnTagRulesRow/AddOnTagRulesRow";
import { getIterationKeyFromObj, getResponseKeyFromObj, getMetadataTitleToShow } from '../../../../../utils/rulesUtils';

/** Importing custom Styles */

/** Main Components */
const RulesMetadataTable = props => {

    const responseTypeKey = getResponseKeyFromObj(props.selectedNode.responseJson);
    const iterationType = responseTypeKey && getIterationKeyFromObj(props.selectedNode.responseJson[responseTypeKey]);
    const responses = (props.selectedNode.responseJson && props.selectedNode.responseJson[responseTypeKey] && props.selectedNode.responseJson[responseTypeKey][iterationType]) ? props.selectedNode.responseJson[responseTypeKey][iterationType] : [];


    const title = getMetadataTitleToShow(props.selectedNode.node);


    return (
        <Table striped bordered hover className={ props.className }>
            <thead>
                <tr>
                    <th colSpan={5} > { title } </th>
                </tr>
                <tr>
                    <th>Response Type</th>
                    <th>Iteration Type</th>
                    <th>Response (s)</th>
                </tr>
            </thead>
            <tbody>
                <AddOnTagRulesRow
                    key = { props.selectedNode.node }
                    ruleKey = { null }
                    responsePriority = { null }
                    onInputValueChange = { props.onInputValueChange }
                    iterationType = { iterationType }
                    responseKey = { responseTypeKey }
                    responses = { responses }
                    showResponseModal = { props.showResponseModal }
                    selectedNode={ props.selectedNode }
                    storyId={ props.storyId }
                />
            </tbody>
        </Table>
    );
}

/** Defining prop types requirements */
RulesMetadataTable.propTypes = {
    onInputValueChange: PropTypes.func.isRequired,
    showResponseModal: PropTypes.func.isRequired,
    selectedNode: PropTypes.object.isRequired,
};

/** Exporting main component */
export default RulesMetadataTable;