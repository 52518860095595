import { SET_LOADING, SET_ERROR, SET_STORY_VARS, SET_IS_UPGRADING, SET_IS_ERROR_MODAL_OPEN, SET_LOADER_OVERLAY, CLOSE_LOADER_OVERLAY } from "../../actions/types";

const initialState = {
    loading: false,
    error: null,
    storyId: null,
    storyType: null,
    title: '',
    backUrl: 'story/admin',
    brandId:null,
    luisAppId:null,
    isErrorModalOpen: {
        isOpen: false,
        errors: null,
    },
    isUpgrading: false,
    loaderOverlay: {
        isOpen: false,
        customMessage:''
    }
};

const storyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING :
            return {
                ...state,
                loading: true
            }
        case SET_ERROR :
            return {
                ...state,
                error: action.payload.error
            }
        case SET_STORY_VARS : 
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        case SET_IS_ERROR_MODAL_OPEN:
            return {
               ...state,
                isErrorModalOpen: action.payload
            }
        case SET_IS_UPGRADING:
            return {
               ...state,
                isUpgrading: action.payload
            }
        case SET_LOADER_OVERLAY:
            return {
                ...state,
                loaderOverlay: action.payload
            }
            
        case CLOSE_LOADER_OVERLAY: 
            return {
                ...state,
                loaderOverlay: {
                    isOpen: false,
                }
            }
    
        default:
            return state;
    }
};

export default storyReducer;