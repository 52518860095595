/*
 * Component Description : Attach Highest Scoring Utterance in all block 
 * Author: Akash Gupta
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { messages } from '../../../../data/strings';
import LoadingIcon from '../../../UI/LoadingIcon/LoadingIcon';
import { Icon } from '@iconify/react';
import { setAlert } from '../../../../store/actions/common/commonActions';
import { setRulesAndCallFlowUpdatedState, setCallFlowJson } from "../../../../store/actions/rules/rulesActions";




/** Main Components */
class OneClickUtterances extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);

        this.state = {
            intentUtterances: this.getIntentUtterances(this.props.propsRedux.sampleUtterances),
            loader: false
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.propsRedux.sampleUtterances !==this.props.propsRedux.sampleUtterances){
            this.setState({intentUtterances: this.getIntentUtterances(this.props.propsRedux.sampleUtterances)})
        }
    }

    /** Lifecycle methods end */

    getIntentUtterances = (utterances) =>{
        let newUtterances = {}
        if (utterances && utterances['data']) {
            Object.keys(utterances['data']).forEach(key=>{
                if(newUtterances[utterances['data'][key].intent]){
                newUtterances[utterances['data'][key].intent] = [...newUtterances[utterances['data'][key].intent], utterances['data'][key]]
                
                }else{
                    newUtterances[utterances['data'][key].intent] = [utterances['data'][key]]
                }
            })
        }
        return newUtterances
    }


    getMaxScoringUtterance = (intentUtterances) =>{
        
        let maxScoringUtterance = null;
        intentUtterances && intentUtterances.forEach(utterance=>{
          
          if(!maxScoringUtterance || (maxScoringUtterance && maxScoringUtterance.score < utterance.score)){
            maxScoringUtterance = {...utterance}
           }
        })
      return maxScoringUtterance
    }

    attachUtterances = () =>{
        this.setState({loader:true})
        const newCallFlowJson = (this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJson;
        const mainSequence = this.props.propsRedux.callFlowJson[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']
        const cache = {}; // for memoization
        Object.keys(newCallFlowJson).forEach(step=>{
            let hadDefaultUtterance = false
            newCallFlowJson[step].list.forEach(block=>{
                let utteranceForBlock = null;

                // if block has entity priority will be given to entity utterances
                if(block.entities && block.entities.length > 0){
                    let allIntentEntitiesUtterances = []
                    block.entities.forEach(entity=>{
                        const intentEntityUtterances = this.props.propsRedux.entitiesUtterances && this.props.propsRedux.entitiesUtterances[`${block.node}::${entity.value}`]
                        if(intentEntityUtterances && intentEntityUtterances.length){
                            allIntentEntitiesUtterances = [...allIntentEntitiesUtterances,...intentEntityUtterances]
                        }
                    })
                    if(allIntentEntitiesUtterances && allIntentEntitiesUtterances.length){
                        utteranceForBlock = this.getMaxScoringUtterance(allIntentEntitiesUtterances)
                    }
                    
                }
                else{
                    if (cache[block.node]) {
                        utteranceForBlock =  cache[block.node];
                    }
                    else{
                        utteranceForBlock = this.getMaxScoringUtterance(this.state.intentUtterances[block.node]);
                        cache[block.node] = utteranceForBlock
                    }
                    
                }
                
                if(utteranceForBlock){
                    var requiredUtteranceObj = {blockId:block.id,utteranceId:utteranceForBlock.utterance_id, relatedIntent: utteranceForBlock.intent, utteranceText: utteranceForBlock.utterance_text}
                    if(block.sampleUtterances && Array.isArray(block.sampleUtterances)){
                        block.sampleUtterances[0] =	requiredUtteranceObj
                    }
                    else{
                        block['sampleUtterances'] = [requiredUtteranceObj]
                    }
                }
                
                // if step has default utterance then now change it with maxscoring for that block 
                if(newCallFlowJson[step].settings && newCallFlowJson[step].settings.defaultUtterance && newCallFlowJson[step].settings.defaultUtterance.blockId){
                    if(newCallFlowJson[step].settings.defaultUtterance.blockId == block.id){
                        hadDefaultUtterance = true
                        newCallFlowJson[step].settings.defaultUtterance = requiredUtteranceObj
                    }
                }
              
            })
            // if step does not have default utterance then make the first block of step as default utterance
            if(!hadDefaultUtterance){
                const newDefaultUtterance = newCallFlowJson[step].list[0] && newCallFlowJson[step].list[0] && 
                                          newCallFlowJson[step].list[0].sampleUtterances && 
                                          newCallFlowJson[step].list[0].sampleUtterances[0]

                if(newDefaultUtterance){
                    if(!newCallFlowJson[step].settings){
                        newCallFlowJson[step]['settings'] = {}
                    }
                    
                    newCallFlowJson[step].settings['defaultUtterance'] = newDefaultUtterance

                }
            }
        })


        this.setState({loader:false})
        this.props.setAlert('Sample utterances attached Successfully', 1);
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJson,true,true);
        
    }
      
    render() {
        console.log('intentUtterances',this.state.intentUtterances)
        return (
            <button 
                className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue"
                data-tip={messages.oneClickUtterances}
                data-class="tooltip_cust"
                data-place = "top"
                style = {{height:"2rem"}}
                disabled = {(this.props.propsRedux.syncingInProgress) || (this.props.propsOwned.disabled)}
                onClick = {this.attachUtterances}
            >
                {
                    this.state.loader
                    ? <LoadingIcon/>
                    : <Icon icon="eva:attach-fill"/>
                }
                    
            </button>
        );
    }
}

/** Defining prop types requirements */
OneClickUtterances.propTypes = {
    propsRedux: PropTypes.shape({
        callFlowJson: PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            sampleUtterances: state.rules.sampleUtterances,
            callFlowJson: state.rules.callFlow.callFlowJSON,
            entitiesUtterances: state.rules.entitiesUtterances
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps,{setAlert, setRulesAndCallFlowUpdatedState, setCallFlowJson})(OneClickUtterances);