import { params } from "../../../data/params";
import { SET_LOADING, SET_ERROR, SET_SUCCESS, SET_RULES_VARS, SET_RULES_VARS_MULTIPLE, SET_RULES_EDITOR_VARS, SET_ALERT, SET_RESPONSE_MODAL_SHOW, SET_RULES_CHANGED, SET_RESPONSE_ACTION, SET_SELECTION_CHANGE, FREEZE_COLUMN_FILTER, SET_LOADERS, SET_SV_ROWS, SET_ONGOING_AXIOS_REQUEST, SET_SELECTED_JSON,SET_SAVE_AS_SELECTED,SET_JSON_NAME, CALL_FLOW_UPDATED, SET_CALL_FLOW_JSON, RULES_CALL_FLOW_UPDATED, SET_CALL_FLOW_MAPPINGS, SET_CALL_FLOW_ADDING_BLOCK, SET_CALL_FLOW_STEP_SETTINGS, NODE_RESPONSE_BLOCK_SHOW, SET_WARN_OVERWRITING, SET_UNSAVED_CHANGES, SET_CALLFLOW_DRAFT, SET_BULK_EDIT, SET_UTTERANCE_MODAL_SHOW, SET_SAMPLE_UTTERANCES, SET_UTTERANCE_SIDE_BAR, SET_SHOW_TESTPLANS,SET_TESTPLAN_LOADER, RUN_TESTPLANS, SET_TESTPLANS, SET_SELECT_TESTPLAN, SET_TESTREPORTS,SET_SAVED_TESTPLAN_DATA,SET_ALL_TESTPLANS,SET_TESTPLANSDATA_FROM_SAVED, TESTPLAN_RESET,SET_ENTITY_MODAL_SHOW, SET_ENTITIES, SET_FILTERED_RESPONSES, SET_BOT_RESPONSES, SET_FILTERING_RESPONSES_IN_PROGRESS, ADD_FILTERED_RESPONSES, SET_SELECTED_BLOCK_CALL_INFO , SET_NEW_NODE_INFO_SIDE_BAR_NEW , SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE,SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE,SET_DELETE_SAMPLE_UTTERANCE, SET_UNTRAINED_LUIS_OBJ_ADD_INTENT
    ,SET_UNTRAINED_LUIS_OBJ_STATE,CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE,RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE,IS_INCOMING_LUIS_DATA_EXIST,IS_STORY_ID_MATCH_IN_LUIS_DATA, EMPTY_FILTERED_RESPONSES} from "../../actions/types";

import {SET_EXISTING_RULES_MODAL_SHOW,SET_STEP_COUNTER,SET_ACCORDION_STATE,SET_STEP_SEQUENCE,RESET_UNTRAINED_LUIS_OBJ_STATE_DATA,SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD,REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE,SET_UNTRAINED_LUIS_OBJ_STORY_TITLE,SET_TRAINED_LUIS_ENTITIES,SET_ADD_UNTRAINED_ENTITY,SET_ADD_UTTERANCE_ENTITIES,SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA,SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA,SET_UNTRAINED_LUIS_OBJ_USERNAME,SET_AI_VERSION,SET_ENTITIES_UTTERANCES, IS_INCOMING_LUIS_DATA_EMPTY_OBJ, HIDE_START_TRAINING_BUTTON} from "../../actions/types";

export const untrainedLuisObjInitialData = {
    ADD: {
        intents: [],
        utterances: [],
        entities:[],
    },

    EDIT: {
        intents: [],
        utterances: [],
        entities:[],
    },

    DELETE: {
        intents: [],
        utterances: [],
        entities:[],
    }

}


  
const initialState = {
    /**
     * ongoingAxiosCalls : cancel token if ongoing post request of saving rules
     */
    ongoingAxiosCalls: {},
    preloader: false,
    refEventId: 0,
    preloaderCounts: 0,
    simpleViewRows: global.CUST_CONSTANTS.READY_STATE,
    loaders: {
        simpleView: {},
        detailedView: {}
    },
    detailedVsSimpleViewIndexesMapper: {},
    loading: true,
    showIntentModal: false,
    showExistingRulesModal: false,
    nodeAction: null,
    /**
     * Active Tab : 
     * 0 : Simple View
     * 1 : Detailed View
     * 2 : Call Flow
     */
    activeTab: 0,
    /** Alert :
     * message : message to show to user
     * type : 0: error, 1: success , 2 :warning
     * fadout : true/false (fadeout after few seconds or not)
     */
    alert: {
        message: null,
        type: 0,
        fadeout: true
    },
    /**
     * 0 : not changed
     * 1 : changed nodes
     */
    isChanged: 0,
    error: null,
    success: null,
    botResponses: {},
    filteredBotResponses: {},
    botResponsesLoadingVars: {
        botResponsesSyncedAll: false,
        responsesKeyToPick: "filteredBotResponses",
        filteringInProgress: false,
        lastFilteredPage: 0
    },
    showUtteranceModal:false, /* show utterance modal flag */
    showUtteranceSideBar:false, /* show utterance Side Bar flag */
    sampleUtterances: null,
    showTestPlans:false,
    testPlanLoader:false,
    testPlans:null,
    testPlanJsons:{},
    testReports:{},
    allTestPlans:false,
    savedTestPlanData:{
        basic:{},
        all:{},
        onLoad: false
    },
    testPlanChannel:null,
    selectedTestPlan:null,
    intents: {},
    statements: null,
    isDraftInDb: true,
    isCallFlowDraftInDb: true,
    warnOverwriting: false,
    version: null,
    rulesJson: null,
    brandTags: {},
    stringifiedJson: "{}",
    selectedNode: {
        node: null,
        action: null,
        actionNewValue: null,
        responseJson: {},
        pathsData: [],
        isMetadata: false,
        scenario: null,
        isSelectedFromJsonView: true,
        type: 1,
        onNewIntentSetForInfoSideBar: null,
    },

    pusherKeys:{},
    /** state variables to see selected things end */
    storyId: null,
    audioclipSource: null,
    /**
     * Player states :
     * 0 - ended (initial state)
     * 1 - buffering
     * 2 - playing
     * 3 - paused
     */
    playerState: 0,
    playerId: "",
    /**
     * mode (global.CUST_CONSTANTS.CRUD_MODE) : CRUD MODE (add and edit mostly till now)
     * selectedId (INT) : selected response id
     * isSimpleView (BOOL) : is being performed from simple view or not (if not, considered detailed view)
     * referencePaths (ARRAY) : Array of objects of paths for refrence
     * step (STRING) : step key for call Flow if it's being added in call flow
    for ref : referencePathObj = {
        referenceId : id to be refereed to
        nodeKey : node being refered to
        index : index being refered to
        ruleKey : ruleKey being refered to
        evalKey : evalKey being refered to (will be present only in case of generic responses)
        responseKey : responseKey being refered to
        iterationKey : iterationKey being refered to
        scenario : scenario being refered to (If refering scenario)
        isMetadata (BOOL) : if the referenced part is metadata part or not being refered to
        simpleViewRowIndex: the row id(index) in which it's being loaded in simple view ,
    }
     */
    responseAction: {
        mode: global.CUST_CONSTANTS.CRUD_MODE.NULL,
        selectedId: -1,
        isSimpleView: false,
        step: null,
        referencePaths: [],
    },
    bulkEdit: {
        state: false,
        action: null,
        type: null,
        from: null,
        to: null,
        responseJson: {},
        keyMapper: {},
        selectedNodeBulk: [],
        applyChanges: false,
        ruleResponse: {},
        selectedNodeRules: [],
        removeableResponse: {}

    },
    showIntentInModal: false,
    showResponseModal: false,
    showRulesNodeResModal: false,
    freezeColumnFilter: false,
    editor: {
        text: JSON.stringify({}, null, 2),
    },
    /**
     * Rules and Call Flow states :
     * 0 - No change (initial state)
     * 1 - Rules Json Change
     * 2 - Call Flow Change
     * 3 - Both Changed
     */
    ruleAndCallFlowUpdated: 0,
    storyRuleId: 0,
    callFlow: {
        callFlowUpdated: false,
        callFlowJSON: {},
        callFlowIsDraft: true,
        addingBlock: false,
    },
    /** 
     * To view available json versions, including restore points
     */
    previousJsons: [
        {
            label: global.CUST_CONSTANTS.JSON_TYPE.STAGING.label,
            value: global.CUST_CONSTANTS.JSON_TYPE.STAGING.value,
        },
        {
            label: global.CUST_CONSTANTS.JSON_TYPE.PRODUCTION.label,
            value: global.CUST_CONSTANTS.JSON_TYPE.PRODUCTION.value,
        },
    ],
    selectedJson: {
        label: "",
        value: 0
    },
    saveAsSelected: {
        label: 'Production Json', 
        value: 1
    },
    selectedBlockCallInfo:{},
    jsonName: null,
    callFlowResponseMappings: {},
    unsavedChanges: false,
    configureEntity:false, /* show entity modal flag */
    entities:null,
    entitiesUtterances:null,
    trainedLuisEntities:{},

    untrainedLuisObj:{
       data:untrainedLuisObjInitialData,
       storyId:null,
       isActionEnabled: false,
       isDataChanged:false,
       isIncomingDataExist:null,
       isStoryIdMatch:null,
       storyTitle:null,
       username:null,
       isIncomingDataEmptyObj:false,

    },
    utteranceEntities:{},
    hideStartTrainingButton: false,
    stepCounter: -1, /* for the latest step count to be added */
    accordionState: {},
    stepSequence:[], /* Rendering sequence of callflow steps */
    aiVersion : null
};

const deepCopyNeededKeys = {
    loading: false,
    error: false,
    success: false,
    botResponses: false,
    intents: false,
    statements: false,
    version: false,
    rulesJson: true,
    stringifiedJson: false,
    selectedNode: false,
    selectedRule: true,
    storyId: false,
    audioclipSource: false,
    /**
     * Player states :
     * 0 - ended (initial state)
     * 1 - buffering
     * 2 - playing
     * 3 - paused
     */
    playerState: false,
    playerId: false,
    editor: false,
    callFlowJSON: true,

};


const rulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case SET_ALERT:
            return {
                ...state,
                alert: { ...action.payload },
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload.success,
                loading: false,
            }
        case SET_RULES_VARS:
            var preloaderCounts = state.preloaderCounts;
            if (preloaderCounts < global.CUST_CONSTANTS.PRELOADER_COUNT_NEED) {
                if (
                    action.payload.key === "brandTags" ||
                    action.payload.key === "stringifiedJson" ||
                    action.payload.key === "rulesJson" ||
                    action.payload.key === "intents" 
                    // || action.payload.key === "botResponses"
                ) {
                    preloaderCounts++;
                }
            }
            if (deepCopyNeededKeys[action.payload.key] === true) {
                return {
                    ...state,
                    [action.payload.key]: JSON.parse(JSON.stringify(action.payload.value)),
                    loading: false,
                    preloaderCounts: preloaderCounts,
                }
            } else {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                    loading: false,
                    preloaderCounts: preloaderCounts,
                }
            }

        case SET_RULES_VARS_MULTIPLE:
            return {
                ...state,
                ...action.payload,
                loading: false,
            }
        case SET_RULES_EDITOR_VARS:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.payload.key]: action.payload.value
                },
                loading: false,
            }
        case SET_RESPONSE_MODAL_SHOW:
            return {
                ...state,
                showResponseModal: action.payload.show,
                showIntentInModal: action.payload.showIntent,
                selectedStorySetting: action.payload.selectedStorySetting,
                loading: false,
            }
        case NODE_RESPONSE_BLOCK_SHOW:
            return {
                ...state,
                showRulesNodeResModal: action.payload.show,
                loading: false,
            }

        case SET_RULES_CHANGED:
            return {
                ...state,
                isChanged: action.payload,
                loading: false,
            }

        case SET_SV_ROWS:
            return {
                ...state,
                simpleViewRows: action.payload,
                loading: false,
            }

        case SET_RESPONSE_ACTION:
            if (action.payload.responseActionJson) {
                return {
                    ...state,
                    responseAction: {
                        ...action.payload.responseActionJson,
                    },
                    loading: false,
                }
            } else if (action.payload.selectedId) {
                return {
                    ...state,
                    responseAction: {
                        ...state.responseAction,
                        selectedId: action.payload.selectedId,
                    },
                    loading: false,
                }
            } else {
                /** Resetting response action if null passed */
                return {
                    ...state,
                    responseAction: JSON.parse(JSON.stringify(initialState.responseAction)),
                    loading: false,
                }
            }

        case SET_SELECTION_CHANGE:
            if (action.payload.selectedNode) {
                return {
                    ...state,
                    selectedNode: {
                        ...action.payload.selectedNode,
                    },
                    loading: false,
                }
            } else {
                /** Resetting response action if null passed */
                return {
                    ...state,
                    selectedNode: { ...initialState.selectedNode },
                    loading: false,
                }
            }

        case FREEZE_COLUMN_FILTER:
            if (action.payload) {
                return {
                    ...state,
                    freezeColumnFilter: action.payload,
                }
            } else {
                return {
                    ...state,
                    freezeColumnFilter: false,
                }
            }

        case SET_SELECTED_JSON:
            if (action.payload.selectedJson) {
                return {
                    ...state,
                    selectedJson: action.payload.selectedJson,
                }
            } else {
                return {
                    ...state,
                    selectedJson: {
                        label: global.CUST_CONSTANTS.JSON_TYPE.STAGING.label,
                        value: global.CUST_CONSTANTS.JSON_TYPE.STAGING.value,
                    },
                }
            }
        case SET_SAVE_AS_SELECTED:
            return {
                ...state,
                saveAsSelected: action.payload
            }

        case SET_JSON_NAME:
            return {
                ...state,
                jsonName: action.payload
            }
        case SET_SELECTED_BLOCK_CALL_INFO:
            return {
                ...state,selectedBlockCallInfo:{ ...state.selectedBlockCallInfo,...action.payload}}
        case SET_LOADERS:
            // if (action.payload && action.payload.view) {
            //     if (action.payload.key) {
            //         return {
            //             ...state,
            //             loaders: {
            //                 ...state.loaders,
            //                 [action.payload.view]: {
            //                     ...state.loaders[action.payload.view],
            //                     [action.payload.key] : true,
            //                 }
            //             },
            //         }
            //     }
            //     return {
            //         ...state,
            //         loaders: {
            //             ...state.loaders,
            //             [action.payload.view]: {}
            //         },
            //     }
            // }
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    simpleView: {},
                    detailedView: {},
                },
            }

        case SET_ONGOING_AXIOS_REQUEST:
            if (action.payload.url) {
                return {
                    ...state,
                    ongoingAxiosCalls: {
                        ...state.ongoingAxiosCalls,
                        [action.payload.url]: action.payload.cancelationSource
                    }
                }
            } else {
                return {
                    ...state,
                    ongoingAxiosCalls: {},
                }
            }

        case SET_CALL_FLOW_JSON:
            let newCallFlow = {};
            /** If version present, upgraded call flow needs to be replaced, else just plan */
            if (
                (action.payload.callFlowJSON && action.payload.callFlowJSON.version) ||
                action.payload.saveAsItIs
            ) {
                newCallFlow = JSON.parse(JSON.stringify(action.payload.callFlowJSON));
            } else {
                if (action.payload.callFlowJSON && action.payload.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) {
                    newCallFlow = {
                        version: params.currentCallFlowJsonVersion,
                        ...state.callFlow.callFlowJSON,
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]: JSON.parse(JSON.stringify(action.payload.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW])),
                    }
                } else {
                    const callFlowMetaData = state.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA];
                    delete state.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA];
                    state.callFlow.callFlowJSON = {}
                    newCallFlow = {
                        ...state.callFlow.callFlowJSON,
                        version: params.currentCallFlowJsonVersion,
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]: JSON.parse(JSON.stringify(action.payload.callFlowJSON)),
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]: JSON.parse(JSON.stringify(callFlowMetaData))
                    }
                }
            }

            let newCFMetaData = newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA] && newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']
            if(newCFMetaData && Array.isArray(newCFMetaData)){
                newCallFlow[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence'] = [...new Set(newCFMetaData)];
            }
            return {
                ...state,
                callFlow: {
                    ...state.callFlow,
                    callFlowJSON: newCallFlow,
                    callFlowIsDraft: action.payload.callFlowIsDraft
                }
            }

        case RULES_CALL_FLOW_UPDATED:
            if (action.payload) {
                return {
                    ...state,
                    ruleAndCallFlowUpdated: action.payload
                }
            } else {
                return {
                    ...state,
                    ruleAndCallFlowUpdated: 0
                }
            }

        case SET_CALL_FLOW_MAPPINGS:
            if (action.payload) {
                return {
                    ...state,
                    callFlowResponseMappings: action.payload
                }
            } else {
                return {
                    ...state,
                    callFlowResponseMappings: {}
                }
            }
        case SET_CALL_FLOW_ADDING_BLOCK:
            if (action.payload) {
                return {
                    ...state,
                    callFlow: {
                        ...state.callFlow,
                        addingBlock: action.payload
                    }
                }
            } else {
                return {
                    ...state,
                    callFlow: {
                        ...state.callFlow,
                        addingBlock: false
                    }
                }
            }
        case SET_CALL_FLOW_STEP_SETTINGS:
            /** Call Flow version 1.1 and old support */
            if (action.payload.step && action.payload.settings) {
                let newCallFlowJSON = { ...state.callFlow.callFlowJSON };
                if (newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) {
                    newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW][action.payload.step]["settings"] = JSON.parse(JSON.stringify(action.payload.settings))
                } else {
                    newCallFlowJSON[action.payload.step]["settings"] = JSON.parse(JSON.stringify(action.payload.settings))
                }
                return {
                    ...state,
                    callFlow: {
                        ...state.callFlow,
                        callFlowJSON: { ...newCallFlowJSON }
                    },
                    ruleAndCallFlowUpdated: 2
                }
            }


        case SET_WARN_OVERWRITING:
            if (action.payload) {
                return {
                    ...state,
                    warnOverwriting: action.payload,
                }
            } else {
                return {
                    ...state,
                    warnOverwriting: false,
                }
            }


        case SET_UNSAVED_CHANGES:
            if (action.payload) {
                return {
                    ...state,
                    unsavedChanges: action.payload,
                }
            } else {
                return {
                    ...state,
                    unsavedChanges: false,
                }
            }


        case SET_CALLFLOW_DRAFT:
            if (action.payload) {
                return {
                    ...state,
                    callFlow: {
                        ...state.callFlow,
                        callFlowIsDraft: true
                    }
                }
            } else {
                return {
                    ...state,
                    callFlow: {
                        ...state.callFlow,
                        callFlowIsDraft: false
                    }
                }
            }
        
        case SET_SAMPLE_UTTERANCES:
            console.log('sampleUtterances in reducer ',action.payload.value )
            var preloaderCounts = state.preloaderCounts;
            if (preloaderCounts < global.CUST_CONSTANTS.PRELOADER_COUNT_NEED) {
                if (
                    action.payload.key === "sampleUtterances"
                ) {
                    preloaderCounts++;
                }
            }
            if (deepCopyNeededKeys[action.payload.key] === true) {
                return {
                    ...state,
                    [action.payload.key]: JSON.parse(JSON.stringify(action.payload.value)),
                    loading: false,
                    preloaderCounts: preloaderCounts,
                }
            } else {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                    loading: false,
                    preloaderCounts: preloaderCounts,
                }
            }

        /* Reducer function to handle visibility of Utterance Modal */
        case SET_UTTERANCE_MODAL_SHOW:
            return {
                ...state,
                showUtteranceModal: action.payload.showUtteranceModal,
                statements: action.payload.statements,
                loading: false,
            }
        
        case SET_ENTITIES:
            return {
                ...state,
                entities: action.payload.entities,
            }

        case SET_ENTITIES_UTTERANCES:
            return {
                ...state,
                entitiesUtterances: action.payload.entitiesUtterances,
            }
        /* Reducer function to handle visibility of Utterance Modal */
        case SET_UTTERANCE_SIDE_BAR:
            return {
                ...state,
                showUtteranceSideBar: action.payload.showUtteranceSideBar,
                statements: action.payload.statements,
                loading: false,
            }

        /* Reducer function to handle visibility of utterance modal on adding new node */
        // case SET_NEW_NODE_INFO_SIDE_BAR :
        //     return{
        //         ...state,
        //         showUtteranceSideBar: action.payload.showUtteranceSideBar,
        //         statements: action.payload.statements,
        //         loading: false,
        //     }

        /* Reducer function to handle visibility of utterance modal on adding new node */
        case SET_NEW_NODE_INFO_SIDE_BAR_NEW :
            return{
                ...state,
                showUtteranceSideBarNew: action.payload,
            }


        /* Reducer function to handle visibility of Entity Modal */
        case SET_ENTITY_MODAL_SHOW:
            console.log('showModal in reducer',action.payload.showEntityModal)
            return {
                ...state,
                configureEntity: action.payload.showEntityModal,
                statements: action.payload.statements,
                loading: false,
            }

        /* Reducer function to handle visibility of Existing Rules Modal */
        case SET_EXISTING_RULES_MODAL_SHOW:
            console.log('showModal in reducer',action.payload.showExistingRulesModal)
            return {
                ...state,
                showExistingRulesModal: action.payload.showExistingRulesModal,
                statements: action.payload.statements,
                loading: false,
            }
         
        /* Reducer function to handle visibility of TestPlans */
        
        case SET_TESTPLAN_LOADER:
            return {
                ...state,
                testPlanLoader: action.payload
            }
        case SET_SHOW_TESTPLANS:
            return {
                ...state,
                showTestPlans: action.payload.showTestPlans,
                testPlans: action.payload.testPlans?action.payload.testPlans:state.testPlans,
                testPlanChannel: action.payload.testPlanChannel?action.payload.testPlanChannel:state.testPlanChannel,
                pusherKeys: action.payload.pusherKeys? action.payload.pusherKeys:state.pusherKeys
            }

        case SET_TESTPLANS:
            var testPlans = state.testPlanJsons;
            return{
                ...state,
                testPlanJsons:{...testPlans,...action.payload.value}
            }

        case SET_TESTREPORTS:
            var testReports = state.testReports;
            return{
                ...state,
                testReports:{...testReports,...action.payload.value}
            }
        case SET_ALL_TESTPLANS:
            return{
                ...state,
                allTestPlans: action.payload
            }
        case SET_TESTPLANSDATA_FROM_SAVED:
            return{
                ...state,
                testReports: action.payload.testReports,
                testPlanJsons: action.payload.testPlanJsons
            }
        case SET_SAVED_TESTPLAN_DATA:
            let type = action.payload.type;
            let key = action.payload.key;
            return {
                ...state,
                savedTestPlanData:type==='reset'?{ basic:{}, all:{}, onLoad:false }:{...state.savedTestPlanData,[key]:action.payload.value}
            }

        case SET_SELECT_TESTPLAN:
            return{
                ...state,
                selectedTestPlan:{...action.payload.value}
            }
        
        case TESTPLAN_RESET:
            return{
                ...state,
                showTestPlans:false,
                testPlans:null,
                testPlanJsons:{},
                testReports:{},
                testPlanChannel:null,
                selectedTestPlan:null,
                error: null,
            } 
        case SET_BOT_RESPONSES:
            var preloaderCounts = state.preloaderCounts;
            var botResponsesSyncedAll = state.botResponsesSyncedAll;
            var responsesKeyToPick = state.botResponsesLoadingVars.responsesKeyToPick;
            if (action.payload.baseOnly){
                preloaderCounts++;
            } else {
                botResponsesSyncedAll = true;
                responsesKeyToPick = "botResponses";
            }
            return{
                ...state,
                botResponses: {...action.payload.responses},
                preloaderCounts: preloaderCounts,
                botResponsesLoadingVars:{
                    ...state.botResponsesLoadingVars,
                    botResponsesSyncedAll: botResponsesSyncedAll,
                    responsesKeyToPick: responsesKeyToPick
                }
            } 
        case SET_FILTERED_RESPONSES:
            var botResponses = state.botResponses;
            if (action.payload.fullData) {
                botResponses = { ...state.botResponses,  ...action.payload.responses }
            }
            return{
                ...state,
                botResponses: botResponses,
                filteredBotResponses: {...action.payload.responses},
                botResponsesLoadingVars: {
                    ...state.botResponsesLoadingVars,
                    filteringInProgress: false,
                    lastFilteredPage: 1
                }
            }
        case EMPTY_FILTERED_RESPONSES:
            return {
                ...state,
                filteredBotResponses: {},
                botResponsesLoadingVars: {
                    ...state.botResponsesLoadingVars,
                    filteringInProgress: false,
                    lastFilteredPage: 0
                }
            } 
        case ADD_FILTERED_RESPONSES:
            return{
                ...state,
                filteredBotResponses: { ...state.filteredBotResponses, ...action.payload.responses},
                botResponsesLoadingVars: {
                    ...state.botResponsesLoadingVars,
                    filteringInProgress: false,
                    lastFilteredPage: action.payload.page
                }
            } 
        
        case SET_FILTERING_RESPONSES_IN_PROGRESS:
            return {
                ...state,
                botResponsesLoadingVars: {
                    ...state.botResponsesLoadingVars,
                    filteringInProgress: true
                }
            }
        
        case SET_BULK_EDIT:
            return {
                ...state,
                bulkEdit: { ...action.payload },
            }


        case SET_UNTRAINED_LUIS_OBJ_ADD_INTENT:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                  data: {
                    ...state.untrainedLuisObj.data,
                    ADD: { ...state.untrainedLuisObj.data.ADD, intents: [...state.untrainedLuisObj.data.ADD.intents, action.payload] }
                  },
                  isDataChanged:true,    
                }
            }

        case SET_UNTRAINED_LUIS_OBJ_ADD_UTTERANCE:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                   data:{
                    ...state.untrainedLuisObj.data, ADD:{...state.untrainedLuisObj.data.ADD,utterances:[...state.untrainedLuisObj.data.ADD.utterances,action.payload]}
                   },
                   isDataChanged:true, 
                }
            }
        case SET_UNTRAINED_LUIS_OBJ_REMOVE_UTTERANCE:
            const filteredUtterances = state.untrainedLuisObj.data.ADD.utterances.filter(item => item.tempId !== action.payload)
               
            return {
                    ...state,
                    untrainedLuisObj: {
                        ...state.untrainedLuisObj,
                       data:{
                        ...state.untrainedLuisObj.data,
                        ADD:{...state.untrainedLuisObj.data.ADD,utterances:filteredUtterances}
                       },
                       isDataChanged:true, 
                    }
            }
        
        case SET_UNTRAINED_LUIS_OBJ_REMOVE_INTENT_FROM_ADD:
            const filteredIntents = state.untrainedLuisObj.data.ADD.intents.filter(intent => intent.name !== action.payload)
               
            return {
                    ...state,
                    untrainedLuisObj: {
                        ...state.untrainedLuisObj,
                       data:{
                        ...state.untrainedLuisObj.data,
                        ADD:{...state.untrainedLuisObj.data.ADD,intents:filteredIntents}
                       },
                       isDataChanged:true, 
                    }
            }
        
        case REMOVE_INTENTS_UTTERANCES_FROM_ADD_AND_DELETE:

            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                   data:{
                    ...state.untrainedLuisObj.data,
                    ADD:{...state.untrainedLuisObj.data.ADD,utterances:action.payload.filteredUtterancesInAddState},
                    DELETE:{...state.untrainedLuisObj.data.DELETE,utterances:action.payload.filteredUtterancesInDeleteState}
                   },
                   isDataChanged:true, 
                }
        }

        case SET_DELETE_SAMPLE_UTTERANCE:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                    data:{
                        ...state.untrainedLuisObj.data,
                        DELETE:{...state.untrainedLuisObj.data.DELETE,utterances:[...state.untrainedLuisObj.data.DELETE.utterances,action.payload]}
                    },
                    isDataChanged:true, 
                }
            }
            
        case SET_UNTRAINED_LUIS_OBJ_STATE:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                    data:action.payload,
                }
            }
        
        case SET_UNTRAINED_LUIS_OBJ_STORY_TITLE:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                    storyTitle: action.payload.storyTitle
                }
            }

        case SET_UNTRAINED_LUIS_OBJ_USERNAME:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                    username: action.payload.username
                }
            }
        
        case RESET_UNTRAINED_LUIS_OBJ_STATE_DATA:
            return {
                ...state,
                untrainedLuisObj:{
                    ...state.untrainedLuisObj,
                    data:untrainedLuisObjInitialData,
                }
            }
        case CHANGE_ACTIONS_FOR_UNTRAINED_LUIS_OBJ_STATE:
            return {
                ...state,
                untrainedLuisObj:{
                    ...state.untrainedLuisObj,
                    isActionEnabled:action.payload
                }
            }
        case RESET_IS_CHANGED_FOR_UNTRAINED_LUIS_OBJ_STATE:
            return {
                ...state,
                untrainedLuisObj:{
                    ...state.untrainedLuisObj,
                    isDataChanged:action.payload
                }
            }
        case IS_INCOMING_LUIS_DATA_EXIST:
            return  {
                ...state,
                untrainedLuisObj:{
                    ...state.untrainedLuisObj,
                    isIncomingDataExist:action.payload
                }
            }
        case IS_STORY_ID_MATCH_IN_LUIS_DATA:
                return  {
                    ...state,
                    untrainedLuisObj:{
                        ...state.untrainedLuisObj,
                        isStoryIdMatch:action.payload
                    }
                }
        case SET_STEP_COUNTER:
            return {
                ...state,
                stepCounter: action.payload
            }
        case SET_ACCORDION_STATE:
            return {
                ...state,
                accordionState: action.payload.isNew ? 
                {...action.payload.value} :
                {...state.accordionState,...action.payload.value}
            }
        case SET_STEP_SEQUENCE:
            return {
                ...state,
                stepSequence: action.payload


            }


        case SET_STEP_COUNTER:
            return {
                ...state,
                stepCounter: action.payload
            }
        case SET_ACCORDION_STATE:
            return {
                ...state,
                accordionState: action.payload.isNew ? 
                {...action.payload.value} :
                {...state.accordionState,...action.payload.value}
            }
        case SET_STEP_SEQUENCE:
            return {
                ...state,
                stepSequence: action.payload
            }
      


        case SET_TRAINED_LUIS_ENTITIES:
            return {
                ...state,
                trainedLuisEntities: action.payload
            }

        case SET_ADD_UNTRAINED_ENTITY:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                  data: {
                    ...state.untrainedLuisObj.data,
                    ADD: { ...state.untrainedLuisObj.data.ADD, entities: [...state.untrainedLuisObj.data.ADD.entities, action.payload] }
                  },
                  isDataChanged:true,
                }
            }
        case SET_ADD_UTTERANCE_ENTITIES:
            return {
                 ...state,utteranceEntities:{...action.payload}
            }

        case SET_EDIT_UTTERANCE_LUIS_OBJ_STATE_DATA:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                  data: {
                    ...state.untrainedLuisObj.data,
                    EDIT: { ...state.untrainedLuisObj.data.EDIT, utterances: action.payload }
                  },
                  isDataChanged:true,
                }
            }
        
         case SET_ADD_UTTERANCE_LUIS_OBJ_STATE_DATA:
            return {
                ...state,
                untrainedLuisObj: {
                    ...state.untrainedLuisObj,
                  data: {
                    ...state.untrainedLuisObj.data,
                    ADD: { ...state.untrainedLuisObj.data.ADD, utterances: action.payload }
                  },
                  isDataChanged:true,
                }
            }
        case SET_AI_VERSION:
            return {
                ...state,
                aiVersion:action.payload
                
            }

            case IS_INCOMING_LUIS_DATA_EMPTY_OBJ:
                return  {
                    ...state,
                    untrainedLuisObj:{
                        ...state.untrainedLuisObj,
                        isIncomingDataEmptyObj:action.payload
                    }
                }
            case HIDE_START_TRAINING_BUTTON: 
                return {
                    ...state,
                    hideStartTrainingButton: action.payload
                }

        

        default:
            return state;
    }
};


export default rulesReducer;