export const importableProperties = {
    INTENTS: "INTENTS",
    ENTITIES: "ENTITIES",
    UTTERANCES: "UTTERANCES"
}

export const syncingStepsDict = {
    EXPORT_JSON: {
        stepNo: 1,
        description: ""
    },
    MAKE_OLD_INACTIVE: {
        stepNo: 2,
        description: ""
    },
    EXPORT_UTTERANCES: {
        stepNo: 3,
        description: ""
    },
    IMPORT_INTENTS: {
        stepNo: 4,
        description: ""
    },
    IMPORT_ENTITIES: {
        stepNo: 5,
        description: ""
    },
    IMPORT_UTTERANCES: {
        stepNo: 6,
        description: ""
    },
    SYNC_PROCESS_END : {
        stepNo: 7,
        description: ""
    },
}

export const getNoOfExpectedCalls = (totalPropCount, limit) => {
    let noOfExpectedCalls = Math.ceil(totalPropCount/limit);
    return noOfExpectedCalls;
}

export const makeMultipleApiCalls = (noOfExpectedCalls, funcToCall, appDbId, moduleSessionId, limit, offset, property=null) => {
    for (let index = 0; index < noOfExpectedCalls; index++) {
        let limit = limit;
        let offset = (index * limit);
        if (this.props.propsRedux.syncingStep == syncingStepsDict.EXPORT_UTTERANCES.stepNo) {
            this.props.exportUtterances( this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
        }
        if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_INTENTS.stepNo) {
            this.props.importProp(importableProperties.INTENTS ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
        }
        if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_ENTITIES.stepNo) {
            this.props.importProp(importableProperties.ENTITIES ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
        }
        if (this.props.propsRedux.syncingStep == syncingStepsDict.IMPORT_UTTERANCES.stepNo) {
            this.props.importProp(importableProperties.UTTERANCES ,this.props.propsRedux.appDbId, this.props.propsRedux.moduleSessionId, limit, offset);
        }
    }
}