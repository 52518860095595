function replaceUrl(url, data) {
    // Create regex using the keys of the replacement object.
    var regex = new RegExp('{{(' + Object.keys(data).join('|') + ')}}', 'g');

    // Replace the string by the value in object
    return url.replace(regex, (m, $1) => data[$1]);
}


const getApiUrl = (base, apiUrl, data) => {
    let url = "";
    apiUrl = base + "/" + apiUrl;
    url = replaceUrl(apiUrl, data);
    return url;
}

export default getApiUrl 