/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import PropTypes from 'prop-types';

/** Importing custom Components */
import Select from "../RulesSelect/RulesSelect";

/** Importing custom Styles */

/** Main Components */
const ResponseTypes = props => {
    
    const responseTypes = [
        {
            value: "failureResponse",
            text: "Failure"
        },
        {
            value: "successResponse",
            text: "Success"
        },
        {
            value: "defaultResponse",
            text: "Default"
        }
    ];

    return (
        <Select 
            selectedValue={ props.selectedValue }
            options={ responseTypes }
            name={ props.name }
            onChange={ props.onChange }
            dataRulekey={ props.dataRulekey }
            readOnly={ props.readOnly }
            dataNode={ props.dataNode }
        />
    );
}

/** Defining prop types requirements */
ResponseTypes.propTypes = {
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

/** Exporting main component */
export default ResponseTypes;