import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import SearchableSelect from "react-select";
import { confirmAlert } from 'react-confirm-alert';
import Icon from 'react-fa';
import { setSelectedRule, setResponseAction, showResponseModal, getRules, setCallFlowJson, setCallFlowMapping,resetBootStrapedCallFlow, setStepSequence, setAccordionState } from "../../../../../store/actions/rules/rulesActions";
import { syncCallFlowWithRuleResponses } from '../../../../../utils/callFlowUtils';
import Modal from "../../../../UI/Modal/Modal";
import SyncPopUp from "../../../SyncPopUp/SyncPopUp";
import classes from './TestStaging.module.css'
import { getPhpUrl } from "../../../../../utils/utils";
import { messages } from "../../../../../data/strings";






class TestStaging extends React.Component {
        /** Lifecycle methods start */
        constructor (props) {
            super(props);
            ReactTooltip.rebuild();

            this.state = {
                testStagingModalShow: false,
                pathToPractice: this.testTypeOptionSelect(true, false),
            }
    
            this.confirmResetAlert = {
                title: 'Reset Call Flow',
                message: 'This will reset your call flow to default call flow made at bootstrapping level and you will loose your current changes in the call flow. This is not going to affect rules directly.',
                buttons: [
                  {
                    label: 'Sync Call Flow',
                    className: [ "", "" ].join(' '),
                    onClick: this.syncCallFlow
                  },
                  {
                    label: 'Start Over',
                    className: [ classes.BtnStartOver, "btn-danger" ].join(' '),
                    onClick: this.resetCallFlow
                  },
                  {
                    label: 'Cancel',
                    onClick: () => {}
                  }
                ],
                childrenElement: () => <div />,
                customUI: ({ onClose }) => <SyncPopUp onClose={ onClose } syncCallFlow={ this.syncCallFlow } resetCallFlow={ this.resetCallFlow } />,
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => {},
                afterClose: () => {},
                onClickOutside: () => {},
                onKeypressEscape: () => {}
            };
        }

    resetCallFlow = () => {
        global.recordAudit("Reset call flow clicked for rule id : " + this.props.propsRedux.storyRuleId);
        this.props.resetBootStrapedCallFlow(this.props.propsRedux.storyId, this.props.propsRedux.storyRuleId);
    }

    syncCallFlow = () => {
        global.recordAudit("Sync call flow clicked for rule id : " + this.props.propsRedux.storyRuleId);
        let { newCallFlowJson, newCallFlowMapping, isChanged, stepSequence, accordionStates} = syncCallFlowWithRuleResponses({rulesJson: this.props.propsRedux.rulesJson, callFlowJson: this.props.propsRedux.callFlowJSON, botResponses: this.props.propsRedux.botResponses, stepSequence: this.props.propsRedux.stepSequence});
        this.props.setStepSequence(stepSequence);
        this.props.setCallFlowJson(newCallFlowJson);
        this.props.setCallFlowMapping(newCallFlowMapping);
        this.props.setAccordionState(accordionStates, true)
    }

    testTypeOptionSelect = (isChat=true, setState=true) => {
        var parameters = {
            "story_id": this.props.propsRedux.storyId,
            "source": "home",
            "recordingType": 4,
            "assignmentId": 0,
            "isStaging": 1,

        }
        if (isChat) {
            parameters["storyType"] = 3; 
        }
        var link = getPhpUrl("video/create", parameters);
        if (setState) {
            this.setState({pathToPractice: link});
        }
        return link;
    }

    onTestModalHide = () => {
        this.testTypeOptionSelect();
        this.setState({testStagingModalShow:false});
    }
    render(){
        const testStagingModalButtons = [
            {
                type: 'htmlLink',
                text: 'Test',
                variant: 'primary',
                otherProps: {
                    href: this.state.pathToPractice,
                    target: "_blank",
                },
                className: [ classes.TestLink, "btn", "btn-primary", "assign-button" ].join(' '),
                isDisabled: ( ((this.props.propsRedux.saveAsSelected.value < 2) || ((this.props.propsRedux.saveAsSelected.value == 2) && (this.props.propsRedux.jsonName != null ))) ) ? false : true,
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: () => { this.setState({testStagingModalShow:false}) },
            }
        ];
        return(
            <div className={ [ classes.ControlGrp, classes.InJsonActionGrp, classes.JsonHistory, "ml-3" ].join(' ') }>

                <Icon 
                    name="medkit" 
                    className={ [ classes.SyncIcon,"mr-2" ].join(' ') } 
                    data-tip={ messages.syncCFRules } data-class="tooltip_cust"  data-place="top" 
                    onClick={ () => {confirmAlert(this.confirmResetAlert)} }
                />
                {
                    ( this.props.propsRedux.selectedJson ) &&
                    <SearchableSelect 
                        name="viewJsonType" 
                        value={ this.props.propsRedux.selectedJson || '' }
                        options={ this.props.propsRedux.previousJsons }
                        className={ [ classes.JsonSelect ].join(' ') } 
                        onChange={ (opt) => this.props.getRules( this.props.propsRedux.storyId, opt.value) }
                    />
                }
                {
                    ( this.props.propsRedux.selectedJson ) &&
                    ( this.props.propsRedux.selectedJson.label === global.CUST_CONSTANTS.JSON_TYPE.STAGING.label ) &&
                    <button onClick={ ()=>{ this.setState({testStagingModalShow:true}) } } className={ [ classes.TestLink, "btn", "btn-primary", "assign-button" ].join(' ')}>
                        <Icon name="external-link" className={ [ '' ].join(' ') } />
                        Test Staging Json
                    </button>
                }
                <Modal
                    show={ this.state.testStagingModalShow }
                    onShow={ () => {} }
                    title={ "Test Staging Json" }
                    onHide={ this.onTestModalHide }
                    footerComponents={ testStagingModalButtons }
                >
                    <div>
                        <h5>Which Type of practice session you want to pursue in test ?</h5>
                        <div>
                            <input type="radio" id="chat" name="testStagingJsonType" value="3" defaultChecked ={ true } onClick={ () => this.testTypeOptionSelect(true) } /> Chat
                            &nbsp;&nbsp;
                            <input type="radio" id="voice" name="testStagingJsonType" value="4" onClick={ () => this.testTypeOptionSelect(false) } /> Voice
                        </div>
                    </div>
                </Modal>
            </div>
        )

    }
}

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
            storyId: state.story.storyId,
            storyType: state.story.storyType,
            storyRuleId: state.rules.storyRuleId,
            isDraftInDb: state.rules.isDraftInDb,
            selectedRule: state.rules.selectedRule,
            showIntentModal: state.rules.showIntentModal,
            showUtteranceModal: state.rules.showUtteranceModal,
            previousJsons: state.rules.previousJsons,
            selectedJson: state.rules.selectedJson,
            isCallFlowDraftInDb: state.rules.callFlow.callFlowIsDraft,
            callFlowJSON: state.rules.callFlow.callFlowJSON,
            callFlowMapping: state.rules.callFlowResponseMappings,
            ruleAndCallFlowUpdated: state.rules.ruleAndCallFlowUpdated,
            botResponses: state.rules.botResponses,
            statements:state.rules.statements,
            testPlans:state.rules.testPlans,
            showTestPlans:state.rules.showTestPlans,
            saveAsSelected: state.rules.saveAsSelected,
            jsonName: state.rules.jsonName,
            stepSequence: state.rules.stepSequence
        }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  setSelectedRule, setResponseAction, showResponseModal,getRules, setCallFlowJson, setCallFlowMapping, resetBootStrapedCallFlow, setStepSequence, setAccordionState })(TestStaging); 