import { importableProperties, syncingStepsDict } from "../../../utils/luisAppUtils";
import { APP_SYNCING, FETCH_PERCENTAGE, INCREMENT_ONGOING_STEP, INCREMENT_SYNCING_STEP, RESET_LUIS_APP_COUNTS, SET_APP_DB_ID, SET_APP_ID, SET_APP_NAME, SET_LUIS_APP, SET_MODULE_SESSION_ID, SET_TOTAL_COUNTS, SYNCING_ERROR_OCCURED } from "../../actions/types";
import {IS_APP_IN_SYNC,SET_TOTAL_DATA_COUNT} from  "../../actions/types";
const initialState = {
    appId: null,
    appName: null,
    inSync: false,
    appDbId: 0,
    moduleSessionId: "",
    syncingInProgress: false,
    syncingError: false,

    percentageFetch:0,
    /**
     * Step no. of syncing in progress
     * 1: Export app json complete
     */
    syncingStep: 0,
    totalPropsCount: {
        [importableProperties.INTENTS]: 0,
        [importableProperties.ENTITIES]: 0,
        [importableProperties.UTTERANCES]: 0,
    },
    ongoingCallCount: {
        [syncingStepsDict.EXPORT_UTTERANCES.stepNo]: 0,
        [syncingStepsDict.IMPORT_INTENTS.stepNo]: 0,
        [syncingStepsDict.IMPORT_ENTITIES.stepNo]: 0,
        [syncingStepsDict.IMPORT_UTTERANCES.stepNo]: 0,
    },
    isAppInSync:true,
    
};


const rulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_ID:
            return {
                ...state,
                appId: action.payload,
            }
        case SET_APP_DB_ID:
            return {
                ...state,
                appDbId: action.payload,
            }
        case SET_APP_NAME:
            return {
                ...state,
                appName: action.payload,
            }
        case SET_MODULE_SESSION_ID:
            return {
                ...state,
                moduleSessionId: action.payload,
            }
        case APP_SYNCING:
            return {
                ...state,
                syncingInProgress: action.payload
            }
        case SYNCING_ERROR_OCCURED:
            return {
                ...state,
                syncingError: action.payload,
                syncingInProgress: false,
            }
        case SET_TOTAL_COUNTS:
            return {
                ...state,
                totalPropsCount: {
                    ...state.totalPropsCount,
                    [action.payload.key]: action.payload.value
                },
            }
        case INCREMENT_SYNCING_STEP:
            return {
                ...state,
                syncingStep: (state.syncingStep+1),
            }
        case INCREMENT_ONGOING_STEP:
            return {
                ...state,
                ongoingCallCount: {
                    ...state.ongoingCallCount,
                    [action.payload]: (state.ongoingCallCount[action.payload] + 1)
                },
            }

        case FETCH_PERCENTAGE:
            return{
                ...state,
                percentageFetch:action.payload
            }

            
        case RESET_LUIS_APP_COUNTS:
            return {
                ...state,
                syncingInProgress: false,
                syncingStep: 0,
                totalPropsCount: {
                    [importableProperties.INTENTS]: 0,
                    [importableProperties.ENTITIES]: 0,
                    [importableProperties.UTTERANCES]: 0,
                },
                ongoingCallCount: {
                    [syncingStepsDict.EXPORT_UTTERANCES.stepNo]: 0,
                    [syncingStepsDict.IMPORT_INTENTS.stepNo]: 0,
                    [syncingStepsDict.IMPORT_ENTITIES.stepNo]: 0,
                    [syncingStepsDict.IMPORT_UTTERANCES.stepNo]: 0,
                },
            }

        case IS_APP_IN_SYNC:
            return {
                ...state,
                isAppInSync:action.payload
            }

        case SET_TOTAL_DATA_COUNT:
            return {              
                ...state,
                totalPropsCount: {
                    ...state.totalPropsCount,
                    [action.payload.key]: action.payload.value
                },
            }
        default:
            return state;
    }
};


export default rulesReducer;