/** Importing pre defined Libraries/Components */
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import Icon from 'react-fa';


/** Importing custom Components */

import TodoList from './TodoList/TodoList';

/** Importing custom Styles */
import classes from './NewTopbar.module.css';

/** Main Components */
const NewTopbar = (props) => {
  
    return (
        <Navbar expand="lg" className={["bg-shadow bg-rounded border-0",classes.NewTopbar].join(' ')} fixed="top">
                    
                    <Navbar.Brand href={ window.location.origin + "/index.php?r=home/index"}><img src={require("../../../assets/images/Zenarate_logo.png")} style={{height:'30px'}}/></Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />                     */}
                    {/* <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto mr-3">
                        </Nav>
                    </Navbar.Collapse> */}
        </Navbar>
    );
}

/** Defining prop types requirements */
NewTopbar.propTypes = {
    showNotifications: PropTypes.bool.isRequired,
};

/** Exporting main component */
export default NewTopbar;