/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";

/** Importing custom Components */

/** Importing custom Styles */
import RulesResponses from "../../../RulesResponses/RulesResponses";
import ResponseTypes from "../../../Inputs/ResponseTypes/ResponseTypes";
import IterationTypes from "../../../Inputs/IterationTypes/IterationTypes";
import Prioirity from "../../../Inputs/Prioirity/Prioirity";
import { setBulkEdit } from '../../../../../store/actions/rules/rulesActions';

/** Main Components */
import classes from './../GenericTable/Generic.module.css'
import { params } from '../../../../../data/params';

class AddOnTagRulesRow extends React.Component {
    constructor (props) {
        super(props);
        this.state = {checked:false, eventName:null};
        this.separator = params.callFlowSeparator;
    }
    
    handleCheck = (e) => {
        this.setState({ checked: !this.state.checked, eventName: e.target.name });
    }
    componentDidUpdate = (prevProps, prevState) => {
            if(prevState.checked !== this.state.checked){
                let selectNode = this.props.bulkEdit.selectedNodeBulk?this.props.bulkEdit.selectedNodeBulk:[];
                if(this.state.checked){
                    selectNode.push(this.state.eventName);
                    this.props.setBulkEdit({...this.props.bulkEdit,selectedNodeBulk:selectNode});
                }else{
                    this.props.setBulkEdit({...this.props.bulkEdit,
                    selectedNodeBulk: selectNode.filter(x => x !== this.state.eventName)});
                }
                
            }
        }

  render() {
    let separator = params.callFlowSeparator;
        return (
      <tr key={"response_row_" + this.props.ruleKey}>
        {
          this.props.bulkEdit && 
          this.props.bulkEdit.state && 
          !this.props.bulkEdit.applyChanges && 

          (
          <td>
            <input
              type="checkbox"
              className={classes.CheckBox}
              name={  ( (this.props.propsOwned.isGlobal) ? "global_" : "") +  ("addon"+separator+this.props.ruleKey+this.separator+this.props.node) }
              onChange={this.handleCheck}
              defaultChecked={this.state.checked}
              disabled={ this.props.enable }
            />
          </td>
        )}
        {!this.props.selectedNode.isMetadata &&
          this.props.ruleKey !== "tagRule:scenarioRule" && (
            <td> {this.props.ruleKey} </td>
          )}
        {!this.props.selectedNode.isMetadata && (
          <td>
            <Prioirity
              name="priority"
              selectedValue={this.props.responsePriority}
              onChange={this.props.onInputValueChange}
              dataRulekey={this.props.ruleKey}
            />
          </td>
        )}
        <td>
          <ResponseTypes
            name="responseType"
            selectedValue={this.props.responseKey}
            onChange={this.props.onInputValueChange}
            dataRulekey={this.props.ruleKey}
            readOnly={this.props.selectedNode.isMetadata}
          />
        </td>
        <td>
          <IterationTypes
            name="iterationType"
            selectedValue={this.props.iterationType}
            onChange={this.props.onInputValueChange}
            dataRulekey={this.props.ruleKey}
            readOnly={this.props.selectedNode.isMetadata}
          />
        </td>
        <td className={["no-padding"].join(" ")}>
          <RulesResponses
            isAddResponseVisible={true}
            responsesArr={this.props.responses}
            showResponseModal={this.props.showResponseModal}
            dataRulekey={this.props.ruleKey}
            iterationKey={this.props.iterationType}
            responseKey={this.props.responseKey}
          />
        </td>
      </tr>
    )
    }
}
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            bulkEdit: state.rules.bulkEdit,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setBulkEdit })(AddOnTagRulesRow);
