global.CUST_CONSTANTS = {
    CRUD_MODE: {
        NULL: "NULL",
        ADD: "ADD",
        EDIT: "EDIT",
        DELETE: "DELETE",
    },
    OTHER_ACTIONS: {
        SWITCH: "SWITCH",
        CLICK: "CLICK",
    },
    DEFAULT_KEYS: {
        RESPONSE_TYPE: "defaultResponse",
        ITERATION_TYPE: "sequence",
        EVAL_TYPE: "evalTrue",
        RULE_KEY: "generic",
    },
    READY_STATE: "ready",

    PREDEFINED_KEYS: {
        RESPONSE_TYPE: {
            DEFAULT_RESPONSE: "defaultResponse",
            SUCCESS_RESPONSE: "successResponse",
            FAILURE_RESPONSE: "failureResponse",
        },
        ITERATION_TYPE: {
            RANDOM: "random",
            RANDOM_ONCE: "random_once",
            SEQUENCE: "sequence"
        },
        RESPONSE_OBJECT_KEYS: {
            RESPONSE_TEXT: "BRText",
            RESPONSE_CLIP: "BRClip",
            RESPONSE_ID: "id",
        },
        RULE_KEY: {
            GENERIC: "generic"
        },
        EVAL_KEYS: {
            EVAL_TRUE: "evalTrue",
            EVAL_FALSE: "evalFalse",
        },
        GLOBAL_RULE: "globalRule",
        CALL_FLOW: {
            STEPS_INSIDE_CALLFLOW: "callPlan",
            META_DATA: "metadata",
            MAPPER_IN_STEP: "ruleResponsePath",
            UTTERANCES_DATA: "data",
            UTTERANCE_TEXT: "utterance_text",
            UTTERANCE_ID:"utterance_id",
            RELATED_INTENT: "intent",
            SAMPLE_UTTERANCES:"sampleUtterances",
            UTTERANCE__TEXT:"utteranceText",
            DEFAULT_UTTERANCE:"defaultUtterance",
            SETTINGS: "settings",
            INTERRUPTION: "interruption",
        },
        TEST_PLAN: {
            REPORT_JSON: "detail_json",
        }
    },
    // add these keys also in pre defined keys
    OPENING_STATEMENT_NODEKEY: "opening_statement",
    STORY_NONE_RESPONSE: "None",
    STORY_EVAL_FALSE_RESPONSE: "default_eval_false_response",
    SAMPLE_NODEKEY: "sample_node",
    CORRUPTED_RESPONSE_TEXT: "Corrupted Response",
    NO_AUDIO_CLIPS: "No Audio Clips Found!",
    // make all predefined keywords list and use that in code
    PASSABLE_RESPONSE_KEY: "passable",
    PRELOADER_COUNT_NEED: 6,
    JSON_TYPE: {
        STAGING:{ 
            value: 0,
            label: 'Staging',
        }, 
        PRODUCTION:{ 
            value: 1,
            label: 'Production',
        }, 
        RESTORE_POINT:{ 
            value: 2,
            label: 'Restore Point',
        }, 
    },
    RECORD_AUDIT_URL: 'common/record-audit',
    KEEP_ALIVE_URL: 'site/keepalive',
    ACTION_LEVEL: {
        NODE: "NODE",
        RESPONSE: "RESPONSE",
        RESPONSE_TYPE: "RESPONSE_TYPE",
        ITERATION_TYPE: "ITERATION_TYPE",
        STATEMENT_TYPE: "STATEMENT_TYPE",
        CALL_FLOW_INTERRUPTION: "CALL_FLOW_INTERRUPTION",
    },
    ELEMENT_TYPE:{
        RESPONSE: "RESPONSE",
        INTENT: "INTENT",
    },
    SIDEBAR_JSON:{
        RULES:[
            {
                text: "Response View",
                tab: 0,
                icon: "fluent:calendar-reply-16-regular"
            },
            {
                text: "Detailed View",
                tab: 1,
                icon: "clarity:details-line"
            },
            {
                text: "Call Flow",
                tab: 2,
                icon: "entypo:flow-cascade"
            },
            {
                text: "Test Report",
                tab: 3,
                icon: "carbon:report"
            }            
        ]

        
    },


    // Train and publish modal
    GENERAL_STATUS: {
        ERROR: "ERROR"
    },
    UNSAVED_CHANGES: {
        NOT_SAVED: 0,
        SAVED: 1,
        SAVING: 2
    },
    DATA_LOADED: {
        LOADING: 0,
        LOADED: 1,
        NOT_LOADED: 2
    },
    BTN_ACTIONS: {
        START_FRESH: "START_FRESH",
        START_OVER: "START_OVER",
        RESUME: "RESUME"
    },
    NAVIGATION_BTN_ACTIONS: {
        NEXT: "NEXT",
        PREV: "PREV"
    },
    SYNC_STATUS: {
        SYNCED: "SYNCED",
        NOT_SYNCED: "NOT_SYNCED",
        CHECKING: "CHECKING",
        NEED_TO_CHECK: "NEED_TO_CHECK",
    },
    EXPORT_APP_JSON_TYPE: {
        PUBLISHED_LATER: "publishedLater",
        NULL_EXPORTED_TIME: "nullExportedTime",
        TRAIN_N_PUBLISH_STEPS: "trainNPublish"
    },
    /** msg, name and desc not being used anywhere currently */
    TRAIN_N_PUBLISH_STEPS: {
        UPDATE_TRAINING_DATA:{
             /** Exporting 0.1 (default version) only */
             stepName: "UPDATE_TRAINING_DATA",
             stepNo: 1,
             msg: "Saving Data",
             desc: "Saving trained utterances",
        },
        GET_VERSION_LIST: {
            /** Exporting 0.1 (default version) only */
            stepName: "GET_VERSION_LIST",
            stepNo: 2,
            msg: "Exporting App",
            desc: "Getting Version list",
        },

        CHECK_VERSION_INFO: {
            stepName: "CHECK_VERSION_INFO",
            stepNo: 3,
            msg: "Checking Version and Exporting App",
            desc: "checking & updating prod version info",
        },
        
        CREATE_BACKUP: {
            /** Exporting 0.1 (default version) only */
            stepName: "CREATE_BACKUP",
            stepNo: 4,
            msg: "Exporting App",
            desc: "Exporting App",
        },
        // EXPORT_APP: {
        //     /** Exporting 0.1 (default version) only */
        //     stepName: "EXPORT_APP",
        //     stepNo: 5,
        //     msg: "Exporting App",
        //     desc: "Exporting App",
        // },
        CREATE_NEW_DATASET: {
            stepName: "CREATE_NEW_DATASET",
            stepNo: 5,
            msg: "Creating New Dataset",
            desc: "Creating New Dataset",
        },
        IMPORT_NEW_JSON: {
            /** Returning the new version */
            stepName: "IMPORT_NEW_JSON",
            stepNo: 6,
            msg: "Importing Dataset",
            desc: "Importing Dataset",
        },
        START_TRAIN_HIT: {
            stepName: "START_TRAIN_HIT",
            stepNo: 7,
            msg: "Training",
            desc: "Training started",
        },
        TRAINING_STATUS_CHECK: {
            stepName: "TRAINING_STATUS_CHECK",
            stepNo: 8,
            msg: "Training",
            desc: "Training status check",
        },
        PUBLISH: {
            stepName: "PUBLISH",
            stepNo: 9,
            msg: "Publishing",
            desc: "Publishing",
        },
        // DELETE_OLD: {
        //     stepName: "DELETE_OLD",
        //     stepNo: 11,
        //     msg: "Publishing",
        //     desc: "deleting old version",
        // },
        // RENAME_NEW_TO_ACTIVE: {
        //     stepName: "RENAME_NEW_TO_ACTIVE",
        //     stepNo: 11,
        //     msg: "Publishing.",
        //     desc: "Rename new to 0.1 if not loaded as new",
        // },
        CHECK_INFO: {
            stepName: "CHECK_INFO",
            stepNo: 10,
            msg: "Checking publish.",
            desc: "checking & updating info",
        },
        END_SESSION: {
            stepName: "END_SESSION",
            stepNo: 11,
            msg: "Ending Session",
            desc: "Ending Session"
        },
        DELETE_EXTRA_VERSIONS: {
            stepName: "DELETE_EXTRA_VERSIONS",
            stepNo: 12,
            msg: "Finalising",
            desc: "Rename new to 0.1 if not loaded as new",
        },

        /** Not used now */
        RENAME_OLD_VERSION: {
            stepName: "RENAME_OLD_VERSION",
            stepNo: "-",
            msg: "Finalising",
            desc: "Renaming the old version to some temperory name",
        },
    },
    TRAINING_STATUSES: {
        STATUS_KEYS: {
            "Success": "SUCCESS",
            "UpToDate": "SUCCESS",
            "InProgress": "IN_PROGRESS",
            "Fail": "FAILURE",
        },
        STATUS_VAL: {
            SUCCESS: "SUCCESS",
            FAILURE: "FAILURE",
            IN_PROGRESS: "IN_PROGRESS",
        }
    },

    CHECK_INFO_FROM:{
        ON_LOAD:"ON_LOAD",
        ON_SYNC:"ON_SYNC",
        ON_TRAIN_N_PUBLISH:"ON_TRAIN_N_PUBLISH"
    }
}

