/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

/** Importing custom Components */

/** Importing custom Styles */
import AddOnTagRulesRow from "../AddOnTagRulesRow/AddOnTagRulesRow";
import { getIterationKeyFromObj, getResponseKeyFromObj } from '../../../../../utils/rulesUtils';

/** Main Components */
/** Strictly made for sceario type tag rules for now */
const TagRulesTable = props => {

    const responsePriority = (props.tagRule.priority) ? props.tagRule.priority : 0;
    const ruleKey = "tagRule:scenarioRule";
    const responseKey = getResponseKeyFromObj(props.selectedNode.responseJson);
    const iterationType = getIterationKeyFromObj(props.selectedNode.responseJson[responseKey]);
    const responses = (props.selectedNode.responseJson[responseKey][iterationType]) ? props.selectedNode.responseJson[responseKey][iterationType] : null;

    return (
        <Table striped bordered hover className={ props.className }>
            <thead>
                <tr>
                    <th colSpan={5} > Scenario Rules </th>
                </tr>
                <tr>
                    <th>Priority</th>
                    <th>Response Type</th>
                    <th>Iteration Type</th>
                    <th>Response (s)</th>
                </tr>
            </thead>
            <tbody>
                <AddOnTagRulesRow
                    ruleKey = { ruleKey }
                    responsePriority = { responsePriority }
                    onInputValueChange = { props.onInputValueChange }
                    iterationType = { iterationType }
                    responseKey = { responseKey }
                    responses = { responses }
                    showResponseModal = { props.showResponseModal }
                />
            </tbody>
        </Table>
    );
}

/** Defining prop types requirements */
TagRulesTable.propTypes = {
    tagRule  : PropTypes.object.isRequired,
    selectedNode : PropTypes.object.isRequired,
    onInputValueChange : PropTypes.func.isRequired,
    showResponseModal : PropTypes.func.isRequired,
};

/** Exporting main component */
export default TagRulesTable;