/*
 * Component Description : Intent populated modal used when edit or duplicating node
 * Author: Sanjivini Ahuja
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

/** Importing third party packages  */
import { confirmAlert } from 'react-confirm-alert';
import SearchableSelect from "react-select";

/** Importing custom Components */
import Modal from "../../UI/Modal/Modal";

/** Importing custom Styles */
import classes from './IntentModal.module.css';

import { setRules, setSelectedRule, editIntent, setShowIntentModal } from "../../../store/actions/rules/rulesActions";
import { getIntentsForModal } from "../../../utils/rulesUtils";
import { setAlert } from "../../../store/actions/common/commonActions";
import { messages } from "../../../data/strings";
import { Alert } from 'react-bootstrap';

/** Main Components */
class IntentModal extends React.Component {
    constructor(props) {
        super(props);

        this.intentsForModal = getIntentsForModal(this.props.propsRedux.intents, this.props.propsRedux.rulesJson);

        this.state = {
            newIntentName : null,
            intents : this.intentsForModal,
            selectionError: null
        }
    }

    showConfirmAlert = (newIntent) => {
        confirmAlert({
            title: 'Confirm',
            message: 'This intent is already present in the json. Are you sure you want to replace the existing intent?',
            buttons: [
              {
                label: 'Confirm',
                onClick: () => { this.props.editIntent(newIntent) }
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ]
        });
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        // if ( nextProps.propsRedux.isChanged ) {
        //     var intentsForModal= getIntentsForModal(nextProps.propsRedux.intents, nextProps.propsRedux.rulesJson);
        //     return {intents: intentsForModal};
        // }
        return null;
    }

    onHide = () => {
        global.recordAudit("Intent modal closed");
        this.setState({ newIntentName : null});
        this.props.setShowIntentModal(false, null);
    }

    onSelectIntent = () => {
        global.recordAudit("Intent selected : " + this.state.newIntentName);
        if(this.props.propsRedux.rulesJson.ruleResponses[this.state.newIntentName]){
            this.props.setAlert(messages.intentAlreadyPresent);
            this.onHide();
        } else {
            if(this.props.propsRedux.selectedNode.action == global.CUST_CONSTANTS.CRUD_MODE.EDIT){
                this.props.editIntent(this.state.newIntentName);
                this.onHide();
            } else if(this.props.propsRedux.selectedNode.action == global.CUST_CONSTANTS.CRUD_MODE.ADD){
                this.onAddIntent();
            }
        }
    }
    
    onAddIntent = () => {
        global.recordAudit("Add intent clicked");
        var newRuleJson = JSON.parse(JSON.stringify(this.props.propsRedux.rulesJson));
        if (this.state.newIntentName == global.CUST_CONSTANTS.STORY_NONE_RESPONSE) {
            if (newRuleJson.ruleResponses) {
                if (this.props.propsRedux.selectedNode.responseJson && (Object.keys(this.props.propsRedux.selectedNode.responseJson).length > 0) &&this.props.propsRedux.selectedNode.responseJson.generic &&this.props.propsRedux.selectedNode.responseJson.generic.evalTrue) {
                    newRuleJson.ruleResponses[this.state.newIntentName] = {
                        generic: {
                            evalTrue: JSON.parse(JSON.stringify(this.props.propsRedux.selectedNode.responseJson.generic.evalTrue)),
                            priority: 1
                        }
                    }
                }
            }
        } else {
            newRuleJson.rule[this.state.newIntentName] = JSON.parse(JSON.stringify(this.props.propsRedux.rulesJson.rule[this.props.propsRedux.selectedNode.node]));
            if (newRuleJson.rule[this.state.newIntentName].rule) {
                if (newRuleJson.rule[this.state.newIntentName].rule.intent_compare && newRuleJson.rule[this.state.newIntentName].rule.intent_compare.lhs && newRuleJson.rule[this.state.newIntentName].rule.intent_compare.lhs == "CurrentIntentAttempted") {
                    newRuleJson.rule[this.state.newIntentName].rule.intent_compare.rhs =this.state.newIntentName;
                } else if (newRuleJson.rule[this.state.newIntentName].rule && newRuleJson.rule[this.state.newIntentName].rule.lhs && newRuleJson.rule[this.state.newIntentName].rule.lhs == "CurrentIntentAttempted") {
                    newRuleJson.rule[this.state.newIntentName].rule.rhs =this.state.newIntentName;
                }
            }
            if (newRuleJson.ruleResponses) {
                newRuleJson.ruleResponses[this.state.newIntentName] = JSON.parse(JSON.stringify(this.props.propsRedux.selectedNode.responseJson));
            }
        }

        this.props.setSelectedRule(this.state.newIntentName, selectedRule, false, null, null, this.props.propsRedux.selectedNode.pathsData, this.props.propsRedux.selectedNode.action, this.state.newIntentName);

        this.props.setRules(newRuleJson, global.CUST_CONSTANTS.ACTION_LEVEL.NODE);
        var selectedRule = (newRuleJson.ruleResponses[this.state.newIntentName]) ? { ...newRuleJson.ruleResponses[this.state.newIntentName] } : null;
        this.onHide();
    }
    
    onIntentChange = (selectedJson) => {
        global.recordAudit("Intent selection changed : " + selectedJson.value);
        if(this.props.propsRedux.rulesJson.ruleResponses[selectedJson.value]){
            this.setState({ selectionError : messages.intentAlreadyPresent});
        } else {
            this.setState({ newIntentName : selectedJson.value, selectionError: null});
        }
    }

    formatOptionLabel = ({label, isDisabled}) => (
        <div className= { (isDisabled == true) ? [ classes.intentInJson ].join(' ') : '' }>
            <span>{ label }</span>
        </div>
    );

    render() {
        var modalButtons = [
            {
                type: 'add',
                text: 'Add Intent',
                variant: 'primary',
                onClick: this.onSelectIntent,
                className: 'gl-btnPrimaryCust',
                isDisabled: ((this.state.newIntentName == null) || (this.state.selectionError != null)) ? true : false
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: this.onHide
            }
        ];

        return (
           this.props.propsRedux.intents &&
            <Modal
                show={this.props.propsOwned.show}
                onShow={this.props.propsOwned.onShow}
                onHide={this.onHide}
                title="Intent Name"
                footerComponents={modalButtons}
            >
                <SearchableSelect 
                    name="intents" 
                    options={this.state.intents} 
                    onChange={this.onIntentChange} 
                    formatOptionLabel = { this.formatOptionLabel }
                />
                {
                    this.state.selectionError &&
                    <div className={ [ 'alert alert-danger' ].join(' ') } style={{marginTop : '10px', marginBottom: '0px' }}>
                        {this.state.selectionError}
                    </div>
                }
            </Modal>
        );

    }
}

/** Defining prop types requirements */
IntentModal.propTypes = {
    propsRedux: PropTypes.shape({
        intents: PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            intents: state.rules.intents,
            rulesJson: state.rules.rulesJson,
            isChanged: state.rules.isChanged,
            selectedNode: state.rules.selectedNode,
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setRules, setSelectedRule, editIntent, setShowIntentModal, setAlert })(IntentModal);