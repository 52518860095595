/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import PropTypes from 'prop-types';

/** Importing custom Components */

/** Importing custom Styles */

/** Main Components */
const Prioirity = props => {
    return (
        <input className="form-control" type="number" min="1" value={ props.selectedValue } name={ props.name } onChange={ props.onChange } data-rulekey={ props.dataRulekey } readOnly={ true } disabled={ true } data-node={ props.dataNode} />
    );
}

/** Defining prop types requirements */
Prioirity.propTypes = {
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

/** Exporting main component */
export default Prioirity;