/*
 * File Description : Have common util functions being used in rules UI
 * Author: Meghana Aggarwal
 */

import { findInObjectResponse, polishResponse } from "./helpers";
import { params } from "../data/params";
import {findBlockIdFromBotResponses} from './callFlowUtils'
/**
 * Function to get response key from object
 * @param {json} obj Object whose next cild is expected to be a responseKey
 */
export const getResponseKeyFromObj = (obj) => {
    var responseKey = null;
    if (typeof obj === "object") {
        let tempKeys = Object.keys(obj);
        if (tempKeys.length > 0) {
            responseKey = tempKeys.find(resp => resp.endsWith("Response"));
        }
    }
    return responseKey;
}

/**
 * Function to get iteration key from object
 * @param {json} obj Object whose next cild is expected to be a iterationKey
 */
export const getIterationKeyFromObj = (obj) => {
    var iterationKey = null;
    if (typeof obj === "object") {
        let tempKeys = Object.keys(obj);
        if (tempKeys.length > 0) {
            iterationKey = tempKeys[0];
        }
    }
    return iterationKey;
}

/**
 * Function to get table object from the passed data for simple view tables
 * @param {string} intent intent 
 * @param {object} responseObj response object from json
 * @param {object} botResponses botResponse object from state redux
 * @param {object} intentResponseCheckObj object to check duplicates and manage them
 * @param {int} responsePos position of the response to pass
 * @param {bool} isMetadata if the response is Metadata or not
 * @param {int} pathsData Array of Objects of path data form the paths, responses hae been loaded from 
 *                      All expected (optional) keys are : 
 *                      ruleKey, evalKey, responseKey, iterationKey, scenario, isMetadata, index
 */
export const getTableDataFromResponseObj = (intent, responseObj, botResponses=null, intentResponseCheckObj={}, responsePos = -1, isMetadata=false, pathsData=[], selectedTags = []) => {
    if (intent == global.CUST_CONSTANTS.SAMPLE_NODEKEY) {
        return null;
    }
    let indexOfObj = (responsePos <= 0) ? 0 : responsePos;
    var responseText = "";    
    var responseClip = "";
    var responseClipTags = "";
    var responseId = responseObj['id'];
    var isCorrupted = false;
    let audioClipNotPresent = false;

    if ( ( 'BRText' in responseObj ) && ( 'BRClip' in responseObj ) ) {
        responseText = responseObj['BRText'];
        responseClip = responseObj['BRClip'];
    } else if ( ( 'defaultText' in responseObj ) && ( 'defaultClip' in responseObj ) ) {
        responseText = responseObj['defaultText'];
        responseClip = responseObj['defaultClip'];
    } else {
        return null;
    }

    if ((responseObj.id == 0) || (responseObj.id == null) || (responseObj.id == "") || (responseClip == null) || (responseText == "") ) {
        isCorrupted = true;
        responseId = 0;
        responseText = global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT;
        responseClip = global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT;
        responseClipTags = ", Clip:" + global.CUST_CONSTANTS.CORRUPTED_RESPONSE_TEXT;
    }else if((responseText == null) || (responseClip == "")){
        audioClipNotPresent = true;
    }

    var tableObj = {
        node: intent,
        responseText: responseText,
        responseClipDesc: "",
        responseId: (responseObj.id ? responseObj.id : -1),
        responseClip: responseClip,
        responseClipTags: responseClipTags ? responseClipTags : "",
        responseTagsObj: {},
        alternateResponseId: -1,
        alternateResponseClip: "",
        alternateResponseClipTags: "",
        alternateResponseTagsObj: {},
        responseObj: responseObj,
        isMetadata: isMetadata,
        pathsData: [ ...pathsData ],
        isCorrupted: isCorrupted,
        audioClipNotPresent: audioClipNotPresent,
    };

    if (!isCorrupted) {
        if (intentResponseCheckObj[responseId]) {
            intentResponseCheckObj[responseId].isDuplicateCheckObject = true;
            intentResponseCheckObj[responseId].pathsData = [ ...intentResponseCheckObj[responseId].pathsData, ...pathsData ];
            return intentResponseCheckObj[responseId];
        } else {
            intentResponseCheckObj[responseId] = {
                isDuplicateCheckObject: false,
                index: indexOfObj,
                pathsData: [ ...pathsData ]
            };
        }
        let newId = responseObj.id;
        if(typeof(newId) === 'number'){
            newId = String(newId);
            let splittedBlockId = newId.split('_');
            if(splittedBlockId.length <=1){
                newId = findBlockIdFromBotResponses({botResponses, oldBlockId: responseObj.id,blockObj: responseObj });                
            }
        }
        
        if (newId && botResponses && botResponses[newId] && botResponses[newId]) {
            /** Making tags object and tag string */
            if (botResponses[newId] && botResponses[newId].TagDetails && (Object.keys(botResponses[newId].TagDetails).length !== 0)) {
                var tagString = "";
                Object.keys(botResponses[newId].TagDetails).forEach( tagCollection => {
                    botResponses[newId].TagDetails[tagCollection].forEach( tag => {
                        tagString += ", " + tagCollection + ":" + tag;
                    })
                })
                tableObj.responseClipTags = tagString + ", Clip:" + tableObj.responseClip;
                
                tableObj.responseTagsObj = { ...botResponses[newId].TagDetails };
            }
            else{
                tableObj.responseClipTags = ", Clip:" + tableObj.responseClip;
            }

            tableObj.responseClipDesc = botResponses[newId].clip_title ? botResponses[newId].clip_title : "";
        }
    }
    return tableObj;
}

/**
 * Function to get table data from rule json
 * @param {json} rulesJson Rule rulesJson main
 */
export const createTableDataForNodeTable = (rulesJson, botResponses, selectedTags) => {
    var intentResponseData = [];
    var tableData = [];
    let responseKey = null;
    let iterationKey = null;
    let responsesArr = null;
    let scenarioResponses = {};
    var intentResponseCheckObj = {};
    var detailedVsSimpleViewIndexesMapper = {};

    
    if (rulesJson.ruleResponses && rulesJson.ruleResponses.tagRule && rulesJson.ruleResponses.tagRule.scenarioRule) {
        scenarioResponses = getScenarioResponses(rulesJson.ruleResponses.tagRule.scenarioRule)
	}

    /** Block for adding opening statement if present in metadata */
    if (rulesJson.metadata) {
        Object.keys(rulesJson.metadata).forEach( (metadataKey) => {
            responseKey = getResponseKeyFromObj(rulesJson.metadata[metadataKey]);
            iterationKey = getIterationKeyFromObj(rulesJson.metadata[metadataKey][responseKey]);

            rulesJson.metadata[metadataKey][responseKey] && rulesJson.metadata[metadataKey][responseKey][iterationKey] && rulesJson.metadata[metadataKey][responseKey][iterationKey].forEach((responseObj, index) => {
                let pathsData = [{
                    referenceId: responseObj.id,
                    nodeKey: metadataKey,
                    responseKey: responseKey,
                    iterationKey: iterationKey,
                    isMetadata: true,
                    index: index,
                    simpleViewRowIndex: tableData.length,
                }];

                /** Creating detailed to simple index mapping */
                var detailedViewPathKey = getDetailedViewLoaderPathStr(pathsData[0]);
                if (typeof detailedVsSimpleViewIndexesMapper[detailedViewPathKey] == "undefined") {
                    detailedVsSimpleViewIndexesMapper[detailedViewPathKey] = {};
                }
                detailedVsSimpleViewIndexesMapper[detailedViewPathKey]["responseIndex"] = tableData.length;

                let tableObj = getTableDataFromResponseObj(metadataKey, responseObj, botResponses, intentResponseCheckObj, tableData.length, true, pathsData, selectedTags);
                if (tableObj) {
                    if(tableObj.isDuplicateCheckObject){
                        tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                    } else {
                        tableData.push(tableObj);
                    }
                }
            });
        });
    }
    
    rulesJson && rulesJson.ruleResponses && Object.keys(rulesJson.ruleResponses).map((intent, intentIndex) => {
        if (intentResponseData[intent] === undefined) {
            intentResponseData[intent] = [];
        }
        var intentResponseCheckObj = {};
        responsesArr = null;
        /** Getting data from generic key */
        if (rulesJson.ruleResponses[intent]['generic']) {
            /** Getting data from evalTrue key */
            responsesArr = null;
            if (rulesJson.ruleResponses[intent]['generic']['evalTrue']) {
                responseKey = getResponseKeyFromObj(rulesJson.ruleResponses[intent]['generic']['evalTrue']);
                if (responseKey) {
                    iterationKey = getIterationKeyFromObj(rulesJson.ruleResponses[intent]['generic']['evalTrue'][responseKey]);
                    responsesArr = (rulesJson.ruleResponses[intent]['generic']['evalTrue'][responseKey] && rulesJson.ruleResponses[intent]['generic']['evalTrue'][responseKey][iterationKey] && rulesJson.ruleResponses[intent]['generic']['evalTrue'][responseKey][iterationKey][0]) ? rulesJson.ruleResponses[intent]['generic']['evalTrue'][responseKey][iterationKey] : null;
                    if (responsesArr && (responsesArr.length > 0)) {
                        responsesArr.forEach((responseObj, index) => {
                            let pathsData = [{
                                referenceId: responseObj.id,
                                nodeKey: intent,
                                ruleKey: "generic",
                                evalKey: "evalTrue",
                                responseKey: responseKey,
                                iterationKey: iterationKey,
                                index: index,
                                simpleViewRowIndex: tableData.length,
                            }];

                            /** Creating detailed to simple index mapping */
                            var detailedViewPathKey = getDetailedViewLoaderPathStr(pathsData[0]);
                            if (typeof detailedVsSimpleViewIndexesMapper[detailedViewPathKey] == "undefined") {
                                detailedVsSimpleViewIndexesMapper[detailedViewPathKey] = {};
                            }
                            detailedVsSimpleViewIndexesMapper[detailedViewPathKey]["responseIndex"] = tableData.length;
                            
                            let tableObj = getTableDataFromResponseObj(intent, responseObj, botResponses, intentResponseCheckObj, tableData.length, false, pathsData, selectedTags);
                            if (tableObj) {
                                let nodeInvalid = false;
                                if ( (intent !== global.CUST_CONSTANTS.STORY_NONE_RESPONSE) && rulesJson.rule && (rulesJson.rule[intent] === undefined)) {
                                    nodeInvalid = true;
                                }
                                tableObj["nodeInvalid"] = nodeInvalid;
                                if(tableObj.isDuplicateCheckObject){
                                    tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                                } else {
                                    tableData.push(tableObj);
                                }
                            }
                        });
                    }
                }
            }
            /** Getting data from evalFalse key */
            responsesArr = null;
            if (rulesJson.ruleResponses[intent]['generic']['evalFalse']) {
                responseKey = getResponseKeyFromObj(rulesJson.ruleResponses[intent]['generic']['evalFalse']);
                if (responseKey) {
                    /** Here it will be just an object instead of array */
                    responsesArr = (rulesJson.ruleResponses[intent]['generic']['evalFalse'][responseKey]) ? rulesJson.ruleResponses[intent]['generic']['evalFalse'][responseKey] : null;
                    if (responsesArr) {
                        let pathsData = [{
                            referenceId: responsesArr.id,
                            nodeKey: intent,
                            ruleKey: "generic",
                            evalKey: "evalFalse",
                            responseKey: responseKey,
                            iterationKey: iterationKey,
                            simpleViewRowIndex: tableData.length,
                        }];

                        /** Creating detailed to simple index mapping */
                        var detailedViewPathKey = getDetailedViewLoaderPathStr(pathsData[0]);
                        if (typeof detailedVsSimpleViewIndexesMapper[detailedViewPathKey] == "undefined") {
                            detailedVsSimpleViewIndexesMapper[detailedViewPathKey] = {};
                        }
                        detailedVsSimpleViewIndexesMapper[detailedViewPathKey]["responseIndex"] = tableData.length;
                        
                        let tableObj = getTableDataFromResponseObj(intent, responsesArr, botResponses, intentResponseCheckObj, tableData.length, false, pathsData, selectedTags);
                        if (tableObj) {
                            if(tableObj.isDuplicateCheckObject){
                                tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                            } else {
                                tableData.push(tableObj);
                            }
                        }
                    }
                }
            }
        }

        /** Getting data from add on keys */
        Object.keys(rulesJson.ruleResponses[intent]).forEach(rule => {
            if (rule === 'generic') {
                return true;
            }
            responsesArr = null;
            responseKey = getResponseKeyFromObj(rulesJson.ruleResponses[intent][rule]);
            if (responseKey) {
                iterationKey = getIterationKeyFromObj(rulesJson.ruleResponses[intent][rule][responseKey]);
                responsesArr = (rulesJson.ruleResponses[intent][rule][responseKey] && rulesJson.ruleResponses[intent][rule][responseKey][iterationKey] && rulesJson.ruleResponses[intent][rule][responseKey][iterationKey][0]) ? rulesJson.ruleResponses[intent][rule][responseKey][iterationKey] : null;
                if (responsesArr && (responsesArr.length > 0)) {
                    responsesArr.forEach((responseObj, index) => {
                        let pathsData = [{
                            referenceId: responseObj.id,
                            nodeKey: intent,
                            ruleKey: rule,
                            responseKey: responseKey,
                            iterationKey: iterationKey,
                            index: index,
                            simpleViewRowIndex: tableData.length,
                        }];

                        /** Creating detailed to simple index mapping */
                        var detailedViewPathKey = getDetailedViewLoaderPathStr(pathsData[0]);
                        if (typeof detailedVsSimpleViewIndexesMapper[detailedViewPathKey] == "undefined") {
                            detailedVsSimpleViewIndexesMapper[detailedViewPathKey] = {};
                        }
                        detailedVsSimpleViewIndexesMapper[detailedViewPathKey]["responseIndex"] = tableData.length;
                        
                        let tableObj = getTableDataFromResponseObj(intent, responseObj, botResponses, intentResponseCheckObj, tableData.length, false, pathsData, selectedTags);
                        if (tableObj) {
                            if(tableObj.isDuplicateCheckObject){
                                tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                            } else {
                                tableData.push(tableObj);
                            }
                        }
                    });
                }
            }
        });

        /** Add responses if also present in a scenario tag */
        if (scenarioResponses[intent]) {
            scenarioResponses[intent].forEach(response => {
                responseKey = getResponseKeyFromObj(response['response']);
                if (responseKey) {
                    iterationKey = getIterationKeyFromObj(response['response'][responseKey]);
                    responsesArr = (response['response'][responseKey][iterationKey] && response['response'][responseKey][iterationKey][0]) ? response['response'][responseKey][iterationKey] : null;
                    if (responsesArr && (responsesArr.length > 0)) {
                        responsesArr.forEach((responseObj, index) => {
                            let pathsData = [{
                                referenceId: responseObj.id,
                                nodeKey: intent,
                                index: index,
                                ruleKey: 'tagRule:scenarioRule',
                                responseKey: responseKey,
                                iterationKey: iterationKey,
                                scenario: response['scenario'],
                                simpleViewRowIndex: tableData.length
                            }];
                            
                            let tableObj = getTableDataFromResponseObj(intent, responseObj, botResponses, intentResponseCheckObj,tableData.length, false, pathsData, selectedTags);
                            if (tableObj) {
                                if(tableObj.isDuplicateCheckObject){
                                    tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                                } else {
                                    tableData.push(tableObj);
                                }
                            }
                        })
                    }
                }
            })
        }
    });

    /** Getting intents that are not in ruleResponses but are configured in scenario */
    const scenarioOnlyResponses = Object.keys(scenarioResponses).filter(ele => !scenarioResponses[ele]['visited']);
    scenarioOnlyResponses.forEach(intent => {
        var intentResponseCheckObj = {}
        intentResponseData[intent] = []
        scenarioResponses[intent].forEach(response => {
            responseKey = getResponseKeyFromObj(response['response']);
            if (responseKey) {
                iterationKey = getIterationKeyFromObj(response['response'][responseKey]);
                responsesArr = (response['response'][responseKey][iterationKey] && response['response'][responseKey][iterationKey][0]) ? response['response'][responseKey][iterationKey] : null;
                if (responsesArr && (responsesArr.length > 0)) {
                    responsesArr.forEach((responseObj, index) => {
                        let pathsData = [{
                            referenceId: responseObj.id,
                            nodeKey: intent,
                            index: index,
                            ruleKey: 'tagRule:scenarioRule',
                            responseKey: responseKey,
                            iterationKey: iterationKey,
                            scenario: response['scenario'],
                            simpleViewRowIndex: tableData.length
                        }];
            
                        let tableObj = getTableDataFromResponseObj(intent, responseObj, botResponses, intentResponseCheckObj, tableData.length, false, pathsData, selectedTags);
                        if (tableObj) {
                            if(tableObj.isDuplicateCheckObject){
                                tableData[tableObj.index].pathsData = [ ...tableObj.pathsData ];
                            } else {
                                tableData.push(tableObj);
                            }
                        }
                    })
                }
            }
        })
    });

    return {
        intentResponseData: intentResponseData,
        tableData: tableData,
        detailedVsSimpleViewIndexesMapper: detailedVsSimpleViewIndexesMapper,
    };
}

/**
 * Function to get table data from rule json
 * @param {json} botResponses responses
 */
export const createTableDataForSwitch = (botResponses, selectedTags, rowsToSwitch) => {
    var tableData = [];
    var switchObj = {};

    var tagsLength = 0;

    if (Object.keys(selectedTags).length > 0) {
        Object.keys(selectedTags).forEach( tagCollectionTemp => {
            tagsLength += Object.keys(selectedTags[tagCollectionTemp]).length;
        });
    }

    if (tagsLength > 0) {
        rowsToSwitch.forEach( row => {
            if (row.original) {
                var tableDataObj = { ...row.original };
                var alternateResponseId = -1;
                var responseId = tableDataObj.responseId;

                if(typeof(responseId) === 'number'){
                    responseId = String(responseId);
                    let splittedBlockId = responseId.split('_');
                    if(splittedBlockId.length <=1){
                        responseId = findBlockIdFromBotResponses({botResponses, oldBlockId: tableDataObj.responseId,blockObj: tableDataObj });                
                    }
                }
                if (botResponses[responseId] && botResponses[responseId].relatedUtterances && (botResponses[responseId].relatedUtterances.length > 1)) {
                    botResponses[responseId].relatedUtterances.forEach( relatedUtteranceId => {
                            var tagsFound = 0;
                            if (botResponses[relatedUtteranceId] && botResponses[relatedUtteranceId].TagDetails && (Object.keys(botResponses[relatedUtteranceId].TagDetails).length > 0)) {
                            Object.keys(botResponses[relatedUtteranceId].TagDetails).forEach( relatedTagCollection => {
                                if (selectedTags[relatedTagCollection]) {
                                    botResponses[relatedUtteranceId].TagDetails[relatedTagCollection].forEach (relatedTag => {
                                        if (selectedTags[relatedTagCollection][relatedTag]) {
                                            tagsFound++;
                                            if (tagsFound == tagsLength) {
                                                alternateResponseId = relatedUtteranceId;
                                                alternateTagString += ", " + relatedTagCollection + ":" + relatedTag;
                                            }
                                        }
                                    })
                                }
                            });
                        }
                    })
                }
        
                if (alternateResponseId) {
                    switchObj[tableData.length] = {
                        alternateResponseId: alternateResponseId,
                        isAlternateChosen: true,
                    };
                    tableDataObj.alternateResponseId = alternateResponseId;
                    tableDataObj.alternateResponseClip = botResponses[alternateResponseId].filepath;
                    tableDataObj.alternateResponseTagsObj = { ...botResponses[alternateResponseId].TagDetails };
                    var alternateTagString = "";
                    Object.keys(botResponses[alternateResponseId].TagDetails).forEach( tagCollectionTemp => {
                        botResponses[alternateResponseId].TagDetails[tagCollectionTemp].forEach( tagTemp => {
                            alternateTagString += ", " + tagCollectionTemp + ":" + tagTemp;
                        })
                    })
                    tableDataObj.alternateResponseClipTags = alternateTagString + ", Clip:" + tableDataObj.alternateResponseClip;
                }
                
                tableData.push(tableDataObj);
            }
        });
    }
    return {
        tableData: tableData,
        switchObj: switchObj,
    };
}

/**
 * Function to get table data from rule json
 * @param {scenarioRules} scenario json in the rule part of the ruelejson
 * @returns Object with key as intent as value being the combined responses across scenarios
 */
/** Returns tableData and responseObject from scenario tags */
export const getScenarioResponses = (scenarioRule) => {
    let scenarioResponses = {};
    let responses = null;

    Object.keys(scenarioRule).map(scenario => {
        Object.keys(scenarioRule[scenario]).forEach(intent => {
            responses = scenarioRule[scenario][intent]
            if (responses && (Object.keys(responses).length > 0)) {
                if (!scenarioResponses[intent]) {
                    scenarioResponses[intent] = []
                }
                Object.keys(responses).forEach((resp) => {
                    let response = {
                        'response': {},
                        'scenario': ''
                    }
                    response['response'][resp] = responses[resp]
                    response['scenario'] = scenario
                    scenarioResponses[intent].push(response);
                })
            }
        })
    })
    return scenarioResponses;
}

/**
 * Function to get all data related to the response
 * @param {object} cell cell object for getting actual values
 */
export const getMetadata = (cell) => {
    let metaDataString = "<span>";
    metaDataString += ((cell.row.original.responseClip) && (cell.row.original.responseClip.trim() !== "")) ? ("<p>" + cell.row.original.responseClip + "</p>") : ""
    metaDataString += ((cell.row.original.responseClipDesc) && (cell.row.original.responseClipDesc.trim() !== "")) ? ("<p>" + cell.row.original.responseClipDesc + "</p>") : ""
    metaDataString += ((cell.row.original.responseText) && (cell.row.original.responseText.trim() !== "")) ? ("<p>" + cell.row.original.responseText + "</p>") : ""
    if (cell.row.original.responseObj && cell.row.original.responseObj.metadata && cell.row.original.responseObj.metadata.tags && ( Object.keys(cell.row.original.responseObj.metadata.tags).length > 0 )) {
        metaDataString += "<p>";
        Object.keys(cell.row.original.responseObj.metadata.tags).forEach( tagCollection => {
            metaDataString += " " + tagCollection + " : ";
            if (typeof cell.row.original.responseObj.metadata.tags[tagCollection] === "object") {
                metaDataString += cell.row.original.responseObj.metadata.tags[tagCollection].join(",");
            } else {
                metaDataString += cell.row.original.responseObj.metadata.tags[tagCollection]
            }
        })
        metaDataString += "</p>";
    }
    metaDataString += "</span>"
    return metaDataString;
}

export const getChangedRuleJson = (rulesJson, referencePaths, action, selectedResponse = null, botResponses = null, detailedVsSimpleViewIndexesMapper=null) => {
    // var newRulesJson = JSON.parse(JSON.stringify(rulesJson));
    var newRulesJson = { ...rulesJson };
    var toDelete = true;
    var loadersPayloads = [];
    if ( referencePaths.length > 0 && ( (action == global.CUST_CONSTANTS.CRUD_MODE.DELETE) || (botResponses[selectedResponse]))) {
        // can use promise over here if needed instead of counter.
        referencePaths.forEach( referencePathObj => {
            var newResponse = {};

            if (referencePathObj.evalKey === "evalFalse") {
                if ( (action == global.CUST_CONSTANTS.CRUD_MODE.EDIT ) || (action == global.CUST_CONSTANTS.CRUD_MODE.ADD ) || (action == global.CUST_CONSTANTS.OTHER_ACTIONS.SWITCH )) {
                    newResponse = {
                        id: selectedResponse,
                        defaultText: botResponses[selectedResponse].utterance_text,
                        defaultClip: botResponses[selectedResponse].filepath,
                        responseUsed: "False",
                    };
                    /** In case of eval false, it doesn't matter, it's add or edit , you just need to replace the json */
                    if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey]) {
                        referencePathObj.evalKey = "evalFalse";
                        referencePathObj.responseKey = "failureResponse";
                        newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey] = {
                            failureResponse: {}
                        }
                    }
                    newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey] = { ...newResponse };
                } else if (action == global.CUST_CONSTANTS.CRUD_MODE.DELETE) {
                    toDelete = false;
                    return false;
                }
            } else {
                var objectToRefer = null;
                if (referencePathObj.ruleKey === "generic") {
                    if (referencePathObj.evalKey === "evalTrue") {
                        if (!newRulesJson.ruleResponses[referencePathObj.nodeKey]) {
                            newRulesJson.ruleResponses[referencePathObj.nodeKey] = {}
                        }
                        if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey]) {
                            newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey] = {}
                        }
                        if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey]) {
                            newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey] = {}
                        }
                        if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey]) {
                            newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey] = {}
                        }
                        if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey]) {
                            newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey] = []
                        }
                        objectToRefer = newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.evalKey][referencePathObj.responseKey][referencePathObj.iterationKey];
                    }
                } else if (referencePathObj.isMetadata) {
                    /** If metadata is changed */
                    if(!newRulesJson.metadata){
                        newRulesJson['metadata'] = {}
                    }
                    if (!newRulesJson.metadata[referencePathObj.nodeKey]) {
                        newRulesJson.metadata[referencePathObj.nodeKey] = {
                            [referencePathObj.responseKey]: {
                                [referencePathObj.iterationKey]: []
                            }
                        }
                    } else if (!newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey]) {
                        newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey] = {
                            [referencePathObj.iterationKey]: []
                        }
                    } else if (!newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey][referencePathObj.iterationKey]) {
                        newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey][referencePathObj.iterationKey] = [];
                    }

                    /** If the eval falase is being set then it needs to be replaced instead */
                    if (referencePathObj.nodeKey === global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE) {
                        newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey][referencePathObj.iterationKey] = [];
                    }

                    objectToRefer = newRulesJson.metadata[referencePathObj.nodeKey][referencePathObj.responseKey][referencePathObj.iterationKey];
                } else if (referencePathObj.ruleKey === "tagRule:scenarioRule") {
                    /** If scenario rule response is changed */
                    objectToRefer = newRulesJson.ruleResponses.tagRule.scenarioRule[referencePathObj.scenario][referencePathObj.nodeKey][referencePathObj.responseKey][referencePathObj.iterationKey];
                } else {
                    if (!newRulesJson.ruleResponses[referencePathObj.nodeKey]) {
                        newRulesJson.ruleResponses[referencePathObj.nodeKey] = {}
                    }
                    if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey]) {
                        newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey] = {}
                    }
                    if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey]) {
                        newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey] = {}
                    }
                    if (!newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey]) {
                        newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey] = []
                    }
                    /** If any addon/global rule response is changed */
                    objectToRefer = newRulesJson.ruleResponses[referencePathObj.nodeKey][referencePathObj.ruleKey][referencePathObj.responseKey][referencePathObj.iterationKey];
                }

                if (objectToRefer) {
                    if (action == global.CUST_CONSTANTS.CRUD_MODE.DELETE && toDelete) {
                        if (objectToRefer.length > 1) {
                            objectToRefer.splice(referencePathObj.index, 1);
                        } else {
                            toDelete = false;
                        }
                    } else {
                        newResponse = {
                            id: selectedResponse,
                            BRText: botResponses[selectedResponse].utterance_text,
                            BRClip: botResponses[selectedResponse].filepath,
                            responseUsed: "False",
                        };
                        if ((action == global.CUST_CONSTANTS.CRUD_MODE.EDIT) || (action == global.CUST_CONSTANTS.OTHER_ACTIONS.SWITCH)) {
                            // referenceId : id to be refereed to
                            objectToRefer[referencePathObj.index] = { ...newResponse };
                        } else if (action == global.CUST_CONSTANTS.CRUD_MODE.ADD) {
                            objectToRefer.push({ ...newResponse });
                        }
                    }
                }
            }

            /**
             * mode (global.CUST_CONSTANTS.CRUD_MODE) : CRUD MODE (add and edit mostly till now)
             * selectedId (INT) : selected response id
             * isSimpleView (BOOL) : is being performed from simple view or not (if not, considered detailed view)
             * referencePaths (ARRAY) : Array of objects of paths for refrence
            for ref : referencePathObj = {
                referenceId : id to be refereed to
                nodeKey : node being refered to
                index : index being refered to
                ruleKey : ruleKey being refered to
                evalKey : evalKey being refered to (will be present only in case of generic responses)
                responseKey : responseKey being refered to
                iterationKey : iterationKey being refered to
                scenario : scenario being refered to (If refering scenario)
                isMetadata (BOOL) : if the referenced part is metadata part or not being refered to
                simpleViewRowIndex: the row id(index) in which it's being loaded in simple view ,
            }
            */

            /** Adding in loader */
            if (referencePathObj) {
                var detailedViewPathKey = getDetailedViewLoaderPathStr(referencePathObj);
                if (referencePathObj.simpleViewRowIndex) {
                    loadersPayloads.push({
                        view: "simpleView",
                        key: "index_" + referencePathObj.simpleViewRowIndex,
                    });
                } else if (detailedVsSimpleViewIndexesMapper && detailedVsSimpleViewIndexesMapper[detailedViewPathKey]) {
                    loadersPayloads.push({
                        view: "simpleView",
                        key: "index_" + detailedVsSimpleViewIndexesMapper[detailedViewPathKey]["responseIndex"],
                    });
                }
                loadersPayloads.push({
                    view: "detailedView",
                    key: detailedViewPathKey,
                });
            }
        });
    }
    return {
        newRulesJson: newRulesJson,
        toDelete: toDelete,
        loadersPayloads: loadersPayloads,
    };
}


export const getDetailedViewLoaderPathStr = (referencePathObj) => {
    var detailedViewPath = "path_";
    if (referencePathObj.isMetadata) {
        detailedViewPath += "metadata";
    } else {
        detailedViewPath += "ruleResponses";
    }
    if (referencePathObj.nodeKey) {
        detailedViewPath += "_" + referencePathObj.nodeKey;
    }
    if (referencePathObj.ruleKey) {
        detailedViewPath += "_" + referencePathObj.ruleKey;
    }
    if (referencePathObj.evalKey) {
        detailedViewPath += "_" + referencePathObj.evalKey;
    }
    if (referencePathObj.responseKey) {
        detailedViewPath += "_" + referencePathObj.responseKey;
    }
    if (referencePathObj.iterationKey) {
        detailedViewPath += "_" + referencePathObj.iterationKey;
    }
    if (referencePathObj.index > -1) {
        detailedViewPath += "_" + referencePathObj.index;
    }
    return detailedViewPath;
}

export const getIntentsForModal = (intents, rulesJson) => {
    
    var intentsForModal = [];
    Object.keys(intents).map((key) => {
       
        if(key !== 'None'){
            intentsForModal.push({ value: key, label: key, isDisabled: false});
            // disabling grey code for now
            // if (rulesJson.ruleResponses[key] === undefined) {
            //     intentsForModal.push({ value: key, label: key, isDisabled: false});
            // }
            // else {
            //     intentsForModal.push({ value: key, label: key, isDisabled: true});
            // }
        }
    });
    return intentsForModal;
}

/**
 * Function to convert metadata node key into readable title
 * @param {string} key Node key of metadata
 */
export const getMetadataTitleToShow = (key) => {
    var title = "";
    switch (key) {
        case global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY:
            title = "Opening Statement";
            break;
        case global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE:
            title = "Default Eval False Statement";
            break;
    
        default:
            break;
    }
    return title;
}


export const onNodeClickBulk = (nodeKey, isMetadata=false, mode=global.CUST_CONSTANTS.CRUD_MODE.ADD, index=-1) => {
        global.recordAudit("Node clicked from simple view : " + nodeKey + ", isMetadata : " + isMetadata + ", Mode :" + mode);
        var selectedRule = {};
        selectedRule = this.props.propsRedux.rulesJson.ruleResponses[nodeKey];
        var pathsData = [];
        if (index > -1) {
            for (let tempIndex = index; tempIndex < this.state.tableData.length; tempIndex++) {
                var element = this.state.tableData[tempIndex];
                if (element.node == nodeKey) {
                    pathsData = [...pathsData, ...element.pathsData];
                }
            }
        }
        this.props.setSelectedRule(nodeKey, selectedRule, isMetadata, null, null, pathsData, mode);
        if(mode === global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK){
            this.props.setViewType(1);
        }  
    }


export const findAllOccurrence = (data={}, needle='', type='', isGlobalIntent=false, action = "EDIT") =>{
   //needle = " Sure I can pay over the phone with a check. ";
    if(type === global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE){
        let original = [data];
        let polishedResponse = null;
        data = Array.isArray(data) ? data : [data];
		/** Added trim as trimmed responses are expected to be checked. **/
        needle = needle.trim();
        let filteredJson = data.map((obj) => findInObjectResponse(obj, needle));
        console.log("FIlyer ",filteredJson);
        if(isGlobalIntent){
            polishedResponse = polishResponse(original,createFlatJson(filteredJson), true);
            
        }else{
            polishedResponse = polishResponse(original,filteredJson, false);
        }
        if(action ==="DELETE"){
            return [polishedResponse,createFlatJson(filteredJson) ]
        }
        return polishedResponse;
    }
    return false;
}

export const  createFlatJson = (resp) => {
    const flatten = (obj, prefix = '', res = {}) => Object.entries(obj).reduce((r, [key, val]) => {
        const k = `${prefix}${key}`;
        if (typeof val === 'object') {
            flatten(val, `${k}${params.callFlowSeparator}`, r); 
        } else {
            res[k] = val;
        }
        return r;
    }, res);
    let res = flatten(resp);
    return res;
}

export const listResponseOccurrence = (needle, responseId, action = global.CUST_CONSTANTS.CRUD_MODE.EDIT, data = {}, changeTo = null) => {
    let to = null;
    let ruleData = data["rule"] ?  data["rule"] :{};
    let responseData = data["ruleResponses"] ?  data["ruleResponses"] :{};

    if(action === global.CUST_CONSTANTS.CRUD_MODE.EDIT && changeTo){
        to = changeTo;
    }
    
    if (needle) {
        var respJson = findAllOccurrence(responseData, needle, global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE, false);
        var ruleJson = findAllOccurrence(ruleData, needle, global.CUST_CONSTANTS.ELEMENT_TYPE.RESPONSE, true);
    }

    if ((respJson || ruleJson) && (typeof respJson != "undefined" || typeof ruleJson != "undefined")) {
        return [responseId, to, respJson, ruleJson];
    }
}

/* get all the utterances from redux or api hit utility in future */

export const getUtterancesForModal = (utterances) => {
    var UtterancesForModal = [];
    Object.keys(utterances['data']).forEach((key)=>{
        UtterancesForModal.push({intent: utterances['data'][key]['intent'],utterance : { value: {utteranceText:utterances['data'][key][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.UTTERANCE_TEXT],utteranceId:utterances['data'][key][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.UTTERANCE_ID],relatedIntent:utterances['data'][key][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.RELATED_INTENT]}, label: utterances['data'][key][global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.UTTERANCE_TEXT], isDisabled: false}});
    });
    return UtterancesForModal;
}

export const getUntrainedUtterancesOfAnIntent = (utterances,intent) => {
    let filteredUtterances = utterances.filter(item => item.intent === intent)
    let formattedUtterances = filteredUtterances.map(item => ({
        value:{
            relatedIntent:item.intent,
            utteranceId:item.tempId,
            utteranceText:item.text
        },label:item.text,isDisabled:false,isUntrained:true
    }))

    return formattedUtterances
}

/**
 * Function to truncate the string upto maximum length 
 * @param {string} str - string to be truncated
 * @param {number} maxlen - maximum length of the truncated string
 * @returns {string}
 */
export const truncateString = (str, maxLen) => {
    try {
        if (str.length <= maxLen) {
            return str;
        }
        return `${str.slice(0, maxLen)}...`;
    } catch (error) {}
};

export const checkIfValidTempVersion = (version) => {
    return !isNaN(version.replace("temp.", ""));
}

export const getEntitesOfAnUtterance = ({entitiesUtterances={},node,selectedUtterance}) => {
    const intentRelatedEntities =  Object.entries(entitiesUtterances)
    const currentIntentEntities =  intentRelatedEntities.filter(item => {
        let nodeName = item[0].split('::')[0]
        return nodeName === node
    })
    const allEntitiesOfIntent = currentIntentEntities.map(item => item[1])
    const allEntitiesOfIntentFlatten = allEntitiesOfIntent.flat()
    const globalUtteranceEntities = allEntitiesOfIntentFlatten.filter(item => item.utterance_text === (selectedUtterance && selectedUtterance.label));
    return globalUtteranceEntities
  }


  export const checkIfnewDataExistsInOldAndMap = ({ oldData={}, newData={} }) => {
    let newDataEntries = Object.entries(newData)
    newDataEntries.forEach(item => {
      if (oldData[item[0]]) {
        oldData[item[0]] = [...oldData[item[0]], ...item[1]]
      } else {
        oldData[item[0]] = item[1]
      }
    })
    return oldData
  }