import * as $ from "jquery";
import axiosCall from "../../utils/axiosCall";

$(window).on("beforeunload", function (e) {
    if (window.stopFromUnload) {
        return "Unsaved changes exists";
    }
});

const keepalive = () => {
    var baseUrl = "https://" + window.location.host;
    /** code for local testing start */
    if (baseUrl != "https://localhost:3000") {
        axiosCall(global.CUST_CONSTANTS.KEEP_ALIVE_URL, (response) => {
            console.log('keep alive success response', response);
        }, (response) => {
            console.log('keep alive failure response', response);
        }, {}, 'GET', null, 'php' );
    }
};

export default keepalive;