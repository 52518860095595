/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes, { element, node } from 'prop-types';

/** Importing custom Components */
import GenericTable from "./GenericTable/GenericTable";
import AddOnRulesTable from "./AddOnRulesTable/AddOnRulesTable";
import TagRulesTable from "./TagRulesTable/TagRulesTable";
import RulesMetadataTable from "./RulesMetadataTable/RulesMetadataTable";
import IntentResponseGlobal from "./IntentResponseGlobal/IntentResponseGlobal";

import { changeRuleResponse, showResponseModal, changeNodeAction } from "../../../../store/actions/rules/rulesActions";
import { getIterationKeyFromObj, getResponseKeyFromObj } from "../../../../utils/rulesUtils";
import logger from 'redux-logger';
import { map, type } from 'jquery';
import { params } from '../../../../data/params';

/** Importing custom Styles */

/** Main Components */
class RulesTable extends React.Component {
    constructor (props) {
        super(props);
    }

    /**
     * 
     * @param {string} key Expected keys : "priority", "evalTrueResponseType", "evalTrueIterationType", "evalFalseResponseType"
     * @param {*} value 
     */
    changeRuleValues = (key, value, rulekey="generic", nodeKey=null) => {
        if(this.props.propsRedux.bulkEdit.state && nodeKey){
            var newSelectedRule = this.props.propsRedux.rulesJson.ruleResponses[nodeKey];
        }else{
            var newSelectedRule = { ...this.props.propsRedux.selectedNode.responseJson };
        }
        console.log("selected node ",newSelectedRule);
        var evalValue = "evalFalse";

        if (!rulekey) {
            rulekey = "generic";
        }
        var actionLevel = null;
        ({ evalValue, actionLevel, newSelectedRule } = this._changeRuleValues(key, newSelectedRule, rulekey, value, evalValue, actionLevel));
        if(this.props.propsRedux.bulkEdit.state && nodeKey){
            let respIndex = this.props.propsRedux.bulkEdit.keyMapper.responseNode[nodeKey];
            this.props.propsRedux.bulkEdit.responseJson.responseNode[respIndex].genericResponse = newSelectedRule['generic'];
        }

    }

    onInputValueChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        var data = event.target.dataset;
        var rulekey = (data.rulekey) ? data.rulekey : null;
        var nodeKey = (data.node) ? data.node : null;

        global.recordAudit("Input value changed - name : " + name + ", value : " + value, ", data : " + data + ", ruleKey : " + rulekey);

        this.changeRuleValues(name, value, rulekey, nodeKey);
    }

    _changeRuleValues(key, newSelectedRule, rulekey, value, evalValue, actionLevel) {
        console.log("In main "," key ",key," new selected rule ",newSelectedRule," rulekey ",rulekey," value ",value," evalTrue ",evalValue," actionlevel ",actionLevel);
        switch (key) {
            case "priority":
                newSelectedRule[rulekey]["priority"] = parseInt(value);
                break;

            /** Case when generic responseType is changed */
            /** sequence of cases evalTrueResponseType & evalFalseResponseType written one after another as same functionality with slight difference of key */
            case "evalTrueResponseType":
                evalValue = "evalTrue";
            /** Case when generic responseType is changed */
            case "evalFalseResponseType":
                var prevkey = getResponseKeyFromObj(newSelectedRule[rulekey][evalValue]);
                newSelectedRule[rulekey][evalValue][value] = { ...newSelectedRule[rulekey][evalValue][prevkey] };
                delete newSelectedRule[rulekey][evalValue][prevkey];
                actionLevel = global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE_TYPE;
                break;

            /** Case when generic iteration type is changed */
            case "evalTrueIterationType":
                var prevResponseKey = getResponseKeyFromObj(newSelectedRule[rulekey].evalTrue);
                var prevkey = getIterationKeyFromObj(newSelectedRule[rulekey].evalTrue[prevResponseKey]);
                newSelectedRule[rulekey]["evalTrue"][prevResponseKey][value] = [...newSelectedRule[rulekey]["evalTrue"][prevResponseKey][prevkey]];
                delete newSelectedRule[rulekey]["evalTrue"][prevResponseKey][prevkey];
                actionLevel = global.CUST_CONSTANTS.ACTION_LEVEL.ITERATION_TYPE;
                break;

            /** Case when add on response type is changed */
            case "responseType":
                var prevResponseKey = getResponseKeyFromObj(newSelectedRule[rulekey]);
                newSelectedRule[rulekey][value] = { ...newSelectedRule[rulekey][prevResponseKey] };
                delete newSelectedRule[rulekey][prevResponseKey];
                actionLevel = global.CUST_CONSTANTS.ACTION_LEVEL.RESPONSE_TYPE;
                break;

            /** Case when add on iteration type is changed */
            case "iterationType":
                var prevResponseKey = getResponseKeyFromObj(newSelectedRule[rulekey]);
                var prevIterationkey = getIterationKeyFromObj(newSelectedRule[rulekey][prevResponseKey]);
                newSelectedRule[rulekey][prevResponseKey][value] = [...newSelectedRule[rulekey][prevResponseKey][prevIterationkey]];
                delete newSelectedRule[rulekey][prevResponseKey][prevIterationkey];
                actionLevel = global.CUST_CONSTANTS.ACTION_LEVEL.ITERATION_TYPE;
                break;

            default:
                break;
        }
        this.props.changeNodeAction(actionLevel, value);
        this.props.changeRuleResponse(newSelectedRule, actionLevel);
        return { evalValue, actionLevel, newSelectedRule };
    }

    render() {
        /** Add on rules: */
        var { generic, ...addOnRuleResponses } = (!this.props.propsRedux.selectedNode.scenario && this.props.propsRedux.selectedNode.responseJson) ? this.props.propsRedux.selectedNode.responseJson : {};

        var priorityOfGeneric = (this.props.propsRedux && this.props.propsRedux.selectedNode && this.props.propsRedux.selectedNode.responseJson) ? (Object.keys(this.props.propsRedux.selectedNode.responseJson).indexOf("generic") + 1) : 1;
        var showTagRuleTable = (
            this.props.propsRedux.selectedNode &&
            this.props.propsRedux.selectedNode.scenario
        ) ? true : false;
        var showMetadataTable = (
            this.props.propsRedux.selectedNode &&
            this.props.propsRedux.selectedNode.node &&
            (
                this.props.propsRedux.selectedNode.isMetadata &&
                this.props.propsRedux.rulesJson.metadata &&
                this.props.propsRedux.rulesJson.metadata[this.props.propsRedux.selectedNode.node]
            )
        ) ? true : false;
        var showGenericTable = ((!showTagRuleTable) && (!showMetadataTable) && (generic && Object.keys(generic).length > 0)) ? true : false;
        var showAddOnTable = ((!showTagRuleTable) && (!showMetadataTable) && (addOnRuleResponses && Object.keys(addOnRuleResponses).length > 0)) ? true : false;

        var scenarioRule = null;
        if (showTagRuleTable) {
            var scenario = this.props.propsRedux.selectedNode.scenario;
            scenarioRule = { ...this.props.propsRedux.rulesJson.ruleResponses.tagRule.scenarioRule[scenario] };
        }


        var responseJson = { "addons": {}, "responseNode": [], "responseGlobal": [], "ruleGlobal": {} };
        if (this.props.propsRedux.bulkEdit.state && this.props.propsRedux.bulkEdit.responseJson) {
            if (this.props.propsRedux.bulkEdit.applyChanges && this.props.propsRedux.bulkEdit.selectedNodeBulk.length > 0) {
                this.props.propsRedux.bulkEdit.selectedNodeBulk.forEach((element) => {
                    let separator = params.callFlowSeparator;
                    let x = element.split(separator);
                    if(
                        (x.length>1) && (x[0] === "addon") && 
                        (x[2]) && 
                        (this.props.propsRedux.bulkEdit.responseJson["responseAddon"]) && 
                        (this.props.propsRedux.bulkEdit.responseJson["responseAddon"][x[2]])) {
                            responseJson["addons"][x[2]] = this.props.propsRedux.bulkEdit.responseJson["responseAddon"][x[2]];
                        // responseJson["addons"] = Object.keys(this.props.propsRedux.bulkEdit.responseJson["responseAddon"]).filter((key)=>{if(key === x[2]){return key;}});
                    } else if (
                        (x.length>1) && (x[0] === "global_addon") && 
                        (x[2]) && 
                        (this.props.propsRedux.bulkEdit.responseJson["responseGlobal"]) && 
                        (this.props.propsRedux.bulkEdit.responseJson["responseGlobal"][x[2]])) {
                            responseJson["responseGlobal"][x[2]] = this.props.propsRedux.bulkEdit.responseJson["responseGlobal"][x[2]];
                        // responseJson["addons"] = Object.keys(this.props.propsRedux.bulkEdit.responseJson["responseAddon"]).filter((key)=>{if(key === x[2]){return key;}});
                    }
                });

                var keyMapper = this.props.propsRedux.bulkEdit.keyMapper;
                var keys = this.props.propsRedux.bulkEdit.selectedNodeBulk.map((node) => keyMapper.responseNode[node]);
                responseJson["responseNode"] = this.props.propsRedux.bulkEdit.responseJson["responseNode"].filter((k, i) => { if (keys.includes(i)) { return k } });


                // previously written code 
                // if(this.props.propsRedux.bulkEdit.selectedNodeGlobal && this.props.propsRedux.bulkEdit.selectedNodeGlobal.length>0){
                //     responseJson["responseGlobal"] = Object.keys(this.props.propsRedux.bulkEdit.ruleResponse).filter((key)=>{if(this.props.propsRedux.bulkEdit.selectedNodeGlobal.includes(key)){return key;}});
                // }

                // changed code 
                // if(this.props.propsRedux.bulkEdit.selectedNodeBulk && this.props.propsRedux.bulkEdit.selectedNodeBulk.length>0){

                //     this.props.propsRedux.bulkEdit.selectedNodeBulk.forEach((element) => {
                //         let x = element.split("-");
                //         if(
                //             (x.length>1) && (x[0] === "global_addon") && 
                //             (x[2]) && 
                //             (this.props.propsRedux.bulkEdit.responseJson["responseGlobal"]) && 
                //             (this.props.propsRedux.bulkEdit.responseJson["responseGlobal"][x[2]])) {
                //                 responseJson["responseGlobal"][x[2]] = this.props.propsRedux.bulkEdit.responseJson["responseAddon"][x[2]];
                //             // responseJson["addons"] = Object.keys(this.props.propsRedux.bulkEdit.responseJson["responseAddon"]).filter((key)=>{if(key === x[2]){return key;}});
                //         }
                //     });

                //     responseJson["responseGlobal"] = this.props.propsRedux.bulkEdit.selectedNodeBulk.filter((key) => 
                //         {
                //             let keyArr = selectedNode.split("-");
                //             this.props.propsRedux.bulkEdit.responseJson["responseGlobal"]
                //             if(this.props.propsRedux.bulkEdit.selectedNodeBulk.includes(key)) {
                //                 return key;
                //             }
                //         }
                //     );
                // }
                
            } else {
                responseJson["responseNode"] = this.props.propsRedux.bulkEdit.responseJson["responseNode"] ? this.props.propsRedux.bulkEdit.responseJson["responseNode"] : {};
                responseJson["responseGlobal"] = this.props.propsRedux.bulkEdit.responseJson["responseGlobal"] ? this.props.propsRedux.bulkEdit.responseJson["responseGlobal"] : {};
                responseJson["addons"] = this.props.propsRedux.bulkEdit.responseJson["responseAddon"] ? this.props.propsRedux.bulkEdit.responseJson["responseAddon"] : {};
            }

        }

        if (this.props.propsRedux.bulkEdit.state && typeof this.props.propsRedux.bulkEdit.ruleResponse != "undefined") {
            if (
                typeof this.props.propsRedux.bulkEdit.selectedNodeGlobal != "undefined" && 
                typeof this.props.propsRedux.bulkEdit.ruleResponse != "undefined" && 
                this.props.propsRedux.bulkEdit.applyChanges
            ) {
                for (const [key, value] of Object.entries(this.props.propsRedux.bulkEdit.ruleResponse)) {
                    if ((this.props.propsRedux.bulkEdit.selectedNodeGlobal).includes(key)) {
                        responseJson["ruleGlobal"][key] = value;
                    }
                }
            } else {
                if (
                    this.props.propsRedux.bulkEdit.applyChanges && 
                    (
                        (typeof this.props.propsRedux.bulkEdit.selectedNodeGlobal === "undefined" ) ||
                        (
                            Array.isArray(this.props.propsRedux.bulkEdit.selectedNodeGlobal) &&
                            (this.props.propsRedux.bulkEdit.selectedNodeGlobal.length <= 0)
                        )
                    )
                ) {
                    responseJson["ruleGlobal"] = {};
                } else {
                    responseJson["ruleGlobal"] = this.props.propsRedux.bulkEdit.ruleResponse;
                }
            }

        }
        
        return (
            !this.props.propsRedux.bulkEdit.state ? this.renderRulesTable(showGenericTable, generic, showAddOnTable, addOnRuleResponses, showTagRuleTable, scenarioRule, showMetadataTable) :
                (this.props.propsRedux.bulkEdit.state && (responseJson ? this.renderRulesTableBulk(responseJson) : {}))
        );
    }
    
    renderRulesTable(showGenericTable, generic, showAddOnTable, addOnRuleResponses, showTagRuleTable, scenarioRule, showMetadataTable) {
        return <>
            { showGenericTable &&
                <GenericTable
                    priorityOfGeneric={this.props.priorityOfGeneric}
                    className={[this.props.propsOwned.className, 'mt-3'].join(' ')}
                    genericResponse={generic}
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    node={this.props.propsRedux.selectedNode.node}
                    bulkEdit={this.props.propsRedux.bulkEdit} />
            }
            {showAddOnTable &&
                <AddOnRulesTable
                    className={[this.props.propsOwned.className].join(' ')}
                    ruleResponses={addOnRuleResponses}
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    selectedNode={this.props.propsRedux.selectedNode.node}
                    storyId={this.props.propsRedux.storyId}
                    bulkEdit= {this.props.propsRedux.bulkEdit} />}
            {showTagRuleTable &&
                scenarioRule &&
                <TagRulesTable
                    tagRule={scenarioRule}
                    selectedNode={this.props.propsRedux.selectedNode}
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal} />}
            {showMetadataTable &&
                <RulesMetadataTable
                    className={[this.props.propsOwned.className].join(' ')}
                    openigStatementJson={this.props.propsRedux.selectedNode.responseJson}
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    selectedNode={this.props.propsRedux.selectedNode}
                    storyId={this.props.propsRedux.storyId} />}
        </>;
    }

    renderRulesTableBulk(allOccurenceJson) {
       var indents = [];
        if(this.props.propsRedux.bulkEdit.state && allOccurenceJson.ruleGlobal){
             Object.keys(allOccurenceJson.ruleGlobal).map((node)=>{

                 let enable = (this.props.propsRedux.bulkEdit.removeableResponse && this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE && !this.props.propsRedux.bulkEdit.removeableResponse["global"].includes(node) )? true: false;
                 console.log("node name intent ",node,"  ",enable);
                indents.push(<IntentResponseGlobal
                    node={ node }
                    value={ allOccurenceJson.ruleGlobal[node]['response'] }
                    path={ allOccurenceJson.ruleGlobal[node].path}
                    key={ node }
                    enable= { enable }
                     />);
            });
        }
        
        if(
            this.props.propsRedux.bulkEdit.state && 
            allOccurenceJson.responseNode && 
            (allOccurenceJson.responseNode.length>0) &&
            (
                !this.props.propsRedux.bulkEdit.applyChanges ||
                (
                    this.props.propsRedux.bulkEdit.selectedNodeBulk &&
                    Array.isArray(this.props.propsRedux.bulkEdit.selectedNodeBulk) &&
                    (this.props.propsRedux.bulkEdit.selectedNodeBulk.length > 0)
                )
            )
        ){
            allOccurenceJson.responseNode.map((rnode)=>{
                let enable = (this.props.propsRedux.bulkEdit.removeableResponse && this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE && !this.props.propsRedux.bulkEdit.removeableResponse["response"].includes(rnode.node) )? true: false;

                indents.push(<GenericTable
                    priorityOfGeneric={rnode.priorityOfGeneric}
                    className={[this.props.propsOwned.className, 'mt-3'].join(' ')}
                    genericResponse={rnode.genericResponse}
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    node={rnode.node}
                    bulkEdit={this.props.propsRedux.bulkEdit}
                    key={ rnode.node }
                    enable={ enable } />);
        });
        }
        if(
            this.props.propsRedux.bulkEdit.state && 
            (
                (
                    allOccurenceJson.responseGlobal || 
                    allOccurenceJson.addons
                ) &&
                (
                    !this.props.propsRedux.bulkEdit.applyChanges ||
                    (
                        this.props.propsRedux.bulkEdit.selectedNodeBulk &&
                        Array.isArray(this.props.propsRedux.bulkEdit.selectedNodeBulk) &&
                        (this.props.propsRedux.bulkEdit.selectedNodeBulk.length > 0)
                    )
                )
            )
         ) {
        
            for(let key in allOccurenceJson.responseGlobal){
                let object = {[key]:allOccurenceJson.responseGlobal[key] };

                // check what is included instead of key or it's fine
                let enable = (this.props.propsRedux.bulkEdit.removeableResponse && this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE && !this.props.propsRedux.bulkEdit.removeableResponse["global"].includes(key) )? true: false;

                indents.push(<AddOnRulesTable
                    className={[this.props.propsOwned.className].join(' ')}
                    ruleResponses={ object }
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    selectedNode={this.props.propsRedux.selectedNode.node}
                    storyId={this.props.propsRedux.storyId} 
                    bulkEdit= {this.props.propsRedux.bulkEdit}
                    node= { key }
                    key= {key} 
                    isGlobal={true}
                    enable={ enable }
                />);
            }

            for(let addonObj in allOccurenceJson.addons){

                let selectable = (this.props.propsRedux.bulkEdit.removeableResponse && this.props.propsRedux.bulkEdit.action === global.CUST_CONSTANTS.CRUD_MODE.DELETE && !this.props.propsRedux.bulkEdit.removeableResponse["addon"].includes(addonObj)) ? true : false;
                let separator = params.callFlowSeparator;
                
                for(let addonKey in allOccurenceJson.addons[addonObj]){
                    let object = {[addonKey]:allOccurenceJson.addons[addonObj][addonKey] };
                    indents.push(<AddOnRulesTable
                    className={[this.props.propsOwned.className].join(' ')}
                    ruleResponses={ object }
                    onInputValueChange={this.onInputValueChange}
                    showResponseModal={this.props.showResponseModal}
                    selectedNode={this.props.propsRedux.selectedNode.node}
                    storyId={this.props.propsRedux.storyId} 
                    bulkEdit= {this.props.propsRedux.bulkEdit}
                    node= { addonObj }
                    key= { addonObj +separator+addonKey }
                    enable= { selectable } />);
                    }                
            }
        }

        return indents;
    }
}

/** Defining prop types requirements */
RulesTable.propTypes = {
    propsRedux: PropTypes.shape({
        selectedNode: PropTypes.shape({
            node: PropTypes.string,
            responseJson: PropTypes.object,
            isMetadata: PropTypes.bool,
            scenario: PropTypes.string,
            isSelectedFromJsonView: PropTypes.bool  ,
        }).isRequired,
        storyId: PropTypes.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            selectedNode: state.rules.selectedNode,
            rulesJson: state.rules.rulesJson,
            storyId: state.story.storyId,
            bulkEdit: state.rules.bulkEdit,
            botResponses: state.rules.botResponses,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { changeRuleResponse, showResponseModal, changeNodeAction })(RulesTable);