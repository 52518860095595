/*
 * File Description : Have common util functions being used in rules UI for sequencing rules
 * Author: Meghana Aggarwal
 */

import { getPathsDataFromResponseNodeObj, getPathString } from "./callFlowUtils";
import { getIterationKeyFromObj, getResponseKeyFromObj } from "./rulesUtils";

/**
 * Function to get count where 2^count > n & 2^(count-1) < n
 * @param {json} {n} n : no. of rules for which you want to get the number
 * @returns number
 */
const getTwosPower = ({n}) => {
    var count = 0;
    var pow = Math.pow(2, count);
    while (n>pow) {
        count++;
        pow = Math.pow(2, count);
    }
    return count;
}

const checkAddonRuleType = (respIsOnMultipleSteps, blockHasEntity) =>{
    if(respIsOnMultipleSteps && blockHasEntity){
        // return 'both'
        return 'entity';
    }
    else if(respIsOnMultipleSteps && !blockHasEntity){
        return 'response'
    }
    else if(!respIsOnMultipleSteps && blockHasEntity){
        return 'entity'
    }
    else{
        return null;
    }
}
const createSubRulePartsArr = ({callFlowStepsObj, callFlowMetaData, rulesJson, callFlowMapping}) => {
    var sequenceRulesPartsArr = [];
    var sequenceResponsePartsArr = [];
    var strictStepFound = false;
    /** List of intents if addon rules needed on it */
    var neededAddonNodes = [];
    /** List of intents if addon rules not needed on it */
    var removeAddOns = [];
    /** Rules data of addon rules are rules */
    var nodeAddonDataToBuild = {};
    /** Addon rules not having part if rules added */
    var nodeAddonNHResponses = [];

    let responseInUniqueStepChecker = {};
    let nonUniqueResponsesList = [];

    let CIANHBlock = {
        "lhs": "CurrentIntentAttempted",
        "op": "NOTHAVING",
        "rhs": []
    }
    let ABUNHBlock = {
        "lhs": "AttemptedBotUtterance",
        "op": "NOTHAVING",
        "rhs": []
    }
    let ABUHBlock = {
        "lhs": "AttemptedBotUtterance",
        "op": "HAVINGANY",
        "rhs": []
    }

    const mainSequence = callFlowMetaData['main-sequence'];
    const relations = callFlowMetaData['relations'];

    const mainSequenceMapper = {};
    const sequenceRulesStepIndex = {};
    const stepIntents = {}

    mainSequence.forEach((step,index) => {
        mainSequenceMapper[step] = index
        let stepEntities = [];
        strictStepFound = false;
        if(callFlowStepsObj[step] && callFlowStepsObj[step].settings && callFlowStepsObj[step].settings.entities && callFlowStepsObj[step].settings.entities.length){
            stepEntities = [...callFlowStepsObj[step].settings.entities]
        }
        /** added condition here so that blank steps are not considered as strict steps */
        if (callFlowStepsObj[step] && callFlowStepsObj[step].list && (callFlowStepsObj[step].list.length > 0)) {
            /** strict step would be found if it is not passable */
            strictStepFound = (callFlowStepsObj[step].settings && callFlowStepsObj[step].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY]) ? false : true;
            callFlowStepsObj[step].list.forEach( block => {
                if(block.node){
                    CIANHBlock.rhs.push(block.node);
                    stepIntents[step] = stepIntents[step] ? [...stepIntents[step],block.node] : [block.node]
                }
                let respTextToAdd = block.BRText.trim();
                if (strictStepFound) {
                    ABUNHBlock.rhs.push(respTextToAdd);
                }
                // if there is a passable intent in the beginning (first step)
                else if(!strictStepFound && index==0){
                    //ABUHBlock.rhs.push(respTextToAdd);
                }
                else {
                    ABUHBlock.rhs.push(respTextToAdd);
                }

                /** intent added in neededAddonNodes if multiple responses is there in call flow  */
                if (
                    nodeAddonDataToBuild[block.node] && 
                    (nodeAddonDataToBuild[block.node].length > 0) &&
                    nodeAddonDataToBuild[block.node][nodeAddonDataToBuild[block.node].length-1]
                ) {
                } else {
                    nodeAddonDataToBuild[block.node] = [];
                }
                let respIsOnMultipleSteps = checkMultipleResponseOnDiffSteps({rulesJson: rulesJson, callFlowMapping: callFlowMapping, node: block.node, stepCheckingOn: step});
                if (respIsOnMultipleSteps) {
                    neededAddonNodes.push(block.node);
                }

                const blockCopy = JSON.parse(JSON.stringify(block))
                if(stepEntities.length){
                    if(blockCopy.entities && blockCopy.entities.length){
                        blockCopy.entities = [...blockCopy.entities,...stepEntities ]
                    }
                    else{
                        blockCopy['entities'] = [...stepEntities]
                    }
                }
                let blockHasEntity = null;
                
                if(blockCopy.entities && blockCopy.entities.length){
                    neededAddonNodes.push(blockCopy.node);
                    blockHasEntity = true
                }

                if(!blockHasEntity && !respIsOnMultipleSteps){
                    removeAddOns.push(block.node);
                }

                

                nodeAddonDataToBuild[block.node].push({
                    /** addon rule response (via index) that will be added in Not having */
                    NHIndex: nodeAddonNHResponses.length,
                    response: { ...blockCopy },
                    stepFoundOn: step,
                    type: checkAddonRuleType(respIsOnMultipleSteps,blockHasEntity)
                    // isPassable: (!strictStepFound),
                    // change this condition for checking particular response repetition also
                    // isInGroup: ((callFlowStepsObj[step].list.length > 1) ? true : false)
                });

                /** Checking for unique responses present on all steps. Same response is allowed on same step only */
                let resTextToCheck = block.BRText.trim();
                if (!responseInUniqueStepChecker[resTextToCheck]) {
                    responseInUniqueStepChecker[resTextToCheck] = step;
                } else if (responseInUniqueStepChecker[resTextToCheck] !== step) {
                    nonUniqueResponsesList.push(resTextToCheck);
                    responseInUniqueStepChecker[resTextToCheck] = step;
                }
            });
        }
        if (strictStepFound) {
            /** making rhs having unique items only  */
            CIANHBlock.rhs = [...new Set(CIANHBlock.rhs)];
            ABUNHBlock.rhs = [...new Set(ABUNHBlock.rhs)];

            var finalRulePart = {
                CIANHBlock: CIANHBlock,
                ABUNHBlock: ABUNHBlock
            };
            if (sequenceRulesPartsArr.length > 0) {
                ABUHBlock.rhs = [ ...ABUHBlock.rhs, ...sequenceRulesPartsArr[sequenceRulesPartsArr.length-1].ABUNHBlock.rhs ];
            }
            if (ABUHBlock.rhs.length > 0) {
                /** making rhs having unique items only  */
                ABUHBlock.rhs = [...new Set(ABUHBlock.rhs)];
                finalRulePart["ABUHBlock"] = ABUHBlock;
            }

            /** adding not having block for addon rules if needed */
            nodeAddonNHResponses.push(finalRulePart.ABUNHBlock.rhs);

            if (strictStepFound) {
                sequenceRulesPartsArr.push(finalRulePart);
                sequenceRulesStepIndex[step] =  sequenceRulesPartsArr.length-1
            }
            CIANHBlock = {
                "lhs": "CurrentIntentAttempted",
                "op": "NOTHAVING",
                "rhs": []
            }
            ABUNHBlock = {
                "lhs": "AttemptedBotUtterance",
                "op": "NOTHAVING",
                "rhs": []
            }
            ABUHBlock = {
                "lhs": "AttemptedBotUtterance",
                "op": "HAVINGANY",
                "rhs": []
            }
            
            sequenceResponsePartsArr.push(getResponseFromCallFlow(callFlowStepsObj[step]));
            strictStepFound = false;
        }
    });
    
    CIANHBlock.rhs = [];
    ABUNHBlock.rhs = [];
    ABUHBlock.rhs = [];
    // let sameEndPoint = {}
    // let lastRuleCIARhs = {};
    // let relationRulesIndex = {}
    relations && Object.keys(relations).forEach((key) => {
        if(callFlowStepsObj[key] && callFlowStepsObj[key].list && (callFlowStepsObj[key].list.length > 0)){
            let accordianStartStep = [];
            let accordianEndStep = [];
            let firstRuleInterruptionSteps = [key];
            let firstStepIndex = sequenceRulesStepIndex[key];
            let firstRuleCIARhs = []; // to show original CIArhs of rule ;
            let lastRuleCIARhs = [];
            let mainSeqRelFirstRule = JSON.parse(JSON.stringify(sequenceRulesPartsArr[firstStepIndex]));
            if(stepIntents[key]){
                // mainSeqRelFirstRule.CIANHBlock.rhs = [...stepIntents[key]]
                firstRuleCIARhs.push(...stepIntents[key]);
            }
            mainSeqRelFirstRule['ABUHBlock'] = {...ABUHBlock};
            mainSeqRelFirstRule.ABUHBlock['rhs'] = [];
            let mainSequenceLastRuleCache = {};
            relations[key].forEach(relation=>{
                console.log('relation', relation,'key', key) 
                let singleRelationSequence = [];
                let singleRelationResponses = [];
                let lastStepOfMain = relation.path1[relation.path1.length-1];
                for(let i=relation.path1.length-1; i>=0;i--){
                    if(callFlowStepsObj[relation.path1[i]] && callFlowStepsObj[relation.path1[i]].list.length > 0){
                        lastStepOfMain = relation.path1[i]
                        break;
                    }
                }
                // sameEndPoint[lastStepOfMain] = sameEndPoint[lastStepOfMain]+1 || 1
                let mainSeqRelLastRule = {}

                // caching last rule of main sequence
                if(mainSequenceLastRuleCache[lastStepOfMain]){
                    mainSeqRelLastRule = mainSequenceLastRuleCache[lastStepOfMain]
                }else{
                    console.log('sequenceRulesStepIndex', sequenceRulesStepIndex, 'lastStepOfMain', lastStepOfMain)
                    let lastStepIndex = sequenceRulesStepIndex[lastStepOfMain];
                    mainSeqRelLastRule = JSON.parse(JSON.stringify(sequenceRulesPartsArr[lastStepIndex]));
                    mainSequenceLastRuleCache[lastStepOfMain] = mainSeqRelLastRule
                }
                lastRuleCIARhs.push(...mainSeqRelLastRule.CIANHBlock.rhs)

                relation.path2.forEach((step,index)=>{
                    let stepEntities = [];
                    strictStepFound = false;
                    if(callFlowStepsObj[step] && callFlowStepsObj[step].settings && callFlowStepsObj[step].settings.entities && callFlowStepsObj[step].settings.entities.length){
                        stepEntities = [...callFlowStepsObj[step].settings.entities]
                    }
                    /** added condition here so that blank steps are not considered as strict steps */
                    if (callFlowStepsObj[step] && callFlowStepsObj[step].list && (callFlowStepsObj[step].list.length > 0)) {
                        /** strict step would be found if it is not passable */
                        strictStepFound = (callFlowStepsObj[step].settings && callFlowStepsObj[step].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY]) ? false : true;
                        callFlowStepsObj[step].list.forEach( block => {
                            if(block.node){
                                CIANHBlock.rhs.push(block.node);
                            }
                            let respTextToAdd = block.BRText.trim();
                            if (strictStepFound) {
                                ABUNHBlock.rhs.push(respTextToAdd);
                            }
                            // if there is a passable intent in the beginning (first step)
                            else if(!strictStepFound && index==0){
                                //ABUHBlock.rhs.push(respTextToAdd);
                            }
                            else {
                                ABUHBlock.rhs.push(respTextToAdd);
                            }
                            /** intent added in neededAddonNodes if multiple responses is there in call flow  */
                            if (
                                nodeAddonDataToBuild[block.node] && 
                                (nodeAddonDataToBuild[block.node].length > 0) &&
                                nodeAddonDataToBuild[block.node][nodeAddonDataToBuild[block.node].length-1]
                            ) {
                            } else {
                                nodeAddonDataToBuild[block.node] = [];
                            }
                            let respIsOnMultipleSteps = checkMultipleResponseOnDiffSteps({rulesJson: rulesJson, callFlowMapping: callFlowMapping, node: block.node, stepCheckingOn: step});
                            if (respIsOnMultipleSteps) {
                                neededAddonNodes.push(block.node);
                            }
            
                            const blockCopy = JSON.parse(JSON.stringify(block))
                            if(stepEntities.length){
                                if(blockCopy.entities && blockCopy.entities.length){
                                    blockCopy.entities = [...blockCopy.entities,...stepEntities ]
                                }
                                else{
                                    blockCopy['entities'] = [...stepEntities]
                                }
                            }
                            let blockHasEntity = null;
                            
                            if(blockCopy.entities && blockCopy.entities.length){
                                neededAddonNodes.push(blockCopy.node);
                                blockHasEntity = true
                            }
            
                            if(!blockHasEntity && !respIsOnMultipleSteps){
                                removeAddOns.push(block.node);
                            }
                        
                            nodeAddonDataToBuild[block.node].push({
                                /** addon rule response (via index) that will be added in Not having */
                                NHIndex: nodeAddonNHResponses.length,
                                response: { ...blockCopy },
                                stepFoundOn: step,
                                type: checkAddonRuleType(respIsOnMultipleSteps,blockHasEntity)
                                // isPassable: (!strictStepFound),
                                // change this condition for checking particular response repetition also
                                // isInGroup: ((callFlowStepsObj[step].list.length > 1) ? true : false)
                            });
            
                            /** Checking for unique responses present on all steps. Same response is allowed on same step only */
                            let resTextToCheck = block.BRText.trim();
                            if (!responseInUniqueStepChecker[resTextToCheck]) {
                                responseInUniqueStepChecker[resTextToCheck] = step;
                            } else if (responseInUniqueStepChecker[resTextToCheck] !== step) {
                                nonUniqueResponsesList.push(resTextToCheck);
                                responseInUniqueStepChecker[resTextToCheck] = step;
                            }
                        });
                    }
                    if (strictStepFound) {
                        /** making rhs having unique items only  */
                        CIANHBlock.rhs = [...new Set(CIANHBlock.rhs)];
                        ABUNHBlock.rhs = [...new Set(ABUNHBlock.rhs)];
                        
                        var finalRulePart = {
                            CIANHBlock: CIANHBlock,
                            ABUNHBlock: ABUNHBlock
                        };
                        if (singleRelationSequence.length > 0) {
                            ABUHBlock.rhs = [ ...ABUHBlock.rhs, ...singleRelationSequence[singleRelationSequence.length-1].ABUNHBlock.rhs ];
                        }
                        if (ABUHBlock.rhs.length > 0) {
                            /** making rhs having unique items only  */
                            ABUHBlock.rhs = [...new Set(ABUHBlock.rhs)];
                            finalRulePart["ABUHBlock"] = ABUHBlock;
                        }
            
                        /** adding not having block for addon rules if needed */
                        nodeAddonNHResponses.push(finalRulePart.ABUNHBlock.rhs);
            
                        if (strictStepFound) {
                            singleRelationSequence.push(finalRulePart);
                        }
                        
                        if(index ===0){
                            let stepIdxInMainSeq = sequenceRulesStepIndex[key];
                            sequenceRulesPartsArr[stepIdxInMainSeq].CIANHBlock.rhs = [...sequenceRulesPartsArr[stepIdxInMainSeq].CIANHBlock.rhs,...finalRulePart.CIANHBlock.rhs ];
                            sequenceRulesPartsArr[stepIdxInMainSeq].CIANHBlock.rhs = [...new Set(sequenceRulesPartsArr[stepIdxInMainSeq].CIANHBlock.rhs)];
                            sequenceRulesPartsArr[stepIdxInMainSeq].ABUNHBlock.rhs = [...sequenceRulesPartsArr[stepIdxInMainSeq].ABUNHBlock.rhs,...finalRulePart.ABUNHBlock.rhs ];
                            sequenceRulesPartsArr[stepIdxInMainSeq].ABUNHBlock.rhs = [...new Set(sequenceRulesPartsArr[stepIdxInMainSeq].ABUNHBlock.rhs)];

                            //Pushing path2 array first element in cross-relation accordian
                            if(relation['relation'] === 'AND'){
                                let newFinalPart = JSON.parse(JSON.stringify(finalRulePart))
                                newFinalPart['lastMainResponse'] = [...mainSeqRelLastRule.ABUNHBlock.rhs];
                                newFinalPart['lastRelationStep'] = lastStepOfMain
                                accordianStartStep.push(newFinalPart);
                                firstRuleCIARhs.push(...finalRulePart.CIANHBlock.rhs)
                                firstRuleInterruptionSteps.push(step);
                            } 
                        }
                        singleRelationResponses.push(getResponseFromCallFlow(callFlowStepsObj[step]));
                        if(relation.path2.length-1 === index){
                            let lastStepOfRel = relation.path1[relation.path1.length-1];
                            // Code Repetition to be fixed

                            for(let i=relation.path1.length-1; i>=0;i--){
                                if(callFlowStepsObj[relation.path1[i]] && callFlowStepsObj[relation.path1[i]].list.length > 0){
                                    lastStepOfRel = relation.path1[i]
                                    break;
                                }
                            }

                            let rellastStepIdx = sequenceRulesStepIndex[lastStepOfRel];
                            if(relation['relation'] === 'OR'){
                                sequenceRulesPartsArr[rellastStepIdx+1].ABUHBlock.rhs = [...sequenceRulesPartsArr[rellastStepIdx+1].ABUHBlock.rhs,...finalRulePart.ABUNHBlock.rhs ];
                                sequenceRulesPartsArr[rellastStepIdx+1].ABUHBlock.rhs = [...new Set(sequenceRulesPartsArr[rellastStepIdx+1].ABUHBlock.rhs)];
                            }else{
                                let ANDABUHBlock = {...ABUHBlock};
                                ANDABUHBlock.rhs = [...finalRulePart.ABUNHBlock.rhs];
                                if(sequenceRulesPartsArr[rellastStepIdx+1]["ANDABUHBlock"]){
                                    sequenceRulesPartsArr[rellastStepIdx+1]["ANDABUHBlock"] = [...sequenceRulesPartsArr[rellastStepIdx+1]["ANDABUHBlock"],ANDABUHBlock]
                                }else{
                                    sequenceRulesPartsArr[rellastStepIdx+1]["ANDABUHBlock"] = [ANDABUHBlock]
                                }

                                //Pushing path2 array last element in cross-relation accordian
                                // let newFinalPart = {...finalRulePart,['rhs']:[...mainSeqRelLastRule.ABUNHBlock.rhs]}
                                accordianEndStep.push(finalRulePart);

                                lastRuleCIARhs.push(...finalRulePart.CIANHBlock.rhs)
                                // lastRuleCIARhs = {
                                //     ...lastRuleCIARhs,
                                //     [lastStepOfMain]: lastRuleCIARhs[lastStepOfMain] ? [
                                //         ...lastRuleCIARhs[lastStepOfMain],
                                //         ...finalRulePart.CIANHBlock.rhs
                                //     ]: [...finalRulePart.CIANHBlock.rhs]
                                // }

                                mainSeqRelFirstRule.ABUHBlock.rhs = [...mainSeqRelFirstRule.ABUHBlock.rhs,...ABUNHBlock.rhs]
                            }
                        }
                        CIANHBlock = {
                            "lhs": "CurrentIntentAttempted",
                            "op": "NOTHAVING",
                            "rhs": []
                        }
                        ABUNHBlock = {
                            "lhs": "AttemptedBotUtterance",
                            "op": "NOTHAVING",
                            "rhs": []
                        }
                        ABUHBlock = {
                            "lhs": "AttemptedBotUtterance",
                            "op": "HAVINGANY",
                            "rhs": []
                        }
                        
                        strictStepFound = false;
                    }    
                });

                singleRelationSequence.splice(0,1); 
                singleRelationResponses.splice(0,1);
                sequenceRulesPartsArr = [...sequenceRulesPartsArr,...singleRelationSequence];
                sequenceResponsePartsArr = [...sequenceResponsePartsArr, ...singleRelationResponses]
            });
            // cross rules for AND relation 

            if(accordianStartStep.length && accordianEndStep.length){
                // mainSeqRelFirstRule['CIANHBlock']['rhs'] = [...new Set([...firstRuleCIARhs,...lastRuleCIARhs])];
                mainSeqRelFirstRule['CIANHBlock']['rhs'] = [...firstRuleCIARhs]
                sequenceRulesPartsArr.push(mainSeqRelFirstRule);
                accordianStartStep.forEach((item1, i1) => {
                    let havingResp = [];
                    accordianEndStep.forEach((item2, i2) => {
                        if(i1 !== i2) {
                            havingResp.push(...item2.ABUNHBlock.rhs)
                        }
                    });
                    havingResp = [...new Set(havingResp)]
                    let newPartsObj = {...item1};
                    newPartsObj['ABUHBlock'] = {...ABUHBlock};
                    newPartsObj['ABUHBlock']['rhs'] = [...havingResp,...item1.lastMainResponse];
                    // let relationLastStep = newPartsObj['lastRelationStep'];
                    delete newPartsObj['lastMainResponse'];
                    // delete newPartsObj['lastRelationStep'];
                    console.log('firstRuleCIARhs', firstRuleCIARhs);
                    // newPartsObj['CIANHBlock']['rhs'] = [...new Set([...firstRuleCIARhs,...lastRuleCIARhs])];
                    newPartsObj['CIANHBlock']['rhs'] = [...firstRuleCIARhs]
                    sequenceRulesPartsArr.push(newPartsObj);

                    // relationRulesIndex[relationLastStep] =  relationRulesIndex[relationLastStep] ? [...relationRulesIndex[relationLastStep],sequenceRulesPartsArr.length-1] : [sequenceRulesPartsArr.length-1];
                    // relationRulesIndex[relationLastStep] = [...new Set(relationRulesIndex[relationLastStep])]
                });

                firstRuleInterruptionSteps.map((interuptionSteps)=>{
                    sequenceResponsePartsArr.push(getResponseFromCallFlow(callFlowStepsObj[interuptionSteps]));
                })
            }
        }    
    });

    // let multipleSameEndPoint = Object.keys(sameEndPoint).filter(step=> sameEndPoint[step] > 1);
    // multipleSameEndPoint.forEach(lastStep=>{
    //     relationRulesIndex[lastStep].forEach((ruleIdx, idx)=>{
    //         sequenceRulesPartsArr[ruleIdx].CIANHBlock.rhs = [...sequenceRulesPartsArr[ruleIdx].CIANHBlock.rhs, ...lastRuleCIARhs[lastStep]];
    //         sequenceRulesPartsArr[ruleIdx].CIANHBlock.rhs = [...new Set(sequenceRulesPartsArr[ruleIdx].CIANHBlock.rhs)]
    //     })
        
    // })

    /** making node names having unique items only  */
    neededAddonNodes = [...new Set(neededAddonNodes)];
    removeAddOns = [...new Set(removeAddOns)];

    return {
        sequenceRulesPartsArr: sequenceRulesPartsArr,
        sequenceResponsePartsArr: sequenceResponsePartsArr,
        addonRules: {
            nodeAddonNHResponses: nodeAddonNHResponses,
            nodeAddonDataToBuild: nodeAddonDataToBuild,
            neededAddonNodes: neededAddonNodes,
            removeAddOnsNodes: removeAddOns
        },
        nonUniqueResponsesList: nonUniqueResponsesList
    };
}


const checkMultipleResponseOnDiffSteps = ({rulesJson, callFlowMapping, node, stepCheckingOn}) => {
    let pathsData = [];
    let pathStr = "";
    let isMultiple = false;
    if (rulesJson && rulesJson.ruleResponses && rulesJson.ruleResponses[node]) {
        pathsData = getPathsDataFromResponseNodeObj({node: node, obj: rulesJson.ruleResponses[node]});
    }
    if (pathsData && Array.isArray(pathsData) && (pathsData.length > 0)) {
        pathsData.forEach( pathObj => {
            pathStr = getPathString(pathObj, true);
            if (callFlowMapping[pathStr] && callFlowMapping[pathStr].step && (callFlowMapping[pathStr].step != stepCheckingOn)) {
                isMultiple = true;
                return true;
            }
        });
    }

    return isMultiple;
}

const addNestedRule = (key,rulesArr) =>{
    if(rulesArr.length ===1){
        return rulesArr.shift();
    }
    return {
        [key + ".1"] : rulesArr.shift(),
        "LO":"AND",
        [key + ".2"] : addNestedRule(key + ".2",rulesArr),
    }
}

const createGlobalSubRule = (subKey, rulesArr) => {

    if(rulesArr.ANDABUHBlock && rulesArr.ANDABUHBlock.length){
        rulesArr.ABUHBlock = {
            [subKey + ".2.2.1"] : rulesArr.ABUHBlock,
            "LO":"AND",
            [subKey + ".2.2.2"] : addNestedRule(subKey + ".2.2.2", rulesArr.ANDABUHBlock),
        }
    }
    if (rulesArr.ABUHBlock) {
        rulesArr.ABUNHBlock = {
            [subKey + ".2.1"] : rulesArr.ABUNHBlock,
            "LO":"AND",
            [subKey + ".2.2"] : rulesArr.ABUHBlock,
        }
    }

    return {
        [subKey]: {
            [subKey + ".1"] : rulesArr.CIANHBlock,
            "LO":"AND",
            [subKey + ".2"] : rulesArr.ABUNHBlock,
        }
    };
}

const createAddonSubRule = (subKey, responseArr, having=false) => {
    var rhsResponse = [ responseArr ];
    if (Array.isArray(responseArr)) {
        rhsResponse = [ ...responseArr ];
    }
    
    return {
        [subKey]: {
            lhs: "AttemptedBotUtterance",
            op: having ? "HAVINGANY" : "NOTHAVING",
            rhs: rhsResponse
        }
    };
    
}

const createAddonResponse = (responseObj) => {
    /** Creating default response object */
    let newResponseObj = {
            [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: [
                    {
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_ID]: responseObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_ID],
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT]: responseObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT],
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP]: responseObj[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP],
                    }
                ]
            }
        }
    return newResponseObj;
}

/**
 * 
 * @param {*} param0 {rulesCount : how may rules to be added, sequenceRulesPartsArr: array of object of rules parts blocks, sequenceResponsePartsArr: rulesponse objects from response type json array}
 * @returns array of responses: responseObj ; array of subrules { [key]: {rule json} }
 */
const createSubRulesArr = ({rulesCount, sequenceRulesPartsArr=[], sequenceResponsePartsArr=[], ruleBaseKey="rule", addonNHArray=[]}) => {
    var count = getTwosPower({n: rulesCount});
    let responsesObj = {};
    let responsesIncluded = {};
    let subRulesArr = [];
    let maxBitArr = Array.from(Array(count), (_, index) => (Math.pow(2, (count-index-1))));
    let countBitArr = new Array(count).fill(0);
    let bitEntryArr = new Array(count).fill(1);
    let isRuleCountOdd = ((rulesCount%2) != 0);
    let normalKeysTill =  (rulesCount > 2) ? (isRuleCountOdd ? (rulesCount - 1) : (rulesCount-2)) :  rulesCount;
    let subKey = "";
    for (let index = 0; index < normalKeysTill; index++) {
        if (
            (
                (ruleBaseKey === "global_rule") &&
                sequenceRulesPartsArr[index].CIANHBlock && 
                sequenceRulesPartsArr[index].ABUNHBlock
            ) ||
            (
                (ruleBaseKey === "rule") &&
                ( "NHIndex" in sequenceRulesPartsArr[index]) && 
                sequenceRulesPartsArr[index].response
            )
        ) {
            subKey = ruleBaseKey + bitEntryArr.join(".");
            if (ruleBaseKey === "global_rule") {
                responsesObj[subKey] = sequenceResponsePartsArr[subRulesArr.length];
                subRulesArr.push(createGlobalSubRule(subKey, sequenceRulesPartsArr[index]));
                responsesIncluded[sequenceRulesPartsArr[index].id] = subKey;
            } else {
                responsesObj[subKey] = createAddonResponse(sequenceRulesPartsArr[index].response);
                responsesIncluded[sequenceRulesPartsArr[index].response.id] = subKey;

                /** 2nd occurence of : If 2 objects have same ref response (next strict is same for multiple responses) (Same intent in multiple passable) */
                if (
                    sequenceRulesPartsArr[index+1] && 
                    sequenceRulesPartsArr[index] && 
                    sequenceRulesPartsArr[index+1].NHIndex &&
                    sequenceRulesPartsArr[index].NHIndex &&
                    ( sequenceRulesPartsArr[index+1].NHIndex === sequenceRulesPartsArr[index].NHIndex )
                ) {
                    subRulesArr.push(createAddonSubRule(subKey, sequenceRulesPartsArr[index].response.BRText, false));
                } else if ((index === (rulesCount-1)) && sequenceRulesPartsArr[index-1] && sequenceRulesPartsArr[index-1].NHIndex) {
                    /** if last part or the rules, just need opposite of last second */
                    
                    /** 2nd occurence of : If 2 objects have same ref response (next strict is same for multiple responses) (Same intent in multiple passable) */
                    if (
                        sequenceRulesPartsArr[index-1] && 
                        sequenceRulesPartsArr[index] && 
                        sequenceRulesPartsArr[index-1].NHIndex &&
                        sequenceRulesPartsArr[index].NHIndex &&
                        ( sequenceRulesPartsArr[index-1].NHIndex === sequenceRulesPartsArr[index].NHIndex )
                    ) {
                        subRulesArr.push(createAddonSubRule(subKey, sequenceRulesPartsArr[index-1].response.BRText, true));
                    } else {
                        subRulesArr.push(createAddonSubRule(subKey, addonNHArray[sequenceRulesPartsArr[index-1].NHIndex], true));
                    }
                } else {
                    subRulesArr.push(createAddonSubRule(subKey, addonNHArray[sequenceRulesPartsArr[index].NHIndex], false));
                }
            }
            /** For last or last 2 rules */
            for (let i = 0; i < count; i++) {
                
                countBitArr[i]++;
                if (countBitArr[i] === maxBitArr[i]) {
                    bitEntryArr[i] = bitEntryArr[i] == 1 ? 2 : 1;
                    countBitArr[i] = 0;
                    if ((index == (normalKeysTill-1)) && (sequenceRulesPartsArr[index + 1])) {
                        bitEntryArr.splice(i+1);
                        /** If rule count is odd , this will be the last rule */
                        if (isRuleCountOdd) {
                            subKey = ruleBaseKey + bitEntryArr.join(".");
                            if (ruleBaseKey === "global_rule") {
                                responsesObj[subKey] = sequenceResponsePartsArr[subRulesArr.length];
                                subRulesArr.push(createGlobalSubRule(subKey, sequenceRulesPartsArr[index + 1]));
                                responsesIncluded[sequenceRulesPartsArr[index + 1].id] = subKey;
                            } else {
                                responsesObj[subKey] = createAddonResponse(sequenceRulesPartsArr[index + 1].response);
                                /** if last part or the rules, just need opposite of last second */
                                subRulesArr.push(createAddonSubRule(subKey, addonNHArray[sequenceRulesPartsArr[index].NHIndex], true));
                                responsesIncluded[sequenceRulesPartsArr[index + 1].response.id] = subKey;
                            }
                        } else {
                            /** If rule count is even , there will be 2 rules in last */
                            subKey = ruleBaseKey + bitEntryArr.join(".") + ".1";
                            if (ruleBaseKey === "global_rule") {
                                responsesObj[subKey] = sequenceResponsePartsArr[subRulesArr.length];
                                subRulesArr.push(createGlobalSubRule(subKey, sequenceRulesPartsArr[index + 1]));
                                responsesIncluded[sequenceRulesPartsArr[index + 1].id] = subKey;
                            } else {
                                responsesObj[subKey] = createAddonResponse(sequenceRulesPartsArr[index + 1].response);
                                subRulesArr.push(createAddonSubRule(subKey, addonNHArray[sequenceRulesPartsArr[index + 1].NHIndex], false));
                                responsesIncluded[sequenceRulesPartsArr[index + 1].response.id] = subKey;
                            }

                            subKey = ruleBaseKey + bitEntryArr.join(".") + ".2";
                            if (ruleBaseKey === "global_rule") {
                                responsesObj[subKey] = sequenceResponsePartsArr[subRulesArr.length];
                                subRulesArr.push(createGlobalSubRule(subKey, sequenceRulesPartsArr[index + 2]));
                                responsesIncluded[sequenceRulesPartsArr[index + 2].id] = subKey;
                            } else {
                                responsesObj[subKey] = createAddonResponse(sequenceRulesPartsArr[index + 2].response);
                                /** if last part or the rules, just need opposite of last second */
                                subRulesArr.push(createAddonSubRule(subKey, addonNHArray[sequenceRulesPartsArr[index + 1].NHIndex], true));
                                responsesIncluded[sequenceRulesPartsArr[index + 2].response.id] = subKey;
                            }
                        }
                        break;
                    }
                }
            }
        }
    }
    return {
        responsesObj: responsesObj,
        subRulesArr: subRulesArr,
        responsesIncluded: responsesIncluded,
    };
}


const createEntityAddonRule = (subKey, rulesArr, type) => {
    if (rulesArr.length <= 0) {
        return;
    }
    let interruptionObj = {};
    let interruptionIncluded = {}
    const obj = {
      [subKey]: {}
    };
  
    if (rulesArr.length === 1) {
      let entityData = rulesArr.shift();
      
      let critera = type === "reverse" ? !entityData.criteria : entityData.criteria;
      if(type === "reverse"){
        if(entityData.interruption){
            interruptionObj[subKey] = createAddonResponse(entityData.interruption);
            if(entityData.interruption.id) interruptionIncluded[entityData.interruption.id] = subKey;
        }
      }
      obj[subKey] = {
        lhs: "CurrentUtteranceEntities",
        op:  critera  ? "HAVING" : "NOTHAVING",
        rhs: [entityData.value]
      };

    } else {
      let entityData = rulesArr.shift();
      
      let critera = type === "reverse" ? !entityData.criteria : entityData.criteria;
      obj[subKey] = {
        [subKey + ".1"]: {
            lhs: "CurrentUtteranceEntities",
            op:  critera  ? "HAVING" : "NOTHAVING",
            rhs: [entityData.value]
        },
        LO:  type === "reverse" ? "OR":"AND"
      };

      if(type === "reverse"){
        if(entityData.interruption){
            interruptionObj[subKey + ".1"] = createAddonResponse(entityData.interruption);
            if(entityData.interruption.id) interruptionIncluded[entityData.interruption.id] = subKey + ".1";
        }
      }
      if (rulesArr.length >= 1) {
        const rescursionResp = createEntityAddonRule(
            subKey + ".2",
            rulesArr,
            type
          );
        obj[subKey][subKey + ".2"] = rescursionResp.obj[subKey + ".2"];
        interruptionObj = {...interruptionObj,...rescursionResp.interruptionObj };
        interruptionIncluded = {...interruptionIncluded,...rescursionResp.interruptionIncluded}
      }
    }
    return {obj, interruptionObj, interruptionIncluded};
  };

  function entityRulesRecursionFn(bitArr, subKey, arr, obj = {},sequenceRulesPartsArr,index =0,type) {
    if(arr.length <=0){
        return;
    }
    let subResponsesObj = {};
    let subResponsesIncluded = {};
    if (arr.length) {
      const newSubKey = subKey + ".1";
      const newBlockArr = arr.shift();
      if(type !=='reverse'){
        subResponsesObj[newSubKey] = createAddonResponse(sequenceRulesPartsArr[index].response);
        subResponsesIncluded[sequenceRulesPartsArr[index].response.id] = newSubKey;
      }
      const blockRuleData = createEntityAddonRule(newSubKey, newBlockArr,type);
      obj = {
        [newSubKey]: blockRuleData.obj[newSubKey],
        LO: "OR"
      };
      subResponsesObj = {...subResponsesObj,...blockRuleData.interruptionObj}
      subResponsesIncluded = {...subResponsesIncluded,...blockRuleData.interruptionIncluded}

      if (arr.length === 1) {
        const newSubKey = subKey + ".2";
        const newBlockArr = arr.shift();
        index++;
        if(type !=='reverse'){
            subResponsesObj[newSubKey] = createAddonResponse(sequenceRulesPartsArr[index].response);
            subResponsesIncluded[sequenceRulesPartsArr[index].response.id] = newSubKey;
        }
        const blockRuleData = createEntityAddonRule(newSubKey, newBlockArr,type);
        obj[newSubKey] = blockRuleData.obj[newSubKey];
        subResponsesObj = {...subResponsesObj,...blockRuleData.interruptionObj}
        subResponsesIncluded = {...subResponsesIncluded,...blockRuleData.interruptionIncluded}
        
      } else if (arr.length > 1) {
        const newSubKey = subKey + ".2";
        obj[newSubKey] = entityRulesRecursionFn(bitArr, newSubKey, arr,{},sequenceRulesPartsArr,index++,type).obj;
      }
    }
    return {obj,subResponsesObj,subResponsesIncluded};
  }

const createEntitySubRulesArr = ({rulesCount, sequenceRulesPartsArr=[], sequenceResponsePartsArr=[], ruleBaseKey="rule", entitiesArray=[]}) => {
    let responsesObj = {};
    let responsesIncluded = {};
    let subRulesArr = [];
    let bitEntryArr = [1];    
    let subKey = ''
    let newEntitiesArr = JSON.parse(JSON.stringify(entitiesArray));
    let firstRuleObj = {}
    
    if(newEntitiesArr.length ===1){
        const newSubKey = 'rule' +"1";

        const blockEntityData = createEntityAddonRule(newSubKey,newEntitiesArr[0]);
        firstRuleObj[newSubKey] = blockEntityData.obj[newSubKey];
        subRulesArr.push(firstRuleObj);
        responsesObj[newSubKey] = createAddonResponse(sequenceRulesPartsArr[0].response);
        responsesIncluded[sequenceRulesPartsArr[0].response.id] = newSubKey;

    }else{
        subKey = ruleBaseKey + bitEntryArr.join(".");
        let {obj,subResponsesObj, subResponsesIncluded} = entityRulesRecursionFn(bitEntryArr,subKey,newEntitiesArr ,firstRuleObj, sequenceRulesPartsArr,0);
        subRulesArr.push({[subKey]:obj});
        responsesObj = {...responsesObj, ...subResponsesObj};
        responsesIncluded = {...responsesIncluded,...subResponsesIncluded}
    }

    const responseIdsToBeDeleted = Object.keys(responsesIncluded).map(key=> key)


    if(subRulesArr.length){
        let secondRuleObj ={}
        let newEntitiesArr = JSON.parse(JSON.stringify(entitiesArray))
        if(newEntitiesArr.length ===1){
            const newSubKey = 'rule' +"2";
            const blockEntityData = createEntityAddonRule(newSubKey,newEntitiesArr[0],"reverse");
            secondRuleObj[newSubKey] = blockEntityData.obj[newSubKey]
            subRulesArr.push(secondRuleObj);
            responsesObj = {...responsesObj,...blockEntityData.interruptionObj}
            responsesIncluded = {...responsesIncluded, ...blockEntityData.interruptionIncluded}
    
        }
        else{
            bitEntryArr[0] = 2
            subKey = ruleBaseKey + bitEntryArr.join(".");
            
            let {obj,subResponsesObj, subResponsesIncluded} = entityRulesRecursionFn(bitEntryArr,subKey,newEntitiesArr ,secondRuleObj, sequenceRulesPartsArr,0,"reverse");
            subRulesArr.push({[subKey]:obj});
            responsesObj = {...responsesObj, ...subResponsesObj};
            responsesIncluded = {...responsesIncluded,...subResponsesIncluded}

        }
    }

    return {
        responsesObj: responsesObj,
        subRulesArr: subRulesArr,
        responsesIncluded: responsesIncluded,
        responseIdsToBeDeleted
    };
}

const createRuleTree = ({subRules = [], ruleBaseKey = "addon_rule"}) => {
    let currentIndex = 0;
    while (subRules.length > 1) {
        currentIndex = 0;
        while (subRules[currentIndex+1]) {
            let subRuleKey1 = Object.keys(subRules[currentIndex])[0];
            let subRuleKey2 = Object.keys(subRules[currentIndex+1])[0];
            let newKey = "";
            // rulekey length have to be atleast 3 characters
            if (subRuleKey1.charAt(subRuleKey1.length-2) == '.') {
                newKey = subRuleKey1.substr(0,subRuleKey1.length-2);
            } else {
                newKey = ruleBaseKey;
            }
            subRules[currentIndex] = {
                [newKey] : {
                    [subRuleKey1]: subRules[currentIndex][subRuleKey1],
                    "LO":"OR",
                    [subRuleKey2]: subRules[currentIndex + 1][subRuleKey2],
                }
            }
            subRules.splice(currentIndex+1, 1);
            currentIndex++;
        }
    }
    return subRules[0];
}

const getResponseFromCallFlow = (callFlowStep) => {
    let responseObj = {callFlowStep};
    if (callFlowStep.settings && callFlowStep.settings.interruption) {
        responseObj = callFlowStep.settings.interruption;
    } else {
        /** Creating default response object */
        responseObj = {
            [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE]: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: [
                    {
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_ID]: 0,
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_TEXT]: "",
                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_OBJECT_KEYS.RESPONSE_CLIP]: "",
                    }
                ]
            }
        }
    }
    return responseObj;
}

const addAddonRuleToNode = (addonRuleJson, ruleNode) => {
    let newRuleNode = {
        rule: {
            "intent_compare": {},
            "LO": "AND",
            ...addonRuleJson
        },
    };
    if (
        ruleNode &&
        ruleNode.rule &&
        ruleNode.rule.intent_compare &&
        ruleNode.type
    ) {
        newRuleNode.rule.intent_compare = { ...ruleNode.rule.intent_compare };
        newRuleNode.type = ruleNode.type;
    } else {
        newRuleNode.rule.intent_compare = { ...ruleNode.rule };
        newRuleNode.type = ruleNode.type;
    }
    return newRuleNode;
}

const addAddonResponsesToNode = (addonResponses, responseNode, nodeResponseObj, responsesIncluded,responseIdsToBeDeleted, ruleType) => {
    let allKeys = Object.keys(addonResponses);
    let lastKey = allKeys[allKeys.length-1];
    let lastKeyResponse = { ...addonResponses[lastKey] };
    if(ruleType !=='entity'){
        delete addonResponses[lastKey];
    }
    
    let newResponseNode = {
        ...addonResponses,
        generic: { ...nodeResponseObj.generic }
    }

    let responseArr = [];
    let pathsData = getPathsDataFromResponseNodeObj({node: responseNode, obj: nodeResponseObj});
    pathsData.forEach( responseObj => {
        if (
            !responsesIncluded ||
            (responsesIncluded && responseObj.referenceId && !responsesIncluded[responseObj.referenceId])
        ) {
            responseArr.push({
                id: responseObj.referenceId,
                BRText: responseObj.BRText,
                BRClip: responseObj.BRClip,
                responseUsed: "False",
            });
        }
    });
    
    if(ruleType === 'entity'){
        let responseKey = getResponseKeyFromObj(newResponseNode.generic.evalTrue);
        let iterationKey = getIterationKeyFromObj(newResponseNode.generic.evalTrue[responseKey]);
        let filteredGenericResponses  = newResponseNode.generic.evalTrue[responseKey][iterationKey].filter(response=>{
            return !responseIdsToBeDeleted.includes(response.id)
        })
        newResponseNode.generic.evalTrue[responseKey][iterationKey] = [...filteredGenericResponses]
    }

    if(ruleType !=='entity'){
        newResponseNode.generic.evalTrue = { ...lastKeyResponse };
        if (responseArr.length>0) {
            let responseKey = getResponseKeyFromObj(lastKeyResponse);
            let iterationKey = getIterationKeyFromObj(lastKeyResponse[responseKey]);
            newResponseNode.generic.evalTrue[responseKey][iterationKey] = [ ...newResponseNode.generic.evalTrue[responseKey][iterationKey], ...responseArr ];
        }
    }

    return newResponseNode;
}
const ruleTypeForIntent = (arr) =>{
    let entities = [];
    let types = []
    let entitySequenceRulesPartsArr = arr.filter(obj=>{
        types.push(obj.type)
        if(obj.response.entities && obj.response.entities.length > 0){
            entities.push(obj.response.entities);
            return obj
        }
        
    });

    let ruleType = null;
    if(types.includes('both')){
        ruleType = 'both';

    }
    // else if(['response','entity'].indexOf(types) !== -1){
    //     ruleType = 'both';

    // }
    else if(types.includes('response') && types.includes('entity')){
        ruleType = 'entity';

    }else if(types.includes('response')){
        ruleType = 'response';

    }else if(types.includes('entity')){
        ruleType = 'entity';

    }else{
        ruleType = null
    }

    return {
        ruleType,
        entities,
        entitySequenceRulesPartsArr
    }
}
const createAddonRules = ({addonRulesParts = {}, rulesJson = {}}) => {
    let changedJson = JSON.parse(JSON.stringify(rulesJson));
    if (
        addonRulesParts.neededAddonNodes && 
        addonRulesParts.nodeAddonDataToBuild && 
        addonRulesParts.nodeAddonNHResponses && 
        addonRulesParts.removeAddOnsNodes && 
        rulesJson &&
        rulesJson.rule &&
        rulesJson.ruleResponses
    ) {
        if (addonRulesParts.neededAddonNodes.length > 0) {
            addonRulesParts.neededAddonNodes.forEach( (nodeTemp) => {
                if (
                    addonRulesParts.nodeAddonDataToBuild[nodeTemp]
                ) {
                    let {ruleType, entities, entitySequenceRulesPartsArr} = ruleTypeForIntent(addonRulesParts.nodeAddonDataToBuild[nodeTemp]);
                    if(ruleType){
                        addonRulesParts.removeAddOnsNodes = addonRulesParts.removeAddOnsNodes.filter(node=> node!==nodeTemp)
                    }
                    if(ruleType ==='entity'){
                        var {responsesObj, subRulesArr, responsesIncluded, responseIdsToBeDeleted} = createEntitySubRulesArr({
                            rulesCount: entities.length,
                            sequenceRulesPartsArr: entitySequenceRulesPartsArr,
                            entitiesArray: entities,
                            ruleBaseKey: "rule"
                        }); 
                    }
                    else{
                        var {responsesObj, subRulesArr, responsesIncluded} = createSubRulesArr({
                            rulesCount: addonRulesParts.nodeAddonDataToBuild[nodeTemp].length,
                            sequenceRulesPartsArr: addonRulesParts.nodeAddonDataToBuild[nodeTemp],
                            addonNHArray: addonRulesParts.nodeAddonNHResponses,
                            ruleBaseKey: "rule"
                        });

                    }
                    let addonRulesJson = createRuleTree({ subRules: subRulesArr, ruleBaseKey: "addon_rule"});
                    changedJson.rule[nodeTemp] = { ...addAddonRuleToNode(addonRulesJson, changedJson.rule[nodeTemp]) };
                    changedJson.ruleResponses[nodeTemp] = { ...addAddonResponsesToNode(responsesObj, nodeTemp, changedJson.ruleResponses[nodeTemp], responsesIncluded, responseIdsToBeDeleted, ruleType) };
                }
            });
        } 
        if (addonRulesParts.removeAddOnsNodes) {
            /** If addon need to be removed or remake needed because of repetition of node is on same step */
            addonRulesParts.removeAddOnsNodes.forEach( (nodeTemp) => {
                if (rulesJson.ruleResponses[nodeTemp]) {
                    let pathsData = getPathsDataFromResponseNodeObj({node: nodeTemp, obj: rulesJson.ruleResponses[nodeTemp]});
                    if (pathsData && Array.isArray(pathsData)) {
                        let responseArr = []
                        pathsData.forEach( responseObj => {
                            responseArr.push({
                                id: responseObj.referenceId,
                                BRText: responseObj.BRText,
                                BRClip: responseObj.BRClip,
                                responseUsed: "False",
                            });
                        });
                        let typeTemp = (rulesJson && rulesJson.rule && rulesJson.rule[nodeTemp] && rulesJson.rule[nodeTemp].type) ? rulesJson.rule[nodeTemp].type : 1;
                        changedJson.rule[nodeTemp] = {
                            rule: {
                                lhs: "CurrentIntentAttempted",
                                op: "EQUALSTO",
                                rhs: nodeTemp
                            },
                            type: typeTemp,
                        }
                        changedJson.ruleResponses[nodeTemp] = {
                            generic: {
                                evalFalse: { ...rulesJson.ruleResponses[nodeTemp].generic.evalFalse },
                                evalTrue: {
                                    [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                                        /** Making iteration type as random instead of default (sequence) because same step same node different response means it is expecting randomly any among the responses */
                                        [global.CUST_CONSTANTS.PREDEFINED_KEYS.ITERATION_TYPE.RANDOM]: [ ...responseArr ]
                                    }
                                },
                                priority: 1
                            }
                        }
                    }
                } else {
                    global.recordAudit("Issue with call flow on node" + nodeTemp + " not present in json");
                    console.log("DEBUGGER:: removeAddOnsNodes rulesJson.ruleResponses", rulesJson.ruleResponses);
                }
            });
        }
    }
    return changedJson;
}

export const getGlobalRules = ({callFlowStepsObj, callFlowMetaData, stepSequence, rulesJson, currentCallFlowMapping}) => {
    var {sequenceRulesPartsArr, sequenceResponsePartsArr, addonRules, nonUniqueResponsesList} = createSubRulePartsArr({callFlowStepsObj: callFlowStepsObj, callFlowMetaData: callFlowMetaData, stepSequence: stepSequence, rulesJson: rulesJson, callFlowMapping: currentCallFlowMapping});
    var {responsesObj, subRulesArr, responsesIncluded} = createSubRulesArr({
        rulesCount: sequenceRulesPartsArr.length,
        sequenceRulesPartsArr: sequenceRulesPartsArr,
        sequenceResponsePartsArr: sequenceResponsePartsArr,
        ruleBaseKey: "global_rule"
    });
    var globalRuleTree = createRuleTree({ subRules: subRulesArr, ruleBaseKey: "global_addon_rule"});
    let newRulesJson = createAddonRules({
        addonRulesParts: addonRules,
        rulesJson: rulesJson
    });

    if (newRulesJson.rule && rulesJson.rule && globalRuleTree && (Object.keys(globalRuleTree).length > 0)) {
        if (globalRuleTree && (Object.keys(globalRuleTree).length > 0)) {
            if (!newRulesJson.rule[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE] && globalRuleTree) {
                newRulesJson.rule = {
                    [global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE]: {},
                    ...newRulesJson.rule
                }
            }
            newRulesJson.rule[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE] = JSON.parse(JSON.stringify(globalRuleTree));
        }
    }  
    if (newRulesJson.ruleResponses && rulesJson.ruleResponses && responsesObj) {
        if (responsesObj && (Object.keys(responsesObj).length > 0)) {
            if (!newRulesJson.ruleResponses[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE]) {
                newRulesJson.ruleResponses = {
                    [global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE]: {},
                    ...newRulesJson.ruleResponses
                }
            } 
            newRulesJson.ruleResponses[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE] = JSON.parse(JSON.stringify(responsesObj));
        }
    }
    return {
        newRulesJson: newRulesJson,
        nonUniqueResponsesList: nonUniqueResponsesList
    };
}


