/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import PropTypes from 'prop-types';

/** Importing custom Components */
import Select from "../../../Inputs/RulesSelect/RulesSelect";

/** Importing custom Styles */
import classes from './RuleType.module.css';

/** Main Components */
const RuleType = props => {

    const types = [
        {
            value: 1,
            text: "Normal Statement"
        },
        {
            value: 2,
            text: "Last Statement"
        }
    ];

    return (
        <div className={ [ "d-flex align-items-center", ((props.isResponseRuleType) ? classes.ResponseRuleType : classes.BasicRuleType) ].join(' ') }>
            <label className={ [ ((props.isResponseRuleType) ? classes.ResponseRuleType : "w-50 m-0 lheight-0") ].join(' ') }> Type : &nbsp;</label>
            <Select 
                selectedValue={ props.type }
                options={ types }
                name={ (props.name) ? props.name : "storyType" }
                onChange={ props.onChange }
                readOnly={ props.readOnly }
                dataNode= { props.dataNode }
            />
        </div>
    );
}

/** Defining prop types requirements */
RuleType.propTypes = {
    type  : PropTypes.number,
    onChange : PropTypes.func.isRequired,
    name : PropTypes.string,
    readOnly : PropTypes.bool,
    isResponseRuleType : PropTypes.bool,
};

/** Exporting main component */
export default RuleType;