/** Importing pre defined Libraries/Components */
import React, { useState } from 'react';

/** Importing custom Components */

/** Importing custom Styles */

/** Main Components */
const Select = props => {

    let selectedOpt = '';

    if (props.selectedValue!==undefined) { 
        selectedOpt = props.selectedValue
    } else if (props.options.length>=1) {
        if ( typeof( props.options[0] ) == "string" ) {
            selectedOpt = props.options[0].toString();
        } else {
            selectedOpt = props.options[0].value;
        }
    }
    /** Hook for value */

    const _handleChange = event => {
        props.onChange(event);
    }

    return (
        <select className={ [ 'form-control', props.className, "RuleSelect" ].join(' ') } name={ (props.name) ? props.name : "select" } value={ selectedOpt ? selectedOpt : ''} onChange={ e => _handleChange(e) } data-rulekey={ props.dataRulekey } disabled={ props.readOnly } data-node={ props.dataNode }>
            {
                props.options.map( ( option , optIndex) => {
                    if (typeof(option)==="string") {
                        return <option key={ optIndex }  value={ option.toString() } >{ option }</option>
                    }
                    return (
                        <option key={ optIndex }  value={ option.value } >{ option.text }</option>
                    )
                })
            }
        </select>
    );
}

/** Exporting main component */
export default Select;