/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';

/** Importing custom Components */

/** Importing custom Styles */

/** Main Components */
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
	return (
		<input
			value={filterValue || ''}
			onChange={e => {
				setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
			}}
			placeholder={"Search..."}
		/>
	);
}

/** Exporting main component */
export default DefaultColumnFilter;