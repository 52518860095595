/** Importing pre defined Libraries/Components */
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";

/** Importing custom Styles */
import classes from './Modal.module.css';
import './Modal.css';

/** Main Components */
const Example = props => {
    

    // see why not getting configured
    const defaultShow = (props.show) ? props.show : false;
    const [show, setShow] = useState(defaultShow);

    const onHide = () => {
        if (props.onHide !== undefined) {
            props.onHide();
        } else {
            setShow(false);
        }
    }

    const onShow = () => {
        if (props.onShow !== undefined) {
            props.onShow();
        } else {
            setShow(true);
        }
        ReactTooltip.rebuild();
    }

    var footerComponents = '';

    if(props.footerComponents && props.footerComponents.length > 0) {
        footerComponents = props.footerComponents.map(( btnObj, index) => {
            var propsObj = {
                key: "ModalButtons_"+index,
                variant: btnObj.variant,
                onClick: btnObj.onClick,
                className: [ btnObj.className, "response-btn" ].join(' '),
                disabled: btnObj.isDisabled,
                type: (btnObj.type || "button"),
            };
            if (btnObj.otherProps) {
                propsObj = { ...propsObj, ...btnObj.otherProps }
            }
            var returnObj = "";
            if (propsObj.type === "htmlLink") {
                returnObj = (
                    <a {...propsObj} >
                        { btnObj.text }
                    </a>
                )
            } else {
                returnObj = (
                    <Button {...propsObj} >
                        { btnObj.text }
                    </Button>
                )
            }
            return ( returnObj );
        })
    }

    const propsToGive = {...props};
    delete propsToGive['footerComponents'];

    return (
        <Modal show={props.show} onHide={ onHide } onShow={ onShow } size={ props.size } className={ [ props.className, classes.Modal ].join(' ') } >
            <Modal.Header closeButton className={ [ classes.ModalHeader ].join(' ') }>
                <Modal.Title className={ [ classes.Title ].join(' ') }>{ props.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props.children }
            </Modal.Body>
            <Modal.Footer>{ footerComponents }</Modal.Footer>
        </Modal>
    );
}

/** Exporting main component */
export default Example;