import React ,{ Component} from 'react';
import classes from './ViewSideBar.module.css';
import { Icon } from '@iconify/react';
import { connect } from "react-redux";
import { setViewType } from "../../../../store/actions/rules/rulesActions";



class ViewSideBar extends Component{

    constructor(props) {
        super(props);
        this.state = {			
            isActive:false,
            isExpanded:false
        };
    }

    slidebtn() {
        this.setState({isExpanded: !this.state.isExpanded})
    }

    setActiveTab = (tab) => {
        this.props.setViewType(tab)
    }

    render(){
            const {menu,propsRedux} = this.props;
            return (
                    <div id="navBar" className={this.state.isExpanded?classes.slide:''} onMouseOver = {()=>this.setState({isExpanded:true})} onMouseOut={()=>this.setState({isExpanded:false})}>
                        <div id="sideNav" className={[classes.leftNav,"pt-4"].join(' ')}>
                            <div className={classes.menu}>
                                {
                                    menu.map(({text,tab,icon})=>(
                                        <div key={text} className={`p-2 mb-2 d-flex sidebar-${tab} ${propsRedux.rules.activeTab == tab && classes.active}`} onClick={()=>this.setActiveTab(tab)}>
                                            <Icon icon={icon} style={{color:propsRedux.rules.activeTab !== tab && 'black'}}/>
                                            <span className="ml-2 text-dark ">{text}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
    }
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rules: state.rules,
        },
    }
}
export default connect(mapStateToProps, {setViewType})(ViewSideBar);