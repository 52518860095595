/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { useState } from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import PropTypes from 'prop-types';

/** Importing custom Components */
import PlayIcon from "../../../../UI/PlayIcon/PlayIcon";
import Tags from "../../../../UI/Tags/Tags";
import LoadingIcon from "../../../../UI/LoadingIcon/LoadingIcon";
import { setShowIntentModal } from '../../../../../store/actions/rules/rulesActions';

/** Importing custom Styles */
import classes from './SimpleViewRow.module.css';

/** Main Components */
const SimpleViewRow = ({ row, rowIndex, parentProps, rows, setShowIntentModal}) => {
    const [hoveredCell, setHoveredCell] = useState({'isNode': false, 'row': -1});
    let newResponseSelected = ((parentProps.switchState >= 2) && parentProps.switchObj && parentProps.switchObj[row.index] && parentProps.switchObj[row.index].isAlternateChosen) ? parentProps.switchObj[row.index].isAlternateChosen : false;

    const onCellHover = (rowId, isNode = false, isEnter = false) => {
        var newHoverVal = {'row' : -1, 'isNode': false};
        if (isEnter) {
            newHoverVal['row'] = rowId;
            newHoverVal['isNode'] = isNode;
        }
        if (newHoverVal['row'] != hoveredCell['row'] || newHoverVal['isNode'] != hoveredCell['isNode']) {
            setHoveredCell(newHoverVal);
        }
    }

    const onEditClick = (e, nodeName, index) => {
        setShowIntentModal(true, global.CUST_CONSTANTS.CRUD_MODE.EDIT);
        parentProps.onNodeClick(nodeName, false, global.CUST_CONSTANTS.CRUD_MODE.EDIT, index)
        e.stopPropagation();
    }

    return (
        <tr {...row.getRowProps()} id={row.original.responseId + "_" + rowIndex} className={[classes.TRow, (newResponseSelected || parentProps.switchState == 3) ? classes.TRowHighlighted : ""].join(' ')}>
            {
                row.cells.map(cell => {
                    var innerEl = "";
                    var cellProps = { ...cell.getCellProps() };
                    var hideCell = false;
                    cellProps['className'] = [classes.TData];

                    switch (cell.column.id) {
                        case "node":
                            var indexToCheck = rowIndex - 1;
                            hideCell = ((indexToCheck !== -1) && (rows[indexToCheck]) && (rows[indexToCheck].original.node === cell.value));

                            var nodeName = cell.render('Cell');

                            if (cell.row.original.isMetadata && cell.value == global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY) {
                                nodeName = "Opening Statement";
                            }

                            if (cell.row.original.isMetadata && cell.value == global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE) {
                                nodeName = "Default EvalFalse";
                            }

                            if (hideCell) {
                                cellProps['className'].push(classes.TdHide);
                            }

                            // if (
                            //     parentProps.loaders &&
                            //     parentProps.loaders.simpleView &&
                            //     parentProps.loaders.simpleView[('index_' + cell.value)]
                            // ) {
                            //     innerEl = (
                            //         <div>
                            //             <LoadingIcon />
                            //         </div>
                            //     );
                            // } else {
                                cellProps['className'].push(classes.ClickableCell);
                                cellProps['className'].push(classes.IntentCell);

                                cellProps['onMouseEnter'] = () => onCellHover(cell.row.id, true, true);
                                cellProps['onMouseLeave'] = () => onCellHover(cell.row.id, true, false);

                                cellProps['onClick'] = (() => { parentProps.onNodeClick(cell.value, cell.row.original.isMetadata, global.CUST_CONSTANTS.OTHER_ACTIONS.CLICK, cell.row.index) })
                                innerEl = (
                                    <div className={[ (cell.row.original.nodeInvalid) ? classes.InvalidNode : "" ].join(' ')}>
                                        { nodeName}
                                        {
                                            (
                                                (hoveredCell['row'] === cell.row.id) && 
                                                hoveredCell['isNode'] && 
                                                (cell.value != 'None') && 
                                                (cell.value != global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY) && 
                                                (cell.value != global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE) && 
                                                (cell.value != 'globalRule')
                                            ) &&
                                            <div className={["d-flex"].join(' ')}>
                                                <Icon
                                                    name="edit"
                                                    className={['cursor-pointer', 'action-btn', 'd-flex', 'align-items-center', 'justify-content-center'].join(' ')}
                                                    onClick={(e) => onEditClick(e, cell.row.original.node, cell.row.index)}
                                                />
                                                <Icon
                                                    name="trash"
                                                    className={[
                                                        'ml-1',
                                                        'cursor-pointer',
                                                        'action-btn',
                                                        'd-flex',
                                                        'align-items-center',
                                                        'justify-content-center',
                                                    ].join(' ')}
                                                    onClick={(e) => parentProps._onNodeDeleteClick(e, cell.row.original.node, cell.row.index)}
                                                />
                                            </div>
                                        }
                                    </div>
                                );
                            // }
                            break;

                        case "responseText":
                            var innerEl = ""
                            var divProps = {};
                            // if (
                            //     parentProps.loaders &&
                            //     parentProps.loaders.simpleView &&
                            //     (
                            //         parentProps.loaders.simpleView[('index_' + rowIndex)] ||
                            //         parentProps.loaders.simpleView[('index_' + cell.row.original.node)]
                            //     )
                            // ) {
                            //     innerEl = <LoadingIcon />
                            // } else {
                                if (cell.row.original.isCorrupted) {
                                    divProps = {
                                        className: ['alert', 'alert-danger'].join(' ')
                                    };
                                } else {
                                    divProps = {
                                        "data-tip": parentProps.getMetadata(cell),
                                        "className": [classes.tooltip].join(' '),
                                        "data-class": "tooltip_intent",
                                        "data-html": true,
                                        "data-place": "top",
                                    };
                                }
                                innerEl = (
                                    <div
                                        {...divProps}
                                    >
                                        { cell.render('Cell')}
                                        {
                                            (hoveredCell['row'] === cell.row.id &&  !hoveredCell['isNode']) &&
                                            <div className={[classes.ActionItems, "d-flex"].join(' ')}
                                            >
                                                <Icon
                                                    name="edit"
                                                    className={['cursor-pointer', 'action-btn', 'd-flex', 'align-items-center', 'justify-content-center'].join(' ')}
                                                    onClick={() => parentProps._onResponseActionClick(global.CUST_CONSTANTS.CRUD_MODE.EDIT, cell.row.original.pathsData)}
                                                />
                                                <Icon
                                                    name="trash"
                                                    className={[
                                                        'ml-1',
                                                        'cursor-pointer',
                                                        'action-btn',
                                                        'd-flex',
                                                        'align-items-center',
                                                        'justify-content-center',
                                                        (cell.row.original.isCorrupted) ? "disabled" : ""
                                                    ].join(' ')}
                                                    onClick={() => parentProps._onResponseActionClick(global.CUST_CONSTANTS.CRUD_MODE.DELETE, cell.row.original.pathsData, cell.row.original.isCorrupted)}
                                                />
                                            </div>
                                        }
                                    </div>
                                );
                                cellProps['onMouseEnter'] = () => onCellHover(cell.row.id, false, true);
                                cellProps['onMouseLeave'] = () => onCellHover(cell.row.id, false , false);
                            // }
                            cellProps['className'].push(classes.ResponseTextCell);
                            break;

                        case "responseClipTags":
                            // if (
                            //     parentProps.loaders &&
                            //     parentProps.loaders.simpleView &&
                            //     (
                            //         parentProps.loaders.simpleView[('index_' + rowIndex)] ||
                            //         parentProps.loaders.simpleView[('index_' + cell.row.original.node)]
                            //     )
                            // ) {
                            //     innerEl = <LoadingIcon />
                            // } else 
                            if (cell.row.original.isCorrupted) {
                                innerEl = (
                                    <div className={['alert', 'alert-danger'].join(' ')}>
                                        { cell.row.original.responseClip}
                                    </div>
                                );
                            }else if(cell.row.original.audioClipNotPresent){
                                innerEl = (
                                    <div>
                                            <div
                                                className={["d-flex align-items-center text-danger"].join(' ')}
                                            >
                                                {global.CUST_CONSTANTS.NO_AUDIO_CLIPS}
                                            </div>
                                            
                                    </div>)
                            } else {
                                innerEl = (
                                    <div>
                                        <div
                                            className={["d-flex align-items-center"].join(' ')}
                                        >
                                            {
                                                (parentProps.switchState == 2) &&
                                                <input type="radio"
                                                    id="clipSelect"
                                                    name={"clipSelect_" + cell.row.original.node + "_" + cell.row.original.responseId}
                                                    value="exisitingClip"
                                                    defaultChecked={(parentProps.switchObj && parentProps.switchObj[cell.row.index]) ? false : true}
                                                    onClick={() => parentProps._onChangeAlternateClipSelection(cell.row.index, false)}
                                                />
                                            }
                                            <span className="ml-2">
                                                <PlayIcon
                                                    src={cell.row.original.responseClip}
                                                    uid={"simple_view_opening_statement_" + cell.row.id}
                                                />
                                            </span>
                                            <span className="ml-2">
                                                {cell.row.original.responseClip}
                                            </span>
                                        </div>
                                        <div>
                                            {
                                                cell.row.original.responseTagsObj &&
                                                <Tags
                                                    keyBase={"response_tag_" + cell.row.id}
                                                    tags={cell.row.original.responseTagsObj}
                                                    className={["pull-right"].join(' ')}
                                                />
                                            }
                                        </div>
                                    </div>
                                );
                            }
                            cellProps['className'].push(classes.ResponseClipCell);
                            break;

                        case "alternateResponseClipTags":
                            if (cell.row.original.isCorrupted) {
                                innerEl = "";
                            }else if(cell.row.original.audioClipNotPresent){
                                innerEl = (
                                    <div>
                                            <div
                                                className={["d-flex align-items-center"].join(' ')}
                                            >
                                                {global.CUST_CONSTANTS.NO_AUDIO_CLIPS}
                                            </div>
                                            
                                    </div>)
                            } else if (cell.row.original.alternateResponseId && (cell.row.original.alternateResponseId > 0)) {
                                innerEl = (
                                    <div>
                                        <div
                                            className={["d-flex align-items-center"].join(' ')}
                                        >
                                            {
                                                (parentProps.switchState == 2) &&
                                                <input type="radio"
                                                    id="clipSelect"
                                                    name={"clipSelect_" + cell.row.original.node + "_" + cell.row.original.responseId}
                                                    value="alternateClip"
                                                    defaultChecked={true}
                                                    onClick={() => parentProps._onChangeAlternateClipSelection(cell.row.index, true)}
                                                />
                                            }
                                            <span className="ml-2">
                                                <PlayIcon
                                                    src={cell.row.original.responseClip}
                                                    uid={"simple_view_opening_statement_" + cell.row.id}
                                                />
                                            </span>
                                            <span className="ml-2">
                                                {cell.row.original.alternateResponseClip}
                                            </span>
                                        </div>
                                        <div>
                                            <Tags
                                                keyBase={"response_tag_" + cell.row.id}
                                                tags={cell.row.original.alternateResponseTagsObj}
                                                className={["pull-right"].join(' ')}
                                            />
                                        </div>
                                    </div>
                                );
                            } else {
                                innerEl = "";
                            }
                            cellProps['className'].push(classes.ResponseClipCell);
                            break;

                        default:
                            innerEl = cell.render('Cell');
                            break;
                    }
                    cellProps['className'] = cellProps['className'].join(' ');
                    return (
                        <td
                            {...cellProps}
                        >
                            { innerEl}
                        </td>
                    );
                })
            }
        </tr>
    );
}
/** Defining prop types requirements */
SimpleViewRow.propTypes = {
    propsRedux: PropTypes.shape({

    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setShowIntentModal,  })(SimpleViewRow);
