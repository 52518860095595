/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import { useAsyncDebounce } from 'react-table';

import PropTypes from 'prop-types';

/** Importing custom Components */
import { setSimpleRows } from "../../../../store/actions/rules/rulesActions";
import { useEffect } from 'react';

/** Importing custom Styles */

/** Main Components */
const GlobalFilter = ({ propsRedux, propsOwned, setSimpleRows }) => {
    const [value, setValue] = React.useState(propsOwned.globalFilter);

    useEffect(() => {
        if (propsRedux.freezeColumnFilter) {
            setValue("");
        }
    });

    const onChange = useAsyncDebounce(value => {
        propsOwned.setGlobalFilter(value || undefined);
        // setSimpleRows(propsOwned.rows);
    }, 200);

    return (
        <span className={ [  ].join(' ') }>
            <input
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                readOnly={ propsRedux.freezeColumnFilter }
                placeholder={ "Search Full Table..." }
                style={{
                    maxWidth: "200px"
                }}
            />
        </span>
    );
}

/** Defining prop types requirements */
GlobalFilter.propTypes = {
    propsRedux: PropTypes.shape({
        freezeColumnFilter: PropTypes.bool.isRequired,
        isChanged: PropTypes.number.isRequired,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            freezeColumnFilter: state.rules.freezeColumnFilter,
            isChanged: state.rules.isChanged,
            simpleViewRows: state.rules.simpleViewRows,
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setSimpleRows })(GlobalFilter);