/*
 * Component Description :
 * Author: Ankit Aditya
 */
/** Importing pre defined Libraries/Components */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./TestReport.module.css";
import { setAlert } from "../../../store/actions/common/commonActions";
import { setViewType } from "../../../store/actions/rules/rulesActions";
import { setSelectedTestPlan} from '../../../store/actions/rules/rulesActions';
import { Icon } from "@iconify/react";
import { DropdownButton, Dropdown, Table, Form } from "react-bootstrap";
import { messages } from '../../../data/strings';
import ControlBar from "../NodeRules/ControlBar/ControlBar";

/** Main Components */
class TestReport extends React.Component {
  /** Lifecycle methods start */

  constructor() {
    super();
    this.state = {
      openFilter: true,
      openMoreItem: true,
      activeIndex: 0,
      openSettings: true,
      ListView: false,
      DetailedView: true,
      testPlanList: {},
      testPlanFetch: [],
      testReportFetch: [],
      reportStatus: {
        running: 0,
        failed: 0,
        passed: 0,
      },
      testPlanSelectKey: null,
      openAccordion: {},
    };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleMore = this.toggleMore.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.toggleListView = this.toggleListView.bind(this);
    this.toggleDetailedView = this.toggleDetailedView.bind(this);
  }

  componentDidMount() {
    if(this.props.propsRedux.error && this.props.propsRedux.error.old_event){
      // old events
      let countRunning = this.state.reportStatus.running;
      let countPassed = this.state.reportStatus.passed;
      let countFailed = this.state.reportStatus.failed;

      // global.recordAudit("Successfully data retrieve: ",data);
      var testPlanListCurrentState = this.state.testPlanList;
      Object.keys(this.props.propsRedux.error.old_event).map((key)=>{

          testPlanListCurrentState[key] = this.props.propsRedux.error.old_event[key];
          countRunning+=1;
          if(testPlanListCurrentState[key].action === 'testcaseGeneration'){
              if (!!testPlanListCurrentState[key].status) {
                this.setState({
                  testPlanFetch: [...this.state.testPlanFetch, key],
                });
              }
              if (!testPlanListCurrentState[key].status) {
                this.setState({
                  testPlanFetch: [...this.state.testPlanFetch, key],
                });
              }
          }
          this.setState({testPlanFetch:[...this.state.testPlanFetch,key]});
          if(testPlanListCurrentState[key].action === 'testcaseReport'){
              countRunning-=1;
              if (!!testPlanListCurrentState[key].status) {
                countPassed += 1;
    
                this.setState({
                  testReportFetch: [...this.state.testReportFetch, key],
                });
              }
              if (!testPlanListCurrentState[key].status) {
                countFailed += 1;
    
                this.setState({
                  testReportFetch: [...this.state.testReportFetch, key],
                });
              }
          }
          
      });
      this.setState({reportStatus:{...this.state.reportStatus,running:countRunning,passed: countPassed, failed: countFailed,},testPlanList:testPlanListCurrentState});
  }  
    var listOfPlans = {};
    Object.keys(this.state.testPlanList).map((key) => {
      listOfPlans[key] = false;
    });
    this.setState({ openAccordion: listOfPlans });
    if (this.props.content) {
      var firstKey = true;
      this.props.content.bind(this.props.event_name, (data) => {
        // global.recordAudit("Successfully data retrieve: ",data);
        var countRunning = this.state.reportStatus.running;
        var testPlanListCurrentState = this.state.testPlanList;
        Object.keys(data).map((key) => {
          if (firstKey) {
            this.setState({ testPlanSelectKey: key });
            firstKey = false;
          }
          testPlanListCurrentState[key] = data[key];
          countRunning += 1;

          if (!!data[key].status) {
            this.setState({
              testPlanFetch: [...this.state.testPlanFetch, key],
            });
          }
          if (!data[key].status) {
            this.setState({
              testPlanFetch: [...this.state.testPlanFetch, key],
            });
          }
        });

        this.setState({
          reportStatus: { ...this.state.reportStatus, running: countRunning },
          testPlanList: testPlanListCurrentState,
        });
      });

      this.props.content.bind("testcaseReport", (data) => {
        var countPassed = this.state.reportStatus.passed;
        var countRunning = this.state.reportStatus.running;
        var countFailed = this.state.reportStatus.failed;
        var testPlanListCurrentState = this.state.testPlanList;
        Object.keys(data).map((key) => {
          testPlanListCurrentState[key] = data[key];
          countRunning -= 1;
          if (!!data[key].status) {
            countPassed += 1;

            this.setState({
              testReportFetch: [...this.state.testReportFetch, key],
            });
          }
          if (!data[key].status) {
            countFailed += 1;

            this.setState({
              testReportFetch: [...this.state.testReportFetch, key],
            });
          }
        });

        this.setState({
          reportStatus: {
            passed: countPassed,
            failed: countFailed,
            running: countRunning,
          },
          testPlanList: testPlanListCurrentState,
        });
      });
    }

    if(Object.keys(this.props.propsRedux.testReports).length > 0){
      let oldReportsCountPassed = this.state.reportStatus.passed;
      let oldReportsCountFailed = this.state.reportStatus.failed;
      var testPlanListCurrentState = this.state.testPlanList
      Object.keys(this.props.propsRedux.testReports).forEach(key=>{
          testPlanListCurrentState[key] = {id: Number(key), action: "testcaseReport",done: true,"status": false }
          if(this.props.propsRedux.testReports[key].detail_json.status === 2){
              oldReportsCountFailed+=1;
              testPlanListCurrentState[key].status = false
          }
          else{
              oldReportsCountPassed+=1
              testPlanListCurrentState[key].status = true
          }
          this.setState({testReportFetch:[...this.state.testReportFetch,key]});
      })
      this.setState({reportStatus:{...this.state.reportStatus,running:0,passed:oldReportsCountPassed,failed:oldReportsCountFailed},testPlanList:testPlanListCurrentState});
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.propsRedux.allTestPlans !==this.props.propsRedux.allTestPlans){
        if(this.props.propsRedux.savedTestPlanData.all && Object.keys(this.props.propsRedux.savedTestPlanData.all).length && this.props.propsRedux.allTestPlans){
            this.testPlanListFromReport(this.props.propsRedux.savedTestPlanData.all.testReports);
        }

        if(this.props.propsRedux.savedTestPlanData.basic && Object.keys(this.props.propsRedux.savedTestPlanData.basic).length && !this.props.propsRedux.allTestPlans){
            this.testPlanListFromReport(this.props.propsRedux.savedTestPlanData.basic.testReports);
        }
        
    }
  }

  componentWillUnmount(){
    if(this.props.content){
      this.props.content.unbind();
      
    }
  }

  testPlanListFromReport = (reports) =>{
    let oldReportsCountPassed = 0;
    let oldReportsCountFailed = 0;
    var testPlanListCurrentState = {}
    Object.keys(reports).forEach(key=>{
        testPlanListCurrentState[key] = {id: Number(key), action: "testcaseReport",done: true,"status": false }
        if(reports[key].detail_json.status === 2){
            oldReportsCountFailed+=1;
            testPlanListCurrentState[key].status = false
        }
        else{
            oldReportsCountPassed+=1
            testPlanListCurrentState[key].status = true
        }
        this.setState({testReportFetch:[...this.state.testReportFetch,key]});
    })
    this.setState({reportStatus:{...this.state.reportStatus,running:0,passed:oldReportsCountPassed,failed:oldReportsCountFailed},testPlanList:testPlanListCurrentState});
      
  }

  toggleListView() {
    this.setState({ ListView: true, DetailedView: false });
  }
  toggleDetailedView() {
    this.setState({ ListView: false, DetailedView: true });
  }
  toggleInfo() {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  }
  toggleMore(index) {
    this.setState({
      activeIndex: index,
    });
  }
  toggleSettings() {
    this.setState({
      openSettings: !this.state.openSettings,
    });
  }

  selectTestPlanAndReport(key,testPlanSelected) {
    if(testPlanSelected){
      var selectTestPlan = {[key]:{}}
      testPlanSelected.detail_json.test_case_flow.map((value, index)=>{
          selectTestPlan[key][value.index.step_index] = value;
          });
      this.props.setSelectedTestPlan(selectTestPlan);
    }else{
      this.props.setSelectedTestPlan(null);
    }
  }

  render() {
    console.log(
      "DEBUGGER:: Check new call flow this.props.propsRedux.callFlowJSON",
      this.props.propsRedux.callFlowJSON
    );
    /** Call Flow version 1.1 and old support */
    let callFlowJSONPicked = this.props.propsRedux.callFlowJSON[
      global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW
    ]
      ? this.props.propsRedux.callFlowJSON[
          global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW
        ]
      : this.props.propsRedux.callFlowJSON;
    console.log(
      "DEBUGGER:: Check new call flow callFlowJSONPicked",
      callFlowJSONPicked
    );
    const { DetailedView, ListView } = this.state;
    const navDropdownTitle = <Icon icon="carbon:overflow-menu-vertical" />;

    return (
      <>
        <ControlBar
          title="Test Report"
          leftControls = {
            <>
            <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-5 font-14 font-normal">
              <span data-tip = {messages.successTestCases} data-place = "top" data-class= "tooltip_cust">
                <Icon
                  icon="carbon:checkmark-outline"
                  className="mr-2 text-success font-16"
                ></Icon>
              </span>
              <span className="blue"> : {this.state.reportStatus.passed}</span>
            </div>
            <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-2 font-14 font-normal">
              <span data-tip = {messages.failedTestCases} data-place = "top" data-class= "tooltip_cust">
                <Icon
                  icon="carbon:warning-alt"
                  className="text-danger font-16"
                ></Icon>
              </span>
              <span className="blue"> : {this.state.reportStatus.failed}</span>
            </div>
            <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-2 font-14 font-normal">
              <span data-tip = {messages.underProcessTestCases} data-place = "top" data-class= "tooltip_cust">  
                <Icon icon="carbon:renew" className="mr-2 font-16 text-danger"></Icon>
              </span>
              <span className="blue"> : {this.state.reportStatus.running}</span>
            </div>
            </>
          }
        />
        <div
          className="position-relative"
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          {/* <div className="bg-light p-2">
            <h5 className="blue float-left pt-2">Test Report</h5>
            <div className="float-left">
              <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-5 font-14 font-normal">
                {" "}
                <Icon
                  icon="carbon:checkmark-outline"
                  className="mr-2 text-success font-16"
                  data-tip = {messages.successTestCases} data-place = "top" data-class= "tooltip_cust"
                ></Icon>{" "}
                : {this.state.reportStatus.passed}
              </div>
              <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-2 font-14 font-normal">
                {" "}
                <Icon
                  icon="carbon:warning-alt"
                  className="text-danger font-16"
                  data-tip = {messages.failedTestCases} data-place = "top" data-class= "tooltip_cust"
                ></Icon>{" "}
                : {this.state.reportStatus.failed}
              </div>
              <div className="badge border rounded bg-white pt-1 pb-1 pl-2 pr-2 ml-2 font-14 font-normal">
                {" "}
                <Icon icon="carbon:renew" className="mr-2 font-16" data-tip = {messages.underProcessTestCases} data-place = "top" data-class= "tooltip_cust"></Icon> :{" "}
                {this.state.reportStatus.running}
              </div>
            </div> */}
            {/* <div className="float-right col-md-6 text-right">
                              {DetailedView && <Link className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue" title="List View" onClick={this.toggleListView}> <Icon icon="carbon:list" /></Link>}
                            {ListView &&  <Link className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue" title="Detailed View" onClick={this.toggleDetailedView}><Icon icon="carbon:document" /></Link>}
                            <select className="form-control ml-2 d-inline-block col-md-2 pt-0 pb-0">
                                <option selected>Tags</option>
                            </select>
                            <select className="form-control ml-2 d-inline-block col-md-2 pt-0 pb-0">
                                <option selected>Status</option>
                            </select>
                            <input type="date" className="form-control ml-2 d-inline-block col-md-2 pt-0 pb-0" placeholder="Date"/>
                          </div> */}
            {/* <span className="clearfix"></span> */}
          {/* </div> */}

          {/* List View Toggle*/}
          {ListView && (
            <div className="mt-3">
              <Table
                striped
                borderless
                hover
                size="sm"
                className="testPlan-table"
              >
                <thead>
                  <tr>
                    <th className="text-center">
                      {" "}
                      <Form.Check type="checkbox" />
                    </th>
                    <th>Report Name</th>
                    <th>Description</th>
                    <th>Generated On</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      {" "}
                      <Form.Check type="checkbox" />
                    </td>
                    <td className="blue">Report 01</td>
                    <td>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry.
                    </td>
                    <td>Sep 18, 2021</td>
                    <td className="text-danger">5/10 Failed</td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {" "}
                      <Form.Check type="checkbox" />
                    </td>
                    <td className="blue">Report 02</td>
                    <td>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry.
                    </td>
                    <td>Sep 18, 2021</td>
                    <td className="text-success">10/10 Passed</td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {" "}
                      <Form.Check type="checkbox" />
                    </td>
                    <td className="blue">Report 03</td>
                    <td>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry.
                    </td>
                    <td>Sep 18, 2021</td>
                    <td className="blue">2/10 Processed</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/* Detailed View Toggle */}
          {DetailedView && (
            <div id="testReportScreen" className="mt-4">
              {/* Date Separator*/}
              {/* <div className="pos-rel mb-3"><hr></hr><badge className="bg-white date-badge pl-2 pr-2">15th Sept, 2021</badge></div> */}
              <div>
                <div className="row mt-3 ml-2">
                  {/* First Column */}
                  <div
                    className="col-md-3"
                    style={{
                      width: "500px",
                      height: "70vh",
                      borderLeft: "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{ height: "100%", width: "100%", overflow: "auto" }}
                    >
                      <div className="text-center mb-2 text-white">Test Case</div>
                      {this.state.testPlanList &&
                        Object.keys(this.state.testPlanList).map((key) => {
                          let testCaseFail = this.props.propsRedux.testReports[key] && this.props.propsRedux.testReports[key].success !==undefined && this.props.propsRedux.testReports[key].success === false ? true : false;
                          let passFailToolTipProps = {"data-tip": "","data-place": "top","data-class": "tooltip_cust"} 
                          let happySadToolTipProps = {"data-tip": "","data-place": "top","data-class": "tooltip_cust"}
                          var design = {};

                          if(testCaseFail){
                            passFailToolTipProps["data-tip"] = messages.testCaseFailedLuis
                          }
                          if (
                            this.props.propsRedux.testReports[key] &&
                            this.props.propsRedux.testReports[key].detail_json &&
                            this.props.propsRedux.testReports[key].detail_json
                              .is_happy
                          ) {
                            design["icon"] = "carbon:face-satisfied";
                            design["className"] = "text-success";
                            happySadToolTipProps["data-tip"] = messages.happyPath
                          }

                          if (
                            this.props.propsRedux.testReports[key] &&
                            this.props.propsRedux.testReports[key].detail_json &&
                            !this.props.propsRedux.testReports[key].detail_json
                              .is_happy
                          ) {
                            design["icon"] = "carbon:face-dissatisfied";
                            design["className"] = "text-danger";
                            happySadToolTipProps["data-tip"] = messages.nonHappytPath
                          }

                          if (!this.state.testPlanList[key].done) {
                            design["overlay"] = (
                              <div className="TP-overlay text-center">
                                <i className="fa fa-refresh font-18 mt-3 blue"></i>
                              </div>
                            );
                          } else {
                            design["overlay"] = (
                              <div className="TP-overlay text-center d-none">
                                <i className="fa fa-refresh font-18 mt-3 blue"></i>
                              </div>
                            );
                          }
                          if (
                            this.props.propsRedux.testReports[key]
                              ? this.props.propsRedux.testReports[key].detail_json
                                  .status == 0
                              : false 
                          ) {
                            design["passFail"] = {
                              icon: "carbon:checkmark-outline",
                              className: "ml-2 text-success",
                            };
                            passFailToolTipProps["data-tip"] = messages.testCaseSuccess;
                          } else if (
                            this.props.propsRedux.testReports[key]
                              ? this.props.propsRedux.testReports[key].detail_json
                                  .status == 2
                              : false
                          ) {
                            // design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                            // design["icon"] = "carbon:face-dissatisfied";
                            // design["className"] = "text-danger";
                            design["passFail"] = {
                              icon: "carbon:warning-alt",
                              className: "ml-2 text-danger",
                            };
                            // happySadToolTipProps["data-tip"] = messages.nonHappytPath;
                            passFailToolTipProps["data-tip"] = messages.testCaseFailed
                          } else if (
                            this.props.propsRedux.testReports[key]
                              ? this.props.propsRedux.testReports[key].detail_json
                                  .status == 1
                              : false
                          ) {
                            // design["overlay"] = <div className="TP-overlay text-center d-none"><i className="fa fa-refresh font-18 mt-3 blue"></i></div>;
                            design["passFail"] = {
                              icon: "carbon:checkmark-outline",
                              className: "ml-2 text-success",
                            };
                            passFailToolTipProps["data-tip"] = messages.testCaseSuccess
                          }

                          return (
                            <div
                              key={key}
                              className={[
                                `row align-items-center mb-2 border p-2 rounded m-0 pos-rel ${testCaseFail && 'border-danger'}`,
                                this.props.propsRedux.selectedTestPlanKey
                                  ? this.props.propsRedux.selectedTestPlanKey == key
                                    ? "TP-selected"
                                    : ""
                                  : "",
                              ].join(" ")}
                            >
                              <div className="col-md-2 pl-0">
                                <div className="bg-light text-center rounded p-1">
                                  <Icon
                                    icon={design["icon"]}
                                    className={design["className"]}
                                    {...happySadToolTipProps}
                                  ></Icon>
                                </div>
                              </div>
                              <div className="col-md-10 pl-0">
                                <span>
                                    <Link
                                      className="blue"
                                      onClick={() =>
                                        this.selectTestPlanAndReport(key,this.props.propsRedux.testReports[key])
                                      }
                                    >
                                      {this.props.propsRedux.testPlanJsons[key]
                                        ? this.props.propsRedux.testPlanJsons[key]
                                            .title
                                        : "Test Plan"}{" "}
                                      <Icon
                                        icon={
                                          design["passFail"]
                                            ? design["passFail"]["icon"]
                                            : ""
                                        }
                                        className={
                                          design["passFail"]
                                            ? design["passFail"]["className"]
                                            : "ml-2 text-warning"
                                        }
                                        {...passFailToolTipProps}
                                      ></Icon>
                                    </Link>

                                </span>
                              </div>
                              {/* <div className="TestPlanMore">
                                <Link onClick={this.toggleInfo}>
                                  <Icon icon="carbon:information"></Icon>
                                </Link>
                                <DropdownButton
                                  align="start"
                                  title={navDropdownTitle}
                                  aria-label="More Menu"
                                  id="dropdown-menu-align-left"
                                  size="sm"
                                  variant="lights"
                                  className="p-0 d-inline-block cardMenu font-12"
                                >
                                  <Dropdown.Item eventKey="1" disabled={true}>
                                    <Icon
                                      icon="carbon:play"
                                      className="mr-2"
                                    ></Icon>
                                    Run
                                  </Dropdown.Item>
                                  <Dropdown.Item eventKey="2" disabled={true}>
                                    <Icon
                                      icon="carbon:add"
                                      className="mr-2"
                                    ></Icon>
                                    Add to Test Plan
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div> */}
                              {design["overlay"]}
                            </div>
                          );
                        })}
                      {Object.keys(this.state.testPlanList).length == 0 && (
                        <div className="text-danger">No Test Plan Found!</div>
                      )}
                      <div
                        className="badge badge-light rounded-circle shadow-new"
                        autoFocus={true}
                        onClick={() => this.props.setViewType(2)}
                        style={{
                          position: "absolute",
                          top: "8%",
                          left: "-15px",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      >
                        <Icon
                          id="slideIcon"
                          className="transition"
                          icon="eva:arrow-ios-forward-fill"
                        ></Icon>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-9"
                    style={{ overflow: "auto", width: "1000px" }}
                  >
                    {/* Add class PS-added when toggle of PS is on  like below */}
                    {/* <div className="row PS-added"> */}
                    <div className="row">
                      {/* Second Column */}
                      <div
                        className="col-md-6 pl-0 pr-0"
                        style={{  height: "70vh" }}
                      >
                        <div className="text-center blue mb-2">Test Case</div>
                        <div style={{height:'100%'}}>
                          <div 
                            className="align-items-center v-middle"
                            style={{
                              overflow:'auto',
                              height:'90%'
                            }}
                          >
                            {this.props.propsRedux.testPlanJsons &&
                              this.props.propsRedux.testPlanJsons[
                                this.props.propsRedux.selectedTestPlanKey
                              ] &&
                              this.props.propsRedux.selectedTestPlanKey &&
                              this.props.propsRedux.testPlanJsons[
                                this.props.propsRedux.selectedTestPlanKey
                              ].test_case_json.test_case_flow.map(
                                (value, index) => {
                                  return (
                                    <div className="col-md-12 pos-rel mb-2" key={index}>
                                      <div className="border p-2 rounded">
                                        <div className="pos-rel ml-70 mr-100">
                                          <div
                                            className={"sampleUtterance font-14 p-2 w-100 ml-0"}
                                          >
                                            {
                                              value.response.sample_utterances[0]
                                                .utteranceText
                                            }
                                          </div>
                                          <div
                                            className={[
                                              "intent font-14 p-2 w-100 ml-0",
                                              "text-truncate",
                                            ].join(" ")}
                                          >
                                            {value.node}
                                          </div>
                                          <div className="user-icon rounded-circle text-center p-2">
                                            <Icon icon="carbon:user-avatar"></Icon>
                                          </div>
                                        </div>
                                        <div className="pos-rel mr-70 ml-100">
                                          <div className="BotChat font-14 p-2 w-100">
                                            {value.response.BRText}
                                          </div>
                                          <div className="bot-icon rounded-circle text-center p-2">
                                            <Icon icon="carbon:bot"></Icon>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="CallFlowStepicon position-absolute"
                                        style={{ right: "30px", top: "5px" }}
                                      >
                                        {/* <Link onClick={this.toggleInfo}>
                                          <Icon icon="carbon:information"></Icon>
                                        </Link> */}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            {Object.keys(this.props.propsRedux.testPlanJsons)
                              .length == 0 && (
                              <div className="text-danger">
                                No Test Plan Found!
                              </div>
                            )}
                            {this.props.propsRedux.selectedTestPlanKey &&
                              this.props.propsRedux.testPlanJsons[
                                this.props.propsRedux.selectedTestPlanKey
                              ] == undefined && (
                                <div className="text-danger">
                                  {"Test Plan for Key=>" +
                                    this.props.propsRedux.selectedTestPlanKey +
                                    " Not found"}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {/* Third Column */}
                      <div
                        className="col-md-6 pl-0 pr-0"
                        style={{ height: "70vh" }}
                      >
                        <div style={{height:'100%'}}>
                          <div className="text-center blue mb-2">Test Run</div>
                          <div 
                            className="align-items-center v-middle"
                            style={{
                              overflow:'auto',
                              height:'90%'
                            }}
                          >
                            {this.props.propsRedux.testReports &&
                              this.props.propsRedux.testReports[
                                this.props.propsRedux.selectedTestPlanKey
                              ] &&
                              this.props.propsRedux.selectedTestPlanKey &&
                              this.props.propsRedux.testReports[this.props.propsRedux.selectedTestPlanKey].success ===undefined &&

                              this.props.propsRedux.testReports[
                                this.props.propsRedux.selectedTestPlanKey
                              ].detail_json.test_case_flow.map((value, index) => {
                                return (
                                  <div className="col-md-12 pos-rel mb-2" key={index}>
                                    <div className="border p-2 rounded">
                                      <div className="pos-rel ml-70 mr-100">
                                        <div
                                          className={[
                                            "sampleUtterance font-14 p-2 w-100 ml-0",
                                            value.code ===200 ? "" : "failure",
                                          ].join(" ")}
                                        >
                                          {
                                            value.response.sample_utterances[0]
                                              .utteranceText
                                          }
                                        </div>
                                        <div
                                          className={[
                                            "intent font-14 p-2 w-100 ml-0",
                                            value.code ===401?"failure":"",
                                            "text-truncate",
                                          ].join(" ")}
                                        >
                                          {value.node}
                                        </div>
                                        <div className="user-icon rounded-circle text-center p-2">
                                          <Icon icon="carbon:user-avatar"></Icon>
                                        </div>
                                      </div>
                                      <div className="pos-rel mr-70 ml-100">
                                        <div className={["BotChat font-14 p-2 w-100",value.code ===423?"failure":""].join(' ')}>
                                          {value.response.BRText}
                                        </div>
                                        <div className="bot-icon rounded-circle text-center p-2">
                                          <Icon icon="carbon:bot"></Icon>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="CallFlowStepicon position-absolute"
                                      style={{ right: "30px", top: "5px" }}
                                    >
                                      {/* <Link onClick={this.toggleInfo}>
                                        <Icon icon="carbon:information"></Icon>
                                      </Link> */}
                                      {value.code !==200 &&
                                        <Icon 
                                        icon="carbon:warning-alt"
                                        className="text-danger ml-2"
                                        data-tip={value.error_message}
                                        data-class="tooltip_cust"
                                        data-place="top">
                                        </Icon>
                                      }
                                    </div>
                                  </div>
                                );
                              }) 
                            }

                            {/* Test report error for code 500 case */}
                            {
                              this.props.propsRedux.testReports && this.props.propsRedux.testReports[this.props.propsRedux.selectedTestPlanKey] && this.props.propsRedux.testReports[this.props.propsRedux.selectedTestPlanKey].success !==undefined && this.props.propsRedux.testReports[this.props.propsRedux.selectedTestPlanKey].success ===false && 
                              <div className="text-danger text-center w-100">
                                {messages.testCaseFailed}
                              </div>  
                            }

                            {Object.keys(this.props.propsRedux.testReports)
                              .length == 0 && (
                              <div className="text-danger">
                                No Test Report Found!
                              </div>
                            )}
                            {/* {this.props.propsRedux.selectedTestPlanKey &&
                              this.props.propsRedux.testReports[
                                this.props.propsRedux.selectedTestPlanKey
                              ] == undefined && (
                                <div className="text-danger">
                                  {"Test Report for Key=>" +
                                    this.props.propsRedux.selectedTestPlanKey +
                                    " Not found"}
                                </div>
                              )} */}
                          </div>
                        </div>
                      </div>

                      {/* Fourth Column of Practice Session  will be visible only when PS toggle is on*/}
                      {/* <div
                        className="col-md-6 pl-0 pr-0"
                        style={{ overflow: "auto", height: "70vh" }}
                      >
                        <div className="text-center blue mb-2">
                          Practice Session
                        </div>
                        <div>
                          <div className="align-items-center v-middle">
                            <div className="col-md-12 pos-rel mb-2">
                              <div className="border p-2 rounded">
                                <div className="pos-rel ml-70 mr-100">
                                  <div className="sampleUtterance font-14 p-2 w-100 ml-0">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="intent font-14 p-2 w-100 ml-0">
                                    agent_intro_how_can_help
                                  </div>
                                  <div className="user-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:user-avatar"></Icon>
                                  </div>
                                </div>
                                <div className="pos-rel mr-70 ml-100">
                                  <div className="BotChat font-14 p-2 w-100">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="bot-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:bot"></Icon>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="CallFlowStepicon"
                                style={{ right: "30px" }}
                              >
                                <Link onClick={this.toggleInfo}>
                                  <Icon icon="carbon:information"></Icon>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-12 pos-rel mb-2">
                              <div className="border p-2 rounded">
                                <div className="pos-rel ml-70 mr-100">
                                  <div className="sampleUtterance font-14 p-2 w-100 ml-0">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="intent font-14 p-2 w-100 ml-0">
                                    agent_intro_how_can_help
                                  </div>
                                  <div className="user-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:user-avatar"></Icon>
                                  </div>
                                </div>
                                <div className="pos-rel mr-70 ml-100">
                                  <div className="BotChat font-14 p-2 w-100">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="bot-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:bot"></Icon>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="CallFlowStepicon"
                                style={{ right: "30px" }}
                              >
                                <Link onClick={this.toggleInfo}>
                                  <Icon icon="carbon:information"></Icon>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-12 pos-rel mb-2">
                              <div className="border p-2 rounded">
                                <div className="pos-rel ml-70 mr-100">
                                  <div className="sampleUtterance font-14 p-2 w-100 ml-0">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="intent font-14 p-2 w-100 ml-0">
                                    agent_intro_how_can_help
                                  </div>
                                  <div className="user-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:user-avatar"></Icon>
                                  </div>
                                </div>
                                <div className="pos-rel mr-70 ml-100">
                                  <div className="BotChat font-14 p-2 w-100">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="bot-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:bot"></Icon>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="CallFlowStepicon"
                                style={{ right: "30px" }}
                              >
                                <Link onClick={this.toggleInfo}>
                                  <Icon icon="carbon:information"></Icon>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-12 pos-rel mb-2">
                              <div className="border p-2 rounded">
                                <div className="pos-rel ml-70 mr-100">
                                  <div className="sampleUtterance font-14 p-2 w-100 ml-0">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="intent font-14 p-2 w-100 ml-0">
                                    agent_intro_how_can_help
                                  </div>
                                  <div className="user-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:user-avatar"></Icon>
                                  </div>
                                </div>
                                <div className="pos-rel mr-70 ml-100">
                                  <div className="BotChat font-14 p-2 w-100">
                                    HI,Need your help please. Lorem Ipsum is
                                    simply dummy text of the printing and
                                    typesetting industry.
                                  </div>
                                  <div className="bot-icon rounded-circle text-center p-2">
                                    <Icon icon="carbon:bot"></Icon>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="CallFlowStepicon"
                                style={{ right: "30px" }}
                              >
                                <Link onClick={this.toggleInfo}>
                                  <Icon icon="carbon:information"></Icon>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

/** Defining prop types requirements */
TestReport.propTypes = {
  // propsRedux: PropTypes.shape({
  //     : PropTypes.object.isRequired
  // }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
  return {
    propsRedux: {
      isChanged: state.rules.isChanged,
      rulesJson: state.rules.rulesJson,
      callFlowJSON: state.rules.callFlow.callFlowJSON,
      callFlowResponseMappings: state.rules.callFlowResponseMappings,
      storyId: state.story.storyId,
      storyRuleId: state.rules.storyRuleId,
      botResponses: state.rules.botResponses,
      showUtteranceSideBar: state.rules.showUtteranceSideBar,
      statements: state.rules.statements,
      showTestPlans: state.rules.showTestPlans,
      selectedTestPlanKey : state && state.rules && state.rules.selectedTestPlan && Object.keys(state.rules.selectedTestPlan).length ? Object.keys(state.rules.selectedTestPlan)[0]: null,
      testPlanChannel: state.rules.testPlanChannel,
      testPlans: state.rules.testPlans,
      utterances: state.rules.sampleUtterances,
      selectedTestPlan: state.rules.selectedTestPlan,
      testReports: state.rules.testReports,
      testPlanJsons: state.rules.testPlanJsons,
      savedTestPlanData: state.rules.savedTestPlanData,
      allTestPlans: state.rules.allTestPlans,
      error: state.rules.error,
    },
    propsOwned: { ...props },
  };
};

/** Exporting main component */
export default connect(mapStateToProps, { setViewType,setSelectedTestPlan })(TestReport);
