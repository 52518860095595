import React from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import {setSelectedRule, setResponseAction, showResponseModal } from "../../../../store/actions/rules/rulesActions";



const EvalFalse = (props) =>{

    const onAddEvalFalseResponse = () => {
        global.recordAudit("Add Eval False Response clicked");
        
        var selectedRule = (props.propsRedux.rulesJson.metadata && props.propsRedux.rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE]) ?     
            props.propsRedux.rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE]: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                }
        };
        
        props.setSelectedRule(global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE, selectedRule, true);
        props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                nodeKey: global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: true,
            }]
        });

        props.showResponseModal(true);
    }

    return (
        props.propsRedux.rulesJson &&
        <button className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue" onClick={ onAddEvalFalseResponse }>
            <Icon name="plus" className="icon-btn"/>
            Eval False
        </button>

    )    
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  setSelectedRule, setResponseAction, showResponseModal })(EvalFalse);