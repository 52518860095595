import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, HashRouter } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";
import * as $ from 'jquery';

/** Importing custom Components */
// import WorkbenchLayout from './WorkbenchLayout/WorkbenchLayout';
import Rules from "../../components/Rules/Rules";

/** Importing store for redux */
import store from "../../store/store";

/** Included just for including global styles */
import '../../assets/GlobalStyles/style.module.css';
import '../../assets/GlobalStyles/style.css';

// import classes from './Layout.module.css';
// import "../../assets/global-scripts/keep-alive";

const Layout = (props) => {
    var internalcomponent = '';
    internalcomponent = (
        <Provider store={ store }>
            <HashRouter>
                {/* <Route path="/workbench" component={ WorkbenchLayout } /> */}
                <Route path="/rules/story/:storyId" component={ Rules } />
                <ReactTooltip
                    effect={"solid"}
                    html={true}
                />
            </HashRouter>
        </Provider>
    );

    return ( 
        <CookiesProvider>
            { internalcomponent }
        </CookiesProvider>
     );
}

export default Layout;