// //*
//  * Component Description : 
//  * Author: Meghana Aggarwal
//  */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './StoryFlow.module.css';
 import TrainNPublishLoader from './TrainAndPublishLoader/TrainAndPublishLoader';
import { trainNPublishProcess,startTrainNPublish } from '../../../store/actions/ai-app/aiAppActions';
import { runTestPlans,resetTestPlan,setShowTestPlans,setShowUtteranceSideBar,setCallFlowJson, setRulesAndCallFlowUpdatedState,resetCallMapping, addStepCallFlow, setCallFlowMapping, resetBootStrapedCallFlow, setViewType, setPreviousTestReports,setSavedTestPlanData, setFromSavedTPData, setAllTestPlans,getEntites, getSampleUtterances, setStepCounter, setAccordionState, setStepSequence, setResponseAction, showNodeResponseModal, setShowEntityModal,setSelectedBlockCallInfo,onNewIntentSetForInfoSideBar, deleteUntrainedLuisObjFromDb ,changeActionsForUntrainedLuisState,startTrainingData, getUntrainedLuisData,} from '../../../store/actions/rules/rulesActions';
import { addStepCallFlowMapping, upgradeCallFlow,callFlowDownload, accordionStepDeletionAllowed } from "../../../utils/callFlowUtils";
import Settings from "./Settings/Settings";
import BlockAction from "./BlockAction/BlockAction"; /* Adding Block Action settings dropdown */
import { params } from "../../../data/params";
import { messages } from '../../../data/strings';
import { setAlert } from '../../../store/actions/common/commonActions';
import { Icon } from '@iconify/react';
/** Importing custom Components */
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import TestPlanSideBar from "../SideBar/TestPlanSideBar/TestPlanSideBar";
import LoadingIcon from '../../UI/LoadingIcon/LoadingIcon';
import ControlBar from '../NodeRules/ControlBar/ControlBar';
import Save from '../NodeRules/Controls/Save/Save';
import pusherCall from '../../../utils/pusherUtils';
import axiosCall from '../../../utils/axiosCall';
import SyncApp from './SyncApp/SyncApp';
import Entities from './Entities/Entities'
import OneClickUtterances from './OneClickUtterances/OneClickUtterances';
import AddNode from '../NodeRules/Controls/AddNode';
import {Upgrade} from '../NodeRules/Controls/Upgrade/Upgrade';



/** // a little function to help us with reordering the result */
const reorder = (list, startIndex, endIndex, destinationId) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
/**
 * Moves an item from one list to another list.
 */
const move = ( source, destination, droppableSource, droppableDestination , startTrainNPublish) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
 
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  /** change background colour if dragging */
  background: isDragging ? '' : 'transparent',
  /** styles we need to apply on draggables */
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
    // display:'table-row',
    flexWrap:'nowrap',
    minHeight:'50px',
    margin: '0px',
    width:'100%',
  background: isDraggingOver ? '#fff' : '',
  minWidth:"768px"
});
/** Main Components */
class StoryFlow extends React.Component {
    /** Lifecycle methods start */
    constructor(props) {
        super(props);
        this.blockRefArr = [];
        this.state = {
            stepLoader: null,
            deletingStep: null,
            deletingBlockIndex: null,
            showSampleUtt: false, /* by default utterances are visible */
            downloadLoader: false,
            runTP:false,
            TPChannelLoading:false,
            expandTPSidebar: false,
            onLoad: false,
            syncLoader:false,
            showUtteranceSideBarOnNewNodeAdd: false,
            showModal: false,
            selectedSteps: {},
            showMultiPathSettings: false,
            disableSelection: false
        };
        this.props.setPreviousTestReports(this.props.propsRedux.storyId);
        this.addBlockRef = (el,selectedTestPlan,stepIndex,item,index) =>{
            if (el && selectedTestPlan && selectedTestPlan[stepIndex]&& selectedTestPlan[stepIndex] && selectedTestPlan[stepIndex]['index']&& selectedTestPlan[stepIndex]['index']['node_index'] === index && !this.blockRefArr.includes(el)) {
                this.blockRefArr.unshift(el);
            }
        }
        this.onShowSampleUttClick = this.onShowSampleUttClick.bind(this);       
        /** Confirm call flow upgrade */
        this.confirmUpgradeAlert = {
            title: 'Upgrade Call Flow',
            message: 'You call flow is out dated. Please upgrade it to work properly. If you choose "Reset and Upgrade", it will reset your call flow to default call flow made at bootstrapping level and you will loose your current changes in the call flow.',
            buttons: [
              {
                label: 'Upgrade',
                onClick: this.upgradeCallFlowInStates,
              },
              {
                label: 'Reset & Upgrade',
                className: [ classes.BtnStartOver, "btn-danger" ].join(' '),
                onClick: this.resetCallFlow
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: false,
            closeOnClickOutside: false,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        /** Confirm call flow block removal */
        this.confirmRemoveBlock = {
            title: 'Remove Block',
            message: 'Are you sure about removing this block from call flow ? This will not be removed from simple rules.',
            buttons: [
              {
                label: 'Remove',
                onClick: this.onRemoveBlock
              },
              {
                label: 'cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        this.checkSampleUtteranceAlert = {
            title: 'Alert',
            message: 'Please make ensure every block has sample utterance configured in It!',
            buttons: [
              {
                label: 'ok',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        this.unsavedChangedAlert = {
            title: 'Alert',
            message: 'Please make sure you saved changes before you generate or run Testplans!',
            buttons: [
              {
                label: 'ok',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        this.channelAlreadyInQueue = {
            title: 'Alert',
            message: 'Channel are already in queue',
            buttons: [
              {
                label: 'ok',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        this.testPlanType = {
            title: 'Generate Test plans',
            message: 'Do you want to generate all test plans?',
            buttons: [
              {
                label: 'Yes',
                onClick: ()=>this.onGenerateAndRunTestPlan(true,'alert')
              },
              {
                label: 'No',
                onClick: ()=>this.onGenerateAndRunTestPlan(false,'alert')
              }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        this.callFlowUpgradeCheck();
    }
    /** Lifecycle methods end */
    stepLoader = (stepLoaderState = null) => {
        this.setState({stepLoader: stepLoaderState});
    }

     /** checks whether  every Intent has atleast one utterance */
     checkIfAllIntentsHaveAtleastOneUtterance = ()=> {
        const untrainedIntents =  this.props.propsRedux.untrainedLuisObjData.ADD.intents;
        const untrainedUtterances =  this.props.propsRedux.untrainedLuisObjData.ADD.utterances;
        const untrainedUtteranceIntents = untrainedUtterances.map(item => item.intent)
        let intentResults = {data : [],isValid : true}

        if(untrainedIntents.length > 0){
            const everyIntentHasAtleastOneUtterance =  untrainedIntents.every(intent => untrainedUtteranceIntents.includes(intent.name) )
            
            if(everyIntentHasAtleastOneUtterance){
                intentResults = {data:[],isValid:true}
                return intentResults
            }

            if(!everyIntentHasAtleastOneUtterance){
                const intentsWithoutUtterance =  untrainedIntents.filter(intent => !untrainedUtteranceIntents.includes(intent.name));
                intentResults = {data:intentsWithoutUtterance,isValid:false}
                return intentResults
            }
        }

        return intentResults
     }

    trainNPublish = (endSession=false) => {
       const {data,isValid} = this.checkIfAllIntentsHaveAtleastOneUtterance()

        if(isValid){
            this.props.startTrainNPublish({endSession: endSession});
        
        }else{
            this.props.setAlert(`${data.map(item => item.name).join()} Intents Need Atleast One Utterance`,0);
        }    
	}

    componentDidUpdate(prevprops){
        const isStoryIdMatchStateChanged =  this.props.propsRedux.untrainedLuisObj.isStoryIdMatch !== prevprops.propsRedux.untrainedLuisObj.isStoryIdMatch   
        const isStoryTitleStateChanged =  this.props.propsRedux.untrainedLuisObj.storyTitle !== prevprops.propsRedux.untrainedLuisObj.storyTitle   
        const storyTitle = this.props.propsRedux.untrainedLuisObj.storyTitle ? "Story: " +  this.props.propsRedux.untrainedLuisObj.storyTitle : 'Prediction Report' ;
        const isIncomingDataStateChanged =  this.props.propsRedux.untrainedLuisObj.isIncomingDataExist !== prevprops.propsRedux.untrainedLuisObj.isIncomingDataExist 
        const isIncomingDataEmptyObjStateChanged =  this.props.propsRedux.untrainedLuisObj.isIncomingDataEmptyObj !== prevprops.propsRedux.untrainedLuisObj.isIncomingDataEmptyObj 
            
                if(isIncomingDataStateChanged){

                    if(this.props.propsRedux.untrainedLuisObj.isIncomingDataExist === false){
                        this.props.startTrainingData()
                        this.props.setAlert(messages.trainingStarted, 1);
                    }
                }
                    if(isStoryIdMatchStateChanged || isStoryTitleStateChanged ){
            
                       if(this.props.propsRedux.untrainedLuisObj.isStoryIdMatch === true ){
                            if( this.props.propsRedux.untrainedLuisObj.isIncomingDataExist === true){
                           const infoElement = () => <div className={classes.luisWarningInfoContainer}>
                               <Icon name='warning' className={classes.luisWarningIcon} /> <span className={classes.luisWarningInfoLabel}>{messages.warningBeforeDeletingTrainingData}</span>
                           </div>
    
                           const handleConfirmAlert=()=>{
                            confirmAlert({
                                title: "AI App Info",message: messages.luisStoryIdMatchMessage,childrenElement:infoElement,
                                className:'custom-react-alert',
                                buttons: [
                                    {label: "Continue", onClick: () => {}},
                                    {label: "Disable", onClick: () => this.props.changeActionsForUntrainedLuisState(false)},
                                    {label: "Delete Training Data", onClick: () => this.props.deleteUntrainedLuisObjFromDb(),className:classes.luisDataDeleteButton}
                                ],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                            })
                        }
                        
                        handleConfirmAlert();
                       }
                       else{
                        this.props.setAlert(messages.alreadyStartedTraining, 1)
                    }
                       
                    }
            /** story_id missmatch case where story title and user name is displayed in luis app info pop up */
    
                       
                    
                    }

                    if(isIncomingDataEmptyObjStateChanged ){
                        if(this.props.propsRedux.untrainedLuisObj.isStoryIdMatch === false){
                         const handleConfirmAlert=()=>{
                             confirmAlert({
                                 title: "AI App Info",message: messages.luisStoryIdMismatchMessage + storyTitle + "," +" User:"+  this.props.propsRedux.untrainedLuisObj.username,
                                 buttons: [{label: "Okay", onClick: () => {}}],
                                 closeOnEscape: false,
                                 closeOnClickOutside: false,
                             })
                         }
                         handleConfirmAlert();
                        }
                    }            
        }
    
    startTrainingButton = () => { 
        this.props.getUntrainedLuisData() 
    }

    checkIfLuisTrainingDataExists = () => {
        const isAddLuisUntrainedDataExists = this.props.propsRedux.untrainedLuisObjData.ADD.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.ADD.utterances.length >0 || ( this.props.propsRedux.untrainedLuisObjData.ADD.entities && this.props.propsRedux.untrainedLuisObjData.ADD.entities.length >0 )
        const isDeleteLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.DELETE.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.DELETE.utterances.length >0  || ( this.props.propsRedux.untrainedLuisObjData.DELETE.entities && this.props.propsRedux.untrainedLuisObjData.DELETE.entities.length >0 )
        const isEditLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.EDIT.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.EDIT.utterances.length >0 || (this.props.propsRedux.untrainedLuisObjData.EDIT.entities && this.props.propsRedux.untrainedLuisObjData.EDIT.entities.length >0 )
        const isDataExists = isAddLuisUntrainedDataExists || isDeleteLuisUntrainedDataExists || isEditLuisUntrainedDataExists
        return isDataExists
    }

    getStepIndex = (stepKey, increment = false) => {
        let index = -1;
        if (stepKey.startsWith("step")) {
            let indexStr = stepKey.replace("step", "");
            index = parseInt(indexStr);
        }
        if ((index > -1) && (increment)) {
            index++;
        }
        return index;
    }
 addStep = (step, addingTemp = false) => {
        let nodeUpdated = false;
        let newMainSequence = [...this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']]
        /** Call Flow version 1.1 and old support */
        let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        let newCallFlowJSON = {...callFlowJSONPicked};
        let newRelations = {...this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations']}
        let newIndex = this.getStepIndex("step"+this.props.propsRedux.stepCounter, true);
        let newStepSequence = [];
        let stepSequence = [...this.props.propsRedux.stepSequence];
        // Adding step in stepSequence and callFlowJson for step 
        stepSequence.map((stepIndex, index)=>{
            if(!nodeUpdated){
                newCallFlowJSON[stepIndex] = callFlowJSONPicked[stepIndex];
                newStepSequence.push(stepIndex)
            }
            if((stepIndex === step) && (newIndex > 0)){
                newCallFlowJSON["step"+newIndex] = {};
                newCallFlowJSON["step"+newIndex]['list'] = [];
                nodeUpdated = true;
                newStepSequence.push("step"+newIndex)
            }
            else if(nodeUpdated && (newIndex > 0)){
                newCallFlowJSON[stepIndex] = callFlowJSONPicked[stepIndex];
                newStepSequence.push(stepIndex)
            }
        });
        // Adding step in main-sequence
        let mainSeqIdx = newMainSequence.indexOf(step);
        if(mainSeqIdx > -1){
            newMainSequence.splice(mainSeqIdx+1,0,"step"+newIndex)
        }
        // Adding Step in relations
        Object.keys(newRelations).forEach(key=>{
            newRelations[key].forEach(rel=>{
                let path1Idx = rel.path1.indexOf(step);
                if(path1Idx > -1){
                    rel.path1.splice(path1Idx+1,0,"step"+newIndex)
                }
                let path2Idx = rel.path2.indexOf(step);
                if(path2Idx > -1){
                    rel.path2.splice(path2Idx+1,0,"step"+newIndex)
                }
            })
        })
        var callflowMapping = this.props.propsRedux.callFlowResponseMappings;
        if (addingTemp) {
            var callflowMapping = addStepCallFlowMapping(newCallFlowJSON, this.props.propsRedux.callFlowResponseMappings, step);
        } else {
            let requiredCallFlowJSON = this.props.propsRedux.callFlowJSON;
            requiredCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = {...newCallFlowJSON};
            requiredCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence'] = [...newMainSequence];
            requiredCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'] = {...newRelations};
            this.props.setCallFlowJson(requiredCallFlowJSON);
            this.props.addStepCallFlow(step);
        }
        this.props.setStepSequence(newStepSequence)
        this.props.setStepCounter(this.props.propsRedux.stepCounter+1)
        return {
            newCallFlowJSON: newCallFlowJSON,
            newCallFlowResponseMappings: callflowMapping,
            newMetaData: {
                ...this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA],
                ['main-sequence']:[...newMainSequence],
                ['relations']: {...newRelations}
            }
        };
    }
    getList = (stepKey, callFlowJSON = null) => {
        if (callFlowJSON === null) {
            /** Call Flow version 1.1 and old support */
            callFlowJSON = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        }
        return callFlowJSON[stepKey].list;
    };

   
    
    onDragEndAnotherList = ({source, destination, newCallFlowJSON, newCallFlowResponseMappings, callFlowMetaData}) => {
        /** Call Flow version 1.1 and old support */
        let newCallFlowJsonSteps = (newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : newCallFlowJSON;
        const result = move(
            this.getList(source.droppableId, newCallFlowJsonSteps),
            this.getList(destination.droppableId, newCallFlowJsonSteps),
            source,
            destination
        );
        let reorderedCallFlow = {
                ...newCallFlowJsonSteps,
                [source.droppableId]:{
                    list:[...result[source.droppableId]],
                    settings: (newCallFlowJsonSteps[source.droppableId].settings) ? { ...newCallFlowJsonSteps[source.droppableId].settings, defaultUtterance: (newCallFlowJsonSteps[source.droppableId].settings.defaultUtterance?(newCallFlowJsonSteps[source.droppableId].settings.defaultUtterance.blockId==newCallFlowJsonSteps[source.droppableId].list[source.index].id?(newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1]&&newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1].sampleUtterances?{...newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1].sampleUtterances[0]}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}):{...newCallFlowJsonSteps[source.droppableId].settings.defaultUtterance}):(newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1]&&newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1].sampleUtterances?{...newCallFlowJsonSteps[source.droppableId].list[source.index==0?1:source.index-1].sampleUtterances[0]}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""})) } : (newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances?{defaultUtterance:{...newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances[0]}}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}) 
                },
                [destination.droppableId]:{
                    list:[...result[destination.droppableId]],
                    settings: (newCallFlowJsonSteps[destination.droppableId].settings) ? { ...newCallFlowJsonSteps[destination.droppableId].settings, defaultUtterance: (newCallFlowJsonSteps[destination.droppableId].settings.defaultUtterance?(newCallFlowJsonSteps[destination.droppableId].settings.defaultUtterance.utteranceText=="")?(newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances?{...newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances[0]}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}):{...newCallFlowJsonSteps[destination.droppableId].settings.defaultUtterance}:(newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances?{...newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances[0]}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}))} : {defaultUtterance:(newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances?{...newCallFlowJsonSteps[source.droppableId].list[source.index].sampleUtterances[0]}:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""})} 
                }
        }
        let requiredCallFlowJSON = this.props.propsRedux.callFlowJSON;
        requiredCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = {...reorderedCallFlow};
        if(callFlowMetaData){
            requiredCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA] = {...callFlowMetaData}
        }
        this.props.setCallFlowJson(requiredCallFlowJSON);
        this.props.resetCallMapping([source.droppableId, destination.droppableId],[source.index, destination.index], reorderedCallFlow, newCallFlowResponseMappings);
    }
    onDragEnd = result => {
        const { source, destination } = result;
        /** dropped outside the list */
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            if (source.index !== destination.index) {
                const items = reorder(
                    this.getList(source.droppableId),
                    source.index,
                    destination.index
                );
                /** Call Flow version 1.1 and old support */
                let newCallFlowJSON = this.props.propsRedux.callFlowJSON;
                let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
                let reorderedCallFlow = {
                        ...callFlowJSONPicked,
                        [source.droppableId]:{
                            list:[...items],
                            settings: (callFlowJSONPicked[source.droppableId].settings) ? { ...callFlowJSONPicked[source.droppableId].settings, defaultUtterance: {blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""} } : {} 
                        },
                        [destination.droppableId]:{
                            list:[...items],
                            settings: (callFlowJSONPicked[destination.droppableId].settings) ? { ...callFlowJSONPicked[destination.droppableId].settings } : {} 
                        }
                }
                newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = reorderedCallFlow
                this.props.setCallFlowJson(newCallFlowJSON);
                this.props.resetCallMapping([source.droppableId],[source.index, destination.index]); 
            }
        } else {
            if (destination && destination.droppableId && destination.droppableId.startsWith("temp_")) {
                let prevStep = destination.droppableId.replace("temp_", "");
                let newStepIndex = this.getStepIndex("step"+this.props.propsRedux.stepCounter, true);
                let sourceIndex = this.getStepIndex(source.droppableId);
                if (sourceIndex >= newStepIndex) {
                    source.droppableId = "step" + (sourceIndex+1);
                }
                if (newStepIndex > 0) {
                    destination.droppableId  = "step" + newStepIndex;
                    destination.index  = 0;
                    let result = this.addStep(prevStep, true);
                    this.onDragEndAnotherList({source: source, destination: destination, newCallFlowJSON: result.newCallFlowJSON, newCallFlowResponseMappings: result.newCallFlowResponseMappings,callFlowMetaData: result.newMetaData});
                }
            } else {
                this.onDragEndAnotherList({source: source, destination: destination, newCallFlowJSON: this.props.propsRedux.callFlowJSON, newCallFlowResponseMappings: this.props.propsRedux.callFlowResponseMappings});
                // const result = move(
                //     this.getList(source.droppableId),
                //     this.getList(destination.droppableId),
                //     source,
                //     destination
                // );
                // let reorderedCallFlow = {
                //         ...this.props.propsRedux.callFlowJSON,
                //         [source.droppableId]:{list:[...result[source.droppableId]]},
                //         [destination.droppableId]:{list:[...result[destination.droppableId]]}
                // }
                // this.props.setCallFlowJson(reorderedCallFlow);
                // this.props.resetCallMapping([source.droppableId, destination.droppableId],[source.index, destination.index]);
            }
        }
        this.setState({disableSelection: false})
    };

    onDragStart = () =>{
        this.setState({disableSelection: true})
    }
    onRemoveBlock = (stepToRemove=null , blockIndexToRemove = null) => {
        let step = stepToRemove !==null ? stepToRemove : this.state.deletingStep;
        let blockIndex = blockIndexToRemove !==null ? blockIndexToRemove : this.state.deletingBlockIndex;
        /** Call Flow version 1.1 and old support */
        let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        let blockToRemove = { ...callFlowJSONPicked[step].list[blockIndex] };
        let blockId = blockToRemove.id;
        var newCallFlowJSON = { ...callFlowJSONPicked };
        if (newCallFlowJSON && newCallFlowJSON[step] && newCallFlowJSON[step].list && Array.isArray(newCallFlowJSON[step].list)) {
            newCallFlowJSON[step].list.splice(blockIndex, 1);
            /** checking if list got blank, remove interruption */
            if (
                (newCallFlowJSON[step].list.length === 0) &&
                newCallFlowJSON[step].settings &&
                newCallFlowJSON[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.INTERRUPTION]
            ) {
                delete newCallFlowJSON[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.INTERRUPTION]
            }
            if ( 
                newCallFlowJSON[step].settings &&
                newCallFlowJSON[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]&&
                (callFlowJSONPicked[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].blockId==blockId)
            ) {
                newCallFlowJSON[step].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE] = (newCallFlowJSON[step].list.length !== 0)&&(newCallFlowJSON[step].list[0].sampleUtterances)?newCallFlowJSON[step].list[0].sampleUtterances[0]:{blockId:null,utteranceId:"",utteranceText:"",relatedIntent:""}
            }
        }
        var newCallFlowMapping = { ...this.props.propsRedux.callFlowResponseMappings }
        let mapperKeyToRemove = "";
        if (
            blockToRemove &&
            blockToRemove[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP]
        ) {
            mapperKeyToRemove = blockToRemove[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.MAPPER_IN_STEP];
        } else {
            /** long process for older jsons : can deprecate later */
            let expectedKeys = Object.keys(newCallFlowMapping).filter(key => (key.includes(blockToRemove.node)));
            expectedKeys.forEach(mapperKey => {
                if (
                    (newCallFlowMapping[mapperKey].step === step) &&
                    (newCallFlowMapping[mapperKey].stepIndex === blockIndex)
                ) {
                    mapperKeyToRemove = mapperKey;
                }
            });
        }
        if (newCallFlowMapping[mapperKeyToRemove]) {
            /** optimised for call flow Jsons of versions > 1.0 */
            delete newCallFlowMapping[mapperKeyToRemove];
        } 
        if(!stepToRemove){
            this.props.setRulesAndCallFlowUpdatedState(2);
        }
        const callFlowJSON = this.props.propsRedux.callFlowJSON;
        callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] = newCallFlowJSON
        this.props.setCallFlowJson(callFlowJSON, true, true);
        this.props.setCallFlowMapping(newCallFlowMapping);
        this.setState({
            deletingStep: null,
            deletingBlockIndex: null,
        });
    }
    checkIfDeletionAllowed = () => {
        /** Call Flow version 1.1 and old support */
        let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        let countBlocks = 0;
        Object.keys(callFlowJSONPicked).some( stepKey => {
            if (callFlowJSONPicked[stepKey].list) {
                countBlocks += callFlowJSONPicked[stepKey].list.length;
            }
            if (countBlocks > 1) {
                return true;
            }
        });
        if (countBlocks > 1) {
            return true;
        }
        return false;
    }
    checkIfTestplanGenerationAllowed = () => {
        /** Call Flow version 1.1 and old support */
        let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        var allowedFlag = true;
        Object.keys(callFlowJSONPicked).some( stepKey => {
            if (callFlowJSONPicked[stepKey].list) {
                callFlowJSONPicked[stepKey].list.map((value)=>{
                    if(value.sampleUtterances?value.sampleUtterances[0].utteranceText?value.sampleUtterances[0].utteranceText=="":true:true){
                        allowedFlag=false;
                    }
                });
            }
        });
        return allowedFlag;
    }
    onRemoveBlockAlertShow = (step, blockIndex) => {
        if(!this.checkIfDeletionAllowed()){
            this.props.setAlert(messages.deletionFromCFNotAllowed);
        }else if(!accordionStepDeletionAllowed(step, this.props.propsRedux.callFlowJSON, this.props.propsRedux.accordionState)){
            this.props.setAlert(messages.accordionAbovePassable);
        }else{
            this.setState({
                deletingStep: step,
                deletingBlockIndex: blockIndex
            });
            confirmAlert(this.confirmRemoveBlock);
        }
   }
    /** Check if call flow needs to be upgraded */
    checkForCallFlowUpdateNeeded = (callFlowJson) => {
        if (callFlowJson.version && (callFlowJson.version === params.currentCallFlowJsonVersion)) {
            return false;
        }
        return true;
    }
    /** Upgrade call flow */
    callFlowUpgradeCheck = () => {
        if ( this.props.propsRedux.callFlowJSON &&
            ( Object.keys(this.props.propsRedux.callFlowJSON).length > 0 ) &&
            this.checkForCallFlowUpdateNeeded(this.props.propsRedux.callFlowJSON)) {
            confirmAlert(this.confirmUpgradeAlert);
        }
    }
    upgradeCallFlowInStates = () => {
        global.recordAudit("upgrading call flow json");
        let newCallFlowJSON = upgradeCallFlow(this.props.propsRedux.callFlowJSON, this.props.propsRedux.callFlowResponseMappings);
        global.recordAudit("upgraded call flow json from " + JSON.stringify(this.props.propsRedux.callFlowJSON) + "  ::to:: " + JSON.stringify(this.props.propsRedux.newCallFlowJSON));
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJSON, true, true, true);
    }
    resetCallFlow = () => {
        global.recordAudit("Reset & Upgrade call flow clicked for rule id : " + this.props.propsRedux.storyRuleId);
        this.props.resetBootStrapedCallFlow(this.props.propsRedux.storyId, this.props.propsRedux.storyRuleId);
    }
    onShowSampleUttClick = (e) => {
        global.recordAudit("DEBUGGER:: onShowSampleUttClick e.target.checked", e.target.checked);
        this.setState({showSampleUtt:e.target.checked});
    }
    downloadHandler = () =>{
        this.setState({downloadLoader:true});
        var url = 'common/download/' + this.props.propsRedux.storyId +'?show_metadata=True';
        const axiosSuccessFunction = (response) => {
        /** Handle success case */
            if (response.code ===200 && response.data && Object.keys(response.data).length) {
                callFlowDownload(response.data,this.props.propsRedux.title);
            }
            this.setState({downloadLoader:false});
        };
        const customErrorFunction = (response) =>{
            if(response){
                if(response.response){
                    if(response.response.message){
                        this.props.setAlert(response.response.data.message, 0);
                    }else{
                        this.props.setAlert('Sample Utterances not found', 0);
                    }
                }
                else if(typeof(response) === 'string'){
                    this.props.setAlert(response, 0);
                }
                else{
                    this.props.setAlert('Sample Utterances not found', 0);
                }
            }
            else{
                this.props.setAlert('Sample Utterances not found', 0);
            }
            this.setState({downloadLoader:false});
        }
        axiosCall(url, axiosSuccessFunction, customErrorFunction);
    }
    testPlanAlerts = () => {
        if(!this.checkIfTestplanGenerationAllowed()){
            confirmAlert(this.checkSampleUtteranceAlert);
        }else if(this.props.propsRedux.isDraftInDb || this.props.propsRedux.callFlowIsDraft || this.props.propsRedux.unsavedChanges){
            confirmAlert(this.unsavedChangedAlert);
        }else{
            confirmAlert(this.testPlanType)
        }
    }
    pusherUnsubscription = () =>{
        let pusherData = {
            pusher_key: this.props.propsRedux.pusherKeys.PUSHER_KEY,
            cluster_name: this.props.propsRedux.pusherKeys.PUSHER_CLUSTER
        }
        const pusherSuccessCall = (pusher) => {
            pusher.unsubscribe(this.props.propsRedux.testPlanChannel.channel_name.toString());
        }
        pusherCall(pusherSuccessCall,pusherData);
    }
    onGenerateAndRunTestPlan = (type,parent) =>{
        const storyId = this.props.propsRedux.storyId;
        this.props.propsRedux.testPlanChannel &&
        this.props.propsRedux.testPlanChannel.channel_name && this.pusherUnsubscription();
        this.props.resetTestPlan();
        this.setState({TPChannelLoading:true})
        global.recordAudit("DEBUGGER:: onGenerateAndRunTestPlan storyId ",storyId);
        this.props.setShowTestPlans(true,storyId,type);
        parent!=='switch' && this.props.setSavedTestPlanData('reset');
        this.openTPSidebar();
            if(this.props.propsRedux.testPlanChannel&&this.props.propsRedux.testPlans){
                this.props.propsRedux.testPlans.bind(this.props.propsRedux.testPlanChannel.event_name,(state)=>{
                this.props.runTestPlans(this.props.propsRedux.testPlanChannel.channel_name,state);
                });
            }
        this.setState({runTP:true})
        this.props.setAllTestPlans(type)
    }
    openTPSidebar = () =>{
        document.getElementById("callFlowScreen").classList.add("slideTP");
        if(document.getElementById("slideIcon")){
            document.getElementById("slideIcon").classList.add("turn");
        }
        this.setState({expandTPSidebar:true})
    }
    closeTPSideBar = () => {
        document.getElementById("callFlowScreen").classList.remove("slideTP");
        if(document.getElementById("slideIcon"))
        {
            document.getElementById("slideIcon").classList.remove("turn");
        }
        this.setState({expandTPSidebar:false})
        // global.recordAudit("On Close Test Plans Side Bar for StoryId: ",this.props.storyId);
    }
    toggleTPSideBar = () => {
        document.getElementById("callFlowScreen").classList.toggle("slideTP");
    }
    disableEnableRunButton =(state) =>{
        this.setState({TPChannelLoading:state})
    }
    tpSwitchHandler = () =>{
        let type = !this.props.propsRedux.allTestPlans;
        let onLoad = this.props.propsRedux.savedTestPlanData.onLoad;
        if(this.props.propsRedux.savedTestPlanData.basic &&!Object.keys(this.props.propsRedux.savedTestPlanData.basic).length && type ){
            this.props.setSavedTestPlanData('add','basic',{testPlanJsons:this.props.propsRedux.testPlanJsons,testReports:this.props.propsRedux.testReports})
        }
        if(this.props.propsRedux.savedTestPlanData.all && !Object.keys(this.props.propsRedux.savedTestPlanData.all).length && !type){
            this.props.setSavedTestPlanData('add','all',{testPlanJsons:this.props.propsRedux.testPlanJsons,testReports:this.props.propsRedux.testReports})
        }
        if(this.props.propsRedux.savedTestPlanData.all && Object.keys(this.props.propsRedux.savedTestPlanData.all).length && type){
            this.props.setFromSavedTPData({testPlanJsons:this.props.propsRedux.savedTestPlanData.all.testPlanJsons, testReports:this.props.propsRedux.savedTestPlanData.all.testReports})
        }
        if(this.props.propsRedux.savedTestPlanData.basic && Object.keys(this.props.propsRedux.savedTestPlanData.basic).length && !type){
            this.props.setFromSavedTPData({testPlanJsons:this.props.propsRedux.savedTestPlanData.basic.testPlanJsons, testReports:this.props.propsRedux.savedTestPlanData.basic.testReports})
        }
        if((!type && Object.keys(this.props.propsRedux.savedTestPlanData.basic).length) || (type && Object.keys(this.props.propsRedux.savedTestPlanData.all).length)){
            this.props.setAllTestPlans(type)
        }
        else{
            this.onGenerateAndRunTestPlan(type,'switch');
        }
        if(onLoad === true){
            onLoad = false
        }
        this.props.setSavedTestPlanData('add','onLoad',onLoad)
    }
    /**
     * new function to sync the luis apps with new APIs
     */
    // syncLuisApp = () => {
    //     this.setState({syncLoader:true});
    //     const  brandId = this.props.propsRedux.brandId;
    //     const appId = this.props.propsRedux.luisAppId;
    // }
    // syncLuisApps = () =>{
    //     this.setState({syncLoader:true});
    //     const  brandId = this.props.propsRedux.brandId;
    //     const appId = this.props.propsRedux.luisAppId;
    //     let data = {brandId :brandId ,appId : appId, step: 1, sync:2};
    //     var self = this;
    //     this.importLuis(2, true,brandId, appId, data, 1).then(function(result){
    //         // step 1 export and import intent
    //         var status = result.success;
    //         var totalIntents = result.data;
    //         if(status == "true")
    //         {
    //                 var offset = 0;
    //                 var limit = 10;
    //                 data = {brandId :brandId ,appId : appId, step: 2, offset: offset, limit: limit, sync:2};
    //                 result = {};
    //                 status = "";            
    //                 // step 2 import utterances
    //                 self.importLuis(2, true,brandId, appId, data, 2, totalIntents).then(function(result){
    //                     status = result.success;
    //                     self.setState({syncLoader:false});
    //                     if(status === "true"){
    //                         self.props.getSampleUtterances(self.props.propsRedux.storyId);
    //                         self.props.setAlert('App synced successfully', 1);        
    //                     }
    //                     else{
    //                         global.recordAudit(result.message);
    //                         self.props.setAlert(result.message, 0);                     
    //                     }                
    //                 })
    //                 .catch(function (error) {
    //                     self.setState({syncLoader:false});
    //                     global.recordAudit("Some error occurred while syncing. Please try again later");
    //                     self.props.setAlert("Some error occurred while syncing. Please try again later", 0);
    //                 });                 
    //         } else {
    //             self.setState({syncLoader:false});
    //             global.recordAudit(result.message);
    //             self.props.setAlert(result.message, 0);
    //         }
    //     })
    //     .catch(function (error) {
    //         self.setState({syncLoader:false});
    //         global.recordAudit("Some error occurred while syncing. Please try again later");
    //         self.props.setAlert("Some error occurred while syncing. Please try again later", 0);
    //     });
    // }
    // importLuis =(sync, currentAppId,brandId, appId, data, stepType,totalIntents, offset, result) =>{
    //     var limit = 2;
    //     if(typeof offset == "undefined") {
    //         var offset = 0;
    //     }
    //     let url = 'luis/ImportLuisApp';
    //     let self = this;
    //     return new Promise(
    //         function (resolve, reject) {
    //             if (stepType == 1) {
    //                 const axiosSuccessFunction = (response) => {
    //                     /** Handle success case */
    //                     resolve(response.data);
    //                 };
    //                 const customErrorFunction = (response) =>{
    //                     resolve(response.data);
    //                 }
    //                 axiosCall(url, axiosSuccessFunction, customErrorFunction, data, 'POST', 2, 'php');
    //             } else {
    //                 var importUtterancedata = {
    //                     brandId: brandId,
    //                     appId: appId,
    //                     step: 2,
    //                     offset: offset,
    //                     limit: limit,
    //                     sync: sync
    //                 };
    //                 if ((offset + limit <= totalIntents) || (offset == 0) || (offset < totalIntents && offset != 0)) {
    //                     const axiosSuccessFunction = (response) => {
    //                         /** Handle success case */
    //                         offset = offset + limit;
    //                         self.importLuis(sync, currentAppId, brandId, appId, response.data, stepType, totalIntents, offset, response.data).then(function (result) {
    //                             resolve(result);
    //                         });
    //                     };
    //                     const customErrorFunction = (response) =>{
    //                         resolve(response);
    //                     }
    //                     axiosCall(url, axiosSuccessFunction, customErrorFunction, importUtterancedata, 'POST', 2, 'php');
    //                 } else {
    //                     if (typeof result != "undefined") {
    //                         resolve(result);
    //                     } else {
    //                         reject(false);
    //                     }
    //                 }
    //             }
    //         });
    // }
    stepSelection = (step, index) =>{
        let openAccordionState = Object.keys(this.props.propsRedux.accordionState).filter(key=> this.props.propsRedux.accordionState[key])[0];
        if(!openAccordionState && !this.state.disableSelection){
            const {selectedSteps} = this.state;
            if(!selectedSteps.hasOwnProperty(step)){
                let newSelectedSteps = {...selectedSteps};
                let mainSequence = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence'];
                let callFlowStepJSON = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]
                if(mainSequence[mainSequence.length-1] !== step && callFlowStepJSON[step] && callFlowStepJSON[step].list && callFlowStepJSON[step].list.length){
                    let selectedKeys = Object.keys(selectedSteps)
                    if(selectedKeys.length){
                        const prevIndex = selectedSteps[selectedKeys[0]];
                        if(index < prevIndex){
                            newSelectedSteps = {
                                [step]: index
                            }
                        }else{
                            for(let i=prevIndex+1; i<=index; i++){
                                newSelectedSteps[mainSequence[i]] = i
                            }
                        }
                    }else{
                        newSelectedSteps[step] = index
                    }
                    this.setState({
                        selectedSteps: newSelectedSteps,
                        showMultiPathSettings: Object.keys(newSelectedSteps).length > 0
                    })
                }
            }
        }
    }
    openAccordion = (accordionId) =>{
        this.props.setAccordionState({[accordionId]: true}, false)
        let [step,relationIdx] = accordionId.split('-');
        relationIdx = parseInt(relationIdx)
        let relation = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'][step][relationIdx]
        let path1 = [...relation.path1];
        let path2 = [...relation.path2];
        let newStepSequnce = [...this.props.propsRedux.stepSequence];
        let firstElIdx = newStepSequnce.indexOf(path1[0]);
        newStepSequnce.splice(firstElIdx,path1.length,...path2)
        this.props.setStepSequence(newStepSequnce)
    }
    closeAccordion = (accordionId) =>{
        this.props.setAccordionState({[accordionId]: false}, false)
        let path = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['main-sequence']
        this.props.setStepSequence([...path])
    }
    closeAccordionViewer = (stepIndex, relations) =>{
        let obj = {};
        return Object.keys(relations).map((startStepKey,index)=>{
            if(relations[startStepKey]){
                return relations[startStepKey].map((relation, relationIdx)=>{
                    if(this.props.propsRedux.accordionState[startStepKey+'-'+relationIdx]){
                        if(relation.path2.includes(stepIndex)){
                            if(obj[stepIndex]){
                                obj[stepIndex] = obj[stepIndex]+1
                            }else{
                                obj[stepIndex] = 1
                            }
                            return <div
                                        key={`${startStepKey}-${index}-${relationIdx}`}
                                        className={[classes.accordionDimensions, classes.accordianSection,'justify-content-center',relation.relation === 'OR' ? classes.orAccordian: classes.andAccordian, relation.path2[relation.path2.length -1] === stepIndex && classes.accordianLastStep].join(' ')}
                                        onClick={()=>this.closeAccordion(`${startStepKey}-${relationIdx}`)}>
                                        {relation.path2.indexOf(stepIndex) ===0 && <>
                                            <span> {'>'} </span>
                                            <span className={classes.verticalText}> {relation.relation} </span>
                                        </>}
                                </div> 
                        }else{    
                            return  obj[stepIndex] && <div key={`${startStepKey}-${index}-${relationIdx}-0`} className={classes.accordionDimensions}></div>           
                        }
                    }
                })
            }
        })
    }
    deleteAccordion = (accordionId) =>{
        let [step,relationIdx] = accordionId.split('-');
        relationIdx = parseInt(relationIdx);
        const callFlowJSON = this.props.propsRedux.callFlowJSON;
        let relations = callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'];
        const callFlowStepJSON = callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]
        relations[step] && relations[step][relationIdx] && relations[step][relationIdx].path2.forEach(stepToRemove=>{
            callFlowStepJSON[stepToRemove] && callFlowStepJSON[stepToRemove].list && callFlowStepJSON[stepToRemove].list.forEach((block, blockIndex)=>{
                this.onRemoveBlock(stepToRemove, blockIndex)
            })
        })
        if(relations[step].length > 1){
            relations[step].splice(relationIdx,1);
        }else{
            delete relations[step]
        }
        let newCallFlowJSON = this.props.propsRedux.callFlowJSON;
        let accordionState = this.props.propsRedux.accordionState
        newCallFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'] = {...relations};
        delete accordionState[accordionId];
        this.props.setRulesAndCallFlowUpdatedState(2);
        this.props.setCallFlowJson(newCallFlowJSON,true,true);
        this.props.setAccordionState(accordionState, true);
    }
    deleteAccordionAlert = (e, accordionId) =>{
        e.stopPropagation();
        confirmAlert({
            title: 'Remove Relation',
            message: 'Are you sure about removing this relation ?',
            buttons: [
                {
                  label: 'Remove',
                  onClick: ()=>this.deleteAccordion(accordionId) 
                },
                {
                  label: 'Cancel',
                  onClick: () => {}
                }
            ],
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          });
    }
    editAccordion = (e, accordionId) =>{
        e.stopPropagation();
        let [step,relationIdx] = accordionId.split('-');
        relationIdx = parseInt(relationIdx);
        const relations = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]['relations'];
        let seletedSteps = relations[step][relationIdx].path1;
        this.props.setShowEntityModal(true, null, null, null, true, {step, relationIdx}, 'multipath', seletedSteps);
    }
    accordionViewer = (step, currIdx, relations ) =>{
        const accordionValues = Object.values(this.props.propsRedux.accordionState);
        if(!accordionValues.includes(true)){
            let obj = {};
            return Object.keys(relations).map((startStepKey,index)=>{
                if(relations[startStepKey]){
                    return relations[startStepKey].map((relation, relationIdx)=>{
                        if(relation.path1.includes(step)){
                            if(obj[step]){
                                obj[step] = obj[step]+1
                            }else{
                                obj[step] = 1
                            }
                            const iconSize = {fontSize:'1rem', color: relation.relation === 'OR' ? '#FFFFFF' : '#000000'};
                            return <div 
                                        key={`${startStepKey}-${index}-${relationIdx}`}
                                        className={[classes.accordionDimensions, 
                                            classes.accordianSection, relation.relation === 'OR' ? 
                                            classes.orAccordian: classes.andAccordian,
                                            relation.path1[relation.path1.length -1] === step && classes.accordianLastStep
                                        ].join(' ')}
                                        onClick={()=>this.openAccordion(`${startStepKey}-${relationIdx}`)}
                                    >   
                                        {relation.path1.indexOf(step) ===0 && 
                                            <>
                                                <div className={`d-flex flex-column`}>
                                                    <span data-tip={messages.deleteRelation} data-class="tooltip_cust" data-place="top" ><Icon onClick={(e)=>this.deleteAccordionAlert(e,`${startStepKey}-${relationIdx}`)} style={iconSize}  icon="ant-design:delete-filled"/></span>
                                                    <span data-tip={messages.editRelation} data-class="tooltip_cust" data-place="top"><Icon onClick={(e)=>this.editAccordion(e,`${startStepKey}-${relationIdx}`)} style={iconSize}  icon="ant-design:edit-filled"/></span>
                                                </div>
                                                <span> {'<'} </span>
                                                <span className={classes.verticalText}> {relation.relation} </span>
                                            </>
                                        }
                                </div> 
                        }else{
                            return  obj[step] &&  <div key={`${startStepKey}-${index}-${relationIdx}-0`} className={classes.accordionDimensions}></div>           
                        }
                    })
                }
            })
        }
    }
    addBlock = (step) => {
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.ACTION_LEVEL.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [],
            step: step
        });
        this.props.showNodeResponseModal(true);   
    }
    accordionChar = () =>{
        const stateKeys = Object.keys(this.props.propsRedux.accordionState);
        let index = -1;
        for(let i=0; i<stateKeys.length; i++){
            if(this.props.propsRedux.accordionState[stateKeys[i]] === true){
                index = i
                break;
            }
        }
        // +2 as our index starts from -1
        return (index+2 +9).toString(36).toUpperCase();
    }
    handleCallFlowInfoSidebar = (item,stepIndex,index) => {
        const config = {utteranceText:(item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]?(item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText?item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText:messages.noUtteranceTextIsConfigured):messages.noUtteranceTextIsConfigured),intent:(item.node),botResponse:((item.id!=0)?{BRText:item.BRText,BRClip:item.BRClip}:"Corrupted Response"),block:{step:stepIndex,index:index}, entities:item.entities}
        this.props.setSelectedBlockCallInfo({...item,...config})
        this.props.setShowUtteranceSideBar(true,{});
    }

    handleIsTrainNPublishButtonDisabled = () => {
        const isAddLuisUntrainedDataExists = this.props.propsRedux.untrainedLuisObjData.ADD.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.ADD.utterances.length >0 
        const isDeleteLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.DELETE.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.DELETE.utterances.length >0 
        const isEditLuisUntrainedDataExists =  this.props.propsRedux.untrainedLuisObjData.EDIT.intents.length >0  || this.props.propsRedux.untrainedLuisObjData.EDIT.utterances.length >0 
        const isDataExists = isAddLuisUntrainedDataExists || isDeleteLuisUntrainedDataExists || isEditLuisUntrainedDataExists

        return !isDataExists
    }
    
    render() {
        // console.log(isDataExists);
        // console.log("test isData exists:");

        console.log("DEBUGGER:: Check new call flow this.props.propsRedux.callFlowJSON", this.props.propsRedux.callFlowJSON);
        /** Call Flow version 1.1 and old support */
        let callFlowJSONPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        let metaData = this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.META_DATA]
        console.log("DEBUGGER:: Check new call flow callFlowJSONPicked", callFlowJSONPicked);
        console.log("DEBUGGER:: Check new call flow stepSequence -->", this.props.propsRedux.stepSequence,"stepCounter-->", this.props.propsRedux.stepCounter,"accordionState-->", this.props.propsRedux.accordionState )
        let isInitialCFREady = ( this.props.propsRedux.callFlowJSON && ( Object.keys(this.props.propsRedux.callFlowJSON).length > 0 ));
        const stepChar = this.accordionChar();
        let stepIdx = 0
        return (
           <>
           < TrainNPublishLoader/>
               <ControlBar
                 title="Call Flow"
                 rightControls={
                     <>
                        {/* <button 
                            className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue"
                            data-tip={messages.syncLuisApps}
                            data-class="tooltip_cust"
                            data-place = "top"
                            style = {{height:"2rem"}}
                            disabled = {this.state.syncLoader}
                            onClick = {this.syncLuisApps}
                        >
                                {this.state.syncLoader?
                                    <LoadingIcon/>
                                    :
                                    <Icon icon="ep:refresh" />
                                }
                        </button> */}
                        <button 
                            className={[classes.startTrainingButton,'btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue'].join(' ')}
                            data-tip={messages.startTraining}
                            data-class="tooltip_cust"
                            data-place = "top"
                            disabled ={this.props.propsRedux.hideStartTrainingButton}
                            onClick = {this.startTrainingButton}
                        >   
                        <Icon icon="material-symbols:not-started-outline-rounded"  />     
                        </button>

                        <SyncApp />
                         <AddNode/>
                        <div className={[classes.ToggleButton,"ml-2 border-0 utteranceToggle pt-0" ].join(' ')}>
                            <div className={["font-14 mr-2"].join(' ')}>Sample Utterances</div>
                            <ToggleSwitch Name="ShowSampleUtterance"  ShowSampleUtt={this.state.showSampleUtt} OnShowSampleUttClick={this.onShowSampleUttClick} disabled={!isInitialCFREady} />
                        </div>
                        <OneClickUtterances  disabled={!isInitialCFREady} />
                        <button 
                            className={`btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue `} 
                            style={{height:'2rem'}} 
                            data-tip={ !(this.props.propsRedux.error && this.props.propsRedux.error.message) && this.state.TPChannelLoading ? messages.generationUnderProcess : messages.generateTestPlan } 
                            data-class="tooltip_cust"  
                            data-place="top" 
                            onClick={this.testPlanAlerts} 
                            disabled={!(this.props.propsRedux.error && this.props.propsRedux.error.message) && this.state.TPChannelLoading}
                            >
                                <Icon icon="carbon:play-filled-alt" />
                        </button>
                        <button 
                            className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue"
                            data-tip={messages.callflowDownload}
                            data-class="tooltip_cust"
                            data-place = "top"
                            style = {{height:"2rem"}}
                            disabled = {(this.state.downloadLoader || !isInitialCFREady)}
                            onClick = {this.downloadHandler}>
                                {this.state.downloadLoader?
                                    <LoadingIcon/>
                                    :
                                    <Icon icon="ci:download"/>
                                }
                        </button>

                        <button 
                            className={"btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue " + (this.handleIsTrainNPublishButtonDisabled() && classes.buttonDisabled)}
                            data-tip={messages.trainAndPublish}
                            data-class="tooltip_cust"
                            data-place = "top"
                            style = {{height:"2rem"}}
                            disabled ={this.handleIsTrainNPublishButtonDisabled()}
                            // resetModal={(val)=> setShowModal(val)} 
                             onClick={() => this.trainNPublish(true)}>
                            <Icon icon="entypo:publish" color="#024890" />

                        </button>
                        
                        <Save/>
                        {this.props.propsRedux.hasMachineLearnEntities && <Upgrade/>}
                     </>
                 }
               />
                <div className="position-relative" style={{marginLeft:'10px',marginRight:'10px'}} onClick={()=>this.setState({selectedSteps:{},showMultiPathSettings: false})}>
                        <div  id="callFlowScreen" className="row m-0 position-relative">
                            <div className={ [ "mt-3 w-100"].join(' ') }>
                            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart} >
                                {
                                        this.props.propsRedux.stepSequence && this.props.propsRedux.stepSequence.length > 0 && this.props.propsRedux.stepSequence.map((stepIndex,index)=>{
                                            if(callFlowJSONPicked[stepIndex]){
                                                stepIdx = stepIdx+1
                                                let isPassable = (callFlowJSONPicked[stepIndex].settings && callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY]);
                                                return(
                                                    <div key={"stepdiv_" + stepIndex} className={ [  "callFLow","d-flex"].join(' ') } onClick={(e)=> e.stopPropagation()}>
                                                        {metaData.relations && Object.keys(metaData.relations).length > 0 && this.closeAccordionViewer(stepIndex,metaData.relations)}
                                                        <div id="CallStepRow" className={[this.state.selectedSteps.hasOwnProperty(stepIndex)  ? classes.selectedSteps:"bg-light", (isPassable) ? "passable" : "" ,classes.justifyContentCenter,"row m-0 w-100 justify-content-center align-items-stretch"].join(' ')} onClick={()=>this.stepSelection(stepIndex,index)}>
                                                            {/* <div className={"colStep-1 pl-2 pr-2"}> */}
                                                            <div className={[classes.AgentBlock,"colStep-1 pl-2 pr-2"].join(' ')}>
                                                                <div className={classes.stepNumbers}>
                                                                    {stepChar}{stepIdx}
                                                                </div>
                                                                <div className={"border-right pr-3 font-14 h-100 text-center"}>
                                                                    <div className="p-1 w-100 blue pt-2"> Agent </div>
                                                                    {
                                                                        /* Adding Default Utterance Block */
                                                                        (callFlowJSONPicked[stepIndex].settings&&
                                                                        (callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]&&
                                                                            callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceText!=""&&
                                                                            stepIndex&&
                                                                        callFlowJSONPicked[stepIndex].list &&
                                                                            (callFlowJSONPicked[stepIndex].list.length > 0))&&
                                                                            <div className={['w-100','mt-2','text-secondary',classes.DefaultUtteranceBlock].join(' ')}>
                                                                                <span 
                                                                                    className={classes.sampleAgentUtteranceText}
                                                                                    data-tip={callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceText} 
                                                                                    data-class="tooltip_cust"
                                                                                    data-place="top">
                                                                                        {callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceText}
                                                                                </span>
                                                                            </div>)
                                                                    }
                                                                    {
                                                                        <div className="w-100 mt-2 text-secondary">
                                                                            <span className={classes.sampleAgentUtteranceText}>
                                                                                {(callFlowJSONPicked[stepIndex].settings?(callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]?(callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceText==""?"No Sample Agent Utterance Configured":""):"No Sample Agent Utterance Configured"):"No Sample Agent Utterance Configured")}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                        <div className={[classes.EntityStyleStepLevel,"p2"].join(' ')}>
                                                                            {callFlowJSONPicked[stepIndex].settings && callFlowJSONPicked[stepIndex].settings.entities &&
                                                                                callFlowJSONPicked[stepIndex].settings.entities.length ?
                                                                                <Entities 
                                                                                    entities = {callFlowJSONPicked[stepIndex].settings.entities}
                                                                                    callFlowJSONPicked={callFlowJSONPicked}
                                                                                    stepKey={stepIndex}
                                                                                    isStepEntity={true}
                                                                                />:''
                                                                            }
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <Droppable key={stepIndex} droppableId={stepIndex} direction="horizontal">
                                                                {(provided, snapshot) => (
                                                                    <div className={["colStep-2 p-0 pt-2 pb-2", classes.ColStepStyle].join(' ')}>
                                                                        <div className='container'
                                                                        >
                                                                            <div
                                                                                className={["row"].join(' ')}
                                                                                ref={provided.innerRef}
                                                                                style={getListStyle(snapshot.isDraggingOver)}>
                                                                                {callFlowJSONPicked[stepIndex].list.length <= 0 && !metaData['main-sequence'].includes(stepIndex) && 
                                                                                    <div className={classes.addResponseBtn} onClick={()=>this.addBlock(stepIndex)}>
                                                                                        Add Response in step
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    callFlowJSONPicked[stepIndex].list.map((item, index) => {
                                                                                        if (item && item.id && item.node) {
                                                                                            return (
                                                                                                <Draggable
                                                                                                    key={stepIndex+"_"+index+"_"+item.id.toString()}
                                                                                                    draggableId={stepIndex+"_"+index+"_"+item.id.toString()}
                                                                                                    index={index}>
                                                                                                    {(provided, snapshot) => (
                                                                                                        <div
                                                                                                            className={
                                                                                                                [ callFlowJSONPicked[stepIndex].list.length>1?"":"col","p-1 align-top",
                                                                                                                    classes.ItemStyle
                                                                                                                ].join(' ')}
                                                                                                            ref={provided.innerRef}
                                                                                                            {...provided.draggableProps}
                                                                                                            {...provided.dragHandleProps}
                                                                                                            style={getItemStyle(
                                                                                                                snapshot.isDragging,
                                                                                                                provided.draggableProps.style
                                                                                                            )}
                                                                                                            onClick={(e)=>e.stopPropagation()}
                                                                                                        >
                                                                                                            <div
                                                                                                                ref = {(el)=>this.addBlockRef(el,this.props.propsRedux.selectedTestPlan,stepIndex,item,index)}
                                                                                                                className={["border callFlowBox",this.props.propsRedux.selectedTestPlan?(this.props.propsRedux.selectedTestPlan[stepIndex]? (this.props.propsRedux.selectedTestPlan[stepIndex]['index'] ? (this.props.propsRedux.selectedTestPlan[stepIndex]['index']['node_index']===index?this.props.propsRedux.selectedTestPlan[stepIndex].code !==200?"border-danger ":"green-highlight " + "shadow-lg":""):""):""):"",classes.PosRel,((item.id == 0) ? "last-stmt" : ((item.type == 2) ? "last-stmt" : (isPassable?classes.NormalItemStylePassable:classes.NormalItemStyle) ))].join(' ')}>
                                                                                                                    {
                                                                                                                        this.state.showSampleUtt&&
                                                                                                                        <div 
                                                                                                                            className={[classes.SampleUtterance,"font-14 p-1",
                                                                                                                            (
                                                                                                                                ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES])?false:true)||
                                                                                                                                ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0])?false:true)||
                                                                                                                                ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText=="")?true:false)
                                                                                                                                )?classes.NoUtterances:"",
                                                                                                                                (item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES] && item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceId!== (callFlowJSONPicked[stepIndex].settings ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE] ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceId:classes.CursorPointer:classes.CursorPointer)) ? classes.CursorPointer:classes.CursorNotAllowed].join(' ')}
                                                                                                                                data-tip={(item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES] && item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceId!== (callFlowJSONPicked[stepIndex].settings ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE] ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceId:null:null)) ? item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText:""} 
                                                                                                                                data-class="tooltip_cust"
                                                                                                                                data-place="top"
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&
                                                                                                                                    (item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText!="")&&
                                                                                                                                    item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES].length>0&&(
                                                                                                                                        callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]=(callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]?callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE]:item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0])
                                                                                                                                    )&&
                                                                                                                                    <div>
                                                                                                                                        {(item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceId!== (callFlowJSONPicked[stepIndex].settings ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE] ? callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.DEFAULT_UTTERANCE].utteranceId:null:null)) ? item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText:""}
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    (
                                                                                                                                        ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES])?false:true)||
                                                                                                                                        ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0])?false:true)||
                                                                                                                                        ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText=="")?true:false)
                                                                                                                                    )   &&                                                       
                                                                                                                                        <div className={[classes.SampleUtteranceTextNotFound].join(' ')}>
                                                                                                                                            {/* No Utterance Is Configured */}
                                                                                                                                        </div>
                                                                                                                                }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div>
                                                                                                                        <div className={[classes.Intent,"font-14 p-2"].join(' ')} title={item.node}
                                                                                                                            data-tip={item.node}
                                                                                                                            data-class="tooltip_cust"
                                                                                                                            data-place="top"
                                                                                                                        >
                                                                                                                            {item.node.length > 30 ?
                                                                                                                                    (
                                                                                                                                    <div>
                                                                                                                                        {`${item.node.substring(0, 30)}...`}
                                                                                                                                    </div>
                                                                                                                                    ) :
                                                                                                                                    <p>{item.node}</p>
                                                                                                                                }
                                                                                                                        </div>
                                                                                                                        <div className={[classes.EntityStyle," p2"].join(' ')}>
                                                                                                                            {item.entities &&
                                                                                                                                item.entities.length ?
                                                                                                                                <Entities 
                                                                                                                                    entities = {item.entities}
                                                                                                                                    callFlowJSONPicked={callFlowJSONPicked}
                                                                                                                                    stepKey={stepIndex}
                                                                                                                                    blockKey={index}
                                                                                                                            />:''
                                                                                                                        }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className={[classes.BotChat,"font-14 p-1"].join(' ')}
                                                                                                                        data-tip = {(item.id!=0)?item.BRText:"Corrupted Response"} 
                                                                                                                        data-class="tooltip_cust"  
                                                                                                                        data-place="top">
                                                                                                                        {(item.id!=0)?item.BRText:"Corrupted Response"}
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className={[classes.CallFlowStepIcon].join(' ')}
                                                                                                                        >
                                                                                                                        <BlockAction
                                                                                                                            title={(<span className="text-center pt-1" aria-label="More Menu">< Icon icon="carbon:overflow-menu-vertical" className={classes.iconStyle} /></span>)}
                                                                                                                            stepKey={stepIndex}
                                                                                                                            blockKey={index}
                                                                                                                            deleteBlock={ () => this.onRemoveBlockAlertShow(stepIndex, index) }
                                                                                                                            settings={ callFlowJSONPicked[stepIndex].list }
                                                                                                                            isDisabled={ (callFlowJSONPicked[stepIndex].list && (callFlowJSONPicked[stepIndex].list.length > 0) ? false : true) }
                                                                                                                            noUtterance = {
                                                                                                                                (
                                                                                                                                    ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES])?false:true)||
                                                                                                                                    ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0])?false:true)||
                                                                                                                                    ((item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES]&&item[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.SAMPLE_UTTERANCES][0].utteranceText=="")?true:false)
                                                                                                                                    )
                                                                                                                                }
                                                                                                                        />
                                                                                                                             <Link onClick={() => this.handleCallFlowInfoSidebar(item,stepIndex,index)} data-tip = {messages.callFlowInfo} data-class="tooltip_cust"  data-place="top"><Icon icon="carbon:information" /></Link>
                                                                                                                    </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Draggable>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                )}
                                                                                {/* <InterruptionBlock 
                                                                                    className={ [ classes.NormalItemStyle, classes.ItemStyle ].join(' ') }
                                                                                    interruption={ "I cannot pay the balance in full" }
                                                                                /> */}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                            <div className={["colStep-3 d-flex align-items-center", classes.PosRel].join(' ')}>
                                                                <div className="border-left pl-3 font-14 h-100 text-center">
                                                                    {!isPassable&&<div className="p-2 w-100 blue pt-3">Interruption</div>}
                                                                    {
                                                                        (
                                                                            callFlowJSONPicked[stepIndex].settings &&
                                                                            (
                                                                                (!callFlowJSONPicked[stepIndex].settings) ||
                                                                                (callFlowJSONPicked[stepIndex].settings && !callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PASSABLE_RESPONSE_KEY])
                                                                            ) &&
                                                                            callFlowJSONPicked[stepIndex].settings.interruption &&
                                                                            callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE] &&
                                                                            callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE] &&
                                                                            callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0] &&
                                                                            callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0].BRText &&
                                                                            /** checking if the step is empty or not, if empty do not show interruption */
                                                                            callFlowJSONPicked[stepIndex].list &&
                                                                            (callFlowJSONPicked[stepIndex].list.length > 0)
                                                                        ) &&
                                                                        (
                                                                        <div className={`w-100 d-flex align-items-center justify-content-center text-secondary p-3 ${classes.interruption}`} >
                                                                            {callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0].BRText }
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        ((callFlowJSONPicked[stepIndex].settings&&
                                                                        !isPassable) && !(callFlowJSONPicked[stepIndex].settings && callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.INTERRUPTION] && callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0].BRText))
                                                                        && 
                                                                        (
                                                                        <div className={`w-100 d-flex align-items-center justify-content-center text-secondary p-3 ${classes.interruption}`}>
                                                                        {(callFlowJSONPicked[stepIndex].settings && callFlowJSONPicked[stepIndex].settings[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.INTERRUPTION] && callFlowJSONPicked[stepIndex].settings.interruption[global.CUST_CONSTANTS.PREDEFINED_KEYS.RESPONSE_TYPE.FAILURE_RESPONSE][global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE][0].BRText?"":<span data-tip={messages.noInterruptionConfigured} data-class="tooltip_cust" data-place="top" className={classes.noInterruption}>No Interruption Is Configured</span>)}
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        isPassable&&(
                                                                        <div className={`w-100 d-flex align-items-center justify-content-center text-secondary p-3 ${classes.interruption}`}>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                {!this.state.selectedSteps.hasOwnProperty(stepIndex) && <div className={[classes.CallFlowStepIcon].join(' ')} style={{zIndex:"5"}} onClick={(e)=>e.stopPropagation()}>
                                                                    {/* Kept outside because of CSS positioning reason */}
                                                                    <Settings 
                                                                        stepKey={stepIndex}
                                                                        addBlock={(step)=>this.addBlock(stepIndex)}
                                                                        addStep={()=>this.addStep(stepIndex)}
                                                                        settings={ callFlowJSONPicked[stepIndex].settings }
                                                                        isDisabled={ (callFlowJSONPicked[stepIndex].list && (callFlowJSONPicked[stepIndex].list.length > 0) ? false : true) }
                                                                        callFlowStepCurrent= {callFlowJSONPicked[stepIndex]}
                                                                    />
                                                                </div>}
                                                            </div>
                                                            <Droppable key={ "temp_" + stepIndex } droppableId={ "temp_" + stepIndex } direction="horizontal">
                                                                {(provided, snapshot) => (
                                                                    <div 
                                                                    ref={provided.innerRef}
                                                                    onClick={(e)=> {
                                                                        e.stopPropagation();
                                                                        this.setState({selectedSteps:{},showMultiPathSettings: false})
                                                                    }}
                                                                    className={[classes.getListStyle,classes.ListStyle, classes.TempListStyle].join(' ')}
                                                                    // onMouseEnter={() => this.stepLoader("addStep_"+stepIndex)}
                                                                    // onMouseLeave={() => this.stepLoader()}
                                                                    >
                                                                        {/* { (this.state.stepLoader==="addStep_"+stepIndex) &&                                
                                                                            <div 
                                                                                className={ [classes.addStepView].join(' ') } 
                                                                                id={ "addStep_" + stepIndex }
                                                                                onClick={()=>this.addStep(stepIndex)}
                                                                                >
                                                                                Add Step
                                                                            </div>
                                                                        } */}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>                                                
                                                        {metaData.relations && Object.keys(metaData.relations).length > 0 && this.accordionViewer(stepIndex, index, metaData.relations)}
                                                    </div>
                                                )
                                            }
                                    })
                                }
                            </DragDropContext>
                            </div>
                            <div id="CallFlowTestPLanToggle" className="rightToggleTP Shrink">
                                <div className="bg-light d-flex justify-content-between">
                                    <div className="font-16 p-2 blue pl-4">
                                        Test Plan Status
                                    </div>
                                    <div className = "d-flex align-items-center">
                                        <div style={{marginRight:'10px'}}>
                                            {(Object.keys(this.props.propsRedux.testReports).length && Object.keys(this.props.propsRedux.testPlanJsons).length)  && !this.props.propsRedux.savedTestPlanData.onLoad ?
                                            <>
                                                <input 
                                                    type="checkbox"
                                                    checked={this.props.propsRedux.allTestPlans}
                                                    disabled = {!(this.props.propsRedux.error && this.props.propsRedux.error.message) && this.state.TPChannelLoading}
                                                    onChange={this.tpSwitchHandler}
                                                />
                                                <span className="font-16 p-2 blue pl-2">All Test Plans</span>
                                            </>:''}
                                        </div>
                                        <Link onClick={this.closeTPSideBar}><Icon icon="carbon:close" /></Link>
                                    </div>
                                </div>
                                {       (Object.keys(this.props.propsRedux.testReports).length && !this.props.propsRedux.testPlanLoader && !this.props.propsRedux.error) || 
                                        (Object.keys(this.props.propsRedux.testReports).length && !this.props.propsRedux.testPlanLoader && (this.props.propsRedux.error && !this.props.propsRedux.error.message)) || 
                                        (this.props.propsRedux.testPlans != undefined 
                                        && this.props.propsRedux.showTestPlans
                                        && Object.keys(this.props.propsRedux.testPlans).length > 0
                                        && this.props.propsRedux.rulesJson
                                        && Object.keys(this.props.propsRedux.rulesJson).length > 0) 
                                        ?  
                                        <TestPlanSideBar
                                            enableButton={()=>this.disableEnableRunButton()}
                                            showStatus = {this.state.runTP}
                                            changeStatus = {()=>{
                                                this.setState({runTP:true})
                                            }}
                                            show={this.closeTPSideBar}
                                            infoBar={this.onToggleInfo}
                                            execScroll={() => this.blockRefArr.forEach(el=>{
                                                el.scrollIntoView({ block: "end", inline: "nearest" })
                                            })}
                                            toggleSideBar = {this.toggleTPSideBar}
                                            title="Test Plan Information"
                                            content={this.props.propsRedux.testPlans}
                                            event_name={this.props.propsRedux.testPlanChannel && this.props.propsRedux.testPlanChannel.event_name}
                                            stopLoading = {()=>this.setState({TPChannelLoading:false})}
                                        />
                                        :
                                        this.props.propsRedux.testPlanLoader ? 
                                            <div className="ml-5 mt-20 text-danger"><LoadingIcon className={ [ "m-5" ].join(' ') } /></div>
                                            :<div className="ml-5 mt-20 text-danger">No Test Plan Found!</div>
                                }
                                <div 
                                    className={[classes.setViewTypeStyle,"badge badge-light rounded-circle shadow-new"].join(' ')}
                                    autoFocus={true}
                                    onClick={()=> {
                                        if(this.state.expandTPSidebar){
                                            this.props.setViewType(3)
                                        }
                                        else{
                                            this.openTPSidebar()
                                        }
                                    }}
                                >
                                        <Icon id="slideIcon" className="transition" icon="eva:arrow-ios-back-fill"></Icon>
                                </div>
                            </div>
                        </div>
                        {this.state.showMultiPathSettings && <div className={[classes.multiPathSettings].join(' ')} style={{zIndex:"5"}} onClick={(e)=>e.stopPropagation()}>
                            {/* Kept outside because of CSS positioning reason */}
                            <Settings 
                                type={'multipath'}
                                selectedSteps = {this.state.selectedSteps}
                                deSelectSteps = {()=>this.setState({
                                    selectedSteps: {},
                                    showMultiPathSettings: false
                                })}
                            />
                        </div>}
                </div>
            </>
        );
    }
}
/** Defining prop types requirements */
StoryFlow.propTypes = {
    // propsRedux: PropTypes.shape({
    //     : PropTypes.object.isRequired
    // }),
};
/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            isChanged: state.rules.isChanged,
            rulesJson : state.rules.rulesJson,
            callFlowJSON: state.rules.callFlow.callFlowJSON,
            callFlowResponseMappings: state.rules.callFlowResponseMappings,
            storyId: state.story.storyId,
            title: state.story.title,
            storyRuleId: state.rules.storyRuleId,
            botResponses: state.rules.botResponses,
            showUtteranceSideBar: state.rules.showUtteranceSideBar,
            showUtteranceSideBarNew: state.rules.showUtteranceSideBarNew,
            statements:state.rules.statements,
            testPlanLoader: state.rules.testPlanLoader,
            showTestPlans:state.rules.showTestPlans,
            testPlanChannel: state.rules.testPlanChannel,
            testPlans:state.rules.testPlans,
            testPlanJsons: state.rules.testPlanJsons,
            testReports: state.rules.testReports,
            pusherKeys: state.rules.pusherKeys,
            allTestPlans: state.rules.allTestPlans,
            savedTestPlanData: state.rules.savedTestPlanData,
            utterances: state.rules.sampleUtterances,
            selectedTestPlan: state && state.rules && state.rules.selectedTestPlan && Object.values(state.rules.selectedTestPlan).length ? Object.values(state.rules.selectedTestPlan)[0] : {},
            unsavedChanges:state.rules.unsavedChanges,
            isDraftInDb:state.rules.isDraftInDb,
            callFlowIsDraft:state.rules.callFlow.callFlowIsDraft,
            error:state.rules.error,
            brandId:state.story.brandId,
            // rules: state.rules,
            luisAppId:state.story.luisAppId,
            stepCounter: state.rules.stepCounter,
            stepSequence: state.rules.stepSequence,
            accordionState: state.rules.accordionState,
            untrainedLuisObjData : state.rules.untrainedLuisObj.data,
            untrainedLuisObj : state.rules.untrainedLuisObj,
            // isLuisActionsEnabled: state.rules.untrainedLuisObj.isActionEnabled,
            isIncomingDataExist: state.rules.untrainedLuisObj.isIncomingDataExist,
            hideStartTrainingButton: state.rules.hideStartTrainingButton,
            hasMachineLearnEntities: state.story.hasMachineLearnEntities

        },
        propsOwned: {...props}
    }
}
/** Exporting main component */
export default connect(mapStateToProps, { resetTestPlan,runTestPlans,setShowTestPlans,setShowUtteranceSideBar,setCallFlowJson,setRulesAndCallFlowUpdatedState, resetCallMapping, addStepCallFlow, setCallFlowMapping, resetBootStrapedCallFlow, setAlert, setViewType, setPreviousTestReports,setAllTestPlans, setSavedTestPlanData, setFromSavedTPData, getEntites, getSampleUtterances, setStepCounter, setAccordionState, setStepSequence, setResponseAction, showNodeResponseModal, setShowEntityModal ,onNewIntentSetForInfoSideBar,setSelectedBlockCallInfo , startTrainNPublish, trainNPublishProcess,deleteUntrainedLuisObjFromDb,changeActionsForUntrainedLuisState,startTrainingData,getUntrainedLuisData})(StoryFlow);

