import React from 'react';
import { connect } from "react-redux";
import { Row, Col, Container} from 'react-bootstrap';
import RulesJsonEditor from ".././RulesJsonEditor/RulesJsonEditor";
import NodeRules from ".././NodeRules/NodeRules";
import ControlBar from '../NodeRules/ControlBar/ControlBar';
import AddNode from '../NodeRules/Controls/AddNode';
import OpeningStatement from '../NodeRules/Controls/OpeningStatement';
import NoneResponse from '../NodeRules/Controls/NoneResponse';
import EvalFalse from '../NodeRules/Controls/EvalFalse';
import Save from '../NodeRules/Controls/Save/Save';
import {Upgrade} from '../NodeRules/Controls/Upgrade/Upgrade';


const DetailedView = (props) =>{
    return(
        <>
            <ControlBar
                title="Detailed View"
                rightControls = {
                    <>
                        <AddNode/>
                        <OpeningStatement/>
                        <NoneResponse/>
                        <EvalFalse/>
                        <Save/>
                        {props.propsRedux.story.hasMachineLearnEntities && <Upgrade/>}
                    </>
                }
            />                                 
            <Container fluid >
                <Row className="mt-3">
                    <Col sm={4}>
                        <RulesJsonEditor />
                    </Col>
                    <Col sm={localStorage.getItem('viewJsonChecked')==='false'?10:8}>
                        {
                            props.propsRedux.rules.rulesJson &&
                            <NodeRules />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rules: state.rules,
            story: state.story
        },
    }
}

export default connect(mapStateToProps)(DetailedView);