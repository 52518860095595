import { SET_LOADING, SET_ERROR, USER_CHANGE } from "../../actions/types";
import "../../../utils/globalVars";
import "../../../utils/globalFunctions";

const initialState = {
    loading: false,
    error: null,
    id: null,
    jwtRefreshedTime: null,
    jwtRefreshToken: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING :
            return {
                ...state,
                loading: true
            }
        case SET_ERROR :
            return {
                ...state,
                error: action.payload.error
            }
        case USER_CHANGE : 
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                loading: false
            }
    
        default:
            return state;
    }
};

export default userReducer;