import axios from "axios";
import qs from "qs";

import { axiosErrorFunction } from "../store/actions/common/commonActions";
import { params } from "../data/params";
import store from "../store/store";

import { getCookie } from "../utils/utils";
import { string } from "prop-types";

/**
 * 
 * @param {*} url Url part to hit on
 * @param {*} thenFunc function to perform on success of the axios call
 * @param {*} errorFunc 
 * @param {*} data 
 * @param {*} method 
 * @param {*} isFormData : 0 - not form data, 1 - convert to form data, 2 - already a form data
 * @param {*} urlType api : Python API, php : existing PHP url hit
 */
const axiosCall = (url, thenFunc, errorFunc = axiosErrorFunction, data={}, method='GET', isFormData=0, urlType='api') => {

    var authToken = getCookie("central_jwt_token");
    var csrftoken = null;
    var axiosReady = false;
    var state = store.getState();

    // can change it in better way
    var apiUrl = url;
    if (urlType=='api') {
        apiUrl =  params.pythonApiUrl;

    // for ai traiing api,change authToken and add ai training api url 
    // console.log("urlMarker",url);
    if(url.includes('ai-services-apis')){
            apiUrl =  params.pythonAiApiUrl;
    }else if(url.includes('new-ui-rules')){
        apiUrl = params.pythonNewUiApiUrl;
    }
    } else if (urlType == 'php') {
        apiUrl = params.phpUrlBase;
    } else {
        apiUrl = params.platformApiUrl;
    }

    if ( (url !== global.CUST_CONSTANTS.RECORD_AUDIT_URL) && (url !== global.CUST_CONSTANTS.KEEP_ALIVE_URL) && (state.rules.ongoingAxiosCalls[url])) {
        cancelAxiosRequest(url, state.rules.ongoingAxiosCalls[url]);
    }

    var cancelTokenSource = axios.CancelToken.source();
    var axiosCallObj = {
        method: method,
        url: apiUrl + url,
        data: {...data},
        headers: {
            'Access-Control-Allow-Origin': '*'
        },
        cancelToken: cancelTokenSource.token,
    }

    switch (method) {
        case 'GET':
            axiosReady = true;
            if (data && (Object.keys(data).length>0)) {
                axiosCallObj["params"] = { ...data };
            }
            break;
        case 'POST':
            if (isFormData > 0) {
                // axiosCallObj.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                axiosCallObj.headers['content-type'] = 'application/x-www-form-urlencoded; charset=utf-8';
                
                console.log("DEBUGGER::  axiosCallObj", axiosCallObj);
                var formData = null;
                if ( isFormData === 1 ) {
                    formData = new FormData();
                    for ( var key in data ) {
                        formData.append(key, data[key]);
                    }
                }
                // axiosCallObj.data = formData;
                axiosCallObj.data = qs.stringify(data);
            }
            axiosReady = true;
            break;
        /** Custome method REFRESH : if method is refresh then refreshToken is expected in the parameters */
        case 'REFRESH':
            if (state.user && state.user.jwtRefreshToken) {
                var refreshToken = state.user.jwtRefreshToken;
                axiosCallObj.headers['Authorization'] = 'Bearer ' + refreshToken;
                axiosCallObj.method = 'get';
                axiosReady = true;
            }
            break;
    
        default:
            break;
    }

    if (axiosReady) {

        dispatchTokenForOngoingRequest(url, cancelTokenSource);
        console.log("DEBUGGER::  axiosCallObj2", axiosCallObj);

        axios({...axiosCallObj})
        .then(function (response) {
            // handle success
            if (urlType=='api') {
                if (response.status===200 && response.data.success===true) {
                    thenFunc(response.data);
                } else if(response.message) {
                    console.error("Custom error message 1 :", response.message, "url:", url);
                    errorFunc(response.message)
                } else if(response.data && response.data.message) {
                    console.error("Custom error message 2 :", response.data.message, "url:", url);
                    
                    if(url.includes("callflow/acknowledge/")){
                        // old events case in generate test plan
                        errorFunc({
                            'error': "unknown",
                            'response': response
                        })
                    }else{
                        errorFunc(response.data.message)
                    }
                }
                else if(response.data && response.data.status && (response.data.status == 403 || response.status == 401)){
                    var phpUrl = params.phpUrlBase
                    window.location.href = phpUrl + "site/renderError&code=400&message=Access%20Denied";
                } else {
                    console.error("Custom error message 3 :", response, "url:", url);
                    errorFunc({
                        'error': "unknown",
                        'response': response
                    })
                }
            } else {
                thenFunc(response);
            }
        })
        .catch(function (error) {

            if (axios.isCancel(error)) {
                console.error("Custom error while cancelling the request :", error, "url:", url);
            } else {
                // handle error
                console.error("Custom error message from axios 3 :", error, "url:", url);
                /** If unauthorized */
                if (error && error.response && error.response.status && (error.response.status === 401 || error.response.status === 422)) {
                    var phpUrl = params.phpUrlBase
                    window.location.href = phpUrl + "story/admin";
                    console.error("Custom error message Unauthorized url :", url);
                } else {
                    errorFunc(error)
                }
            }
        })
        .finally(function () {
            dispatchTokenForOngoingRequest(url);
        });
    }
}

export const dispatchTokenForOngoingRequest = (url, cancelTokenSource=null) => {
    store.dispatch({
        type: "SET_ONGOING_AXIOS_REQUEST",
        payload: {
            url: url,
            cancelationSource: cancelTokenSource
        }
    });
}

export const cancelAxiosRequest = (url, cancelTokenSource) => {
    cancelTokenSource.cancel("Operation cancelled due to another same request");
}

export default axiosCall;



/**\
 * 
 * token change based on url ( baseurl/ ${ai-services-apis} / api/ )
 *   token passed to headers
 *   port number change
 *   
 */