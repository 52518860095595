import React from 'react';
import { connect } from "react-redux";
import Icon from 'react-fa';
import {setSelectedRule, setResponseAction, showResponseModal } from "../../../../store/actions/rules/rulesActions";



const OpeningStatement = (props) =>{

    const onAddOpeningStatement = () => {
        global.recordAudit("Add opening stateent clicked");
        var selectedRule = (props.propsRedux.rulesJson.metadata && props.propsRedux.rulesJson.metadata.opening_statement) ?     
            props.propsRedux.rulesJson.metadata.opening_statement: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                }
        };
        props.setSelectedRule(global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY, selectedRule, true);
        props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                nodeKey: global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: true,
            }]
        });
        props.showResponseModal(true);
    }

    return (
        props.propsRedux.rulesJson &&
        <button className="btn btn-secondary ml-2 btn-sm border bg-white blue btn-blue" onClick={ onAddOpeningStatement }>
            <Icon name="plus" className="icon-btn"/>
            Opening Statement
        </button>

    )    
}

const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rulesJson: state.rules.rulesJson,
        },
    }
}

/** Exporting main component */
export default connect(mapStateToProps, {  setSelectedRule, setResponseAction, showResponseModal })(OpeningStatement);