/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React, { setState } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Row, Col, Container, Tab, Nav } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import

/** Importing custom Components */
import keepalive from "../../assets/global-scripts/keep-alive";
import { params } from "../../data/params";

import { setCsrf, getRefreshToken, getRefreshedToken, getCentralizedRefreshedToken } from "../../store/actions/common/userActions";
import { getResponses, getRules, getIntents, getTags, getSampleUtterances, showResponseModal, setViewType ,getUntrainedLuisData ,changeActionsForUntrainedLuisState,deleteUntrainedLuisObjFromDb} from "../../store/actions/rules/rulesActions";
import { checkIsInSync} from "../../store/actions/luis-app/luisAppActions";
import { setStoryVars, setStoryTitle, setBackUrl } from "../../store/actions/common/storyActions";

import { messages } from "../../data/strings";

import RulesJsonEditor from "./RulesJsonEditor/RulesJsonEditor";
import NodeRules from "./NodeRules/NodeRules";
import Topbar from "../../containers/Navigation/Topbar/Topbar";
import NewTopbar from '../../containers/Navigation/Topbar/NewTopbar'
import SimpleView from "./SimpleView/SimpleView"
import HeaderDiv from "./NodeRules/HeaderDiv/HeaderDiv";
import Preloader from "../UI/Preloader/Preloader";
import StoryFlow from "./StoryFlow/StoryFlow";
import ViewSideBar from './SideBar/ViewSideBar/ViewSideBar'

/** Importing custom Styles */
import "./stylesToManage.css";
import classes from './Rules.module.css';
import { render } from 'react-dom';
import { getCookie } from '../../utils/utils';
import Icon from 'react-fa';
import TestReport from './TestReport/TestReport';
import DetailedView from './DetailedView/DetailedView';
import NewHeaderDiv from './NodeRules/NewHeaderDiv/NewHeaderDiv';
import ControlBar from './NodeRules/ControlBar/ControlBar';
import AddNode from './NodeRules/Controls/AddNode';
import OpeningStatement from './NodeRules/Controls/OpeningStatement';
import NoneResponse from './NodeRules/Controls/NoneResponse';
import EvalFalse from './NodeRules/Controls/EvalFalse';
import Save from './NodeRules/Controls/Save/Save';
import {LoaderOverlay, Upgrade} from './NodeRules/Controls/Upgrade/Upgrade';
import UpgradeErrorsModal from './UpgradeErrorsModal/UpgradeErrorsModal';


/** Main Components */
class Rules extends React.Component {

    constructor(props){
        super(props);
        
        var currentAccountId = getCookie("account_id");
        
        this.storyId = (this.props.propsOwned.match && this.props.propsOwned.match.params && this.props.propsOwned.match.params.storyId) ? this.props.propsOwned.match.params.storyId : 0;
        console.log('this.props.propsOwned.match',this.props.propsOwned.match, 'this.props.propsOwned.match.params',this.props.propsOwned.match.params)
        /** set refresh token to redux and also refreshes access token once */
        // this.props.getRefreshToken();
        this.props.setCsrf();
        
        this.props.getResponses(this.storyId);
        this.props.getResponses(this.storyId, true);
        this.props.getRules(this.storyId);

        this.props.getTags(currentAccountId);
        this.props.setViewType();
        this.props.setStoryVars("storyId", this.storyId);
        this.props.setStoryTitle(this.storyId);
        this.props.setBackUrl();
        // this.props.checkIsInSync(this.storyId);  
    }

    shouldComponentUpdate(nextProps, nextState){
        return true;
    }

    // componentDidUpdate(prevprops){
        
    //         const isStoryIdMatchStateChanged =  this.props.propsRedux.rules.untrainedLuisObj.isStoryIdMatch !== prevprops.propsRedux.rules.untrainedLuisObj.isStoryIdMatch   
    //         const isStoryTitleStateChanged =  this.props.propsRedux.rules.untrainedLuisObj.storyTitle !== prevprops.propsRedux.rules.untrainedLuisObj.storyTitle   
    //         const storyTitle = this.props.propsRedux.rules.untrainedLuisObj.storyTitle ? "Story: " +  this.props.propsRedux.rules.untrainedLuisObj.storyTitle : 'Prediction Report' ;

    //             if(isStoryIdMatchStateChanged || isStoryTitleStateChanged){
    //                if(this.props.propsRedux.rules.untrainedLuisObj.isStoryIdMatch === true){
                  
    //                    const infoElement = () => <div className={classes.luisWarningInfoContainer}>
    //                        <Icon name='warning' className={classes.luisWarningIcon} /> <span className={classes.luisWarningInfoLabel}>{messages.warningBeforeDeletingTrainingData}</span>
    //                    </div>

    //                    const handleConfirmAlert=()=>{
    //                     confirmAlert({
    //                         title: "LUIS App Info",message: messages.luisStoryIdMatchMessage,childrenElement:infoElement,
    //                         className:'custom-react-alert',
    //                         buttons: [
    //                             {label: "Continue", onClick: () => {}},
    //                             {label: "Disable", onClick: () => this.props.changeActionsForUntrainedLuisState(false)},
    //                             {label: "Delete Training Data", onClick: () => this.props.deleteUntrainedLuisObjFromDb(),className:classes.luisDataDeleteButton}
    //                         ],
    //                         closeOnEscape: false,
    //                         closeOnClickOutside: false,
    //                     })
    //                 }
                    
    //                 handleConfirmAlert();
    //                }

    //     /** story_id missmatch case where story title and user name is displayed in luis app info pop up */

    //                if(this.props.propsRedux.rules.untrainedLuisObj.isStoryIdMatch === false){
    //                 const handleConfirmAlert=()=>{
    //                     confirmAlert({
    //                         title: "LUIS App Info",message: messages.luisStoryIdMismatchMessage + storyTitle,
    //                         buttons: [{label: "Okay", onClick: () => {}}],
    //                         closeOnEscape: false,
    //                         closeOnClickOutside: false,
    //                     })
    //                 }
    //                 handleConfirmAlert();
    //                }
                    
    //             }

    // }

   
    render() {
        return (
            <>
                {
                    ( ( this.props.propsRedux.rules.preloaderCounts < global.CUST_CONSTANTS.PRELOADER_COUNT_NEED)  || this.props.propsRedux.rules.preloader ) &&
                    <Preloader />
                }
                <LoaderOverlay/>                
                <Container fluid>
                    <Row onClick={() => keepalive()}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12} className="fixed-header-top">
                                    {/* <Topbar title={this.props.propsRedux.story.title} backUrl={this.props.propsRedux.story.backUrl} showNotifications={false} /> */}
                                    <NewTopbar showNotifications={false}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="fixed-header">
                                                    <Row className = {[ classes.ActionRow ].join(' ')}>
                                                        <Col sm={12}>
                                                            <NewHeaderDiv title={this.props.propsRedux.story.title} backUrl={this.props.propsRedux.story.backUrl}/>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <ViewSideBar menu = {global.CUST_CONSTANTS.SIDEBAR_JSON.RULES}/>
                                                    {/* <Row className={ [ 'simple-rules-tab ml-3 justify-content-end ' , classes.TabRow ].join(' ') }>                                                    
                                                        <Col sm={8} className={ [ 'pl-0', "d-flex"].join(' ') }>
                                                            <div className={ [ "pull-right" ].join(' ') } >
                                                                <label className={ [ classes.AutosaveLabel," mr-3" ].join(' ') }  data-tip={ messages.autoSaveLabel } data-class="tooltip_cust"  data-place="top">
                                                                    <Icon name="circle" className={ [ classes.AutosaveLabelFa ].join(' ') } />  Autosave Enabled : {
                                                                        this.props.propsRedux.rules.loading ? "Autosaving" : "Autosaved"
                                                                    }
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row> */}
                                                </div>
            
                                            </Col>
                                            <Col sm={12} style={{marginTop:'175px',paddingLeft:'60px',overflow:"hidden"}}>
                                                <Tab.Content>
                                                    <Tab.Pane active = {this.props.propsRedux.rules.activeTab === 0} eventKey={0}>

                                                        <ControlBar
                                                            title="Response View"
                                                            rightControls = {
                                                                <>
                                                                    <AddNode/>
                                                                    <OpeningStatement/>
                                                                    <NoneResponse/>
                                                                    <EvalFalse/>
                                                                    <Save/>
                                                                    {this.props.propsRedux.story.hasMachineLearnEntities && <Upgrade/>}
                                                                </>
                                                            }
                                                        />
                                                        {
                                                            (
                                                                this.props.propsRedux.rules.rulesJson && 
                                                                (Object.keys(this.props.propsRedux.rules.rulesJson).length > 0 )
                                                                && (Object.keys(this.props.propsRedux.rules.botResponses).length > 0 )
                                                            )
                                                            ?
                                                            <>
                                                            <SimpleView 
                                                            rulesJson={ this.props.propsRedux.rules.rulesJson } 
                                                            botResponses = {this.props.propsRedux.rules.botResponses} 
                                                            tags = {this.props.propsRedux.rules.brandTags}/>
                                                            </>
                                                            :
                                                            <div className="text-danger" style={{marginTop:"25vh",textAlign:"center",paddingLeft:"60px"}}>
                                                                Rules json has not been configured properly
                                                            </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane active = {this.props.propsRedux.rules.activeTab === 1} eventKey={1}>
                                                        <DetailedView/>
                                                    </Tab.Pane>
                                                    <Tab.Pane active = {this.props.propsRedux.rules.activeTab === 2} eventKey={2}>
                                                        {
                                                            /** Call Flow version 1.1 and old support */
                                                            // (
                                                            //     this.props.propsRedux.rules.callFlow.callFlowJSON && 
                                                            //     ( Object.keys(this.props.propsRedux.rules.callFlow.callFlowJSON).length > 0 ) &&
                                                            //     this.props.propsRedux.rules.callFlowResponseMappings &&
                                                            //     ( Object.keys(this.props.propsRedux.rules.callFlowResponseMappings).length > 0 )
                                                            // )
                                                            // && 
                                                            <StoryFlow/>
                                                        }
                                                        {
                                                            (
                                                                this.props.propsRedux.rules.callFlow.callFlowJSON && 
                                                                (Object.keys(this.props.propsRedux.rules.callFlow.callFlowJSON).length == 0) &&
                                                                (
                                                                    (!this.props.propsRedux.rules.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ||
                                                                    (
                                                                        this.props.propsRedux.rules.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] &&
                                                                        ( Object.keys(this.props.propsRedux.rules.callFlow.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]).length == 0 )
                                                                    )
                                                                )
                                                            ) &&
                                                            <div className="text-danger text-center" style={{marginTop:'30vh'}}>
                                                                Call Flow is not yet configured. Please add node to start using call flow.

                                                            </div>
                                                        }
                                                    </Tab.Pane>
                                                    <Tab.Pane active = {this.props.propsRedux.rules.activeTab === 3} eventKey={3}>
                                                    {
                                                            (Object.keys(this.props.propsRedux.rules.testReports).length && !this.props.propsRedux.rules.testPlanLoader && !this.props.propsRedux.rules.error) || 
                                                            (Object.keys(this.props.propsRedux.rules.testReports).length && !this.props.propsRedux.rules.testPlanLoader && (this.props.propsRedux.rules.error && !this.props.propsRedux.rules.error.message)) ||
                                                            (this.props.propsRedux.rules.testPlans != null 
                                                            && Object.keys(this.props.propsRedux.rules.testPlans).length > 0  
                                                            && this.props.propsRedux.rules.rulesJson
                                                            && Object.keys(this.props.propsRedux.rules.rulesJson).length > 0)  
                                                            ? 
                                                            <TestReport
                                                                content={this.props.propsRedux.rules.testPlans}
                                                                event_name={this.props.propsRedux.rules.testPlanChannel && this.props.propsRedux.rules.testPlanChannel.event_name}
                                                            />
                                                            :
                                                            <div className="text-danger" style={{marginTop:"30vh",textAlign:"center",paddingLeft:"60px"}}>No Report Found!</div>
                                                            
                                                    }

                                                    </Tab.Pane>
                                                </Tab.Content>
                                                {this.props.propsRedux.story.isErrorModalOpen.isOpen && <UpgradeErrorsModal isOpen = {this.props.propsRedux.story.isErrorModalOpen.isOpen} errors = {this.props.propsRedux.story.isErrorModalOpen.errors}/>}
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

/** Defining prop types requirements */
Rules.propTypes = {
    propsRedux: PropTypes.shape({
        rules: PropTypes.object.isRequired,
        story: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            rules: state.rules,
            story: state.story,
            user: state.user,
        },
        propsOwned: { ...props }
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { setCsrf, getRefreshToken, getRefreshedToken, getResponses,getSampleUtterances, getRules, getIntents, getTags, setStoryVars, setStoryTitle, setViewType ,setBackUrl, showResponseModal, checkIsInSync ,getUntrainedLuisData,changeActionsForUntrainedLuisState,deleteUntrainedLuisObjFromDb,getCentralizedRefreshedToken})(Rules);