import React from 'react';
import Layout from './hoc/Layout/Layout';

function App() {
	return (
		<Layout />
	);
}

export default App;
