import axiosCall from "../../../utils/axiosCall";
import { axiosErrorFunction, setAlert, setError } from "../common/commonActions";
import { APP_SYNCING, FETCH_PERCENTAGE, INCREMENT_ONGOING_STEP, INCREMENT_SYNCING_STEP,IS_IN_SYNC,RESET_LUIS_APP_COUNTS, SET_APP_DB_ID, SET_APP_ID, SET_APP_NAME, SET_MODULE_SESSION_ID, SET_TOTAL_COUNTS, SET_TOTAL_DATA_COUNT, SYNCING_ERROR_OCCURED } from "../types";
import {IS_APP_IN_SYNC} from "../types";
import getApiUrl from "../../../api-urls/apiUrlsUtils";
import { base, apiUrls } from "../../../api-urls/luis-app-apis";
import { syncingStepsDict, importableProperties } from "../../../utils/luisAppUtils";
import { messages } from "../../../data/strings";
import { getSampleUtterances, getIntents, getEntites,getUntrainedLuisData} from "../rules/rulesActions";


export const setLuisAppId = (appId) => dispatch => {
    dispatch({
        type: SET_APP_ID,
        payload: appId
    });
}

export const setLuisAppDbId = (luisAppDbId) => dispatch => {
    dispatch({
        type: SET_APP_DB_ID,
        payload: luisAppDbId
    })
}

export const setLuisAppName = (name) => dispatch => {
    dispatch({
        type: SET_APP_NAME,
        payload: name
    })
}

export const resetCounts = () => dispatch => {
    dispatch({
        type: RESET_LUIS_APP_COUNTS,
        payload: null
    });
    window.stopFromUnload = false;
}


export const endSyncProcess = (appDbId, calledOnFailure = true, storyId) => dispatch => {
    var url = getApiUrl(base, apiUrls.SYNC_PROCESS_END, {luisAppDbId: appDbId});
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        if (response.data) {
            dispatch(resetCounts());
            if (!calledOnFailure) {
                dispatch(setAlert("App Synced successfully!", 1));
                dispatch(getSampleUtterances(appDbId));
                dispatch(getIntents(appDbId));
                dispatch(getEntites(appDbId));
                dispatch(setIsAppInSync(true))
            }
        } else {
            dispatch({
                type: SYNCING_ERROR_OCCURED,
                payload: "Error in ending process"
            });
            dispatch(axiosErrorFunction("Error in ending process"));
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}

export const syncingErrorFunction = (errorMessage, appDbId=null) => async dispatch => {
    dispatch({
        type: SYNCING_ERROR_OCCURED,
        payload: errorMessage
    });
    global.recordAudit(errorMessage);
    if(errorMessage === 'AI app does not exist'){
        dispatch(setAlert(errorMessage, 0));
    }else{
        dispatch(endSyncProcess(appDbId, true));
        dispatch(setAlert('Something went wrong', 0));
    }
    // dispatch(axiosErrorFunction(errorMessage));
    // dispatch(resetCounts());
}


export const incrementSyncingSteps = () => dispatch => {
    dispatch({
        type:FETCH_PERCENTAGE,payload:0
    })
    dispatch({
        type: INCREMENT_SYNCING_STEP,
        payload: null
    });
}

export const exportAppJson = (appId) => dispatch => {
    dispatch({
        type: APP_SYNCING,
        payload: true
    });
    window.stopFromUnload = true;
    var url = getApiUrl(base, apiUrls.EXPORT_JSON, {luisAppId: appId});
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        if (response.data) {
            let error = false;
            let appDbId = null;
            if (response.data.luisAppDbId) {
                appDbId = response.data.luisAppDbId;
                dispatch(setLuisAppDbId(response.data.luisAppDbId));
            } else {
                error = "DB ID not present";
            }
            if (response.data.moduleSessionId) {
                dispatch({
                    type: SET_MODULE_SESSION_ID,
                    payload: response.data.moduleSessionId
                })
            } else {
                error = "Session Id not present";
            }
            if ("intentsCount" in response.data) {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.INTENTS,
                        value: response.data.intentsCount
                    }
                })
            } else {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.INTENTS,
                        value: 0
                    }
                })
            }
            if ("entitiesCount" in response.data) {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.ENTITIES,
                        value: response.data.entitiesCount
                    }
                })
            } else {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.ENTITIES,
                        value: 0
                    }
                })
            }
            if ("utterancesCount" in response.data) {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.UTTERANCES,
                        value: response.data.utterancesCount
                    }
                })
            } else {
                dispatch({
                    type: SET_TOTAL_COUNTS,
                    payload: {
                        key: importableProperties.UTTERANCES,
                        value: 0
                    }
                })
            }
            if(response.data.error){
                error = 'AI app does not exist'
            }
            if (!error) {
                dispatch(incrementSyncingSteps());
            } else {
                dispatch(syncingErrorFunction(error, appDbId));
            }
        }
    };

    const axiosErrorFunction = (errorMsg) => {
        dispatch(syncingErrorFunction(errorMsg, appId));
        // dispatch(setAlert(errorMsg, 0));
    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}

export const makeOldInactive = (appDbId, moduleSessionId) => dispatch => {
    var url = getApiUrl(base, apiUrls.MAKE_OLD_INACTIVE, {luisAppDbId: appDbId, moduleSessionId: moduleSessionId});
    const axiosSuccessFunction = (response) => {
        /** Handle success case */
        if (response.data) {
            dispatch(incrementSyncingSteps());
        } else {
            dispatch(syncingErrorFunction("Make old inactive failed!", appDbId));
        }
    };

    const axiosErrorFunction = (errorMsg) => {
        dispatch(syncingErrorFunction(errorMsg, appDbId));
    }
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}

export const exportUtterances = (appDbId, moduleSessionId,  offset=0, totalPropCount) => dispatch => {
    dispatch({type:FETCH_PERCENTAGE,payload:0})
    if(totalPropCount){
        var url = getApiUrl(base, apiUrls.EXPORT_UTTERANCES, {luisAppDbId: appDbId, moduleSessionId: moduleSessionId, offset: offset});
        const axiosSuccessFunction = (response) => {
            if (response.data) {
                dispatch({
                    type: INCREMENT_ONGOING_STEP,
                    payload: syncingStepsDict.EXPORT_UTTERANCES.stepNo
                });
                let newOffset = offset + 500;
                if (totalPropCount > newOffset) {
                    dispatch(exportUtterances(appDbId, moduleSessionId, newOffset, totalPropCount));
                }else {
                    
                    dispatch(incrementSyncingSteps())
                }
            } else {
                dispatch(syncingErrorFunction("exporting utterance Failed"));
            }
        };
    
        const axiosErrorFunction = (errorMsg) => {
            dispatch(syncingErrorFunction(errorMsg, appDbId));
        }
        
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    }else{
        dispatch(incrementSyncingSteps())
    }
}

/**
 * 
 * @param {property-enum} property INTENTS, ENTITIES, UTTERANCES
 * @param {*} appDbId 
 * @param {*} moduleSessionId 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
export const importProp = (property, appDbId, moduleSessionId, offset=0, totalPropCount) => dispatch => {

    if(totalPropCount){
        let key = "IMPORT_" + property;
        if ( (apiUrls[key] == undefined) || (syncingStepsDict[key] == undefined) ) {
            /** Invalid key */
            console.error("Invalid Property ", property);
            console.error("Invalid apiUrls ", apiUrls);
            console.error("Invalid syncingStepsDict ", syncingStepsDict);
            return null;
        }
    
        var url = getApiUrl(base, apiUrls[key], {luisAppDbId: appDbId, moduleSessionId: moduleSessionId, offset: offset});
        const axiosSuccessFunction = (response) => {
            if (response.data) {
                dispatch({
                    type: INCREMENT_ONGOING_STEP,
                    payload: syncingStepsDict[key].stepNo
                });
                if (response.data.stopIndex && (totalPropCount > response.data.stopIndex+1)) {
                    var newOffset = response.data.stopIndex+1;
                    dispatch(importProp(property, appDbId, moduleSessionId, newOffset, totalPropCount));
                
                }
                else {
                    dispatch(incrementSyncingSteps())
                    if (property==="INTENTS"){
                        dispatch({type:FETCH_PERCENTAGE,payload:5})
                    }
                    else if(property==="ENTITIES"){
                        dispatch({type:FETCH_PERCENTAGE,payload:10})  
                    }
                    
                }
            } else {
                dispatch(syncingErrorFunction("importing "+property+" Failed"));
            }
        };
    
        const axiosErrorFunction = (errorMsg) => {
            dispatch(syncingErrorFunction(errorMsg, appDbId));
        }
        
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    }else{
        dispatch(incrementSyncingSteps())
    }
}

/**
 * 
 * @param {property-enum} property INTENTS, ENTITIES, UTTERANCES
 * @param {*} appDbId 
 * @param {*} moduleSessionId 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
export const importUtterances = (property, appDbId, moduleSessionId, offset=0, totalPropCount) => dispatch => {
    if(totalPropCount){
        let key = "IMPORT_" + importableProperties.UTTERANCES;
        if ( (apiUrls[key] == undefined) || (syncingStepsDict[key] == undefined) ) {
            /** Invalid key */
            console.error("Invalid Property ", property);
            console.error("Invalid apiUrls ", apiUrls);
            console.error("Invalid syncingStepsDict ", syncingStepsDict);
            return null;
        }
    
        var url = getApiUrl(base, apiUrls[key], {luisAppDbId: appDbId, moduleSessionId: moduleSessionId, offset: offset});
        const axiosSuccessFunction = (response) => {
            if (response.data) {
                dispatch({
                    type: INCREMENT_ONGOING_STEP,
                    payload: syncingStepsDict[key].stepNo
                });
                if (response.data.stopIndex && (totalPropCount > response.data.stopIndex+1)) {
                    var newOffset = response.data.stopIndex+1;
                    dispatch({type:FETCH_PERCENTAGE,payload:parseInt((newOffset/totalPropCount)*90)+10})
                    
                    dispatch(importUtterances(property, appDbId, moduleSessionId, newOffset, totalPropCount));
                }else {
                    dispatch(incrementSyncingSteps())
                    dispatch({type:FETCH_PERCENTAGE,payload:100})
                }
            } else {
                dispatch(syncingErrorFunction("importing "+property+" Failed"));
            }
        };
    
        const axiosErrorFunction = (errorMsg) => {
            dispatch(syncingErrorFunction(errorMsg, appDbId));
        }
        
        axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
    }else{
        dispatch(incrementSyncingSteps())
    }
}

export const incrementOnGoingStep = (stepNo) => dispatch => {
    dispatch({
        type: INCREMENT_ONGOING_STEP,
        payload: stepNo
    });
}


/**
 * 
 * @param {*} appDbId 
 * @returns 
 */
 export const checkIsInSync = (storyId) => dispatch => {
     
    var url = getApiUrl(base, apiUrls.CHECK_IN_SYNC, {storyId: storyId});
    const axiosSuccessFunction = (response) => {
        if (response.data) {
            dispatch({
                type: IS_IN_SYNC,
                payload: response.data.inSync
            });
            if (response.data.luisAppDbId) {
                // dispatch(setLuisAppDbId(response.data.luisAppDbId));
                // dispatch(setLuisAppName(response.data.luisAppName));
               
            }
            if (!response.data.inSync) {
                // dispatch(setAlert(messages.luisAppNotInSync, 0, false));
                // dispatch(setIsAppInSync(response.data.inSync)) // will always get the latest data - on load 
            }
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction);
}

export const updateFetchPercentage = (value) => dispatch => {
    dispatch({
        type:FETCH_PERCENTAGE,
        payload:value
    })

}

export const setIsAppInSync = (isAppInSync) => dispatch => {
    dispatch({
        type:IS_APP_IN_SYNC,
        payload:isAppInSync
    })
}

export const setTotalDataCount = ({key,value}) => dispatch => {
    dispatch({
        type : SET_TOTAL_DATA_COUNT,
        payload :{key,value}
    })
}