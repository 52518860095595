/*
 * Component Description : 
 * Author: Meghana Aggarwal
 */
/** Importing pre defined Libraries/Components */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import SearchableSelect from "react-select";
import Icon from 'react-fa';
import ReactDiffViewer from "react-diff-viewer";
import { confirmAlert } from 'react-confirm-alert';

/** Importing custom Components */
import LoadingIcon from "../../../UI/LoadingIcon/LoadingIcon";
import AlertDiv from "../../../UI/AlertDiv/AlertDiv";
import IntentModal from '../../IntentModal/IntentModal';
import UtteranceModal from '../../UtteranceModal/UtteranceModal';
import Modal from "../../../UI/Modal/Modal";
import { getPhpUrl } from "../../../../utils/utils";
import { messages } from "../../../../data/strings";

import { saveRulesToDb, setSelectedRule, setResponseAction, showResponseModal, getRules, setRules, resetBootStrapedCallFlow, setWarnOverwriting, setCallFlowJson, setCallFlowMapping, setStepSequence, setAccordionState } from "../../../../store/actions/rules/rulesActions";
import { setAlert } from "../../../../store/actions/common/commonActions";
import {  getGlobalRules  } from "../../../../utils/sequencingUtils";

/** Importing custom Styles */
import classes from './HeaderDiv.module.css';
import { syncCallFlowWithRuleResponses } from '../../../../utils/callFlowUtils';
import SyncPopUp from "../../SyncPopUp/SyncPopUp";
import InfoSideBar from '../../SideBar/InfoSideBar/InfoSideBar';

/** Main Components */
class HeaderDiv extends React.Component {
    /** Lifecycle methods start */
    constructor (props) {
        super(props);
        ReactTooltip.rebuild();
        this.saveAsOptions = [
            {
                label: 'Production Json', 
                value: 1
            },
            {
                label: 'Staging Json' ,
                value: 0
            },
            {
                label: 'Restore Point' ,
                value: 2
            }
        ];

        this.state = {
            saveModalShow: false,
            saveAsSelected: this.saveAsOptions[0],
            jsonName: null,
            recordingType: 4,
            testStagingModalShow: false,
            pathToPractice: this.testTypeOptionSelect(true, false),
            /** State = 0: none, 1: to show comparision, 2: compared/comparing (diff view open) */
            cf_state: 0,
            cf_createCallFlowRules: false,
            cf_callFlowRulesJson: "",
            cf_oldRulesJson: "",
            cf_callFlowResponseJson: "",
            cf_oldResponseJson: "",
            cf_fullRulesJson: {},
            /** list to check if same response exists in different steps while creating rules */
            nonUniqueResponsesList: [],
        }

        this.confirmResetAlert = {
            title: 'Reset Call Flow',
            message: 'This will reset your call flow to default call flow made at bootstrapping level and you will loose your current changes in the call flow. This is not going to affect rules directly.',
            buttons: [
              {
                label: 'Sync Call Flow',
                className: [ "", "" ].join(' '),
                onClick: this.syncCallFlow
              },
              {
                label: 'Start Over',
                className: [ classes.BtnStartOver, "btn-danger" ].join(' '),
                onClick: this.resetCallFlow
              },
              {
                label: 'Cancel',
                onClick: () => {}
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ onClose }) => <SyncPopUp onClose={ onClose } syncCallFlow={ this.syncCallFlow } resetCallFlow={ this.resetCallFlow } />,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
    }
    /** Lifecycle methods end */

    saveRules = () => {
        global.recordAudit("Save button clicked on header");
        this.setState({
            saveModalShow: true,
        });
    }

    onDismissAlert = () => {
        this.props.setAlert(null);
    }

    showJsonsAddButtons = (this.props.propsRedux.rulesJson) ? true : false;

    onAddNode = () => {
        global.recordAudit("Add node clicked");
        this.props.showResponseModal(true, true);
    }

    onAddOpeningStatement = () => {
        global.recordAudit("Add opening stateent clicked");
        var selectedRule = (this.props.propsRedux.rulesJson.metadata && this.props.propsRedux.rulesJson.metadata.opening_statement) ?     
            this.props.propsRedux.rulesJson.metadata.opening_statement: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                }
        };
        this.props.setSelectedRule(global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY, selectedRule, true);
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                nodeKey: global.CUST_CONSTANTS.OPENING_STATEMENT_NODEKEY,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: true,
            }]
        });
        this.props.showResponseModal(true);
    }

    onAddNoneResponse = () => {
        global.recordAudit("Add None Response clicked");

        var selectedRule = (this.props.propsRedux.rulesJson.ruleResponses && this.props.propsRedux.rulesJson.ruleResponses.None) 
            ?  this.props.propsRedux.rulesJson.ruleResponses.None 
            :   {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                        [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                    }
                };
        this.props.setSelectedRule(global.CUST_CONSTANTS.STORY_NONE_RESPONSE, selectedRule);
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                ruleKey: "generic",
                evalKey: "evalTrue",
                nodeKey: global.CUST_CONSTANTS.STORY_NONE_RESPONSE,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: false,
            }]
        });
        this.props.showResponseModal(true);
    }

    onAddEvalFalseResponse = () => {
        global.recordAudit("Add Eval False Response clicked");
        
        var selectedRule = (this.props.propsRedux.rulesJson.metadata && this.props.propsRedux.rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE]) ?     
            this.props.propsRedux.rulesJson.metadata[global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE]: {
                [global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE]: {
                    [global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE]: []
                }
        };
        
        this.props.setSelectedRule(global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE, selectedRule, true);
        this.props.setResponseAction({
            mode: global.CUST_CONSTANTS.CRUD_MODE.ADD,
            isSimpleView: false,
            selectedId: -1,
            referencePaths: [{
                nodeKey: global.CUST_CONSTANTS.STORY_EVAL_FALSE_RESPONSE,
                responseKey: global.CUST_CONSTANTS.DEFAULT_KEYS.RESPONSE_TYPE,
                iterationKey: global.CUST_CONSTANTS.DEFAULT_KEYS.ITERATION_TYPE,
                isMetadata: true,
            }]
        });

        this.props.showResponseModal(true);
    }

    onSaveAsOptionChange = (opt) => {
        global.recordAudit("Saveas option changed to " + JSON.stringify(opt));
        this.setState({
            jsonName: null,
            saveAsSelected: opt,
        });
    }

    testTypeOptionSelect = (isChat=true, setState=true) => {
        var parameters = {
            "story_id": this.props.propsRedux.storyId,
            "source": "home",
            "recordingType": 4,
            "assignmentId": 0,
            "isStaging": 1,

        }
        if (isChat) {
            parameters["storyType"] = 3; 
        }
        var link = getPhpUrl("video/create", parameters);
        if (setState) {
            this.setState({pathToPractice: link});
        }
        return link;
    }

    onTestModalHide = () => {
        this.testTypeOptionSelect();
        this.setState({testStagingModalShow:false});
    }

    onSaveAsModalHide = () => {
        global.recordAudit("Saveas modal closed");
        this.setState({
            jsonName: null,
            saveAsSelected: this.saveAsOptions[0],
            saveModalShow: false,
            cf_state: 0,
            cf_createCallFlowRules: false,
            cf_callFlowRulesJson: "",
            cf_oldRulesJson: "",
            cf_callFlowResponseJson: "",
            cf_oldResponseJson: "",
        });
    }

    saveRulesFinal = (forceSave=0) => {
        global.recordAudit("Final Save Rules button clicked with forceSave as " + forceSave);
        this.props.saveRulesToDb(false, forceSave, this.state.saveAsSelected.value, this.state.jsonName);
        this.onSaveAsModalHide();
    }

    /** New functions to create call flow json */

    onCallFlowRulesCheck = (e) => {
        if (e.target.checked) {
            this.setState({
                cf_createCallFlowRules: true,
                cf_state: 1,
            });
        } else {
            this.setState({
                cf_createCallFlowRules: false,
                cf_state: 0,
            });
        }
    }

    saveRulesCreatingGlobal = (forceSave=0) => {
        if (this.state.cf_state == 1) {
            global.recordAudit("Save rules with call flow rules state 1 : forceSave as " + forceSave);
            /** Call Flow version 1.1 and old support */
            let callFlowPicked = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
            let {newRulesJson, nonUniqueResponsesList} =   getGlobalRules({callFlowStepsObj: callFlowPicked, rulesJson: this.props.propsRedux.rulesJson, currentCallFlowMapping: this.props.propsRedux.callFlowMapping});
            this.setState({
                cf_state: 2,
                cf_callFlowRulesJson: JSON.stringify(newRulesJson.rule, null, 2),
                cf_oldRulesJson: JSON.stringify(this.props.propsRedux.rulesJson.rule, null, 2),
                cf_callFlowResponseJson: JSON.stringify(newRulesJson.ruleResponses, null, 2),
                cf_oldResponseJson: JSON.stringify(this.props.propsRedux.rulesJson.ruleResponses, null, 2),
                cf_fullRulesJson: newRulesJson,
                nonUniqueResponsesList: nonUniqueResponsesList,
            });
            // this.onSaveAsModalHide();
        } else if (this.state.cf_state == 2) {
            global.recordAudit("Save rules with call flow rules state 2 : forceSave as " + forceSave);
            this.props.setRules(this.state.cf_fullRulesJson);
            this.props.setWarnOverwriting(false);
            this.saveRulesFinal(forceSave);
        } else {
            global.recordAudit("Save rules without call flow rules : forceSave as " + forceSave);
            this.saveRulesFinal(forceSave);
        }
    }

    resetCallFlow = () => {
        global.recordAudit("Reset call flow clicked for rule id : " + this.props.propsRedux.storyRuleId);
        this.props.resetBootStrapedCallFlow(this.props.propsRedux.storyId, this.props.propsRedux.storyRuleId);
    }

    syncCallFlow = () => {
        global.recordAudit("Sync call flow clicked for rule id : " + this.props.propsRedux.storyRuleId);
        let { newCallFlowJson, newCallFlowMapping, isChanged, stepSequence, accordionStates } = syncCallFlowWithRuleResponses({rulesJson: this.props.propsRedux.rulesJson, callFlowJson: this.props.propsRedux.callFlowJSON, botResponses: this.props.propsRedux.botResponses, stepSequence: this.props.propsRedux.stepSequence});
        this.props.setStepSequence(stepSequence);
        this.props.setCallFlowJson(newCallFlowJson);
        this.props.setCallFlowMapping(newCallFlowMapping);
        this.props.setAccordionState(accordionStates, true)
    }

    render() {
        const globalRulesPresent = (this.props.propsRedux.rulesJson && this.props.propsRedux.rulesJson.rule && this.props.propsRedux.rulesJson.ruleResponses && (this.props.propsRedux.rulesJson.rule[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE] || this.props.propsRedux.rulesJson.ruleResponses[global.CUST_CONSTANTS.PREDEFINED_KEYS.GLOBAL_RULE]));
        const modalButtons = [
            {
                type: 'save',
                text: ((this.state.cf_state == 1) ? "Compare" : "Save"),
                variant: 'primary',
                onClick: () => this.saveRulesCreatingGlobal(),
                className: 'gl-btnPrimaryCust',
                isDisabled: ( ((this.state.saveAsSelected.value < 2) || ((this.state.saveAsSelected.value == 2) && (this.state.jsonName != null ))) ) ? false : true,
            },
            {
                type: 'ignoresave',
                text: 'Ignore and Save',
                variant: 'danger',
                onClick: () => this.saveRulesCreatingGlobal(1),
                className: [  classes.HeaderBtn, "btn", "btn-danger" ].join(' '),
                isDisabled: ( 
                        (this.state.cf_state != 1) &&
                        (
                            (this.state.saveAsSelected.value < 2) || 
                            (
                                (this.state.saveAsSelected.value == 2) && 
                                (this.state.jsonName != null )
                            )
                        ) 
                    ) ? false : true,
                otherProps: {
                    "data-class": "tooltip_cust",
                    "data-multiline": "true",
                    "data-tip": "This will forcefully save the rules json regardless it is corrupted or not and will reflect the same in practice sessions too."
                } 
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: this.onSaveAsModalHide
            }
        ];

        const testStagingModalButtons = [
            {
                type: 'htmlLink',
                text: 'Test',
                variant: 'primary',
                otherProps: {
                    href: this.state.pathToPractice,
                    target: "_blank",
                },
                className: [ classes.TestLink, "btn", "btn-primary", "assign-button" ].join(' '),
                isDisabled: ( ((this.state.saveAsSelected.value < 2) || ((this.state.saveAsSelected.value == 2) && (this.state.jsonName != null ))) ) ? false : true,
            },
            {
                type: 'close',
                text: 'Close',
                variant: 'secondary',
                onClick: () => { this.setState({testStagingModalShow:false}) },
            }
        ];

        let isCFCheckboxDisabled = true;
        let cfjson = (this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW]) ? this.props.propsRedux.callFlowJSON[global.CUST_CONSTANTS.PREDEFINED_KEYS.CALL_FLOW.STEPS_INSIDE_CALLFLOW] : this.props.propsRedux.callFlowJSON;
        if (cfjson && ( Object.keys(cfjson).length > 1 )) {
            let stepKeysTemp = Object.keys(cfjson);
            for (let indexTemp = 0; indexTemp < stepKeysTemp.length; indexTemp++) {
                const step = stepKeysTemp[indexTemp];
                if (cfjson[step] && cfjson[step].list && Array.isArray(cfjson[step].list) && (cfjson[step].list.length > 0)) {
                    isCFCheckboxDisabled = false;
                    break;
                }
            }
        }

        return (
            <div className={ [ classes.HeaderDiv ].join(' ') } >
                <div sm={ 12 } className={ [ 'p-0', classes.CornerDiv ].join(' ') } >
                    <div className={ [ classes.ControlGrp, classes.InJsonActionGrp, classes.JsonHistory ].join(' ') }>

                        <Icon 
                            name="refresh" 
                            className={ [ classes.SyncIcon ].join(' ') } 
                            data-tip={ messages.syncCFRules } data-class="tooltip_cust"  data-place="top" 
                            onClick={ () => {confirmAlert(this.confirmResetAlert)} }
                        />
                        {
                            ( this.props.propsRedux.selectedJson ) &&
                            <SearchableSelect 
                                name="viewJsonType" 
                                value={ this.props.propsRedux.selectedJson || '' }
                                options={ this.props.propsRedux.previousJsons }
                                className={ [ classes.JsonSelect ].join(' ') } 
                                onChange={ (opt) => this.props.getRules( this.props.propsRedux.storyId, opt.value) }
                            />
                        }
                        {
                            ( this.props.propsRedux.selectedJson ) &&
                            ( this.props.propsRedux.selectedJson.label === global.CUST_CONSTANTS.JSON_TYPE.STAGING.label ) &&
                            <button onClick={ ()=>{ this.setState({testStagingModalShow:true}) } } className={ [ classes.TestLink, "btn", "btn-primary", "assign-button" ].join(' ')}>
                                <Icon name="external-link" className={ [ '' ].join(' ') } />
                                Test Staging Json
                            </button>
                        }
                        <Modal
                            show={ this.state.testStagingModalShow }
                            onShow={ () => {} }
                            title={ "Test Staging Json" }
                            onHide={ this.onTestModalHide }
                            footerComponents={ testStagingModalButtons }
                        >
                            <div>
                                <h5>Which Type of practice session you want to pursue in test ?</h5>
                                <div>
                                    <input type="radio" id="chat" name="testStagingJsonType" value="3" defaultChecked ={ true } onClick={ () => this.testTypeOptionSelect(true) } /> Chat
                                    &nbsp;&nbsp;
                                    <input type="radio" id="voice" name="testStagingJsonType" value="4" onClick={ () => this.testTypeOptionSelect(false) } /> Voice
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div className={ [ classes.ControlGrp, classes.InJsonActionGrp ].join(' ') }>
                        {
                            this.props.propsRedux.alert.message && <AlertDiv message={ this.props.propsRedux.alert.message } type={ this.props.propsRedux.alert.type } onHide={ () => this.onDismissAlert() } fadeout={ this.props.propsRedux.alert.fadeout } />
                        }
                        {
                            this.props.propsRedux.loading && 
                            <AlertDiv message={ messages.autoSavingAlertMessage } type={ 2 } onHide={ () => this.onDismissAlert() } fadeout={ false } />
                        }
                        {
                            this.props.propsRedux.rulesJson &&
                            <button className={ [ classes.HeaderBtn, "m-0", "btn", "btn-primary", "assign-button" ].join(' ') } onClick={ this.onAddNode }>
                                <Icon name="plus-square" className={ [ classes.IconBtn ].join(' ') } />
                                Node
                            </button>
                        }
                        {
                            this.props.propsRedux.rulesJson &&
                            <button className={ [ classes.HeaderBtn, "btn", "btn-primary", "assign-button" ].join(' ') } onClick={ this.onAddOpeningStatement }>
                                <Icon name="plus-square" className={ [ classes.IconBtn ].join(' ') } />
                                Opening Statement
                            </button>
                        }
                        {
                            this.props.propsRedux.rulesJson &&
                            <button className={ [ classes.HeaderBtn, "btn", "btn-primary", "assign-button" ].join(' ') } onClick={ this.onAddNoneResponse }>
                                <Icon name="plus-square" className={ [ classes.IconBtn ].join(' ') } />
                                None Response
                            </button>
                        }
                                            {
                            this.props.propsRedux.rulesJson &&
                            <button className={ [ classes.HeaderBtn, "btn", "btn-primary", "assign-button" ].join(' ') } onClick={ this.onAddEvalFalseResponse }>
                                <Icon name="cog" className={ [ classes.IconBtn ].join(' ') } />
                                Eval False
                            </button>
                        }
                    </div>
                    <div className={ [ classes.ControlGrp, classes.JsonActionGrp ].join(' ') }>
                        {
                            this.props.propsRedux.loading && <LoadingIcon className={ [ classes.Icon ].join(' ') } />
                        }
                        {
                            (
                                this.props.propsRedux.isDraftInDb || 
                                this.props.propsRedux.isCallFlowDraftInDb || 
                                this.props.propsRedux.unsavedChanges
                            ) && 
                            <Icon 
                                name="warning" 
                                className={ [ classes.isDraftIcon, classes.Icon, (this.props.propsRedux.unsavedChanges) ? classes.UnsavedChanges : "" ].join(' ') } 
                                data-tip={ (this.props.propsRedux.unsavedChanges) ? messages.unsavedChanges : messages.isDraftIcon } 
                                data-class="tooltip_cust"  
                                data-place="top" />
                        }
                        <button className={ [ 'mr-0', classes.HeaderBtn, "btn", "btn-primary", "assign-button" ].join(' ') } onClick={ () => this.saveRules() }>
                            Save As
                        </button>
                    </div>
                </div>
                
                {
                    this.props.propsRedux.intents != undefined 
                    && Object.keys(this.props.propsRedux.intents).length > 0  
                    && this.props.propsRedux.rulesJson
                    && Object.keys(this.props.propsRedux.rulesJson).length > 0  
                    && <IntentModal
                        show={this.props.propsRedux.showIntentModal}
                        onShow={() => { }}
                        onHide={() => { }}
                        title="Intent Name"
                    />
                }
                {
                    this.props.propsRedux.utterances != undefined 
                    && Object.keys(this.props.propsRedux.utterances).length > 0  
                    && this.props.propsRedux.rulesJson
                    && Object.keys(this.props.propsRedux.rulesJson).length > 0  
                    && <UtteranceModal
                        show={this.props.propsRedux.showUtteranceModal}
                        onShow={() => { }}
                        onHide={() => { }}
                        title="Utterance Name"
                    />
                }

                {/* { not sure why it is mounted here, looks redundant
                    this.props.propsRedux.utterances != undefined 
                    && Object.keys(this.props.propsRedux.utterances).length > 0  
                    && this.props.propsRedux.rulesJson
                    && Object.keys(this.props.propsRedux.rulesJson).length > 0  
                    &&  
                    <InfoSideBar
                        show={this.props.propsRedux.showUtteranceSideBar}
                        title="Call Flow Information"
                        content={this.props.propsRedux.statements}
                    />
                } */}

                <Modal
                    show={ this.state.saveModalShow }
                    onShow={ () => {} }
                    onHide={ this.onSaveAsModalHide }
                    title="Save"
                    footerComponents={ modalButtons }
                    className={ [ (this.state.cf_state == 2 ) ? "modal-full" : "" ].join(' ') }
                >
                    {
                        (this.state.cf_state < 2) &&
                        <div>
                            In which form you want to save the json?
                            <SearchableSelect 
                                name="saveAs" 
                                defaultValue={ this.saveAsOptions[0] }
                                options={ [...this.saveAsOptions] }
                                onChange={ (opt) => this.onSaveAsOptionChange(opt) }
                            />
                            <div className={ [ 'mt-10' ].join(' ') }>
                                <label>
                                    Friendly Name : 
                                </label>
                                <input 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    value={ this.state.jsonName || "" }
                                    className={ [ "form-control" ].join(' ') }
                                    readOnly={ ( this.state.saveAsSelected.value != 2) } 
                                    disabled={ ( this.state.saveAsSelected.value != 2) } 
                                    onChange={ (e) => { this.setState({ jsonName: e.target.value }) } } 
                                />
                            </div>
                            <div>
                                <input type="checkbox" onClick={ (e) => this.onCallFlowRulesCheck(e) } disabled={ isCFCheckboxDisabled } />
                                &nbsp; { (globalRulesPresent) ? "Overwrite" : "Create" } the Rules according to the Call Flow ?
                            </div>
    
                        </div>
                    }
                    {
                        (this.state.cf_state == 2 ) &&
                        <div>
                            <div>
                                {
                                    (this.state.nonUniqueResponsesList.length > 0) && 
                                    <div className={ [ 'alert', 'alert-danger', classes.ResponseAlertListDiv ].join(' ') }>
                                        <h6>These responses are repeated in different steps. Thus might not work according to sequence.</h6>
                                        <ul className={ [ classes.ResponseAlertList ].join(' ') }>
                                            {
                                                this.state.nonUniqueResponsesList.map( (responseText, rti) => {
                                                    return (
                                                        <li key={ "rt_"+rti }>
                                                            { responseText }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                            <h5>Rules</h5>
                            <div className={ [ classes.DiffCheckerDiv ].join(' ') }>
                                <ReactDiffViewer oldValue={ this.state.cf_oldRulesJson } newValue={ this.state.cf_callFlowRulesJson } splitView={true} />
                            </div>
                            <h5>Responses</h5>
                            <div className={ [ classes.DiffCheckerDiv ].join(' ') }>
                                <ReactDiffViewer oldValue={ this.state.cf_oldResponseJson } newValue={ this.state.cf_callFlowResponseJson } splitView={true} />
                            </div>
                        </div>
                    }
                    {
                        (this.state.cf_state == 1 ) &&
                        (this.props.propsRedux.warnOverwriting) &&
                        <div className={ [ "alert", "alert-danger" ].join(' ') }>
                            { messages.warnOverwritingMessage }
                        </div>
                    }
                </Modal>
                
            </div>
        );
    }
}

/** Defining prop types requirements */
HeaderDiv.propTypes = {
    propsRedux: PropTypes.shape({
        // rulesJson: PropTypes.object.isRequired,
        alert: PropTypes.object.isRequired,
        success: PropTypes.string,
        error: PropTypes.string,
        loading: PropTypes.bool,
        storyId: PropTypes.any,
    }),
};

/** Redux store management  */
const mapStateToProps = (state, props) => {
    return {
        propsRedux: {
            stringifiedJson: state.rules.stringifiedJson,
            rulesJson: state.rules.rulesJson,
            intents: state.rules.intents,
            utterances:state.rules.sampleUtterances,
            alert: state.rules.alert,
            success: state.rules.success,
            error: state.rules.error,
            loading: state.rules.loading,
            storyId: state.story.storyId,
            storyType: state.story.storyType,
            isDraftInDb: state.rules.isDraftInDb,
            selectedRule: state.rules.selectedRule,
            showIntentModal: state.rules.showIntentModal,
            showUtteranceModal: state.rules.showUtteranceModal,
            previousJsons: state.rules.previousJsons,
            selectedJson: state.rules.selectedJson,
            isCallFlowDraftInDb: state.rules.callFlow.callFlowIsDraft,
            callFlowJSON: state.rules.callFlow.callFlowJSON,
            callFlowMapping: state.rules.callFlowResponseMappings,
            warnOverwriting: state.rules.warnOverwriting,
            unsavedChanges: state.rules.unsavedChanges,
            ruleAndCallFlowUpdated: state.rules.ruleAndCallFlowUpdated,
            showUtteranceSideBar: state.rules.showUtteranceSideBar,
            statements:state.rules.statements,
            testPlans:state.rules.testPlans,
            showTestPlans:state.rules.showTestPlans,
            stepSequence: state.rules.stepSequence
        },
        propsOwned: {...props}
    }
}

/** Exporting main component */
export default connect(mapStateToProps, { saveRulesToDb, setAlert, setSelectedRule, setResponseAction, showResponseModal, getRules, setRules, resetBootStrapedCallFlow, setWarnOverwriting, setCallFlowJson, setCallFlowMapping, setStepSequence, setAccordionState })(HeaderDiv);
