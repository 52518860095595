import axiosCall from "../../../utils/axiosCall";
import { TODOLIST_GET, TODOLIST_CHANGEVIEW } from "../types";
import { setLoading, axiosErrorFunction } from "../common/commonActions";

export const getTodoList = (hasCompleted) => async dispatch => {
    setLoading();
    var url = 'todo-list';
    const axiosSuccessFunction = (response) => {
        // handle success
        if (response.data && response.data.list) {
            console.log('Action todo list get :', response);
            
            var payload = {
                'type': '',
                'list': []
            }
            
            if (hasCompleted) {
                payload.type = 'past';
            } else {
                payload.type = 'current';
            }
            payload.list = response.data.list;
            dispatch({
                type: TODOLIST_GET,
                payload: payload
            });
        }
    };
    
    axiosCall(url, axiosSuccessFunction, axiosErrorFunction, { hasCompleted: hasCompleted });
};

export const changeView = (view) => async dispatch => {
    console.log('changeview called view:', view);
    
    setLoading();
    dispatch({
        type: TODOLIST_CHANGEVIEW,
        payload: { view: view }
    });
}